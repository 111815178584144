/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector, isValid } from 'redux-form';
import { useHistory, useLocation } from 'react-router-dom';
import { error, success } from 'react-notification-system-redux';
import { Country, State, City } from 'country-state-city';
import CreateAccountView from '../components/view/CreateAccountView';
import Loading from '../components/loading/Loading';

import {
  removeSpecialSymbols,
  formatCompanyData,
  organizeCompanies,
  // logFormData
} from '../utils/functions';
import {
  profiles,
  CountryOptions,
  StateOptions,
  DefaultOptionType,
  Paths,
  LocationState,
  CompanyOption,
  companyTypes,
  FormFileType,
  AddressAttributes,
  AdditionalData,
  AccountFormAttributes,
} from '../utils/constants';
import { CompanyJson, FETCH_COMPANY_CHILDREN } from '../store/companies';
import { RootState } from '../store/configureStore';
import {
  AddressJson,
  CREATE_USER,
  DELETE_USER_AVATAR,
  FetchUserResponse,
  FETCH_USER,
  ProfileJson,
  UPDATE_USER,
} from '../store/users';
import { filter, find, includes, isEmpty, isNil, keys, toString } from 'lodash';
import { AccountJson, FETCH_ACCOUNT_SESSION_REQUEST } from '../store/accounts';
import { SIGN_IN } from '../store/auth';

const defaultInitialValue = {
  country_code: { value: '+55', label: '🇧🇷 +55', countryValue: 'BR' },
  nationality_country: {
    phonecode: '55',
    flag: '🇧🇷',
    label: 'Brazil',
    value: 'BR',
  },
};

interface AccountFormData {
  id?: string;
  company?: DefaultOptionType;
  name?: string;
  email?: string;
  gender?: string;
  birthdate?: Date | string;
  phone?: string;
  document_cpf?: string;
  document_cnpj?: string;
  nationality_country?: CountryOptions;
  nationality_state?: StateOptions;
  nationality_city?: DefaultOptionType | string;
  marital_status?: string;
  profile?: string;
  race?: string;
  ethnicity?: string;
  country_code?: { value: string | number };
  accounts_attributes?: AccountFormAttributes[];
  address_attributes?: AddressAttributes;
  additional_data?: AdditionalData;
  avatar?: FormFileType;
  zap_sign_user_token?: string;
}

const formatAccountsAttributes = (data: AccountFormAttributes[]) => {
  return data.map((accounts_attributes) => {
    const { company_name, ...rest } = accounts_attributes;
    return rest;
  });
};

const formatInitialValues = (
  user: FetchUserResponse,
  countryOptions: CountryOptions[],
  companyDescendants: number[],
) => {
  const {
    data: { data, included },
  } = user;
  const includedAccounts = filter(included, (incl) => incl.type === 'accounts') as AccountJson[];
  const accounts_attributes = includedAccounts
    .filter((acc) =>
      find(
        included,
        (incl) =>
          incl.type === 'companies' &&
          ~~incl.id === acc.attributes.company_id &&
          includes(companyDescendants, ~~incl.id),
      ),
    )
    .map((acc) => {
      const accCompany = find(
        included,
        (incl) => incl.type === 'companies' && ~~incl.id === acc.attributes.company_id,
      ) as CompanyJson;
      const accProfiles = filter(
        included,
        (incl) => incl.type === 'profiles' && incl.attributes.account_id === ~~acc.id,
      ) as ProfileJson[];
      return {
        id: acc.id,
        company_id: ~~accCompany.id,
        company_name: accCompany.attributes.name,
        profiles_attributes: accProfiles.map((profile) => ({ ...profile.attributes, id: profile.id })),
      };
    });
  const inclAddressAttributes = find(included, (incl) => incl.type === 'addresses') as AddressJson;
  let address_attributes;
  if (inclAddressAttributes) {
    const {
      attributes: { city_id, state_id, country_id, ...rest },
    } = inclAddressAttributes;
    address_attributes = {
      id: inclAddressAttributes.id,
      city_id: toString(city_id),
      state_id: toString(state_id),
      country_id: toString(country_id),
      ...rest,
    };
  }

  const {
    attributes: {
      profiles,
      ethnicity_ids,
      avatar_attachment_id,
      avatar_url,
      document_number,
      document_type,
      birthplace,
      nationality,
      ...user_attributes
    },
  } = data;
  const {
    attributes: { phone },
  } = data;
  let documentAttrs = {};
  const documentField = document_type && `document_${document_type}`;
  if (documentField) {
    documentAttrs = {
      [documentField]: document_number,
    };
  }
  const stateInfo = birthplace?.substring(birthplace.lastIndexOf('-') + 1);
  const cityInfo = birthplace?.substring(0, birthplace.lastIndexOf('-'));
  const nationality_country = countryOptions.find((item) => item.label === nationality);

  const nationality_state = State.getStatesOfCountry(nationality_country?.value as string)
    .map(({ name, isoCode, countryCode }) => ({
      label: name,
      value: name,
      isoCode,
      countryCode,
    }))
    .find((item) => item.isoCode === stateInfo);
  const nationality_city =
    City.getCitiesOfState(nationality_state?.countryCode as string, nationality_state?.isoCode as string)
      .map(({ name }) => ({
        label: name,
        value: name,
      }))
      .find((item) => item.value === cityInfo) || cityInfo;

  return {
    id: data.id,
    accounts_attributes,
    address_attributes,
    ...user_attributes,
    phone,
    ...documentAttrs,
    nationality_city,
    nationality_country,
    nationality_state,
    avatar: {
      id: avatar_attachment_id,
      url: avatar_url,
    },
  };
};

const formatAccountFormData = async (data: AccountFormData, initialValues: AccountFormData) => {
  const {
    name,
    email,
    gender,
    document_cpf,
    document_cnpj,
    nationality_country,
    nationality_state,
    nationality_city,
    marital_status,
    race,
    additional_data,
    address_attributes,
    avatar,
    id,
    phone,
    zap_sign_user_token,
  } = data;
  try {
    const isUpdating = !isNil(id);
    let { accounts_attributes, birthdate } = data;
    accounts_attributes = accounts_attributes && formatAccountsAttributes(accounts_attributes);

    birthdate = birthdate instanceof Date ? birthdate.toUTCString() : '';

    const document_type = document_cpf !== undefined && document_cpf !== ''
        ? 'cpf'
        : document_cnpj !== undefined && document_cnpj !== ''
        ? 'cnpj'
        : '';

    const document_number = document_cpf !== undefined && document_cpf !== ''
        ? removeSpecialSymbols(document_cpf)
        : document_cnpj !== undefined && document_cnpj !== ''
        ? removeSpecialSymbols(document_cnpj)
        : '';

    const nationality = nationality_country && nationality_country['label'];
    const nationalityCity = `${
      nationality_city && typeof nationality_city === 'object' && nationality_city['label']
        ? nationality_city['label']
        : nationality_city
    }`;

    const nationalityState = `${nationality_state && nationality_state['isoCode'] ? nationality_state['isoCode'] : ''}`;
    const birthplace = [nationalityCity, nationalityState].filter((item) => !isEmpty(item)).join('-') || null;

    const race_ids = race ? [race] : [];

    const avatarExisted = !isNil(initialValues.avatar?.id);
    const avatarWasDestroyed = avatarExisted && isNil(avatar);
    const avatarDidntChange = avatarExisted && initialValues.avatar?.id === data.avatar?.id;
    const formattedData = {
      name: name?.trim().replace('  ', ' '),
      email,
      gender,
      birthdate,
      phone,
      document_type,
      document_number,
      marital_status,
      nationality,
      birthplace,
      additional_data,
      address_attributes,
      race_ids,
      accounts_attributes,
      avatar,
      zap_sign_user_token,
    };
    const formData = new FormData();
    formData.append('data[type]', 'users');
    if (isUpdating) formData.append('data[id]', id);
    keys(formattedData)
      .filter((item) => {
        const currentItem = formattedData[item as keyof typeof formattedData];
        if (!isUpdating) {
          return currentItem !== undefined && !isEmpty(currentItem);
        } else {
          const initialItem = initialValues[item as keyof typeof initialValues];
          return (!isEmpty(initialItem) && isEmpty(currentItem)) || !isEmpty(currentItem);
        }
      })
      .forEach((item) => {
        const key = item as keyof typeof formattedData;
        if (item === 'accounts_attributes') {
          const accAttrs = formattedData['accounts_attributes'];
          accAttrs &&
            accAttrs
              .filter((accAttr, index, self) => {
                const currentAccount = self[index];
                const accountWasDestroyed = accAttr._destroy;
                const initialAccountAttrValues = initialValues.accounts_attributes?.find(
                  (initialAccAttr) => initialAccAttr.id === currentAccount.id,
                );
                let accountRolesHasChanged = false;
                if (isUpdating && initialAccountAttrValues) {
                  const currentAccountAttrRoles = currentAccount.profiles_attributes;
                  const initialCurentAccountRoles = initialAccountAttrValues.profiles_attributes;
                  accountRolesHasChanged =
                    initialCurentAccountRoles.some((profile) => {
                      const existsInCurrentAccountAttrRoles = currentAccountAttrRoles.find(
                        (currAccAttr) => currAccAttr.role === profile.role,
                      );
                      if (!existsInCurrentAccountAttrRoles) return true;
                      const wasDestroyed = existsInCurrentAccountAttrRoles._destroy;
                      if (wasDestroyed) return true;
                      return false;
                    }) ||
                    currentAccountAttrRoles.some((profile) => {
                      const existsInInitialAccountAttrRoles = initialCurentAccountRoles.find(
                        (initialAccAttr) => initialAccAttr.role === profile.role,
                      );
                      return !existsInInitialAccountAttrRoles;
                    });
                  if (!accountRolesHasChanged && !accountWasDestroyed) return false;
                }
                return true;
              })
              .forEach((accAttr, index, self) => {
                const currentAccount = self[index];
                const accountWasDestroyed = accAttr._destroy;
                const initialAccountAttrValues = initialValues.accounts_attributes?.find(
                  (initialAccAttr) => initialAccAttr.id === currentAccount.id,
                );

                const accAttrPrefix = (sufix: string) => `data[attributes][accounts_attributes][${index}]${sufix}`;
                if (isUpdating && currentAccount.id) formData.append(accAttrPrefix('[id]'), currentAccount.id);
                if (accountWasDestroyed) {
                  formData.append(accAttrPrefix('[_destroy]'), 'true');
                } else {
                  formData.append(accAttrPrefix('[company_id]'), accAttr.company_id.toString());
                  formData.append(accAttrPrefix('[active]'), 'true');
                  const profileAttrs = accAttr.profiles_attributes;
                  profileAttrs
                    .filter((profileAttr) => {
                      let roleChanged = false;
                      const initialProfileAttrValue = initialAccountAttrValues?.profiles_attributes.find(
                        (profile) => profile.role === profileAttr.role,
                      );
                      if (initialProfileAttrValue && profileAttr._destroy) roleChanged = true;
                      if (initialProfileAttrValue && !roleChanged) return false;
                      return true;
                    })
                    .forEach((profileAttr, index) => {
                      const profileAttrPrefix = (sufix: string) =>
                        accAttrPrefix(`[profiles_attributes][${index}][${sufix}]`);
                      if (profileAttr.id) {
                        formData.append(profileAttrPrefix('id'), profileAttr.id);
                      }
                      if (profileAttr._destroy && profileAttr.id) {
                        formData.append(profileAttrPrefix('_destroy'), 'true');
                      } else {
                        formData.append(profileAttrPrefix('role'), profileAttr.role);
                      }
                    });
                }
              });
        } else if (item === 'address_attributes') {
          const addressAttrs = formattedData['address_attributes'];
          !isEmpty(addressAttrs) &&
            addressAttrs &&
            keys(addressAttrs)
              .filter((item) => {
                const key = item as keyof typeof addressAttrs;
                return addressAttrs[key] !== undefined && addressAttrs[key] !== null;
              })
              .forEach((item) => {
                const key = item as keyof typeof addressAttrs;
                const prefix = (sufix: keyof typeof addressAttrs) => `data[attributes][address_attributes][${sufix}]`;
                formData.append(prefix(key), addressAttrs[key] as string);
              });
        } else if (item === 'additional_data') {
          const additionalData = formattedData['additional_data'];
          !isEmpty(additionalData) &&
            additionalData &&
            keys(additionalData)
              .filter((item) => {
                const key = item as keyof typeof additionalData;
                return additionalData[key] !== undefined && additionalData[key] !== null;
              })
              .forEach((item) => {
                const key = item as keyof typeof additionalData;
                const prefix = (sufix: keyof typeof additionalData) => `data[attributes][additional_data][${sufix}]`;
                formData.append(prefix(key), additionalData[key] as string);
              });
        } else if (item === 'race_ids') {
          const raceIds = formattedData['race_ids'];
          !isEmpty(raceIds) &&
            raceIds &&
            raceIds.forEach((race) => {
              formData.append(`data[attributes][race_ids][]`, race);
            });
        } else if (item === 'avatar') {
          const avatar = formattedData['avatar'];
          !isEmpty(avatar) &&
            avatar?.file &&
            !avatarDidntChange &&
            formData.append(`data[attributes][avatar]`, avatar.file as Blob);
        } else {
          if (isUpdating) {
            initialValues[item as keyof typeof initialValues] !== formattedData[key] &&
              formData.append(`data[attributes][${item}]`, formattedData[key] as string);
          } else {
            formData.append(`data[attributes][${item}]`, formattedData[key] as string);
          }
        }
      });
    // logFormData(formData)

    return { formData, destroyAvatar: avatarWasDestroyed };
  } catch (error) {
    throw Error('Erro na formatação dos dados de criação de usuário');
  }
};

const CreateAccountContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, user },
    account: { companies },
  } = state;
  const location = useLocation<LocationState>();
  const formValues = formValueSelector('accountForm');
  const [companyOptions, setCompanyOptions] = React.useState<CompanyOption[]>([]);
  const [initialCompanyOptions, setInitialCompanyOptions] = React.useState<CompanyOption[]>([]);
  const [loadingItems, setLoadingItems] = React.useState<string[]>([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<AccountFormData>(defaultInitialValue);
  const [loaded, setLoaded] = React.useState(false);
  const accountsAttributesValue: AccountFormAttributes[] = formValues(state, 'accounts_attributes');
  const isFormValid = isValid('accountForm')(state);

  const saveUserMethod = React.useCallback(
    async (data: FormData, destroyAvatar: boolean) => {
      try {
        const isUpdating = !isNil(initialValues.id);
        if (isUpdating && initialValues.id) {
          const updatedUser = await dispatch(
            UPDATE_USER.request({
              id: initialValues.id,
              data,
            }),
          );
          if (destroyAvatar) {
            await dispatch(
              DELETE_USER_AVATAR.request({
                id: initialValues.id,
              }),
            );
          }
          if (user.id === initialValues.id && updatedUser) {
            const {
              data: {
                data: { id, attributes },
              },
            } = updatedUser;
            dispatch(SIGN_IN.success({ id, ...attributes }));
            dispatch(FETCH_ACCOUNT_SESSION_REQUEST({ id: user.id, changeProfile: false }));
          }
        } else {
          await dispatch(
            CREATE_USER.request({
              data,
            }),
          );
        }
        dispatch(
          success({
            message: `Usuário ${isUpdating ? 'atualizado' : 'criado'} com sucesso!`,
            autoDismiss: 3,
          }),
        );

        setSubmitting(false);
        history.push(Paths.USERS_LIST);
      } catch (e) {
        dispatch(
          error({
            title: 'Erro',
            message: 'Erro ao salvar usuário, favor entrar em contato com suporte.',
            autoDismiss: 3,
          }),
        );
      } finally {
        setSubmitting(false);
      }
    },
    [initialValues],
  );

  const fetchCountriesMethod = React.useCallback(async () => {
    try {
      const countryList = await Country.getAllCountries().map(({ flag, name, isoCode, phonecode }) => ({
        label: name,
        value: isoCode,
        flag,
        phonecode,
      }));
      setLoadingItems((items) => items.filter((x) => x !== 'countries'));
      return countryList;
    } catch (e) {
      dispatch(error({ message: 'Erro no carregamento dos países' }));
    }
  }, [setLoadingItems]);

  const fetchUserMethod = React.useCallback(
    async (id: string, countryOptions) => {
      try {
        const user = await dispatch(
          FETCH_USER.request({
            id,
            params: {
              filters: {
                include: 'accounts,accounts.profiles,accounts.company,address',
              },
            },
          }),
        );
        const currentCompany = companies.find((co: any) => co.id === company);
        const descendants: number[] = currentCompany.descendants_ids.concat(~~company);
        const formattedInitialValues = formatInitialValues(user, countryOptions, descendants);
        setInitialValues(formattedInitialValues);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar informações de usuário',
          }),
        );
      }
    },
    [setInitialValues],
  );

  const setCompanyOptionsMethod = React.useCallback(
    (
      newCompanyOptions: CompanyOption[] = (!isEmpty(initialCompanyOptions) && initialCompanyOptions) || companyOptions,
    ) => {
      if (isEmpty(initialCompanyOptions)) setInitialCompanyOptions(newCompanyOptions);
      if (accountsAttributesValue) {
        newCompanyOptions = newCompanyOptions.reduce((ac, companyOption) => {
          const accountForCompany = accountsAttributesValue.find(
            (accountAttribute) => accountAttribute.company_id === companyOption.value,
          );
          if (isNil(accountForCompany)) return ac.concat(companyOption);
          const profileOptionsForCompany = companyTypes.find(
            (item) => item.value === companyOption.kind,
          )?.profileOptions;
          const accountProfiles = accountForCompany.profiles_attributes.map((profile) => ({
            role: profile.role,
            _destroy: profile._destroy,
          }));
          const accountHasAllProfilesForCompany = profileOptionsForCompany?.every((option) => {
            const relativeRole = accountProfiles.find((accPf) => accPf.role === option);
            return relativeRole && Boolean(relativeRole._destroy) === false;
          });
          if (accountHasAllProfilesForCompany) return ac;
          return ac.concat(companyOption);
        }, [] as CompanyOption[]);
      }
      setCompanyOptions(newCompanyOptions);
    },
    [setCompanyOptions, accountsAttributesValue],
  );

  const fetchCompanyChildrenMethod = async ({
    currentOptions = companyOptions,
    ids = [],
  }: {
    ids?: number[];
    currentOptions?: CompanyOption[];
  }) => {
    setLoadingItems((items) => items.concat('companies'));
    if (isEmpty(ids)) {
      ids = companies.find((item: any) => item.id === company).descendants_ids;
      if (isEmpty(ids)) {
        ids = [company];
      }
    }
    if (ids.length) {
      try {
        const result = await dispatch(FETCH_COMPANY_CHILDREN.request({ ids }));
        if (result.length) {
          const formattedData = result.map((item) => {
            const {
              data: { data },
            } = item;
            return formatCompanyData({ ...data });
          });
          if (formattedData.length) {
            const { newOptionsValue } = organizeCompanies(formattedData, currentOptions);
            const currentCompany = companies.find((item: any) => item.id === company);
            const currentCompanyOption: CompanyOption = {
              value: currentCompany.id,
              label: currentCompany.name,
              kind: currentCompany.kind,
              child_ids: currentCompany.child_ids,
            };
            if (!newOptionsValue.find((option) => option.value === company))
              newOptionsValue.unshift(currentCompanyOption);
            setCompanyOptionsMethod(newOptionsValue);
          }
        }
        setLoadingItems((items) => items.filter((x) => x !== 'companies'));
      } catch (e) {
        setLoadingItems((items) => items.filter((x) => x !== 'companies'));
        dispatch(
          error({
            title: 'Erro ao carregar hierarquia de companhias',
            message: 'Favor contatar suporte',
          }),
        );
      }
    }
  };


  const initAccountForm = React.useCallback(async () => {
    try {
      const countryOptions = await fetchCountriesMethod();
      if (location?.state && location?.state?.user_id) {
        await fetchUserMethod(location.state.user_id.toString(), countryOptions);
      }
      await fetchCompanyChildrenMethod({});
      setLoaded(true);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao inicializar formulário de usuário',
        }),
      );
    }
  }, [fetchUserMethod, fetchCountriesMethod, profiles]);


  React.useEffect(() => {
    initAccountForm();
  }, []);

  React.useEffect(() => {
    setCompanyOptionsMethod();
  }, [accountsAttributesValue]);


  const onSubmit = React.useCallback(
    async (data: AccountFormData) => {
      try {
        setSubmitting(true);
        const { formData, destroyAvatar } = await formatAccountFormData(data, initialValues);
        // logFormData(formData)
        await saveUserMethod(formData, destroyAvatar);
        setSubmitting(false);
      } catch {
        dispatch(
          error({
            title: 'Erro',
            message: 'Erro ao salvar usuário, favor entrar em contato com suporte.',
            autoDismiss: 1.5,
          }),
        );
      }
    },
    [setSubmitting, saveUserMethod],
  );

  if (loadingItems.length || !loaded) {
    return <Loading />;
  }
  return (
    <CreateAccountView
      submitting={submitting}
      onSubmit={onSubmit}
      companyOptions={companyOptions}
      isFormValid={isFormValid}
      initialValues={initialValues}
    />
  );
};

export default CreateAccountContainer;
