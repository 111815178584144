import React from 'react'
import { SvgIcon } from "@mui/material"

const AcademicIcon = (props: any) => (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M13.1862 31.1908H26.9932C27.5473 31.1908 27.9999 30.7383 27.9999 30.1841V2.00667C27.9999 1.45254 27.5473 1 26.9932 1H8.11587C5.76083 1 3.83984 2.92098 3.83984 5.27603V26.9148C3.83984 29.2421 5.70541 31.1354 8.02352 31.1908H13.1954H13.1862Z' stroke='black' strokeMiterlimit='10'/>
      <path d='M4.05273 28.0231C4.05273 26.49 5.1887 25.2524 6.59249 25.2524H28.0004' stroke='black' strokeMiterlimit='10'/>
      <path d='M23.3172 6.55078H8.72514C8.3732 6.55078 8.08789 6.83609 8.08789 7.18803V11.852C8.08789 12.2039 8.3732 12.4892 8.72514 12.4892H23.3172C23.6692 12.4892 23.9545 12.2039 23.9545 11.852V7.18803C23.9545 6.83609 23.6692 6.55078 23.3172 6.55078Z' stroke='black' strokeMiterlimit='10'/>
      <path d='M8.28223 16.5806H17.5547' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
      <path d='M8.28223 20.2837H13.2602' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
    </SvgIcon>
  )

export default AcademicIcon