import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_DEFICIENCIES,
  DELETE_USER_DEFICIENCY,
  FetchDeficienciesPayload,
  DeleteUserDeficiencyPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchDeficiencies (action: DefaultAction<FetchDeficienciesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchDeficiencies, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_DEFICIENCIES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_DEFICIENCIES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteUserDeficiency (action: DefaultAction<DeleteUserDeficiencyPayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.deleteUserDeficiency, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_USER_DEFICIENCY.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_USER_DEFICIENCY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchDeficienciesSagas () {
  yield takeLatest(FETCH_DEFICIENCIES.request, fetchDeficiencies)
  yield takeLatest(DELETE_USER_DEFICIENCY.request, deleteUserDeficiency)
}

export default function * deficienciesSagas () {
  yield all([watchDeficienciesSagas()])
}
