import React from 'react'
import { change, Field } from 'redux-form'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/system'

import { colors, defaultPagination, KtwelveCurriculumRegistrationAttributes, PaginationType, ProductTypesEnum, RegistrationAttributes, RoomAttributes, RoomStudentAttributes } from '../../utils/constants'
import RadioComponent from '../input/form/radio'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import Loading from '../loading/Loading'
import { error } from 'react-notification-system-redux'
import { FETCH_STUDENTS_MISSING_REGISTRATIONS } from '../../store/registrations'
import InputComponent from '../input/form/input'
import { filter, find, get, isEmpty } from 'lodash'
import { KtwelveCurriculumRegistrationJson } from '../../store/ktwelve_curriculum_registrations'
import { RegistrationProductJson } from '../../store/registration_products'
import { ProductJson } from '../../store/products'
import { ProductItemJson } from '../../store/product_items'
import { parse } from 'date-fns'

const columns = [
  'Nome', 'Descrição', 'Vagas'
]

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    background: '#FDFEFF',
    padding: '1rem',
    borderRadius: '1rem',
    '& .delete': {
      color: colors.lightRed,
      cursor: 'pointer',
      '&:hover' :{
        color: '#cc8080'
      },
    },
    '& th': {
      color: colors.darkGrayBlue,
      borderBottom: 'none'
    },
    '& .MuiPaper-root': {
      flexGrow: '1'
    },
    '& span.empty': {
      color: colors.lightRed
    },
    '& span.empty:empty:before': {
      content: '"\\200b"'
    },
    '& tbody': {
      position: 'relative'
    },
    '& td': {
      padding: '2px',
      color: colors.darkBlue,
      fontSize: '1rem',
      borderBottom: `1px solid ${colors.grayBlue}`,
      [theme.breakpoints.down('lg')]: {
        fontSize: '0.75rem'
      },
    },
    '& .contract': {
      background: '#FDFEFF',
      '& td:last-of-type, th:last-of-type': {
        borderLeft: `1px solid ${colors.grayBlue}`,
        borderRight: `1px solid ${colors.grayBlue}`    
      },
      '& th:last-of-type': {
        borderTop: `1px solid ${colors.grayBlue}`
      },  
    },
    '& .applied': {
      border: '1px solid #C5D4E3',
      background: 'white'
    },
    '& .applied, .contract':{
      '& tfoot':{
        '& td:last-of-type':{
          color: colors.green,
          paddingRight: '0.5rem'
        }
      }
    },
    '& .applied, .contract, .preview': {
      '& tfoot': {
        '& td:first-of-type':{
          paddingLeft: '0.5rem'
        }
      }
    },
    '& tfoot' : {
      "& > tr": {
        border: `1px solid ${colors.grayBlue}`,
        'border-radius': '0px 0px 8px 8px',  
        background: 'white',
        '& > td': {
          padding: '0.5rem 0',
          fontWeight: 'bold'
        },
      }
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& td': {
        color: colors.darkBlue,
        '& svg': {
          cursor: 'pointer',
          margin: '0 0.25rem'
        }
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none'
      }
    }
  }
}))

interface CustomRegistrationOptions extends RegistrationAttributes {
  ktwelve_curriculum_registration?: KtwelveCurriculumRegistrationAttributes
  starts_at: string
}
const form_name = 'roomStudentForm';

const InsertRoomStudentTable = ({
  room,
  room_student,
}:{
  room: RoomAttributes
  room_student: RoomStudentAttributes
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const product_id = room.product_id
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [registrationOptions, setRegistrationOptions] = React.useState([] as CustomRegistrationOptions[])
  const [nameFilterValue, setNameFilterValue] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  const fetchStudentsMissingRegistration = React.useCallback(async (props: {
    newPagination?: PaginationType
  }) => {
    const {
      newPagination = pagination
    } = props
    try {
      setLoading(true);
      const registrations = await dispatch(
        FETCH_STUDENTS_MISSING_REGISTRATIONS.request({
          product_id: product_id.toString(),
          params: {
            filters: {
              'q[company_id_eq]': room.company_id.toString(),
              'q[student_user_name_start]': nameFilterValue,
              'page[number]': (newPagination.pageNumber + 1).toString(),
              'page[size]': newPagination.pageSize.toString(),
              'include': ['ktwelve_curriculum_registrations','registration_products.product.product_items'].join(',')
            }
          }
        }),
      );
      if (registrations) {
        const {
          data: { data, included },
        } = registrations;
        const formattedData = data.map(({ attributes, id }) => {
          const ktwelve_curriculum_registration = find(
            included, incl => incl.type === 'ktwelve_curriculum_registrations' && incl.attributes.registration_id === ~~id 
          ) as KtwelveCurriculumRegistrationJson
          const registration_product = find(
            filter(included, incl => incl.type === 'registration_products' && incl.attributes.active) as RegistrationProductJson[], incl => {
              const product = find(included, x => x.type === 'products' && ~~x.id === incl.attributes.product_id && incl.attributes.kind === ProductTypesEnum.KIT) as ProductJson
              const product_item = find(included, x => x.type === 'product_items' && x.attributes.product_id === ~~get(product, 'id') && x.attributes.item_id === room.product_id) as ProductItemJson
              return !isEmpty(product) && !isEmpty(product_item)
            }
          )
          return {
            id,
            ...attributes,
            ...(ktwelve_curriculum_registration ? {ktwelve_curriculum_registration: { id: ktwelve_curriculum_registration.id, ...ktwelve_curriculum_registration.attributes }} : {}),
            ...(registration_product ? { starts_at: registration_product.attributes.starts_at } : {})
          };
        });
        setRegistrationOptions(formattedData);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      dispatch(
        error({
          message: 'Erro ao carregar opções de alunos',
        }),
      );
    }
  }, [room, pagination, nameFilterValue]);

  const init = () => {
    if(isEmpty(room_student.registration_id)){
      fetchStudentsMissingRegistration({})
    }
  }

  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchStudentsMissingRegistration({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchStudentsMissingRegistration({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  React.useEffect(() => {
    init()
  }, [])

  if(loading) {
    return <Loading />
  }

  if(!isEmpty(room_student.id)){
    return (
      <div>
        <h2>{room_student.student_name}</h2>
      </div>
    )
  }

  const handleSelectRegistration = (_: React.ChangeEvent<any> | undefined, value: string) => {
    if (value) {
      const found_registration = find(registrationOptions, opt => opt.id === value) as CustomRegistrationOptions
      const ktwelve_curriculum_registration = found_registration?.ktwelve_curriculum_registration
      if(ktwelve_curriculum_registration && ktwelve_curriculum_registration.ktwelve_curriculum_id === room.ktwelve_curriculum_id) {
        dispatch(
          change(
            form_name, 'room_students_attributes[0].ktwelve_curriculum_registration_id', ktwelve_curriculum_registration.id
          )
        )
        dispatch(
          change(
            form_name, 'id', ktwelve_curriculum_registration.id
          )
        )
      }
      const starts_at = parse(found_registration.starts_at, 'yyyy-MM-dd', new Date());
      dispatch(
        change(
          form_name, 'room_students_attributes[0].starts_at', starts_at
        )
      )
      dispatch(
        change(
          form_name, 'room_students_attributes[0].ends_at', room.last_day
        )
      )
    }
  };

  return (
      <div style={{ display: 'grid' }}>
        <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
          {' '}
          Selecionar aluno{' '}
        </span>
        <InputComponent
          placeholder={`Buscar por nome do aluno`}
          search
          small
          onSearch={() => {
            fetchStudentsMissingRegistration({});
          }}
          input={{
            value: nameFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setNameFilterValue(e.target.value);
            },
          }}
        />
      <div className={classes.table}>
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='left' />
                <TableCell align='center'>
                  Nome
                </TableCell>
                <TableCell align='center'>
                  RA
                </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {registrationOptions.map(option => {
                  return (
                  <TableRow key={option.code}>
                    <TableCell align='left'>
                      <Field
                        name='registration_id'
                        onChange={handleSelectRegistration}
                        component={RadioComponent}
                        props={{ value: option.id }}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      {option.user_data.name}
                    </TableCell>
                    <TableCell align='center'>
                      {option.code}
                    </TableCell>
                  </TableRow>
                  )
                })
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 2}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : ` 'mais de' ${to}`}`
                  }
                />
              </TableRow>
            </TableFooter>
            </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default InsertRoomStudentTable
