/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { error } from 'react-notification-system-redux';

import { RootState } from '../store/configureStore';
import { Company, CompanyAttributes } from '../utils/constants';
import HomeView from '../components/view/HomeView';
import { UI_SET_LOADING_OPEN } from '../store/ui';

const HomeContainer = () => {
  const dispatch = useDispatch();
  const {
    auth: { profile, company, company_descendants },
    account: { companies },
  } = useSelector((state: RootState) => state);
  const descendants = company_descendants as CompanyAttributes[];
  const profileValue = profile.role;
  const [currentCompany, setcurrentCompany] = React.useState<CompanyAttributes | null>(null);
  const [hubs, setHubs] = React.useState<CompanyAttributes[]>([]);
  const [groups, setGroups] = React.useState<CompanyAttributes[]>([]);
  const [schools, setSchools] = React.useState<CompanyAttributes[]>([]);
  const [publishers, setPublishers] = React.useState<CompanyAttributes[]>([]);

  const currentCompanyKind = companies.find((item: any) => item.id === company)?.kind;
  const setLoading = (value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  };

  const fetchCompaniesMethod = async () => {
    setHubs([]);
    setGroups([]);
    setSchools([]);
    setPublishers([]);
    setLoading(true);
    try {
      const { hub, group, school } = descendants.reduce(
        (acc, co) => {
          return { ...acc, [co.kind]: acc[co.kind].concat(co) };
        },
        {
          school: [] as CompanyAttributes[],
          hub: [] as CompanyAttributes[],
          group: [] as CompanyAttributes[],
          publisher: [] as CompanyAttributes[],
          fund: [] as CompanyAttributes[],
        },
      );
      setHubs(hub);
      setGroups(group);
      setSchools(school);
      setPublishers(publishers);
      setcurrentCompany(companies.find((item: any) => item.id === company));
    } catch (e) {
      dispatch(
        error({
          title: 'Erro ao carregar companhias',
          message: 'Favor contatar suporte',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchCompaniesMethod();
  }, []);

  const title =
    currentCompanyKind === Company.FUND
      ? 'Meus Hubs / Grupos / Escolas'
      : currentCompanyKind === Company.HUB
      ? 'Meus Grupos / Escolas'
      : currentCompanyKind === Company.GROUP
      ? 'Minhas Escolas'
      : '';
  return (
    <HomeView
      currentCompany={currentCompany}
      title={title}
      profile={profileValue}
      company={company}
      hubs={hubs}
      groups={groups}
      schools={schools}
      publishers={publishers}
    />
  );
};

export default HomeContainer;
