import React, { useRef } from "react";
import SunEditor from 'suneditor-react';
import SunEditorCore from "suneditor/src/lib/core";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { WrappedFieldProps } from "redux-form";

const EditorComponent = (props: WrappedFieldProps) => {
  const { input: { value, onChange, onBlur } } = props
    const editor = useRef<SunEditorCore>();

    // The sunEditor parameter will be set to the core suneditor instance when this function is called
     const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editor.current = sunEditor;
    };
    return (
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        lang={'pt_br'}
        setContents={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
};
export default EditorComponent;
