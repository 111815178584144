/* eslint-disable camelcase */
import { JsonFormat, JsonResponseFormat, PaymentSimulation, RegistrationStatusTypes, RequestParams, WithRequestParams } from '../../utils/constants'
import { RegistrationProductJson } from '../registration_products'
import { KtwelveJson } from '../ktwelves'
import { ProductJson, ProductPeriodJson } from '../products'
import { RepresentativeJson, RepresentativeProductJson } from '../representatives'
import { AccountJson } from '../accounts'
import { AddressJson, UserJson } from '../users'
import { RegistrationConnectionJson } from '../registration_connections'
import { ContractJson } from '../contracts'
import { UserAlergyJson } from '../alergies'
import { UserDeficiencyJson } from '../deficiencies'
import { RegistrationDocumentJson } from '../registration_documents'
import { CompanyJson } from '../companies'
import { SubsidyJson } from '../subsidies'
import { ClassroomJson } from '../classrooms'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { CityJson } from '../cities'
import { StateJson } from '../states'
import { CountryJson } from '../countries'
import { AxiosResponse } from 'axios'
import { RegistrationClosureJson } from '../registration_closures'
import { InvoiceJson } from '../invoices'
import { InvoiceItemJson } from '../invoice_items'
import { BillingJson } from '../billings'
import { PaymentCollectionJson, PaymentOptionJson } from '../payment_options'
import { ScheduleJson } from '../schedules'
import { TransactionJson } from '../transactions'
import { InvoiceRenegotiationJson, RenegotiationJson } from '../renegotiations'
import { RegistrationClosureReasonJson } from '../registration_closure_reasons'
import { WalletJson } from '../wallets'
import { WalletCreditJson } from '../wallet_credits'
import { UserRaceJson } from '../races'
import { KtwelveCurriculumRegistrationJson } from '../ktwelve_curriculum_registrations'
import { ProductItemJson } from '../product_items'


export type EmergencyContactsJson = JsonFormat<'emergency_contacts', EmergencyContactsAttrs>

export interface EmergencyContactsAttrs {
  name: string
  phone: string
  registration_id: number
}

export interface DocumentRequirementAttrs {
  document_id: number
  document_name: string
  mandatory: boolean
  product_id: number
}

export type DocumentRequirementJson = JsonFormat<'document_requirements', DocumentRequirementAttrs>

export interface RegistrationProductSubsidyAttrs {
  discount: number
  portions: number[]
  registration_product_id: number
  subsidy_id: number
  comments: string
}

export type RegistrationProductSubsidiesJson = JsonFormat<'registration_product_subsidies', RegistrationProductSubsidyAttrs>


export interface RegistrationAttrs {
  account_id: number
  company_id: number
  code: string
  ends_at: string
  starts_at: string
  status: RegistrationStatusTypes
  active: boolean
  payment_simulation: PaymentSimulation,
  user_data: {
    id: number
    name: string
    email: string
  }
}

export type RegistrationJson = JsonFormat<'registrations', RegistrationAttrs>
export type FetchRegistrationsResponse = JsonResponseFormat<RegistrationJson[], (RegistrationJson | ProductJson
  | ClassroomJson | KtwelveJson
  | RegistrationProductJson | RepresentativeJson
  | DocumentRequirementJson | RegistrationDocumentJson
  | AccountJson | UserJson
  |AddressJson|RegistrationConnectionJson
  |ContractJson|UserAlergyJson|UserDeficiencyJson|CompanyJson
  |RegistrationProductSubsidiesJson|SubsidyJson
  |EmergencyContactsJson|CityJson|StateJson|CountryJson
  |RegistrationClosureJson|RepresentativeProductJson
  |InvoiceJson|InvoiceItemJson|BillingJson|
  PaymentOptionJson|PaymentCollectionJson|ScheduleJson|
  TransactionJson|RenegotiationJson|InvoiceRenegotiationJson
  |RegistrationClosureReasonJson|ProductPeriodJson|UserRaceJson
  |KtwelveCurriculumRegistrationJson|ProductItemJson)[]>

export type FetchRegistrationResponse = JsonResponseFormat<RegistrationJson,(RegistrationJson | ProductJson
  | ClassroomJson | KtwelveJson
  | RegistrationProductJson | RepresentativeJson
  | DocumentRequirementJson | RegistrationDocumentJson
  | AccountJson | UserJson
  |AddressJson|RegistrationConnectionJson
  |ContractJson|UserAlergyJson|UserDeficiencyJson|CompanyJson
  |RegistrationProductSubsidiesJson|SubsidyJson
  |EmergencyContactsJson|CityJson|StateJson|CountryJson
  |RegistrationClosureJson|RepresentativeProductJson
  |InvoiceJson|InvoiceItemJson|BillingJson|
  PaymentOptionJson|PaymentCollectionJson|ScheduleJson|
  TransactionJson|RenegotiationJson|InvoiceRenegotiationJson|
  RegistrationClosureReasonJson|ProductPeriodJson|WalletJson
  |WalletCreditJson|UserRaceJson)[]>


export interface CreateRegistrationPayload {
  data: object | FormData
}
export interface FetchStudentsMissingrRegistrationProductsPayload {
  product_id: string
  params?: RequestParams
}

export interface UpdateRegistrationPayload extends CreateRegistrationPayload {
  id: string | number
}
export type FetchRegistrationsPayload = WithRequestParams
export interface FetchRegistrationPayload extends WithRequestParams {
  id: string | number;
}

export interface DeleteRegistrationPayload {
  id: string
}

export const CREATE_REGISTRATION = createPromiseAction('registrations: CREATE_REGISTRATION')<CreateRegistrationPayload, FetchRegistrationResponse, any>()
export const UPDATE_REGISTRATION = createPromiseAction('registrations: UPDATE_REGISTRATION')<UpdateRegistrationPayload, FetchRegistrationResponse, any>()
export const FETCH_REGISTRATIONS = createPromiseAction('registrations: FETCH_REGISTRATIONS')<FetchRegistrationsPayload, FetchRegistrationsResponse, any>()
export const FETCH_REGISTRATION = createPromiseAction('registrations: FETCH_REGISTRATION')<FetchRegistrationPayload, FetchRegistrationResponse, any>()
export const DELETE_REGISTRATION = createPromiseAction('registrations: DELETE_REGISTRATION')<DeleteRegistrationPayload, AxiosResponse, any>()
export const FETCH_STUDENTS_MISSING_REGISTRATIONS = createPromiseAction('registrations: FETCH_STUDENTS_MISSING_REGISTRATIONS')<FetchStudentsMissingrRegistrationProductsPayload, FetchRegistrationsResponse, any>()
