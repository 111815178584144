/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { format } from 'date-fns';

import RegistrationDetailsView from '../components/view/Secretary/RegistrationDetailsView';
import Loading from '../components/loading/Loading';

import { updateCredentialsRequest } from '../store/auth';
import {
    userConnectionTypes,
    LocationState,
    FullRepresentativeName,
    RegDetailsCurrentRegistrationData,
    RegDetailsCurrentStudentData,
    RegDetailsRepresentativeData,
    Paths
} from '../utils/constants';
import { DocumentRequirementJson, FetchRegistrationResponse, FETCH_REGISTRATION } from '../store/registrations';
import { CountryJson, FETCH_COUNTRY } from '../store/countries';
import { FETCH_STATE, StateJson } from '../store/states';
import { CityJson, FETCH_CITY } from '../store/cities';
import { FETCH_RACE } from '../store/races';
import { error, warning } from 'react-notification-system-redux';
import { FETCH_ETHNICITY } from '../store/ethnicities';
import { RepresentativeJson } from '../store/representatives';
import { AccountJson } from '../store/accounts';
import { AddressJson, UserJson } from '../store/users';
import { UserAlergyJson } from '../store/alergies';
import { UserDeficiencyJson } from '../store/deficiencies';
import { RegistrationConnectionJson } from '../store/registration_connections';
import { ProductJson } from '../store/products';
import { formatToCurrency } from '../utils/functions';
import { CompanyJson } from '../store/companies';
import { RegistrationDocumentJson } from '../store/registration_documents';
import { RootState } from '../store/configureStore';

const RegistrationDetailsContainer = () => {
    const location = useLocation<LocationState>();
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        auth: { company },
    } = useSelector((state: RootState) => state);

    const [currentRegistration, setCurrentRegistration] = React.useState<RegDetailsCurrentRegistrationData | null>(
        null,
    );
    const [currentStudent, setCurrentStudent] = React.useState<RegDetailsCurrentStudentData | null>(null);
    const [renderReady, setRenderReady] = React.useState(false);

    const fetchCountryMethod = async (id: string) => {
      try {
        const country = await dispatch(
          FETCH_COUNTRY.request({
              id: id,
          }),
        );
        return country
      } catch (er) {
        dispatch(
          error(
            {
              message: 'Erro ao carregar país'
            }
          )
        )
      }
    }

    const fetchStateMethod = async (id: string) => {
      try {
        const state = await dispatch(
          FETCH_STATE.request({
              id
          })
        )
        return state
      } catch (err) {
        dispatch(
          error(
            {
              message: 'Erro ao carregar estaado'
            }
          )
        )

      }
    }

    const fetchCityMethod = async (id: string) => {
      try {
        const city = await dispatch(
          FETCH_CITY.request({
            id
          }),
        );
        return city
      } catch (err) {
        dispatch(
          error(
            {
              message: 'Erro ao carregar cidadae'
            }
          )
        )
      }
    }

    const fetchRaceMethod = async (id: string) => {
      try {
        const race = await dispatch(
          FETCH_RACE.request({
              id
          }),
        );
        return race
      } catch (err) {
        dispatch(
          error(
            {
              message: 'Erro o carregar raça'
            }
          )
        )
      }
    }

    const fetchEthnicityMethod = async(id: string) => {
      try {
        const ethnicity = await dispatch(
          FETCH_ETHNICITY.request({
              id
          })
      );
      return ethnicity
      } catch (err) {
        dispatch(
          error(
            {
              message: 'Erro o carregar raça'
            }
          )
        )
      }
    }


    const addRaceToData = async (data: RegDetailsCurrentStudentData) => {
      try {
        const race = await fetchRaceMethod(data.race_ids[0].toString())
        if(race) {
          const {
            data: {
                data: {
                    attributes: { name },
                },
            },
          } = race;
          data.race = name;
        }
      } catch (err) {
        dispatch(
          warning(
            {
              message: 'Erro ao adicionar dados de raça'
            }
          )
        )
      }
      return data
    }

    const addEthnicityToData = async (data: RegDetailsCurrentStudentData) => {
      try {
        const { ethnicity_ids } = data;
        const ethnicity = await fetchEthnicityMethod(ethnicity_ids[0].toString())
        if(ethnicity) {
          const {
            data: {
                data: {
                    attributes: { name },
                },
            },
            headers,
          } = ethnicity;
          dispatch(updateCredentialsRequest(headers));
          data.ethnicity = name;
        }
      } catch (error) {
        dispatch(
          warning(
            {
              message: 'Erro ao adicionar dados de etnia'
            }
          )
        )
      }
      return data
    }

    const addCityToData = async(data: RegDetailsCurrentStudentData) => {
      if(data.address){
        const {
          address: { city_id },
        } = data
        try {
          const city = await fetchCityMethod(city_id.toString())
          if(city) {
            const {
              data: {
                  data: {
                      attributes: { name },
                  },
              },
            } = city
            data.address.city = name;
          }
        } catch (error) {
          dispatch(
            warning(
              {
                message: 'Erro ao adicionar dados de cidade'
              }
            )
          )
        }
      }
      return data
    }

    const addStateToData = async(data: RegDetailsCurrentStudentData) => {
      if(data.address){
        const {
          address: { state_id },
        } = data
        try {
          const state = await fetchStateMethod(state_id.toString())
          if(state) {
            const {
              data: {
                  data: {
                      attributes: { name },
                  },
              },
            } = state;
            data.address.state = name;
          }
        } catch (error) {
          dispatch(
            warning(
              {
                message: 'Erro ao adicionar dados do estado'
              }
            )
          )
        }
      }
      return data
    }

    const addCountryToData = async(data: RegDetailsCurrentStudentData) => {
      if(data.address){
        const {
          address: { country_id },
        } = data
        try {
          const country = await fetchCountryMethod(country_id.toString())
          if(country){
          const {
            data: {
                data: {
                    attributes: { name },
                },
            },
          } = country;
          data.address.country = name;
          }
        } catch (error) {
          dispatch(
            warning(
              {
                message: 'Erro ao adicionar dados do país'
              }
            )
          )
        }
      }
      return data
    }


    const updateCurrentStudent = async (formattedData: RegDetailsCurrentStudentData) => {
        if (formattedData?.race_ids?.length) {
          formattedData = await addRaceToData(formattedData)
        }

        if (formattedData?.ethnicity_ids?.length) {
          formattedData = await addEthnicityToData(formattedData)
        }
        if (formattedData['address'] !== undefined) {
          const {
              address: { city_id, state_id, country_id },
          } = formattedData;
          if (city_id) {
            formattedData = await addCityToData(formattedData)
          }
          if (state_id) {
            formattedData = await addStateToData(formattedData)
          }
          if (country_id) {
            formattedData = await addCountryToData(formattedData)  
          }
        }
        setCurrentStudent(formattedData);
      }
    const updateCurrentRegistration = (res: FetchRegistrationResponse) => {
        const {
            data: {
                data: { attributes, id },
                included,
            },
        } = res;
        const includedRepresentatives = included.filter((item) => {
            return item.type === 'representatives' && item.attributes.registration_id === ~~id;
        }) as RepresentativeJson[];
        const representativesAttrs: { [key in FullRepresentativeName]: RegDetailsRepresentativeData[] } = {
            financial_representatives: [],
            pedagogical_representatives: [],
            pedagogical_financial_representatives: []
        };
        if (includedRepresentatives && includedRepresentatives.length) {
            const repAlternatives: FullRepresentativeName[] = [
                'financial_representatives',
                'pedagogical_representatives',
            ];
            repAlternatives.forEach((type: FullRepresentativeName) => {
                const findRepresentativeType = includedRepresentatives.filter((item) => {
                    return type.includes(item.attributes.kind);
                });
                if (findRepresentativeType && findRepresentativeType.length) {
                      const representativeAttr = findRepresentativeType.map((representative) => {
                        const findRepresentativeAccount = included.find(
                            (item) => item.type === 'accounts' && ~~item.id === representative.attributes.account_id,
                        ) as AccountJson;
                        const findRepresentativeUser = included.find(
                            (item) =>
                                item.type === 'users' && ~~item.id === findRepresentativeAccount.attributes.user_id,
                        ) as UserJson;
                        const getRepresentativeAddress = included.find(
                            (item) =>
                                item.type === 'addresses' && item.attributes.user_id === ~~findRepresentativeUser.id,
                        ) as AddressJson;
                        const representativeCity = included.find(item => {
                          return item.type === 'cities' && ~~item.id === getRepresentativeAddress.attributes.city_id
                        }) as CityJson
                        const representativeState = included.find(item => {
                          return item.type === 'states' && ~~item.id === getRepresentativeAddress.attributes.state_id
                        }) as StateJson
                        const representativeCountry = included.find(item => {
                          return item.type === 'countries' && ~~item.id === getRepresentativeAddress.attributes.country_id
                        }) as CountryJson
                        const representativeAddressAttrs = getRepresentativeAddress
                            ? {
                                  address: {
                                      ...getRepresentativeAddress['attributes'],
                                      id: getRepresentativeAddress['id'],
                                  },
                              }
                            : {};
                        const representantData = {
                            ...representative.attributes,
                            id: representative.id,
                            type: representative.type,
                            ...findRepresentativeUser.attributes,
                            ...representativeAddressAttrs,
                        } as RegDetailsRepresentativeData;
                        if (representantData['address'] !== undefined) {
                            const {
                                address: { city_id, state_id, country_id },
                            } = representantData;
                            if (city_id && representativeCity) {
                              const {
                                attributes: { name },
                              } = representativeCity;
                              representantData.address = {
                                ...representantData.address,
                                city: name,
                              };  
                            }
                            if (state_id && representativeState) {
                              const {
                                attributes: { name },
                              } = representativeState;
                              representantData.address = {
                                ...representantData.address,
                                state: name,
                              }
                            }
                            if (country_id && representativeCountry) {
                              const {
                                attributes: { name }
                              } = representativeCountry;
                              representantData.address = {
                                ...representantData.address,
                                country: name,  
                              }
                            }
                        }
                        return representantData;
                      })
                      if (representativeAttr.length) {
                        representativesAttrs[type] = [...representativeAttr];
                      }
                }
            });
        }

        const includedProducts = included.filter((item) => item.type === 'products') as ProductJson[];
        const mappedProducts = includedProducts.map((item) => {
            const payment = attributes.payment_simulation.selected_items.find(
                (x) => x.product.id === ~~item.id,
            )?.payment;
            const price = payment?.billings?.reduce((ac, x) => {
                return ac + (x?.initial_value || x?.value || 0);
            }, 0);

            const discount = payment?.billings?.reduce((ac, x) => {
                const initialValue = x.total.full;
                return ac + (x.total.final - initialValue);
            }, 0);
            const subtotal = payment?.total.final;
            return {
                kind: item.attributes.kind,
                price: formatToCurrency(price as number),
                discount: formatToCurrency(discount as number),
                subtotal: formatToCurrency(subtotal as number),
                description: item.attributes.description,
                id: item.id,
            };
        });

        const includedDocuments = included.filter(
            (item) => item.type === 'registration_documents',
        ) as RegistrationDocumentJson[];
        const mappedDocuments = includedDocuments.map((item) => {
            const requiredDoc = included.find(
                (x) => x.type === 'document_requirements' && item.attributes.document_requirement_id === ~~x.id,
            ) as DocumentRequirementJson;
            return {
                name: requiredDoc.attributes.document_name,
                url: item.attributes.file_url.url,
                filename: item.attributes.file_url.filename,
                id: item.id,
                date: format(new Date(item.attributes.sent_at), 'dd/MM/yyyy'),
            };
        });
        const includedCompany = included.find((item) => item.type === 'companies') as CompanyJson;

        const formattedData = {
            ...attributes,
            id,
            company_id: includedCompany.id,
            representatives: { ...representativesAttrs },
            products: [...mappedProducts],
            documents: [...mappedDocuments],
        };
        setCurrentRegistration(formattedData);
        const findUser = included.find(
            (item) => item.type === 'users' && ~~item.id === attributes.user_data.id,
        ) as UserJson;
        if (findUser) {
            const includedAddress = included.find(
                (item) => item.type === 'addresses' && ~~findUser.id === item.attributes.user_id,
            ) as AddressJson;
            const addressProps = includedAddress
                ? {
                      address: {
                          ...includedAddress['attributes'],
                          id: includedAddress['id'],
                      },
                  }
                : { address: undefined };
            const filteredAlergies = included.filter((item) => item.type === 'user_alergies') as UserAlergyJson[];
            const includedAlergies = filteredAlergies.map((item) => {
                return {
                    alergy: item.attributes.alergy_name,
                    description: item.attributes.description,
                    alergyId: item.attributes.alergy_id,
                    stored: true,
                };
            });
            const filteredDeficiencies = included.filter(
                (item) => item.type === 'user_deficiencies',
            ) as UserDeficiencyJson[];
            const includedDeficiencies = filteredDeficiencies.map((item) => {
                return {
                    deficiency: item.attributes.deficiency_name,
                    description: item.attributes.description,
                    deficiencyId: item.attributes.deficiency_id,
                    stored: true,
                };
            });
            const filteredConnections = included.filter(
                (item) => item.type === 'registration_connections',
            ) as RegistrationConnectionJson[];
            const userConnections = filteredConnections.map((item) => {
                const kindLabel = userConnectionTypes.find((kind) => kind.value === item.attributes.kind)?.label;
                return {
                    kind: item.attributes.kind,
                    kindLabel: kindLabel,
                    connectionId: item.attributes.connection_id,
                    connectionLabel: item.attributes.connection_name,
                    stored: true,
                };
            });
            const formattedData = {
                ...findUser.attributes,
                id: findUser.id,
                alergies: includedAlergies,
                deficiencies: includedDeficiencies,
                user_connections: userConnections,
                ...addressProps,
            };
            updateCurrentStudent(formattedData);
        }
    };

    const fetchAndUpdateRegistrationMethod = async (id: string) => {
      try {
        const registration = await dispatch(
          FETCH_REGISTRATION.request({
              id: id,
              params: {
                  filters: {
                      include:
                          'account.company,contracts,representatives,representatives.account.user,representatives.account.user.address,representatives.account.user.address.city,representatives.account.user.address.state,representatives.account.user.address.country,registration_connections,account.user.address,account.user.user_alergies,account.user.user_deficiencies,registration_products,products,account,account.user,registration_documents,products.document_requirements,emergency_contacts',
                  },
              },
          }),
        );
        updateCurrentRegistration(registration)
      } catch (err) {
        dispatch(
          error({
              message: 'Erro no carregamento da matrícula',
          }),
        );
        history.push(Paths.SECRETARY);
      }
    }

    React.useEffect(() => {
        if (location?.state?.registration_id) {
            fetchAndUpdateRegistrationMethod(location?.state?.registration_id.toString())
        } else {
            history.push(Paths.HOME);
        }
    }, []);

    React.useEffect(() => {
        if (currentRegistration !== null && currentRegistration.company_id !== company) {
            history.push(Paths.SECRETARY);
        }
    }, [company]);

    React.useEffect(() => {
        if (currentStudent !== null && currentRegistration !== null) {
            setRenderReady(true);
        }
    }, [currentStudent, currentRegistration]);

    if (renderReady) {
        return (
            <RegistrationDetailsView
                currentStudent={currentStudent as RegDetailsCurrentStudentData}
                currentRegistration={currentRegistration as RegDetailsCurrentRegistrationData}
            />
        );
    }

    return <Loading />;
};

export default RegistrationDetailsContainer;
