/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, PeriodTypes, WithRequestParams } from '../../utils/constants'
import { KtwelveJson } from '../ktwelves'
import { CompanyJson } from '../companies'

export interface ClassroomAttrs {
  name: string
  year: number
  period: PeriodTypes
  company_id: number
  ktwelve_degree_id: number
}
export type ClassroomJson = JsonFormat<'classrooms', ClassroomAttrs>

export type FetchClassroomsResponse = JsonResponseFormat<ClassroomJson[], (KtwelveJson|CompanyJson)[]>
export type FetchClassroomResponse = JsonResponseFormat<ClassroomJson, KtwelveJson[]>
export type FetchClassroomsPayload = WithRequestParams
export interface FetchClassroomPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateClassroomPayload {
  data: object
}

export interface UpdateClassroomPayload extends CreateClassroomPayload {
  id: string
}

export const FETCH_CLASSROOMS = createPromiseAction('classrooms: FETCH_CLASSROOMS')<FetchClassroomsPayload, FetchClassroomsResponse, any>()
export const FETCH_CLASSROOM  = createPromiseAction('classrooms: FETCH_CLASSROOM')<FetchClassroomPayload, FetchClassroomResponse, any>()
export const CREATE_CLASSROOM = createPromiseAction('classrooms: CREATE_CLASSROOM')<CreateClassroomPayload, FetchClassroomResponse, any>()
export const UPDATE_CLASSROOM = createPromiseAction('classrooms: UPDATE_CLASSROOM')<UpdateClassroomPayload, FetchClassroomResponse, any>()