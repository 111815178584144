/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, DiscountTypes, WithRequestParams } from '../../utils/constants'

export interface SubsidyAttrs {
  active_ends_at: string
  active_starts_at: string
  changeable: boolean
  description: string
  discount_type_id: number
  discount: number
  include_registration: boolean
  kind: DiscountTypes
  name: string
  portions: number[]
  product_id: number
  status: string
}

export interface DiscountTypeAttrs {
    company_id: number
    name: string
    description: string
    suggested_kind: DiscountTypes
    suggested_discount: number
}
export type SubsidyJson = JsonFormat<'subsidies', SubsidyAttrs>
export type DiscountTypeJson = JsonFormat<'discount_types', DiscountTypeAttrs>
export type FetchSubsidiesResponse = JsonResponseFormat<SubsidyJson[], (DiscountTypeJson)[]>

export type FetchSubsidiesPayload = WithRequestParams
export const FETCH_SUBSIDIES = createPromiseAction('subsidies: FETCH_SUBSIDIES')<FetchSubsidiesPayload, FetchSubsidiesResponse, any>()
