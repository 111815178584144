import React from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import ProductFamiliesView from '../components/view/Secretary/ProductFamiliesView';
import { FETCH_PRODUCT_FAMILIES } from '../store/product_families';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { ProductFamilyAttributes, defaultPagination, PaginationType } from '../utils/constants';

const ProductFamiliesContainer = () => {
  const dispatch = useDispatch();

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [productFamilies, setProductFamilies] = React.useState<ProductFamilyAttributes[]>([]);
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [descriptionFilterValue, setDescriptionFilterValue] = React.useState('');
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);

  const fetchProductFamiliesMethod = React.useCallback(
    async ({ newPagination = pagination }: { newPagination?: PaginationType }) => {
      setLoading(true);
      try {
        const productFamilies = await dispatch(
          FETCH_PRODUCT_FAMILIES.request({
            params: {
              filters: {
                'q[name_cont]': nameFilterValue,
                'q[description_cont]': descriptionFilterValue,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
              },
            },
          }),
        );
        const {
          data: { data, meta },
        } = productFamilies;
        const formattedProductFamilies = data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setProductFamilies(formattedProductFamilies);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar famílias de produtos',
          }),
        );
      }
    },
    [pagination, nameFilterValue, descriptionFilterValue],
  );

  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchProductFamiliesMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchProductFamiliesMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  React.useEffect(() => {
    fetchProductFamiliesMethod({});
  }, []);

  return (
    <ProductFamiliesView
      productFamilies={productFamilies}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      descriptionFilterValue={descriptionFilterValue}
      setDescriptionFilterValue={setDescriptionFilterValue}
      nameFilterValue={nameFilterValue}
      setNameFilterValue={setNameFilterValue}
      fetchProductFamiliesMethod={fetchProductFamiliesMethod}
    />
  );
};

export default ProductFamiliesContainer;
