import { once } from 'lodash'
import { AppDispatch } from './configureStore'
import { Middleware, AnyAction } from '@reduxjs/toolkit'

import axiosInstance from '../utils/http'
import { SIGN_OUT as logout } from './auth'

const clearUserOnAuthErrors = once(next => {
  axiosInstance.interceptors.response.use(
    response => response,
    /**
     * This is a central point to handle all
     * error messages generated by HTTP
     * requests
     */
    error => {
      const { response } = error
      /**
       * If token is either expired, not provided or invalid
       * then redirect to login. On server side the error
       * messages can be changed on app/Providers/EventServiceProvider.php
       */
      const isUnauthorizedError = [401, 400].indexOf(response?.status) > -1
      const isAuthEndpoint = error.config.url.includes('auth')

      if (isUnauthorizedError && !isAuthEndpoint) next(logout.request())

      return Promise.reject(error)
    }
  )
})

export const onAuthErrors: Middleware = () => (next: AppDispatch) => (action: AnyAction) => {
  clearUserOnAuthErrors(next)
  next(action)
}

/**
 * This set the token on two moments: On localStorage rehydrate
 * and on user sign in.
 * TODO: Set the token on signup too
 */
export const addTokenToRequest: Middleware = () => (next: AppDispatch) => (action: AnyAction) => {
  const isStorageRehydrate = action.type === 'persist/REHYDRATE'
  const isUserSignedIn = action.type === 'auth:SIGN_IN_SUCCESSFUL'
  const isLoggedIn = action.payload?.auth?.isLogged

  if (isStorageRehydrate && action.payload && action.payload.headers) {
    axiosInstance.defaults.headers.common['access-token'] =
      action.payload.headers['access-token']
    axiosInstance.defaults.headers.common['token-type'] =
      action.payload.headers['token-type']
    axiosInstance.defaults.headers.common.client = action.payload.headers.client
    axiosInstance.defaults.headers.common.client = action.payload.headers.client
    axiosInstance.defaults.headers.common.uid = action.payload.headers.uid
  }

  if (isStorageRehydrate && isLoggedIn) {
    axiosInstance.defaults.headers.common['access-token'] =
      action.payload.auth?.credentials['access-token']
    axiosInstance.defaults.headers.common['token-type'] =
      action.payload.auth?.credentials['token-type']
    axiosInstance.defaults.headers.common.client =
      action.payload.auth?.credentials.client
    axiosInstance.defaults.headers.common.uid =
      action.payload.auth?.credentials.uid
  }
  if (
    (isUserSignedIn && action.payload && action.payload.headers) ||
    (action.payload && action.payload.headers)
  ) {
    axiosInstance.defaults.headers['access-token'] =
      action.payload.headers['access-token']
    axiosInstance.defaults.headers['token-type'] =
      action.payload.headers['token-type']
    axiosInstance.defaults.headers.client = action.payload.headers.client
    axiosInstance.defaults.headers.uid = action.payload.headers.uid
  }

  next(action)
}
