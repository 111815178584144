/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ProductAttributes, RegistrationProductSubsidyAttributes, SubsidiesAttributes, colors } from '../../utils/constants';
import { makeStyles } from '@mui/styles';
import {  calculateDiscount, formatToCurrency, getPortionMonth, parseText } from '../../utils/functions';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles(() => ({
  table: {
    '& tbody > tr:nth-child(even)': {
      backgroundColor: `${colors.darkGrayBlue} !important`,
    },
    '& tbody > tr:nth-child(odd)': {
      backgroundColor: `${colors.grayBlue} !important`,
    },
    '& .include': {
      display: 'flex',
      marginLeft: 'auto',
      marginBottom: '1rem',
    },
    '& tfoot': {
      background: 'white',
      '& td': {
        fontWeight: 'bold',
        fontSize: '1rem',
      },
      '& td:last-of-type': {
        color: colors.blue,
      },
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `5px solid ${colors.lightBlue}`,
      },
      '& th': {
        color: colors.darkGrayBlue,
      },
      '& td': {
        color: colors.darkBlue,
        '& svg': {
          color: colors.darkGrayBlue,
          cursor: 'pointer',
        },
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none',
      },
    },
  },
}));

const RegistrationProductSubsidiesTable = ({
  registration_product_subsidies,
  subsidies,
  product
}: {
  registration_product_subsidies: RegistrationProductSubsidyAttributes[];
  subsidies: SubsidiesAttributes[]
  product: ProductAttributes
}) => {
  const classes = useStyles();
  return (
    <Table className={classes.table} size='small' aria-label='payment-simulation'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Nome do desconto</TableCell>
          <TableCell align='center'>Tipo do desconto</TableCell>
          <TableCell align='center'>Parcelas</TableCell>
          <TableCell align='center'>Desconto por parcela</TableCell>
          <TableCell align='center'>Valor total do desconto</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {registration_product_subsidies.map((rps) => {
        const subsidy = subsidies.find(subsidy => ~~subsidy.id === rps.subsidy_id) as SubsidiesAttributes
        const subsidyKindLabel = subsidy.kind === 'full_value' ? 'Valor cheio' : 'Porcentagem'
        const calculated_discount= rps.portions.reduce((acc, portion) => {
          const value = portion === 0 ? product.registration_fee : product.price
          return acc + calculateDiscount(subsidy.kind, rps.discount, value)
        }, 0)
        const translatedPortions = rps.portions.map(item => {
          if(item === 0){
            return 'Entrada'
          }
          return getPortionMonth(item)
        }).join(',')
        const discountValue = subsidy.kind === 'full_value' ? formatToCurrency(rps.discount) : `${rps.discount}%`
        return (
          <React.Fragment key={rps.id}>
            <TableRow>
              <TableCell align='center'>{subsidy.name}</TableCell>
              <TableCell align='center'>{subsidyKindLabel}</TableCell>
              <TableCell align='center'>
                <Tooltip title={translatedPortions}>
                  <span>{parseText(translatedPortions, 30)}</span>
                </Tooltip>
              </TableCell>
              <TableCell align='center'>{discountValue}</TableCell>
              <TableCell align='center'>
                {formatToCurrency(calculated_discount)}
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </TableBody>
    </Table>
  );
};

export default RegistrationProductSubsidiesTable;
