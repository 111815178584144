import React from 'react'
import { SvgIcon } from "@mui/material"

const ClipboardIcon = (props: any) => (
    <SvgIcon viewBox='0 0 10 13' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M2.57387 7.51898C2.49717 7.51898 2.42363 7.48849 2.3694 7.43425C2.31516 7.38002 2.28467 7.30648 2.28467 7.22978C2.28467 7.15308 2.31516 7.07947 2.3694 7.02523C2.42363 6.97099 2.49717 6.94057 2.57387 6.94057H7.20111C7.27781 6.94057 7.35135 6.97099 7.40559 7.02523C7.45982 7.07947 7.49031 7.15308 7.49031 7.22978C7.48883 7.30601 7.45788 7.37871 7.40396 7.43263C7.35005 7.48655 7.27735 7.5175 7.20111 7.51898H2.57387ZM2.57387 5.20536C2.49717 5.20536 2.42363 5.17487 2.3694 5.12063C2.31516 5.06639 2.28467 4.99286 2.28467 4.91616C2.28467 4.83945 2.31516 4.76585 2.3694 4.71161C2.42363 4.65737 2.49717 4.62695 2.57387 4.62695H7.20111C7.27781 4.62695 7.35135 4.65737 7.40559 4.71161C7.45982 4.76585 7.49031 4.83945 7.49031 4.91616C7.48883 4.99239 7.45788 5.06509 7.40396 5.11901C7.35005 5.17293 7.27735 5.20388 7.20111 5.20536H2.57387ZM2.57387 9.8326C2.49717 9.8326 2.42363 9.80211 2.3694 9.74787C2.31516 9.69364 2.28467 9.6201 2.28467 9.5434C2.28467 9.4667 2.31516 9.39309 2.3694 9.33885C2.42363 9.28462 2.49717 9.25419 2.57387 9.25419H7.20111C7.27781 9.25419 7.35135 9.28462 7.40559 9.33885C7.45982 9.39309 7.49031 9.4667 7.49031 9.5434C7.48883 9.61964 7.45788 9.69233 7.40396 9.74625C7.35005 9.80017 7.27735 9.83112 7.20111 9.8326H2.57387Z' />
      <path d='M7.49033 1.15681H7.80848C8.34539 1.15681 8.8603 1.37004 9.23995 1.74969C9.6196 2.12934 9.8329 2.64432 9.8329 3.18123V10.1221C9.8329 10.659 9.6196 11.1739 9.23995 11.5536C8.8603 11.9332 8.34539 12.1465 7.80848 12.1465H2.02443C1.48752 12.1465 0.97254 11.9332 0.592888 11.5536C0.213236 11.1739 8.31678e-06 10.659 8.31678e-06 10.1221V3.18123C-0.000755144 2.91516 0.0510514 2.65151 0.152518 2.40555C0.253984 2.15959 0.403057 1.93613 0.591193 1.748C0.77933 1.55986 1.00286 1.41079 1.24882 1.30932C1.49478 1.20785 1.75836 1.15605 2.02443 1.15681H2.34251C2.40918 0.830195 2.58664 0.536579 2.84487 0.325777C3.1031 0.114974 3.42629 -6.80062e-05 3.75964 3.01607e-08H6.07326C6.40661 -6.80062e-05 6.72973 0.114974 6.98797 0.325777C7.2462 0.536579 7.42366 0.830195 7.49033 1.15681ZM2.34251 1.73522H2.02443C1.64092 1.73522 1.27309 1.88753 1.00191 2.15871C0.730729 2.42989 0.578414 2.79772 0.578414 3.18123V10.1221C0.578414 10.5056 0.730729 10.8734 1.00191 11.1445C1.27309 11.4157 1.64092 11.5681 2.02443 11.5681H7.80848C8.19199 11.5681 8.55975 11.4157 8.83093 11.1445C9.10211 10.8734 9.25449 10.5056 9.25449 10.1221V3.18123C9.25449 2.79772 9.10211 2.42989 8.83093 2.15871C8.55975 1.88753 8.19199 1.73522 7.80848 1.73522H7.49033C7.42366 2.06183 7.2462 2.35538 6.98797 2.56618C6.72973 2.77698 6.40661 2.89209 6.07326 2.89203H3.75964C3.42629 2.89209 3.1031 2.77698 2.84487 2.56618C2.58664 2.35538 2.40918 2.06183 2.34251 1.73522ZM3.75964 0.578405C3.52954 0.578405 3.30885 0.669809 3.14615 0.832517C2.98344 0.995225 2.89203 1.21591 2.89203 1.44601C2.89203 1.67612 2.98344 1.89673 3.14615 2.05944C3.30885 2.22215 3.52954 2.31362 3.75964 2.31362H6.07326C6.30337 2.31362 6.52405 2.22215 6.68676 2.05944C6.84947 1.89673 6.94087 1.67612 6.94087 1.44601C6.94087 1.21591 6.84947 0.995225 6.68676 0.832517C6.52405 0.669809 6.30337 0.578405 6.07326 0.578405H3.75964Z' />
    </SvgIcon>
  )

export default ClipboardIcon