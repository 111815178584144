import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface CourseAttrs {
  name: string
  description: string
  code: string
}
export type CourseJson = JsonFormat<'courses', CourseAttrs>
export type FetchCoursesResponse = JsonResponseFormat<CourseJson[]>
export type FetchCourseResponse = JsonResponseFormat<CourseJson>
export type FetchCoursesPayload = WithRequestParams

export interface FetchCoursePayload extends WithRequestParams {
  id: string | number,
}
export interface CreateCoursePayload {
  data: object
}

export interface UpdateCoursePayload extends CreateCoursePayload {
  id: string
}

export const FETCH_COURSES = createPromiseAction('courses: FETCH_COURSES')<FetchCoursesPayload, FetchCoursesResponse, any>()
export const FETCH_COURSE  = createPromiseAction('courses: FETCH_COURSE')<FetchCoursePayload, FetchCourseResponse, any>()
export const CREATE_COURSE = createPromiseAction('courses: CREATE_COURSE')<CreateCoursePayload, FetchCourseResponse, any>()
export const UPDATE_COURSE = createPromiseAction('courses: UPDATE_COURSE')<UpdateCoursePayload, FetchCourseResponse, any>()