import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const coordinatorsEndpoint = '/coordinators'

export const fetchCoordinators = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${coordinatorsEndpoint}?${queryParams}`)
}

export const fetchCoordinator = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${coordinatorsEndpoint}/${id}?${queryParams}`)
}

export const createCoordinator = (data: object) => {
  return http.post(`${coordinatorsEndpoint}`, data)
}

export const updateCoordinator = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${coordinatorsEndpoint}/${id}`, data)
}

export const deleteCoordinator = ({ id }: { id: string | number } ) => {
  return http.delete(`${coordinatorsEndpoint}/${id}`)
}