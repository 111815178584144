/* eslint-disable camelcase */
import { createAction, createReducer } from '@reduxjs/toolkit'
import { History } from 'history'
import { CompanyAttributes, RoleTypes } from '../../utils/constants';
import { withPayloadType } from '../../utils/functions'
import { createPromiseAction } from 'redux-saga-promise-actions';

export interface SignInCredentials {
    token: string;
    history: History;
}

export interface SetProfileType {
  id: string;
  account_id: string;
  label: string;
  role: RoleTypes
}

export interface AuthCredentials {
    'access-token': string;
    client?: string;
    uid?: string;
    'token-type'?: string;
}
export interface UpdateCredentialsSuccess {
  headers: AuthCredentials
}

export interface UserData {
    name: string;
    email?: string;
    phone?: string | null;
    fractal_id?: number;
    gender: null | string;
    marital_status: null | string;
    birthdate: null | string | Date;
    nationality: null | string;
    document_type: null | string;
    document_number: null | string;
    profiles: string[];
}

export interface SignSuccessPayload extends UserData {
    id: string;
}

export interface ProfileOption {
  id: string, account_id: string, label: string, role: RoleTypes
}

export interface SetCompanySuccessPayload {
  company_descendants: CompanyAttributes[]
  company_ancestors: CompanyAttributes[]
  company_id: string
  profile: ProfileOption
}

export interface SetCompanyRequestPayload {
  company: CompanyAttributes
  profile: ProfileOption
}


export const SIGN_IN = createPromiseAction('auth:SIGN_IN')<SignInCredentials, SignSuccessPayload, undefined>()
export const SIGN_OUT = createPromiseAction('auth:SIGN_OUT')<undefined, undefined, undefined>()

export const COMPLETE_SIGN_IN = createAction('auth:COMPLETE_SIGN_IN',withPayloadType<SignSuccessPayload>())
export const SIGN_IN_REQUEST = createAction('auth:SIGN_IN_REQUEST', withPayloadType<SignSuccessPayload>())
export const SIGN_IN_SUCCESS = createAction('auth:SIGN_IN_SUCCESS', withPayloadType<SignSuccessPayload>())
export const SIGN_IN_FAILURE = createAction('auth:SIGN_IN_FAILURE', withPayloadType<unknown>())
export const SIGN_OUT_REQUEST = createAction('auth:SIGN_OUT_REQUEST')
export const SIGN_OUT_SUCCESS = createAction('auth:SIGN_OUT_SUCCESS')
export const SIGN_OUT_FAILURE = createAction('auth:SIGN_OUT_FAILURE')

export const GET_COMPANY_INFO = createAction('auth:GET_COMPANY_INFO')

export const updateCredentialsRequest = createAction(
  'auth: UPDATE_CREDENTIALS_REQUEST',
  withPayloadType<AuthCredentials>()
)
export const updateCredentialsSuccess = createAction(
  'auth: UPDATE_CREDENTIALS_SUCCESS',
  withPayloadType<UpdateCredentialsSuccess>()
)

export const updateCredentialsFailure = createAction('auth: UPDATE_CREDENTIALS_FAILURE')

export const setProfile = createAction('auth: SET_PROFILE', withPayloadType<SetProfileType>())
export const SET_PROFILE = createPromiseAction('auth: SET_PROFILE')<ProfileOption, ProfileOption, any>()
export const SET_COMPANY = createPromiseAction('auth: SET_COMPANY')<SetCompanyRequestPayload, SetCompanySuccessPayload, any>()
export const SET_COMPANY_REQUEST = createAction('auth: SET_COMPANY_REQUEST')
export const SET_COMPANY_SUCCESS = createAction('auth: SET_COMPANY_SUCCESS')
export const SET_COMPANY_FAILURE = createAction('auth: SET_COMPANY_FAILURE')
export const SET_COMPANY_RELATIVES = createAction('auth:SET_COMPANY_RELATIVES', withPayloadType<SetCompanySuccessPayload>())



interface StateType {
    user: object;
    credentials: object;
    profile: {id: string, label: string, account_id: string} | null;
    company: null | string;
    company_descendants: CompanyAttributes[]
    company_ancestors: CompanyAttributes[]
    isLogged: boolean;
    isFetching: boolean;
    isError: boolean;
    errorMessage: string;
}

const initialState: StateType = {
  user: {},
  credentials: {},
  profile: null,
  company: null,
  company_descendants: [],
  company_ancestors: [],
  isLogged: false,
  isFetching: false,
  isError: false,
  errorMessage: ''

}
  const auth = createReducer(initialState, (builder) => {
    builder
      .addCase(
        SIGN_IN_REQUEST,
        (state: StateType): StateType => ({
          ...state,
          isFetching: true,
          isLogged: false,
          isError: false,
          errorMessage: ''
        })
      ).addCase(
        SIGN_IN_SUCCESS,
        (state: StateType, action: { payload: UserData }): StateType => ({
          ...state,
          isFetching: false,
          isLogged: true,
          isError: false,
          user: action.payload,
          errorMessage: ''
        })
      ).addCase(
        SIGN_IN_FAILURE,
        (state: StateType, action: { payload: any }): StateType => ({
          ...state,
          isFetching: false,
          isLogged: false,
          user: {},
          isError: true,
          errorMessage: action.payload
        })
      ).addCase(
        SIGN_OUT_SUCCESS,
        (): StateType => ({
          ...initialState
        })
      ).addCase(
        updateCredentialsSuccess,
        (state: StateType, action: { payload: any }): StateType => ({
          ...state,
          credentials: action.payload.headers
        })
      )
      .addCase(
        setProfile,
        (state: StateType, action: { payload: any }): StateType => ({
          ...state,
          profile: action.payload,
        })
      ).addCase(
        SET_COMPANY_RELATIVES,
        (state: StateType, action: { payload: SetCompanySuccessPayload }): StateType => ({
          ...state,
          company_descendants: action.payload.company_descendants,
          company_ancestors: action.payload.company_ancestors,
          company: action.payload.company_id,
          profile: action.payload.profile
        })
      )
  })


export default auth
