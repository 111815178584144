import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_TEACHER, FETCH_TEACHERS } from '../store/teachers';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { TeacherAttributes, defaultPagination, PaginationType } from '../utils/constants';
import { RootState } from '../store/configureStore';
import { get_company_search } from '../utils/functions';
import TeachersView from '../components/view/Pedagogic/TeachersView';

const TeachersContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants },
    account: { companies },
  } = state;

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [teachers, setTeachers] = React.useState<TeacherAttributes[]>([]);
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const fetchTeachersMethod = React.useCallback(
    async ({
      newPagination = pagination,
      company_filter = companyFilterValue,
    }: {
      newPagination?: PaginationType;
      company_filter?: string;
    }) => {
      setLoading(true);
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company,
      });
      try {
        const teachers = await dispatch(
          FETCH_TEACHERS.request({
            params: {
              filters: {
                'q[account_user_name_cont]': nameFilterValue,
                'q[account_company_id_in]': companies_array,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
              },
            },
          }),
        );
        const {
          data: { data, meta },
        } = teachers;
        const formattedTeachers = data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setTeachers(formattedTeachers);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar turmas',
          }),
        );
      }
    },
    [pagination, nameFilterValue, company, companyFilterValue],
  );

  const deleteTeacher = React.useCallback(
    async (
      id: string,
      {
        setLoading,
        handleClose,
      }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    ) => {
      try {
        setLoading(true);
        await dispatch(
          DELETE_TEACHER.request({
            id,
          }),
        );
        setLoading(false);
        dispatch(
          success({
            message: 'Coordenador removido com sucesso',
          }),
        );
        fetchTeachersMethod({});
        handleClose();
      } catch (e) {
        handleClose();
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao remover coordenador',
          }),
        );
      }
    },
    [],
  );
  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchTeachersMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchTeachersMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const loadAssets = React.useCallback(async () => {
    await fetchTeachersMethod({});
  }, [company]);

  React.useEffect(() => {
    loadAssets();
  }, []);

  return (
    <TeachersView
      teachers={teachers}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      nameFilterValue={nameFilterValue}
      setNameFilterValue={setNameFilterValue}
      fetchTeachersMethod={fetchTeachersMethod}
      setCompanyFilterValue={setCompanyFilterValue}
      companyFilterValue={companyFilterValue}
      deleteTeacher={deleteTeacher}
    />
  );
};

export default TeachersContainer;
