import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const companiesEndpoint = '/companies'

const multipartInstance = http
multipartInstance.defaults.headers.common['Content-Type'] =
    'multipart/form-data; boundary=----------XnJLe9ZIbbGUYtzPQJ16u1'

export const fetchCompanies = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${companiesEndpoint}?${queryParams}`)
}

export const fetchCompany = ({ id, params }: { id: number | string, params?: RequestParams }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${companiesEndpoint}/${id}?${queryParams}`)
}

export const createCompany = (params: FormData | object) => {
  const instance = typeof params === 'object' ? http : multipartInstance

  return instance.post(`${companiesEndpoint}`, params)
}
export const updateCompany = ({ params, id }: { params: FormData | object, id: string|number }) => {
  const instance = typeof params === 'object' ? http : multipartInstance
  return instance.put(`${companiesEndpoint}/${id}`, params)
}

export const deleteCompanyLogo = ({ id }: { id: string|number }) => {
  return http.delete(`${companiesEndpoint}/${id}/logo`)
}

export const deleteCompanyEmailHeaderBanner = ({ id }: { id: string|number }) => {
  return http.delete(`${companiesEndpoint}/${id}/email_header_banner`)
}

export const importCompanyData = ({ id, data } : { id: string | number, data: object }) => {
  return http.post(`${companiesEndpoint}/${id}/import`, data)
}

export const exportCompanyRegistrations = ({data} : { data: object }) => {
  return http.post(`${companiesEndpoint}/export_registrations`, data)
}

export const exportCompanyRepresentatives = ({data} : { data: object }) => {
  return http.post(`${companiesEndpoint}/export_representatives`, data)
}

export const exportInvoices = async ({data} : { data: object }) => {
  return http.post(`${companiesEndpoint}/export_invoices`, data)
}

