import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const registrationEndpoint = '/registrations'

const multipartInstance = http
multipartInstance.defaults.headers.common['Content-Type'] =
    'multipart/form-data; boundary=----------XnJLe9ZIbbGUYtzPQJ16u1'

export const createRegistration = (params: FormData | object) => {
  const instance = typeof params === 'object' ? http : multipartInstance
  return instance.post(`${registrationEndpoint}`, params)
}

export const updateRegistration = ({ params, id }: { params: FormData | object, id: string|number }) => {
  const instance = params instanceof FormData ? multipartInstance : http
  return instance.put(`${registrationEndpoint}/${id}`, params)
}

export const fetchRegistrations = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${registrationEndpoint}?${queryParams}`)
}

export const fetchRegistration = ({ id, params }: { id: string|number, params?: RequestParams }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${registrationEndpoint}/${id}?${queryParams}`)
}

export const deleteRegistration = ({id}: {id: string}) => {
  return http.delete(`${registrationEndpoint}/${id}`)
}

export const fetchStudentMissingRegistrations = ({ product_id, params }: { params?: RequestParams, product_id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${registrationEndpoint}/students_missing/${product_id}?${queryParams}`)
}