import React from 'react';
import { makeStyles } from '@mui/styles';

import { DefaultOptionType, PaginationType, Role } from '../../utils/constants';
import SelectComponent from '../input/form/select';
import InputComponent from '../input/form/input';
import DatePickerComponent from '../input/form/datepicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';

const useStyles = makeStyles(
  (): {
    filters: any;
    subtitle: any;
    container: any;
  } => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    subtitle: {
      textAlign: 'start',
      marginBottom: '1rem',
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '1rem',
      '& .inputArea': {
        width: '10rem !important',
        paddingRight: '3rem',
      },
    },
  }),
);

const RegistrationViewFilters = ({
  searchForOptions,
  companyFilterValue,
  filterOption,
  setFilterOption,
  fetchRegistrationsMethod,
  pagination,
  defaultPagination,
  studentFilter,
  setStudentFilter,
  startsAtFilter,
  setStartsAtFilter,
  setCompanyFilterValue,
}: {
  companyFilterValue: string;
  searchForOptions: DefaultOptionType[];
  filterOption: string;
  setFilterOption: React.Dispatch<React.SetStateAction<string>>;
  fetchRegistrationsMethod: ({
    newPagination,
    closed,
    startsAt,
    company_filter,
  }: {
    newPagination?:
      | {
          pageCount: number;
          totalCount: number;
          pageSize: number;
          pageNumber: number;
        }
      | undefined;
    status?: string | undefined;
    startsAt?: Date | null | undefined;
    closed?: boolean | undefined;
    company_filter?: string | undefined;
    student_filter?: string
    filter_option?: string
  }) => Promise<void>;
  pagination: PaginationType;
  defaultPagination: PaginationType;
  studentFilter: string;
  setStudentFilter: React.Dispatch<React.SetStateAction<string>>;
  startsAtFilter: Date | null;
  setStartsAtFilter: React.Dispatch<React.SetStateAction<Date | null>>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  return (
    <div className={classes.container}>
      <span className={classes.subtitle}>Filtros</span>
      <div className={classes.filters}>
        {is_above_school_director && (
          <SelectComponent
            placeholder='Selecionar Unidade'
            small
            options={companies_options}
            clearFieldFunction={() => {
              setCompanyFilterValue('');
              fetchRegistrationsMethod({
                newPagination: { ...defaultPagination },
                company_filter: '',
              });
            }}
            clearable
  
            input={{
              value: companyFilterValue,
              onChange: (e: any) => {
                setCompanyFilterValue(e.target.value);
                fetchRegistrationsMethod({
                  newPagination: { ...defaultPagination },
                  company_filter: e.target.value,
                });
              },
            }}
          />
        )}
        <SelectComponent
          placeholder='Buscar por'
          small
          clearFieldFunction={() => {
            setFilterOption('');
            setStudentFilter('')
            fetchRegistrationsMethod({
              newPagination: { ...defaultPagination },
              student_filter: '',
              filter_option: ''
            });
          }}
          clearable
          options={searchForOptions}
          input={{
            value: filterOption,
            onChange: (e: any) => {
              let value = ''
              if(e?.target?.value) {
                value = e.target.value
              }
              setFilterOption(value);
            },
          }}
        />
        <InputComponent
          placeholder={`Buscar por ${searchForOptions?.find((item) => item.value === filterOption)?.label || ''}`}
          search
          small
          onSearch={() => {
            fetchRegistrationsMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
          input={{
            value: studentFilter,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              let value = ''
              if(e?.target?.value) {
                value = e.target.value
              }
              setStudentFilter(value);
            },
          }}
        />
        <DatePickerComponent
          placeholder='Ano letivo'
          small
          datePickerProps={{
            views: ['year'],
            inputFormat: 'yyyy',
            allowSameDateSelection: true,
          }}
          clearFieldFunction={() => {
            setStartsAtFilter(null);
            fetchRegistrationsMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
              startsAt: null,
            });
          }}
          input={{
            value: startsAtFilter,
            name: 'year',
            onChange: (e: Date) => {
              const value = new Date(e.getFullYear(), 0, 1, 1);
              setStartsAtFilter(value);
              fetchRegistrationsMethod({
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                startsAt: value,
              });
            },
          }}
        />
      </div>
    </div>
  );
};

export default RegistrationViewFilters;
