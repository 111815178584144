/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
export interface CityAttrs{
  name: string,
  state_id: number
}

export type CityJson = JsonFormat<'cities', CityAttrs>

export type FetchCitiesResponse = JsonResponseFormat<CityJson[]>
export type FetchCityResponse = JsonResponseFormat<CityJson>

export type FetchCitiesPayload = WithRequestParams

export interface FetchCityPayload extends WithRequestParams {
    id: string | number,
  }

export const FETCH_CITIES = createPromiseAction('cities: FETCH_CITIES')<FetchCitiesPayload, FetchCitiesResponse, any>()

export const FETCH_CITY = createPromiseAction('cities: FETCH_CITY')<FetchCityPayload, FetchCityResponse, any>()
