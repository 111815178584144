import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_REGISTRATION_CLOSURE_REASONS,
  FetchRegistrationClosureReasonsPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'
import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRegistrationClosureReasons (action: DefaultAction<FetchRegistrationClosureReasonsPayload, string>): any {
  const { payload: {params} } = action

  try {
    const response = yield call(services.fetchRegistrationClosureReasons, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_REGISTRATION_CLOSURE_REASONS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_REGISTRATION_CLOSURE_REASONS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRegistrationClosureReasonSagas () {
  yield takeLatest(FETCH_REGISTRATION_CLOSURE_REASONS.request, fetchRegistrationClosureReasons)
}

export default function * registrationClosureReasonSagas () {
  yield all([watchRegistrationClosureReasonSagas()])
}
