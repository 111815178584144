/* eslint-disable camelcase */
import React from 'react';
import {
  InvoiceAttributes,
  InvoiceFormAttributes,
  InvoiceItemAttributes,
  NestedInvoiceItemAttributes,
  NestedWalletCreditAttributes,
  WalletCreditAttributes,
  colors,
  invoiceChargeStatusOptions,
  invoicePaymentOptions,
} from '../../utils/constants';
import { InvoiceItemBaseValues } from '../../store/invoice_items';
import Loading from '../loading/Loading';
import InvoiceValue from './InvoiceValues';
import { totalInvoiceValues } from '../../utils/functions';
import { isEmpty } from 'lodash';
import { css } from '@emotion/react';
import { parse, format } from 'date-fns';
import { HighlightOffOutlined } from '@mui/icons-material';
import { ptBR } from 'date-fns/locale';

const InvoiceSummary = (props: {
  invoice: InvoiceFormAttributes | InvoiceAttributes;
  invoice_items: InvoiceItemAttributes[] | NestedInvoiceItemAttributes[];
  allow_remove_values: boolean;
  handleClearBaseValues?: (key: keyof InvoiceItemBaseValues, invoice_item_ids: string[]) => void;
  wallet_credits: (WalletCreditAttributes | NestedWalletCreditAttributes)[];
}) => {
  const {
    invoice,
    allow_remove_values,
    handleClearBaseValues,
    invoice_items,
    wallet_credits,
  } = props;
  const representative = invoice?.student || '';
  const expiration_date = (invoice.expiration_date && format(new Date(invoice.expiration_date), 'dd/MM/yyyy')) || '';
  const punctuality_expiration_date =
    (invoice.punctuality_expiration_date && format(new Date(invoice.punctuality_expiration_date), 'dd/MM/yyyy')) || '';
  const payment_method = invoicePaymentOptions.find((item) => item.value === invoice.payment_method)?.label || '';
  if (isEmpty(invoice)) {
    return <Loading />;
  }
  const paid_at_date =
    (invoice.paid_at && format(parse(invoice.paid_at, 'yyyy-LL-dd', new Date(), { locale: ptBR }), 'dd/MM/yyyy')) || '';
  const created_at_date = (invoice.created_at && format(new Date(invoice.created_at), 'dd/MM/yyyy')) || ''
  const {
    accumulated_fees,
    accumulated_penalty,
    billing_conditional_discount,
    billing_full_value,
    current_anticipation_discount,
    current_contractual_addition,
    current_installment_addition,
    current_renegotiation_allowance,
    expected_liquid_value,
    initial_anticipation_discount,
    initial_contractual_addition,
    initial_fees,
    initial_installment_addition,
    initial_penalty,
    initial_charge,
    initial_renegotiation_allowance,
    total_credits,
    original_charge,
    conditional_charge,
    invoice_liquid_value,
    effective_conditional_discount,
    initial_conditional_discount_charge,
    reminiscing_charge_value,
    reminiscing_liquid_value,
    refunded_value,
    retained_value,
  } = totalInvoiceValues({invoice_items, invoice, wallet_credits});
  const all_invoice_item_ids = invoice_items.map((item) => item.id as string);
  const iconProps = { style: { color: colors.lightRed } };
  const icon = HighlightOffOutlined;
  return (
    <div>
      <InvoiceValue
        values={[
          {label: 'Gerada em', value: created_at_date, convert: false},
          { label: 'Responsável', value: representative, convert: false },
          { label: 'Vencimento', value: expiration_date, convert: false },
          { label: 'Vencimento Desconto Condicional', value: punctuality_expiration_date, convert: false },
          { label: 'Data do Pagamento', value: paid_at_date, convert: false },
          {
            label: 'Status de Cobrança',
            value: invoiceChargeStatusOptions.find((option) => option.value === invoice.charge_status)?.label || '',
            convert: false,
          },
          { label: 'Método de Pagamento', value: payment_method, convert: false },
          { label: 'Número da Parcela', value: invoice.installment_number || 'N/A', convert: false },
        ]}
      />
      <span>{invoice.description}</span>
      <hr />
      <span className='subtitle-two'>Fatura Original</span>
      <div
        css={css`
          display: flex;
          justify-content: space-evenly;
        `}
      >
        <InvoiceValue
          values={[
            { label: 'Valor Contratual', value: billing_full_value },
            {
              label: 'Ajuste Contratual Prévio',
              value: initial_contractual_addition,
              ...(allow_remove_values &&
                handleClearBaseValues &&
                initial_contractual_addition > 0 && {
                  iconFunction: () => handleClearBaseValues('initial_contractual_addition', all_invoice_item_ids),
                  iconProps,
                  icon,
                }),
            },
            {
              label: 'Acréscimo de Postergação Prévio',
              value: initial_installment_addition,
              ...(allow_remove_values &&
                handleClearBaseValues &&
                initial_installment_addition > 0 && {
                  iconFunction: () => handleClearBaseValues('initial_installment_addition', all_invoice_item_ids),
                  iconProps,
                  icon,
                }),
            },
            {
              label: 'Abono de Negociação Prévio',
              value: initial_renegotiation_allowance,
              ...(allow_remove_values &&
                handleClearBaseValues &&
                initial_renegotiation_allowance > 0 && {
                  iconFunction: () => handleClearBaseValues('initial_renegotiation_allowance', all_invoice_item_ids),
                  iconProps,
                  icon,
                }),
            },
            {
              label: 'Juros Prévio',
              value: initial_fees,
              ...(allow_remove_values &&
                handleClearBaseValues &&
                initial_fees > 0 && {
                  iconFunction: () => handleClearBaseValues('initial_fees', all_invoice_item_ids),
                  iconProps,
                  icon,
                }),
            },
            {
              label: 'Multa Prévia',
              value: initial_penalty,
              ...(allow_remove_values &&
                handleClearBaseValues &&
                initial_penalty > 0 && {
                  iconFunction: () => handleClearBaseValues('initial_penalty', all_invoice_item_ids),
                  iconProps,
                  icon,
                }),
            },
            { label: 'Valor Prévio', value: initial_charge, resultProps: { style: { fontWeight: 'bold' } } },
            { label: 'Ajuste Contratual', value: current_contractual_addition },
            { label: 'Acréscimo de Postergação', value: current_installment_addition },
            { label: 'Abono de Negociação', value: -current_renegotiation_allowance },
            {
              label: 'Valor Ajuste',
              value: current_contractual_addition + current_installment_addition - current_renegotiation_allowance,
              resultProps: { style: { fontWeight: 'bold' } },
            },
          ]}
        />
        <InvoiceValue
          values={[
            { label: 'Desconto Condicional Contratual', value: -billing_conditional_discount },
            {
              label: 'Desconto de Antecipação Prévio',
              value: -initial_anticipation_discount,
              ...(allow_remove_values &&
                handleClearBaseValues &&
                initial_anticipation_discount > 0 && {
                  iconFunction: () => handleClearBaseValues('initial_anticipation_discount', all_invoice_item_ids),
                  iconProps,
                  icon,
                }),
            },
            {
              label: 'Desconto Condicional Prévio',
              value: -initial_conditional_discount_charge,
              resultProps: { style: { fontWeight: 'bold' } },
            },
            { label: 'Desconto de Antecipação', value: -current_anticipation_discount },
            {
              label: 'Desconto Condicional Previsto',
              value: -conditional_charge,
              resultProps: { style: { fontWeight: 'bold' } },
            },
          ]}
        />
      </div>
      <InvoiceValue
        values={[
          { label: 'Valor Original Fatura', value: original_charge, resultProps: { style: { fontWeight: 'bold' } } },
          {
            label: 'Valor Líquido Esperado',
            value: expected_liquid_value,
            resultProps: { style: { fontWeight: 'bold' } },
          },
        ]}
      />
      <hr />
      <span className='subtitle-two'>Valores Efetivos</span>
      <div
        css={css`
          display: flex;
          justify-content: space-evenly;
        `}
      >
        <InvoiceValue
          values={[
            { label: 'Valor Fatura Original', value: original_charge },
            { label: 'Desconto Condicional Efetivo', value: -effective_conditional_discount },
            { label: 'Juros Fatura', value: accumulated_fees },
            { label: 'Multa Fatura', value: accumulated_penalty },
            {
              label: 'Valor Líquido Fatura',
              value: invoice_liquid_value,
              resultProps: { style: { fontWeight: 'bold' } },
            },
          ]}
        />
        { total_credits > 0 && (
          <InvoiceValue
            values={[
              { label: 'Valor Fatura Original', value: original_charge },
              { label: 'Crédito Aplicado', value: total_credits },
              {
                label: 'Valor Cobrança Remanescente',
                value: reminiscing_charge_value,
                resultProps: { style: { fontWeight: 'bold' } },
              },
              { label: 'Desconto Condicional Efetivo', value: -effective_conditional_discount },
              {
                label: 'Valor Líquido Remanescente',
                value: reminiscing_liquid_value,
                resultProps: { style: { fontWeight: 'bold' } },
              },
            ]}
          />
        )
        }
      </div>
      <hr />
      <span className='subtitle-two'>Valor Retido</span>
      <InvoiceValue
        values={[
          { label: 'Valor Líquido Fatura', value: invoice_liquid_value },
          { label: 'Valor Estornado', value: refunded_value },
          { label: 'Valor Retido', value: retained_value, resultProps: { style: { fontWeight: 'bold' } } },
        ]}
      />
    </div>
  );
};

export default InvoiceSummary;
