import React from 'react'
import { render } from 'react-dom'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { Provider } from 'react-redux'
import Notifications from './components/notifications/Notifications'
import { PersistGate } from 'redux-persist/es/integration/react'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import App from './app'
import configureStore from './store/configureStore'

import { GlobalStyle } from './index.style'

const { persistor, store } = configureStore()

const theme = createTheme()

const onBeforeLift = () => {
  // take some action before the gate lifts
}

const renderApp = (NextApp) => {
  render(
    <Provider store={store}>
      <PersistGate loading={null} onBeforeLift={onBeforeLift} persistor={persistor}>
        <Notifications />
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <NextApp />
        </ThemeProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('app')
  )
}

renderApp(App)
