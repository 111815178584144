import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'

const invoiceEndpoint = '/invoices'

export const createInvoice = (params: object) => {
  return http.post(`${invoiceEndpoint}`, params)
}
export const updateInvoice = ({ params, id }: { params: object, id: string|number }) => {
  return http.put(`${invoiceEndpoint}/${id}`, params)
}

export const fetchInvoices = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${invoiceEndpoint}?${queryParams}`)
}

export const fetchInvoice = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${invoiceEndpoint}/${id}?${queryParams}`)
}


export const createInvoiceCharge = ({ id }: { id: string | number }) => {
  return http.post(`${invoiceEndpoint}/${id}/create_invoice_charge`)
}

export const deleteInvoiceCharge = ({ id }: { id: string | number }) => {
  return http.delete(`${invoiceEndpoint}/${id}/delete_invoice_charge`)
}

export const refreshInvoiceCharge = ({ id }: { id: string | number }) => {
  return http.get(`${invoiceEndpoint}/${id}/refresh_invoice_charge`)
}

export const fetchInvoiceChargeQrCode = ({ id }: { id: string | number }) => {
  return http.get(`${invoiceEndpoint}/${id}/fetch_invoice_charge_qr_code`)
}

export const sendStraightNotification = ({ id }: { id: string | number }) => {
  return http.post(`${invoiceEndpoint}/${id}/send_invoice_notification`)
}

export const unlinkInvoiceCharge = ({ id }: { id: string | number }) => {
  return http.get(`${invoiceEndpoint}/${id}/unlink_invoice_charge`)
}

export const generateInvoiceReceipts = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${invoiceEndpoint}/generate_invoice_receipts?${queryParams}`, { responseType: 'blob' })
}




