import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_COURSES,
  FetchCoursesPayload,
  FETCH_COURSE,
  CREATE_COURSE,
  UPDATE_COURSE,
  FetchCoursePayload,
  CreateCoursePayload,
  UpdateCoursePayload,
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchCourses (action: DefaultAction<FetchCoursesPayload, string>): any {
  const { payload: {params}} = action
  try {
    const response = yield call(services.fetchCourses, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_COURSES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_COURSES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchCourse (action: DefaultAction<FetchCoursePayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchCourse, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_COURSE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_COURSE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createCourse (action: DefaultAction<CreateCoursePayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'courses',
        attributes: { ...data },
      }
    }
    const response = yield call(services.createCourse, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_COURSE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_COURSE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateCourse (action: DefaultAction<UpdateCoursePayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'courses',
        attributes: { ...data },
        id
      }
    }
    const response = yield call(services.updateCourse, {data: params, id})
    yield put(UPDATE_COURSE.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_COURSE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchCoursesSagas () {
  yield takeLatest(FETCH_COURSES.request, fetchCourses)
  yield takeLatest(FETCH_COURSE.request, fetchCourse)
  yield takeLatest(CREATE_COURSE.request, createCourse)
  yield takeLatest(UPDATE_COURSE.request, updateCourse)
}

export default function * coursesSagas () {
  yield all([watchCoursesSagas()])
}
