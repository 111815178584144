import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const absenceJustificationsEndpoint = '/absence_justifications'

export const fetchAbsenceJustifications = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${absenceJustificationsEndpoint}?${queryParams}`)
}

export const fetchAbsenceJustification = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${absenceJustificationsEndpoint}/${id}?${queryParams}`)
}
