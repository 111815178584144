import React from 'react'
import { useSelector } from 'react-redux'

import { private_routes_permissions } from '../../utils/constants'
import {useLocation } from 'react-router-dom'
import { RootState } from '../../store/configureStore'
import Loading from '../loading/Loading'

export const navigationHoc = (WrappedComponent: React.ElementType) => {
  const navigationHocComponent = (props: any) => {
    const [key, updateState] = React.useState(new Date().toISOString());
    const state = useSelector((state: RootState) => state)
    const location = useLocation()
    const { auth: { company, profile }, account: { companies }, ui: { changing_company } } = state 
    const forceUpdate = React.useCallback(async () => updateState(new Date().toISOString()), []);
  
    React.useEffect(() => {
      if (company && profile) {
        const currentCompanyKind = companies.find((co: any) => co.id === company)?.kind
        const current_menu_option = private_routes_permissions.find(item => item.path === location.pathname)
        if(current_menu_option && current_menu_option.companies && current_menu_option.companies.includes(currentCompanyKind)){
          forceUpdate()
        }
      }
    }, [company, profile])
    if(changing_company){
      return <Loading />
    }
    return <WrappedComponent key={key} {...props} />
  }
  return navigationHocComponent
}

export default navigationHoc
