import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const racesEndpoint = '/races'

export const fetchRaces = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${racesEndpoint}?${queryParams}`)
}

export const fetchRace = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${racesEndpoint}/${id}?${queryParams}`)
}
