import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { css } from '@emotion/react'
import { colors } from '../../utils/constants'
import { Close } from '@mui/icons-material'

const DeleteButton = (props: { onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void, tooltip?: string, disabled?: boolean }) => {
  const { onClick, tooltip, disabled = false } = props
  const Wrapper = tooltip ? (props:{children: React.ReactElement}) => {
    const { children } = props
    return <Tooltip title={tooltip || ''}>{children}</Tooltip>
  } : React.Fragment
  return (
    <Wrapper>
      <IconButton
        css={css`
          width: 3rem;
          height: 3rem;
        `}
        onClick={onClick}
        disabled={disabled}
      >
        <Close
          css={css`
            color: ${colors.lightRed};
          `}
        />
      </IconButton>
    </Wrapper>
  )

}

export default DeleteButton