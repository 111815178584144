/* eslint-disable camelcase */
import { find, isNil, keys, reduce } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CurriculumsForm from '../components/form/CurriculumsForm';
import { FETCH_CURRICULUM, UPDATE_CURRICULUM, CREATE_CURRICULUM } from '../store/curriculums';
import { RootState } from '../store/configureStore';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import {CurriculumAttributes, DefaultOptionType, LocationState, NestedCompositionAttributes, NestedCompositionPeriodAttributes, NestedCurriculumSubjectAttributes, NestedKtwelveCurriculumAttributes, NestedKtwelveSubjectAttributes, Paths, Role } from '../utils/constants';
import { evaluate_permissions, verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { formValueSelector } from 'redux-form';
import { FETCH_COURSES } from '../store/courses';
import KtwelveCurriculumsForm from '../components/form/KtwelveCurriculumsForm';
import { KtwelveSubjectJson } from '../store/ktwelve_subjects';

const ktwelveSubjectUpdater = (
  currentKtwelveSubjects: NestedKtwelveSubjectAttributes[],
  initialKtwelveSubjects: NestedKtwelveSubjectAttributes[],
) => {
  const sorted: NestedKtwelveSubjectAttributes[] = [];
  currentKtwelveSubjects.forEach((ktwelve_subject) => {
    const related = find(initialKtwelveSubjects, (initial) => initial.id === ktwelve_subject.id);
    if (isNil(related)) {
      sorted.push(ktwelve_subject);
      return;
    }
    const omit = ['id', '_destroy', 'ktwelve_curriculum_id'];
    const result = verifyDifferentValues(ktwelve_subject, related, omit) as NestedKtwelveSubjectAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedKtwelveSubjectAttributes[];
};

const compositionPeriodUpdater = (
  currentCompositionPeriods: NestedCompositionPeriodAttributes[],
  initialCompositionPeriods: NestedCompositionPeriodAttributes[],
) => {
  const sorted: NestedCompositionPeriodAttributes[] = [];
  currentCompositionPeriods.forEach((composition_periods) => {
    const related = find(initialCompositionPeriods, (initial) => initial.id === composition_periods.id);
    if (isNil(related)) {
      sorted.push(composition_periods);
      return;
    }
    const omit = ['id', '_destroy', 'composition_id'];
    const result = verifyDifferentValues(composition_periods, related, omit) as NestedCompositionPeriodAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedCompositionPeriodAttributes[];
};


const compositionsUpdater = (
  currentComposition: NestedCompositionAttributes[],
  initialComposition: NestedCompositionAttributes[],
) => {
  const sorted: NestedCompositionAttributes[] = [];
  currentComposition.forEach((composition) => {
    const related = find(initialComposition, (initial) => initial.id === composition.id);
    if (isNil(related)) {
      sorted.push(composition);
      return;
    }
    const result = verifyDifferentValues(composition, related, [
      'id',
      '_destroy',
      'composition_periods_attributes',
      'curriculum_id'
    ]) as NestedCompositionAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentCompositionPeriods = composition.composition_periods_attributes;
      const initialCompositionPeriods = related?.composition_periods_attributes;
      const composition_periods_attributes = compositionPeriodUpdater(
        currentCompositionPeriods as NestedCompositionPeriodAttributes[],
        initialCompositionPeriods as NestedCompositionPeriodAttributes[],
      );
      const formattedPaymentOption = {
        ...result,
        composition_periods_attributes,
      };
      sorted.push(formattedPaymentOption);
    }
  });
  return sorted as NestedCompositionAttributes[];
};

const ktwelveCurriculumUpdater = (
  currentKtwelveCurriculumSubjects: NestedKtwelveCurriculumAttributes[],
  initialKtwelveCurriculumSubjects: NestedKtwelveCurriculumAttributes[],
) => {
  const sorted: NestedKtwelveCurriculumAttributes[] = [];
  currentKtwelveCurriculumSubjects.forEach((ktwelve_curriculum) => {
    const related = find(initialKtwelveCurriculumSubjects, (initial) => initial.id === ktwelve_curriculum.id);
    if (isNil(related)) {
      sorted.push(ktwelve_curriculum);
      return;
    }
    const omit = ['id', '_destroy', 'company_id', 'curriculum_id'];
    const result = verifyDifferentValues(ktwelve_curriculum, related, omit) as NestedKtwelveCurriculumAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentKtwelveSubjects = ktwelve_curriculum.ktwelve_subjects_attributes;
      const initialKtwelveSubjects = related?.ktwelve_subjects_attributes;
      const ktwelve_subjects_attributes = ktwelveSubjectUpdater(
        currentKtwelveSubjects as NestedKtwelveSubjectAttributes[],
        initialKtwelveSubjects as NestedKtwelveSubjectAttributes[],
      );
      const formattedPaymentOption = {
        ...result,
        ktwelve_subjects_attributes,
      };
      sorted.push(formattedPaymentOption);

    }
  });
  return sorted as NestedKtwelveCurriculumAttributes[];
};




const curriculumSubjectsUpdater = (
  currentCurriculumSubjects: NestedCurriculumSubjectAttributes[],
  initialCurriculumSubjects: NestedCurriculumSubjectAttributes[],
) => {
  const sorted: NestedCurriculumSubjectAttributes[] = [];
  currentCurriculumSubjects.forEach((curriculum_subject) => {
    const related = find(initialCurriculumSubjects, (initial) => initial.id === curriculum_subject.id);
    if (isNil(related)) {
      sorted.push(curriculum_subject);
      return;
    }
    const omit = ['id', '_destroy', 'curriculum_id'];
    const result = verifyDifferentValues(curriculum_subject, related, omit) as NestedCurriculumSubjectAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedCurriculumSubjectAttributes[];
};


interface CurriculumFormAttributes extends CurriculumAttributes {
  curriculum_subjects_attributes: NestedCurriculumSubjectAttributes[]
  compositions_attributes: NestedCompositionAttributes[]
  ktwelve_curriculums_attributes?: NestedKtwelveCurriculumAttributes[]
}



const CurriculumsFormContainer = (props: { fetchAndUpdateCurriculumMethod: (id?: string) => Promise<void> }) => {
  const { fetchAndUpdateCurriculumMethod } = props
  const location = useLocation<LocationState>();
  const ktwelve_curriculum_form = location?.state?.ktwelve_curriculum_form || false
  const dispatch = useDispatch();
  const history = useHistory();
  const state  = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
  } = state
  const form_name = ktwelve_curriculum_form ? 'ktwelveCurriculumForm' : 'curriculumForm'
  const formValues = formValueSelector(form_name);
  const [initialValues, setInitialValues] = React.useState<CurriculumFormAttributes | null>(null);
  const [course_options, setCourseOptions] = React.useState<DefaultOptionType[]>([])
  const [edit, setEdit] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const companyIdValue = formValues(state, 'company_id') as string;
  const isUpdating = !isNil(initialValues?.id);
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const company_id_to_use = location?.state?.company_id
    ? location?.state?.company_id
    : is_above_school_director
    ? companyIdValue
    : company;

  const loadCourseOptions = React.useCallback(async () => {
    try {
      const courses = await dispatch(
        FETCH_COURSES.request({
          params: {
            filters: {
              'page[size]': '30',
            },
          },
        }),
      );
      const {
        data: { data },
      } = courses;
      const formattedCourses = data.map((item) => {
        return {
          value: ~~item.id,
          label: item.attributes.name,
        };
      });
      setCourseOptions(formattedCourses);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar as opções de curso',
        }),
      );
    }
  }, []);
  
  const loadCurriculums = React.useCallback(async () => {
    if (location?.state?.curriculum_id) {
      const curriculum = await dispatch(
        FETCH_CURRICULUM.request({
          id: location.state.curriculum_id,
          params: {
            filters: {
              include: ['curriculum_subjects','compositions.composition_periods', 'ktwelve_curriculums.ktwelve_subjects'].join(',')
            }
          }
        }),
      );
      const {
        data: {
          data: { id, attributes }, included,
        },
      } = curriculum;
      const nested_attributes = reduce(included, (acc, incl,_, self) => {
        if(incl.type === 'curriculum_subjects'){
          return {...acc, curriculum_subjects_attributes: acc.curriculum_subjects_attributes.concat({id: incl.id, ...incl.attributes})}
        }
        if(incl.type === 'ktwelve_curriculums' && ktwelve_curriculum_form){
          const ktwelve_subjects = self.filter(item => item.type === 'ktwelve_subjects' && item.attributes.ktwelve_curriculum_id === ~~incl.id) as KtwelveSubjectJson[]
          return {...acc, ktwelve_curriculums_attributes: acc.ktwelve_curriculums_attributes.concat({
            id: incl.id, ...incl.attributes,
            ktwelve_subjects_attributes: ktwelve_subjects.map(cp => ({id: cp.id, ...cp.attributes}))
          }) as NestedKtwelveCurriculumAttributes[]}
        }
        return acc
      }, {
        curriculum_subjects_attributes: [] as NestedCurriculumSubjectAttributes[],
        compositions_attributes: [] as NestedCompositionAttributes[],
        ktwelve_curriculums_attributes: [] as NestedKtwelveCurriculumAttributes[]
      })
      const formattedCurriculum = {
        id,
        ...attributes,
        ...nested_attributes
      };
      setInitialValues(formattedCurriculum);
    } else {
      setEdit(true)
      setInitialValues({ company_id: company_id_to_use } as CurriculumFormAttributes)
    }
  }, [location]);

  const initCurriculumsForm = React.useCallback(async () => {
    setLoading(true);
    await loadCourseOptions();
    await loadCurriculums();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (form_data: CurriculumFormAttributes) => {
      const { curriculum_subjects_attributes, compositions_attributes, ktwelve_curriculums_attributes, ...dataRest } = form_data
      try {
        let id = ''
        if (isUpdating) {
          const { curriculum_subjects_attributes: initial_curriculum_subject_attributes, compositions_attributes: initial_compositions_attributes, ktwelve_curriculums_attributes: initial_ktwelve_curriculums_attributes, ...initialRest } = initialValues as CurriculumFormAttributes
          const { id, ...rest } = verifyDifferentValues(dataRest, initialRest, [
            'id',
            'company_id',
          ]) as CurriculumAttributes;
          const updated_curriculum_subjects_attributes = ktwelve_curriculum_form ? [] : curriculumSubjectsUpdater(curriculum_subjects_attributes, initial_curriculum_subject_attributes || [])
          const updated_compositions_attributes = ktwelve_curriculum_form ? [] : compositionsUpdater(compositions_attributes, initial_compositions_attributes || [])
          const updated_ktwelve_curriculums_attributes = ktwelve_curriculum_form ? ktwelveCurriculumUpdater(ktwelve_curriculums_attributes || [], initial_ktwelve_curriculums_attributes || []) : []
          await dispatch(
            UPDATE_CURRICULUM.request({
              id: initialValues?.id as string,
              data: {
                ...rest,
                curriculum_subjects_attributes: updated_curriculum_subjects_attributes,
                compositions_attributes: updated_compositions_attributes,
                ktwelve_curriculums_attributes: updated_ktwelve_curriculums_attributes
              },
            }),
          );
        } else {
          const response = await dispatch(
            CREATE_CURRICULUM.request({
              data: {
                ...form_data,
                company_id: company_id_to_use,
              },
            }),
          );
          const { data: { data: { id: created_id } } } = response
          id = created_id
        }
        dispatch(
          success({
            message: 'Matriz curricular salva com sucesso.',
          }),
        );
        setEdit(false)
        if(id){
          history.push({pathname: Paths.CURRICULUMS_FORM, state: { curriculum_id: id }})
          await fetchAndUpdateCurriculumMethod(id)
        }
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar matriz curricular.',
          }),
        );
      }
    },
    [initialValues, company_id_to_use, isUpdating, history],
  );

  React.useEffect(() => {
    initCurriculumsForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  if(ktwelve_curriculum_form){
    return (
      <KtwelveCurriculumsForm initialValues={initialValues} onSubmit={onSubmit} />
    )
  }
  return <CurriculumsForm initialValues={initialValues} onSubmit={onSubmit} course_options={course_options} edit={edit} setEdit={setEdit}/>;
};

export default CurriculumsFormContainer;
