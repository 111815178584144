import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_KTWELVE_CURRICULUM_REGISTRATIONS,
  FetchKtwelveCurriculumRegistrationsPayload,
  FetchKtwelveCurriculumRegistrationPayload,
  FETCH_KTWELVE_CURRICULUM_REGISTRATION,
  CREATE_KTWELVE_CURRICULUM_REGISTRATION,
  CreateKtwelveCurriculumRegistrationPayload,
  UpdateKtwelveCurriculumRegistrationPayload,
  UPDATE_KTWELVE_CURRICULUM_REGISTRATION,
  DeleteKtwelveCurriculumRegistrationPayload,
  DELETE_KTWELVE_CURRICULUM_REGISTRATION,
  GENERATE_KTWELVE_CURRICULUM_REGISTRATION_REPORT
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchKtwelveCurriculumRegistrations (action: DefaultAction<FetchKtwelveCurriculumRegistrationsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchKtwelveCurriculumRegistrations, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_KTWELVE_CURRICULUM_REGISTRATIONS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_KTWELVE_CURRICULUM_REGISTRATIONS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchKtwelveCurriculumRegistration (action: DefaultAction<FetchKtwelveCurriculumRegistrationPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchKtwelveCurriculumRegistration, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_KTWELVE_CURRICULUM_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_KTWELVE_CURRICULUM_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * generateKtwelveCurriculumRegistrationReport (action: DefaultAction<FetchKtwelveCurriculumRegistrationPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.generateKtwelveCurriculumRegistrationReport, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(GENERATE_KTWELVE_CURRICULUM_REGISTRATION_REPORT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(GENERATE_KTWELVE_CURRICULUM_REGISTRATION_REPORT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createKtwelveCurriculumRegistration (action: DefaultAction<CreateKtwelveCurriculumRegistrationPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'ktwelve_curriculum_registrations',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createKtwelveCurriculumRegistration, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_KTWELVE_CURRICULUM_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_KTWELVE_CURRICULUM_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateKtwelveCurriculumRegistration (action: DefaultAction<UpdateKtwelveCurriculumRegistrationPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'ktwelve_curriculum_registrations',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateKtwelveCurriculumRegistration, {data: params, id})
    yield put(UPDATE_KTWELVE_CURRICULUM_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_KTWELVE_CURRICULUM_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteKtwelveCurriculumRegistration (action: DefaultAction<DeleteKtwelveCurriculumRegistrationPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteKtwelveCurriculumRegistration, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_KTWELVE_CURRICULUM_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_KTWELVE_CURRICULUM_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchKtwelveCurriculumRegistrationsSagas () {
  yield takeLatest(FETCH_KTWELVE_CURRICULUM_REGISTRATIONS.request, fetchKtwelveCurriculumRegistrations)
  yield takeLatest(GENERATE_KTWELVE_CURRICULUM_REGISTRATION_REPORT.request, generateKtwelveCurriculumRegistrationReport)
  yield takeLatest(FETCH_KTWELVE_CURRICULUM_REGISTRATION.request, fetchKtwelveCurriculumRegistration)
  yield takeLatest(CREATE_KTWELVE_CURRICULUM_REGISTRATION.request, createKtwelveCurriculumRegistration)
  yield takeLatest(UPDATE_KTWELVE_CURRICULUM_REGISTRATION.request, updateKtwelveCurriculumRegistration)
  yield takeLatest(DELETE_KTWELVE_CURRICULUM_REGISTRATION.request, deleteKtwelveCurriculumRegistration)
}

export default function * ktwelveCurriculumRegistrationsSagas () {
  yield all([watchKtwelveCurriculumRegistrationsSagas()])
}
