/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@mui/styles';

import Loading from '../loading/Loading';

import { colors, CurrentRegistrationData, CurriculumAttributes, ElementView, LocationState, Paths } from '../../utils/constants';
import { IconButton, Tooltip } from '@mui/material';
import { Sync } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import CustomTabs from './CustomTabs';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(
  (): {
    view: any;
    registration: any;
    tabs: any;
    content: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      display: 'grid',
      padding: '2rem',
      rowGap: '1rem',
      flexGrow: '1',
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
        overflow: 'hidden',
      },
    },
    registration: {
      borderRadius: '4px',
      width: '100%',
      height: '100%',
      '& hr': {
        width: '100%',
        margin: '2rem 0',
        border: 'none',
        color: colors.grayBlue,
        backgroundColor: colors.grayBlue,
        height: '1px',
      },
    },
    tabs: {
      display: 'grid',
      background: 'white',
      gridTemplateColumns: 'repeat(5, 0.2fr)',
      borderRadius: '4px',
      margin: '2rem 0',
      cursor: 'default',
      justifyContent: 'space-between',
      '& > div': {
        padding: '1rem 0',
        cursor: 'pointer',
        '& span': {
          fontSize: '1.25vw',
        },
      },
      '& > div:first-of-type': {
        borderRadius: '4px 0 0 4px',
      },
      '& > div:last-of-type': {
        borderRadius: '0 4px 4px 0',
      },
    },
    content: {
      background: colors.lightBlue,
      borderRadius: '4px',
      padding: '2rem',
      'min-height': '5rem',
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
    },
  }),
);



const SetupView = ({
  title,
  current_resource,
  tooltip_title,
  element_array,
  custom_tabs_props = {}
}: {
  title: string
  current_resource: CurrentRegistrationData|CurriculumAttributes
  element_array: ElementView[]
  tooltip_title: string
  custom_tabs_props?: object
}) => {
  const location = useLocation<LocationState>();

  const classes = useStyles();
  const [autoCompleteKey, setAutocompleteKey] = React.useState(new Date().toISOString())
  const [renderReady, setRenderReady] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const init = async () => {
    setRenderReady(false)
    if (location?.state?.initialStep) {
      setStep(location?.state?.initialStep)
    }
    setRenderReady(true)
  }


  React.useEffect(() => {
      init()
  }, []);
  return (
    <div className={classes.view} key={autoCompleteKey}>
      <div className={classes.registration}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span className='title'> {title} </span>
          <Tooltip title={tooltip_title} style={{ display: !current_resource ? 'none' : 'block' }}>
            <IconButton disabled={step === 0} onClick={() => setAutocompleteKey(new Date().toISOString())}>
              <Sync />
            </IconButton>
          </Tooltip>
        </div>
        <CustomTabs
          step_options={element_array.map(item => ({label: item.label, value: item.step, path: item.path as Paths}))}
          step={step}
          has_resource={!isEmpty(current_resource)}
          handleClick={(option) => {
            current_resource && setStep(option);
          }}
         {...custom_tabs_props}
        />
        <div className={classes.content}>
          {!renderReady ? (
            <Loading />
          ) : element_array.map(element => {
              let result = false
              if(element.path){
                result = location.pathname === element.path
              } else {
                result = element.step === step
              }
              if(!result){
                return null
              }
              const Element = element.element
              return (
                <Element
                  key={element.step}
                  setStep={setStep}
                  {...element.props}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default SetupView;
