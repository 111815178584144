/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { AuditActionEnum, ClassTimeAttributes, ExamAttributes, JsonFormat, JsonResponseFormat, RoomStudentAttributes, WithRequestParams } from '../../utils/constants'
import { CourseJson } from '../courses'
import { AxiosResponse } from 'axios'
import { AddressJson, UserJson } from '../users'
import { AccountJson } from '../accounts'
import { CompanyJson } from '../companies'
import { CityJson } from '../cities'
import { StateJson } from '../states'
import { UserDeficiencyJson } from '../deficiencies'
import { UserAlergyJson } from '../alergies'
import { ExamJson } from '../exams'
import { ClassTimeSubjectJson } from '../class_time_subjects'
import { StudentAbsenceJson } from '../student_absences'
import { RoomStudentJson } from '../room_students'

export interface AuditAttrs {
  auditable_id: number
  auditable_type: string
  associated_id: number
  associated_type: string
  user_id: number
  user_type: number
  user_name: string
  action: AuditActionEnum
  audited_changes: {
    [x:string]: []
  }
  comment: string
  created_at: string
  revision: RoomStudentAttributes | ExamAttributes | ClassTimeAttributes
  previous_revision: RoomStudentAttributes | ClassTimeAttributes
}
export type AuditJson = JsonFormat<'audits', AuditAttrs>

export type FetchAuditsResponse = JsonResponseFormat<AuditJson[], (ExamJson|ClassTimeSubjectJson|StudentAbsenceJson|RoomStudentJson|UserJson)[]>
export type FetchAuditResponse = JsonResponseFormat<AuditJson, (CourseJson|
  UserJson|AccountJson|CompanyJson|AddressJson|CityJson|StateJson|
  UserAlergyJson|UserDeficiencyJson|AuditJson)[]>

export type FetchAuditsPayload = WithRequestParams
export interface FetchAuditPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateAuditPayload {
  data: Partial<AuditAttrs>
}

export interface UpdateAuditPayload extends CreateAuditPayload {
  id: string
}

export interface DeleteAuditPayload {
  id: string
}

export const FETCH_AUDITS= createPromiseAction('coordenators: FETCH_AUDITS')<FetchAuditsPayload, FetchAuditsResponse, any>()
export const FETCH_AUDIT = createPromiseAction('coordenators: FETCH_AUDIT')<FetchAuditPayload, FetchAuditResponse, any>()
export const CREATE_AUDIT= createPromiseAction('coordenators: CREATE_AUDIT')<CreateAuditPayload, FetchAuditResponse, any>()
export const UPDATE_AUDIT= createPromiseAction('coordenators: UPDATE_AUDIT')<UpdateAuditPayload, FetchAuditResponse, any>()
export const DELETE_AUDIT = createPromiseAction('coordenators: DELETE_AUDIT')<DeleteAuditPayload, AxiosResponse, any>()
