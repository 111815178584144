/* eslint-disable camelcase */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'


import UsersView from '../components/view/UsersView'
import { calculateMenuOptions, ensure } from '../utils/functions'
import { CompanyAttributes, FormattedUserData, menuOptions, PaginationType, Paths, profiles, ProfileTypes, Role } from '../utils/constants'
import { updateCredentialsRequest } from '../store/auth'
import { FetchUsersResponse, AddressJson, FETCH_USERS, ProfileJson } from '../store/users'
import { error } from 'react-notification-system-redux'
import { AccountJson } from '../store/accounts'
import { CompanyJson } from '../store/companies'
import { CityJson } from '../store/cities'
import { StateJson } from '../store/states'
import { RootState } from '../store/configureStore'
import { filter, find, get, head, isEmpty, map } from 'lodash'
import { useHistory } from 'react-router-dom'


const defaultPagination = {
  pageCount: 0,
  totalCount: 0,
  pageSize: 10,
  pageNumber: 0
}

const UsersContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    auth: { company, profile },
    account: { companies }
  } = useSelector((state: RootState) => state)
  const [users, setUsers] = React.useState<FormattedUserData[]>([])
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination)
  const [profileFilter, setProfileFilter] = React.useState<ProfileTypes|string>('')
  const [nameFilter, setNameFilter] = React.useState('')
  const [emailFilter, setEmailFilter] = React.useState('')
  const [companyFilter, setCompanyFilter] = React.useState('')
  const [renderReady, setRenderReady] = React.useState(false)
  const profileLabel = profile.role
  const profileOptions = profiles.find(item => item.name === profileLabel)?.canCreateProfile.map(item => ({ value: item, label: profiles.find(x => x.name === item)?.label }))
  const afterEffect = (res: FetchUsersResponse) => {
    const {
      data: { data, meta, included },
      headers
    } = res
    dispatch(updateCredentialsRequest(headers))
    const formattedData = data.map(({ id, attributes: { additional_data, ...rest } }) => {

      const accountOnCurrentCompany = find(included, item => item.type === 'accounts' && item.attributes.company_id === ~~company &&  item.attributes.user_id === ~~id) as AccountJson
      const accountData = accountOnCurrentCompany || find(included, (item) => {
        return item.type === 'accounts' && item.attributes.user_id === ~~id
      }) as AccountJson
      const profiles = map(filter(included, item => item.type === 'profiles' && item.attributes.account_id === ~~get(accountData, 'id')) as ProfileJson[], item => item.attributes.role)
      const companyData =!!accountData &&  find(included, (item) => {
        return item.type === 'companies' && ~~item.id === accountData?.attributes?.company_id
      }) as CompanyJson
      const getAddress = find(included, (item) => {
        return item.type === 'addresses' && item.attributes.user_id === ~~id
      }) as AddressJson || null
      const addressAttrs = getAddress
        ? {
          ...getAddress.attributes
        }
        : null
      let state = null
      let city = null
      if (addressAttrs) {
        const getCityInfo = ensure(find(included,
          (item) => item.type === 'cities' && ~~item.id === addressAttrs?.city_id
        )) as CityJson
        const getStateInfo = ensure(find(included,
          (item) => item.type === 'states' && ~~item.id === addressAttrs?.state_id
        )) as StateJson
        city = !!getCityInfo && getCityInfo.attributes?.name
        state = !!getStateInfo && getStateInfo.attributes?.name
      }
      return {
        name: rest.name,
        phone: rest.phone,
        email: rest.email,
        profiles: profiles || rest.profiles,
        document_type: rest.document_type,
        document_number: rest.document_number,
        state: state,
        city: city,
        company: companyData?.attributes?.name,
        id,
        avatar_url: rest.avatar_url
      }
    })
    setPagination((current) => ({
      ...current,
      pageCount: meta.page_count,
      totalCount: meta.total_count
    }))
    setUsers(formattedData)
    setRenderReady(true)
  }
  const fetchUsersMethod = async ({
    newPagination = pagination,
    oldPagination = pagination,
    profile = profileFilter,
    email = emailFilter
  } : {
    newPagination?: PaginationType,
    oldPagination?: PaginationType,
    profile?: ProfileTypes | string,
    email?: string
  }) => {
    if (renderReady) setRenderReady(false)
    setPagination((current) => ({ ...current, ...newPagination }))
    const isSuperUser = profileLabel === Role.SUPERUSER
    const currentCompany = companies.find((item: any) => item.id === company) as CompanyAttributes
    const company_ids = currentCompany.descendants_ids.reduce((acc, co) => {
      return acc.concat(co)
    } ,[~~currentCompany.id])
    try {
      const promise = await dispatch(
        FETCH_USERS.request({
          params: {
            filters: {
              'q[name_cont]': nameFilter,
              'q[email_cont]': email,
              'q[accounts_company_id_in]': isSuperUser ? '' : company_ids.join(','),
              'q[accounts_company_name_cont]': companyFilter,
              'q[accounts_profiles_role_eq]': profile,
              'page[number]': (newPagination.pageNumber + 1).toString(),
              'page[size]': (newPagination.pageSize).toString(),
              include: 'companies,address,address.city,address.state,accounts.profiles'
            }
          },
        })
      )
      afterEffect(promise)
    } catch (e) {
        dispatch(
          error({
            message: 'Erro no carregamento de  usuários'
          })
        )
        setPagination({ ...oldPagination })
    }
  }


  const initUsersView = async () => {
    try {
      await fetchUsersMethod({})
    } catch (e) {
      dispatch(
        error({
          message: 'Erro no carregamento da tela de usuários'
        })
      )
    }
  }

  React.useEffect(() => {
    initUsersView()
  }, [])


  React.useEffect(() => {
    if (company && profile) {
      const pathOptions = calculateMenuOptions(menuOptions, profile, companies, company).map(item => item.path)
      if(pathOptions.includes(Paths.USERS_LIST)){
        initUsersView()
      } else if(pathOptions.includes(Paths.HOME)) {
        history.push(Paths.HOME)
      } else if(!isEmpty(pathOptions)) {
        history.push(head(pathOptions) as string)
      }
    }
  }, [company, profile])


  const handlePageChange = (_: any, newPage: number) => {
    fetchUsersMethod({
      newPagination: {
        ...pagination,
        pageNumber: newPage
      },
      oldPagination: {
        ...pagination
      }
    })
  }

  const handleChangePageSize = (e: any) => {
    fetchUsersMethod({
      newPagination: {
        ...pagination,
        pageSize: e.target.value
      },
      oldPagination: {
        ...pagination
      }
    })
  }

  return <UsersView
          handleChangePageSize={handleChangePageSize}
          handlePageChange={handlePageChange}
          users={users}
          renderReady={renderReady}
          pagination={pagination}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          fetchUsersMethod={fetchUsersMethod}
          defaultPagination={defaultPagination}
          companyFilter={companyFilter}
          setCompanyFilter={setCompanyFilter}
          profileOptions={profileOptions}
          profileFilter={profileFilter}
          profile={profile}
          setProfileFilter={setProfileFilter}
          emailFilter={emailFilter}
          setEmailFilter={setEmailFilter}
        />
}

export default UsersContainer
