import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const registrationConnections = '/registration_connections'

export const fetchRegistrationConnections = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${registrationConnections}?${queryParams}`)
}

export const deleteRegistrationConnection = ({ id }: { id: string | number }) => {
  return http.delete(`${registrationConnections}/${id}`)
}
