/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, ProfileDashboardAttributes, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'

export interface ProfileDashboardAttrs {
  company_id: number
  role: string
  url: string
  name: string
  dashboard_logo_url: string
  dashboard_logo_attachment_id: number
}

export type ProfileDashboardJson = JsonFormat<'profile_dashboards', ProfileDashboardAttrs>
export type FetchProfileDashboardsResponse = JsonResponseFormat<ProfileDashboardJson[]>
export type FetchProfileDashboardResponse = JsonResponseFormat<ProfileDashboardJson>

export type FetchProfileDashboardsPayload = WithRequestParams
  export interface FetchProfileDashboardPayload extends WithRequestParams {
  id: string | number,
}

export interface CreateProfileDashboardPayload {
  data: Omit<ProfileDashboardAttributes, 'id'>
}

export interface UpdateProfileDashboardPayload {
  id: string
  data: Omit<ProfileDashboardAttributes, 'id'>
}

export interface DeleteDashboardLogoPayload {
  id: string
}

export const FETCH_PROFILE_DASHBOARDS   = createPromiseAction('profile_dashboards: FETCH_PROFILE_DASHBOARDS')<FetchProfileDashboardsPayload, FetchProfileDashboardsResponse, any>()
export const FETCH_PROFILE_DASHBOARD    = createPromiseAction('profile_dashboards: FETCH_PROFILE_DASHBOARD')<FetchProfileDashboardPayload, FetchProfileDashboardResponse, any>()
export const CREATE_PROFILE_DASHBOARD   = createPromiseAction('profile_dashboards: CREATE_PROFILE_DASHBOARD')<CreateProfileDashboardPayload, FetchProfileDashboardResponse, any>()
export const UPDATE_PROFILE_DASHBOARD   = createPromiseAction('profile_dashboards: UPDATE_PROFILE_DASHBOARD')<UpdateProfileDashboardPayload, FetchProfileDashboardResponse, any>()
export const DELETE_DASHBOARD_LOGO      = createPromiseAction('profile_dashboards: DELETE_DASHBOARD_LOGO')<DeleteDashboardLogoPayload, AxiosResponse, any>()
