import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const ktwelveSubjectEndpoints = '/ktwelve_subjects'

export const fetchKtwelveSubjects = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ktwelveSubjectEndpoints}?${queryParams}`)
}

export const fetchKtwelveSubject = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ktwelveSubjectEndpoints}/${id}?${queryParams}`)
}

export const createKtwelveSubject = (data: object) => {
  return http.post(`${ktwelveSubjectEndpoints}`, data)
}

export const updateKtwelveSubject = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${ktwelveSubjectEndpoints}/${id}`, data)
}

export const deleteKtwelveSubject = ({ id }: { id: string | number } ) => {
  return http.delete(`${ktwelveSubjectEndpoints}/${id}`)
}
