/* eslint-disable camelcase */
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Paths } from '../../utils/constants';
import InputComponent from '../input/form/input';
import CheckboxComponent from '../input/form/checkbox';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      padding: '2rem 10%',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const AsaasWebhookSettingForm = (props: { handleSubmit: (arg: () => void) => any; onSubmit: () => void }) => {
  const { handleSubmit, onSubmit } = props;
  const classes = useStyles();

  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Criar configuração de pagamento</span>
      <form className={classes.form}>
        <div style={{ display: 'grid', gridTemplateColumns: '32% 32% 32%', justifyContent: 'space-between' }}>
          <Field
            name='url'
            component={InputComponent}
            label={'Url que recebe informações do webhook'}
            placeholder={'https://stag-api.plataformasoma.net'}
          />
          <Field
            name='email'
            component={InputComponent}
            label={'Notificação Email em caso de erro'}
            placeholder={'email@soma.com'}
          />
          <Field
            name='apiVersion'
            component={InputComponent}
            label={'Versão da API'}
            placeholder={'3'}
          />
        </div>
        <Field
          name='authToken'
          component={InputComponent}
          label={'Notificação Email em caso de erro'}
          placeholder={'email@soma.com'}
        />
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Field name='enabled' component={CheckboxComponent} placeholder={'Habilitar Webhook'} />
          <Field name='interrupted' component={CheckboxComponent} placeholder={'Habilitar Webhook'} />
        </div>
        <div className={classes.buttons}>
          <Link to={Paths.HOME} className='red small'>
            <span> Cancelar </span>
          </Link>
          <button onClick={handleSubmit(onSubmit)} className='blue small'>
            <span> Salvar configuração </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: 'asaasWebhookSettingForm',
    enableReinitialize: true
  }),
)(AsaasWebhookSettingForm);
