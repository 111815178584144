import React from 'react';
import {
  colors,
  ExamAttributes,
  ExamIrregularityEnum,
  examIrregularityOptions,
  ExamKindEnum,
  ExamPlaceableType,
  ExamPlacementAttributes,
  FormFileType,
  FormulaAttributes,
  FormulableType,
  KtwelveSubjectAttributes,
  KtwelveSubjectEvaluationKindEnum,
  onConfirm,
  Role,
  RoomAttributes,
  SubjectPeriodRegistrationAttributes,
  SubjectPeriodRegistrationStatusEnum,
} from '../../utils/constants';
import { CompositionPeriodData, SubjectPeriodData } from './RoomsTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import {
  FETCH_SUBJECT_PERIOD_REGISTRATION,
  FETCH_SUBJECT_PERIOD_REGISTRATIONS,
  UPDATE_SUBJECT_PERIOD_REGISTRATION,
} from '../../store/subject_period_registrations';
import { filter, find, isEmpty, isNumber, map, orderBy, size, sortBy, toNumber, union } from 'lodash';
import { SubjectPeriodJson } from '../../store/subject_periods';
import { FormulaJson } from '../../store/formulas';
import { ExamPlacementJson } from '../../store/exam_placements';
import { RegistrationResultJson } from '../../store/registration_results';
import { CREATE_EXAM, ExamJson, UPDATE_EXAM } from '../../store/exams';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { css } from '@emotion/react';
import { isAfter, isBefore } from 'date-fns';
import { IconModal } from '../modal/Modal';
import { MdErrorOutline } from 'react-icons/md';
import SelectComponent from '../input/form/select';
import TextAreaComponent from '../input/form/textarea';
import PenIcon from '../icon/PenIcon';
import { compactSum } from '../../utils/functions';
import TooltipButton from '../shared/TooltipButton';
import { UploadFile, Visibility } from '@mui/icons-material';
import UploadComponent from '../input/form/upload';
import CloudIcon from '../icon/CloudIcon';
import TrashIcon from '../icon/TrashIcon';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const SliderWrapperCss = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SliderCss = css`
  & .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  & .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  & input:checked + .slider {
    background-color: #2196f3;
  }

  & input:focus-within + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  & input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  & .slider.round {
    border-radius: 34px;
  }

  & .slider.round:before {
    border-radius: 50%;
  }
`;

const InputCss = css`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const DescriptionOpinionForm = (props: {
  subject_period_registration: SubjectPeriodRegistrationAttributes;
  updateSubjectPeriodRegistrationResult: (props: {
    subject_period_registration_id: string;
    update_data: object;
  }) => Promise<void>;
}) => {
  const dispatch = useDispatch();
  const { subject_period_registration, updateSubjectPeriodRegistrationResult } = props;
  const [file, setFile] = React.useState<FormFileType | null>();
  const onConfirmDescription: onConfirm = async (props) => {
    const { setLoading, handleClose, setLoadingMessage } = props;
    try {
      setLoading(true);
      setLoadingMessage('Salvando parecer descritivo');
      const formData = new FormData();
      formData.append('data[type]', 'subject_period_registrations');
      formData.append('data[id]', subject_period_registration.id);
      file && formData.append('data[attributes][descriptive_opinion]', file.file as Blob);
      await updateSubjectPeriodRegistrationResult({
        subject_period_registration_id: subject_period_registration.id,
        update_data: formData,
      });
      setLoading(false);
      setFile(null)
      handleClose();
    } catch (err) {
      setLoading(false);
      setFile(null)
      dispatch(
        error({
          message: 'Erro ao salvar parecer descritivo',
        }),
      );
      setLoading(false);
      handleClose();
    }
  };
  return (
    <IconModal tooltipText='Salvar Parecer Descritivo' onConfirm={onConfirmDescription} icon={UploadFile} title={'Salvar Parecer Descritivo'}>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: 'transparent',
            border: `1px dashed ${colors.grayBlue} `,
            padding: '1rem 1.5rem',
            position: 'relative',
          }}
        >
          <div
            style={{
              background: colors.grayBlue,
              borderRadius: '50%',
              width: '3rem',
              height: '3rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloudIcon />
          </div>
          <span style={{ fontSize: '0.75rem' }}>
            <strong>Solte o arquivo aqui</strong> ou navegue por seu <strong>computador</strong>
          </span>
          <span style={{ color: colors.darkGrayBlue, fontSize: '0.75rem' }}>Suporte Arquivos: Doc, Docx</span>
          <UploadComponent
            input={{
              value: file,
              name: `descriptive-opinion-${subject_period_registration.id}`,
              onChange: (e: FormFileType) => {
                setFile(e);
              },
              onBlur: () => undefined
            }}
          />
        </div>
        {file && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '1rem',
            }}
          >
            <span style={{ textDecoration: 'underline' }}>
              {file?.name} - {file?.size}
            </span>
            <IconButton
              style={{
                backgroundColor: colors.lightRed,
              }}
              onClick={() => setFile(null)}
            >
              <TrashIcon style={{ height: '0.75rem', width: '0.75rem', color: 'white' }} />
            </IconButton>
          </div>
        )}
      </div>
    </IconModal>
  );
};

const enforceMaxMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (e.target.value && isNumber(~~e.target.value)) {
    const value = e.target.value;
    if (toNumber(value) < 0) {
      e.target.value = 0 + '';
    } else if (toNumber(value) > 10) {
      e.target.value = 10 + '';
    }
  }
};

const SubjectPeriodRegistrationTable = (props: {
  ktwelve_subject: KtwelveSubjectAttributes;
  room: RoomAttributes;
  initial_current_composition_period: CompositionPeriodData;
}) => {
  const { ktwelve_subject, room, initial_current_composition_period } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [is_checked, setCheckbox] = React.useState(
    ktwelve_subject.evaluation_kind === KtwelveSubjectEvaluationKindEnum.CONCEPT,
  );
  const checkboxHandler = (value: boolean) => {
    setCheckbox(value);
  };
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile },
  } = state;
  const [subjectPeriodData, setSubjectPeriodData] = React.useState<SubjectPeriodData | null>(null);
  const fetchSubjectPeriodRegistrations = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        FETCH_SUBJECT_PERIOD_REGISTRATIONS.request({
          params: {
            filters: {
              'q[rooms_id_eq]': room.id,
              'page[size]': '100',
              'q[ktwelve_subject_id_eq]': ktwelve_subject.id,
              'q[composition_period_id_eq]': initial_current_composition_period.id,
              include: [
                'registration_results',
                'subject_period.formulas',
                'subject_period.exam_placements',
                'exams',
              ].join(','),
            },
          },
        }),
      );
      const {
        data: { data, included },
      } = response;
      const subject_period = find(
        included,
        (incl) =>
          incl.type === 'subject_periods' &&
          incl.attributes.composition_period_id === ~~initial_current_composition_period.id,
      ) as SubjectPeriodJson;
      const subject_period_formulas = map(
        filter(
          included,
          (incl) =>
            incl.type === 'formulas' &&
            incl.attributes.formulable_id === ~~subject_period.id &&
            incl.attributes.formulable_type === FormulableType.SUBJECT_PERIOD,
        ) as FormulaJson[],
        (formula) => ({
          id: formula.id,
          ...formula.attributes,
        }),
      );
      const subject_period_exam_placements = map(
        filter(
          included,
          (incl) =>
            incl.type === 'exam_placements' &&
            incl.attributes.exam_placeable_id === ~~subject_period.id &&
            incl.attributes.exam_placeable_type === ExamPlaceableType.SUBJECT_PERIOD,
        ) as ExamPlacementJson[],
        (exam_placement) => ({
          id: exam_placement.id,
          ...exam_placement.attributes,
        }),
      );
      const subject_period_formulas_steps = sortBy(map(subject_period_formulas, (formula) => formula.step));
      const composition_period_formulas_steps = sortBy(
        initial_current_composition_period.formulas.map((formula) => formula.step),
      );
      const formulas = map(union(subject_period_formulas_steps, composition_period_formulas_steps), (step) => {
        return (
          find(subject_period_formulas, (spf) => spf.step === step) ||
          find(initial_current_composition_period.formulas, (cpf) => cpf.step === step)
        );
      }) as FormulaAttributes[];

      const subject_period_exam_placement_order = sortBy(
        map(subject_period_exam_placements, (exam_placement) => exam_placement.order),
      );
      const composition_period_exam_placement_order = sortBy(
        map(initial_current_composition_period.exam_placements, (exam_placement) => exam_placement.order),
      );
      const exam_placements = map(
        union(subject_period_exam_placement_order, composition_period_exam_placement_order),
        (order) => {
          return (
            find(subject_period_exam_placements, (spep) => spep.order === order) ||
            find(initial_current_composition_period.exam_placements, (cpep) => cpep.order === order)
          );
        },
      ) as ExamPlacementAttributes[];
      const subject_period_registrations = map(data, (spr) => {
        const registration_results = map(
          filter(
            included,
            (incl) =>
              incl.type === 'registration_results' && incl.attributes.subject_period_registration_id === ~~spr.id,
          ) as RegistrationResultJson[],
          (rs) => ({
            id: rs.id,
            ...rs.attributes,
          }),
        );
        const exams = map(
          filter(
            included,
            (incl) => incl.type === 'exams' && incl.attributes.subject_period_registration_id === ~~spr.id,
          ) as ExamJson[],
          (exam) => ({
            id: exam.id,
            ...exam.attributes,
          }),
        );
        return {
          id: spr.id,
          ...spr.attributes,
          exams,
          registration_results,
        };
      });
      const result = {
        id: subject_period?.id,
        ...subject_period?.attributes,
        subject_period_registrations,
        exam_placements,
        formulas,
      };
      setSubjectPeriodData(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        error({
          message: 'Erro ao carregar disciplinas da turma',
        }),
      );
    }
  }, [ktwelve_subject, room, initial_current_composition_period]);

  const updateSubjectPeriodRegistrationResult = React.useCallback(
    async (props: { subject_period_registration_id: string; update_data: object }) => {
      try {
        const { subject_period_registration_id, update_data } = props;
        const response = await dispatch(
          UPDATE_SUBJECT_PERIOD_REGISTRATION.request({
            id: subject_period_registration_id,
            data: update_data,
          }),
        );
        const {
          data: { data },
        } = response;
        setSubjectPeriodData((current) => {
          if (current) {
            return {
              ...current,
              subject_period_registrations: current.subject_period_registrations.map((spr) => {
                if (spr.id === subject_period_registration_id) {
                  return {
                    ...spr,
                    ...data.attributes,
                  };
                }
                return spr;
              }),
            };
          }
          return current;
        });
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao atualizar resultado do aluno',
          }),
        );
      }
    },
    [subjectPeriodData, setSubjectPeriodData],
  );

  const init = React.useCallback(async () => {
    await fetchSubjectPeriodRegistrations();
  }, [initial_current_composition_period, room, ktwelve_subject]);

  const createExam = React.useCallback(
    async (props: {
      subject_period_registration_id: number;
      exam_placement_id: number;
      subject_period_id: number;
      kind: ExamKindEnum;
      score: number;
    }) => {
      const { subject_period_registration_id, exam_placement_id, kind, score } = props;
      try {
        await dispatch(
          CREATE_EXAM.request({
            data: {
              subject_period_registration_id,
              exam_placement_id,
              kind,
              score,
            },
          }),
        );
        const response = await dispatch(
          FETCH_SUBJECT_PERIOD_REGISTRATION.request({
            id: subject_period_registration_id.toString(),
            params: {
              filters: {
                include: ['registration_results', 'exams'].join(','),
              },
            },
          }),
        );
        const {
          data: { data: responseData, included },
        } = response;
        const registration_results = map(
          filter(included, (incl) => incl.type === 'registration_results') as RegistrationResultJson[],
          (rs) => ({
            id: rs.id,
            ...rs.attributes,
          }),
        );
        const exams = map(filter(included, (incl) => incl.type === 'exams') as ExamJson[], (exam) => ({
          id: exam.id,
          ...exam.attributes,
        }));
        setSubjectPeriodData((current) => {
          if (current) {
            return {
              ...current,
              subject_period_registrations: current.subject_period_registrations.map((spr) => {
                if (~~spr.id === subject_period_registration_id) {
                  return {
                    ...spr,
                    ...responseData.attributes,
                    exams,
                    registration_results,
                  };
                }
                return spr;
              }),
            };
          }
          return current;
        });
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao criar prova',
          }),
        );
      }
    },
    [subjectPeriodData, setSubjectPeriodData],
  );

  const updateExam = React.useCallback(
    async (props: {
      subject_period_registration_id: number;
      exam_id: number;
      subject_period_id: number;
      data: Partial<ExamAttributes>;
    }) => {
      const { subject_period_registration_id, exam_id, data } = props;
      try {
        await dispatch(
          UPDATE_EXAM.request({
            id: exam_id.toString(),
            data: {
              subject_period_registration_id,
              ...data,
            },
          }),
        );
        const response = await dispatch(
          FETCH_SUBJECT_PERIOD_REGISTRATION.request({
            id: subject_period_registration_id.toString(),
            params: {
              filters: {
                include: ['registration_results', 'exams'].join(','),
              },
            },
          }),
        );
        const {
          data: { data: responseData, included },
        } = response;
        const registration_results = map(
          filter(included, (incl) => incl.type === 'registration_results') as RegistrationResultJson[],
          (rs) => ({
            id: rs.id,
            ...rs.attributes,
          }),
        );
        const exams = map(filter(included, (incl) => incl.type === 'exams') as ExamJson[], (exam) => ({
          id: exam.id,
          ...exam.attributes,
        }));
        setSubjectPeriodData((current) => {
          if (current) {
            return {
              ...current,
              subject_period_registrations: current.subject_period_registrations.map((spr) => {
                if (~~spr.id === subject_period_registration_id) {
                  return {
                    ...spr,
                    ...responseData.attributes,
                    exams,
                    registration_results,
                  };
                }
                return spr;
              }),
            };
          }
          return current;
        });
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao criar prova',
          }),
        );
      }
    },
    [setSubjectPeriodData, subjectPeriodData],
  );
  React.useEffect(() => {
    init();
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div css={SliderWrapperCss}>
        <span>Notas</span>
        <div css={SliderCss}>
          <label className='switch'>
            <input
              checked={is_checked}
              onChange={(ev) => checkboxHandler(ev.target.checked)}
              id='slide-check'
              type='checkbox'
            />
            <span className='slider round' />
          </label>
        </div>
        <span>Frequência</span>
      </div>
      <Table stickyHeader css={TableCss} size='small' aria-label='subject_period_registrations'>
        <TableHead>
          <TableRow style={{ display: is_checked ? 'none' : 'table-row' }}>
            <TableCell align='left' colSpan={5} />
            {map(orderBy(subjectPeriodData?.exam_placements, 'order'), (exam_placement) => {
              return (
                <TableCell
                  colSpan={exam_placement.replaceable ? 2 : 1}
                  align='center'
                  key={`${exam_placement.id}-${ktwelve_subject.id}`}
                >
                  <Tooltip title={exam_placement.name}>
                    <span>{`A${exam_placement.order}`}</span>
                  </Tooltip>
                </TableCell>
              );
            })}
            {!isEmpty(subjectPeriodData?.formulas) && (
              <TableCell colSpan={size(subjectPeriodData?.formulas) + 1} align='center'>
                Médias
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell align='left' colSpan={5}>
              Nome
            </TableCell>
            {!is_checked &&
              map(orderBy(subjectPeriodData?.exam_placements, 'order'), (exam_placement) => {
                return (
                  <React.Fragment key={`${exam_placement.id}-${ktwelve_subject.id}`}>
                    <TableCell align='center' colSpan={1}>
                      <Tooltip title='Avaliação'>
                        <span>AV</span>
                      </Tooltip>
                    </TableCell>
                    {exam_placement.replaceable && (
                      <TableCell align='center' colSpan={1}>
                        <Tooltip title='Reposição'>
                          <span>AVR</span>
                        </Tooltip>
                      </TableCell>
                    )}
                  </React.Fragment>
                );
              })}
            {!is_checked &&
              map(orderBy(subjectPeriodData?.formulas, 'step'), (formula) => {
                return (
                  <TableCell align='center' colSpan={1} key={formula.id}>
                    <Tooltip title={formula.name}>
                      <span>{`M${formula.step}`}</span>
                    </Tooltip>
                  </TableCell>
                );
              })}
            <TableCell style={{ display: is_checked ? 'none' : 'table-cell' }} align='center' colSpan={1}>
              <Tooltip title={'Média Final'}>
                <span>{`MF`}</span>
              </Tooltip>
            </TableCell>
            {is_checked && (
              <>
                <TableCell align='center'>Total de Faltas</TableCell>
                <TableCell align='center'>Faltas sem justificativa</TableCell>
                <TableCell align='center'>Total de Aulas</TableCell>
                <TableCell align='center'>Frequência geral(%)</TableCell>
                <TableCell align='center'>Frequência c/ justificativa(%)</TableCell>
                {ktwelve_subject.evaluation_kind === KtwelveSubjectEvaluationKindEnum.DESCRIPTIVE && (
                  <TableCell>Parecer Descritivo</TableCell>
                )}
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(
            orderBy(subjectPeriodData?.subject_period_registrations, 'student_name'),
            (subject_period_registration) => {
              const onConfirmResultInput: onConfirm = async (props) => {
                const { setLoading, handleClose, setLoadingMessage } = props;
                try {
                  setLoading(true);
                  setLoadingMessage('Salvando resultado');
                  const input_element = document.querySelector(
                    `#result-input-${subject_period_registration.id}`,
                  ) as HTMLInputElement;
                  await updateSubjectPeriodRegistrationResult({
                    subject_period_registration_id: subject_period_registration.id,
                    update_data: {
                      result_input: isEmpty(input_element) ? null : input_element,
                    },
                  });
                  setLoading(false);
                  handleClose();
                } catch (err) {
                  dispatch(
                    error({
                      message: 'Erro ao salvar resultado de aluno',
                    }),
                  );
                  setLoading(false);
                  handleClose();
                }
              };
              const frequency_rate = subject_period_registration.frequency_rate || [];
              const disabled_final_score_button = ![
                Role.SUPERUSER,
                Role.COORDINATOR,
                Role.SCHOOL_MANAGER,
                Role.PEDAGOGICAL_ADMIN,
              ].includes(profile.role);

              const final_result = isEmpty(subject_period_registration.result_input)
                ? subject_period_registration.score
                : subject_period_registration.result_input;
              return (
                <React.Fragment key={subject_period_registration.id}>
                  <TableRow css={InputCss}>
                    <TableCell align='left' colSpan={5}>
                      {subject_period_registration.student_name}
                    </TableCell>
                    {!is_checked &&
                      map(orderBy(subjectPeriodData?.exam_placements, 'order'), (exam_placement) => {
                        const exams_for_this_placement = filter(
                          subject_period_registration.exams,
                          (exam) => exam.exam_placement_id === ~~exam_placement.id,
                        );
                        const default_exam = find(
                          exams_for_this_placement,
                          (exam) => exam.kind === ExamKindEnum.DEFAULT,
                        );
                        const replacement_exam = find(
                          exams_for_this_placement,
                          (exam) => exam.kind === ExamKindEnum.REPLACEMENT,
                        );
                        const exams = {
                          [ExamKindEnum.DEFAULT]: default_exam,
                          [ExamKindEnum.REPLACEMENT]: replacement_exam,
                        };
                        const changeExam = async (event: React.FocusEvent<HTMLInputElement, Element>) => {
                          const data_kind = event.target.getAttribute('data-kind') as ExamKindEnum;
                          const score = event.target.value;
                          if (isEmpty(exams[data_kind]) && score) {
                            await createExam({
                              subject_period_id: subject_period_registration.subject_period_id,
                              subject_period_registration_id: ~~subject_period_registration.id,
                              kind: data_kind,
                              exam_placement_id: ~~exam_placement.id,
                              score: toNumber(score),
                            });
                          } else if (exams[data_kind]) {
                            const exam_data = exams[data_kind] as ExamAttributes;
                            await updateExam({
                              subject_period_id: subject_period_registration.subject_period_id,
                              subject_period_registration_id: ~~subject_period_registration.id,
                              data: {
                                score: !isEmpty(score) ? ~~score : null,
                              },
                              exam_id: toNumber(exam_data.id),
                            });
                          }
                        };
                        const onConfirmSaveIrregulairy = async (props: {
                          setLoading: React.Dispatch<React.SetStateAction<boolean>>;
                          handleClose: () => void;
                          setLoadingMessage: React.Dispatch<React.SetStateAction<string>>;
                          exam_id: string;
                        }) => {
                          const { setLoading, handleClose, setLoadingMessage, exam_id } = props;
                          try {
                            setLoading(true);
                            setLoadingMessage('Salvando irregularidade');
                            const irregularity_input_element = document.querySelector(
                              `#input-irregularity-${exam_id} input`,
                            ) as HTMLInputElement;
                            const comments_input_element = document.querySelector(
                              `#input-comments-${exam_id} textarea`,
                            ) as React.HTMLProps<HTMLTextAreaElement>;
                            await updateExam({
                              subject_period_registration_id: ~~subject_period_registration.id,
                              exam_id: ~~exam_id,
                              data: {
                                irregularity: (irregularity_input_element?.value || '') as ExamIrregularityEnum,
                                comments: (comments_input_element.value as string) || '',
                              },
                              subject_period_id: subject_period_registration.subject_period_id,
                            });
                            setLoading(false);
                            handleClose();
                          } catch (err) {
                            dispatch(
                              error({
                                message: 'Erro ao salvar resultado de aluno',
                              }),
                            );
                            setLoading(false);
                            handleClose();
                          }
                        };
                        const disabled_fields =
                          profile.role === Role.TEACHER
                            ? isBefore(new Date(), new Date(subjectPeriodData?.insert_starts_at as string)) ||
                              isAfter(new Date(), new Date(subjectPeriodData?.insert_ends_at as string))
                            : ![Role.COORDINATOR, Role.SUPERUSER, Role.SCHOOL_MANAGER, Role.PEDAGOGICAL_ADMIN].includes(
                                profile.role,
                              ) || subject_period_registration.status !== SubjectPeriodRegistrationStatusEnum.ENROLLED;
                        const examButtonCss = css`
                          ${default_exam && `#input-irregularity-${default_exam?.id} { min-width: 50% }`}
                          ${replacement_exam && `#input-irregularity-${replacement_exam?.id} { min-width: 50% }`}
                        `;
                        const disabled_default_exam_comment_button =
                          !default_exam || !isNumber(default_exam?.score) || default_exam.score > 0;
                        const disabled_replacement_exam_comment_button =
                          !replacement_exam || !isNumber(replacement_exam?.score) || replacement_exam.score > 0;
                        return (
                          <React.Fragment key={exam_placement.id}>
                            <TableCell align='center' colSpan={1}>
                              <div>
                                <input
                                  min={0}
                                  max={10}
                                  onChange={enforceMaxMinChange}
                                  type='number'
                                  disabled={disabled_fields}
                                  onBlur={changeExam}
                                  data-kind={ExamKindEnum.DEFAULT}
                                  style={{ width: '50px' }}
                                  defaultValue={!isNumber(default_exam?.score) ? '' : (default_exam?.score as number)}
                                />
                                {!disabled_default_exam_comment_button && (
                                  <IconModal
                                    icon={MdErrorOutline}
                                    disabled={disabled_default_exam_comment_button}
                                    iconProps={{
                                      style: {
                                        display: disabled_default_exam_comment_button ? 'none' : 'block',
                                        color: colors.blue,
                                      },
                                    }}
                                    onConfirm={(props) =>
                                      onConfirmSaveIrregulairy({ ...props, exam_id: default_exam?.id as string })
                                    }
                                    title={'Adicionar comentário e/ou irregularidade'}
                                    tooltipText={'Adicionar comentário e/ou irregularidade'}
                                    confirmButtonText='Salvar'
                                  >
                                    <div css={examButtonCss}>
                                      <SelectComponent
                                        placeholder='Selecionar Irregularidade'
                                        small
                                        input={{
                                          label: 'Irregularidade',
                                          placeholder: 'Selecionar opção de irregularidade',
                                          name: `irregularity-${default_exam?.id}`,
                                          defaultValue: default_exam?.irregularity,
                                        }}
                                        options={examIrregularityOptions}
                                      />
                                      <TextAreaComponent
                                        input={{
                                          placeholder: 'Comentários',
                                          defaultValue: default_exam?.comments,
                                          name: `comments-${default_exam?.id}`,
                                        }}
                                      />
                                    </div>
                                  </IconModal>
                                )}
                              </div>
                            </TableCell>
                            {exam_placement.replaceable && (
                              <TableCell align='center' colSpan={1}>
                                <div css={InputCss}>
                                  <input
                                    onChange={enforceMaxMinChange}
                                    min={0}
                                    max={10}
                                    type='number'
                                    disabled={disabled_fields}
                                    onBlur={changeExam}
                                    data-kind={ExamKindEnum.REPLACEMENT}
                                    style={{ width: '50px' }}
                                    defaultValue={replacement_exam?.score || ''}
                                  />
                                  {disabled_replacement_exam_comment_button && (
                                    <IconModal
                                      icon={MdErrorOutline}
                                      disabled={disabled_replacement_exam_comment_button}
                                      iconProps={{
                                        style: {
                                          display: disabled_replacement_exam_comment_button ? 'none' : 'block',
                                          color: colors.blue,
                                        },
                                      }}
                                      onConfirm={(props) =>
                                        onConfirmSaveIrregulairy({ ...props, exam_id: replacement_exam?.id as string })
                                      }
                                      title={'Adicionar comentário e/ou irregularidade'}
                                      tooltipText={'Adicionar comentário e/ou irregularidade'}
                                      confirmButtonText='Salvar'
                                    >
                                      <div css={examButtonCss}>
                                        <SelectComponent
                                          placeholder='Selecionar Irregularidade'
                                          small
                                          input={{
                                            label: 'Irregularidade',
                                            placeholder: 'Selecionar opção de irregularidade',
                                            name: `irregularity-${replacement_exam?.id}`,
                                            defaultValue: replacement_exam?.irregularity,
                                          }}
                                          options={examIrregularityOptions}
                                        />
                                        <TextAreaComponent
                                          input={{
                                            placeholder: 'Comentários',
                                            defaultValue: replacement_exam?.comments,
                                            name: `comments-${replacement_exam?.id}`,
                                          }}
                                        />
                                      </div>
                                    </IconModal>
                                  )}
                                </div>
                              </TableCell>
                            )}{' '}
                          </React.Fragment>
                        );
                      })}
                    {!is_checked &&
                      map(orderBy(subjectPeriodData?.formulas, 'step'), (formula) => {
                        const result_for_this_formula = find(
                          subject_period_registration.registration_results,
                          (rr) => rr.formula_id === ~~formula.id,
                        );
                        return (
                          <TableCell align='center' colSpan={1} key={formula.id}>
                            <Tooltip title={formula.name}>
                              <span>{result_for_this_formula?.score}</span>
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    <TableCell style={{ display: is_checked ? 'none' : 'table-cell' }} align='center' colSpan={1}>
                      <span>{final_result}</span>
                      <IconModal
                        icon={PenIcon}
                        disabled={disabled_final_score_button}
                        iconProps={{
                          style: {
                            color: colors.blue,
                            display: disabled_final_score_button ? 'none' : 'block',
                          },
                        }}
                        onConfirm={onConfirmResultInput}
                        title={'Ajustar média final'}
                        tooltipText={'Ajustar média final'}
                        confirmButtonText='Salvar'
                      >
                        <input
                          min={0}
                          max={10}
                          onChange={enforceMaxMinChange}
                          type='number'
                          id={`result-input-${subject_period_registration.id}`}
                          defaultValue={subject_period_registration.result_input}
                        />
                      </IconModal>
                    </TableCell>
                    {is_checked && (
                      <>
                        <TableCell align='center'>{subject_period_registration.frequency_data[1]}</TableCell>
                        <TableCell align='center'>{subject_period_registration.frequency_data[0]}</TableCell>
                        <TableCell align='center'>{subject_period_registration.frequency_data[2]}</TableCell>
                        <TableCell align='center'>{compactSum([100, (-frequency_rate[0] || 0) * 100])}</TableCell>
                        <TableCell align='center'>{compactSum([100, (-frequency_rate[1] || 0) * 100])}</TableCell>
                        {ktwelve_subject.evaluation_kind === KtwelveSubjectEvaluationKindEnum.DESCRIPTIVE && (
                          <TableCell>
                            {subject_period_registration.descriptive_opinion_url && (
                              <TooltipButton
                                tooltipProps={{
                                  title: 'Ver Parecer Descritivo',
                                }}
                                iconButtonProps={{
                                  href: subject_period_registration.descriptive_opinion_url,
                                  target: '_blank',
                                  rel: 'noreferrer',
                                }}
                                Icon={Visibility}
                              />
                            )}
                            <DescriptionOpinionForm
                              subject_period_registration={subject_period_registration}
                              updateSubjectPeriodRegistrationResult={updateSubjectPeriodRegistrationResult}
                            />
                          </TableCell>
                        )}
                      </>
                    )}
                  </TableRow>
                </React.Fragment>
              );
            },
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default SubjectPeriodRegistrationTable;
