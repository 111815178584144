import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const auditsEndpoint = '/audits'

export const fetchAudits = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${auditsEndpoint}?${queryParams}`)
}

export const fetchAudit = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${auditsEndpoint}/${id}?${queryParams}`)
}

export const createAudit = (data: object) => {
  return http.post(`${auditsEndpoint}`, data)
}

export const updateAudit = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${auditsEndpoint}/${id}`, data)
}

export const deleteAudit = ({ id }: { id: string | number } ) => {
  return http.delete(`${auditsEndpoint}/${id}`)
}