import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_ALERGIES,
  DELETE_USER_ALERGY,
  FetchAlergiesPayload,
  DeleteUserAlergyPayload,
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchAlergies (action: DefaultAction<FetchAlergiesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchAlergies, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ALERGIES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ALERGIES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteUserAlergy (action: DefaultAction<DeleteUserAlergyPayload, string>): any {
  const { payload:{id}} = action
  try {
    const response = yield call(services.deleteUserAlergy, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_USER_ALERGY.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_USER_ALERGY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchAlergiesSagas () {
  yield takeLatest(FETCH_ALERGIES.request, fetchAlergies)
  yield takeLatest(DELETE_USER_ALERGY.request, deleteUserAlergy)
}

export default function * alergySagas () {
  yield all([watchAlergiesSagas()])
}
