import React from 'react'
import { makeStyles, DefaultTheme } from '@mui/styles'

import { colors } from '../../../utils/constants'

type Props = {
  disabled: boolean
}

const useStyles = makeStyles<DefaultTheme, Props>(() => ({
  inputArea: {
    display: 'grid',
    position: 'relative',
    '& span': {
      justifySelf: 'flex-start',
      color: colors.darkGrayBlue,
      fontSize: '0.8rem'
    },
    '& span.error': {
      color: colors.lightRed
    },
    '& span.error:empty:before': {
      content: '"\\200b"'
    },
    '& div': {
      display: 'grid',
      background: (props) => (props.disabled ? colors.lightBlue : 'white'),
      justifyItems: 'flex-start',
      cursor: 'text',
      borderRadius: '4px',
      border: `1px solid ${colors.grayBlue}`,
      padding: '0.5rem 0.75rem',
      '& label': {
        color: colors.darkGrayBlue,
        fontSize: '10px'
      },
      '& textarea': {
        border: 'none',
        outline: 'none',
        width: '100%'
      },
      '& ::placeholder': {
        color: colors.grayBlue,
        fontSize: '16px'
      },
      '& span': {
        justifySelf: 'flex-start'
      }
    }
  }
}))

const TextAreaComponent = (props: any) => {
  const { label, input, meta, ...rest } = props
  const inputRef = React.useRef<HTMLInputElement>()
  const classes = useStyles({
    disabled: input.disabled || rest.disabled
  })
  return (
    <div className={classes.inputArea} id={`input-${input.name}`}>
      <div onClick={() => inputRef?.current?.focus()}>
        <label> {label} </label>
        <textarea data-testid={input.name} {...input} {...rest} ref={inputRef} />
      </div>
      <span className='error'>{meta && meta.touched && meta.error && meta.error}</span>
    </div>
  )
}

export default TextAreaComponent
