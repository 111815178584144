import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const userAlergiesEndpoint = '/user_alergies'
const alergiesEndpoint = '/alergies'

export const deleteUserAlergy = ({ id }: { id: string | number }) => {
  return http.delete(`${userAlergiesEndpoint}/${id}`)
}

export const fetchAlergies = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${alergiesEndpoint}?${queryParams}`)
}
