import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  CREATE_REGISTRATION_CLOSURE,
  UPDATE_REGISTRATION_CLOSURE,
  CreateRegistrationClosurePayload,
  UpdateRegistrationClosurePayload,
  FetchRegistrationClosurePayload,
  FETCH_REGISTRATION_CLOSURE,
  DeleteRegistrationClosurePayload,
  DELETE_REGISTRATION_CLOSURE
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'
import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRegistrationClosure (action: DefaultAction<FetchRegistrationClosurePayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchRegistrationClosure, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_REGISTRATION_CLOSURE.success(response))
    resolvePromiseAction(action, response)
    
  } catch(err) {
    yield put(FETCH_REGISTRATION_CLOSURE.failure(err))
    rejectPromiseAction(action, err)
  }
}

export function * createRegistrationClosure (action: DefaultAction<CreateRegistrationClosurePayload, string>): any {
  const { payload: {data}} = action
  const reqBody = {
    data: {
      type: 'registration_closures',
      attributes: { ...data }
    }
  }
  try {
    const response = yield call(services.createRegistrationClosure, reqBody)
    const {headers} = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_REGISTRATION_CLOSURE.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_REGISTRATION_CLOSURE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateRegistrationClosure (action: DefaultAction<UpdateRegistrationClosurePayload, string>): any {
  const {payload: {data, id}} = action
  const reqBody = {
    data: {
      id,
      type: 'registration_closures',
      attributes: { ...data }
    }
  }

  try {
    const response = yield call(services.updateRegistrationClosure, {
      data: reqBody,
      id
    })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(UPDATE_REGISTRATION_CLOSURE.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_REGISTRATION_CLOSURE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteRegistrationClosure (action: DefaultAction<DeleteRegistrationClosurePayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.deleteRegistrationClosure, {
      id
    })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_REGISTRATION_CLOSURE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_REGISTRATION_CLOSURE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRegistrationClosureSagas () {
  yield takeLatest(CREATE_REGISTRATION_CLOSURE.request, createRegistrationClosure)
  yield takeLatest(UPDATE_REGISTRATION_CLOSURE.request, updateRegistrationClosure)
  yield takeLatest(DELETE_REGISTRATION_CLOSURE.request, deleteRegistrationClosure)
}

export default function * registrationClosureSagas () {
  yield all([watchRegistrationClosureSagas()])
}
