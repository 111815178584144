/* eslint-disable camelcase */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import LockResetIcon from '@mui/icons-material/LockReset';
import LoadingWithLabel from '../shared/LoadingWithLabel';

import { IconModal, LoadingBackdrop } from '../modal/Modal';

import {
  colors,
  profiles,
  FormattedUserData,
  Paths,
  PaginationType,
  ProfileTypes,
  Role,
  TransactionAttributes,
  defaultPagination,
} from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { Collapse, IconButton, Tooltip } from '@mui/material';
import PenIcon from '../icon/PenIcon';
import { nameToInitials } from '../../utils/functions';
import { isNil, map, uniq } from 'lodash';
import { DELETE_USER, RECOVER_PASSWORD } from '../../store/users';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import TrashIcon from '../icon/TrashIcon';
import { History } from '@mui/icons-material';
import { FETCH_TRANSACTIONS } from '../../store/transactions';
import Loading from '../loading/Loading';
import TransactionsTable from '../table/TransactionsTable';
import DatePickerComponent from '../input/form/datepicker';
import { format } from 'date-fns';

const useStyles = makeStyles(
  (
    theme: Theme,
  ): {
    view: any;
    filters: any;
    table: any;
    tableHeader: any;
    initials: any;
  } => {
    return {
      view: {
        width: 'inherit',
        minHeight: '100%',
        height: 'fit-content',
        display: 'flex',
        padding: '2rem',
        flexDirection: 'column',
        flexGrow: '1',
        position: 'relative',
        [theme.breakpoints?.down('lg')]: {
          padding: '2rem 1rem',
        },
        '& .MuiBackdrop-root': {
          position: 'inherit',
        },
        '& .MuiPaper-root': {
          background: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      },
      filters: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '1rem',
        '& > span': {
          marginBottom: '14px',
        },
        '& .inputArea': {
          width: '10rem !important',
          paddingRight: '3rem',
        },
      },
      tableHeader: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1rem',
      },
      table: {
        background: colors.lightBlue,
        padding: '2rem',
        borderRadius: '4px',
        display: 'grid',
        '& .subtitle': {
          textAlign: 'start',
          marginBottom: '1rem',
        },
        '& .MuiTableContainer-root': {
          background: 'inherit',
          boxShadow: 'none',
          '& .MuiTableCell-root': {
            borderBottom: `1px solid ${colors.grayBlue}`,
          },

          '& th': {
            color: colors.darkGrayBlue,
          },
          '& td': {
            fontSize: '12px',
            color: colors.darkBlue,
            [theme.breakpoints?.down('lg')]: {
              fontSize: '0.75rem',
            },
            '& svg': {
              cursor: 'pointer',
              margin: '0 0.25rem',
            },
          },
          '& td.MuiTableCell-footer ': {
            borderBottom: 'none',
          },
        },
      },
      initials: {
        background: 'lightblue',
        borderRadius: '50%',
        display: 'flex',
        width: '2rem',
        height: '2rem',
        '& > span': {
          fontSize: '1rem',
          margin: 'auto',
        },
        '& > img': {
          width: '100%',
          height: '100%',
          margin: 'auto',
          borderRadius: '50%',
        },
      },
    };
  },
);

const RowLoading = ({ loading = false }) => {
  if (!loading) {
    return null;
  }
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          zIndex: '1',
          backgroundColor: colors.lightBlue,
        }}
      >
        <LoadingWithLabel message={'Carregando'} />
      </div>
    </div>
  );
};

const TransactionsRow = ({
  user,
  load_resources = false,
  loadingUsers,
  setLoadingUsers,
  open = false,
}: {
  user: FormattedUserData;
  loadingUsers: {
    id: string;
    message: string;
  }[];
  setLoadingUsers: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        message: string;
      }[]
    >
  >;
  load_resources?: boolean;
  open: boolean;
}) => {
  const initial_data = {
    transactions: [] as TransactionAttributes[],
  };
  const dispatch = useDispatch();
  const isLoading = loadingUsers.some((item) => item.id === user.id);
  const [data, setData] = React.useState(initial_data);
  const [loadingData, setLoadingData] = React.useState(load_resources);
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [fromEventDateFilterValue, setFromEventDateFilterValue] = React.useState<Date | null>(null);
  const [toEventDateFilterValue, settoEventDateFilterValue] = React.useState<Date | null>(null);
  const [descriptionFilterValue, setDescriptionFilterValue] = React.useState('');

  const fetchTransactions = React.useCallback(
    async ({
      newPagination = pagination,
      from_event_date_filter = fromEventDateFilterValue,
      to_event_date_filter = toEventDateFilterValue,
    }: {
      newPagination?: PaginationType;
      from_event_date_filter?: Date | null;
      to_event_date_filter?: Date | null;
    }) => {
      const user_id = user.id;
      try {
        setLoadingUsers((current) => current.concat({ id: user_id, message: 'Carregando histórico de transações' }));
        const event_date_filter_object = {
          ...(from_event_date_filter && {
            'q[expiration_date_gteq]': format(new Date(from_event_date_filter), 'yyyy-MM-dd'),
          }),
          ...(to_event_date_filter && {
            'q[expiration_date_lteq]': format(new Date(to_event_date_filter), 'yyyy-MM-dd'),
          }),
        };
        const response = await dispatch(
          FETCH_TRANSACTIONS.request({
            params: {
              filters: {
                'q[transactable_type_eq]': 'User',
                'q[transactable_id_eq]': user_id,
                'q[description_cont]': descriptionFilterValue,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                ...event_date_filter_object,
              },
            },
          }),
        );
        const {
          data: { data, meta },
        } = response;
        const transactions = map(data, (item) => ({
          id: item.id,
          ...item.attributes,
        })) as TransactionAttributes[];
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setData({
          transactions,
        });
        dispatch(
          success({
            title: 'Transações',
            message: 'Transações carregadas!',
            autoDismiss: 3,
          }),
        );
        setLoadingUsers((current) => current.filter((item) => item.id !== user_id));
      } catch (err) {
        setLoadingUsers((current) => current.filter((item) => item.id !== user_id));
        dispatch(
          error({
            message: 'Erro no carregamento de transações',
          }),
        );
      }
      setLoadingData(false);
    },
    [user, pagination, descriptionFilterValue, toEventDateFilterValue, fromEventDateFilterValue],
  );

  const handlePageChange = (_: any, newPage: number) => {
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
    fetchTransactions({
      newPagination: {
        ...pagination,
        pageNumber: newPage,
      },
    });
  };

  const handleChangePageSize = (e: any) => {
    setPagination({
      ...pagination,
      pageSize: e.target.value,
    });
    fetchTransactions({
      newPagination: {
        ...pagination,
        pageSize: e.target.value,
      },
    });
  };

  React.useEffect(() => {
    if (load_resources) {
      fetchTransactions({});
    }
  }, []);

  if (!open) {
    return null;
  }
  if (isLoading) {
    return <RowLoading loading={isLoading} />;
  }

  if (loadingData) {
    return <Loading />;
  }

  return (
    <div style={{ display: 'grid' }}>
      <div style={{ display: 'flex', margin: '1rem 0' }}>
        <InputComponent
          placeholder={`Buscar por descrição`}
          search
          small
          onSearch={() => {
            fetchTransactions({});
          }}
          input={{
            value: descriptionFilterValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setDescriptionFilterValue(e.target.value);
            },
          }}
        />
        <DatePickerComponent
          small
          placeholder='Evento a partir de'
          datePickerProps={{
            allowSameDateSelection: true,
          }}
          clearFieldFunction={() => {
            setFromEventDateFilterValue(null);
            fetchTransactions({
              from_event_date_filter: null,
            });
          }}
          input={{
            value: fromEventDateFilterValue,
            name: 'year',
            onChange: (e: Date) => {
              setFromEventDateFilterValue(e);
              fetchTransactions({
                from_event_date_filter: e,
              });
            },
          }}
        />
        <DatePickerComponent
          small
          placeholder='Evento até'
          datePickerProps={{
            allowSameDateSelection: true,
          }}
          clearFieldFunction={() => {
            settoEventDateFilterValue(null);
            fetchTransactions({
              to_event_date_filter: null,
            });
          }}
          input={{
            value: toEventDateFilterValue,
            name: 'year',
            onChange: (e: Date) => {
              settoEventDateFilterValue(e);
              fetchTransactions({
                to_event_date_filter: e,
              });
            },
          }}
        />
      </div>
      <TransactionsTable
        handleChangePageSize={handleChangePageSize}
        handlePageChange={handlePageChange}
        pagination={pagination}
        transactions={data.transactions}
      />
      ;
    </div>
  );
};

const UsersView = ({
  renderReady,
  users,
  pagination,
  handleChangePageSize,
  handlePageChange,
  nameFilter,
  setNameFilter,
  companyFilter,
  setCompanyFilter,
  fetchUsersMethod,
  defaultPagination,
  profileOptions,
  profileFilter,
  setProfileFilter,
  profile,
  emailFilter,
  setEmailFilter,
}: {
  renderReady: boolean;
  users: FormattedUserData[];
  pagination: PaginationType;
  defaultPagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  nameFilter: string;
  setNameFilter: React.Dispatch<React.SetStateAction<string>>;
  companyFilter: string;
  setCompanyFilter: React.Dispatch<React.SetStateAction<string>>;
  fetchUsersMethod: ({
    newPagination,
    oldPagination,
    profile,
    email,
  }: {
    newPagination?: PaginationType;
    oldPagination?: PaginationType;
    profile?: ProfileTypes | string;
    email?: string;
  }) => void;
  profileOptions:
    | {
        value: ProfileTypes;
        label: string | undefined;
      }[]
    | undefined;
  profileFilter: ProfileTypes | string;
  setProfileFilter: React.Dispatch<React.SetStateAction<ProfileTypes | string>>;
  profile: {
    id: string;
    label: string;
    role: ProfileTypes;
  };
  emailFilter: string;
  setEmailFilter: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const history = useHistory();
  const [expandedTransactions, setExpandedTransactions] = React.useState<number[]>([]);
  const [loadingUsers, setLoadingUsers] = React.useState<{ id: string; message: string }[]>([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const columns = isTabletOrMobile
    ? ['Usuário']
    : ['', 'Nome', 'Telefone', 'Email', 'Perfil', 'Tipo Doc.', 'N° Doc.', 'Estado', 'Cidade', 'Empresa', ''];

  const recoverPassword = React.useCallback(
    async (
      email: string,
      {
        setLoading,
        handleClose,
      }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    ) => {
      try {
        setLoading(true);
        await dispatch(
          RECOVER_PASSWORD.request({
            data: {
              email,
            },
          }),
        );
        setLoading(false);
        handleClose();
      } catch (er) {
        setLoading(false);
        handleClose();
        dispatch(
          error({
            message: 'Erro ao enviar email de redefinição de senha',
          }),
        );
      }
    },
    [],
  );

  const deleteUser = React.useCallback(
    async (
      id: string,
      {
        setLoading,
        handleClose,
      }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    ) => {
      try {
        setLoading(true);
        await dispatch(
          DELETE_USER.request({
            id,
          }),
        );
        fetchUsersMethod({});
        setLoading(false);
        handleClose();
      } catch (err) {
        setLoading(false);
        handleClose();
        dispatch(
          error({
            message: 'Erro ao excluir usuário',
          }),
        );
      }
    },
    [users],
  );
  return (
    <div className={classes.view}>
      <LoadingBackdrop open={!renderReady} />
      <div className={classes.tableHeader}>
        <span className='title'>Lista de usuários</span>
        <button onClick={() => history.push(Paths.USERS_FORM)} className='green small'>
          <span>Criar usuário</span>
        </button>
      </div>
      <div className={classes.filters}>
        <InputComponent
          placeholder='Buscar por nome'
          small
          search
          onSearch={() => {
            fetchUsersMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
          input={{
            value: nameFilter,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setNameFilter(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder='Buscar por email'
          small
          search
          onSearch={() => {
            fetchUsersMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
          input={{
            value: emailFilter,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setEmailFilter(e.target.value);
            },
          }}
        />
        <InputComponent
          placeholder='Buscar por companhia'
          small
          search
          input={{
            value: companyFilter,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyFilter(e.target.value);
            },
          }}
          onSearch={() => {
            fetchUsersMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
            });
          }}
        />
        <SelectComponent
          placeholder='Buscar por perfil'
          small
          options={profileOptions}
          clearable
          clearFieldFunction={() => {
            setProfileFilter('');
            fetchUsersMethod({
              newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
              profile: '',
            });
          }}
          input={{
            value: profileFilter,
            onChange: (e: any) => {
              let value = '';
              if (e?.target?.value) {
                value = e.target.value;
              }
              setProfileFilter(value);
              fetchUsersMethod({
                newPagination: { ...defaultPagination, pageSize: pagination.pageSize },
                profile: value,
              });
            },
          }}
        />
      </div>
      <hr style={{ color: colors.darkGrayBlue, width: '100%' }} />
      <div className={classes.table}>
        <TableContainer component={Paper}>
          <Table data-testid='users-table'>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row, index) => {
                const {
                  name,
                  phone,
                  email,
                  profiles: userProfiles,
                  document_type,
                  document_number,
                  state,
                  city,
                  company,
                  avatar_url,
                } = row;
                const isLast = index === users.length - 1;
                const profilesInfo = uniq(
                  userProfiles?.map((item) => {
                    return profiles.find((profile) => profile.name === item)?.label;
                  }),
                ).join(', ');
                const transactionsExpanded = expandedTransactions.includes(~~row.id);

                return (
                  <>
                    <TableRow key={row.id}>
                      {isTabletOrMobile ? (
                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              gap: '0.5rem',
                              flexWrap: 'wrap',
                            }}
                          >
                            {name && (
                              <span>
                                <strong>Nome:</strong> {name}
                              </span>
                            )}
                            {phone && (
                              <span>
                                <strong>Telefone:</strong> {phone && phone.includes('undefined') ? '' : phone}
                              </span>
                            )}
                            {email && (
                              <span>
                                <strong>Email:</strong> {email}
                              </span>
                            )}
                            {profiles && profiles.length && (
                              <span>
                                <strong>Perfil:</strong>
                                {profilesInfo}
                              </span>
                            )}
                            {profiles && (
                              <span>
                                <strong>Documento:</strong>
                                {document_type} {document_number}
                              </span>
                            )}
                            {state && (
                              <span>
                                <strong>Estado:</strong> {state}
                              </span>
                            )}
                            {city && (
                              <span>
                                <strong>Cidade:</strong> {city}
                              </span>
                            )}
                            {company && (
                              <span>
                                <strong>Companhia:</strong> {company}
                              </span>
                            )}
                          </div>
                        </TableCell>
                      ) : (
                        <>
                          <TableCell>
                            <div className={classes.initials}>
                              {avatar_url ? <img alt={name} src={avatar_url} /> : <span> {nameToInitials(name)} </span>}
                            </div>
                          </TableCell>
                          <TableCell>{name}</TableCell>
                          <TableCell>{phone && phone.includes('undefined') ? '' : phone}</TableCell>
                          <TableCell>{email}</TableCell>
                          <TableCell>{profilesInfo}</TableCell>
                          <TableCell>{document_type}</TableCell>
                          <TableCell>{document_number}</TableCell>
                          <TableCell>{state}</TableCell>
                          <TableCell>{city}</TableCell>
                          <TableCell>{company}</TableCell>
                          <TableCell>
                            <div style={{ display: 'flex' }}>
                              <IconButton
                                onClick={() => {
                                  history.push(Paths.USERS_FORM, {
                                    user_id: row.id,
                                  });
                                }}
                              >
                                <PenIcon style={{ color: colors.blue }} />
                              </IconButton>
                              {profile.role === Role.SUPERUSER && (
                                <>
                                  {!isNil(row.email) && (
                                    <IconModal
                                      icon={LockResetIcon}
                                      title={`Enviar email de redefinição de senha para ${row.name}`}
                                      tooltipText='Redefinir senha'
                                      onConfirm={(props) => recoverPassword(row.email, props)}
                                      message='Deseja prosseguir?'
                                    />
                                  )}
                                  <IconModal
                                    icon={TrashIcon}
                                    title={'Excluir usuário'}
                                    tooltipText='Excluir usuário'
                                    onConfirm={(props) => deleteUser(row.id, props)}
                                    message='Tem certeza que deseja excluir o usuário?'
                                    iconProps={{ style: { color: 'red' } }}
                                  />
                                  <Tooltip title={`${transactionsExpanded ? 'Ocultar' : 'Ver'} eventos do usuário`}>
                                    <IconButton
                                      onClick={() =>
                                        setExpandedTransactions((current) => {
                                          if (current.includes(~~row.id)) {
                                            return current.filter((item) => item !== ~~row.id);
                                          } else {
                                            return current.concat(~~row.id);
                                          }
                                        })
                                      }
                                    >
                                      <History />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0, ...(isLast && { borderBottom: 0 }) }}
                        colSpan={columns.length + 1}
                      >
                        <Collapse
                          in={expandedTransactions.includes(~~row.id)}
                          timeout='auto'
                          unmountOnExit
                          mountOnEnter
                        >
                          <TransactionsRow
                            loadingUsers={loadingUsers}
                            setLoadingUsers={setLoadingUsers}
                            load_resources
                            user={row}
                            open={expandedTransactions.includes(~~row.id)}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : ` 'mais de' ${to}`}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default UsersView;
