/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reduxForm,
  Field,
  reset,
  change,
  FormSection,
  FieldArray,
  WrappedFieldArrayProps,
  formValueSelector,
  getFormInitialValues,
} from 'redux-form';
import { compose } from 'redux';

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

import withRaces from '../hoc/racesHoc';

import InputComponent from '../input/form/input';
import RadioComponent from '../input/form/radio';

import {
  colors,
  relationshipOptions,
  validation,
  ResponsibleStepFormData,
  UserOptionsData,
  CurrentResponsibleData,
  ResponsibleStepFormInitialValues,
  NestedRepresentativeAttributes,
  UserFormFields,
  ConnectionEnum,
  CurrentRegistrationData,
  RepresentativeFormUserAttributes,
} from '../../utils/constants';
import { convertToCNPJ, convertToCPF } from '../../utils/functions';
import UserForm from './UserForm';
import { get, isEmpty } from 'lodash';
import { RootState } from '../../store/configureStore';
const form_name = 'registration-responsiblestep';

const useStyles = makeStyles((theme: Theme) => ({
  formButtons: {
    display: 'flex',
    justifyContent: 'end',
    gap: '1rem',
    marginTop: '4.5rem',
  },
  form: {
    background: colors.lightBlue,
    padding: '2rem',
    borderRadius: '16px',
    display: 'grid',
    rowGap: '0.5rem',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem',
      rowGap: '0.25rem',
    },
    '& .user-form': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      rowGap: '1rem',
      '& > div': {
        flexBasis: '100%',
      },
      '& #input-nationality_country': {
        flexBasis: '32.6666666%',
        order: 1,
      },
      '& #input-nationality_state': {
        flexBasis: '32.6666666%',
        order: 2,
      },
      '& #input-nationality_city': {
        flexBasis: '32.6666666%',
        order: 3,
      },
      '& #input-document_number': {
        order: 4,
        flexBasis: '24.5%',
      },
      '& #input-name': {
        flexBasis: '24.5%',
        order: 5,
      },
      '& #input-email': {
        flexBasis: '24.5%',
        order: 6,
      },
      '& div[id$="personal_email"]': {
        flexBasis: '24.5%',
        order: 7,
      },
      '& #input-phone': {
        flexBasis: '32.6666666%',
        order: 8,
      },
      '& #input-gender': {
        flexBasis: '32.6666666%',
        order: 9,
      },
      '& #input-birthdate': {
        flexBasis: '32.6666666%',
        order: 10,
      },
      '& .nationality-span': {
        display: 'none',
      },
      '& #input-address_form': {
        order: 11,
      },
      '& div[id$="street"]': {
        flexBasis: '40%',
      },
      '& div[id$="number"]': {
        flexBasis: '24%',
      },
      '& div[id$="complement"]': {
        flexBasis: '35%',
      },
      '& div[id$="neighbourhood"]': {
        flexBasis: '30%',
      },
      '& #input-address_form > div[id$="country_id"]': {
        flexBasis: `20%`,
      },
      '& #input-address_form > div[id$="state_id"]': {
        flexBasis: `25%`,
      },
      '& #input-address_form > div[id$="city_id"]': {
        flexBasis: `22.5%`,
      },
    },
    '& hr': {
      borderColor: colors.grayBlue,
      width: '100%',
      margin: '2rem auto',
      [theme.breakpoints.down('lg')]: {
        margin: 'auto',
      },
    },
    '& .title': {
      fontWeight: 'bold',
      fontSize: '1.75rem',
      justifySelf: 'center',
      marginBottom: '1rem',
    },
    '& .sectionTitle': {
      justifySelf: 'flex-start',
      fontSize: '1.75rem',
      margin: '1rem 0',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },
    },
    '& .zipcode': {
      display: 'flex',
      maxWidth: '100%',
      width: '50rem',
      gap: '1rem',
      '& > .search': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.25rem',
        cursor: 'pointer',
        marginBottom: '1rem',
        border: 'none',
        gap: '1rem',
        width: 'fit-content',
        background: 'inherit',
        '& > svg': {
          fontSize: '3rem',
          color: colors.darkBlue,
          '&:hover': {
            color: '#5a5a72',
          },
        },
      },
    },
    '& > .grid-6': {
      display: 'grid',
      gridTemplateColumns: '44% 20% 35%',
      justifyContent: 'space-between',
    },
    '& > .grid-7': {
      display: 'grid',
      gridTemplateColumns: '30% 24% 25% 20%',
      justifyContent: 'space-between',
    },
  },
  representativeFormContainer: {
    display: 'grid',
    '& .relationship-section': {
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',
    },
    '& .remaining-fields': {
      display: 'grid',
      gridTemplateColumns: '30% 30% 30%',
      justifyContent: 'space-between',
    },
  },
}));

function usePrevious(value: any) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface RepresentativesFormProps extends WrappedFieldArrayProps {
  insertStudentData: (
    connection: ConnectionEnum,
    previous_connection: ConnectionEnum | undefined,
    initial_connection: ConnectionEnum | undefined,
  ) => void;
}

const renderRepresentativesForm = (props: RepresentativesFormProps) => {
  const { fields, insertStudentData } = props;
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const formValues = formValueSelector(form_name);
  const representativeAttributes = formValues(state, 'accounts_attributes[0].representatives_attributes[0]');
  const initialValues = getFormInitialValues(form_name)(state) as RepresentativeFormUserAttributes;

  const initialRepresentativeAttributes = get(
    initialValues,
    'accounts_attributes[0].representatives_attributes[0]',
  ) as NestedRepresentativeAttributes;
  const connectionValue = representativeAttributes?.connection;
  const previousRelationship = usePrevious(connectionValue);
  if (fields.length === 0) {
    return null;
  }

  const handleConnectionChange = (value: ConnectionEnum) => {
    insertStudentData(value, previousRelationship, initialRepresentativeAttributes?.connection as ConnectionEnum);
  };

  return (
    <div>
      {fields.map((representative, index) => {
        const currentRepresentative = fields.get(index) as NestedRepresentativeAttributes;
        const currentConnectionValue = currentRepresentative.connection;
        return (
          <FormSection name={representative} key={representative}>
            <div className={classes.representativeFormContainer}>
              <div className='relationship-section'>
                {relationshipOptions.map((option) => {
                  return (
                    <div style={{ marginBottom: '14px' }} key={option.label}>
                      <Field
                        name='connection'
                        component={RadioComponent}
                        props={{ value: option.value }}
                        validate={[validation.required]}
                        onChange={() => {
                          handleConnectionChange(option.value);
                        }}
                      />
                      <span style={{ whiteSpace: 'nowrap' }}> {option.label} </span>
                    </div>
                  );
                })}
                <Field
                  label={'Descrição'}
                  placeholder={'Descrição do relacionamento'}
                  component={InputComponent}
                  name='connection_description'
                  validate={currentConnectionValue === 'other' ? [validation.required] : null}
                />
              </div>
              <div className='remaining-fields'>
                {currentConnectionValue === 'company' && (
                  <>
                    <Field
                      name='spokesperson'
                      placeholder={'Waliomar Bezerra'}
                      label={'Responsável *'}
                      component={InputComponent}
                      validate={[validation.required]}
                    />
                    <Field
                      name='occupation'
                      placeholder={'Cargo'}
                      label={'Cargo *'}
                      component={InputComponent}
                      validate={[validation.required]}
                    />
                  </>
                )}
              </div>
            </div>
          </FormSection>
        );
      })}
    </div>
  );
};

interface AccountFormProps extends WrappedFieldArrayProps {
  insertStudentData: (
    connection: ConnectionEnum,
    previous_connection: ConnectionEnum | undefined,
    initial_connection: ConnectionEnum | undefined,
  ) => void;
}

const renderAccountForm = (props: AccountFormProps) => {
  const { fields, insertStudentData } = props;
  if (fields.length === 0) {
    return null;
  }
  return (
    <div>
      {fields.map((account_attribute) => {
        return (
          <FormSection name={account_attribute} key={account_attribute}>
            <FieldArray
              name='representatives_attributes'
              component={renderRepresentativesForm}
              insertStudentData={insertStudentData}
            />
          </FormSection>
        );
      })}
    </div>
  );
};

const ResponsibleForm = (props: {
  onSubmit: ({ data }: { data: ResponsibleStepFormData }) => void;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: (arg: (args: ResponsibleStepFormData) => void) => any;
  setCurrentUser: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: boolean;
  insertStudentAddress: () => {
    country_id: string;
    state_id: string;
    city_id: string;
  };
  setCurrentRepresentativeId: React.Dispatch<React.SetStateAction<string>>;
  setInitialValues: React.Dispatch<React.SetStateAction<ResponsibleStepFormInitialValues | null>>;
  defaultInitialValues: {
    country_code: {
      value: string;
      label: string;
      countryValue: string;
    };
    nationality_country: {
      phonecode: string;
      flag: string;
      label: string;
      value: string;
    };
    accounts_attributes: [];
  };
  currentResponsible: CurrentResponsibleData;
  connectionValue: string;
  isFormValid: boolean;
  handleSelectUser: (user_option: UserOptionsData) => Promise<void>
  currentRegistration: CurrentRegistrationData;
  insertStudentData: (
    connection: ConnectionEnum,
    previous_connection: ConnectionEnum | undefined,
    initial_connection: ConnectionEnum | undefined,
  ) => void;
  allowUserUpdate: boolean
}) => {
  const {
    setTabValue,
    onSubmit,
    handleSubmit,
    setCurrentUser,
    currentUser,
    insertStudentAddress,
    setCurrentRepresentativeId,
    setInitialValues,
    defaultInitialValues,
    currentResponsible,
    connectionValue,
    isFormValid,
    handleSelectUser,
    currentRegistration,
    insertStudentData,
    allowUserUpdate
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const company_id_to_use = currentRegistration.company_id;

  const userFormData = [
    'document_number',
    'name',
    'email',
    'additional_data.personal_email',
    'phone',
    'gender',
    'birthdate',
    'nationality_country',
    'nationality_state',
    'nationality_city',
  ] as UserFormFields[];
  const document_label = connectionValue === ConnectionEnum.COMPANY ? 'CNPJ' : 'CPF';
  const document_placeholder = connectionValue === ConnectionEnum.COMPANY ? 'CNPJ *' : 'CPF *';
  const document_number_format_function = connectionValue === ConnectionEnum.COMPANY ? convertToCNPJ : convertToCPF;

  const handleClearDocumentField = () => {
    if (currentUser) {
      dispatch(reset(form_name));
      setInitialValues(defaultInitialValues);
      setCurrentUser(false);
    } else {
      dispatch(change(form_name, 'document', null));
    }
  };

  return (
    <form className={classes.form}>
      <span className='title'> Cadastrar Responsável </span>
      <span className='sectionTitle'>Dados do Responsável</span>
      <FieldArray component={renderAccountForm} name='accounts_attributes' insertStudentData={insertStudentData} />
      <hr />
      <span className='sectionTitle'>Dados do Usuário</span>
      <div className='user-form'>
        <UserForm
          data={userFormData}
          form_name={form_name}
          document_number_format_function={document_number_format_function}
          handleClearDocumentField={handleClearDocumentField}
          handleSelectUser={handleSelectUser}
          company_id_to_use={company_id_to_use.toString()}
          disabled_document_number={!isEmpty(currentUser)}
          document_clearable={!currentResponsible}
          document_label={document_label}
          document_placeholder={document_placeholder}
          insertStudentAddress={insertStudentAddress}
          disable_all={!allowUserUpdate}
        />
      </div>
      <div className={classes.formButtons}>
        <button
          onClick={() => {
            setCurrentRepresentativeId('');
            setInitialValues(null);
            setTabValue(0);
          }}
          className='red small'
        >
          <span> Cancelar </span>
        </button>
        <button
          type='submit'
          disabled={!isFormValid}
          onClick={handleSubmit((values) => {
            onSubmit({
              data: values,
            });
          })}
          className='green small'
        >
          <span> Inserir </span>
        </button>
      </div>
    </form>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
    enableReinitialize: true,
  }),
  withRaces,
)(ResponsibleForm);
