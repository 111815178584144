/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { colors, WalletCreditAttributes } from '../../utils/constants';
import { makeStyles } from '@mui/styles';
import { formatToCurrency, parseText } from '../../utils/functions';
import { sum } from 'lodash';
import { Tooltip } from '@mui/material';
import { format, parseISO } from 'date-fns';

const useStyles = makeStyles(() => ({
  table: {
    '& tbody > tr:nth-child(even)': {
      backgroundColor: `${colors.darkGrayBlue} !important`,
    },
    '& tbody > tr:nth-child(odd)': {
      backgroundColor: `${colors.grayBlue} !important`,
    },
    '& .include': {
      display: 'flex',
      marginLeft: 'auto',
      marginBottom: '1rem',
    },
    '& tfoot': {
      background: 'white',
      '& td': {
        fontWeight: 'bold',
        fontSize: '1rem',
      },
      '& td:last-of-type': {
        color: colors.blue,
      },
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `5px solid ${colors.lightBlue}`,
      },
      '& th': {
        color: colors.darkGrayBlue,
      },
      '& td': {
        color: colors.darkBlue,
        '& svg': {
          color: colors.darkGrayBlue,
          cursor: 'pointer',
        },
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none',
      },
    },
  },
}));

const WalletCreditsBody = ({ credits }: { credits: WalletCreditAttributes[] }) => {
  return (
    <TableBody>
      {credits.map((credit) => {
        const description = parseText(credit.description, 60);
        const credit_value = sum([credit.credit_in, -credit.credit_out]);
        return (
          <React.Fragment key={credit.id}>
            <TableRow>
              <TableCell
                align='center'
                style={{ backgroundColor: credit_value < 0 ? colors.lightRed : credit_value > 0 ? colors.green : 'initial', color: 'white' }}
              >
                {formatToCurrency(credit_value)}
              </TableCell>
              <TableCell align='center'>{credit.invoice_code}</TableCell>
              <TableCell align='center'>
                <Tooltip title={credit.description}>
                  <span>{description}</span>
                </Tooltip>
              </TableCell>
              <TableCell align='center'>{format(parseISO(credit.created_at), 'dd-MM-yyyy HH:mm')}</TableCell>
              <TableCell align='center'>{credit.created_by_user?.name}</TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </TableBody>
  );
};

const WalletCredits = ({ credits }: { credits: WalletCreditAttributes[] }) => {
  const classes = useStyles();
  return (
    <Table className={classes.table} size='small' aria-label='wallet-credit'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Valor</TableCell>
          <TableCell align='center'>Código da fatura</TableCell>
          <TableCell align='center'>Descrição</TableCell>
          <TableCell align='center'>Data</TableCell>
          <TableCell align='center'>Criado por</TableCell>
        </TableRow>
      </TableHead>
      <WalletCreditsBody credits={credits} />
    </Table>
  );
};

export default WalletCredits;
