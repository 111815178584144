import React from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field, change, untouch, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import InputComponent from '../input/form/input';
import MaskedInputComponent from '../input/form/masked';
import SelectComponent from '../input/form/select';
import TextAreaComponent from '../input/form/textarea';
import { companyTypes, validation, colors, Paths, DefaultOptionType } from '../../utils/constants';
import { fetchCepInfo } from '../../utils/functions';
import TabPanel from '../shared/TabPanel';
import UploadFileComponent from '../shared/UploadFileComponent';
import CheckboxComponent from '../input/form/checkbox';

const chargeGenerationConfigOptions = [
  {
    label: 'Gerar cobranças manualmente',
    value: "manually"
  },
  {
    label: 'Gerar cobranças nas regras de agendamento',
    value: 'on_schedule'
  },
  {
    label: 'Gerar cobrança na geração da fatura',
    value: 'on_invoice_generation'
  },
  {
    label: 'Gerar cobrança na assinatura do contrato',
    value: 'on_contract_sign'
  }
]

const paymentServiceOptions = [
  {
    label: 'ASAAS',
    value: "asaas"
  },
  {
    label: 'SICOOB',
    value: "sicoob"
  }
]

const useStyles = makeStyles(() => ({
  tabStyle: {
    '& .Mui-selected': {
      background: '#FDFEFF',
      borderRadius: '1rem 1rem 0 0',
    },
  },
  form: {
    display: 'grid',
    rowGap: '1rem',
    '& .colorSelector': {
      '& > div': {
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          cursor: 'pointer',
        },
      },
    },
    '& > span': {
      justifySelf: 'flex-start',
    },
    '& .zipcode': {
      display: 'flex',
      maxWidth: '100%',
      width: '50rem',
      gap: '1rem',
      '& > .search': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.25rem',
        cursor: 'pointer',
        marginBottom: '1rem',
        border: 'none',
        gap: '1rem',
        width: 'fit-content',
        background: 'inherit',
        '& > svg': {
          fontSize: '3rem',
          color: colors.darkBlue,
          '&:hover': {
            color: '#5a5a72',
          },
        },
      },
    },
    '& > .grid-1': {
      display: 'grid',
      gridTemplateColumns: '65% 34%',
      justifyContent: 'space-between',
    },
    '& > .grid-2': {
      display: 'grid',
      gridTemplateColumns: `100%`,
      justifyContent: 'space-between',
      '& > div:last-of-type': {
        padding: '0.5rem',
        background: 'white',
        width: 'inherit',
        textAlign: 'center',
        border: `1px solid ${colors.grayBlue}`,
      },
    },
    '& > .grid-3': {
      display: 'grid',
      gridTemplateColumns: '39% 60%',
      justifyContent: 'space-between',
    },
    '& > .grid-picture': {
      display: 'flex',
      maxHeight: '6rem',
      alignItems: 'center',
      gap: '0.5rem',
      marginBottom: '2rem',
      '& > div': {
        'max-width': '100%',
        maxHeight: 'inherit',
        '& img': {
          maxHeight: 'inherit',
        },
      },
    },
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '45% 45%',
    justifyContent: 'space-between',
    fontSize: '1.25rem',
    textAlign: 'center',
  },
}));

const SingleColorSelector = ({ label, input }: { label: string; input: { value: string; name: string } }) => {
  return (
    <div>
      <span>{label}</span>
      <div
        style={{
          padding: '0.25rem',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {input.value === '' ? (
          <AddCircleOutlineIcon />
        ) : (
          <div
            style={{
              width: '1rem',
              height: '1rem',
              borderRadius: '50%',
              border: '1px solid black',
              backgroundColor: input.value,
            }}
          />
        )}
        <input
          id={input.name}
          data-testid={input.name}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: '0',
            left: '0',
            right: '0',
          }}
          type='color'
          {...input}
        />
      </div>
    </div>
  );
};

const SelectColorComponent = () => {
  return (
    <div className='colorSelector'>
      <span> Selecione a cor primária e secundária </span>
      <div>
        <Field name='primary_color' label='Primária' component={SingleColorSelector} />
        <Field name='secondary_color' label='Secundária' component={SingleColorSelector} />
      </div>
    </div>
  );
};

export const CompanyForm = (props: {
  kind: string;
  onSubmit: any;
  handleSubmit: (event: React.MouseEvent<HTMLElement>) => any;
  companyOptions: DefaultOptionType[];
}) => {
  const { companyOptions, kind, handleSubmit, onSubmit } = props;
  const companyKindInfo = companyTypes.find((type) => type.value === kind);
  const [loadingZipcode, setLoadingZipcode] = React.useState(false);
  const [tab, setTab] = React.useState('company');
  const state = useSelector((state) => state);
  const formValues = formValueSelector('companyForm');
  const cepFieldValue = formValues(state, 'zipcode');
  const logoValue = formValues(state, 'logo');
  const emailHeaderBannerValue = formValues(state, 'email_header_banner')
  const cnpjValue = formValues(state, 'document');
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };

  const clearLogoField = () => {
    dispatch(change('companyForm', 'logo', null));
    dispatch(untouch('companyForm', 'logo'));
  };

  const clearEmailHeaderBannerField = () => {
    dispatch(change('companyForm', 'email_header_banner', null));
    dispatch(untouch('companyForm', 'email_header_banner'));
  }

  const handleCepSearch = async () => {
    const formattedcepFieldValue = cepFieldValue.replace(/[^0-9]/g, '');
    await fetchCepInfo(formattedcepFieldValue)
      .then((res) => {
        const {
          data: { bairro, localidade, logradouro, uf },
        } = res;
        bairro && dispatch(change('companyForm', 'neighbourhood', bairro));
        logradouro && dispatch(change('companyForm', 'street', logradouro));
        uf && dispatch(change('companyForm', 'state', uf));
        localidade && dispatch(change('companyForm', 'city', localidade));
        setLoadingZipcode(false);
      })
      .catch(() => {
        setLoadingZipcode(false);
      });
  };
  React.useEffect(() => {
    if (cepFieldValue && cepFieldValue.replace(/[^0-9]/g, '').length === 8) {
      setLoadingZipcode(true);
      handleCepSearch();
    }
  }, [cepFieldValue]);
  return (
    <form data-testid='companyForm' className={classes.form}>
      <Tabs className={classes.tabStyle} value={tab} onChange={handleTabChange}>
        <Tab label='Dados' value={'company'} />
        <Tab label='Configurações' value={'config'} />
      </Tabs>
      <TabPanel value={tab} index={'company'}>
        <Field
          name='parent_id'
          label='Companhia-pai'
          placeholder='Selecione a companhia pai'
          component={SelectComponent}
          options={companyOptions}
        />
        <Field
          name='name'
          label={`Nome da companhia ${companyKindInfo && `tipo ${companyKindInfo.label}`} *`}
          placeholder='Fractal Tecnologia'
          component={InputComponent}
          validate={[validation.required]}
        />
        <Field
          label={'Descrição *'}
          placeholder={'Escreva uma descrição'}
          name='description'
          component={TextAreaComponent}
        />
        <Field
          name='document'
          label='CNPJ *'
          placeholder='01.234.567/8900-00'
          maskProps={{
            format: '##.###.###/####-##',
            mask: '_',
          }}
          component={MaskedInputComponent}
          validate={cnpjValue && [validation.validCnpj]}
        />
        <span>Endereço</span>
        <div className='zipcode'>
          <Field
            name='zipcode'
            placeholder={'58032-102'}
            label={'CEP *'}
            maskProps={{
              format: '#####-###',
              mask: '_',
            }}
            component={MaskedInputComponent}
          />
          <div
            onClick={() => {
              if (cepFieldValue && cepFieldValue.replace(/[^0-9]/g, '').length === 8) {
                setLoadingZipcode(true);
                handleCepSearch();
              }
            }}
            className='search'
          >
            {loadingZipcode ? <CircularProgress /> : <SearchIcon />}
          </div>
        </div>
        <div className='grid-1'>
          <Field name='street' label={'Rua *'} placeholder={'Av. Gen. Edson Ramalho'} component={InputComponent} />
          <Field name='number' label={'Número *'} placeholder={'99'} component={InputComponent} />
        </div>
        <div className='grid-3'>
          <Field name='neighbourhood' label={'Bairro *'} placeholder={'Jardim Oceania'} component={InputComponent} />
          <Field name='complement' label={'Complemento'} placeholder={'Apartamento 201'} component={InputComponent} />
        </div>
        <div className='grid-1'>
          <Field name='city' label={'Cidade *'} placeholder={'João Pessooa'} component={InputComponent} />
          <Field name='state' label={'Estado *'} placeholder={'Paraiba'} component={InputComponent} />
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <UploadFileComponent fileValue={logoValue} clearFileField={clearLogoField} name={'logo'} fileLabel='Adicionar Logo' />
        </div>
        <div className='grid-2'>
          <SelectColorComponent />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={'config'}>
        <Field
          label='Serviço utilizado para pagamentos'
          name='config_params.payment_service'
          component={SelectComponent}
          options={paymentServiceOptions}
          validate={[validation.required]}
        />
        <Field
          name='config_params.enable_whatsapp_invoice_notification'
          component={CheckboxComponent}
          placeholder={'Habilitar Notificações de Fatura por WhatsApp'}
        />
        <Field
          label='Chave do ASAAS'
          component={InputComponent}
          name='config_params.asaas_api_key'
          placeholder={'Chave do ASAAS'}
        />
        <Field
          name='config_params.charge_generation_config'
          label='Regra de geração de cobrança'
          placeholder='Selecione regra de geração de cobrança'
          component={SelectComponent}
          options={chargeGenerationConfigOptions}
          validate={[validation.required]}
        />
        <Field
          label='Dias antes do vencimento/pontualidade para geração de cobrança'
          component={InputComponent}
          name='config_params.days_before_expiration_charge'
          onlyNumbers
          placeholder={'5'}
          validate={[validation.required]}
        />
        <Field
          label='Dias para notificação antes da pontualidade'
          component={InputComponent}
          name='config_params.days_before_punctuality_notification'
          onlyNumbers
          placeholder={'5'}
          validate={[validation.required]}
        />
        <Field
          label='Dias para notificação antes do vencimento'
          component={InputComponent}
          name='config_params.days_before_expiration_notification'
          onlyNumbers
          placeholder={'5'}
          validate={[validation.required]}
        />
        <Field
          label='Intervalo em dias para notificações após vencimento'
          component={InputComponent}
          name='config_params.days_interval_after_expiration_notification'
          onlyNumbers
          placeholder={'5'}
          validate={[validation.required]}
        />
        <Field
          label='Bloquear ajuste de faturas em dias após geração da fatura'
          component={InputComponent}
          name='config_params.days_to_block_invoice_adjustment_after_generation'
          onlyNumbers
          placeholder={'5'}
          validate={[validation.required]}
        />
        <div style={{ marginBottom: '1rem' }}>
          <UploadFileComponent fileValue={emailHeaderBannerValue} clearFileField={clearEmailHeaderBannerField} name={'email_header_banner'} fileLabel='Imagem de cabeçalho de email' />
        </div>
        <Field
          label='Remetente para email'
          component={InputComponent}
          name='config_params.email_sender'
          placeholder={'5'}
          validate={[validation.validEmail,validation.required]}
        />
      </TabPanel>
      <div className={classes.buttons}>
        <Link to={Paths.HOME} className='red'>
          <span> Cancelar </span>
        </Link>
        <button
          type='submit'
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
          className='blue'
        >
          <span> Salvar </span>
        </button>
      </div>
    </form>
  );
};

export default compose<any>(
  reduxForm({
    form: 'companyForm',
  }),
)(CompanyForm);
