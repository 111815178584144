import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const countriesEndpoint = '/countries'

export const fetchCountries = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${countriesEndpoint}?${queryParams}`)
}

export const fetchCountry = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${countriesEndpoint}/${id}?${queryParams}`)
}
