/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface UserDeficiencyAtrrs {
  description: string
  user_id: number
  deficiency_id: number
  deficiency_name: string
}

export type UserDeficiencyJson = JsonFormat<'user_deficiencies', UserDeficiencyAtrrs>

export interface DeficiencyAttrs {
  name: string
  description: string
}
export type DeficiencyJson = JsonFormat<'deficiencies',DeficiencyAttrs>

export type FetchDeficienciesResponse = JsonResponseFormat<DeficiencyJson[]>

export type FetchDeficienciesPayload =WithRequestParams

export interface DeleteUserDeficiencyPayload {
  id: string | number,
}

export const FETCH_DEFICIENCIES = createPromiseAction('deficiencies: FETCH_DEFICIENCIES')<FetchDeficienciesPayload, FetchDeficienciesResponse, any>()
export const DELETE_USER_DEFICIENCY = createPromiseAction('user_deficiencies: DELETE_USER_DEFICIENCY')<DeleteUserDeficiencyPayload, AxiosResponse, any>()
