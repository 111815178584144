import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_AUDIT, CreateAuditPayload, DELETE_AUDIT, DeleteAuditPayload, FETCH_AUDIT, FETCH_AUDITS, FetchAuditPayload, FetchAuditsPayload, UPDATE_AUDIT, UpdateAuditPayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchAudits (action: DefaultAction<FetchAuditsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchAudits, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_AUDITS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_AUDITS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchAudit (action: DefaultAction<FetchAuditPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchAudit, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_AUDIT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_AUDIT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createAudit (action: DefaultAction<CreateAuditPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'audits',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createAudit, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_AUDIT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_AUDIT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateAudit (action: DefaultAction<UpdateAuditPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'audits',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateAudit, {data: params, id})
    yield put(UPDATE_AUDIT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_AUDIT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteAudit (action: DefaultAction<DeleteAuditPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteAudit, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_AUDIT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_AUDIT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchAuditsSagas () {
  yield takeLatest(FETCH_AUDITS.request, fetchAudits)
  yield takeLatest(FETCH_AUDIT.request, fetchAudit)
  yield takeLatest(CREATE_AUDIT.request, createAudit)
  yield takeLatest(UPDATE_AUDIT.request, updateAudit)
  yield takeLatest(DELETE_AUDIT.request, deleteAudit)
}

export default function * auditsSagas () {
  yield all([watchAuditsSagas()])
}
