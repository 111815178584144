import { createPromiseAction } from 'redux-saga-promise-actions'

import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface EthnicityAttrs {
  name: string
  description: string
}

export type EthnicityJson = JsonFormat<'ethnicities', EthnicityAttrs>
export type FetchEthnicitiesResponse = JsonResponseFormat<EthnicityJson[]>
export type FetchEthnicitiesPayload = WithRequestParams

export type FetchEthnicityResponse = JsonResponseFormat<EthnicityJson>
export interface FetchEthnicityPayload extends WithRequestParams {
  id: string | number,
}

export const FETCH_ETHNICITIES = createPromiseAction('ethnicities: FETCH_ETHNICITIES')<FetchEthnicitiesPayload, FetchEthnicitiesResponse, any>()
export const FETCH_ETHNICITY = createPromiseAction('ethnicities: FETCH_ETHNICITY')<FetchEthnicityPayload, FetchEthnicityResponse, any>()
