import React from 'react'
import ClassroomsFormContainer from '../containers/ClassroomsFormContainer'
import navigationHoc from '../components/hoc/navigationHoc'

const ClassroomsFormPage = () => {
  return(
    <ClassroomsFormContainer />
  )
}

export default navigationHoc(ClassroomsFormPage)