/* eslint-disable camelcase */
import React from 'react'
import { css } from '@emotion/react'
import { makeStyles } from '@mui/styles'
import { Link, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { blur, change, Field, FieldArray, FormSection, reduxForm, WrappedFieldArrayProps } from 'redux-form'
import { colors, DefaultOptionType, FormFileType, LocationState, NestedProfileDashboardAttributes, Paths, validation } from '../../utils/constants'
import InputComponent from '../input/form/input'
import SelectComponent from '../input/form/select'
import AddButton from '../shared/AddButton'
import { useDispatch } from 'react-redux'
import DeleteButton from '../shared/DeleteButton'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { find } from 'lodash'
import UploadFileComponent from '../shared/UploadFileComponent'

const useStyles = makeStyles((): {
  view: any,
  title: any,
  form: any,
  buttons: any,
  accordion: any,
  accordionSummary: any
} => ({
  view: {
    width: 'inherit',
    height: 'fit-content',
    flexGrow: '1',
    display: 'flex',
    padding: '2rem 10%',
    flexDirection: 'column',
    position: 'relative',
    '& .MuiBackdrop-root': {
      position: 'inherit'
    },
    '& .MuiPaper-root': {
      background: 'none',
      boxShadow: 'none'
    }
  },
  title: {
    alignSelf: 'flex-start',
    marginBottom: '1rem',
    padding: '0 1rem'
  },
  form: {
    display: 'grid',
    rowGap: '1rem',
    background: '#FDFEFF',
    padding: '1rem'
  },

  buttons: {
    margin: '2rem 1rem',
    display: 'flex',
    justifyContent: 'end',
    gap: '1rem'
  },
  accordion: {
    background: 'inherit',
    'box-shadow': 'none',
    'border-bottom': `1px solid ${colors.grayBlue}`
  },
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'inherit'
    },
  },
}))

const ProfileDashboardForm = (props: {
  profileOptions: DefaultOptionType[]
  clearDashboardLogoField: (e: React.MouseEvent<HTMLButtonElement>) => void
  dashboardLogoValue: FormFileType
}) => {
  const classes = useStyles()
  const {profileOptions, dashboardLogoValue, clearDashboardLogoField} = props

  return (
    <div className={classes.form}>
      <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between'}}>
        <Field
          name='name'
          component={InputComponent}
          label={'Nome'}
          placeholder={'Insira o nome do dashboard'}
          validate={[validation.required]}
        />
        <Field
          name='role'
          component={SelectComponent}
          label='Perfil'
          options={profileOptions}
          placeholder='Insira o perfil do dashboard'
          validate={[validation.required]}
        />
      </div>
        <UploadFileComponent fileValue={dashboardLogoValue} clearFileField={clearDashboardLogoField} name={'dashboard_logo'} fileLabel='Imagem do dashboard' />
      <Field
        name='url'
        component={InputComponent}
        label={'Link'}
        placeholder={'Insira o link do dashboard'}
      />
    </div>
  )
}

const ProfileDashboardAccordion = ({
  currentProfileDashboard,
  deleteProfileDashboardMethod,
  profile_dashboard,
  profileOptions,
  clearDashboardLogoField,
  dashboardLogoValue
}:{
  currentProfileDashboard: NestedProfileDashboardAttributes
  deleteProfileDashboardMethod: (e: React.MouseEvent<HTMLButtonElement>) => void
  profile_dashboard: string
  profileOptions: DefaultOptionType[]
  clearDashboardLogoField: (e: React.MouseEvent<HTMLButtonElement>) => void
  dashboardLogoValue: FormFileType
}) => {
  const wasDestroyed = currentProfileDashboard._destroy
  const classes = useStyles()
  if(wasDestroyed) {
    return null
  }
  const currentRoleLabel = find(profileOptions, profile => profile.value === currentProfileDashboard.role)?.label || ''
  const accordionTitle = `${currentRoleLabel} ${currentProfileDashboard.name && `- ${currentProfileDashboard.name}` || ''}`
  return (
    <Accordion key={profile_dashboard} className={classes.accordion}>
      <AccordionSummary className={classes.accordionSummary}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <ExpandMore />
        <span>{accordionTitle}</span>
      </div>
      <DeleteButton onClick={deleteProfileDashboardMethod}/>
      </AccordionSummary>
      <AccordionDetails>
        <FormSection name={profile_dashboard}>
          <ProfileDashboardForm
            profileOptions={profileOptions}
            clearDashboardLogoField={clearDashboardLogoField}
            dashboardLogoValue={dashboardLogoValue}
          />
        </FormSection>
      </AccordionDetails>
    </Accordion>
  )
}

interface CustomProfileDashboardsFormProps extends WrappedFieldArrayProps {
  profileOptions: DefaultOptionType[]
}

const renderProfileDashboardsForm = (props: CustomProfileDashboardsFormProps) => {
  const { fields, profileOptions } = props
  const dispatch = useDispatch()
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(async () => updateState({}), []);
  const addProfileDashboard = React.useCallback(() => {
    fields.push({})
  }, [fields])  
  const deleteProfileDashboard = React.useCallback((currentProfileDashboard: NestedProfileDashboardAttributes, profile_dashboard: string, index: number) => {
    currentProfileDashboard?.id ? dispatch(
      change(
        'profileDashboardForm',`${profile_dashboard}._destroy`, true
      )
    ) : fields.remove(index)
  }, [fields])

  const clearLogoFieldMethod = React.useCallback(async (profile_dashboard: string) => {
    await dispatch(change('profileDashboardForm',`${profile_dashboard}.dashboard_logo`, null))
    await dispatch(blur('profileDashboardForm', `${profile_dashboard}.dashboard_logo`, null))
}, [fields]);


  return (
    <div>
      <div
        css={css `
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
      <span>Adicionar dashboard de perfil</span>
      <AddButton
        onClick={addProfileDashboard}
      />
      </div>
      {
        fields.map((profile_dashboard, index) => {
          const currentProfileDashboard : NestedProfileDashboardAttributes = fields.get(index)
          const deleteProfileDashboardMethod = async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            deleteProfileDashboard(currentProfileDashboard, profile_dashboard, index)
            await forceUpdate()
          }

          const clearDashboardLogoField = async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            await clearLogoFieldMethod(profile_dashboard)
            await forceUpdate()
          }

          const dashboardLogoValue = currentProfileDashboard.dashboard_logo as FormFileType
          return(
            <div key={profile_dashboard}>
              <ProfileDashboardAccordion
                profileOptions={profileOptions}
                deleteProfileDashboardMethod={deleteProfileDashboardMethod}
                currentProfileDashboard={currentProfileDashboard}
                profile_dashboard={profile_dashboard}
                dashboardLogoValue={dashboardLogoValue}
                clearDashboardLogoField={clearDashboardLogoField}
              />
            </div>
          )
        })
      }
    </div>
  )
}


const ProfileDashboardsForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  profileOptions: DefaultOptionType[]
}) => {
  const { handleSubmit, onSubmit, profileOptions } = props
  const classes = useStyles()
  const location = useLocation<LocationState>()

  return(
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar dashboards por perfil {`${location.state.company_name ? ` - ${location.state.company_name}` : ''}`}</span>
      <form className={classes.form}>
        <FieldArray
          name='profile_dashboards_attributes'
          component={renderProfileDashboardsForm}
          profileOptions={profileOptions}
        />
        <div className={classes.buttons}>
          <Link to={Paths.PROFILE_DASHBOARDS_LIST} className='red small'>
            <span> Cancelar </span>
          </Link>
          <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar dashboards por perfil </span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default compose<any>(
  reduxForm({
      form: 'profileDashboardForm',
  }),
)(ProfileDashboardsForm);
