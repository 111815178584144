import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_ASAAS_CUSTOMERS,
  FETCH_ASAAS_PAYMENTS,
  FETCH_ASAAS_WEBHOOK_SETTING,
  FetchAsaasCustomerPayload,
  FetchAsaasPaymentPayload,
  FetchAsaasWebhookSettingPayload,
  UPDATE_ASAAS_WEBHOOK_SETTING,
  UpdateAsaasWebhookSettingPayload,
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchAsaasCustomers (action: DefaultAction<FetchAsaasCustomerPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchAsaasPayments, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ASAAS_CUSTOMERS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ASAAS_CUSTOMERS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchAsaasPayments (action: DefaultAction<FetchAsaasPaymentPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchAsaasPayments, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ASAAS_PAYMENTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ASAAS_PAYMENTS.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * fetchAsaasWebhookSettings (action: DefaultAction<FetchAsaasWebhookSettingPayload, string>): any {
  try {
    const response = yield call(services.fetchAsaasWebhookSettings)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ASAAS_WEBHOOK_SETTING.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ASAAS_WEBHOOK_SETTING.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateAsaasWebhookSettings (action: DefaultAction<UpdateAsaasWebhookSettingPayload, string>): any {
  try {
    const { payload: {data} } = action

    const response = yield call(services.updateAsaasWebhookSettings, data)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(UPDATE_ASAAS_WEBHOOK_SETTING.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(UPDATE_ASAAS_WEBHOOK_SETTING.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchAsaasSagas () {
  yield takeLatest(FETCH_ASAAS_CUSTOMERS.request, fetchAsaasCustomers)
  yield takeLatest(FETCH_ASAAS_PAYMENTS.request, fetchAsaasPayments)
  yield takeLatest(FETCH_ASAAS_WEBHOOK_SETTING.request, fetchAsaasWebhookSettings)
  yield takeLatest(UPDATE_ASAAS_WEBHOOK_SETTING.request, updateAsaasWebhookSettings)
}

export default function * asaasSagas () {
  yield all([watchAsaasSagas()])
}
