import React from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import DiscountTypesView from '../components/view/Secretary/DiscountTypesView';
import { RootState } from '../store/configureStore';
import { FETCH_DISCOUNT_TYPES } from '../store/discount_types';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { defaultPagination, PaginationType, DiscountTypesViewAttributes } from '../utils/constants';
import { CompanyJson } from '../store/companies';
import { get_company_search } from '../utils/functions';


const DiscountTypesContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants },
    account: { companies }
  } = state;

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [discountTypes, setDiscountTypes] = React.useState<DiscountTypesViewAttributes[]>([]);
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [descriptionFilterValue, setDescriptionFilterValue] = React.useState('');
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);

  const fetchDiscountTypesMethod = React.useCallback(
    async ({ newPagination = pagination, company_filter = companyFilterValue }: { newPagination?: PaginationType, company_filter?: string }) => {
      setLoading(true);
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company
      })
      try {
        const discountTypes = await dispatch(
          FETCH_DISCOUNT_TYPES.request({
            params: {
              filters: {
                'include': 'company',
                'q[company_id_in]': companies_array,
                'q[name_cont]': nameFilterValue,
                'q[description_cont]': descriptionFilterValue,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
              },
            },
          }),
        );
        const {
          data: { data, meta, included },
        } = discountTypes;
        const formattedDiscountTypes = data.map((item) => {
          const company = included.find(incl => incl.type === 'companies' && ~~incl.id === item.attributes.company_id) as CompanyJson
          return {
            id: item.id,
            ...item.attributes,
            company: company.attributes.name
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setDiscountTypes(formattedDiscountTypes);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar tipos de desconto',
          }),
        );
      }
    },
    [company, nameFilterValue, descriptionFilterValue, pagination, companyFilterValue],
  );

  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchDiscountTypesMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchDiscountTypesMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  React.useEffect(() => {
    fetchDiscountTypesMethod({});
  }, []);

  return (
    <DiscountTypesView
      discountTypes={discountTypes}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      descriptionFilterValue={descriptionFilterValue}
      setDescriptionFilterValue={setDescriptionFilterValue}
      nameFilterValue={nameFilterValue}
      setNameFilterValue={setNameFilterValue}
      fetchDiscountTypesMethod={fetchDiscountTypesMethod}
      setCompanyFilterValue={setCompanyFilterValue}
      companyFilterValue={companyFilterValue}
    />
  );
};

export default DiscountTypesContainer;
