import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const ethnicitiesEndpoint = '/ethnicities'

export const fetchEthnicities = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ethnicitiesEndpoint}?${queryParams}`)
}

export const fetchEthnicity = ({ params, id } : { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ethnicitiesEndpoint}/${id}?${queryParams}`)
}
