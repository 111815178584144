import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const compositionPeriodsEndpoint = '/composition_periods'

export const fetchCompositionPeriods = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${compositionPeriodsEndpoint}?${queryParams}`)
}

export const fetchCompositionPeriod = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${compositionPeriodsEndpoint}/${id}?${queryParams}`)
}

export const createCompositionPeriod = (data: object) => {
  return http.post(`${compositionPeriodsEndpoint}`, data)
}

export const updateCompositionPeriod = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${compositionPeriodsEndpoint}/${id}`, data)
}

export const deleteCompositionPeriod = ({ id }: { id: string | number } ) => {
  return http.delete(`${compositionPeriodsEndpoint}/${id}`)
}
