import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_DISCOUNT_TYPES,
  FetchDiscountTypesPayload,
  FetchDiscountTypePayload,
  CreateDiscountTypePayload,
  UpdateDiscountTypePayload,
  FETCH_DISCOUNT_TYPE,
  CREATE_DISCOUNT_TYPE,
  UPDATE_DISCOUNT_TYPE,
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchDiscountTypes (action: DefaultAction<FetchDiscountTypesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchDiscountTypes, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_DISCOUNT_TYPES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_DISCOUNT_TYPES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchDiscountType (action: DefaultAction<FetchDiscountTypePayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchDiscountType, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_DISCOUNT_TYPE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_DISCOUNT_TYPE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createDiscountType (action: DefaultAction<CreateDiscountTypePayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'discount_types',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createDiscountType, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_DISCOUNT_TYPE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_DISCOUNT_TYPE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateDiscountType (action: DefaultAction<UpdateDiscountTypePayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'discount_types',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateDiscountType, {data: params, id})
    yield put(UPDATE_DISCOUNT_TYPE.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_DISCOUNT_TYPE.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * watchDiscountTypeSagas () {
  yield takeLatest(FETCH_DISCOUNT_TYPES.request, fetchDiscountTypes)
  yield takeLatest(FETCH_DISCOUNT_TYPE.request, fetchDiscountType)
  yield takeLatest(CREATE_DISCOUNT_TYPE.request, createDiscountType)
  yield takeLatest(UPDATE_DISCOUNT_TYPE.request, updateDiscountType)
}

export default function * discountTypesSagas () {
  yield all([watchDiscountTypeSagas()])
}
