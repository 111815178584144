import React from 'react';
import UploadComponent from '../input/form/upload';
import { Field } from 'redux-form';
import CloudIcon from '../icon/CloudIcon';
import TrashIcon from '../icon/TrashIcon';
import { IconButton } from '@mui/material';
import { FormFileType, colors } from '../../utils/constants';

const UploadFileComponent = (props: {
  fileLabel: string;
  name: string;
  fileValue: FormFileType;
  clearFileField: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const { fileLabel, name, fileValue, clearFileField } = props;
  return (
    <div className='grid-picture' id={`input-${name}`}>
      <span> {fileLabel} </span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: 'transparent',
          border: `1px dashed ${colors.grayBlue} `,
          padding: '1rem 1.5rem',
          position: 'relative',
        }}
      >
        <div
          style={{
            background: colors.grayBlue,
            borderRadius: '50%',
            width: '2rem',
            height: '2rem',
          }}
        >
          <CloudIcon />
        </div>
        <span style={{ fontSize: '0.75rem' }}>
          <strong>Solte sua imagem aqui</strong> ou navegue por seu <strong>computador</strong>
        </span>
        <span style={{ color: colors.darkGrayBlue, fontSize: '0.75rem' }}>
          Suporte Arquivos: Jpg, Png, Jpeg Dimensões mínimas: 170x135px
        </span>
        <Field
          name={name}
          label={`Àdicionar ${fileLabel}`}
          accept='image/png, image/gif, image/jpeg'
          component={UploadComponent}
        />
      </div>
      {!!fileValue && (fileValue?.file || fileValue?.url) && (
        <div style={{ position: 'relative' }}>
          <img
            style={{ maxWidth: '250px' }}
            src={fileValue.file ? URL.createObjectURL(fileValue?.file) : fileValue?.url}
          />
          <IconButton
            style={{ position: 'absolute', right: '-0.75rem', bottom: '-0.75rem', background: colors.lightRed }}
            onClick={clearFileField}
          >
            <TrashIcon style={{ height: '0.75rem', width: '0.75rem', color: 'white' }} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default UploadFileComponent;
