/* eslint-disable camelcase */
import { find, isNil, keys, reduce } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {CurriculumAttributes, LocationState, NestedCompositionAttributes, NestedCompositionPeriodAttributes, NestedExamPlacementAttributes } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { CREATE_COMPOSITION, FETCH_COMPOSITION, UPDATE_COMPOSITION } from '../store/compositions';
import CompositionsForm from '../components/form/CompositionsForm';
import { ExamPlacementJson } from '../store/exam_placements';


const examPlacementUpdater = (
  currentExamPlacements: NestedExamPlacementAttributes[],
  initialExamPlacements: NestedExamPlacementAttributes[],
) => {
  const sorted: NestedExamPlacementAttributes[] = [];
  currentExamPlacements.forEach((exam_placement) => {
    const related = find(initialExamPlacements, (initial) => initial.id === exam_placement.id);
    if (isNil(related)) {
      sorted.push(exam_placement);
      return;
    }
    const omit = ['id', '_destroy', 'exam_placeable_id', 'exam_placement_type'];
    const result = verifyDifferentValues(exam_placement, related, omit) as NestedExamPlacementAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedExamPlacementAttributes[];
};


const compositionPeriodUpdater = (
  currentCompositionPeriods: NestedCompositionPeriodAttributes[],
  initialCompositionPeriods: NestedCompositionPeriodAttributes[],
) => {
  const sorted: NestedCompositionPeriodAttributes[] = [];
  currentCompositionPeriods.forEach((composition_period) => {
    const related = find(initialCompositionPeriods, (initial) => initial.id === composition_period.id);
    if (isNil(related)) {
      sorted.push(composition_period);
      return;
    }
    const omit = ['id', '_destroy', 'composition_id'];
    const result = verifyDifferentValues(composition_period, related, omit) as NestedCompositionPeriodAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentExamPlacements = composition_period.exam_placements_attributes
      const initialExamPlacements = related?.exam_placements_attributes;
      const exam_placements_attributes = examPlacementUpdater(
        currentExamPlacements as NestedExamPlacementAttributes[],
        initialExamPlacements as NestedExamPlacementAttributes[],
      );
      sorted.push({
        ...result,
        exam_placements_attributes
      });
    }
  });
  return sorted as NestedCompositionPeriodAttributes[];
};



const CompositionsFormContainer = (props: {
  composition_id?: string
  curriculum_id?: number
  onSave: () => void
  close_form?: () => void
}) => {
  const {composition_id, curriculum_id, onSave, close_form } = props
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [initialValues, setInitialValues] = React.useState<Partial<NestedCompositionAttributes> | null>(null);
  const [loaded, setLoaded] = React.useState(false);

  const isUpdating = !isNil(initialValues?.id);

  
  const loadComposition = React.useCallback(async () => {
    if (composition_id) {
      const response = await dispatch(
        FETCH_COMPOSITION.request({
          id: composition_id,
          params: {
            filters: {
              include: 'composition_periods.exam_placements'
            }
          }
        })
      )
      const { data: { included = [], data } } = response
      const nested_attributes = reduce(included, (acc, incl,_, self) => {
        if(incl.type === 'composition_periods'){
          const exam_placements = self.filter(item => item.type === 'exam_placements' && item.attributes.exam_placeable_id === ~~incl.id && item.attributes.exam_placeable_type === 'CompositionPeriod') as ExamPlacementJson[]
          return {...acc, composition_periods_attributes: acc.composition_periods_attributes.concat({
            id: incl.id, ...incl.attributes,
            exam_placements_attributes: exam_placements.map(cp => ({id: cp.id, ...cp.attributes}))
          }) as NestedCompositionPeriodAttributes[]}
        }

        return acc
      }, {
        composition_periods_attributes: [] as NestedCompositionPeriodAttributes[],
      })

      const formatted_data = {
        id: data.id,
        ...data.attributes,
        ...nested_attributes
      }
      setInitialValues(formatted_data);
    } else {
      setInitialValues({ curriculum_id: curriculum_id as number } )
    }
  }, [location]);

  const initCurriculumsForm = React.useCallback(async () => {
    await loadComposition();
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: NestedCompositionAttributes) => {
      const { composition_periods_attributes, ...dataRest } = data
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(dataRest, initialValues, [
            'id',
            'curriculum_id',
          ]) as CurriculumAttributes;
          const updated_composition_periods_attributes =  compositionPeriodUpdater(composition_periods_attributes, initialValues?.composition_periods_attributes || [])
          await dispatch(
            UPDATE_COMPOSITION.request({
              id: initialValues?.id as string,
              data: {
                ...rest,
                composition_periods_attributes: updated_composition_periods_attributes
              },
            }),
          );
        } else {
          await dispatch(
            CREATE_COMPOSITION.request({
              data: {
                ...data,
                curriculum_id
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Matriz curricular salva com sucesso.',
          }),
        );
        await onSave()
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar matriz curricular.',
          }),
        );
      }
    },
    [initialValues, curriculum_id, isUpdating, history],
  );

  React.useEffect(() => {
    initCurriculumsForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <CompositionsForm initialValues={initialValues} close_form={close_form} onSubmit={onSubmit}/>;
};

export default CompositionsFormContainer;
