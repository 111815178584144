import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_ROOM, CreateRoomPayload, DELETE_ROOM, DeleteRoomPayload, FETCH_ROOM, FETCH_ROOMS, FetchRoomPayload, FetchRoomsPayload, UPDATE_ROOM, UpdateRoomPayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRooms (action: DefaultAction<FetchRoomsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchRooms, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ROOMS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ROOMS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchRoom (action: DefaultAction<FetchRoomPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchRoom, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ROOM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ROOM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createRoom (action: DefaultAction<CreateRoomPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'rooms',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createRoom, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_ROOM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_ROOM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateRoom (action: DefaultAction<UpdateRoomPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'rooms',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateRoom, {data: params, id})
    yield put(UPDATE_ROOM.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_ROOM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteRoom (action: DefaultAction<DeleteRoomPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteRoom, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_ROOM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_ROOM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRoomsSagas () {
  yield takeLatest(FETCH_ROOMS.request, fetchRooms)
  yield takeLatest(FETCH_ROOM.request, fetchRoom)
  yield takeLatest(CREATE_ROOM.request, createRoom)
  yield takeLatest(UPDATE_ROOM.request, updateRoom)
  yield takeLatest(DELETE_ROOM.request, deleteRoom)
}

export default function * roomsSagas () {
  yield all([watchRoomsSagas()])
}
