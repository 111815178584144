/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { ConnectionTypes, JsonFormat, JsonResponseFormat, RepresentativeTypes, WithRequestParams } from '../../utils/constants'
import { AddressJson, ProfileJson, UserJson } from '../users'
import { AccountJson } from '../accounts'
import { CompanyJson } from '../companies'
import { CityJson } from '../cities'
import { StateJson } from '../states'
import { UserAlergyJson } from '../alergies'
import { UserDeficiencyJson } from '../deficiencies'
import { RegistrationConnectionJson } from '../registration_connections'
import { RegistrationJson } from '../registrations'
import { RegistrationProductJson } from '../registration_products'

export interface RepresentativeAttrs {
  account_id: number
  connection_description: string
  connection: ConnectionTypes
  kind: RepresentativeTypes,
  occupation: string
  registration_id: number
  representative_document: string
  representative_name: string
  spokesperson: string
  wallet_id: string
}

export interface RepresentativeProductAttrs {
  representative_id: number
  registration_product_id: number
  product_id: number
}

export type RepresentativeJson = JsonFormat<'representatives', RepresentativeAttrs>
export type RepresentativeProductJson = JsonFormat<'representative_products', RepresentativeProductAttrs>
export type FetchRepresentativeResponse = JsonResponseFormat<RepresentativeJson, (
  RegistrationJson|UserJson|AccountJson|CompanyJson|AddressJson|CityJson|StateJson|
  UserAlergyJson|UserDeficiencyJson|RegistrationConnectionJson|ProfileJson|RegistrationProductJson)[]>

export interface FetchRepresentativePayload extends WithRequestParams {
  id: string|number;
}
export interface DeleteRepresentativePayload {
  id: string|number;
}

export interface DeleteRepresentativesPayload {
  ids: (number|string)[];
}

export const FETCH_REPRESENTATIVE = createPromiseAction('representatives: FETCH_REPRESENTATIVE')<FetchRepresentativePayload, FetchRepresentativeResponse, any>()
export const DELETE_REPRESENTATIVE = createPromiseAction('representatives: DELETE_REPRESENTATIVE')<DeleteRepresentativePayload, AxiosResponse, any>()
export const DELETE_REPRESENTATIVES = createPromiseAction('representatives: DELETE_REPRESENTATIVES')<DeleteRepresentativesPayload, AxiosResponse[], any>()

