import React from 'react'
import { SvgIcon } from "@mui/material"

const CloudIcon = (props:any) => {
    return (
        <SvgIcon width='30' height='28' viewBox='0 0 30 28' fill='none' {...props}>
            <path d='M24.0101 27.649H7.69399C5.83992 27.6162 4.06583 26.8836 2.7237 25.5964C1.38158 24.3093 0.569502 22.5617 0.448598 20.7003C0.327694 18.839 0.90679 16.9999 2.071 15.548C3.23521 14.0962 4.89944 13.1375 6.73352 12.8624C7.68369 11.0042 9.19162 9.49437 11.0432 8.54716C12.8949 7.59995 14.9961 7.26355 17.0486 7.58569C19.101 7.90784 21.0004 8.87216 22.4772 10.3418C23.9539 11.8114 24.9329 13.7116 25.2751 15.7726C26.6932 16.1126 27.9392 16.9616 28.7791 18.1602C29.6191 19.3588 29.9953 20.8245 29.8372 22.2823C29.679 23.74 28.9974 25.0897 27.9202 26.0778C26.843 27.0659 25.4444 27.6246 23.9867 27.649H24.0101ZM15.53 8.95077C13.9276 8.96262 12.3613 9.43026 11.012 10.2997C9.66274 11.1691 8.58551 12.4047 7.90482 13.8639C7.85048 13.9831 7.76527 14.0853 7.6581 14.1599C7.55094 14.2346 7.42578 14.2788 7.29576 14.2881C5.73009 14.3443 4.25076 15.0239 3.18321 16.1773C2.11566 17.3307 1.54732 18.8634 1.60324 20.4383C1.65915 22.0132 2.33473 23.5013 3.48136 24.5751C4.62799 25.649 6.15174 26.2207 7.7174 26.1644H24.0101C25.1423 26.1413 26.2244 25.6906 27.0414 24.9018C27.8583 24.1131 28.3504 23.044 28.4197 21.907C28.4891 20.7701 28.1307 19.6485 27.4157 18.7652C26.7007 17.8819 25.6815 17.3015 24.5606 17.1393C24.3959 17.1214 24.2421 17.0475 24.125 16.9296C24.0078 16.8117 23.9342 16.6571 23.9165 16.4913C23.6731 14.4177 22.6831 12.5055 21.1336 11.1162C19.5841 9.72684 17.5823 8.95653 15.5065 8.95077H15.53Z' fill='#F6F8F9'/>
            <path d='M15.1431 22.0042C14.9474 22.0042 14.7597 21.926 14.6213 21.7868C14.4829 21.6476 14.4052 21.4588 14.4052 21.2619V1.62118C14.4052 1.42432 14.4829 1.2355 14.6213 1.0963C14.7597 0.957094 14.9474 0.878906 15.1431 0.878906C15.3388 0.878906 15.5265 0.957094 15.6649 1.0963C15.8033 1.2355 15.881 1.42432 15.881 1.62118V21.2973C15.872 21.488 15.7903 21.6678 15.6528 21.7994C15.5154 21.9311 15.3328 22.0044 15.1431 22.0042Z' fill='#F6F8F9'/>
            <path d='M11.7343 5.38125C11.5383 5.38036 11.3499 5.30452 11.2073 5.16915C11.0692 5.02999 10.9917 4.84146 10.9917 4.64487C10.9917 4.44829 11.0692 4.25972 11.2073 4.12056L14.6158 0.644825C14.7575 0.50801 14.9464 0.431641 15.1428 0.431641C15.3393 0.431641 15.5281 0.50801 15.6699 0.644825L19.0784 4.12056C19.1482 4.18865 19.2036 4.27015 19.2415 4.36021C19.2794 4.45027 19.2989 4.54708 19.2989 4.64487C19.2989 4.74267 19.2794 4.83944 19.2415 4.9295C19.2036 5.01956 19.1482 5.10106 19.0784 5.16915C18.9386 5.3052 18.7517 5.38125 18.5572 5.38125C18.3626 5.38125 18.1757 5.3052 18.0359 5.16915L15.1428 2.22362L12.2497 5.16915C12.1819 5.23687 12.1015 5.29046 12.013 5.32685C11.9246 5.36325 11.8299 5.38175 11.7343 5.38125Z' fill='#F6F8F9'/>
        </SvgIcon>
    )
}

export default CloudIcon