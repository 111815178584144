import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  CREATE_ACCOUNT,
  FETCH_ACCOUNT_SESSION_REQUEST,
  FETCH_ACCOUNT_SESSION_SUCCESS,
  FETCH_ACCOUNT_SESSION_FAILURE,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_REQUEST,
  FETCH_ACCOUNT_FAILURE,
  FetchAccountSessionPayload,
  CreateAccountPayload,
  UpdateAccountPayload,
  FetchAccountPayload,
  AccountJson,
  FETCH_ACCOUNTS,
  FetchAccountsPayload,
  UPDATE_ACCOUNT,
} from './index'
import { DefaultAction, RepresentativeAttributes, profiles } from '../../utils/constants'

import { SET_COMPANY, updateCredentialsRequest } from '../auth'
import * as services from './services'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'
import { CompanyJson } from '../companies'
import { ProfileDashboardJson } from '../profile_dashboards'
import { find, isEmpty, some } from 'lodash'

export function * createAccount (action: DefaultAction<CreateAccountPayload, string>) :any {
  const { payload: {data} } = action
  try{
    const params = data instanceof FormData ? data : {
      data: {
        type: 'accounts',
        attributes: { ...data }
      }
    }
    const response = yield call(services.createAccounts, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_ACCOUNT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {

    yield put(CREATE_ACCOUNT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchAccountSession ({ payload }: DefaultAction<FetchAccountSessionPayload>): any {
  const { id, changeProfile = true } = payload
  try {
    const response = yield call(services.fetchAccountUser, { id })
    if (response.status === 200) {
      const {
        data: { included }
      } = response
      const company = included.find((item: CompanyJson) => item.type === 'companies')
      const account = included.find((item: AccountJson) => item.type === 'accounts')
      const companies = included.filter((item: { type: string }) => item.type === 'companies').map((item: CompanyJson) => {
        const companyAccounts = included.filter((x: any) => x.type === 'accounts' && x.attributes.company_id === ~~item.id).reduce((ac: any,x: any) => {          
          const accountProfiles = [...new Set(included.filter((y: any) => y.type === 'profiles' && y.attributes.account_id === ~~x.id).map((y: any) => ({id: y.id, role: y.attributes.role, label: profiles.find(x => x.name === y.attributes.role)?.label})))]
          const oldValue = ac.map((item: any) => item.role)
          const newValue: any = []
          accountProfiles.forEach((y:any) => {
            !oldValue.includes(y.role) && newValue.push({ role: y.role, id: y.id, account_id: x.id, label: y.label })
          })
          return ac.concat(newValue)
        }, [])
        const companyProfileDashboards = included.filter((incl: any) => incl.type === 'profile_dashboards' && incl.attributes.company_id === ~~item.id).map((profDash: ProfileDashboardJson) => {
          return ({
            id: profDash.id,
            ...profDash.attributes
          })
        })
        const representatives = included.filter((item: any) => item.type === 'representatives' && companyAccounts.map((item: any) => ~~item.account_id).includes(~~item.attributes.account_id)).map((item: any) => ({id: item.id, ...item.attributes})) as RepresentativeAttributes[]
        const registrations = included.filter((item: any) => item.type === 'registrations' && representatives.map(item => ~~item.registration_id).includes(item.id)).map((item: any) => ({id: item.id, ...item.attributes}))
        const teachers = included.filter((item: any) => item.type === 'teachers').map((item: any) => ({id: item.id, ...item.attributes}))
        const coordinators = included.filter((item: any) => item.type === 'coordinators').map((item: any) => ({id: item.id, ...item.attributes}))

        return ({
          id: item.id,
          ...item.attributes,
          profiles: companyAccounts,
          representatives,
          registrations,
          coordinators,
          teachers,
          profile_dashboards: companyProfileDashboards
        })
      })
      yield put(
        FETCH_ACCOUNT_SESSION_SUCCESS({
          current: {
            id: account.id,
            company: { ...company.attributes, id: company.id },
            ...account.attributes
          },
          companies: companies
        })
      )
      if(changeProfile) {
        const rolesWithAccess = profiles.filter(item => !isEmpty(item.default_route)).map(item => item.id)
        const findAdminCompany = find(companies, (item: any) => some(item.profiles, (item: any) => rolesWithAccess.includes(item.role)))
        const findProfile = find(findAdminCompany.profiles, (item: any) => rolesWithAccess.includes(item.role))  
        const profile = findProfile
        const profileAttrs = {
          id: profile['id'],
          account_id:profile['account_id'],
          role: profile['role'],
          label: profile['label']
        }
        yield put(SET_COMPANY.request({company: findAdminCompany, profile: profileAttrs}))
      }
    }
  } catch (error) {
    yield put(FETCH_ACCOUNT_SESSION_FAILURE())
  }
}

export function * updateAccount (action: DefaultAction<UpdateAccountPayload, string>) :any {
  const { payload: {data, id} } = action
  try{
    const params = data instanceof FormData ? data : {
      data: {
        type: 'accounts',
        attributes: { ...data },
        id
      }
    }
    const response = yield call(services.updateAccount, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(UPDATE_ACCOUNT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {

    yield put(UPDATE_ACCOUNT.failure(e))
    rejectPromiseAction(action, e)
  }
}
export function * fetchAccounts (action: DefaultAction<FetchAccountsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchAccounts, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ACCOUNTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ACCOUNTS.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * fetchAccount ({ payload }: DefaultAction<FetchAccountPayload>): any {
  const { id, params, afterEffect, handleErrorEffect } = payload
  try {
    const response = yield call(services.fetchAccount, { id, params })
    if (response.status === 200) {
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(FETCH_ACCOUNT_SUCCESS())
      if (afterEffect) {
        afterEffect(response)
      }
    }
  } catch (e) {
    yield put(FETCH_ACCOUNT_FAILURE())
    if (handleErrorEffect) {
      handleErrorEffect()
    }
  }
}

export function * watchAccountSagas () {
  yield takeLatest(CREATE_ACCOUNT.request, createAccount)
  yield takeLatest(FETCH_ACCOUNT_SESSION_REQUEST, fetchAccountSession)
  yield takeLatest(UPDATE_ACCOUNT.request, updateAccount)
  yield takeLatest(FETCH_ACCOUNT_REQUEST, fetchAccount)
  yield takeLatest(FETCH_ACCOUNTS.request, fetchAccounts)
}

export default function * accountSagas () {
  yield all([watchAccountSagas()])
}
