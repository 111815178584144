import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles, DefaultTheme } from '@mui/styles'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { colors } from '../../../utils/constants'

type Props = {
  disabled: boolean,
  small: boolean,
  withLabel: boolean
}

const useStyles = makeStyles<DefaultTheme, Props>(() => ({
  inputArea: {
    display: 'grid',
    '& span': {
      justifySelf: 'flex-start',
      color: colors.darkGrayBlue,
      fontSize: '0.8rem'
    },
    '& span.error': {
      color: colors.lightRed,
    },
    '& span.error:empty:before': {
      content: '"\\200b"'
    },
    '& .wrapper': {
      background: (props) => (props.disabled ? colors.lightBlue : 'white'),
      borderRadius: '4px',
      border: `1px solid ${colors.grayBlue}`,
      display: 'flex',
      width: '100%'
    },
    '& div.inputArea': {
      display: 'grid',
      justifyItems: 'flex-start',
      cursor: 'pointer',
      padding: props => props.small ? '0rem 0.75rem' : '0.5rem 0.75rem',
      position: 'relative',
      width: 'inherit',
      height: '2.5rem',
      '& input': {
        height: '100%'
      },
      '& label': {
        color: colors.darkGrayBlue,
        fontSize: '10px',
        height: 'fit-content'
      },
      '& .select': {
        width: '100%',
        border: 'none',
        background: 'inherit',
        outline: 'none',
        position: 'absolute',
        height: '100%',
        cursor: 'inherit',
        zIndex: '2',
        '& .MuiSelect-select': {
          flexBasis: '100%',
          color: colors.darkBlue,
          textAlign: 'start',
         ' padding-top': props => props.withLabel ? '1.5rem' : 'initial',
          'padding-bottom': props => props.withLabel ? '0.65rem' : 'initial' ,
        },
        '& fieldset': {
          border: 'none'
        }
      },
      '& .circular': {
        position: 'absolute',
        right: '5%',
        top: '25%',
        cursor: 'inherit',
        zIndex: '1'
      },
      '& svg.end': {
        position: 'absolute',
        right: '17px',
        top: '25%',
        cursor: 'inherit',
        zIndex: '1'
      },
      '& ::placeholder': {
        color: colors.grayBlue,
        fontSize: '16px'
      },
      '& span': {
        justifySelf: 'flex-start',
        fontSize: '16px',
        color: colors.darkBlue
      },
      '& .placeholder': {
        color: colors.grayBlue,
        fontSize: '16px'
      }
    },
    '& .clear': {
      '& svg': {
        color: colors.lightRed
      }
    },
  }
}))

const SelectComponent = (props: any) => {
  const { label, options, placeholder, input, meta, loading, clearable, clearFieldFunction, small, ...rest } = props
  const classes = useStyles({
    disabled: input?.disabled || rest?.disabled,
    withLabel: Boolean(label),
    small
  })
  return (
    <div className={classes.inputArea} id={`input-${input.name}`}>
      <div className='wrapper' >
        <div className='inputArea'>
        <label> {label} </label>
          {input.value === '' && (
            <span className='placeholder'> {placeholder} </span>
          )}
        <Select
          data-testid={input.name}
          autoComplete='new-password'
          className='select'
          {...input}
          {...rest}
        >
          {options?.map((option: { value: any, label: string, disabled: boolean }) => (
            <MenuItem disabled={option.disabled} key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {loading
          && <CircularProgress disableShrink size='1rem' className='circular' />
        }
        </div>
        {input.value !== undefined && input.value !== '' && clearable && clearFieldFunction !== undefined ?
            <IconButton
              className='clear'
              onClick={(e) => {
                e.stopPropagation()
                if(clearFieldFunction){
                  clearFieldFunction()
                }
                input.onChange('')
            }}
            >
              <CloseIcon />
            </IconButton>
             : null}

      </div>
      <span className='error'>{meta && meta.touched && meta.error && meta.error}</span>
    </div>
  )
}

export default SelectComponent
