import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_EXAM, CreateExamPayload, DELETE_EXAM, DeleteExamPayload, FETCH_EXAM, FETCH_EXAMS, FetchExamPayload, FetchExamsPayload, UPDATE_EXAM, UpdateExamPayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchExams (action: DefaultAction<FetchExamsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchExams, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_EXAMS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_EXAMS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchExam (action: DefaultAction<FetchExamPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchExam, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_EXAM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_EXAM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createExam (action: DefaultAction<CreateExamPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'exams',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createExam, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_EXAM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_EXAM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateExam (action: DefaultAction<UpdateExamPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'exams',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateExam, {data: params, id})
    yield put(UPDATE_EXAM.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_EXAM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteExam (action: DefaultAction<DeleteExamPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteExam, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_EXAM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_EXAM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchExamsSagas () {
  yield takeLatest(FETCH_EXAMS.request, fetchExams)
  yield takeLatest(FETCH_EXAM.request, fetchExam)
  yield takeLatest(CREATE_EXAM.request, createExam)
  yield takeLatest(UPDATE_EXAM.request, updateExam)
  yield takeLatest(DELETE_EXAM.request, deleteExam)
}

export default function * examsSagas () {
  yield all([watchExamsSagas()])
}
