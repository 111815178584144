/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { ClassroomAttributes, DefaultOptionType, Role, validation } from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { useSelector } from 'react-redux';
import { evaluate_permissions, getCompanyFilterOptions } from '../../utils/functions';
import { RootState } from '../../store/configureStore';
import CheckboxComponent from '../input/form/checkbox';
import DatePickerComponent from '../input/form/datepicker';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      padding: '2rem 0',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const CalendarEventsForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  initialValues: ClassroomAttributes;
  close_form: () => void;
}) => {
  const { handleSubmit, onSubmit, initialValues, close_form } = props;
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;
  const formValues = formValueSelector('calendarEventsForm');
  const companyIdValue = formValues(state, 'company_id') as string;
  const starts_at = formValues(state, 'starts_at') as Date | null;
  const ends_at = formValues(state, 'starts_at') as Date | null;
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);

  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  const mustSelectCompanyId = is_above_school_director && !initialValues?.id;

  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar Evento</span>
      <form className={classes.form}>
        {mustSelectCompanyId && (
          <>
            <span style={{ justifySelf: 'self-start' }} className='subtitle-one'>
              {' '}
              Unidade{' '}
            </span>
            <Field
              label={'Unidade'}
              placeholder={'Selecionar unidade'}
              name='company_id'
              component={SelectComponent}
              options={companies_options as DefaultOptionType[]}
              validate={[...(mustSelectCompanyId ? [validation.required] : [])]}
            />
          </>
        )}

        {companyIdValue && (
          <div>
            <Field
              name='name'
              component={InputComponent}
              label={'Nome'}
              placeholder={'Insira o nome do evento'}
              validate={[validation.required]}
            />
            <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
              <Field
                name={`starts_at`}
                label={'Início do período*'}
                placeholder={'01/01/2024'}
                component={DatePickerComponent}
                datePickerProps={{
                  ...(ends_at && { maxDate: ends_at instanceof Date ? ends_at : new Date(ends_at) }),
                  showDropdownIcon: true,
                }}
                validate={[validation.dateRequired]}
              />
              <Field
                name={`ends_at`}
                label={'Fim do período*'}
                placeholder={'01/01/2024'}
                component={DatePickerComponent}
                datePickerProps={{
                  ...(starts_at && { minDate: starts_at instanceof Date ? starts_at : new Date(starts_at) }),
                  showDropdownIcon: true,
                }}
                validate={[validation.dateRequired]}
              />
            </div>
            <Field name='allow_class' component={CheckboxComponent} placeholder={'Permitir aulas'} />
            <div className={classes.buttons}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  close_form();
                }}
                className='red small'
              >
                <span> Cancelar </span>
              </button>{' '}
              <button onClick={handleSubmit(onSubmit)} className='blue small'>
                <span> Salvar Evento </span>
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: 'calendarEventsForm',
  }),
)(CalendarEventsForm);
