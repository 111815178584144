/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { UserJson } from '../users'
import { CompanyJson } from '../companies'

export interface ContractSettingAttrs {
  company_id: number
  description: string
  params: object
  clauses: string
  signatures: string[]
  witnesses: number
  service: string
}

export interface ContractSettingSignerAttrs {
  file_url: string
  file_attachment_id: number
  contract_setting_id: string
  user_id: string
}

export type ContractSettingsJson = JsonFormat<'contract_settings', ContractSettingAttrs>
export type ContractSettingSignerJson = JsonFormat<'contract_setting_signers', ContractSettingSignerAttrs>

export type FetchContractSettingsResponse = JsonResponseFormat<ContractSettingsJson[], (ContractSettingSignerJson|CompanyJson)[]>
export type FetchContractSettingResponse = JsonResponseFormat<ContractSettingsJson, (ContractSettingSignerJson | UserJson)[]>
export type FetchContractSettingsPayload = WithRequestParams
export interface FetchContractSettingPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateContractSettingPayload {
  data: object | FormData
}

export interface UpdateContractSettingPayload extends CreateContractSettingPayload {
  id: string
}

export const FETCH_CONTRACT_SETTINGS = createPromiseAction('contract_settings: FETCH_CONTRACT_SETTINGS')<FetchContractSettingsPayload, FetchContractSettingsResponse, any>()
export const FETCH_CONTRACT_SETTING  = createPromiseAction('contract_settings: FETCH_CONTRACT_SETTING')<FetchContractSettingPayload, FetchContractSettingResponse, any>()
export const CREATE_CONTRACT_SETTING = createPromiseAction('contract_settings: CREATE_CONTRACT_SETTING')<CreateContractSettingPayload, FetchContractSettingResponse, any>()
export const UPDATE_CONTRACT_SETTING = createPromiseAction('contract_settings: UPDATE_CONTRACT_SETTING')<UpdateContractSettingPayload, FetchContractSettingResponse, any>()