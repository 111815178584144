import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_USER,
  FETCH_USERS,
  UPDATE_USER,
  DELETE_USER_AVATAR,
  FetchUserPayload,
  FetchUsersPayload,
  UpdateUserPayload,
  DeleteUserAvatarPayload,
  CreateUserPayload,
  CREATE_USER,
  RECOVER_PASSWORD,
  RecoverPasswordPayload,
  DELETE_USER,
  RegisterUserActionPayload,
  REGISTER_USER_ACTION,
  VerifyUserHierarchyPayload,
  VERIFY_USER_HIERARCHY
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'
import { updateCredentialsRequest } from '../auth'
import { resolvePromiseAction, rejectPromiseAction } from 'redux-saga-promise-actions'

export function * fetchUsers (action : DefaultAction<FetchUsersPayload, string>): any {
  const { payload : {params} } = action
  
  try {
    const response = yield call(services.fetchUsers, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_USERS.success(response))
    resolvePromiseAction(action, response)

  } catch (e) {
    rejectPromiseAction(action, e)
    yield put(FETCH_USERS.failure(e))
  }
}

export function * createUser (action: DefaultAction<CreateUserPayload, string>): any {
  const { payload: {data}} = action

  const params = data instanceof FormData ? data : {
    data: {
      type: 'users',
      attributes: { ...data },
    }
  }
  try {
    const response = yield call(services.createUser, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_USER.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_USER.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateUsers (action: DefaultAction<UpdateUserPayload, string>): any {
  const { payload: {id, data}} = action
  const params = data instanceof FormData ? data : {
    data: {
      id,
      type: 'users',
      attributes: { ...data },
    }
  }
  try {
    const response = yield call(services.updateUsers, { id, params })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(UPDATE_USER.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_USER.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchUser (action: DefaultAction<FetchUserPayload, string>): any {
  const { payload: {id, params}} = action
  try {
    const response = yield call(services.fetchUser, { id, params })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_USER.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(FETCH_USER.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteUser (action: DefaultAction<DeleteUserAvatarPayload, string>) : any {
  const { payload: {id}} = action
  
  try {
    const response = yield call(services.deleteUser, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_USER.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_USER.failure(e))
    rejectPromiseAction(action, e)
  }
}
export function * deleteUserAvatar (action: DefaultAction<DeleteUserAvatarPayload, string>) : any {
  const { payload: {id}} = action
  
  try {
    const response = yield call(services.deleteUserAvatar, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_USER_AVATAR.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_USER_AVATAR.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * recoverUserPassword (action: DefaultAction<RecoverPasswordPayload, string>): any {
  const { payload: {data}} = action
  const params = {
    data: {
      type: 'users',
      attributes: { ...data },
    }
  }    

  try {
    const response = yield call(services.recoverUserPassword, { data: params })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(RECOVER_PASSWORD.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(RECOVER_PASSWORD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * registerUserAction (action: DefaultAction<RegisterUserActionPayload, string>): any {
  const { payload: {data}} = action
  const params = {
    data: {
      type: 'users',
      attributes: { ...data },
    }
  }    

  try {
    const response = yield call(services.registerUserAction, { data: params })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(REGISTER_USER_ACTION.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(REGISTER_USER_ACTION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * verifyUserHierarchy (action: DefaultAction<VerifyUserHierarchyPayload, string>) : any {
  const { payload: {id}} = action
  
  try {
    const response = yield call(services.verifyUserHierarchy, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(VERIFY_USER_HIERARCHY.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(VERIFY_USER_HIERARCHY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchUserSagas () {
  yield takeLatest(CREATE_USER.request, createUser)
  yield takeLatest(FETCH_USERS.request, fetchUsers)
  yield takeLatest(UPDATE_USER.request, updateUsers)
  yield takeLatest(FETCH_USER.request, fetchUser)
  yield takeLatest(DELETE_USER.request, deleteUser)
  yield takeLatest(DELETE_USER_AVATAR.request, deleteUserAvatar)
  yield takeLatest(RECOVER_PASSWORD.request, recoverUserPassword)
  yield takeLatest(REGISTER_USER_ACTION.request, registerUserAction)
  yield takeLatest(VERIFY_USER_HIERARCHY.request, verifyUserHierarchy)
}

export default function * accountSagas () {
  yield all([watchUserSagas()])
}
