import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_SUBSIDIES,
  FetchSubsidiesPayload,
} from './index'
import { DefaultAction } from '../../utils/constants'
import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchSubsidies (action: DefaultAction<FetchSubsidiesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchSubsidies, params)
    if (response.status === 200) {
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(FETCH_SUBSIDIES.success(response))
      resolvePromiseAction(action, response)
    }
  } catch(e) {
    yield put(FETCH_SUBSIDIES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchSubsidiesSagas () {
  yield takeLatest(FETCH_SUBSIDIES.request, fetchSubsidies)
}

export default function * subsidySagas () {
  yield all([watchSubsidiesSagas()])
}
