import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const calendar_eventsEndpoint = '/calendar_events'

export const fetchCalendarEvents = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${calendar_eventsEndpoint}?${queryParams}`)
}

export const fetchCalendarEvent = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${calendar_eventsEndpoint}/${id}?${queryParams}`)
}

export const createCalendarEvent = (data: object) => {
  return http.post(`${calendar_eventsEndpoint}`, data)
}

export const updateCalendarEvent = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${calendar_eventsEndpoint}/${id}`, data)
}

export const deleteCalendarEvent = ({ id }: { id: string | number } ) => {
  return http.delete(`${calendar_eventsEndpoint}/${id}`)
}