/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { ConnectionTypes, RepresentativeTypes, WithRequestParams } from '../../utils/constants'

export interface RepresentativeAttrs {
  account_id: number
  connection: ConnectionTypes
  connection_description: string
  kind: RepresentativeTypes,
  product_ids: (string|number)[]
  registration_id: number
  spokesperson: string
  occupation: string
}

export interface AsaasCustomerAttrs {
  "object": "customer",
  "id": string,
  "dateCreated": string,
  "name": string,
  "email": string,
  "company": string,
  "phone": string,
  "mobilePhone": string,
  "address": string,
  "addressNumber": string,
  "complement": string,
  "province": string,
  "postalCode": string,
  "cpfCnpj": string,
  "personType": string,
  "deleted": boolean,
  "additionalEmails": string,
  "externalReference": string,
  "notificationDisabled": boolean,
  "observations": string,
  "municipalInscription": string,
  "stateInscription": string,
  "canDelete": boolean,
  "cannotBeDeletedReason": string,
  "canEdit": boolean,
  "cannotEditReason": string,
  "foreignCustomer": boolean,
  "city": string,
  "state": string,
  "country": "Brasil"
}

export interface AsaasPaymentAttrs {
  "object": "payment",
  "id": string,
  "dateCreated": string,
  "customer": string,
  "paymentLink": string,
  "value": number,
  "netValue": number,
  "originalValue": string,
  "interestValue": string,
  "description": string,
  "billingType": string,
  "canBePaidAfterDueDate": boolean,
  "pixTransaction": string,
  "status": string,
  "dueDate": string,
  "originalDueDate": string,
  "paymentDate": string,
  "clientPaymentDate": string,
  "installmentNumber": string,
  "transactionReceiptUrl": string,
  "nossoNumero": string,
  "invoiceUrl": string,
  "invoiceNumber": string,
  "externalReference": string,
  "deleted": boolean,
  "bankSlipUrl": string,
  "postalService": boolean,
  "anticipated": boolean,
  "anticipable": boolean,
  "refunds": string
}

export interface AsaasWebhookSettingsAttrs {
  "object": "webhook",
  "url": string,
  "email": string,
  "enabled": boolean,
  "interrupted": boolean,
  "apiVersion": number,
  "authToken": string
}

export interface AsaasResponseFormat <A> extends AxiosResponse {
  data: A
}

export type FetchAsaasPaymentResponse = AsaasResponseFormat<AsaasPaymentAttrs[]>

export type FetchAsaasCustomerPayload = WithRequestParams
export type FetchAsaasPaymentPayload = WithRequestParams
export type FetchAsaasWebhookSettingPayload = WithRequestParams

export type FetchAsaasCustomerResponse = AsaasResponseFormat<AsaasCustomerAttrs[]>
export type FetchAsaasWebhookSettingResponse = AsaasResponseFormat<AsaasWebhookSettingsAttrs>

export type UpdateAsaasWebhookSettingPayload = {
  data: AsaasWebhookSettingsAttrs
}

export const FETCH_ASAAS_CUSTOMERS = createPromiseAction('asaas: FETCH_ASAAS_CUSTOMERS')<FetchAsaasCustomerPayload, FetchAsaasCustomerResponse , any>()
export const FETCH_ASAAS_PAYMENTS = createPromiseAction('asaas: FETCH_ASAAS_PAYMENTS')<FetchAsaasPaymentPayload, FetchAsaasPaymentResponse, any>()
export const FETCH_ASAAS_WEBHOOK_SETTING = createPromiseAction('asaas: FETCH_ASAAS_WEBHOOK_SETTING')<FetchAsaasWebhookSettingPayload, FetchAsaasWebhookSettingResponse, any>()
export const UPDATE_ASAAS_WEBHOOK_SETTING = createPromiseAction('asaas: UPDATE_ASAAS_WEBHOOK_SETTING')<UpdateAsaasWebhookSettingPayload, FetchAsaasWebhookSettingResponse, any>()


