import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const classTimeEndpoint = '/class_times'

export const fetchClassTimes = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${classTimeEndpoint}?${queryParams}`)
}

export const fetchClassTime = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${classTimeEndpoint}/${id}?${queryParams}`)
}

export const createClassTime = (data: object) => {
  return http.post(`${classTimeEndpoint}`, data)
}

export const updateClassTime = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${classTimeEndpoint}/${id}`, data)
}

export const deleteClassTime = ({ id }: { id: string | number } ) => {
  return http.delete(`${classTimeEndpoint}/${id}`)
}