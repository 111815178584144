/* eslint-disable camelcase */
import React from 'react'
import { InvoiceAttributes, InvoiceFormAttributes, InvoiceItemAttributes, NestedInvoiceItemAttributes, NestedWalletCreditAttributes, WalletCreditAttributes, colors } from '../../utils/constants'
import { InvoiceItemBaseValues } from '../../store/invoice_items'
import { formatToCurrency, invoiceItemValues } from '../../utils/functions'
import { css } from '@emotion/react'
import InvoiceValue from './InvoiceValues'
import { HighlightOffOutlined } from '@mui/icons-material'


const InvoiceItemSummary = (props: {
  invoice: InvoiceAttributes | InvoiceFormAttributes
  invoice_item: NestedInvoiceItemAttributes | InvoiceItemAttributes
  invoice_items: NestedInvoiceItemAttributes[] | InvoiceItemAttributes[]
  allow_remove_values?: boolean
  handleClearValue?: (key: keyof InvoiceItemBaseValues) => void
  wallet_credits: (WalletCreditAttributes | NestedWalletCreditAttributes)[]
}) => {
  const { invoice, invoice_item, invoice_items, allow_remove_values = false, handleClearValue, wallet_credits } = props
  const result = invoiceItemValues({
    invoice_item,
    invoice,
    invoice_items,
    wallet_credits
  });
  const {
    accumulated_fees,
    accumulated_penalty,
    billing_conditional_discount,
    billing_full_value,
    current_anticipation_discount,
    current_charge,
    current_conditional_charge,
    current_contractual_addition,
    current_installment_addition,
    current_renegotiation_allowance,
    expected_liquid_value,
    initial_anticipation_discount,
    initial_conditional_discount_charge,
    initial_contractual_addition,
    initial_fees,
    initial_installment_addition,
    initial_penalty,
    initial_renegotiation_allowance,
    current_credits_discount
  } = result;
  const iconProps = {style:{ color: colors.lightRed }}
  const icon = HighlightOffOutlined
  return(
    <div
    css={css`
      align-items: center;
      background: white;
      border: 2px solid ${colors.darkGrayBlue};
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      flex-direction: column;
      gap: 1vmin;
      justify-items: center;
      padding: 1vmin;
      width: 100%;
    `}
  >
    <div />
    <span>Valor Total Contrato: {formatToCurrency(billing_full_value)}</span>
    <span>Valor Pontualidade Contrato: {formatToCurrency(billing_conditional_discount)}</span>
    <span> Valores iniciais do item nessa fatura </span>
    <InvoiceValue
      values={[
        {
          label: 'Valor Acréscimo Contratual',
          value: initial_contractual_addition,
          ...(allow_remove_values &&
            initial_contractual_addition > 0 && {
              iconFunction: () => handleClearValue && handleClearValue('initial_contractual_addition'),
              iconProps,
              icon
            }),
        },
        {
          label: 'Valor Acréscimo de Parcela',
          value: initial_installment_addition,
          ...(allow_remove_values &&
            initial_installment_addition > 0 && {
              iconFunction: () => handleClearValue && handleClearValue('initial_installment_addition'),
              iconProps,
              icon
            }),
        },
        {
          label: 'Valor Abono de Renegociação',
          value: initial_renegotiation_allowance,
          ...(allow_remove_values &&
            initial_renegotiation_allowance > 0 && {
              iconFunction: () => handleClearValue && handleClearValue('initial_renegotiation_allowance'),
              iconProps,
              icon
            }),
        },
        {
          label: 'Juros',
          value: initial_fees,
          ...(allow_remove_values &&
            initial_fees > 0 && { iconFunction: () => handleClearValue && handleClearValue('initial_fees') }),
            iconProps,
            icon
        },
        {
          label: 'Multa',
          value: initial_penalty,
          ...(allow_remove_values &&
            initial_penalty > 0 && {
              iconFunction: () => handleClearValue && handleClearValue('initial_penalty'),
              iconProps,
              icon  
            }),
        },
      ]}
    />
    <InvoiceValue
      values={[
        {
          label: 'Valor Desconto Antecipação',
          value: initial_anticipation_discount,
          ...(allow_remove_values &&
            initial_anticipation_discount > 0 && {
              iconFunction: () => handleClearValue && handleClearValue('initial_anticipation_discount'),
              iconProps,
              icon
            }),
        },
        { label: 'Valor pontualidade inicial', value: initial_conditional_discount_charge },
      ]}
    />
    <span> Valores atuais do item nessa fatura </span>
    <InvoiceValue
      values={[
        { label: 'Valor Acréscimo Contratual', value: current_contractual_addition },
        { label: 'Valor Acréscimo de Parcela', value: current_installment_addition },
        { label: 'Valor Abono de Renegociação', value: current_renegotiation_allowance },
        { label: 'Juros', value: accumulated_fees },
        { label: 'Multa', value: accumulated_penalty },
        { label: 'Créditos', value: current_credits_discount},
        { label: 'Valor Cobrança Atual', value: current_charge },
        { label: 'Valor Líquido Esperado', value: expected_liquid_value },
      ]}
    />
    <InvoiceValue
      values={[
        { label: 'Valor Desconto Antecipação', value: current_anticipation_discount },
        { label: 'Valor Pontualidade Atual', value: current_conditional_charge },
      ]}
    />
  </div>
  )
}

export default InvoiceItemSummary
