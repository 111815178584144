import React from 'react'
import { SvgIcon } from "@mui/material"

const UploadIcon = (props: any) => (
    <SvgIcon width='13' height='15' viewBox='0 0 13 15' fill='none' {...props}>
        <g clipPath='url(#clip0_1460_4598)'>
        <path d='M12.3518 6.59384C12.2386 6.59365 12.13 6.54979 12.0494 6.47174L6.49179 1.01528L0.939638 6.47174C0.859423 6.55057 0.750662 6.59487 0.637221 6.59487C0.52378 6.59487 0.414953 6.55057 0.334738 6.47174C0.254523 6.3929 0.209473 6.28598 0.209473 6.1745C0.209473 6.06301 0.254523 5.95609 0.334738 5.87726L6.18931 0.123561C6.22896 0.0844022 6.27612 0.0533083 6.32802 0.032105C6.37993 0.0109017 6.43558 0 6.49179 0C6.548 0 6.60366 0.0109017 6.65556 0.032105C6.70746 0.0533083 6.75463 0.0844022 6.79428 0.123561L12.6488 5.87726C12.6887 5.91622 12.7203 5.96254 12.7419 6.01355C12.7634 6.06456 12.7746 6.11926 12.7746 6.1745C12.7746 6.22974 12.7634 6.28443 12.7419 6.33544C12.7203 6.38645 12.6887 6.43277 12.6488 6.47174C12.5695 6.54852 12.4631 6.59228 12.3518 6.59384Z' fill='#5FBFED'/>
        <path d='M6.49188 11.5499C6.37872 11.5499 6.2702 11.5057 6.19019 11.4271C6.11017 11.3484 6.06519 11.2418 6.06519 11.1306V0.419341C6.06519 0.308131 6.11017 0.201453 6.19019 0.122815C6.2702 0.0441776 6.37872 0 6.49188 0C6.60504 0 6.71355 0.0441776 6.79357 0.122815C6.87359 0.201453 6.91857 0.308131 6.91857 0.419341V11.1571C6.9117 11.2637 6.86369 11.3636 6.78441 11.4366C6.70512 11.5096 6.60051 11.5501 6.49188 11.5499Z' fill='#5FBFED'/>
        <path d='M12.5678 15.0002H0.426564C0.320987 14.9885 0.223501 14.939 0.152694 14.8612C0.0818874 14.7834 0.0427246 14.6826 0.0427246 14.5782C0.0427246 14.4738 0.0818874 14.373 0.152694 14.2952C0.223501 14.2174 0.320987 14.1679 0.426564 14.1562H12.5678C12.6734 14.1679 12.7709 14.2174 12.8417 14.2952C12.9125 14.373 12.9516 14.4738 12.9516 14.5782C12.9516 14.6826 12.9125 14.7834 12.8417 14.8612C12.7709 14.939 12.6734 14.9885 12.5678 15.0002Z' fill='#5FBFED'/>
        </g>
        <defs>
        <clipPath id='clip0_1460_4598'>
        <rect width='13' height='15' fill='white'/>
        </clipPath>
        </defs>
</SvgIcon>
)

export default UploadIcon