import React from 'react'
import { Route } from 'react-router-dom'
import { Route as RouteTypes } from './pathUrls'

interface RouteProps extends RouteTypes {
  template: React.ElementType
}

const RouteWithTemplate = ({ component: Component, template: Template, ...rest } : RouteProps ) => {
  if(!Component) {
    return null
  }
  return(
  <Route
    {...rest}
    render={(props) => (
      <Template {...props}>
        <Component {...props} />
      </Template>
    )}
  />
)}


export default RouteWithTemplate
