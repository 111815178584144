import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_KTWELVES,
  FetchKtwelvesPayload,
  FetchKtwelvePayload,
  FETCH_KTWELVE,
  CreateKtwelvePayload,
  CREATE_KTWELVE,
  UPDATE_KTWELVE,
  UpdateKtwelvePayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchKtwelves (action: DefaultAction<FetchKtwelvesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchKtwelves, params)
    if (response.status === 200) {
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(FETCH_KTWELVES.success(response))
      resolvePromiseAction(action, response)
    }
  } catch(e) {
    yield put(FETCH_KTWELVES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchKtwelve (action: DefaultAction<FetchKtwelvePayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchKtwelve, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_KTWELVE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_KTWELVE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createKtwelve (action: DefaultAction<CreateKtwelvePayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'ktwelve_degrees',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createKtwelve, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_KTWELVE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_KTWELVE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateKtwelve (action: DefaultAction<UpdateKtwelvePayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'ktwelve_degrees',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateKtwelve, {data: params, id})
    yield put(UPDATE_KTWELVE.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_KTWELVE.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * watchKtwelvesSagas () {
  yield takeLatest(FETCH_KTWELVES.request, fetchKtwelves)
  yield takeLatest(FETCH_KTWELVE.request, fetchKtwelve)
  yield takeLatest(CREATE_KTWELVE.request, createKtwelve)
  yield takeLatest(UPDATE_KTWELVE.request, updateKtwelve)
}

export default function * ktwelvesSagas () {
  yield all([watchKtwelvesSagas()])
}
