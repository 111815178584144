/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, DiscountTypeEnum, WithRequestParams } from '../../utils/constants'
import { CompanyJson } from '../companies'

export interface DiscountTypesAttrs {
  "company_id": number
  "name": string
  "description": string
  "suggested_kind": DiscountTypeEnum
  "suggested_discount": number
}

export type DiscountTypes = JsonFormat<'discount_types', DiscountTypesAttrs>

export type FetchDiscountTypesResponse = JsonResponseFormat<DiscountTypes[], (CompanyJson)[]>
export type FetchDiscountTypeResponse = JsonResponseFormat<DiscountTypes>

export type FetchDiscountTypesPayload = WithRequestParams
export interface FetchDiscountTypePayload extends WithRequestParams {
  id: string | number,
}
export interface CreateDiscountTypePayload {
  data: object
}

export interface UpdateDiscountTypePayload extends CreateDiscountTypePayload {
  id: string
}


export const FETCH_DISCOUNT_TYPES = createPromiseAction('discount_types: FETCH_DISCOUNT_TYPES')<FetchDiscountTypesPayload, FetchDiscountTypesResponse, any>()
export const FETCH_DISCOUNT_TYPE  = createPromiseAction('discount_types: FETCH_DISCOUNT_TYPE')<FetchDiscountTypePayload, FetchDiscountTypeResponse, any>()
export const CREATE_DISCOUNT_TYPE = createPromiseAction('discount_types: CREATE_DISCOUNT_TYPE')<CreateDiscountTypePayload, FetchDiscountTypeResponse, any>()
export const UPDATE_DISCOUNT_TYPE = createPromiseAction('discount_types: UPDATE_DISCOUNT_TYPE')<UpdateDiscountTypePayload, FetchDiscountTypeResponse, any>()