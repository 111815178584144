import React from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { defaultPagination, PaginationType, PeriodTypes, WalletsViewAttributes } from '../utils/constants';
import { RootState } from '../store/configureStore';
import { get_company_search } from '../utils/functions';
import { FETCH_WALLETS } from '../store/wallets';
import WalletsView from '../components/view/Secretary/WalletsView';

const WalletsContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants },
    account: { companies },
  } = state;

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [wallets, setWallets] = React.useState<WalletsViewAttributes[]>([]);
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [documentNumberValue, setDocumentNumberFilterValue] = React.useState('')
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);

  const fetchWalletsMethod = React.useCallback(
    async ({
      newPagination = pagination,
      company_filter = companyFilterValue,
    }: {
      newPagination?: PaginationType;
      period?: PeriodTypes | string;
      company_filter?: string;
    }) => {
      setLoading(true);
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company,
      });
      try {
        const wallet = await dispatch(
          FETCH_WALLETS.request({
            params: {
              filters: {
                'q[account_user_name_cont]': nameFilterValue,
                'q[account_company_id_in]': companies_array,
                'q[account_user_document_number_eq]': documentNumberValue,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                'q[s]': 'balance desc'
              },
            },
          }),
        );
        const {
          data: { data, meta },
        } = wallet;
        const formattedWallets = data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setWallets(formattedWallets);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar carteiras',
          }),
        );
      }
    },
    [pagination, nameFilterValue, company, companyFilterValue, documentNumberValue],
  );

  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchWalletsMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchWalletsMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const loadAssets = React.useCallback(async () => {
    await fetchWalletsMethod({});
  }, [company]);

  React.useEffect(() => {
    loadAssets();
  }, []);

  return (
    <WalletsView
      wallets={wallets}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      nameFilterValue={nameFilterValue}
      setNameFilterValue={setNameFilterValue}
      documentNumberFilterValue={documentNumberValue}
      setDocumentNumberFilterValue={setDocumentNumberFilterValue}
      fetchWalletsMethod={fetchWalletsMethod}
      setCompanyFilterValue={setCompanyFilterValue}
      companyFilterValue={companyFilterValue}
    />
  );
};

export default WalletsContainer;
