import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import SelectProfile from '../input/SelectProfileInput'
import { colors, ssoUrl } from '../../utils/constants'
import { nameToInitials, encodeQueryData } from '../../utils/functions'
import { SIGN_OUT } from '../../store/auth'
import { RootState } from '../../store/configureStore'

const useStyles = makeStyles(() => ({
  header: {
    background: 'white',
    height: '5rem',
    padding: '1rem 2rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    justifyContent: 'space-between'
  },
  initials: {
    background: 'lightblue',
    borderRadius: '50%',
    display: 'flex',
    width: '3rem',
    height: '3rem',
    '& > span': {
      fontSize: '1rem',
      margin: 'auto'
    },
    '& > img': {
      width: '100%',
      height: '100%',
      margin: 'auto',
      borderRadius: '50%',
    }
  },
  input: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      background: colors.lightBlue,
      '& fieldset': {
        border: '0'
      }
    },
    '& label': {
      color: colors.darkGrayBlue
    }
  },
  user: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '& > .info': {
      display: 'grid',
      textAlign: 'end',
      color: colors.darkBlue
    },
    '& > img': {
      maxWidth: '3rem',
      borderRadius: '50%',
      marginLeft: '1rem'
    }
  },
  selectProfile: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '15rem',
      marginRight: '1rem'
    },
    '& > span': {
      marginBottom: '14px',
      marginRight: '1rem'
    }
  }
}))

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()
  const {
    REACT_APP_SERVER_ENV = 'local',
    REACT_APP_SSO_ENV = REACT_APP_SERVER_ENV,
  } = process.env
  const data = {
    app_id: 44,
  }
  const urlString = encodeQueryData(data)
  const url = `${ssoUrl[REACT_APP_SSO_ENV]}?${urlString}`
  const {
    auth: { user, profile },
  } = useSelector((state: RootState) => state)
  const profileLabel = profile.label
  const dispatch = useDispatch()
  const handleClick = (event: any) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div className={classes.header}>
      <div className={classes.selectProfile}>
        <SelectProfile />
      </div>
      <div className={classes.user}>
        <div className='info'>
          <span>
            <strong>{user.name}</strong>
          </span>
          <span>{profileLabel}</span>
        </div>
        <div className={classes.initials}>
          {user.avatar_url ? (
            <img alt={user.name} src={user.avatar_url} />
          ) : (
            <span> {nameToInitials(user.name)} </span>
          )}
        </div>
        <ArrowDropDownIcon onClick={handleClick} />
        <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem>
            <a style={{ textDecoration: 'none', color: 'inherit' }} href={url} rel='noopener noreferrer' target='_blank'>
              Trocar senha
              </a>
            </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(SIGN_OUT.request())
              handleClose()
            }}
          >
            Sair
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default Header
