/* eslint-disable camelcase */
import { makeStyles } from '@mui/styles';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { validation } from '../../utils/constants';
import InputComponent from '../input/form/input';
import CheckboxComponent from '../input/form/checkbox';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const form_name = 'examPlacementForm';

export const ExamPlacementFormBody = () => {
  return (
    <div style={{ width: '100%' }}>
      <Field
        name='name'
        component={InputComponent}
        label={'Nome'}
        placeholder={'Insira o nome da avaliação'}
        validate={[validation.required]}
      />
      <Field
        label='Ordem'
        component={InputComponent}
        name='order'
        onlyNumbers
        placeholder={'Ordem'}
        validate={[validation.required]}
      />
      <Field
        name='mandatory'
        component={CheckboxComponent}
        placeholder={'Avaliação Mandatória'}
      />
      <Field
        name='replaceable'
        component={CheckboxComponent}
        placeholder={'Substituível'}
      />
    </div>
  );
};

const ExamPlacementForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form: () => void;
}) => {
  const { handleSubmit, onSubmit, close_form } = props;
  const classes = useStyles();

  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar Avaliação</span>
      <form className={classes.form}>
        <ExamPlacementFormBody />
        <div className={classes.buttons}>
          <button
            onClick={(e) => {
              e.preventDefault();
              close_form();
            }}
            className='red small'
          >
            <span> Cancelar </span>
          </button>
          <button onClick={handleSubmit(onSubmit)} className='blue small'>
            <span> Salvar Avaliação </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(ExamPlacementForm);
