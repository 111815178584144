/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { AxiosResponse } from 'axios'
import { CustomJsonResponseFormat, JsonFormat, JsonResponseFormat, ProfileTypes, RoleTypes, WithRequestParams } from '../../utils/constants'
import { CompanyJson } from '../companies'
import { AccountJson } from '../accounts'
import { CityJson } from '../cities'
import { StateJson } from '../states'
import { UserAlergyJson } from '../alergies'
import { UserDeficiencyJson } from '../deficiencies'
import { RegistrationConnectionJson } from '../registration_connections'
import { RepresentativeJson } from '../representatives'
import { UserRaceJson } from '../races'

export interface AddressAttrs {
  user_id: number
  country_id: number
  state_id: number
  city_id: number
  street: string
  complement: string
  neighbourhood: string
  number: string
  zipcode: string
  zap_sign_user_token: string
}

export type AddressJson = JsonFormat<'addresses', AddressAttrs>

export interface UserAttrs {
  name: string
  email: string
  gender: string
  phone: string
  birthdate: string
  document_type: string
  document_number: string
  nationality: string
  birthplace: string
  marital_status: string
  fractal_id: number
  additional_data: {
    social_name: string
    personal_email: string
  },
  profiles: ProfileTypes[]
  avatar_attachment_id: number
  avatar_url: string
  race_ids: number[]
  ethnicity_ids: number[]
}

export interface ProfileAttrs {
  account_id: number
  role: RoleTypes
}

export type UserJson = JsonFormat<'users', UserAttrs>
export type ProfileJson = JsonFormat<'profiles', ProfileAttrs>

export type FetchUsersResponse = JsonResponseFormat<UserJson[],(AccountJson|CompanyJson|AddressJson|CityJson|StateJson|UserAlergyJson|UserDeficiencyJson|ProfileJson|RepresentativeJson|UserRaceJson)[]>

export type FetchUserResponse = JsonResponseFormat<UserJson, (AccountJson|CompanyJson|AddressJson|CityJson|StateJson|UserAlergyJson|UserDeficiencyJson|RegistrationConnectionJson|ProfileJson|RepresentativeJson|UserRaceJson)[]>
export type FetchUsersPayload = WithRequestParams
export interface CreateUserPayload {
  data: object | FormData
}
export interface UpdateUserPayload extends CreateUserPayload {
  id: string | number,
}

export interface FetchUserPayload extends WithRequestParams {
  id: string | number
}

export interface DeleteUserAvatarPayload {
  id: string | number,
}

export interface VerifyUserHierarchyPayload {
  id: string | number,
}

export interface RecoverPasswordPayload {
  data: object
}

export interface RegisterUserActionPayload {
  data: object
}

export type VerifyUserHierarchyResponse = CustomJsonResponseFormat<boolean>
export const CREATE_USER = createPromiseAction('users: CREATE_USER')<CreateUserPayload, FetchUserResponse, any>()
export const FETCH_USERS = createPromiseAction('users: FETCH_USERS')<FetchUsersPayload, FetchUsersResponse, any>()
export const UPDATE_USER = createPromiseAction('users: UPDATE_USER')<UpdateUserPayload, FetchUserResponse, any>()
export const FETCH_USER = createPromiseAction('users: FETCH_USER')<FetchUserPayload, FetchUserResponse, any>()
export const DELETE_USER = createPromiseAction('users: DELETE_USER')<DeleteUserAvatarPayload, AxiosResponse, any>()
export const DELETE_USER_AVATAR = createPromiseAction('users: DELETE_USER_AVATAR')<DeleteUserAvatarPayload, AxiosResponse, any>()
export const RECOVER_PASSWORD = createPromiseAction('users: RECOVER_PASSWORD')<RecoverPasswordPayload, AxiosResponse, any>()
export const REGISTER_USER_ACTION = createPromiseAction('users: REGISTER_USER_ACTION')<RegisterUserActionPayload, AxiosResponse, any>()
export const VERIFY_USER_HIERARCHY = createPromiseAction('users: VERIFY_USER_HIERARCHY')<VerifyUserHierarchyPayload, VerifyUserHierarchyResponse, any>()