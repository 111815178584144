import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_REGISTRATION_CLOSURE_STATUSES,
  FetchRegistrationClosureStatusesPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'
import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRegistrationClosureStatuses (action: DefaultAction<FetchRegistrationClosureStatusesPayload, string>): any {
  const { payload: {params} } = action

  try {
    const response = yield call(services.fetchRegistrationClosureStatuses, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_REGISTRATION_CLOSURE_STATUSES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_REGISTRATION_CLOSURE_STATUSES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRegistrationClosureStatusSagas () {
  yield takeLatest(FETCH_REGISTRATION_CLOSURE_STATUSES.request, fetchRegistrationClosureStatuses)
}

export default function * registrationClosureStatusSagas () {
  yield all([watchRegistrationClosureStatusSagas()])
}
