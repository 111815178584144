import React from 'react'
import DiscountTypesFormContainer from '../containers/DiscountTypesFormContainer'
import navigationHoc from '../components/hoc/navigationHoc'

const DiscountTypesFormPage = () => {
  return(
    <DiscountTypesFormContainer />
  )
}

export default navigationHoc(DiscountTypesFormPage)