import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const teachersEndpoint = '/teachers'

export const fetchTeachers = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${teachersEndpoint}?${queryParams}`)
}

export const fetchTeacher = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${teachersEndpoint}/${id}?${queryParams}`)
}

export const createTeacher = (data: object) => {
  return http.post(`${teachersEndpoint}`, data)
}

export const updateTeacher = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${teachersEndpoint}/${id}`, data)
}

export const deleteTeacher = ({ id }: { id: string | number } ) => {
  return http.delete(`${teachersEndpoint}/${id}`)
}