import { all, call, takeLatest, put } from 'redux-saga/effects'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

import {
  CREATE_COMPANY,
  FETCH_COMPANIES,
  FETCH_COMPANY_CHILDREN,
  FETCH_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY_LOGO,
  IMPORT_COMPANY_DATA,
  DeleteCompanyPayload,
  CreateCompanyPayload,
  FetchCompaniesPayload,
  FetchCompanyPayload,
  FetchCompaniesChildrenPayload,
  UpdateCompanyPayload,
  ImportCompanyDataPayload,
  EXPORT_COMPANY_REGISTRATIONS,
  EXPORT_COMPANY_REPRESENTATIVES,
  DELETE_COMPANY_EMAIL_HEADER_BANNER,
  EXPORT_INVOICES,
  ExportInvoicesPayload
} from './index'
import { DefaultAction } from '../../utils/constants'
import * as services from './services'
import { updateCredentialsRequest } from '../auth'

export function * createCompany (action: DefaultAction<CreateCompanyPayload, string>): any {
  const { payload: {data} } = action
  const params = data instanceof FormData ? data : {
    data: {
      type: 'companies',
      attributes: {
        ...data
      }
    }
  }
  try {
    const response = yield call(services.createCompany, params)
    const {headers} = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_COMPANY.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_COMPANY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateCompany (action: DefaultAction<UpdateCompanyPayload, string>): any {
  const { payload: {id, data} } = action
  const params = data instanceof FormData ? data : {
    data: {
      id: id,
      type: 'companies',
      attributes: {
        ...data
      }
    }
  }

  try {
    const response = yield call(services.updateCompany, {params, id})
    const {headers} = response
    yield put(updateCredentialsRequest(headers))
    yield put(UPDATE_COMPANY.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_COMPANY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchCompanies (action: DefaultAction<FetchCompaniesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchCompanies, params)
    if (response.status === 200) {
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(FETCH_COMPANIES.success(response))
      resolvePromiseAction(action, response)
    }
  } catch(e) {
    yield put(FETCH_COMPANIES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchCompanyChildren (action: DefaultAction<FetchCompaniesChildrenPayload, string>): any {
  const { payload: {ids} } = action
  try {
    const response = yield all(ids.map((id) => call(services.fetchCompany, { id, params: { filters: { 'include': 'profile_dashboards' } } })))
    if (response) {
      yield put(FETCH_COMPANY_CHILDREN.success(response))
      resolvePromiseAction(action, response)
    }
  } catch(e) {
    yield put(FETCH_COMPANY_CHILDREN.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * fetchCompany (action: DefaultAction<FetchCompanyPayload,string>): any {
  const { payload: {id, params}} = action
  try {
    const response = yield call(services.fetchCompany, { id, params })
    yield put(FETCH_COMPANY.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_COMPANY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteCompanyLogo (action:DefaultAction<DeleteCompanyPayload,string>): any {
  const { payload: {id}} = action
  try {
    const response = yield call(services.deleteCompanyLogo, { id })
    const {headers} = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_COMPANY_LOGO.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_COMPANY_LOGO.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteCompanyEmailHeaderBanner (action:DefaultAction<DeleteCompanyPayload,string>): any {
  const { payload: {id}} = action
  try {
    const response = yield call(services.deleteCompanyEmailHeaderBanner, { id })
    const {headers} = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_COMPANY_EMAIL_HEADER_BANNER.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_COMPANY_EMAIL_HEADER_BANNER.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * importCompanyData (action:DefaultAction<ImportCompanyDataPayload, string>): any {
  const { payload: { id, data } } = action
  try {
    const params = {
      data: {
        type: 'companies',
        attributes: { ...data }
      }
    }
    const response = yield call(services.importCompanyData, { id, data: params })
    yield put(IMPORT_COMPANY_DATA.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(IMPORT_COMPANY_DATA.failure(e))
    rejectPromiseAction(action, e)
  }

}


export function * exportCompanyRegistrations (action:DefaultAction<ImportCompanyDataPayload, string>): any {
  const { payload: { data } } = action
  try {
    const params = {
      data: {
        type: 'companies',
        attributes: { ...data }
      }
    }
    const response = yield call(services.exportCompanyRegistrations, { data: params })
    yield put(EXPORT_COMPANY_REGISTRATIONS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(EXPORT_COMPANY_REGISTRATIONS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * exportCompanyRepresentatives (action:DefaultAction<ImportCompanyDataPayload, string>): any {
  const { payload: { data } } = action
  try {
    const params = {
      data: {
        type: 'companies',
        attributes: { ...data }
      }
    }
    const response = yield call(services.exportCompanyRepresentatives, { data: params })
    yield put(EXPORT_COMPANY_REPRESENTATIVES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(EXPORT_COMPANY_REPRESENTATIVES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * exportInvoices (action:DefaultAction<ExportInvoicesPayload, string>): any {
  const { payload: { data } } = action
  try {
    const params = {
      data: {
        type: 'companies',
        attributes: { ...data }
      }
    }
    const response = yield call(services.exportInvoices, {data: params})
    yield put(EXPORT_INVOICES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(EXPORT_INVOICES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchCreateCompany () {
  yield takeLatest(CREATE_COMPANY.request, createCompany)
  yield takeLatest(DELETE_COMPANY_EMAIL_HEADER_BANNER.request, deleteCompanyEmailHeaderBanner)
  yield takeLatest(DELETE_COMPANY_LOGO.request, deleteCompanyLogo)
  yield takeLatest(EXPORT_COMPANY_REGISTRATIONS.request, exportCompanyRegistrations)
  yield takeLatest(EXPORT_COMPANY_REPRESENTATIVES.request, exportCompanyRepresentatives)
  yield takeLatest(FETCH_COMPANIES.request, fetchCompanies)
  yield takeLatest(FETCH_COMPANY_CHILDREN.request, fetchCompanyChildren)
  yield takeLatest(FETCH_COMPANY.request, fetchCompany)
  yield takeLatest(IMPORT_COMPANY_DATA.request, importCompanyData)
  yield takeLatest(UPDATE_COMPANY.request, updateCompany)
  yield takeLatest(EXPORT_INVOICES.request, exportInvoices)
}

export default function * companySagas () {
  yield all([watchCreateCompany()])
}
