
import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { css } from '@emotion/react'; 

const IconWrapper = css`
    max-width: 1rem;
    max-height: 1rem;
`
const Wrapper = css`
  align-items: center;
  display: flex;
  justify-content: center;
`
const LoadingIndicator = ({message} : { message:string }) => {
  return (
    <div css={Wrapper}>
      <div>
        <CircularProgress css={IconWrapper} />
      </div>
      <span>{message}</span>
    </div>
  )
}

export default LoadingIndicator
