/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { CompanyJson } from '../companies'
import { AxiosResponse } from 'axios'
import { CurriculumSubjectJson } from '../curriculum_subjects'
import { CompositionJson } from '../compositions'
import { CompositionPeriodJson } from '../composition_periods'
import { KtwelveCurriculumJson } from '../ktwelve_curriculums'
import { KtwelveSubjectJson } from '../ktwelve_subjects'

export interface CurriculumAttrs {
  class_time_length: number
  code: string
  company_id: number
  course_id: number
  curriculum_company_id: number
  curriculum_company_name: string
  curriculum_course_code: string
  curriculum_course_name: string
  name: string
  reprovable: string
  year: number
}
export type CurriculumJson = JsonFormat<'curriculums', CurriculumAttrs>

export type FetchCurriculumsResponse = JsonResponseFormat<CurriculumJson[], (CompanyJson)[]>
export type FetchCurriculumResponse = JsonResponseFormat<CurriculumJson, (CompanyJson|CurriculumSubjectJson|CompositionJson|CompositionPeriodJson|KtwelveCurriculumJson|
  KtwelveSubjectJson)[]>
export type FetchCurriculumsPayload = WithRequestParams
export interface FetchCurriculumPayload extends WithRequestParams {
  id: string | number,
}

export type DeleteCurriculumPayload = FetchCurriculumPayload
export interface CreateCurriculumPayload {
  data: object
}

export interface UpdateCurriculumPayload extends CreateCurriculumPayload {
  id: string
}

export const FETCH_CURRICULUMS = createPromiseAction('curriculums: FETCH_CURRICULUMS')<FetchCurriculumsPayload, FetchCurriculumsResponse, any>()
export const FETCH_CURRICULUM  = createPromiseAction('curriculums: FETCH_CURRICULUM')<FetchCurriculumPayload, FetchCurriculumResponse, any>()
export const CREATE_CURRICULUM = createPromiseAction('curriculums: CREATE_CURRICULUM')<CreateCurriculumPayload, FetchCurriculumResponse, any>()
export const UPDATE_CURRICULUM = createPromiseAction('curriculums: UPDATE_CURRICULUM')<UpdateCurriculumPayload, FetchCurriculumResponse, any>()
export const DELETE_CURRICULUM = createPromiseAction('curriculums: DELETE_CURRICULUM')<DeleteCurriculumPayload, AxiosResponse, any>()
