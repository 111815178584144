/* eslint-disable camelcase */
import React from 'react';
import { Field } from 'redux-form';
import { DefaultOptionType, validation, NestedProductItemsAttributes, ProductTypesEnum } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import AutoCompleteComponent from '../input/form/autocomplete';
import { isEmpty } from 'lodash';
import { FETCH_PRODUCTS } from '../../store/products';
import { error } from 'react-notification-system-redux';

const ProductItemForm = (props: {
  classroom_id: string;
  product_id: string;
  current_product_item: NestedProductItemsAttributes;
}) => {
  const { classroom_id, product_id, current_product_item } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [productOptions, setProductOptions] = React.useState<DefaultOptionType[]>([]);

  const fetchProductOptionsMethod = React.useCallback(
    async (value: string) => {
      try {
        setLoading(true);
        const products = await dispatch(
          FETCH_PRODUCTS.request({
            params: {
              filters: {
                'q[name_start]': value,
                'q[classroom_id_eq]': classroom_id,
                'q[kind_not_eq]': ProductTypesEnum.KIT,
                ...(product_id ? { 'q[id_not_eq]': product_id } : {}),
              },
            },
          }),
        );
        if (products) {
          const {
            data: { data },
          } = products;
          const formattedData = data.reduce((acc, { attributes, id }) => {
            return acc.concat({
              label: attributes.name,
              value: id,
            });
          }, [] as DefaultOptionType[]);
          setProductOptions(formattedData);
          setLoading(false);
        }
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de itens',
          }),
        );
      }
    },
    [classroom_id, product_id],
  );

  React.useEffect(() => {
    if (current_product_item.id) {
      setProductOptions([
        {
          label: current_product_item.item_name,
          value: current_product_item.item_id.toString(),
        },
      ]);
    }
  }, []);
  return (
    <Field
      name='item_id'
      label={'Item do combo'}
      placeholder={'Item do combo'}
      component={AutoCompleteComponent}
      autoCompleteProps={{
        disabled: !isEmpty(current_product_item.id),
        filterOptions: (x: any) => x,
        loading,
        getValueOnChange: true,
        fetchOptions: fetchProductOptionsMethod,
      }}
      options={productOptions}
      validate={[validation.required]}
    />
  );
};

export default ProductItemForm;
