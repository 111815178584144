/* eslint-disable camelcase */
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { UserJson } from '../users'
import { ContractJson } from '../contracts'
import { ContractSettingSignerJson } from '../contract_settings'


export interface PendingSignaturesAttrs {
  contract_setting_signer_id: number
  contract_id: number
  status: string
}

export type PendingSignaturesJson = JsonFormat<'pending_signatures', PendingSignaturesAttrs>

export type FetchPendingSignaturesResponse = JsonResponseFormat<PendingSignaturesJson[], (UserJson | ContractJson | ContractSettingSignerJson)[]>

export type FetchPendingSignatureResponse = JsonResponseFormat<PendingSignaturesJson, (UserJson | ContractJson | ContractSettingSignerJson)[]>

export type FetchPendingSignaturesPayload = WithRequestParams

export interface FetchPendingSignaturePayload extends WithRequestParams {
    id: string | number,
  }

export const FETCH_PENDING_SIGNATURES = createPromiseAction('pending_signatures: FETCH_PENDING_SIGNATURES')<FetchPendingSignaturesPayload, FetchPendingSignaturesResponse, any>()
export const FETCH_PENDING_SIGNATURE = createPromiseAction('pending_signatures: FETCH_PENDING_SIGNATURE')<FetchPendingSignaturePayload, FetchPendingSignatureResponse, any>()
