import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const transactionsEndpoint = '/transactions'

export const fetchTransactions = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${transactionsEndpoint}?${queryParams}`)
}

export const createTransaction = (data: object) => {
  return http.post(`${transactionsEndpoint}`, data)
}
 