/* eslint-disable camelcase */
import React from 'react';

import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import {
  colors,
  PaginationType,
  Paths,
  Role,
  defaultPagination,
  CoordinatorAttributes,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import { useHistory } from 'react-router-dom';
import PenIcon from '../../icon/PenIcon';
import InputComponent from '../../input/form/input';
import SelectComponent from '../../input/form/select';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import TrashIcon from '../../icon/TrashIcon';
import { IconModal } from '../../modal/Modal';
import TooltipButton from '../../shared/TooltipButton';
import { RiChatHistoryFill, RiChatHistoryLine, RiFileHistoryLine } from 'react-icons/ri';
import ClassPlanLogTable from '../../table/ClassPlanLogTable';
import ExamsLog from '../../table/ExamsLogTable';
import StudentAbsenceLogTable from '../../table/StudentAbsenceLogTable';

const useStyles = makeStyles(
  (
    theme: Theme,
  ): {
    view: any;
    tableTitle: any;
    button: any;
    filters: any;
    table: any;
  } => ({
    view: {
      width: 'inherit',
      minHeight: '100%',
      height: 'fit-content',
      display: 'flex',
      padding: '2rem',
      flexDirection: 'column',
      flexGrow: '1',
      position: 'relative',
      [theme.breakpoints?.down('lg')]: {
        padding: '2rem 1rem',
      },
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
        overflow: 'hidden',
      },
    },
    tableTitle: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1rem',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      cursor: 'pointer',
      background: colors.green,
      padding: '1rem 4rem',
      border: 'none',
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      gap: '1rem',
      '& > span': {
        marginBottom: '14px',
      },
      '& .inputArea': {
        width: '10rem !important',
        paddingRight: '3rem',
      },
    },
    table: {
      background: colors.lightBlue,
      padding: '2rem',
      borderRadius: '4px',
      display: 'grid',
      '& .subtitle': {
        textAlign: 'start',
        marginBottom: '1rem',
      },
      '& .MuiTableContainer-root': {
        background: 'inherit',
        boxShadow: 'none',
        '& .MuiTableCell-root': {
          borderBottom: `1px solid ${colors.grayBlue}`,
        },

        '& th': {
          color: colors.darkGrayBlue,
        },
        '& td': {
          fontSize: '12px',
          color: colors.darkBlue,
          [theme.breakpoints?.down('lg')]: {
            fontSize: '0.75rem',
          },
          '& svg': {
            cursor: 'pointer',
            margin: '0 0.25rem',
          },
        },
        '& td.MuiTableCell-footer ': {
          borderBottom: 'none',
        },
      },
    },
  }),
);

const CoordinatorsView = ({
  coordinators,
  pagination,
  handleChangePageSize,
  handlePageChange,
  nameFilterValue,
  setNameFilterValue,
  fetchCoordinatorsMethod,
  courseFiltervalue,
  setCourseFilterValue,
  companyFilterValue,
  setCompanyFilterValue,
  deleteCoordinator
}: {
  coordinators: CoordinatorAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  courseFiltervalue: string;
  setCourseFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchCoordinatorsMethod: ({
    newPagination,
    company_filter,
  }: {
    newPagination?: PaginationType | undefined;
    company_filter?: string;
  }) => Promise<void>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
  deleteCoordinator: (id: string, { setLoading, handleClose }: {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleClose: () => void;
  }) => Promise<void>
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [expandedExamLog, setExpandedExamLog] = React.useState<number[]>([]);
  const [expandedPresenceLog, setExpandedPresenceLog] = React.useState<number[]>([]);
  const [expandedClassPlanLog, setExpandedClassPlanLog] = React.useState<number[]>([]);

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });
  
  const columns = [...(is_above_school_director ? ['Unidade'] : []), 'Nome', 'Curso', 'Código do Curso', ''];

  return (
    <div className={classes.view}>
      <div className={classes.tableTitle}>
        <span className='title'>Coordenadores</span>
        <button className='green small' onClick={() => history.push(Paths.COORDINATORS_FORM)}>
          <span>Criar coordenador</span>
        </button>
      </div>
      <div className={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div className={classes.filters}>
          {is_above_school_director && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchCoordinatorsMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchCoordinatorsMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por curso`}
            search
            small
            onSearch={() => {
              fetchCoordinatorsMethod({});
            }}
            input={{
              value: courseFiltervalue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setCourseFilterValue(e.target.value);
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
              {columns.map((item, index) => {
                const align = index === 0 ? 'left' : 'center'
                return (
                  <TableCell align={align} key={item}>{item}</TableCell>
                )
              })}
              </TableRow>
            </TableHead>
            <TableBody>
              {coordinators.map((coordinator) => {
                return (
                  <>
                                    <TableRow key={coordinator.id}>
                    {is_above_school_director && <TableCell align='left'>{coordinator.coordinator_company_name}</TableCell>}
                    <TableCell align={`${is_above_school_director ? 'center' : 'left'}`}>{coordinator.coordinator_name}</TableCell>
                    <TableCell align='center'>{coordinator.course_name}</TableCell>
                    <TableCell align='center'>{coordinator.course_code}</TableCell>
                    <TableCell align='center'>
                    <TooltipButton
                          tooltipProps={{
                            title: expandedExamLog.includes(~~coordinator.id)
                              ? 'Ocultar log de alterações em planos de aula'
                              : 'Ver log de alterações em planos de aula',
                          }}
                          Icon={RiFileHistoryLine}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedClassPlanLog((current) => {
                                if (current.includes(~~coordinator.id)) {
                                  return current.filter((item) => item !== ~~coordinator.id);
                                } else {
                                  return current.concat(~~coordinator.id);
                                }
                              }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: expandedExamLog.includes(~~coordinator.id)
                              ? 'Ocultar log de alterações em notas'
                              : 'Ver log de alterações em notas',
                          }}
                          Icon={RiChatHistoryLine}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedExamLog((current) => {
                                if (current.includes(~~coordinator.id)) {
                                  return current.filter((item) => item !== ~~coordinator.id);
                                } else {
                                  return current.concat(~~coordinator.id);
                                }
                              }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: expandedPresenceLog.includes(~~coordinator.id)
                              ? 'Ocultar log de alterações em faltas'
                              : 'Ver log de alterações em faltas',
                          }}
                          Icon={RiChatHistoryFill}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedPresenceLog((current) => {
                                if (current.includes(~~coordinator.id)) {
                                  return current.filter((item) => item !== ~~coordinator.id);
                                } else {
                                  return current.concat(~~coordinator.id);
                                }
                              }),
                          }}
                        />

                      <Tooltip title='Editar coordenador'>
                        <IconButton
                          onClick={() => {
                            history.push(Paths.COORDINATORS_FORM, {
                              coordinator_id: coordinator.id,
                              company_id: coordinator.coordinator_company_id
                            });
                          }}
                        >
                          <PenIcon />
                        </IconButton>
                      </Tooltip>
                      <IconModal
                        icon={TrashIcon}
                        title={'Excluir coordenador'}
                        tooltipText='Excluir coordenador'
                        onConfirm={(props) => deleteCoordinator(coordinator.id, props)}
                        message='Tem certeza que deseja remover o coordenador?'
                        iconProps={{style:{color: 'red'}}}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedClassPlanLog.includes(~~coordinator.id)} timeout='auto' unmountOnExit>
                      <ClassPlanLogTable user_id={coordinator.user_id.toString()} company_id={coordinator.coordinator_company_id.toString()} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedExamLog.includes(~~coordinator.id)} timeout='auto' unmountOnExit>
                      <ExamsLog user_id={coordinator.user_id.toString()} company_id={coordinator.coordinator_company_id.toString()}/>
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={expandedPresenceLog.includes(~~coordinator.id)} timeout='auto' unmountOnExit>
                      <StudentAbsenceLogTable user_id={coordinator.user_id.toString()} company_id={coordinator.coordinator_company_id.toString()} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                  </>

                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CoordinatorsView;
