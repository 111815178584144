import React from 'react';
import { SvgIcon } from '@mui/material';

const AdminIcon = (props: any) => (
  <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2588_5627)'>
      <path
        d='M23.3116 1H8.54915C5.40731 1 2.86035 3.54696 2.86035 6.6888V25.9833C2.86035 29.1251 5.40731 31.6721 8.54915 31.6721H23.3116C26.4534 31.6721 29.0004 29.1251 29.0004 25.9833V6.6888C29.0004 3.54696 26.4534 1 23.3116 1Z'
        stroke='black'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path d='M8.2627 19.9341H23.594' stroke='black' strokeMiterlimit='10' strokeLinecap='round' />
      <path
        d='M8.2627 11.505H11.1355L12.9844 8.36671C13.1645 8.06331 13.6102 8.06331 13.7808 8.36671L17.0614 14.1883C17.2131 14.4632 17.5923 14.4822 17.7819 14.2357L19.8299 11.505H23.5845'
        stroke='black'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path d='M8.2627 24.5322H23.594' stroke='black' strokeMiterlimit='10' strokeLinecap='round' />
    </g>
    <defs>
      <clipPath id='clip0_2588_5627'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>{' '}
  </SvgIcon>
);

export default AdminIcon;
