import React from 'react'
import { SvgIcon } from "@mui/material"

const PenIcon = (props: any) => (
    <SvgIcon viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M11.4647 11.5885H7.99424C7.9524 11.5887 7.91094 11.5806 7.87223 11.5647C7.83352 11.5488 7.79832 11.5255 7.76865 11.496L0.821996 4.68239C0.561761 4.42763 0.355017 4.12344 0.213866 3.78774C0.0727146 3.45203 0 3.09155 0 2.72737C0 2.3632 0.0727146 2.00272 0.213866 1.66701C0.355017 1.3313 0.561761 1.02712 0.821996 0.77236C1.33617 0.276846 2.02247 0 2.73655 0C3.45063 0 4.13686 0.276846 4.65104 0.77236L11.6555 7.62645C11.7149 7.68883 11.7481 7.77168 11.7481 7.85782L11.7827 11.2589C11.7833 11.3021 11.7748 11.3451 11.7579 11.3849C11.741 11.4247 11.716 11.4606 11.6845 11.4902C11.6562 11.5203 11.6221 11.5445 11.5844 11.5614C11.5467 11.5783 11.506 11.5875 11.4647 11.5885ZM8.11568 10.9118H11.1349V7.97348L4.20565 1.21195C3.81479 0.842609 3.29741 0.636797 2.75964 0.636797C2.22187 0.636797 1.70449 0.842609 1.31363 1.21195C1.11424 1.40599 0.955772 1.63798 0.847556 1.89429C0.73934 2.1506 0.683608 2.426 0.683608 2.70421C0.683608 2.98243 0.73934 3.25783 0.847556 3.51414C0.955772 3.77045 1.11424 4.00251 1.31363 4.19654L8.11568 10.9118Z' />
      <path d='M1.60278 5.1447C1.53434 5.14789 1.46665 5.12967 1.40904 5.09259C1.35143 5.05552 1.30676 5.00137 1.28131 4.93775C1.25587 4.87414 1.2509 4.80424 1.26705 4.73766C1.2832 4.67108 1.31965 4.61118 1.37141 4.5663L4.65099 1.36191C4.71169 1.30151 4.79384 1.26758 4.87947 1.26758C4.9651 1.26758 5.04725 1.30151 5.10795 1.36191C5.16871 1.42359 5.20278 1.50671 5.20278 1.59328C5.20278 1.67986 5.16871 1.76298 5.10795 1.82466L1.83416 5.02898C1.80609 5.06367 1.77091 5.09198 1.731 5.11194C1.69109 5.13189 1.64737 5.14306 1.60278 5.1447Z' />
    </SvgIcon>
  )

export default PenIcon