import { createPromiseAction } from "redux-saga-promise-actions"
import { JsonFormat, JsonResponseFormat, UserAttributes } from "../../utils/constants"

export interface WalletCreditAttrs {
  invoice_id: number
  wallet_id: number
  credit_in: number
  credit_out: number
  description: string
  invoice_code: string
  parent_id: number
  created_at: string
  created_by_user: UserAttributes
}

export type WalletCreditJson = JsonFormat<'wallet_credits', WalletCreditAttrs>
export type FetchWalletCreditResponse = JsonResponseFormat<WalletCreditJson>

export interface CreateWalletCreditPayload {
  data: Partial<WalletCreditAttrs>
}

export const CREATE_WALLET_CREDIT = createPromiseAction('wallet_credits: CREATE_WALLET_CREDIT')<CreateWalletCreditPayload, FetchWalletCreditResponse, any>()
