import React from 'react'
import Radio from '@mui/material/Radio'
import { css } from '@emotion/react'
import { colors } from '../../../utils/constants'

const RadioCss = css`
 & .Mui-disabled svg {
  background: ${colors.grayBlue};
  border-radius: 20px;
 }
`

const RadioComponent = ({ input, meta, ...rest } : any) => {
  return <div css={RadioCss}><Radio id='TEST_ID' data-testid={input.name} {...input} {...rest} checked={input.value === rest.value} /></div>
}

export default RadioComponent
