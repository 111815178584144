import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_COMPOSITION_PERIODS,
  FetchCompositionPeriodsPayload,
  FetchCompositionPeriodPayload,
  FETCH_COMPOSITION_PERIOD,
  CREATE_COMPOSITION_PERIOD,
  CreateCompositionPeriodPayload,
  UpdateCompositionPeriodPayload,
  UPDATE_COMPOSITION_PERIOD,
  DeleteCompositionPeriodPayload,
  DELETE_COMPOSITION_PERIOD
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchCompositionPeriods (action: DefaultAction<FetchCompositionPeriodsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchCompositionPeriods, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_COMPOSITION_PERIODS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_COMPOSITION_PERIODS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchCompositionPeriod (action: DefaultAction<FetchCompositionPeriodPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchCompositionPeriod, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_COMPOSITION_PERIOD.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_COMPOSITION_PERIOD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createCompositionPeriod (action: DefaultAction<CreateCompositionPeriodPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'composition_periods',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createCompositionPeriod, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_COMPOSITION_PERIOD.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_COMPOSITION_PERIOD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateCompositionPeriod (action: DefaultAction<UpdateCompositionPeriodPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'composition_periods',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateCompositionPeriod, {data: params, id})
    yield put(UPDATE_COMPOSITION_PERIOD.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_COMPOSITION_PERIOD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteCompositionPeriod (action: DefaultAction<DeleteCompositionPeriodPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteCompositionPeriod, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_COMPOSITION_PERIOD.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_COMPOSITION_PERIOD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchCompositionPeriodsSagas () {
  yield takeLatest(FETCH_COMPOSITION_PERIODS.request, fetchCompositionPeriods)
  yield takeLatest(FETCH_COMPOSITION_PERIOD.request, fetchCompositionPeriod)
  yield takeLatest(CREATE_COMPOSITION_PERIOD.request, createCompositionPeriod)
  yield takeLatest(UPDATE_COMPOSITION_PERIOD.request, updateCompositionPeriod)
  yield takeLatest(DELETE_COMPOSITION_PERIOD.request, deleteCompositionPeriod)
}

export default function * compositionPeriodsSagas () {
  yield all([watchCompositionPeriodsSagas()])
}
