import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_ROOM_SCHEDULE, CreateRoomSchedulePayload, DELETE_ROOM_SCHEDULE, DeleteRoomSchedulePayload, FETCH_ROOM_SCHEDULE, FETCH_ROOM_SCHEDULES, FetchRoomSchedulePayload, FetchRoomSchedulesPayload, UPDATE_ROOM_SCHEDULE, UpdateRoomSchedulePayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRoomSchedules (action: DefaultAction<FetchRoomSchedulesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchRoomSchedules, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ROOM_SCHEDULES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ROOM_SCHEDULES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchRoomSchedule (action: DefaultAction<FetchRoomSchedulePayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchRoomSchedule, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ROOM_SCHEDULE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ROOM_SCHEDULE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createRoomSchedule (action: DefaultAction<CreateRoomSchedulePayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'room_schedules',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createRoomSchedule, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_ROOM_SCHEDULE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_ROOM_SCHEDULE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateRoomSchedule (action: DefaultAction<UpdateRoomSchedulePayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'room_schedules',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateRoomSchedule, {data: params, id})
    yield put(UPDATE_ROOM_SCHEDULE.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_ROOM_SCHEDULE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteRoomSchedule (action: DefaultAction<DeleteRoomSchedulePayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteRoomSchedule, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_ROOM_SCHEDULE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_ROOM_SCHEDULE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRoomSchedulesSagas () {
  yield takeLatest(FETCH_ROOM_SCHEDULES.request, fetchRoomSchedules)
  yield takeLatest(FETCH_ROOM_SCHEDULE.request, fetchRoomSchedule)
  yield takeLatest(CREATE_ROOM_SCHEDULE.request, createRoomSchedule)
  yield takeLatest(UPDATE_ROOM_SCHEDULE.request, updateRoomSchedule)
  yield takeLatest(DELETE_ROOM_SCHEDULE.request, deleteRoomSchedule)
}

export default function * roomSchedulesSagas () {
  yield all([watchRoomSchedulesSagas()])
}
