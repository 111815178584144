import React from 'react'
import { Spinner } from './styles/Loading.style'

const Loading = () => {
  return (
    <div style={{ display: 'flex', height: '100%', background: 'inherit', minHeight: '10rem', flexGrow: 1 }}>
      <Spinner>Carregando...</Spinner>
    </div>
  )
}

export default Loading
