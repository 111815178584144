import React from 'react'
import { SvgIcon } from "@mui/material"

const FinancialIcon = (props: any) => (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M11.2383 1.4917L15.9197 4.40655C16.0964 4.51897 16.1526 4.75183 16.0402 4.92849L13.1173 9.61794' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
      <path d='M24.1423 7.78708C27.1133 10.2201 29.0003 13.9219 29.0003 18.0573C29.0003 25.3966 23.0502 31.3387 15.7189 31.3387C8.38764 31.3387 2.4375 25.3886 2.4375 18.0573C2.4375 10.726 8.31537 4.84012 15.5904 4.77588' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
      <path d='M19.701 20.8677C19.701 19.1332 18.8579 17.9207 15.8145 17.0294C13.8312 16.4512 13.0844 16.0096 13.0844 14.6445C13.0844 13.5043 13.783 12.5246 15.5897 12.5246C17.5169 12.5246 18.1673 13.4561 18.3761 14.4679H19.3718C19.2433 13.3838 18.5848 11.8662 16.1116 11.6815V10.3164H15.2043V11.6975C13.1566 11.8501 12.0646 13.1349 12.0646 14.7328C12.0646 16.6279 13.4457 17.3988 15.6941 18.009C18.0388 18.6595 18.6892 19.6552 18.6892 20.9319C18.6892 22.5299 17.5329 23.3168 15.7664 23.3168C14.1363 23.3168 13.0844 22.5138 12.715 20.8436H11.7354C11.9602 22.5861 13.0683 23.9351 15.2203 24.1358V25.806H16.1277V24.1519C18.4002 24.0234 19.701 22.7467 19.701 20.8677Z' fill='black'/>
    </SvgIcon>
  )

export default FinancialIcon