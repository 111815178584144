/* eslint-disable camelcase */
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { KtwelveCurriculumAttributes, KtwelveSubjectAttributes, LocationState, TeacherSubjectAttributes } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import TeacherSubjectsForm from '../components/form/TeacherSubjectForm';
import { CREATE_TEACHER_SUBJECT, FETCH_TEACHER_SUBJECT, UPDATE_TEACHER_SUBJECT } from '../store/teacher_subjects';

type TeacherSubjectFormData = Partial<TeacherSubjectAttributes>

const TeacherSubjectsFormContainer = (props: {
  ktwelve_subject: KtwelveSubjectAttributes
  teacher_subject_id?: string
  onSave: () => void;
  close_form?: () => void;
  all_teacher_subject_teacher_ids: number[]
}) => {
  const { ktwelve_subject, close_form, onSave, teacher_subject_id, all_teacher_subject_teacher_ids } = props;
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();

  const [initialValues, setInitialValues] = React.useState<TeacherSubjectFormData | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const loadKtwelveSubject = React.useCallback(async () => {
    if (teacher_subject_id) {
      const ktwelve_subject = await dispatch(
        FETCH_TEACHER_SUBJECT.request({
          id: teacher_subject_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = ktwelve_subject;

      const formattedKtwelveSubject = {
        id,
        ...attributes,
      };
      setInitialValues(formattedKtwelveSubject);
    } else {
      setInitialValues({ ktwelve_subject_id: ~~ktwelve_subject.id } as TeacherSubjectFormData);
    }
  }, [location]);

  const initTeacherSubject = React.useCallback(async () => {
    setLoading(true);
    await loadKtwelveSubject();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: KtwelveCurriculumAttributes) => {
      try {
        if (initialValues?.id) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'ktwelve_curriculum_id',
          ]) as KtwelveCurriculumAttributes;
          await dispatch(
            UPDATE_TEACHER_SUBJECT.request({
              id: initialValues?.id as string,
              data: {
                ...rest,
              },
            }),
          );
        } else {
          await dispatch(
            CREATE_TEACHER_SUBJECT.request({
              data: {
                ...data,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Disciplina inserida na série com sucesso.',
          }),
        );
        onSave();
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao inserir disciplina na série.',
          }),
        );
      }
    },
    [initialValues, history],
  );

  React.useEffect(() => {
    initTeacherSubject();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <TeacherSubjectsForm
      close_form={close_form}
      initialValues={initialValues}
      onSubmit={onSubmit}
      ktwelve_subject={ktwelve_subject}
      all_teacher_subject_teacher_ids={all_teacher_subject_teacher_ids}
    />
  );
};

export default TeacherSubjectsFormContainer;
