/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { ClassTimeSubjectFormAttributes, JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'
import { TeacherClassTimeJson } from '../teacher_class_times'

export interface ClassTimeSubjectAttrs {
  ktwelve_subject_id: number
  class_time_id: number
  subject_name: string
  class_plan: string
  year: number
  room_name: string
  period_name: string
}
export type ClassTimeSubjectJson = JsonFormat<'class_time_subjects', ClassTimeSubjectAttrs>

export type FetchClassTimeSubjectsResponse = JsonResponseFormat<ClassTimeSubjectJson[]>
export type FetchClassTimeSubjectResponse = JsonResponseFormat<ClassTimeSubjectJson, (TeacherClassTimeJson)[]>

export type FetchClassTimeSubjectsPayload = WithRequestParams
export interface FetchClassTimeSubjectPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateClassTimeSubjectPayload {
  data: Partial<ClassTimeSubjectFormAttributes>
}

export interface UpdateClassTimeSubjectPayload extends CreateClassTimeSubjectPayload {
  id: string
}

export interface DeleteClassTimeSubjectPayload {
  id: string
}

export const FETCH_CLASS_TIME_SUBJECTS = createPromiseAction('class_time_subjects: FETCH_CLASS_TIME_SUBJECTS')<FetchClassTimeSubjectsPayload, FetchClassTimeSubjectsResponse, any>()
export const FETCH_CLASS_TIME_SUBJECT = createPromiseAction('class_time_subjects: FETCH_CLASS_TIME_SUBJECT')<FetchClassTimeSubjectPayload, FetchClassTimeSubjectResponse, any>()
export const CREATE_CLASS_TIME_SUBJECT = createPromiseAction('class_time_subjects: CREATE_CLASS_TIME_SUBJECT')<CreateClassTimeSubjectPayload, FetchClassTimeSubjectResponse, any>()
export const UPDATE_CLASS_TIME_SUBJECT = createPromiseAction('class_time_subjects: UPDATE_CLASS_TIME_SUBJECT')<UpdateClassTimeSubjectPayload, FetchClassTimeSubjectResponse, any>()
export const DELETE_CLASS_TIME_SUBJECT = createPromiseAction('class_time_subjects: DELETE_CLASS_TIME_SUBJECT')<DeleteClassTimeSubjectPayload, AxiosResponse, any>()
