import React from 'react'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/system'

import { LoadingBackdrop } from '../../modal/Modal'
import { colors, DefaultOptionType, PaginationType, ContractStatusTypes, PeriodTypes, ProductTypes, PendingSignaturesStatusTypes, Role, OrderOptions, ContractsViewData} from '../../../utils/constants'
import ContractsTable from '../../table/ContractsTable'
import { Tab, Tabs } from '@mui/material'
import TabPanel from '../../shared/TabPanel'
import { evaluate_permissions } from '../../../utils/functions'



const useStyles = makeStyles((theme: Theme): {
  view: any
  tableTitle: any
  button: any
  tabStyle: any
} => ({
  view: {
    width: 'inherit',
    minHeight: '100%',
    height: 'fit-content',
    display: 'flex',
    padding: '2rem',
    flexDirection: 'column',
    flexGrow: '1',
    position: 'relative',
    [theme.breakpoints?.down('lg')]: {
      padding: '2rem 1rem'
    },
    '& .MuiBackdrop-root': {
      position: 'inherit'
    },
    '& .MuiPaper-root': {
      background: 'none',
      boxShadow: 'none',
      overflow: 'hidden'
    }
  },
  tableTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    paddingLeft: '1rem',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
    background: colors.green,
    padding: '1rem 4rem',
    border: 'none'
  },
  tabStyle: {
    padding: '1rem 1rem 0 1rem',
    '& .Mui-selected': {
        background: '#FDFEFF',
        borderRadius: '1rem 1rem 0 0',
    },
  },
}))


const ContractsView = ({
  data,
  renderReady,
  searchForOptions,
  filterOption,
  setFilterOption,
  defaultPagination,
  pagination,
  fetchContractsMethod,
  filterValue,
  setFilterValue,
  handlePageChange,
  handleChangePageSize,
  statusFilterValue,
  setStatusFilterValue,
  ktwelveFilterValue,
  setKtwelveFilterValue,
  classroomFilterValue,
  setClassroomFilterValue,
  periodFilterValue,
  setPeriodFilterValue,
  yearFilterValue,
  setYearFilterValue,
  profile,
  productTypeFilterValue,
  setProductTypeFilterValue,
  tab,
  handleTabChange,
  pendingSignatureStatusFilterValue,
  setPendingSignatureStatusFilterValue,
  contractNumberFilterValue,
  setContractNumberFilterValue,
  setCompanyFilterValue,
  companyFilterValue,
  order,
  orderBy,
  setOrder,
  setOrderBy
} : {
  data: ContractsViewData
  renderReady: boolean
  searchForOptions: DefaultOptionType[]
  filterOption: string
  setFilterOption: React.Dispatch<React.SetStateAction<string>>
  defaultPagination: PaginationType
  pagination: PaginationType,
  fetchContractsMethod: ({ newPagination, status }: {
    newPagination?: PaginationType,
    status?: ContractStatusTypes | string,
    period?: PeriodTypes | string,
    year?: Date | null
    productType?: ProductTypes | string
  }) => void
  filterValue: string
  setFilterValue: React.Dispatch<React.SetStateAction<string>>
  handlePageChange: (_: any, newPage: number) => void
  handleChangePageSize: (e: any) => void
  ktwelveFilterValue: string
  setKtwelveFilterValue: React.Dispatch<React.SetStateAction<string>>
  classroomFilterValue: string
  setClassroomFilterValue: React.Dispatch<React.SetStateAction<string>>
  periodFilterValue: PeriodTypes | string
  setPeriodFilterValue:React.Dispatch<React.SetStateAction<PeriodTypes | string>>
  statusFilterValue: string
  setStatusFilterValue: React.Dispatch<React.SetStateAction<ContractStatusTypes | string>>
  yearFilterValue: Date | null
  setYearFilterValue: React.Dispatch<React.SetStateAction<Date | null>>
  profile: {
    role: Role
  }
  productTypeFilterValue: ProductTypes | string
  setProductTypeFilterValue: React.Dispatch<React.SetStateAction<ProductTypes | string>>
  tab: 'contracts'| 'signatures'
  handleTabChange: (_: any, newValue: 'contracts' | 'signatures') => void
  pendingSignatureStatusFilterValue: string | PendingSignaturesStatusTypes
  setPendingSignatureStatusFilterValue: React.Dispatch<React.SetStateAction<string | PendingSignaturesStatusTypes>>
  contractNumberFilterValue: string
  setContractNumberFilterValue: React.Dispatch<React.SetStateAction<string>>
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>
  companyFilterValue: string
  order: OrderOptions
  orderBy: string
  setOrder: React.Dispatch<React.SetStateAction<OrderOptions>>
  setOrderBy: React.Dispatch<React.SetStateAction<string>>
}) => {
  const classes = useStyles()
  return (
    <div className={classes.view}>
      <LoadingBackdrop open={!renderReady} />
      <div className={classes.tableTitle}>
        <span className='title'>Contratos</span>
      </div>
      <Tabs className={classes.tabStyle} value={tab} onChange={handleTabChange}>
          <Tab label='Contratos' value={'contracts'} />
          <Tab style={{ display: 'none' }} disabled={!evaluate_permissions.is_school_admin_or_above(profile.role)} label='Minhas Assinaturas Pendentes' value={'signatures'} />
      </Tabs>
      <TabPanel style={{ display: 'inherit', paddingTop: '0' }} value={tab} index={'contracts'}>
        <ContractsTable
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          companyFilterValue={companyFilterValue}
          setCompanyFilterValue={setCompanyFilterValue}
          contractNumberFilterValue={contractNumberFilterValue}
          setContractNumberFilterValue={setContractNumberFilterValue}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          handlePageChange={handlePageChange}
          handleChangePageSize={handleChangePageSize}
          statusFilterValue={statusFilterValue}
          setStatusFilterValue={setStatusFilterValue}
          ktwelveFilterValue={ktwelveFilterValue}
          setKtwelveFilterValue={setKtwelveFilterValue}
          classroomFilterValue={classroomFilterValue}
          setClassroomFilterValue={setClassroomFilterValue}
          periodFilterValue={periodFilterValue}
          setPeriodFilterValue={setPeriodFilterValue}
          yearFilterValue={yearFilterValue}
          setYearFilterValue={setYearFilterValue}
          productTypeFilterValue={productTypeFilterValue}
          setProductTypeFilterValue={setProductTypeFilterValue}
          tab={tab}
          searchForOptions={searchForOptions}
          fetchContractsMethod={fetchContractsMethod}
          data={data}
          filterOption={filterOption}
          setFilterOption={setFilterOption}
          defaultPagination={defaultPagination}
          pagination={pagination}
          pendingSignatureStatusFilterValue={pendingSignatureStatusFilterValue}
          setPendingSignatureStatusFilterValue={setPendingSignatureStatusFilterValue}
        />
      </TabPanel>
      <TabPanel style={{ display: 'inherit', paddingTop: '0' }} value={tab} index={'signatures'}>
        <ContractsTable
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}       
          companyFilterValue={companyFilterValue}
          setCompanyFilterValue={setCompanyFilterValue}        
          contractNumberFilterValue={contractNumberFilterValue}
          setContractNumberFilterValue={setContractNumberFilterValue}        
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          handlePageChange={handlePageChange}
          handleChangePageSize={handleChangePageSize}
          statusFilterValue={statusFilterValue}
          setStatusFilterValue={setStatusFilterValue}
          ktwelveFilterValue={ktwelveFilterValue}
          setKtwelveFilterValue={setKtwelveFilterValue}
          classroomFilterValue={classroomFilterValue}
          setClassroomFilterValue={setClassroomFilterValue}
          periodFilterValue={periodFilterValue}
          setPeriodFilterValue={setPeriodFilterValue}
          yearFilterValue={yearFilterValue}
          setYearFilterValue={setYearFilterValue}
          productTypeFilterValue={productTypeFilterValue}
          setProductTypeFilterValue={setProductTypeFilterValue}
          tab={tab}
          searchForOptions={searchForOptions}
          fetchContractsMethod={fetchContractsMethod}
          data={data}
          filterOption={filterOption}
          setFilterOption={setFilterOption}
          defaultPagination={defaultPagination}
          pagination={pagination}
          pendingSignatureStatusFilterValue={pendingSignatureStatusFilterValue}
          setPendingSignatureStatusFilterValue={setPendingSignatureStatusFilterValue}
        />
      </TabPanel>

    </div>
  )
}

export default ContractsView