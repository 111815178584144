import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  CREATE_INVOICE_ITEM, CreateInvoiceItemPayload,
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'


export function * createInvoice (action: DefaultAction<CreateInvoiceItemPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'invoice_items',
        attributes: { ...data },
      }
    }    
    const response = yield call(services.createInvoice, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_INVOICE_ITEM.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_INVOICE_ITEM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchInvoiceItemsSagas () {
  yield takeLatest(CREATE_INVOICE_ITEM.request, createInvoice)
}

export default function * invoiceItemSagas () {
  yield all([watchInvoiceItemsSagas()])
}
