import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const classTimeSubjectEndpoint = '/class_time_subjects'

export const fetchClassTimeSubjects = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${classTimeSubjectEndpoint}?${queryParams}`)
}

export const fetchClassTimeSubject = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${classTimeSubjectEndpoint}/${id}?${queryParams}`)
}

export const createClassTimeSubject = (data: object) => {
  return http.post(`${classTimeSubjectEndpoint}`, data)
}

export const updateClassTimeSubject = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${classTimeSubjectEndpoint}/${id}`, data)
}

export const deleteClassTimeSubject = ({ id }: { id: string | number } ) => {
  return http.delete(`${classTimeSubjectEndpoint}/${id}`)
}