/* eslint-disable camelcase */
import { DoDisturbAlt } from '@mui/icons-material'
import { filter, isEmpty, isNil, map, some } from 'lodash'
import React from 'react'
import { error, success, warning } from 'react-notification-system-redux'
import { useDispatch, useSelector } from 'react-redux'
import { formValueSelector, isValid } from 'redux-form'
import { RootState } from '../../store/configureStore'
import { CreateRegistrationClosurePayloadData, CREATE_REGISTRATION_CLOSURE, UPDATE_REGISTRATION_CLOSURE } from '../../store/registration_closures'
import { colors, ContractAttributes, BillingAttributes, DefaultOptionType, InvoiceAttributes, InvoiceChargeStatusEnum, InvoiceItemAttributes, InvoiceStatusEnum, onConfirm, RegistrationClosureAttributes, RegistrationProductAttributes } from '../../utils/constants'
import RegistrationClosureForm from '../form/RegistrationClosureForm'
import { IconModal } from '../modal/Modal'
import { FETCH_CONTRACTS } from '../../store/contracts'
import { InvoiceJson } from '../../store/invoices'
import { BillingJson } from '../../store/billings'
import { InvoiceItemJson } from '../../store/invoice_items'
import { FETCH_REGISTRATION_CLOSURE_STATUSES } from '../../store/registration_closure_statuses'
import { FETCH_REGISTRATION_CLOSURE_REASONS } from '../../store/registration_closure_reasons'
import { FETCH_REGISTRATION_PRODUCT } from '../../store/registration_products'

const getRemainingInvoices = (args: {closure_date: Date, refund_registration_fee: boolean, billings: BillingAttributes[], invoices:InvoiceAttributes[], invoice_items: InvoiceItemAttributes[], registration_product: RegistrationProductAttributes}) => {
  const { billings, invoice_items, invoices, registration_product, closure_date, refund_registration_fee } = args
  const product_year = registration_product.year
  const provision_months = billings.map(cb => cb.service_month)
  const months_to_be_canceled = provision_months.filter(pm => pm > 0 && new Date(product_year, pm - 1) > closure_date)
  const provision_months_without_reg_fee = provision_months.filter(pm => pm > 0)
  if(provision_months.includes(0) && refund_registration_fee && new Date(closure_date) < new Date(product_year, Math.min(...provision_months_without_reg_fee) - 1)) {
    months_to_be_canceled.unshift(0)
  }
  if(isEmpty(months_to_be_canceled)) {
    return []
  }
  const cbs_to_be_canceled = billings.filter(cb => months_to_be_canceled.includes(cb.service_month))
  const cb_ids = cbs_to_be_canceled.map(cb => cb.id)
  return invoices.filter(invoice => {
    const ii = invoice_items.filter(invoice_item => invoice_item.invoice_id === ~~invoice.id && cb_ids.includes(invoice_item.billing_id.toString()))
    return invoice.status === InvoiceStatusEnum.FINISHED && [InvoiceChargeStatusEnum.RECEIVED, InvoiceChargeStatusEnum.CONFIRMED].includes(invoice.charge_status as InvoiceChargeStatusEnum) && !isEmpty(ii)
  })
}

const CloseRegistrationProduct= ({
  initialValues,
  fetchRegistrationProducts,
  product_year,
  registration_product,
  company_id
} : {
  initialValues?: RegistrationClosureAttributes
  fetchRegistrationProducts: () => Promise<void>
  product_year: number
  company_id: number
  registration_product: RegistrationProductAttributes
}) => {
  const [invoices, setInvoicesData] = React.useState<InvoiceAttributes[]>([])
  const [invoice_items, setInvoiceItemsData] = React.useState<InvoiceItemAttributes[]>([])
  const [contracts, setContractsData] = React.useState<ContractAttributes[]>([])
  const [billings, setBillingsData] = React.useState<BillingAttributes[]>([])
  const [closureStatusOptions, setClosureStatusOptions] = React.useState<DefaultOptionType[]>([])
  const [closureReasonOptions, setClosureReasonOptions] = React.useState<DefaultOptionType[]>([])
  const formName = 'registrationClosureForm'
  const dispatch = useDispatch()
  const formValues = formValueSelector(formName);
  const state = useSelector((state: RootState) => state)
  const isFormValid = isValid(formName)(state)
  const registration_closure_status_id = formValues(state, 'registration_closure_status_id') as number
  const registration_closure_reason_id = formValues(state, 'registration_closure_reason_id') as number
  const closure_date = formValues(state, 'closure_date') as Date
  const request_date = formValues(state, 'request_date') as Date
  const last_date = formValues(state, 'last_date') as Date
  const room_student_status_id = formValues(state, 'room_student_status_id') as number

  const details = formValues(state, 'details') as string
  const penalty = formValues(state, 'penalty')
  const refund_registration_fee = formValues(state, 'refund_registration_fee')

  const fetchClosureStatusOptions = React.useCallback(async () => {
    try {
      const statuses = await dispatch(
        FETCH_REGISTRATION_CLOSURE_STATUSES.request({
          params: {
            filters: {
              'page[size]': '100',
            }
          }  
        })
      )
      const {
        data: { data }
      } = statuses
      const formattedStatuses = data.map(status => {
        return ({
          value: status.id,
          label: status.attributes.name
        })
      })
      setClosureStatusOptions(formattedStatuses)
    } catch (error) {
      dispatch(
        warning({
          message: 'Erro no carregamento dos status de encerramento de matrícula'
        })
      )
    }
  }, [])

  const fetchClosureReasonOptions = React.useCallback(async () => {
    try {
      const reasons = await dispatch(
        FETCH_REGISTRATION_CLOSURE_REASONS.request({
          params: {
            filters: {
              'page[size]': '100',
            }
          }  
        })
      )
      const {
        data: { data }
      } = reasons
      const formattedReasons = data.map(reason => {
        return ({
          value: reason.id,
          label: reason.attributes.name
        })
      })

      setClosureReasonOptions(formattedReasons)
    } catch (error) {
      dispatch(
        warning({
          message: 'Erro no carregamento das razões de encerramento de matrícula'
        })
      )
    }
  }, [])


  const fetchRegistrationProductContracts = React.useCallback(async () => {
    let contracts = [] as ContractAttributes[]
    let includedInvoices = [] as InvoiceAttributes[]
    let includedBillings = [] as BillingAttributes[]
    let includedInvoiteItems = [] as InvoiceItemAttributes[]
      if(registration_product.invoices_through_product){
        const response = await dispatch(FETCH_REGISTRATION_PRODUCT.request({
          id:  registration_product.id,
          params: {
            filters: {
              'include': [
                'billings.invoice_items.invoice'
              ].join(',')
            }
          }
        }))
        const { data: { included } } = response
        includedInvoices = map(filter(included, incl => incl.type === 'invoices') as InvoiceJson[], item => ({
          id: item.id,
          ...item.attributes
        }))
  
        includedBillings = map(filter(included, incl => incl.type === 'billings') as BillingJson[], item => ({
          id: item.id,
          ...item.attributes
        }))
        includedInvoiteItems = map(filter(included, incl => incl.type === 'invoice_items') as InvoiceItemJson[], item => ({
          id: item.id,
          ...item.attributes
        }))
      } else {
        const response = await dispatch(
          FETCH_CONTRACTS.request({
            params: {
              filters: {
                'q[registration_product_id_eq]': registration_product.id,
                'include': [
                  'billings.invoice_items.invoice'
                ].join(',')
              }
            }
          })
        )
        const { data: { data, included } } = response
        contracts = data.map(item => ({id: item.id, ...item.attributes})) as ContractAttributes[]
        includedInvoices = map(filter(included, incl => incl.type === 'invoices') as InvoiceJson[], item => ({
          id: item.id,
          ...item.attributes
        }))
  
        includedBillings = map(filter(included, incl => incl.type === 'billings' && incl.attributes.billable_type === 'Contract') as BillingJson[], item => ({
          id: item.id,
          ...item.attributes
        }))
        includedInvoiteItems = map(filter(included, incl => incl.type === 'invoice_items') as InvoiceItemJson[], item => ({
          id: item.id,
          ...item.attributes
        }))
      }

      setContractsData(contracts)
      setBillingsData(includedBillings)
      setInvoicesData(includedInvoices)
      setInvoiceItemsData(includedInvoiteItems)
  }, [registration_product.id])
  const initData = async () => {
    await fetchRegistrationProductContracts()
    await fetchClosureReasonOptions()
    await fetchClosureStatusOptions()
  }
  const createRegistrationClosureMethod = React.useCallback(async (data: CreateRegistrationClosurePayloadData) => {
    try {
      await dispatch(
        CREATE_REGISTRATION_CLOSURE.request({data})
      )
      dispatch(
        success(
          {
            message: 'Produto cancelado com sucesso'
          }
        )
      )
      await fetchRegistrationProducts()
    } catch (err) {
      dispatch(error({
        message: 'Erro ao cancelar produto'
      }))
    }
  }, [
    fetchRegistrationProducts,
    closure_date,
    details,
    registration_closure_reason_id,
    registration_closure_status_id,
    room_student_status_id,
    last_date,
    request_date,
    registration_product.id,
    penalty,
    refund_registration_fee
  ])

  const updateRegistrationClosureMethod = React.useCallback(async (data: CreateRegistrationClosurePayloadData) => {
    try {
      if(initialValues?.id){
        await dispatch(
          UPDATE_REGISTRATION_CLOSURE.request({data, id: initialValues.id})
        )
        dispatch(
          success(
            {
              message: 'Cancelamento do produto atualizado com sucesso'
            }
          )
        )
        await fetchRegistrationProducts()
      }
    } catch (err) {
      dispatch(error({
        message: 'Erro ao atualizar cancelamento do produto'
      }))
    }
  },[
    initialValues,
    fetchRegistrationProducts,
    closure_date,
    details,
    registration_closure_reason_id,
    registration_closure_status_id,
    room_student_status_id,
    request_date,
    last_date,
    registration_product.id
  ])


  const onConfirm: onConfirm = React.useCallback(async ({ setLoading, handleClose }) => {
    
    const data = {
      registration_product_id: registration_product.id,
      registration_closure_status_id,
      registration_closure_reason_id,
      room_student_status_id,
      last_date: last_date instanceof Date ? last_date.toISOString() : last_date,
      request_date: request_date instanceof Date ? request_date.toISOString() : request_date,
      closure_date: closure_date instanceof Date ? closure_date.toISOString() : closure_date,
      details: details,
      penalty,
      refund_registration_fee
    }
    setLoading(true)
    if(initialValues?.id){
      await updateRegistrationClosureMethod(data)
    } else {
      await createRegistrationClosureMethod(data)
    }
    setLoading(false)
    handleClose()
  }, [
    initialValues,
    updateRegistrationClosureMethod,
    createRegistrationClosureMethod,
    closure_date,
    details,
    registration_closure_reason_id,
    registration_closure_status_id,
    registration_product.id,
    request_date,
    last_date,
    room_student_status_id,
    penalty,
    refund_registration_fee
  ])
  const disabled = some([registration_closure_reason_id, registration_closure_status_id, closure_date, details], item => isNil(item) || !item) || !isFormValid

  const isEditing = initialValues?.id
  const tooltipText = isEditing ? 'Alterar dados de cancelamento' : 'Cancelar produto'
  const remaining_invoices = (isEmpty(invoices) || !closure_date) ? [] : registration_product.invoices_through_product ? getRemainingInvoices({
    refund_registration_fee,
    registration_product,
    invoice_items,
    invoices,
    billings,
    closure_date
  }) : contracts.reduce((acc, contract) => {
    const cbs = billings.filter((cb) => {
      return cb.billable_id === ~~contract.id && cb.billable_type === 'Contract'
    })
    const result = getRemainingInvoices({
      billings: cbs,
      refund_registration_fee,
      registration_product,
      closure_date,
      invoice_items,
      invoices
    })
    return acc.concat(result)
  }, [] as InvoiceAttributes[])
  const provision_months = registration_product.provision_months
  const is_closed = initialValues?.id && initialValues.closure_date && new Date(initialValues.closure_date) <= new Date() && !registration_product.active
  const date_for_comparison = new Date(product_year, Math.min(...provision_months.filter(pm => pm > 0)) - 1, 1)

  const disable_display_refund_registration_fee = !provision_months.includes(0) || !closure_date || !(closure_date && new Date(closure_date) < date_for_comparison)
  const closureDatePickerProps = {
    showDropdownIcon: true,
    ...(is_closed ? {
      maxDate: new Date(initialValues.closure_date)
    } : {})
  }
  return(
        <IconModal
          icon={DoDisturbAlt}
          iconProps={{style:{color: colors.darkBlue}}}
          disableConfirm={disabled}
          onConfirm={onConfirm}
          title='Cancelamento de produto'
          tooltipText={tooltipText}
          confirmButtonText='Salvar'
          onOpen={initData}
        >
          <RegistrationClosureForm
            disable_display_refund_registration_fee={disable_display_refund_registration_fee}
            closureReasonOptions={filter(closureReasonOptions, option => option.label !== 'Contrato Finalizado')}
            closureStatusOptions={filter(closureStatusOptions, option => option.label !== 'Concluído')}
            initialValues={initialValues}
            closure_date={closure_date}
            closureDatePickerProps={closureDatePickerProps}
            company_id={company_id}
          />
          {
            !isEmpty(remaining_invoices) && (
              <div style={{ display: 'grid', width:'100%', gap: '10%' }}>
                <span className='subtitle-two'>Faturas a serem estornadas</span>
                {remaining_invoices.map(invoice => <span key={invoice.code}>{invoice.code}</span>)}
              </div>
            )
          }
        </IconModal>
  )
}

export default CloseRegistrationProduct