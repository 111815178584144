import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_KTWELVE_CURRICULUMS,
  FetchKtwelveCurriculumsPayload,
  FetchKtwelveCurriculumPayload,
  FETCH_KTWELVE_CURRICULUM,
  CREATE_KTWELVE_CURRICULUM,
  CreateKtwelveCurriculumPayload,
  UpdateKtwelveCurriculumPayload,
  UPDATE_KTWELVE_CURRICULUM,
  DeleteKtwelveCurriculumPayload,
  DELETE_KTWELVE_CURRICULUM
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchKtwelveCurriculums (action: DefaultAction<FetchKtwelveCurriculumsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchKtwelveCurriculums, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_KTWELVE_CURRICULUMS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_KTWELVE_CURRICULUMS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchKtwelveCurriculum (action: DefaultAction<FetchKtwelveCurriculumPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchKtwelveCurriculum, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_KTWELVE_CURRICULUM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_KTWELVE_CURRICULUM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createKtwelveCurriculum (action: DefaultAction<CreateKtwelveCurriculumPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'ktwelve_curriculums',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createKtwelveCurriculum, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_KTWELVE_CURRICULUM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_KTWELVE_CURRICULUM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateKtwelveCurriculum (action: DefaultAction<UpdateKtwelveCurriculumPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'ktwelve_curriculums',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateKtwelveCurriculum, {data: params, id})
    yield put(UPDATE_KTWELVE_CURRICULUM.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_KTWELVE_CURRICULUM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteKtwelveCurriculum (action: DefaultAction<DeleteKtwelveCurriculumPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteKtwelveCurriculum, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_KTWELVE_CURRICULUM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_KTWELVE_CURRICULUM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchKtwelveCurriculumsSagas () {
  yield takeLatest(FETCH_KTWELVE_CURRICULUMS.request, fetchKtwelveCurriculums)
  yield takeLatest(FETCH_KTWELVE_CURRICULUM.request, fetchKtwelveCurriculum)
  yield takeLatest(CREATE_KTWELVE_CURRICULUM.request, createKtwelveCurriculum)
  yield takeLatest(UPDATE_KTWELVE_CURRICULUM.request, updateKtwelveCurriculum)
  yield takeLatest(DELETE_KTWELVE_CURRICULUM.request, deleteKtwelveCurriculum)
}

export default function * ktwelveCurriculumsSagas () {
  yield all([watchKtwelveCurriculumsSagas()])
}
