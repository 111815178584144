import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'

const productsEndpoint = '/products'
const covenantEndpoint = '/covenants'
const paymentOptionsEndpoint = '/payment_options'
const subsidiesEndpoint = '/subsidies'

export const createProduct = (params: object) => {
  return http.post(`${productsEndpoint}`, params)
}
export const updateProduct = ({ params, id }: { params: object, id: string|number }) => {
  return http.put(`${productsEndpoint}/${id}`, params)
}


export const fetchProducts = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${productsEndpoint}?${queryParams}`)
}

export const fetchProduct = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${productsEndpoint}/${id}?${queryParams}`)
}

export const fetchSubsidies = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${subsidiesEndpoint}?${queryParams}`)
}


export const fetchCovenants = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${covenantEndpoint}?${queryParams}`)
}

export const fetchPaymentOptions = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${paymentOptionsEndpoint}?${queryParams}`)
}
