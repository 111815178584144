/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { CourseJson } from '../courses'
import { AxiosResponse } from 'axios'
import { AddressJson, UserJson } from '../users'
import { AccountJson } from '../accounts'
import { CompanyJson } from '../companies'
import { CityJson } from '../cities'
import { StateJson } from '../states'
import { UserDeficiencyJson } from '../deficiencies'
import { UserAlergyJson } from '../alergies'

export interface CoordinatorAttrs {
  account_id: number
  course_id: number
  coordinator_name: string
  course_name: string
  course_code: string
  coordinator_company_name: string
  coordinator_company_id: number
  user_id: number
}
export type CoordinatorJson = JsonFormat<'coordinators', CoordinatorAttrs>

export type FetchCoordinatorsResponse = JsonResponseFormat<CoordinatorJson[], (CourseJson)[]>
export type FetchCoordinatorResponse = JsonResponseFormat<CoordinatorJson, (CourseJson|
  UserJson|AccountJson|CompanyJson|AddressJson|CityJson|StateJson|
  UserAlergyJson|UserDeficiencyJson|CoordinatorJson)[]>

export type FetchCoordinatorsPayload = WithRequestParams
export interface FetchCoordinatorPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateCoordinatorPayload {
  data: Partial<CoordinatorAttrs>
}

export interface UpdateCoordinatorPayload extends CreateCoordinatorPayload {
  id: string
}

export interface DeleteCoordinatorPayload {
  id: string
}

export const FETCH_COORDINATORS= createPromiseAction('coordenators: FETCH_COORDINATORS')<FetchCoordinatorsPayload, FetchCoordinatorsResponse, any>()
export const FETCH_COORDINATOR = createPromiseAction('coordenators: FETCH_COORDINATOR')<FetchCoordinatorPayload, FetchCoordinatorResponse, any>()
export const CREATE_COORDINATOR= createPromiseAction('coordenators: CREATE_COORDINATOR')<CreateCoordinatorPayload, FetchCoordinatorResponse, any>()
export const UPDATE_COORDINATOR= createPromiseAction('coordenators: UPDATE_COORDINATOR')<UpdateCoordinatorPayload, FetchCoordinatorResponse, any>()
export const DELETE_COORDINATOR = createPromiseAction('coordenators: DELETE_COORDINATOR')<DeleteCoordinatorPayload, AxiosResponse, any>()
