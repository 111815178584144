import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_COUNTRIES,
  FETCH_COUNTRY,
  FetchCountriesPayload,
  FetchCountryPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchCountries (action: DefaultAction<FetchCountriesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchCountries, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_COUNTRIES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_COUNTRIES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchCountry (action: DefaultAction<FetchCountryPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchCountry, { params, id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_COUNTRY.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(FETCH_COUNTRY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchCountriesSagas () {
  yield takeLatest(FETCH_COUNTRIES.request, fetchCountries)
  yield takeLatest(FETCH_COUNTRY.request, fetchCountry)
}

export default function * countrySagas () {
  yield all([watchCountriesSagas()])
}
