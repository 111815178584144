/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface AbsenceJustificationAttrs {
  company_id: number
  name: string
  creditable: boolean
}
export type AbsenceJustificationJson = JsonFormat<'absence_justifications', AbsenceJustificationAttrs>

export type FetchAbsenceJustificationsResponse = JsonResponseFormat<AbsenceJustificationJson[]>
export type FetchAbsenceJustificationResponse = JsonResponseFormat<AbsenceJustificationJson>

export type FetchAbsenceJustificationsPayload = WithRequestParams
export interface FetchAbsenceJustificationPayload extends WithRequestParams {
  id: string | number,
}


export const FETCH_ABSENCE_JUSTIFICATIONS = createPromiseAction('absence_justifications: FETCH_ABSENCE_JUSTIFICATIONS')<FetchAbsenceJustificationsPayload, FetchAbsenceJustificationsResponse, any>()
export const FETCH_ABSENCE_JUSTIFICATION = createPromiseAction('absence_justifications: FETCH_ABSENCE_JUSTIFICATION')<FetchAbsenceJustificationPayload, FetchAbsenceJustificationResponse, any>()
