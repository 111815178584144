import React from 'react'
import { makeStyles, DefaultTheme } from '@mui/styles'
import IconButton from '@mui/material/IconButton'

import { colors } from '../../../utils/constants'
import SearchIcon from '../../icon/SearchIcon'

type Props = {
  disabled: boolean,
  small: boolean,
  search: boolean
}

const useStyles = makeStyles<DefaultTheme, Props>(() => ({
  inputArea: {
    display: 'grid',
    position: 'relative',
    '& span': {
      justifySelf: 'flex-start',
      color: colors.darkGrayBlue,
      fontSize: '0.8rem'
    },
    '& span.error': {
      color: colors.lightRed
    },
    '& span.error:empty:before': {
      content: '"\\200b"'
    },
    '& .search': {
      color: colors.darkGrayBlue,
      position: 'absolute',
      right: 0,
    },
    '& div': {
      display: 'grid',
      background: (props) => (props.disabled ? colors.lightBlue : 'white'),
      justifyItems: 'flex-start',
      cursor: 'text',
      borderRadius: '4px',
      border: `1px solid ${colors.grayBlue}`,
      padding: props => props.small ? '0rem 0.75rem' : '0.5rem 0.75rem',
      paddingRight: props => !!props.search && '2rem',
      width: 'inherit',
      height: '2.5rem',
      '& label': {
        color: colors.darkGrayBlue,
        fontSize: '10px'
      },
      '& input': {
        border: 'none',
        outline: 'none',
        padding: '0.25rem',
        width: '100%'
      },
      '& ::placeholder': {
        color: colors.grayBlue,
        fontSize: '16px'
      },
      '& span': {
        justifySelf: 'flex-start'
      }
    }
  }
}))
const InputComponent = (props: any) => {
  const { label, input, meta, search, small, onSearch, onlyNumbers, upperCase, ...rest } = props
  const inputRef = React.useRef<HTMLInputElement>()
  const classes = useStyles({
    disabled: input?.disabled || rest?.disabled,
    small,
    search
  })
  return (
    <div className={classes.inputArea} id={`input-${input.name}`}>
      <div className='inputArea' onClick={() => inputRef?.current?.focus()}>
        {!!label && <label> {label} </label>}
        <input data-testid={input.name}
          onKeyDown={(e) => {
            if(search && e.key === 'Enter'){
              onSearch()
            }
          }}
          autoComplete='new-password'
          ref={inputRef}
          {...input}
          {...rest}
          onChange={(e: { target: HTMLInputElement }) => {
            if(upperCase){
              e.target.value = e.target.value.toUpperCase()
            }
            if (onlyNumbers) {
              e.target.value = e.target.value?.replace(/[^0-9]/g, '')
            }
            input.onChange(e)
            }}
          />
        {!!search && (
          <IconButton onClick={onSearch} className='search'>
            <SearchIcon />
          </IconButton>
        )}
      </div>
      <span className='error'>{meta && meta.touched && meta.error && meta.error}</span>
    </div>
  )
}

export default InputComponent
