/* eslint-disable camelcase */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SelectComponent from './form/select'

import { setProfile, SET_COMPANY } from '../../store/auth'

import {  CompanyOption, DefaultOptionType, profiles, RoleTypes } from '../../utils/constants'
import { RootState } from '../../store/configureStore'
import { isEmpty, sortBy } from 'lodash'

interface ProfileOptionType extends DefaultOptionType {
  account_id: string
  role: RoleTypes
}

const SelectProfile = () => {
  const {
    auth: { profile, company },
    account: { companies }
  } = useSelector((state: RootState) => state)
  const companyOptions = companies.filter((item: any) => {
    const nonStudentProfiles = item.profiles.filter((item: any) => {
      return item.role !== 'student'
    })
    return nonStudentProfiles.length > 0
  }).map((item: { id: string, name: string }) => ({
    value: item.id,
    label: item.name
  }))

  const profileOptions = companies?.find(
    (item: any) => item.id === company
    )?.profiles.filter(
      (item: any) => item.role !== 'student'
    ).map(
        (item: any) => ({ value: item.id, account_id: item.account_id, label: profiles?.find(x => x.id === item.role)?.label, role: item.role })
      ) as ProfileOptionType[]
  const dispatch = useDispatch()
  
  return (
    <>
      <span> Escolha a escola/instituição: </span>
      <SelectComponent
        options={sortBy(companyOptions, (x: CompanyOption) => x.label.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))}
        small
        input={{
          value: company,
          onChange: async (e: any) => {
            const current_company = companies?.find((item: any) => item.id === e.target.value)
            const newProfileValue = current_company?.profiles.find((item: any) => profiles.filter(item => !isEmpty(item.default_route)).map(item => item.id).includes(item.role))
            const profile = {id: newProfileValue['id'], account_id: newProfileValue['account_id'], label: newProfileValue['label'], role: newProfileValue['role']}
            await dispatch(SET_COMPANY.request({company: current_company, profile }))
          }
        }}
      />
      <span> Escolha o perfil: </span>
      <SelectComponent
        options={profileOptions}
        small
        input={{
          value: profile.id,
          onChange: (e: any) => {
            const profileOption = profileOptions.find(option => option.value === e.target.value)
            dispatch(setProfile({ id: e.target.value, account_id: profileOption?.account_id as string, label: profileOption?.label as string, role: profileOption?.role as RoleTypes }))
          }
        }}
      />
    </>
  )
}

export default SelectProfile
