import React from 'react';

import { makeStyles, DefaultTheme } from '@mui/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { colors, DefaultOptionType } from '../../../utils/constants';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { isEmpty } from 'lodash';

type Props = {
  disabled: boolean;
  small: boolean;
};

const useStyles = makeStyles<DefaultTheme, Props>(() => ({
  inputArea: {
    display: 'grid',
    position: 'relative',
    '& span': {
      justifySelf: 'flex-start',
      color: colors.darkGrayBlue,
      fontSize: '0.8rem',
    },
    '& span.error': {
      color: colors.lightRed,
    },
    '& span.error:empty:before': {
      content: '"\\200b"',
    },
    '& > div.inputArea': {
      display: 'grid',
      background: (props) => (props.disabled ? colors.lightBlue : 'white'),
      justifyItems: 'flex-start',
      cursor: 'pointer',
      borderRadius: '4px',
      border: `1px solid ${colors.grayBlue}`,
      padding: (props) => (props.small ? '0rem 0.75rem' : '0.5rem 0.75rem'),
      position: 'relative',
      height: '2.5rem',
      '& label': {
        color: colors.darkGrayBlue,
        fontSize: '10px',
        position: 'relative',
        zIndex: 3,
        height: 'fit-content',
        pointerEvents: 'none',
      },
      '& .select': {
        width: '100%',
        border: 'none',
        background: 'inherit',
        outline: 'none',
        position: 'absolute',
        height: '100%',
        cursor: 'inherit',
        zIndex: '2',
        '& .MuiSelect-select': {
          color: colors.darkBlue,
          textAlign: 'start',
          'padding-top': '1.5rem',
          'padding-bottom': '0.65rem',
        },
        '& fieldset': {
          border: 'none',
        },
        '& .selectedValues': {
          '& > span': {
            border: '1px solid #31314F',
            margin: '0 0.25rem',
            padding: '0 0.25rem',
            fontSize: '12px',
          },
        },
      },
      '& svg': {
        position: 'absolute',
        right: '17px',
        top: '25%',
        cursor: 'inherit',
        zIndex: '1',
      },
      '& ::placeholder': {
        color: colors.grayBlue,
        fontSize: '16px',
      },
      '& span': {
        justifySelf: 'flex-start',
        fontSize: '16px',
        color: colors.darkBlue,
      },
      '& .placeholder': {
        color: colors.grayBlue,
        fontSize: '16px',
        zIndex: 3,
        pointerEvents: 'none',
        margin: 'auto 0',
      },
    },
  },
}));

const SelectMultipleComponent = (props: any) => {
  const { label, options, placeholder, input, meta, small, allow_select_all = false, ...rest } = props;

  const classes = useStyles({
    disabled: input.disabled || rest.disabled,
    small,
  });
  const { value, ...restInput } = input;
  const limitSelection = rest?.limitSelection;
  let disabledBySelection: any[] = [];
  if (limitSelection && !isEmpty(input.value)) {
    disabledBySelection = options
      .filter((option: any) => !input.value.includes(option.value))
      .map((option: any) => option.value);
  }
  const isAllSelected = options.length > 0 && input.value.length === options.length;
  return (
    <div className={classes.inputArea} id={`input-${input.name}`}>
      <div className='inputArea'>
        {label && <label> {label} </label>}
        {(input.value === '' || (Array.isArray(input.value) && input.value.length === 0)) && (
          <span className='placeholder'> {placeholder} </span>
        )}
        <Select
          data-testid={input.name}
          multiple
          className='select'
          value={Array.isArray(input.value) ? input.value : []}
          renderValue={() => {
            return (
              <div className='selectedValues'>
                {input.value &&
                  Array.isArray(input.value) &&
                  input.value.map((item: any) => {
                    const label = options.find((option: DefaultOptionType) => option.value === item).label;
                    return <span key={item}> {label} </span>;
                  })}
              </div>
            );
          }}
          {...restInput}
          {...rest}
          {...(allow_select_all && {
            onChange: (e) => {
              const value = e?.target?.value as unknown as string[];
              if (value && value[value.length - 1] === 'all') {
                e.target.value = input.value.length === options.length ? [] : options.map((item: DefaultOptionType) => item.value);
              }
              input.onChange(e);
            },
          })}
        >
          {allow_select_all && (
            <MenuItem
              value='all'
            >
              <Checkbox
                checked={isAllSelected}
                indeterminate={input.value.length > 0 && input.value.length < options.length}
              />
              <ListItemText primary='Selecionar todos' />
            </MenuItem>
          )}
          {options.map((option: DefaultOptionType) => (
            <MenuItem
              disabled={rest?.disabledValues?.includes(option.value) || disabledBySelection.includes(option.value)}
              key={option.label}
              value={option.value}
            >
              <Checkbox
                disabled={rest?.disabledValues?.includes(option.value) || disabledBySelection.includes(option.value)}
                checked={input?.value?.includes(option.value)}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <span className='error'>{meta && meta.touched && meta.error && meta.error}</span>
    </div>
  );
};

export default SelectMultipleComponent;
