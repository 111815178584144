import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const discountTypesEndpoint = '/discount_types'


export const fetchDiscountTypes = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${discountTypesEndpoint}?${queryParams}`)
}

export const fetchDiscountType = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${discountTypesEndpoint}/${id}?${queryParams}`)
}

export const createDiscountType = (data: object) => {
  return http.post(`${discountTypesEndpoint}`, data)
}

export const updateDiscountType = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${discountTypesEndpoint}/${id}`, data)
}