import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const documentsEndpoint = '/documents'


export const fetchDocuments = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${documentsEndpoint}?${queryParams}`)
}
