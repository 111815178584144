import { css } from '@emotion/react';
import React from 'react';
import {
  AuditActionEnum,
  auditActionOptions,
  AuditAttributes,
  colors,
  defaultPagination,
  PaginationType,
  RoomStudentAttributes,
} from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { FETCH_AUDITS } from '../../store/audits';
import { find, head, isEmpty, keys, last, map } from 'lodash';
import { RoomStudentJson } from '../../store/room_students';
import { error } from 'react-notification-system-redux';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import TooltipButton from '../shared/TooltipButton';
import { Visibility } from '@mui/icons-material';
import TablePaginationActions from '../shared/TablePaginationActions';

const TableCss = css`
  margin-bottom: 15%;
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;
interface AuditStudentAbsenceLog extends AuditAttributes {
  room_student: RoomStudentAttributes;
}
const StudentAbsenceLogTable = (props: { user_id: string; company_id: string }) => {
  const dispatch = useDispatch();
  const [audits, setAudits] = React.useState<AuditStudentAbsenceLog[]>([]);
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [expandedChanges, setExpandedChanges] = React.useState<number[]>([]);

  const fetchAudits = React.useCallback(
    async ({ newPagination = pagination }: { newPagination?: PaginationType }) => {
      const { user_id, company_id } = props;
      try {
        const audits_response = await dispatch(
          FETCH_AUDITS.request({
            params: {
              filters: {
                'q[user_id_eq]': user_id,
                'q[auditable_type_eq]': 'StudentAbsence',
                'q[associated_type_eq]': 'RoomStudent',
                'q[associated_of_RoomStudent_type_company_id_eq]': company_id,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                include: 'associated',
              },
            },
          }),
        );
        const {
          data: { data, included, meta },
        } = audits_response;
        const formatted_data = data.map((item) => {
          const included_exam = find(
            included,
            (incl) => ~~incl.id === item.attributes.associated_id && item.attributes.associated_type === 'RoomStudent',
          ) as RoomStudentJson;
          return {
            id: item.id,
            ...item.attributes,
            room_student: {
              id: included_exam.id,
              ...included_exam.attributes,
            },
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setAudits(formatted_data);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar logs',
          }),
        );
      }
    },
    [pagination],
  );
  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchAudits({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchAudits({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const init = async () => {
    await fetchAudits({});
  };

  React.useEffect(() => {
    init();
  }, []);
  const columns = [
    'Tipo',
    'Ano Letivo',
    'Período',
    'Disciplina',
    'Horário da aula',
    'Turma',
    'Aluno',
    'Data/Horário',
    '',
  ];
  return (
    <Table css={TableCss} size='small' aria-label='exam_audits'>
      <TableHead>
        <TableRow>
          {columns.map((item) => (
            <TableCell align='center' key={item}>
              {item}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {audits.map((audit) => {
          const { room_student } = audit;
          const actionLabel = auditActionOptions.find((item) => item.value === audit.action)?.label || '';
          const splitted_comment = (audit.comment || '').split('//');
          const period = splitted_comment[0];
          const subject = splitted_comment[1];
          const date = splitted_comment[2];
          return (
            <React.Fragment key={audit.id}>
              <TableRow>
                <TableCell align='center'>{actionLabel}</TableCell>
                <TableCell align='center'>{room_student.year}</TableCell>
                <TableCell align='center'>{period}</TableCell>
                <TableCell align='center'>{subject}</TableCell>
                <TableCell align='center'>{date}</TableCell>
                <TableCell align='center'>{room_student.room_name}</TableCell>
                <TableCell align='center'>{room_student.student_name}</TableCell>
                <TableCell align='center'>{format(parseISO(audit.created_at), 'dd-MM-yyyy H:mm')}</TableCell>
                <TableCell align='center'>
                  <TooltipButton
                    tooltipProps={{
                      title: expandedChanges.includes(~~audit.id) ? 'Ocultar mudanças' : 'Ver mudanças',
                    }}
                    Icon={Visibility}
                    iconButtonProps={{
                      style: {
                        display:
                          audit.action === AuditActionEnum.UPDATE && !isEmpty(audit.audited_changes)
                            ? 'table-row'
                            : 'none',
                      },
                      onClick: () =>
                        setExpandedChanges((current) => {
                          if (current.includes(~~audit.id)) {
                            return current.filter((item) => item !== ~~audit.id);
                          } else {
                            return current.concat(~~audit.id);
                          }
                        }),
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow
                style={{
                  display:
                    audit.action === AuditActionEnum.UPDATE && !isEmpty(audit.audited_changes) ? 'table-row' : 'none',
                }}
              >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
                  <Collapse in={expandedChanges.includes(~~audit.id)} timeout='auto' unmountOnExit>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'>
                            <span>Campo</span>
                          </TableCell>
                          <TableCell align='center'>
                            <span>Valor Anterior</span>
                          </TableCell>
                          <TableCell align='center'>
                            <span>Novo Valor</span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {map(keys(audit.audited_changes), (key) => {
                          const changes = audit.audited_changes[key];
                          return (
                            <TableRow key={key}>
                              <TableCell align='center'>
                                <span>{key === 'justification' ? 'Justificativa' : ''}</span>
                              </TableCell>
                              <TableCell align='center'>
                                <span>{head(changes)}</span>
                              </TableCell>
                              <TableCell align='center'>
                                <span>{last(changes)}</span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            colSpan={columns?.length + 1}
            count={pagination.totalCount}
            rowsPerPage={pagination.pageSize}
            page={pagination.pageNumber}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangePageSize}
            labelRowsPerPage='Itens Por página'
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default StudentAbsenceLogTable;
