import { find } from 'lodash';
import React from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import KtwelvesView from '../components/view/Secretary/KtwelvesView';
import { CourseJson } from '../store/courses';
import { FETCH_KTWELVES } from '../store/ktwelves';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { KtwelvesViewAttributes, defaultPagination, PaginationType } from '../utils/constants';

const KtwelvesContainer = () => {
  const dispatch = useDispatch();

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [ktwelves, setKtwelves] = React.useState<KtwelvesViewAttributes[]>([]);
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [courseNameFilterValue, setCourseNameFilterValue] = React.useState('');
  const [descriptionFilterValue, setDescriptionFilterValue] = React.useState('');

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);

  const fetchKtwelvesMethod = React.useCallback(
    async ({ newPagination = pagination }: { newPagination?: PaginationType }) => {
      setLoading(true);
      try {
        const ktwelves = await dispatch(
          FETCH_KTWELVES.request({
            params: {
              filters: {
                'q[name_cont]': nameFilterValue,
                'q[description_cont]': descriptionFilterValue,
                'q[course_name_cont]': courseNameFilterValue,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                include: 'course',
              },
            },
          }),
        );
        const {
          data: { data, meta, included },
        } = ktwelves;
        const formattedKtwelves = data.map((item) => {
          const course = find(included, (incl) => incl.type === 'courses' && ~~incl.id === item.attributes.course_id) as CourseJson;
          return {
            id: item.id,
            ...item.attributes,
            course: course ? { id: course.id, ...course.attributes } : undefined,
          };
        });
        setPagination(() => ({
          ...newPagination,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setKtwelves(formattedKtwelves);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar séries',
          }),
        );
      }
    },
    [pagination, nameFilterValue, descriptionFilterValue, courseNameFilterValue],
  );

  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchKtwelvesMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchKtwelvesMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const loadAssets = React.useCallback(async () => {
    await fetchKtwelvesMethod({});
  }, []);

  React.useEffect(() => {
    loadAssets();
  }, []);

  return (
    <KtwelvesView
      ktwelves={ktwelves}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      descriptionFilterValue={descriptionFilterValue}
      setDescriptionFilterValue={setDescriptionFilterValue}
      nameFilterValue={nameFilterValue}
      setNameFilterValue={setNameFilterValue}
      fetchKtwelvesMethod={fetchKtwelvesMethod}
      courseNameFilterValue={courseNameFilterValue}
      setCourseNameFilterValue={setCourseNameFilterValue}
    />
  );
};

export default KtwelvesContainer;
