/* eslint-disable camelcase */
import { filter, head, isEmpty, sortBy } from 'lodash';
import React from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from '../components/loading/Loading';
import DashboardView from '../components/view/DashboardView';
import { UPDATE_STORED_COMPANIES } from '../store/accounts';
import { FETCH_COMPANY, FETCH_COMPANY_CHILDREN, FetchCompanyResponse } from '../store/companies';
import { RootState } from '../store/configureStore';
import {
  Company,
  DashboardViewData,
  menuOptions,
  Paths,
  ProfileDashboardAttributes,
  ProfileDashboardsDashboardView,
  TransactionEventEnum,
} from '../utils/constants';
import { calculateMenuOptions } from '../utils/functions';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { CREATE_TRANSACTION } from '../store/transactions';

const defaultCompanyDashboardView: DashboardViewData = {
  current: '',
  data: [] as { id: string; items_per_row: number }[],
};

const DashboardContainer = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [type, setType] = React.useState<'powerbi' | 'pdf'>('powerbi');
  const [companyDashboardView, setCompanyDashboardView] =
    React.useState<DashboardViewData>(defaultCompanyDashboardView);
  const [profileDashboards, setProfileDashboards] = React.useState<ProfileDashboardsDashboardView[]>([]);
  const [initialProfileDashboards, setInitialProfileDashboards] = React.useState<ProfileDashboardsDashboardView[]>([]);
  const [iframe, setIframe] = React.useState<string>('');
  const [loadingAssets, setLoadingAssets] = React.useState(false);
  const dispatch = useDispatch();
  const {
    auth: { profile, company, user: { id: user_id } },
    account: { companies },
  } = useSelector((state: RootState) => state);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const currentCompany = companies.find((item: any) => item.id === company);
  const dashboardTitle = companyDashboardView.current
    ? companies.find((item: any) => item.id === companyDashboardView.current)?.name ||
      initialProfileDashboards.find((item) => item.company_id.toString() === companyDashboardView.current)?.name
    : 'Painel Principal';
  const history = useHistory();
  const createUserEvent = async (link: string, name = '') => {
    await dispatch(CREATE_TRANSACTION.request({
      data: {
        event: TransactionEventEnum.USER_EVENT,
        transactable_id: user_id,
        transactable_type: 'User',
        event_date: new Date().toISOString(),
        description: `Usuário acessou link no dashboard. ${name ? `Nome: ${name}` : ''}; Link: ${link}`
      }
    }))
  }

  const handleSetIframe = async (url: string, name = '') => {
    if (url) {
      setLoading(true);
      await createUserEvent(url, name)
      setIframe(url);
      setTimeout(() => {
        setLoading(false);
      }, 8000);
    }
  };

  const loadAssets = React.useCallback(async () => {
    try {
      setLoadingAssets(true);
      const response = await dispatch(
        FETCH_COMPANY.request({
          id: company,
          params: {
            filters: {
              include: 'profile_dashboards',
            },
          },
        }),
      );
      const {
        data: {
          data: { attributes },
          included,
        },
      } = response;
      const profile_dashboards = filter(
        included,
        (incl) => incl.type === 'profile_dashboards' && incl.attributes.role === profile.role,
      ).map((profDash) => {
        return {
          id: profDash.id,
          ...profDash.attributes,
        };
      });
      const adjustedCompanies = companies.map((item: any) => {
        if (item.id === company) {
          return { ...item, ...attributes, profile_dashboards };
        }
        return item;
      });
      const formattedCurrentProfileDashboards = profile_dashboards.map((item) => {
        return {
          ...item,
          handleSelect: async () => handleSetIframe(item.url, item?.name || '')
        };
      });
      const missingCompanyInfo = currentCompany.descendants_ids.filter(
        (item: any) => !adjustedCompanies.map((co: any) => ~~co.id).includes(item),
      );
      const missing = (await dispatch(
        FETCH_COMPANY_CHILDREN.request({ ids: missingCompanyInfo }),
      )) as FetchCompanyResponse[];
      const formatMissing = missing
        .filter((item) => {
          return (
            item.data.included && item.data.included.filter((incl) => incl.type === 'profile_dashboards').length > 0
          );
        })
        .map((item) => {
          return {
            id: item.data.data.id,
            ...item.data.data.attributes,
            profile_dashboards: item.data.included
              .filter((incl) => incl.type === 'profile_dashboards')
              .map((item) => {
                return {
                  id: item.id,
                  ...item.attributes,
                };
              }),
          };
        });
      const childrenCompanies = adjustedCompanies
        .concat(formatMissing)
        .filter((item: any) => currentCompany.child_ids.includes(~~item.id));
      const childrenCompanyDashboardProfiles = childrenCompanies
        .filter((item: any) => item.profile_dashboards && item.profile_dashboards.length > 0)
        .map((item: any) => ({
          company_id: item.id,
          name: item.name,
          dashboard_logo_url: item.logo_url,
          dashboard_logo_attachment_id: item.logo_attachment_id,
          handleSelect: () => {
            setCompanyDashboardView((current_value) => ({ current: item.id, data: current_value.data }));
            setProfileDashboards(
              item.profile_dashboards.map((item: ProfileDashboardAttributes) => ({
                ...item,
                handleSelect: async () => handleSetIframe(item.url, item?.name || '')
              })),
            );
          },
        }));

      const formattedProfileDashboards = formattedCurrentProfileDashboards.concat(childrenCompanyDashboardProfiles);
      dispatch(UPDATE_STORED_COMPANIES({ companies: adjustedCompanies }));
      setProfileDashboards(formattedProfileDashboards);
      if (isEmpty(initialProfileDashboards)) {
        setInitialProfileDashboards(formattedProfileDashboards);
      }
      setCompanyDashboardView({
        current: company,
        data: formattedProfileDashboards.reduce(
          (acc, item) => {
            if (!acc.find((this_item) => this_item.id === item.company_id.toString())) {
              return acc.concat({ id: item.company_id.toString(), items_per_row: 4 });
            }
            return acc;
          },
          [{ id: company, items_per_row: 4 }] as { items_per_row: number; id: string }[],
        ),
      });
      setLoadingAssets(false);
    } catch (err) {
      setLoadingAssets(false);
      dispatch(
        error({
          message: 'Erro ao carregar dashboards',
        }),
      );
    }
  }, [company, companies, companyDashboardView, setCompanyDashboardView]);

  const handleNavigation = React.useCallback(
    async ({ goBack = false }: { goBack?: boolean }) => {
      const currentCompanyKind = companies.find((co: any) => co.id === company)?.kind;
      const pathOptions = calculateMenuOptions(menuOptions, profile, companies, company).map((item) => item.path);
      if (
        [Company.SCHOOL, Company.PUBLISHER, Company.GROUP, Company.HUB, Company.FUND].includes(currentCompanyKind) &&
        pathOptions.includes(Paths.DASHBOARD) &&
        !goBack
      ) {
        await loadAssets();
      } else if (pathOptions.includes(Paths.HOME)) {
        history.push(Paths.HOME);
      } else if (!isEmpty(pathOptions)) {
        history.push(head(pathOptions) as string);
      }
    },
    [company, profile],
  );

  const handleGoBack = React.useCallback(() => {
    if (iframe) {
      setIframe('');
    } else if (!iframe && companyDashboardView.current) {
      setProfileDashboards(initialProfileDashboards);
      setCompanyDashboardView({ current: company, data: companyDashboardView.data });
    } else if (!iframe && !companyDashboardView.current) {
      handleNavigation({ goBack: true });
    }
  }, [iframe, companyDashboardView.current, initialProfileDashboards]);

  React.useEffect(() => {
    if (company && profile) {
      handleNavigation({});
    }
  }, [company, profile]);

  if (loadingAssets) {
    return <Loading />;
  }

  return (
    <DashboardView
      dashboard_title={dashboardTitle}
      iframe={iframe}
      companyDashboardView={companyDashboardView}
      setCompanyDashboardView={setCompanyDashboardView}
      profileDashboards={sortBy(profileDashboards, (x) => ~~x.id)}
      handleGoBack={handleGoBack}
    />
  );
};

export default DashboardContainer;
