import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_DOCUMENTS,
  FetchDocumentsPayload,
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchDocuments (action: DefaultAction<FetchDocumentsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchDocuments, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_DOCUMENTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_DOCUMENTS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchDocuments () {
  yield takeLatest(FETCH_DOCUMENTS.request, fetchDocuments)
}

export default function * documentsSagas () {
  yield all([watchDocuments()])
}
