/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { ExamIrregularityEnum, ExamKindEnum, JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'

export interface ExamAttrs {
  exam_placement_id: number
  subject_period_registration_id: number
  score: number | null
  kind: ExamKindEnum
  irregularity: ExamIrregularityEnum
  comments: string
  year: number;
  subject_name: string
  period_name:string
  exam_placement_name:string
  student_name: string
}
export type ExamJson = JsonFormat<'exams', ExamAttrs>

export type FetchExamsResponse = JsonResponseFormat<ExamJson[]>
export type FetchExamResponse = JsonResponseFormat<ExamJson>

export type FetchExamsPayload = WithRequestParams
export interface FetchExamPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateExamPayload {
  data: Partial<ExamAttrs>
}

export interface UpdateExamPayload extends CreateExamPayload {
  id: string
}

export interface DeleteExamPayload {
  id: string
}

export const FETCH_EXAMS = createPromiseAction('exams: FETCH_EXAMS')<FetchExamsPayload, FetchExamsResponse, any>()
export const FETCH_EXAM = createPromiseAction('exams: FETCH_EXAM')<FetchExamPayload, FetchExamResponse, any>()
export const CREATE_EXAM = createPromiseAction('exams: CREATE_EXAM')<CreateExamPayload, FetchExamResponse, any>()
export const UPDATE_EXAM = createPromiseAction('exams: UPDATE_EXAM')<UpdateExamPayload, FetchExamResponse, any>()
export const DELETE_EXAM = createPromiseAction('exams: DELETE_EXAM')<DeleteExamPayload, AxiosResponse, any>()
