/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface RegistrationClosureStatusAttrs {
  name: string
}

export type RegistrationClosureStatusJson = JsonFormat<'registration_closure_status', RegistrationClosureStatusAttrs>

export type FetchRegistrationClosureStatusesResponse = JsonResponseFormat<RegistrationClosureStatusJson[]>

export type FetchRegistrationClosureStatusesPayload = WithRequestParams

export const FETCH_REGISTRATION_CLOSURE_STATUSES = createPromiseAction('registration_closure_statuses: FETCH_REGISTRATION_CLOSURE_STATUSES')<FetchRegistrationClosureStatusesPayload, FetchRegistrationClosureStatusesResponse, any>()
