import { find } from 'lodash';
import React from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import ClassroomsView from '../components/view/Secretary/ClassroomsView';
import { FETCH_CLASSROOMS } from '../store/classrooms';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { ClassroomViewAttributes, defaultPagination, PaginationType, PeriodTypes } from '../utils/constants';
import { RootState } from '../store/configureStore';
import { KtwelveJson } from '../store/ktwelves';
import { get_company_search } from '../utils/functions';
import { CompanyJson } from '../store/companies';

const ClassroomsContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants },
    account: { companies },
  } = state;

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [classrooms, setClassrooms] = React.useState<ClassroomViewAttributes[]>([]);
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [periodFilterValue, setPeriodFilterValue] = React.useState<PeriodTypes | string>('');
  const [yearFilterValue, setYearFilterValue] = React.useState<string>('');
  const [ktwelveFilterValue, setKtwelveFilterValue] = React.useState('');
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);

  const fetchClassroomsMethod = React.useCallback(
    async ({
      newPagination = pagination,
      period = periodFilterValue,
      company_filter = companyFilterValue,
    }: {
      newPagination?: PaginationType;
      period?: PeriodTypes | string;
      company_filter?: string;
    }) => {
      setLoading(true);
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company,
      });
      try {
        const classrooms = await dispatch(
          FETCH_CLASSROOMS.request({
            params: {
              filters: {
                'q[name_cont]': nameFilterValue,
                'q[company_id_in]': companies_array,
                'q[period_eq]': period || '',
                'q[year_eq]': yearFilterValue,
                'q[ktwelve_name_cont]': ktwelveFilterValue,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                include: 'ktwelve_degree,company',
              },
            },
          }),
        );
        const {
          data: { data, meta, included },
        } = classrooms;
        const formattedClassrooms = data.map((item) => {
          const ktwelve = find(
            included,
            (incl) => incl.type === 'ktwelve_degrees' && ~~incl.id === item.attributes.ktwelve_degree_id,
          ) as KtwelveJson;
          const company = find(
            included,
            (incl) => incl.type === 'companies' && ~~incl.id === item.attributes.company_id,
          ) as CompanyJson;
          return {
            id: item.id,
            ...item.attributes,
            ktwelve: ktwelve ? { id: ktwelve.id, ...ktwelve.attributes } : undefined,
            company: company.attributes.name
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setClassrooms(formattedClassrooms);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar turmas',
          }),
        );
      }
    },
    [pagination, nameFilterValue, company, periodFilterValue, yearFilterValue, companyFilterValue],
  );

  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchClassroomsMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchClassroomsMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const loadAssets = React.useCallback(async () => {
    await fetchClassroomsMethod({});
  }, [company]);

  React.useEffect(() => {
    loadAssets();
  }, []);

  return (
    <ClassroomsView
      classrooms={classrooms}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      nameFilterValue={nameFilterValue}
      setNameFilterValue={setNameFilterValue}
      periodFilterValue={periodFilterValue}
      setPeriodFilterValue={setPeriodFilterValue}
      yearFilterValue={yearFilterValue}
      setYearFilterValue={setYearFilterValue}
      ktwelveFilterValue={ktwelveFilterValue}
      setKtwelveFilterValue={setKtwelveFilterValue}
      fetchClassroomsMethod={fetchClassroomsMethod}
      setCompanyFilterValue={setCompanyFilterValue}
      companyFilterValue={companyFilterValue}
    />
  );
};

export default ClassroomsContainer;
