/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { InvoiceItemAttributes, JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface InvoiceItemBaseValues {
  contract_conditional_discount_billing_percentage_base: number
  contract_full_billing_percentage_base: number
  initial_anticipation_discount: number
  initial_contractual_addition: number
  initial_fees: number
  initial_installment_addition: number
  initial_penalty: number
  initial_renegotiation_allowance: number
}
export interface InvoiceItemAttrs {
  active: boolean
  ancestry: string|null;
  base_values: InvoiceItemBaseValues
  billable_id: number
  billable_type: 'RegistrationProduct'|'Contract'
  billable_representative_id: number;
  billable_representative_name: string;
  billable_fees: number;
  billable_penalty: number;
  billing_id: number
  child_ids: number;
  child_invoice_items: InvoiceItemAttributes[]
  code: string
  contract_conditional_discount: number
  contract_full: number;
  created_at: string;
  description: string;
  invoice_id: number;
  parent_id: number;
  portion: number;
  product_name: string;
  sibling_ids: number;
  updated_at: string;
  deleted_at: string|null;
}


export type InvoiceItemJson = JsonFormat<'invoice_items', InvoiceItemAttrs>

export type FetchInvoicesItemResponse = JsonResponseFormat<InvoiceItemJson[]>
export type FetchInvoiceItemResponse = JsonResponseFormat<InvoiceItemJson>

export type FetchInvoiceItemsPayload = WithRequestParams
  export interface FetchInvoiceItemPayload extends WithRequestParams {
  id: string | number,
}

export interface CreateInvoiceItemPayload {
  data: Partial<Omit<InvoiceItemAttributes, 'id'>>
}

export const CREATE_INVOICE_ITEM = createPromiseAction('invoice_items: CREATE_INVOICE_ITEM')<CreateInvoiceItemPayload, FetchInvoiceItemResponse, any>()
