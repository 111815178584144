import React from 'react';
import { css } from '@emotion/react';
import {
  colors,
  CompositionAttributes,
  CurriculumAttributes,
} from '../../../utils/constants';
import { FETCH_CURRICULUM } from '../../../store/curriculums';
import { useDispatch } from 'react-redux';
import { error } from 'react-notification-system-redux';
import { reduce } from 'lodash';
import { Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TooltipButton from '../../shared/TooltipButton';
import PenIcon from '../../icon/PenIcon';
import Loading from '../../loading/Loading';
import CompositionPeriodsTable from '../../table/CompositionPeriodsTable';
import CompositionsFormContainer from '../../../containers/CompositionsFormContainer';
import { Visibility } from '@mui/icons-material';

const WraperCss = css`
  flex: 1;
`;

const TableCss = css`
  & .include {
    display: flex;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  & .actionsIcon {
    color: ${colors.darkGrayBlue};
    cursor: pointer;
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
    & tbody > tr {
      background: white;
    }
  }
`;

const CompositionStep = (props: { curriculum: CurriculumAttributes }) => {
  const { curriculum } = props;
  const dispatch = useDispatch();
  const [compositions, setCompositions] = React.useState<CompositionAttributes[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [expandedPeriods, setExpandedPeriods] = React.useState<number[]>([]);
  const [editingComposition, setEditingCompositions] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);

  const fetchCompositions = async (id: string = curriculum?.id as string) => {
    setLoading(true);
    try {
      const response = await dispatch(
        FETCH_CURRICULUM.request({
          id: id,
          params: {
            filters: {
              include: 'compositions',
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const compositions_attributes = reduce(
        included,
        (acc, incl) => {
          if (incl.type === 'compositions') {
            return acc.concat({ id: incl.id, ...incl.attributes });
          }
          return acc;
        },
        [] as CompositionAttributes[],
      );
      setCompositions(compositions_attributes);
      setLoading(false);
    } catch (er) {
      dispatch(
        error({
          message: 'Erro ao carregar e matriz curricular',
          autoDismiss: 3,
        }),
      );
    }
  };

  const init = async () => {
    await fetchCompositions();
  };

  React.useEffect(() => {
    init();
  }, []);

  const columns = ['Nome'];
  if (loading) {
    return <Loading />;
  }

  const handleSavingWhenCreate = async () => {
    setCreate(false);
    await init();
  };
  return (
    <div css={WraperCss}>
      <div css={TableCss}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell align='left' key={item}>
                    {item}
                  </TableCell>
                ))}
                <TableCell align='right'>
                  <button disabled={editingComposition.length > 0} onClick={() => setCreate(!create)} className='green small'>
                    <span> Criar Composição </span>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                  <Collapse in={create} timeout='auto' unmountOnExit>
                    <CompositionsFormContainer
                      close_form={() => setCreate(false)}
                      onSave={handleSavingWhenCreate}
                      curriculum_id={~~curriculum.id}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
              {compositions.map((composition) => {
                const close_editing_form = () => {
                  setEditingCompositions((current) => current.filter((item) => item !== ~~composition.id));
                };
                return (
                  <React.Fragment key={composition.id}>
                    <TableRow>
                      <TableCell align='left'>{composition.name}</TableCell>
                      <TableCell align='right'>
                        <TooltipButton
                          tooltipProps={{
                            title: expandedPeriods.includes(~~composition.id) ? 'Ocultar períodos' : 'Ver períodos',
                          }}
                          Icon={Visibility}
                          iconProps={{ style: { color: colors.blue } }}
                          iconButtonProps={{
                            onClick: () =>
                              setExpandedPeriods((current) => {
                                if (current.includes(~~composition.id)) {
                                  return current.filter((item) => item !== ~~composition.id);
                                } else {
                                  return current.concat(~~composition.id);
                                }
                              }),
                          }}
                        />
                        <TooltipButton
                          tooltipProps={{
                            title: editingComposition.includes(~~composition.id)
                              ? 'Cancelar edição'
                              : 'Editar composição',
                          }}
                          Icon={PenIcon}
                          iconProps={{ style: { color: colors.blue } }}
                          iconButtonProps={{
                            disabled:
                              create ||
                              (editingComposition.length > 0 && !editingComposition.includes(~~composition.id)),
                            onClick: () =>
                              setEditingCompositions((current) => {
                                if (current.includes(~~composition.id)) {
                                  return current.filter((item) => item !== ~~composition.id);
                                } else {
                                  return current.concat(~~composition.id);
                                }
                              }),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 2}>
                        <Collapse in={editingComposition.includes(~~composition.id)} timeout='auto' unmountOnExit>
                          <CompositionsFormContainer
                            onSave={async () => {
                              close_editing_form();
                              await init();
                            }}
                            curriculum_id={~~curriculum.id}
                            composition_id={composition.id}
                            close_form={close_editing_form}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 2}>
                        <Collapse in={expandedPeriods.includes(~~composition.id)} timeout='auto' unmountOnExit>
                          <CompositionPeriodsTable  composition={composition} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CompositionStep;
