import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  DELETE_REPRESENTATIVE,
  DELETE_REPRESENTATIVES,
  DeleteRepresentativePayload,
  DeleteRepresentativesPayload,
  FETCH_REPRESENTATIVE,
  FetchRepresentativePayload
} from './index'
import { DefaultAction } from '../../utils/constants'
import * as services from './services'

import { updateCredentialsRequest } from '../auth/'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRepresentative (action: DefaultAction<FetchRepresentativePayload, string>): any {
  const { payload: {id, params} } = action
  try {
    const response = yield call(services.fetchRepresentative, { id, params })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_REPRESENTATIVE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_REPRESENTATIVE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteRepresentative (action: DefaultAction<DeleteRepresentativePayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.deleteRepresentative, id)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_REPRESENTATIVE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_REPRESENTATIVE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteRepresentatives(action: DefaultAction<DeleteRepresentativesPayload, string>): any {
  const { payload: {ids}} = action
  try {
    const response = yield all(ids.map((id) => call(services.deleteRepresentative, id )))
    yield put(DELETE_REPRESENTATIVES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_REPRESENTATIVES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRepresentativesSagas () {
  yield takeLatest(DELETE_REPRESENTATIVE.request, deleteRepresentative)
  yield takeLatest(DELETE_REPRESENTATIVES.request, deleteRepresentatives)
  yield takeLatest(FETCH_REPRESENTATIVE.request, fetchRepresentative)
}

export default function * representativesSagas () {
  yield all([watchRepresentativesSagas()])
}
