import React from 'react'
import { makeStyles } from '@mui/styles'

import Loading from '../components/loading/Loading'
import Logo from '../assets/logo.svg'

const useStyles = makeStyles((): {
  container: any,
  childContainer: any,
  logo: any
} => ({
  container: {
    boxSizing: 'border-box',
    background: '#E8E8E8',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%'
  },
  childContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'transparent',
    padding: '60px 80px',
    height: '100%',
    position: 'relative',
    zIndex: '5'
  },
  logo: {
    width: '300px',
    marginBottom: '30px'
  }
}))
const SomaLogin = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <section className={classes.childContainer}>
        <img src={Logo} className={classes.logo} alt='Logo do versar' />
        <Loading />
      </section>
    </div>
  )
}

export default SomaLogin
