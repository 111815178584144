import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const contractsEndpoint = '/contracts'

export const fetchContracts = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${contractsEndpoint}?${queryParams}`)
}

export const signContractsBatch = ({data} : { data: object }) => {
  return http.post(`${contractsEndpoint}/batch_sign`, data)
}

export const fetchContract = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${contractsEndpoint}/${id}?${queryParams}`)
}

export const createContract = (params: object) => {
  return http.post(`${contractsEndpoint}`, params)
}
export const sendContract = ({ params, id }: { params: object | undefined, id: string | number } ) => {
  return http.post(`${contractsEndpoint}/${id}/to_sign_service`, params)
}
export const resendContract = ({ id }: { id: string | number } ) => {
  return http.post(`${contractsEndpoint}/${id}/resend`)
}

export const refreshContract = ({ id }: { id: string | number } ) => {
  return http.get(`${contractsEndpoint}/${id}/refresh_contract`)
}

export const generateBillings = ({ id }: { id: string | number } ) => {
  return http.post(`${contractsEndpoint}/${id}/generate_billings`)
}

export const deleteContract = ({ id }: { id: string | number } ) => {
  return http.delete(`${contractsEndpoint}/${id}`)
}


