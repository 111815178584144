import React from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { CompanyAttributes, CoordinatorAttributes, defaultPagination, PaginationType, Role, RoomAttributes, TeacherAttributes } from '../utils/constants';
import { RootState } from '../store/configureStore';
import { get_company_search } from '../utils/functions';
import { FETCH_ROOMS } from '../store/rooms';
import RoomsTable from '../components/table/RoomsTable';
import { find, head, isEmpty } from 'lodash';
interface StateCompanies extends CompanyAttributes {
  teachers: TeacherAttributes[]
  coordinators: CoordinatorAttributes[]
}

const RoomsContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants, profile },
    account: { companies },
  } = state;

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [rooms, setRooms] = React.useState<RoomAttributes[]>([]);
  const [productFilterValue, setProductFilterValue] = React.useState('');
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const fetchRoomsMethod = React.useCallback(
    async ({
      newPagination = pagination,
      company_filter = companyFilterValue,
      product_filter = productFilterValue
    }: {
      newPagination?: PaginationType;
      company_filter?: string;
      product_filter?: string
    }) => {
      setLoading(true);
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company,
      });
      try {
        let extra_params = {}
        if(profile.role === Role.TEACHER){
          const current_teacher = head(find(companies as StateCompanies[], (co: StateCompanies) => co.id === company)?.teachers)
          extra_params = {
            ...extra_params,
            ...(current_teacher ? {'q[teachers_id_eq]': current_teacher.id} : {})
          }
        } else if (profile.role === Role.COORDINATOR) {
          const coordinators = find(companies as StateCompanies[], (co: StateCompanies) => co.id === company)?.coordinators
          extra_params = {
            ...extra_params,
            ...(coordinators && !isEmpty(coordinators) ? {'q[course_id_in]': coordinators.map(item => item.course_id)} : {})
          }
        }
        const rooms = await dispatch(
          FETCH_ROOMS.request({
            params: {
              filters: {
                'q[name_cont]': nameFilterValue,
                'q[product_name_eq]': product_filter,
                'q[company_id_in]': companies_array,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                ...extra_params
              },
            },
          }),
        );
        const {
          data: { data, meta },
        } = rooms;
        const formattedRooms = data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setRooms(formattedRooms);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar salas',
          }),
        );
      }
    },
    [pagination, nameFilterValue, company, companyFilterValue, productFilterValue],
  );
  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchRoomsMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchRoomsMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const loadAssets = React.useCallback(async () => {
    await fetchRoomsMethod({});
  }, [company]);

  React.useEffect(() => {
    loadAssets();
  }, []);

  return (
    <RoomsTable
      rooms={rooms}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      nameFilterValue={nameFilterValue}
      productFilterValue={productFilterValue}
      setNameFilterValue={setNameFilterValue}
      fetchRoomsMethod={fetchRoomsMethod}
      setCompanyFilterValue={setCompanyFilterValue}
      setProductFilterValue={setProductFilterValue}
      companyFilterValue={companyFilterValue}
    />
  );
};

export default RoomsContainer;
