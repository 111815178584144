/* eslint-disable camelcase */
import React from 'react'
import { error, success } from 'react-notification-system-redux'
import { useDispatch, useSelector } from 'react-redux'
import { formValueSelector, isValid } from 'redux-form'
import { RootState } from '../../store/configureStore'
import { colors, onConfirm, RegistrationProductAttributes } from '../../utils/constants'
import { IconModal } from '../modal/Modal'
import { UPDATE_REGISTRATION_PRODUCT } from '../../store/registration_products'
import PenIcon from '../icon/PenIcon'
import RegistrationProductForm from '../form/RegistrationProductForm'

const EditRegistrationProduct = ({
  initialValues,
  fetchRegistrationProducts,
} : {
  initialValues?: Partial<RegistrationProductAttributes>
  fetchRegistrationProducts: () => Promise<void>
}) => {
  if(!initialValues?.starts_at){
    return null
  }
  const formName = 'registrationProductForm'
  const dispatch = useDispatch()
  const formValues = formValueSelector(formName);
  const state = useSelector((state: RootState) => state)
  const isFormValid = isValid(formName)(state)
  const starts_at = formValues(state, 'starts_at') as Date
  const notification_email = formValues(state, 'notification_email') as string


  const updateRegistrationProduct = React.useCallback(async (data: Partial<RegistrationProductAttributes>) => {
    try {
      if(initialValues?.id){
        await dispatch(
          UPDATE_REGISTRATION_PRODUCT.request({data, id: initialValues.id})
        )
        dispatch(
          success(
            {
              message: 'Data do produto alterados com sucesso'
            }
          )
        )
        await fetchRegistrationProducts()
      }
    } catch (err) {
      dispatch(error({
        message: 'Erro ao atualizar dados do produto'
      }))
    }
  },[
    initialValues,
    fetchRegistrationProducts,
    starts_at,
    notification_email
  ])


  const onConfirm: onConfirm = React.useCallback(async ({ setLoading, handleClose }) => {
    
    const data = {
      starts_at: starts_at instanceof Date ? starts_at.toISOString() : starts_at,
      notification_email
    }
    setLoading(true)
    await updateRegistrationProduct(data)
    
    setLoading(false)
    handleClose()
  }, [
    initialValues,
    updateRegistrationProduct,
    starts_at
  ])
  const disabled = !isFormValid

  const tooltipText = 'Editar produto da matrícula'
  return(
        <IconModal
          icon={PenIcon}
          iconProps={{style:{color: colors.blue}}}
          disableConfirm={disabled}
          onConfirm={onConfirm}
          title='Editar produto da matrícula'
          tooltipText={tooltipText}
          confirmButtonText='Salvar'
        >
          <RegistrationProductForm
            initialValues={initialValues}
          />
        </IconModal>
  )
}

export default EditRegistrationProduct 