/* eslint-disable camelcase */
import { makeStyles } from '@mui/styles';
import { compose } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import {
  validation,
  DefaultOptionType,
  KtwelveSubjectAttributes,
  TeacherSubjectAttributes,
} from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { compact, isEmpty } from 'lodash';
import React from 'react';
import AutoCompleteComponent from '../input/form/autocomplete';
import { error } from 'react-notification-system-redux';
import { FETCH_TEACHERS } from '../../store/teachers';
import CheckboxComponent from '../input/form/checkbox';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      padding: '2rem 0',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const form_name = 'teacherSubjectForm';

export const TeacherSubjectFormBody = (props: {
  teacher_subject_form_values: TeacherSubjectAttributes;
  all_teacher_subject_teacher_ids?: number[];
  ktwelve_subject: KtwelveSubjectAttributes;
}) => {
  const { teacher_subject_form_values, all_teacher_subject_teacher_ids = [], ktwelve_subject } = props;
  const [loading, setLoading] = React.useState(false);
  const [teacherSubjectOptions, setTeacherSubjectOptions] = React.useState<DefaultOptionType[]>([]);
  const dispatch = useDispatch();
  const fetchTeacherOptions = React.useCallback(
    async (value: string, teacher_id?: string) => {
      try {
        setLoading(true);
        const used_teacher_subject_ids = compact(
          all_teacher_subject_teacher_ids.filter((item) => {
            return !(teacher_id && item.toString() === teacher_id);
          }),
        );
        let extra_attrs = {};
        if (!isEmpty(used_teacher_subject_ids)) {
          extra_attrs = {
            ...extra_attrs,
            'q[id_not_int]': used_teacher_subject_ids,
          };
        }
        if (!isEmpty(value)) {
          extra_attrs = {
            ...extra_attrs,
            'q[teacher_user_name_start]': value,
          };
        }

        if (!isEmpty(teacher_id)) {
          extra_attrs = {
            ...extra_attrs,
            'q[id_eq]': teacher_id,
          };
        }

        const ktwelve_subjects = await dispatch(
          FETCH_TEACHERS.request({
            params: {
              filters: {
                'q[company_id_eq]': ktwelve_subject.company_id.toString(),
                'q[ktwelve_subject_id_eq]': ktwelve_subject.id.toString(),
                ...extra_attrs,
              },
            },
          }),
        );
        if (ktwelve_subjects) {
          const {
            data: { data },
          } = ktwelve_subjects;
          const formattedData = data.map(({ attributes, id }) => {
            return {
              label: attributes.teacher_name,
              value: id,
            };
          });
          setTeacherSubjectOptions(formattedData);
          setLoading(false);
        }
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de professor para essa disciplina',
          }),
        );
      }
    },
    [ktwelve_subject],
  );

  const init = React.useCallback(async () => {
    if (!isEmpty(teacher_subject_form_values.id)) {
      setTeacherSubjectOptions([
        {
          label: teacher_subject_form_values.teacher_name,
          value: teacher_subject_form_values.teacher_id.toString(),
        },
      ]);
    } else if (teacher_subject_form_values.teacher_id) {
      await fetchTeacherOptions('', teacher_subject_form_values.teacher_id.toString());
    }
  }, [teacher_subject_form_values]);
  React.useEffect(() => {
    init();
  }, []);
  return (
    <div style={{ display: 'grid' }}>
      <Field
        name='teacher_id'
        label={'Professor'}
        placeholder={'Buscar professor'}
        component={AutoCompleteComponent}
        autoCompleteProps={{
          disabled: !isEmpty(teacher_subject_form_values.id),
          filterOptions: (x: any) => x,
          loading,
          getValueOnChange: true,
          fetchOptions: fetchTeacherOptions,
        }}
        options={teacherSubjectOptions}
        validate={[validation.required]}
      />
      <Field name='active' component={CheckboxComponent} placeholder={'Ativo'} />
    </div>
  );
};

const TeacherSubjectForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form: () => void;
  ktwelve_subject: KtwelveSubjectAttributes;
  all_teacher_subject_teacher_ids: number[];
}) => {
  const { handleSubmit, onSubmit, close_form, ktwelve_subject, all_teacher_subject_teacher_ids } = props;
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const teacher_subject_form_values = getFormValues(form_name)(state) as TeacherSubjectAttributes;

  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar professor na disciplina</span>
      <form className={classes.form}>
        <>
          <TeacherSubjectFormBody
            ktwelve_subject={ktwelve_subject}
            teacher_subject_form_values={teacher_subject_form_values}
            all_teacher_subject_teacher_ids={all_teacher_subject_teacher_ids}
          />
          <div className={classes.buttons}>
            <button
              onClick={(e) => {
                e.preventDefault();
                close_form();
              }}
              className='red small'
            >
              <span> Cancelar </span>
            </button>
            <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar professor na disciplina</span>
            </button>
          </div>
        </>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(TeacherSubjectForm);
