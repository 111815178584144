/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PiChalkboardTeacher } from 'react-icons/pi';
import { colors, ClassTimeAttributes, RoomAttributes, ClassTimeSubjectAttributes } from '../../utils/constants';
import { map } from 'lodash';
import TooltipButton from '../shared/TooltipButton';
import { Collapse } from '@mui/material';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';
import PenIcon from '../icon/PenIcon';
import { ClassTimeSubjectJson } from '../../store/class_time_subjects';
import { FETCH_CLASS_TIME } from '../../store/class_times';
import ClassTimeSubjectsFormContainer from '../../containers/ClassTimeSubjectsFormContainer';
import TeacherClassTimesTable from './TeacherClassTimesTable';

const TableCss = css`
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

const ClassTimeSubjectsTable = ({ room, class_time }: { room: RoomAttributes, class_time: ClassTimeAttributes }) => {
  const [editClassTimeSubject, setEditingClassTimeSubject] = React.useState<number[]>([]);
  const [create, setCreate] = React.useState(false);
  const [class_time_subjects, setClassTimeSubjects] = React.useState<ClassTimeSubjectAttributes[]>([]);
  const [expandedTeacherClassTimes, setExpandedTeacherClassTimes] = React.useState<number[]>([]);

  const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch();

  const fetchClassTimeSubjects = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        FETCH_CLASS_TIME.request({
          id: class_time.id,
          params: {
            filters: {
              include: 'class_time_subjects',
            },
          },
        }),
      );
      const {
        data: { included = [] },
      } = response;
      const class_time_subjects = map(
        included.filter((incl) => incl.type === 'class_time_subjects') as ClassTimeSubjectJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );
      setClassTimeSubjects(class_time_subjects);
      setLoading(false);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar disciplinas do horário',
        }),
      );
      setLoading(false);
    }
  }, [class_time]);

  const init = async () => {
    await fetchClassTimeSubjects();

  }
  React.useEffect(() => {
    init()
  }, []);

  if (loading){
    return <Loading />;
  }

  return (
    <Table css={TableCss} size='small' aria-label='subject-periods'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Disciplina</TableCell>
          <TableCell align='right'>
            {' '}
            <button disabled={editClassTimeSubject.length > 0} onClick={() => setCreate(!create)} className='green small'>
              <span> Adicionar Disciplina no horário</span>
            </button>
          </TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} align='right'>
            <Collapse in={create} timeout='auto' unmountOnExit>
              <ClassTimeSubjectsFormContainer
                room={room}
                onSave={async () => {
                  await fetchClassTimeSubjects();
                  setCreate(false);
                }}
                close_form={() => setCreate(false)}
                class_time={class_time}
              />
            </Collapse>
          </TableCell>
        </TableRow>
        {class_time_subjects.map((class_time_subject) => {
          const close_editing_form = () => {
            setEditingClassTimeSubject((current) => current.filter((item) => item !== ~~class_time_subject.id));
          };
          return (
            <React.Fragment key={class_time_subject.id}>
              <TableRow>
                <TableCell align='center'>{class_time_subject.subject_name}</TableCell>
                <TableCell align='right'>
                  <TooltipButton
                    tooltipProps={{
                      title: expandedTeacherClassTimes.includes(~~class_time_subject.id)
                        ? 'Ocultar professores da disciplina no horário'
                        : 'Ver professores da discplina no horário',
                    }}
                    Icon={PiChalkboardTeacher}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedTeacherClassTimes((current) => {
                          if (current.includes(~~class_time_subject.id)) {
                            return current.filter((item) => item !== ~~class_time_subject.id);
                          } else {
                            return current.concat(~~class_time_subject.id);
                          }
                        }),
                    }}
                  />
                  <TooltipButton
                    tooltipProps={{
                      title: editClassTimeSubject.includes(~~class_time_subject.id)
                        ? 'Cancelar edição'
                        : 'Editar Horário',
                    }}
                    Icon={PenIcon}
                    iconButtonProps={{
                      disabled:
                        create || (editClassTimeSubject.length > 0 && !editClassTimeSubject.includes(~~class_time_subject.id)),
                      onClick: () =>
                        setEditingClassTimeSubject((current) => {
                          if (current.includes(~~class_time_subject.id)) {
                            return current.filter((item) => item !== ~~class_time_subject.id);
                          } else {
                            return current.concat(~~class_time_subject.id);
                          }
                        }),
                    }}
                  />{' '}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                  <Collapse in={expandedTeacherClassTimes.includes(~~class_time_subject.id)} timeout='auto' unmountOnExit>
                    <TeacherClassTimesTable room={room} class_time_subject={class_time_subject} />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={editClassTimeSubject.includes(~~class_time_subject.id)} timeout='auto' unmountOnExit>
                    <ClassTimeSubjectsFormContainer
                      room={room}
                      onSave={fetchClassTimeSubjects}
                      close_form={close_editing_form}
                      class_time={class_time}
                      class_time_subject_id={class_time_subject.id}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ClassTimeSubjectsTable;
