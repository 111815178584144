/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ProductFamiliesForm from '../components/form/ProductFamiliesForm';
import { RootState } from '../store/configureStore';
import { CREATE_PRODUCT_FAMILY, FETCH_PRODUCT_FAMILY, UPDATE_PRODUCT_FAMILY } from '../store/product_families';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { ProductFamilyAttributes, LocationState, Paths } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';

const ProductFamiliesFormContainer = () => {
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    auth: { company },
  } = useSelector((state: RootState) => state);
  const [initialValues, setInitialValues] = React.useState<ProductFamilyAttributes | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const isUpdating = !isNil(initialValues?.id);

  const loadProductFamily = React.useCallback(async () => {
    if (location?.state?.product_family_id) {
      const product_family = await dispatch(
        FETCH_PRODUCT_FAMILY.request({
          id: location.state.product_family_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = product_family;
      const formattedProductFamily = {
        id,
        ...attributes,
      };
      setInitialValues(formattedProductFamily);
    }
  }, [location]);

  const initProductFamiliesForm = React.useCallback(async () => {
    setLoading(true);
    await loadProductFamily();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: ProductFamilyAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'company_id',
          ]) as ProductFamilyAttributes;
          await dispatch(
            UPDATE_PRODUCT_FAMILY.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          await dispatch(
            CREATE_PRODUCT_FAMILY.request({
              data: {
                ...data,
              },
            }),
          );
        }
        history.push(Paths.PRODUCT_FAMILY_LIST);
        dispatch(
          success({
            message: 'Família de produtos salvo com sucesso.',
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar família de produtos.',
          }),
        );
      }
    },
    [initialValues, company, isUpdating],
  );

  React.useEffect(() => {
    initProductFamiliesForm();
  }, []);
  if (!loaded) {
    return <Loading />;
  }

  return <ProductFamiliesForm initialValues={initialValues} onSubmit={onSubmit} />;
};

export default ProductFamiliesFormContainer;
