/* eslint-disable camelcase */
import { css } from '@emotion/react'
import React from 'react'
import { Field } from 'redux-form'
import { validation } from '../../utils/constants'
import DatePickerComponent from '../input/form/datepicker'

interface ProductPeriodStartEnd {
  start: string,
  end: string
}

const productPeriodFormWrapperCss = css`
  display: grid;
  grid-template-columns: 40% 40% 10%;
  justify-content: space-between;
  align-items: center;
`

const ProductPeriodForm = (props : {
  starts_at_limits?: ProductPeriodStartEnd
  ends_at_limits?: ProductPeriodStartEnd
}) => {
  const {starts_at_limits, ends_at_limits} = props
  return (
    <div css={productPeriodFormWrapperCss}>
      <Field
      name={`starts_at`}
      label={'Início *'}
      placeholder={'01/01/2021'}
      component={DatePickerComponent}
      datePickerProps={{
          minDate: starts_at_limits?.start,
          maxDate: starts_at_limits?.end,
          showDropdownIcon: true,
      }}
      validate={[validation.dateRequired]}
    />
    <Field
      name={`ends_at`}
      label={'Fim *'}
      placeholder={'01/01/2021'}
      component={DatePickerComponent}
      datePickerProps={{
          minDate: ends_at_limits?.start,
          maxDate: ends_at_limits?.end,
          showDropdownIcon: true,
      }}
      validate={[validation.dateRequired]}
    />
    </div>
  )
}

export default ProductPeriodForm