import React from 'react';
import { SvgIcon } from '@mui/material';

const SettingIcon = (props: any) => (
  <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
<path d='M8.72869 25.4981C9.3189 25.4981 9.90911 25.6525 10.4539 25.9703C11.5344 26.5968 12.1791 27.7137 12.1791 28.9576C12.1791 29.9655 12.9963 30.7827 14.0042 30.7827H18.5443C19.5522 30.7827 20.3694 29.9655 20.3694 28.9576C20.3694 27.7137 21.0141 26.5968 22.0946 25.9703C23.1751 25.3438 24.4645 25.3438 25.545 25.9703C26.4167 26.4788 27.5336 26.17 28.0421 25.2983L30.3121 21.3667C30.8115 20.495 30.5119 19.3781 29.6402 18.8787C28.5596 18.2522 27.9149 17.1353 27.9149 15.8914C27.9149 14.6474 28.5596 13.5305 29.6402 12.904C30.5119 12.4046 30.8115 11.2787 30.3121 10.4161L28.0421 6.48438C27.7969 6.06669 27.4065 5.75798 26.9343 5.63086C26.4621 5.50374 25.9718 5.56729 25.5541 5.81246C24.4736 6.43898 23.1842 6.43898 22.1037 5.81246C21.0232 5.18593 20.3785 4.06907 20.3785 2.8251C20.3785 1.8172 19.5613 1 18.5534 1H14.0133C13.0054 1 12.1882 1.8172 12.1882 2.8251C12.1882 4.06907 11.5435 5.18593 10.463 5.81246C9.38246 6.43898 8.09309 6.43898 7.01256 5.81246C6.14087 5.31305 5.02401 5.61269 4.51553 6.48438L2.2455 10.4161C2.00034 10.8337 1.93678 11.3331 2.0639 11.8053C2.19102 12.2775 2.49067 12.6679 2.91743 12.9131C3.99796 13.5396 4.64265 14.6565 4.64265 15.9004C4.64265 17.1444 3.99796 18.2613 2.91743 18.8878C2.49975 19.133 2.19102 19.5234 2.0639 19.9956C1.93678 20.4677 2.00034 20.9581 2.2455 21.3757L4.51553 25.3074C4.76069 25.7251 5.15114 26.0338 5.6233 26.161C6.09547 26.2881 6.58579 26.2245 7.00348 25.9794C7.5392 25.6706 8.13849 25.5072 8.72869 25.5072V25.4981Z' stroke='#1D1D1B' strokeMiterlimit='10' strokeLinecap='round'/>
<path d='M16.2741 10.5706C13.3412 10.5706 10.9531 12.9586 10.9531 15.8915C10.9531 18.8244 13.3412 21.2124 16.2741 21.2124C19.2069 21.2124 21.595 18.8244 21.595 15.8915C21.595 12.9586 19.2069 10.5706 16.2741 10.5706Z' stroke='#1D1D1B' strokeMiterlimit='10' strokeLinecap='round'/>
  </SvgIcon>
);

export default SettingIcon;
