import React from 'react'
import { DefaultTheme, makeStyles } from '@mui/styles';
import { colors, DefaultOptionType, LocationState, Paths } from '../../utils/constants';
import { useHistory, useLocation } from 'react-router-dom';

type Props = {
  grid_template_columns: string,
}
const useStyles = makeStyles<DefaultTheme, Props>(
  (): {
    tabs: any;
  } => ({
    tabs: {
      display: 'grid',
      background: 'white',
      gridTemplateColumns: (props: Props) => props.grid_template_columns,
      borderRadius: '4px',
      margin: '2rem 0',
      cursor: 'default',
      justifyContent: 'space-between',
      '& > div': {
        padding: '1rem 0',
        cursor: 'pointer',
        '& span': {
          fontSize: '1.25vw',
        },
      },
      '& > div:first-of-type': {
        borderRadius: '4px 0 0 4px',
      },
      '& > div:last-of-type': {
        borderRadius: '0 4px 4px 0',
      },
    },
  }),
);
interface StepOptions extends DefaultOptionType {
  path: Paths
}
const CustomTabs = (props: {
  grid_template_columns?: string
  step: number
  step_options: StepOptions[]
  handleClick: (value: number) => void
  has_resource: boolean
}) => {
  const { grid_template_columns = 'repeat(5, 0.2fr)', step, step_options, handleClick, has_resource} = props
  const classes = useStyles({
    grid_template_columns
  })
  const location = useLocation<LocationState>();
  const history = useHistory()
  return (
    <div className={classes.tabs}>
    {step_options.map((option) => {
      const selected = option.path ? option.path === location.pathname : step === option.value;
      const styles: {
        background: string;
        cursor: string;
      } = { background: 'default', cursor: 'pointer' };
      styles.background = selected ? colors.darkBlue : 'initial';
      styles.cursor = selected ? 'default' : 'pointer';
      if (!has_resource && option.value !== 0) {
        styles.cursor = 'default';
        styles.background = colors.grayBlue;
      }
      const handleClickMethod = () => {
        if(option.path){
          history.push({pathname: option.path, state: { curriculum_id: location?.state?.curriculum_id }})
        } else {
          handleClick(option.value as number)
        }
      }
      return (
        <div
          style={{
            ...styles,
          }}
          onClick={handleClickMethod}
          key={option.label}
        >
          <span style={{ color: selected ? 'white' : 'initial' }}>{option.label}</span>
        </div>
      );
    })}
  </div>
  )
}

export default CustomTabs