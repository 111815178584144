import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const examPlacementEndpoints = '/exam_placements'

export const fetchExamPlacements = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${examPlacementEndpoints}?${queryParams}`)
}

export const fetchExamPlacement = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${examPlacementEndpoints}/${id}?${queryParams}`)
}

export const createExamPlacement = (data: object) => {
  return http.post(`${examPlacementEndpoints}`, data)
}

export const updateExamPlacement = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${examPlacementEndpoints}/${id}`, data)
}

export const deleteExamPlacement = ({ id }: { id: string | number } ) => {
  return http.delete(`${examPlacementEndpoints}/${id}`)
}
