/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { CourseJson } from '../courses'

export interface KtwelveAttrs {
  course_id: number
  description: string
  name: string
}
export type KtwelveJson = JsonFormat<'ktwelve_degrees', KtwelveAttrs>

export type FetchKtwelvesResponse = JsonResponseFormat<KtwelveJson[], (CourseJson)[]>
export type FetchKtwelveResponse = JsonResponseFormat<KtwelveJson, (CourseJson)[]>
export type FetchKtwelvesPayload = WithRequestParams
export interface FetchKtwelvePayload extends WithRequestParams {
  id: string | number,
}
export interface CreateKtwelvePayload {
  data: object
}

export interface UpdateKtwelvePayload extends CreateKtwelvePayload {
  id: string
}

export const FETCH_KTWELVES = createPromiseAction('ktwelves: FETCH_KTWELVES')<FetchKtwelvesPayload, FetchKtwelvesResponse, any>()
export const FETCH_KTWELVE  = createPromiseAction('ktwelves: FETCH_KTWELVE')<FetchKtwelvePayload, FetchKtwelveResponse, any>()
export const CREATE_KTWELVE = createPromiseAction('ktwelves: CREATE_KTWELVE')<CreateKtwelvePayload, FetchKtwelveResponse, any>()
export const UPDATE_KTWELVE = createPromiseAction('ktwelves: UPDATE_KTWELVE')<UpdateKtwelvePayload, FetchKtwelveResponse, any>()