/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'

export interface RoomStudentStatusAttrs {
  company_id: number
  name: string
  cancellation_option: boolean
}
export type RoomStudentStatusJson = JsonFormat<'room_student_status', RoomStudentStatusAttrs>

export type FetchRoomStudentStatusesResponse = JsonResponseFormat<RoomStudentStatusJson[]>
export type FetchRoomStudentStatusResponse = JsonResponseFormat<RoomStudentStatusJson>

export type FetchRoomStudentStatusesPayload = WithRequestParams
export interface FetchRoomStudentStatusPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateRoomStudentStatusPayload {
  data: Partial<RoomStudentStatusAttrs>
}

export interface UpdateRoomStudentStatusPayload extends CreateRoomStudentStatusPayload {
  id: string
}

export interface DeleteRoomStudentStatusPayload {
  id: string
}

export const FETCH_ROOM_STUDENT_STATUSES = createPromiseAction('room_student_status: FETCH_ROOM_STUDENT_STATUSES')<FetchRoomStudentStatusesPayload, FetchRoomStudentStatusesResponse, any>()
export const FETCH_ROOM_STUDENT_STATUS = createPromiseAction('room_student_status: FETCH_ROOM_STUDENT_STATUS')<FetchRoomStudentStatusPayload, FetchRoomStudentStatusResponse, any>()
export const CREATE_ROOM_STUDENT_STATUS = createPromiseAction('room_student_status: CREATE_ROOM_STUDENT_STATUS')<CreateRoomStudentStatusPayload, FetchRoomStudentStatusResponse, any>()
export const UPDATE_ROOM_STUDENT_STATUS = createPromiseAction('room_student_status: UPDATE_ROOM_STUDENT_STATUS')<UpdateRoomStudentStatusPayload, FetchRoomStudentStatusResponse, any>()
export const DELETE_ROOM_STUDENT_STATUS = createPromiseAction('room_student_status: DELETE_ROOM_STUDENT_STATUS')<DeleteRoomStudentStatusPayload, AxiosResponse, any>()
