import { createPromiseAction } from "redux-saga-promise-actions"
import { FormulableType, JsonFormat, JsonResponseFormat, WithRequestParams } from "../../utils/constants"
import { AxiosResponse } from "axios"
import { OperationJson } from "../operations"
import { ExamPlacementJson } from "../exam_placements"

export interface FormulaAttrs {
  formulable_type: FormulableType
  formulable_id: number
  name: string
  step: number
  threshold: number
}

export type FormulaJson = JsonFormat<'formulas', FormulaAttrs>
export type FetchFormulasResponse = JsonResponseFormat<FormulaJson[],(OperationJson)[]>
export type FetchFormulaResponse = JsonResponseFormat<FormulaJson, (OperationJson|ExamPlacementJson|FormulaJson)[]>
export type FetchFormulasPayload = WithRequestParams

export interface FetchFormulaPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateFormulaPayload {
  data: object
}

export interface UpdateFormulaPayload extends CreateFormulaPayload {
  id: string
}

export interface DeleteFormulaPayload {
  id: string
}


export const FETCH_FORMULAS = createPromiseAction('formulas: FETCH_FORMULASS')<FetchFormulasPayload, FetchFormulasResponse, any>()
export const FETCH_FORMULA  = createPromiseAction('formulas: FETCH_FORMULA')<FetchFormulaPayload, FetchFormulaResponse, any>()
export const CREATE_FORMULA = createPromiseAction('formulas: CREATE_FORMULAS')<CreateFormulaPayload, FetchFormulaResponse, any>()
export const UPDATE_FORMULA = createPromiseAction('formulas: UPDATE_FORMULAS')<UpdateFormulaPayload, FetchFormulaResponse, any>()
export const DELETE_FORMULA = createPromiseAction('formulas: DELETE_FORMULAS')<DeleteFormulaPayload, AxiosResponse, any>()