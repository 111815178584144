/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DefaultOptionType, ScheduleAttributes, ScheduleStatusEnum, colors, scheduleKindOptions, scheduleStatusOptions } from '../../utils/constants';
import { makeStyles } from '@mui/styles';
import {  parseText } from '../../utils/functions';
import { Circle } from '@mui/icons-material';
import { format } from 'date-fns';
import { Tooltip } from '@mui/material';
import { orderBy } from 'lodash';

const useStyles = makeStyles(() => ({
  table: {
    '& tbody > tr:nth-child(even)': {
      backgroundColor: `${colors.darkGrayBlue} !important`,
    },
    '& tbody > tr:nth-child(odd)': {
      backgroundColor: `${colors.grayBlue} !important`,
    },
    '& .include': {
      display: 'flex',
      marginLeft: 'auto',
      marginBottom: '1rem',
    },
    '& tfoot': {
      background: 'white',
      '& td': {
        fontWeight: 'bold',
        fontSize: '1rem',
      },
      '& td:last-of-type': {
        color: colors.blue,
      },
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `5px solid ${colors.lightBlue}`,
      },
      '& th': {
        color: colors.darkGrayBlue,
      },
      '& td': {
        color: colors.darkBlue,
        '& svg': {
          color: colors.darkGrayBlue,
          cursor: 'pointer',
        },
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none',
      },
    },
  },
}));

const ScheduleActions = () => {
  return (
    <>
    </>
  );
};

const SchedulesTableBody = ({
  schedules,
}: {
  schedules: ScheduleAttributes[];
}) => {
  return (
    <TableBody>
      {orderBy(schedules, (schedule) => new Date(schedule.schedule_date), 'asc').map((schedule) => {
        const status = scheduleStatusOptions.find(option => option.value === schedule.status) as DefaultOptionType
        const statusLabel = status?.label
        const statusColorStyle = { color: status.value === ScheduleStatusEnum.PENDING ? '#f5c542' : status.value === ScheduleStatusEnum.SUCCESSFUL ? colors.green : colors.lightRed };
        const kindLabel = scheduleKindOptions.find(option => option.value === schedule.kind)?.label
        const scheduleDate = schedule.schedule_date && format(new Date(schedule.schedule_date), 'dd/MM/yyyy');

        return (
          <React.Fragment key={schedule.id}>
            <TableRow>
              <TableCell align='center'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Circle style={statusColorStyle} />
                  <span style={statusColorStyle}>{statusLabel}</span>
                </div>
              </TableCell>
              <TableCell align='center'>{kindLabel}</TableCell>
              <TableCell align='center'>{scheduleDate}</TableCell>
              <TableCell align='center'>
                <Tooltip title={schedule.description}>
                  <span>{parseText(schedule.description, 20)}</span>
                </Tooltip>
              </TableCell>
              <TableCell align='center'>
                <ScheduleActions
                />
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </TableBody>
  );
};

const SchedulesTable = ({
  schedules
}: {
  schedules: ScheduleAttributes[];
}) => {
  const classes = useStyles();
  return (
    <Table className={classes.table} size='small' aria-label='payment-simulation'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Status</TableCell>
          <TableCell align='center'>Tipo</TableCell>
          <TableCell align='center'>Data do agendamento</TableCell>
          <TableCell align='center'>Descrição</TableCell>
        </TableRow>
      </TableHead>
      <SchedulesTableBody
        schedules={schedules}
      />
    </Table>
  );
};

export default SchedulesTable;
