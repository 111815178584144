import React from 'react'
import { useMediaQuery } from 'react-responsive'

import Drawer from '@mui/material/Drawer'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import MenuIcon from '@mui/icons-material/Menu'

import Sidebar from '../sidebar/Sidebar'
import { Container, ChildrenContainer } from './styles/DashboardTemplate.style'
import { LoadingBackdrop } from '../modal/Modal'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/configureStore'

const DashboardTemplate = ({ children }: { children: React.ReactNode }) => {
  const [drawer, setDrawer] = React.useState(false)
  const {
    ui: { loading: { open } },
  } = useSelector((state: RootState) => state)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const closeDrawer = () => {
    setDrawer(false)
  }
  return (
    <Container>
      {isTabletOrMobile && (
                <>
                    <div
                      onClick={() => {
                        setDrawer(true)
                      }}
                      style={{
                        position: 'absolute',
                        top: '0.5rem',
                        left: '0.5rem',
                        zIndex: 10
                      }}
                    >
                      <ArrowForwardIosIcon />
                      <MenuIcon />
                    </div>

                    <Drawer onClose={closeDrawer} anchor='left' open={drawer}>
                      <Sidebar closeDrawer={closeDrawer} />
                    </Drawer>
                </>
      )}
      <div
        style={{
          display: 'flex',
          height: '100%'
        }}
      >
        {!isTabletOrMobile && <Sidebar dashboard />}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'auto'
          }}
        >
          <LoadingBackdrop open={open} somaIcon />
          <ChildrenContainer>{children}</ChildrenContainer>
        </div>
      </div>
    </Container>
  )
}

export default DashboardTemplate
