import React from 'react'
import ProductFamiliesFormContainer from '../containers/ProductFamiliesFormContainer'
import navigationHoc from '../components/hoc/navigationHoc'

const ProductFamiliesFormPage = () => {
  return(
    <ProductFamiliesFormContainer />
  )
}

export default navigationHoc(ProductFamiliesFormPage)