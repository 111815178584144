/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { InvoiceChargeStatusTypes, InvoiceFormAttributes, InvoiceKindTypes, InvoicePaymentOptionTypes, InvoiceStatusTypes, JsonFormat, JsonResponseFormat, PaymentServiceEnum, WalletCreditAttributes, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'
import { BillingJson } from '../billings'
import { InvoiceItemJson } from '../invoice_items'
import { AccountJson } from '../accounts'
import { UserJson } from '../users'
import { RepresentativeJson } from '../representatives'
import { RegistrationJson } from '../registrations'
import { ContractJson } from '../contracts'
import { ProductJson } from '../products'
import { WalletCreditJson } from '../wallet_credits'
import { WalletJson } from '../wallets'
import { CompanyConfigParams, CompanyJson } from '../companies'
import { InvoiceRenegotiationJson, RenegotiationJson } from '../renegotiations'
import { ScheduleJson } from '../schedules'
import { TransactionJson } from '../transactions'

export type QrCodeParams = {
  encoded_image: string
  expiration_date: string
  payload: string
}

export interface InvoiceAttrs {
  accumulated_fines: {
    accumulated_fees: number
    accumulated_penalty: number
  }
  anticipation_discount: number
  charge_status: InvoiceChargeStatusTypes,
  charge: number
  child_ids: number[]
  company_name: string
  company_config: CompanyConfigParams
  code: string
  conditional_discount_charge: number
  contractual_addition: number
  created_at: string
  description: string|null
  effective_anticipation_discount: number
  effective_conditional_discount: number
  effective_liquid: number
  effective_fees: number
  expected_liquid: number
  expiration_date: string
  fees: number
  installment_addition: number
  installment_number: string
  invoice_description: string
  invoice_renegotiation_id: number|null
  invoice_pdf_url: string|null
  invoice_pdf_attachment_id: number|null
  kind: InvoiceKindTypes
  paid_at: string
  parent_id: number|null
  payment_method: InvoicePaymentOptionTypes
  payment_setting_id: number
  payment_service: PaymentServiceEnum
  penalty: number
  qr_code_url: string
  qr_code_attachment_id: number
  punctuality_expiration_date: string
  registration_id: number
  renegotiation_allowance: number
  renegotiation_id: number|null
  representative_name: string
  representative_wallet_id: number;
  representative_id: number
  retained: number
  refunded: number
  service_params: {
    asaas_bank_slip_url: string
    asaas_invoice_number: string
    asaas_payment_id: string
    qr_code: QrCodeParams
    sicoob_number: number
    sicoob_pdf: number
    webhook_cals: object[]
  } | null
  status: InvoiceStatusTypes
  student: string
  wallet_credits: WalletCreditAttributes[]
  wallet_charge: number
}

export type InvoiceJson = JsonFormat<'invoices', InvoiceAttrs>

export type FetchInvoicesResponse = JsonResponseFormat<InvoiceJson[], (
  InvoiceItemJson|BillingJson|AccountJson|UserJson|RepresentativeJson
  |RegistrationJson|ContractJson|ProductJson|WalletJson|WalletCreditJson|CompanyJson)[]>
export type FetchInvoiceResponse = JsonResponseFormat<InvoiceJson, (
  InvoiceItemJson|BillingJson|AccountJson|UserJson|RepresentativeJson
  |RegistrationJson|ContractJson|ProductJson|WalletJson|WalletCreditJson|CompanyJson
  |RenegotiationJson|InvoiceRenegotiationJson|InvoiceJson|ScheduleJson|TransactionJson
  |WalletJson)[]>

export type FetchInvoicesPayload = WithRequestParams
  export interface FetchInvoicePayload extends WithRequestParams {
  id: string | number,
}

export interface CreateInvoicePayload {
  data: Partial<Omit<InvoiceFormAttributes, 'id'>>
}

export interface UpdateInvoicePayload {
  id: string
  data: Partial<Omit<InvoiceFormAttributes, 'id'>>
}

export interface CreateInvoiceChargePayload {
  id: string
}

export type GenerateInvoiceReceipts = WithRequestParams

export type DeleteInvoiceChargePayload = CreateInvoiceChargePayload

export type SendStraightNotificationPayload = CreateInvoiceChargePayload

export type RefreshInvoiceChargePayload = CreateInvoiceChargePayload

export type UnlinkInvoiceChargePayload = CreateInvoiceChargePayload

export type FetchInvoiceChargeQRCodePayload = CreateInvoiceChargePayload


export const FETCH_INVOICES = createPromiseAction('invoices: FETCH_INVOICES')<FetchInvoicesPayload, FetchInvoicesResponse, any>()
export const FETCH_INVOICE = createPromiseAction('invoices: FETCH_INVOICE')<FetchInvoicePayload, FetchInvoiceResponse, any>()
export const CREATE_INVOICE = createPromiseAction('invoices: CREATE_INVOICE')<CreateInvoicePayload, FetchInvoiceResponse, any>()
export const UPDATE_INVOICE = createPromiseAction('invoices: UPDATE_INVOICE')<UpdateInvoicePayload, FetchInvoiceResponse, any>()
export const CREATE_INVOICE_CHARGE = createPromiseAction('invoices: CREATE_INVOICE_CHARGE')<CreateInvoiceChargePayload, AxiosResponse, any>()
export const DELETE_INVOICE_CHARGE = createPromiseAction('invoices: DELETE_INVOICE_CHARGE')<DeleteInvoiceChargePayload, AxiosResponse, any>()
export const SEND_STRAIGHT_NOTIFICATION = createPromiseAction('invoices: SEND_STRAIGHT_NOTIFICATION')<SendStraightNotificationPayload, AxiosResponse, any>()
export const REFRESH_INVOICE_CHARGE = createPromiseAction('invoices: REFRESH_INVOICE_CHARGE')<RefreshInvoiceChargePayload, FetchInvoiceResponse, any>()
export const FETCH_INVOICE_CHARGE_QR_CODE = createPromiseAction('invoices: FETCH_INVOICE_CHARGE_QR_CODE')<FetchInvoiceChargeQRCodePayload, FetchInvoiceResponse, any>()
export const UNLINK_INVOICE_CHARGE = createPromiseAction('invoices: UNLINK_INVOICE_CHARGE')<UnlinkInvoiceChargePayload, FetchInvoiceResponse, any>()
export const GENERATE_INVOICE_RECEIPTS = createPromiseAction('invoices: GENERATE_INVOICE_RECEIPTS')<GenerateInvoiceReceipts, any, any>()


