import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const contractSettingsEndpoint = '/contract_settings'

const multipartInstance = http
multipartInstance.defaults.headers.common['Content-Type'] =
    'multipart/form-data; boundary=----------XnJLe9ZIbbGUYtzPQJ16u1'

export const fetchContractSettings = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${contractSettingsEndpoint}?${queryParams}`)
}
export const fetchContractSetting = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${contractSettingsEndpoint}/${id}?${queryParams}`)
}

export const createContractSetting = (data: FormData | object) => {
  const instance = typeof data === 'object' ? http : multipartInstance
  return instance.post(`${contractSettingsEndpoint}`, data)
}

export const updateContractSetting = ({ data, id }: { data: FormData | object, id: string|number }) => {
  const instance = typeof data === 'object' ? http : multipartInstance
  return instance.put(`${contractSettingsEndpoint}/${id}`, data)
}