import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const pendingSignaturesEndpoint = '/pending_signatures'

export const fetchPendingSignatures = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${pendingSignaturesEndpoint}?${queryParams}`)
}

export const fetchPendingSignature = ({ params, id }: { params?: RequestParams, id: string|number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${pendingSignaturesEndpoint}/${id}?${queryParams}`)
}
