/* eslint-disable camelcase */
import { filter, find, includes, reduce, map, isEmpty } from "lodash"
import { PaymentOptionPortionsEnum, PaymentSimulationBillings, PaymentSimulationPayment, PaymentSimulationPaymentInfo, PaymentSimulationPaymentOption, ProductStepRegistrationProduct, ProductTypesEnum, RegistrationClosureAttributes, RegistrationProductAttributes, semiAnualPortionsConfig } from "./constants"
import { ptBR } from 'date-fns/locale'
import { parse, isSameYear, isSameMonth } from 'date-fns'
import { calculateDiscount, dateRange, formatToCurrency, getMonthInteger, toFixedNumber } from "./functions"

export const getInitialProductPunctualityDiscountPortions = ({ portions, provision_months, advance_discount_value }: { portions: number, provision_months: number[], advance_discount_value: number }) => {
  let punctualityDiscountPortionsAttrs = new Array(portions).fill(null).map((_, index) => index + 1)
  if (portions > 2) {
    punctualityDiscountPortionsAttrs = provision_months
  }
  const registrationFeeHasDiscount = advance_discount_value
  if (registrationFeeHasDiscount && includes(provision_months, 0) && !includes(punctualityDiscountPortionsAttrs, 0)) {
    punctualityDiscountPortionsAttrs.push(0)
  } else if (!registrationFeeHasDiscount && includes(punctualityDiscountPortionsAttrs, 0)) {
    punctualityDiscountPortionsAttrs = filter(punctualityDiscountPortionsAttrs, punc => punc !== 0)
  }

  return punctualityDiscountPortionsAttrs
}

export const getMonthAndYearFromBilling = (billing: PaymentSimulationBillings) => {
  const billing_month = billing.month as string
  const splitted = billing_month.split('-')
  const month = ~~splitted[0]
  const secondSplitted = splitted[1].split('/')
  const year = ~~`20${secondSplitted[1]}`
  return { month, year }
}

export const isPortionNullifiedByClosure = ({ billing, closure }: { billing: PaymentSimulationBillings, closure?: RegistrationClosureAttributes }) => {
  const closure_date = closure?.closure_date
  const { month, year } = getMonthAndYearFromBilling(billing)
  const billingDate = new Date(year, month - 1)
  return closure && closure_date && billingDate.getTime() > new Date(closure_date).getTime()
}

export const calculateProvisionMonths = ({ starts_at, ends_at, registration_fee, current_date, product_kind }: { starts_at: string, ends_at: string, registration_fee: number, current_date: Date, product_kind?: ProductTypesEnum }) => {
  const range = dateRange(starts_at, ends_at)
  const newProvisionMonths = range.reduce<number[]>((ac, item) => {
    const parsedItem = parse(item, 'LLL/yyyy', new Date(), { locale: ptBR })
    const month = getMonthInteger(item)
    const check = product_kind === ProductTypesEnum.OTHER || parsedItem >= current_date || (isSameYear(parsedItem, current_date) && isSameMonth(parsedItem, current_date))
    return check ? ac.concat(month) : ac
  }, [])
  if (registration_fee) {
    newProvisionMonths.unshift(0)
  }
  return newProvisionMonths
}

export const getPaymentSimulationPortions = ({ payment_option_portions, provision_months }: { payment_option_portions: PaymentOptionPortionsEnum | number, provision_months: number[] }) => {
  if(typeof payment_option_portions === 'number'){
    return payment_option_portions > 2 ? provision_months.length : payment_option_portions
  }
  return payment_option_portions === PaymentOptionPortionsEnum.MONTHLY ? provision_months.length : payment_option_portions === PaymentOptionPortionsEnum.SEMI_ANUALY ? 2 : 1
}

const calculateSemiAnualPortions = (
  {
    billings, portion, payment_option, punctuality_discount_portions, closure
  }: {
    closure?: RegistrationClosureAttributes,
    billings: PaymentSimulationBillings[],
    portion: number,
    payment_option: PaymentSimulationPaymentOption,
    punctuality_discount_portions: number[]
  }) => {
  const portionConfig = find(semiAnualPortionsConfig, item => item.portion === portion)
  if (portionConfig) {
    const billingToMonths = filter(billings, billing => {
      const { month } = getMonthAndYearFromBilling(billing)
      return includes(portionConfig.months, month)
    })
    return reduce(billingToMonths, (ac, billing) => {
      if (isPortionNullifiedByClosure({ billing, closure })) {
        return ac
      }
      const { month } = getMonthAndYearFromBilling(billing)
      const hasFinancialDiscount = includes(punctuality_discount_portions, month)
      const preValue = billing.total.final
      const tableValue = billing.total.full
      const {
        portion_discount_kind,
        portion_discount_value,
        punctuality_discount_kind,
        punctuality_discount_value
      } = payment_option

      const portionDiscount = calculateDiscount(portion_discount_kind, portion_discount_value, preValue)
      const puncDiscount = calculateDiscount(punctuality_discount_kind, punctuality_discount_value, preValue)
      const discount = hasFinancialDiscount ? portionDiscount + puncDiscount : 0
      return { tableValue: tableValue + ac.tableValue, full: ac.full + preValue, discount: ac.discount + Number(discount.toFixed(2)), month: ac.month.concat(`${billing.month}+`) }
    }, { full: 0, discount: 0, month: '', tableValue: 0 })
  }
  else return { full: 0, discount: 0, month: '', tableValue: 0 }
}

const calculateAnualPortions = ({
  billings, payment_option, punctuality_discount_portions, closure
}: {
  billings: PaymentSimulationBillings[],
  payment_option: PaymentSimulationPaymentOption,
  punctuality_discount_portions: number[]
  closure?: RegistrationClosureAttributes
}) => {
  return reduce(billings, (ac, billing) => {
    if (isPortionNullifiedByClosure({ billing, closure })) {
      return ac
    }
    const { month } = getMonthAndYearFromBilling(billing)
    const hasFinancialDiscount = includes(punctuality_discount_portions, month)
    const preValue = billing.total.final
    const tableValue = billing.total.full
    const {
      portion_discount_kind,
      portion_discount_value,
      punctuality_discount_kind,
      punctuality_discount_value
    } = payment_option

    const portionDiscount = calculateDiscount(portion_discount_kind, portion_discount_value, preValue)
    const puncDiscount = calculateDiscount(punctuality_discount_kind, punctuality_discount_value, preValue)
    const discount = hasFinancialDiscount ? portionDiscount + puncDiscount : 0
    return { tableValue: ac.tableValue + tableValue, full: ac.full + preValue, discount: ac.discount + Number(discount.toFixed(2)), month: ac.month.concat(`${billing.month}+`) }
  }, { full: 0, discount: 0, month: '', tableValue: 0 })
}

const calculateMonthlyPortion = ({ billing, payment_option, closure }: { billing: PaymentSimulationBillings, payment_option: PaymentSimulationPaymentOption, closure?: RegistrationClosureAttributes }) => {
  if (isPortionNullifiedByClosure({ billing, closure })) {
    return {
      full: 0,
      discount: 0,
      month: billing.month,
      tableValue: 0
    }
  }
  const hasFinancialDiscount = billing.punctuality_included
  const tableValue = billing.total.full
  const preValue = billing.total.final
  const {
    portion_discount_kind,
    portion_discount_value,
    punctuality_discount_kind,
    punctuality_discount_value
  } = payment_option

  const portionDiscount = calculateDiscount(portion_discount_kind, portion_discount_value, preValue)
  const puncDiscount = calculateDiscount(punctuality_discount_kind, punctuality_discount_value, preValue)
  const discount = hasFinancialDiscount ? portionDiscount + puncDiscount : 0
  return { tableValue, full: preValue, discount: Number(discount.toFixed(2)), month: billing.month }
}

export const getRegistrationFee = (billings: PaymentSimulationBillings[]) => find(billings, item => item.kind === 'registration_fee')


export const getRegistrationFeePayment = (payment_option: PaymentSimulationPaymentOption, billing: PaymentSimulationBillings) => {
  const initialValue = billing.total.final
  const tableValue = billing.total.full
  const financial_discount_value = billing.financial_discount
  const final_financial_discount = billing.total.final_financial
  const hasFinancialDiscount = billing.punctuality_included
  let total = 0
  let financialDiscountValue = 0
  if (!isEmpty(final_financial_discount) && !isEmpty(financial_discount_value)) {
    financialDiscountValue = hasFinancialDiscount ? financial_discount_value : 0
    total = final_financial_discount
  } else {
    financialDiscountValue = hasFinancialDiscount ? calculateDiscount(payment_option.advance_discount_kind, payment_option.advance_discount_value, initialValue) : 0
    total = initialValue - financialDiscountValue
  }
  const registrationFeePaymentData = {
    registrationFeeInitialValue: initialValue,
    registrationFeeDiscountValue: financialDiscountValue,
    registrationFeeTotal: total,
    registrationFeeTableValue: tableValue
  }
  const registrationFeePaymentInfo = {
    code: 'Entrada',
    initialValue: formatToCurrency(initialValue),
    discountValue: formatToCurrency(financialDiscountValue),
    total: formatToCurrency(total),
    tableValue: formatToCurrency(tableValue)
  }
  return ({ registrationFeePaymentData, registrationFeePaymentInfo })
}

export const getPortionPayment = ({ payment, billing, punctuality_discount_portions, portion, closure }: { closure?: RegistrationClosureAttributes, payment: PaymentSimulationPayment, billing: PaymentSimulationBillings, punctuality_discount_portions: number[], portion: number }) => {
  const { payment_option, billings } = payment
  const portionBillings = filter(billings, item => item.kind === 'portion')
  let isMonthly = payment_option.portions === PaymentOptionPortionsEnum.MONTHLY
  let isSemiAnual = payment_option.portions === PaymentOptionPortionsEnum.SEMI_ANUALY
  let isAnual = payment_option.portions === PaymentOptionPortionsEnum.YEARLY
  if(typeof payment_option.portions === 'number') {
    const provision_months = map(portionBillings, item => item.portion as number)
    const portions = getPaymentSimulationPortions({ payment_option_portions: payment_option.portions, provision_months })
    isMonthly = ((portions <= 2 && payment_option.portions > 2) || portions > 2)
    isSemiAnual = !isMonthly && portions === 2
    isAnual = !isMonthly && !isSemiAnual && portions === 1
  }
  const { full, discount, month = '', tableValue } = isMonthly ? calculateMonthlyPortion({ billing, payment_option, closure }) :
    isSemiAnual ? calculateSemiAnualPortions({ closure: closure, billings: portionBillings, portion, payment_option, punctuality_discount_portions }) :
      isAnual ? calculateAnualPortions({ closure, billings: portionBillings, payment_option, punctuality_discount_portions }) : { full: 0, discount: 0, tableValue: 0 }
  const total = full - discount
  const portionPaymentData = {
    portionsTableValue: tableValue,
    portionInitialValue: full,
    portionDiscountValue: discount,
    portionTotal: total,
    month
  }
  const portionPaymentInfo = {
    tableValue: formatToCurrency(tableValue),
    initialValue: formatToCurrency(full),
    discountValue: formatToCurrency(discount),
    total: formatToCurrency(total),
    month
  }
  return ({
    portionPaymentData,
    portionPaymentInfo
  })
}

export const getBillingsSimulationData = ({ payment, punctuality_discount_portions, closure, simulation_id }: { payment: PaymentSimulationPayment, punctuality_discount_portions: number[], closure?: RegistrationClosureAttributes, simulation_id: string }) => {
  const { billings, payment_option } = payment
  let initialValue = 0
  let discountValue = 0
  let total = 0
  let tableValue = 0
  const paymentInfo: PaymentSimulationPaymentInfo[] = []
  const registrationFeePortion = getRegistrationFee(billings)
  const portionBillings = filter(billings, item => item.kind === 'portion')
  const provision_months = map(portionBillings, item => item.portion as number)
  const portions = getPaymentSimulationPortions({ payment_option_portions: payment_option.portions, provision_months })
  if (registrationFeePortion) {
    const {
      registrationFeePaymentData: {
        registrationFeeTotal,
        registrationFeeDiscountValue,
        registrationFeeInitialValue,
        registrationFeeTableValue
      },
      registrationFeePaymentInfo
    } = getRegistrationFeePayment(payment_option, registrationFeePortion)
    initialValue = initialValue + registrationFeeInitialValue
    discountValue = discountValue + registrationFeeDiscountValue
    total = total + registrationFeeTotal
    tableValue = tableValue + registrationFeeTableValue

    paymentInfo.push(registrationFeePaymentInfo)
  }
  Array(portions).fill(undefined).forEach((_, index) => {
    const billing = billings.filter(item => item.kind !== 'registration_fee')[index]
    if (!billing) {
      return
    }
    const {
      portionPaymentData: {
        portionInitialValue,
        portionDiscountValue,
        portionTotal,
        portionsTableValue
      },
      portionPaymentInfo
    } = getPortionPayment({ payment, billing, punctuality_discount_portions, portion: index + 1, closure })
    initialValue = initialValue + portionInitialValue
    discountValue = discountValue + portionDiscountValue
    tableValue = tableValue + portionsTableValue
    total = total + portionTotal
    const code = `Parcela ${index + 1}`
    paymentInfo.push({ ...portionPaymentInfo, code })
  })

  const { total: { full, final } } = payment
  const discount = full - final
  const newData = {
    id: ~~simulation_id,
    value: full,
    discount: discount,
    subtotal: final,
    paymentInfo,
    result: {
      tableValue,
      initialValue,
      discountValue,
      total
    },
  }
  const newParsedData = {
    ...newData,
    parsedResult: {
      tableValue: formatToCurrency(tableValue),
      initialValue: formatToCurrency(initialValue),
      discountValue: formatToCurrency(discountValue),
      total: formatToCurrency(total)
    },
    value: formatToCurrency(newData.value),
    discount: formatToCurrency(newData.discount),
    subtotal: formatToCurrency(newData.subtotal),
  }
  return newParsedData


}

export const selectProductPaymentSimulation = ({ product, payment_option_portions, provision_months }: { product: { registration_fee: number, price: number }, payment_option_portions: PaymentOptionPortionsEnum, provision_months: number[] }) => {
  const registrationFee = product.registration_fee || 0
  const provisionMonthsWithoutRegFee = provision_months.filter(item => item !== 0)
  const portions = getPaymentSimulationPortions({ payment_option_portions, provision_months: provisionMonthsWithoutRegFee })
  const provisionValue = product.price
  const totalProvisionsValue = provisionValue * provisionMonthsWithoutRegFee.length
  const total = totalProvisionsValue + registrationFee
  const portionsValue = (totalProvisionsValue) / portions
  const parsedRegistrationFee = formatToCurrency(registrationFee)
  const parsedTotal = formatToCurrency(total)
  const parsedPortionsValue = `${formatToCurrency(toFixedNumber(portionsValue, 2))}`
  const description = registrationFee > 0 ?
    `Entrada + ${portions} parcela(s)` : `${portions} parcela(s)`

  return {
    registrationFee,
    portionsValue,
    total,
    parsedRegistrationFee,
    parsedPortionsValue,
    parsedTotal,
    description
  }
}

export const getResults = (registration_products: ProductStepRegistrationProduct[]): PaymentSimulationPaymentInfo => {
  const data = registration_products.reduce((ac, item) => {
    const { payment_simulation: { result: { tableValue, initialValue, discountValue, total } } } = item
    const resultsInitialValues = ac.initialValue + initialValue
    const resultsDiscountValue = ac.discountValue + discountValue
    const resultsTotal = ac.total + total
    const tableValueTotal = ac.tableValue + tableValue
    const results = {
      tableValue: tableValueTotal,
      initialValue: resultsInitialValues,
      discountValue: resultsDiscountValue,
      total: resultsTotal
    }

    return results
  }, { tableValue: 0, initialValue: 0, discountValue: 0, total: 0 })
  const parsedResults = {
    tableValue: formatToCurrency(data.tableValue),
    initialValue: formatToCurrency(data.initialValue),
    discountValue: formatToCurrency(data.discountValue),
    total: formatToCurrency(data.total)
  }
  return parsedResults
}


export const paymentSimulationFactory = (
  {
    registration_product,
    payment,
    closure
  }: {
    registration_product: RegistrationProductAttributes,
    payment: PaymentSimulationPayment,
    closure?: RegistrationClosureAttributes
  }
) => {
  try {
    const punctuality_discount_portions = payment.billings.filter(billing => billing.punctuality_included).map(billing => {
      if (billing.kind === 'registration_fee') {
        return 0
      } else {
        const { month } = getMonthAndYearFromBilling(billing)
        return month
      }
    })
    return getBillingsSimulationData({ payment, punctuality_discount_portions, closure, simulation_id: registration_product.id })

  } catch (error) {
    throw Error('Erro no carregamento da simulação de pagamento')
  }
}
