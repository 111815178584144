import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_CLASS_TIME, CreateClassTimePayload, DELETE_CLASS_TIME, DeleteClassTimePayload, FETCH_CLASS_TIME, FETCH_CLASS_TIMES, FetchClassTimePayload, FetchClassTimesPayload, UPDATE_CLASS_TIME, UpdateClassTimePayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchClassTimes (action: DefaultAction<FetchClassTimesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchClassTimes, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CLASS_TIMES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CLASS_TIMES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchClassTime (action: DefaultAction<FetchClassTimePayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchClassTime, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CLASS_TIME.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CLASS_TIME.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createClassTime (action: DefaultAction<CreateClassTimePayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'class_times',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createClassTime, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_CLASS_TIME.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_CLASS_TIME.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateClassTime (action: DefaultAction<UpdateClassTimePayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'class_times',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateClassTime, {data: params, id})
    yield put(UPDATE_CLASS_TIME.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_CLASS_TIME.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteClassTime (action: DefaultAction<DeleteClassTimePayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteClassTime, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_CLASS_TIME.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_CLASS_TIME.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchClassTimesSagas () {
  yield takeLatest(FETCH_CLASS_TIMES.request, fetchClassTimes)
  yield takeLatest(FETCH_CLASS_TIME.request, fetchClassTime)
  yield takeLatest(CREATE_CLASS_TIME.request, createClassTime)
  yield takeLatest(UPDATE_CLASS_TIME.request, updateClassTime)
  yield takeLatest(DELETE_CLASS_TIME.request, deleteClassTime)
}

export default function * classTimesSagas () {
  yield all([watchClassTimesSagas()])
}
