import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
import http from '../../utils/http'

const registrationClosureStatusEndpoint = '/registration_closure_statuses'

export const fetchRegistrationClosureStatuses = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${registrationClosureStatusEndpoint}?${queryParams}`)
}
