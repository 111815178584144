/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'

export interface CalendarEventAttrs {
  "company_id": number
  "name": string
  "starts_at": string
  "ends_at": string
  "allow_class": boolean
  company_name: string
}
export type CalendarEventJson = JsonFormat<'calendar_events', CalendarEventAttrs>

export type FetchCalendarEventsResponse = JsonResponseFormat<CalendarEventJson[]>
export type FetchCalendarEventResponse = JsonResponseFormat<CalendarEventJson>
export type FetchCalendarEventsPayload = WithRequestParams
export interface FetchCalendarEventPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateCalendarEventPayload {
  data: object
}

export interface UpdateCalendarEventPayload extends CreateCalendarEventPayload {
  id: string
}

export type DeleteCalendarEventPayload = FetchCalendarEventPayload

export const FETCH_CALENDAR_EVENTS = createPromiseAction('classrooms: FETCH_CALENDAR_EVENTS')<FetchCalendarEventsPayload, FetchCalendarEventsResponse, any>()
export const FETCH_CALENDAR_EVENT = createPromiseAction('classrooms: FETCH_CALENDAR_EVENT')<FetchCalendarEventPayload, FetchCalendarEventResponse, any>()
export const CREATE_CALENDAR_EVENT = createPromiseAction('classrooms: CREATE_CALENDAR_EVENT')<CreateCalendarEventPayload, FetchCalendarEventResponse, any>()
export const UPDATE_CALENDAR_EVENT = createPromiseAction('classrooms: UPDATE_CALENDAR_EVENT')<UpdateCalendarEventPayload, FetchCalendarEventResponse, any>()
export const DELETE_CALENDAR_EVENT = createPromiseAction('classrooms: DELETE_CALENDAR_EVENT')<DeleteCalendarEventPayload, AxiosResponse, any>()
