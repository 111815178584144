import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_EXAM_PLACEMENTS,
  FetchExamPlacementsPayload,
  FetchExamPlacementPayload,
  FETCH_EXAM_PLACEMENT,
  CREATE_EXAM_PLACEMENT,
  CreateExamPlacementPayload,
  UpdateExamPlacementPayload,
  UPDATE_EXAM_PLACEMENT,
  DeleteExamPlacementPayload,
  DELETE_EXAM_PLACEMENT
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchExamPlacements (action: DefaultAction<FetchExamPlacementsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchExamPlacements, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_EXAM_PLACEMENTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_EXAM_PLACEMENTS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchExamPlacement (action: DefaultAction<FetchExamPlacementPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchExamPlacement, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_EXAM_PLACEMENT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_EXAM_PLACEMENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createExamPlacement (action: DefaultAction<CreateExamPlacementPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'exam_placements',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createExamPlacement, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_EXAM_PLACEMENT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_EXAM_PLACEMENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateExamPlacement (action: DefaultAction<UpdateExamPlacementPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'exam_placements',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateExamPlacement, {data: params, id})
    yield put(UPDATE_EXAM_PLACEMENT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_EXAM_PLACEMENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteExamPlacement (action: DefaultAction<DeleteExamPlacementPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteExamPlacement, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_EXAM_PLACEMENT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_EXAM_PLACEMENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchExamPlacementsSagas () {
  yield takeLatest(FETCH_EXAM_PLACEMENTS.request, fetchExamPlacements)
  yield takeLatest(FETCH_EXAM_PLACEMENT.request, fetchExamPlacement)
  yield takeLatest(CREATE_EXAM_PLACEMENT.request, createExamPlacement)
  yield takeLatest(UPDATE_EXAM_PLACEMENT.request, updateExamPlacement)
  yield takeLatest(DELETE_EXAM_PLACEMENT.request, deleteExamPlacement)
}

export default function * examPlacementsSagas () {
  yield all([watchExamPlacementsSagas()])
}
