import axios from 'axios';

const { REACT_APP_SERVER_ENV } = process.env;

const currentApi = REACT_APP_SERVER_ENV;
interface Hosts {
    [key: string]: string;
}

const backendHosts: Hosts = {
    production: 'https://api.plataformasoma.net/',
    staging: 'https://stag-api.plataformasoma.net/',
    local: 'http://localhost:3000',
};

export const currentHost = currentApi !== undefined ? backendHosts[currentApi] : backendHosts['local'];

const instance = axios.create({
    baseURL: currentHost,
    headers: {
        'Content-Type': 'application/vnd.api+json',
    },
});

export default instance;
