import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  CANCEL_RENEGOTIATION,
  CREATE_RENEGOTIATION, CancelRenegotiationPayload, CreateRenegotiationPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'


export function * createRenegotiation (action: DefaultAction<CreateRenegotiationPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'renegotiations',
        attributes: { ...data },
      }
    }    
    const response = yield call(services.createRenegotiation, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_RENEGOTIATION.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_RENEGOTIATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * cancelRenegotiation(action: DefaultAction<CancelRenegotiationPayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.cancelRenegotiation, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CANCEL_RENEGOTIATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CANCEL_RENEGOTIATION.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * watchRenegotiationSagas () {
  yield takeLatest(CREATE_RENEGOTIATION.request, createRenegotiation)
  yield takeLatest(CANCEL_RENEGOTIATION.request, cancelRenegotiation)
}

export default function * renegotiationSagas () {
  yield all([watchRenegotiationSagas()])
}
