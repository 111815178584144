/* eslint-disable camelcase */
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { isValid } from 'redux-form';
import { RootState } from '../store/configureStore';
import {
  DefaultOptionType,
  InvoiceAttributes,
  InvoiceItemAttributes,
  InvoiceRenegotiationFormAttributes,
  RenegotiationFormAttributes,
} from '../utils/constants';
import { handleRenegotiationFormInitialData } from '../utils/functions';
import { filter, isEmpty, map, pick, uniq } from 'lodash';
import { CREATE_RENEGOTIATION } from '../store/renegotiations';
import RenegotiationForm from '../components/form/RenegotiationForm';
import Loading from '../components/loading/Loading';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { FETCH_INVOICES } from '../store/invoices';
import { InvoiceItemJson } from '../store/invoice_items';


const RenegotiationFormContainer = ({
  handleFetchInvoices,
  handleTabChange,
  selected_invoice_ids,
  setSelectedInvoices,
  resetFilters
}: {
  handleFetchInvoices: () => Promise<void>;
  handleTabChange: (_: any, newValue: string) => void;
  selected_invoice_ids: number[];
  setSelectedInvoices: React.Dispatch<React.SetStateAction<number[]>>;
  resetFilters: () => void
}) => {
  const formName = 'renegotiationForm';
  const [invoice_items, setInvoiceItems] = React.useState<InvoiceItemAttributes[]>([]);
  const [initialValues, setInitialValues] = React.useState<RenegotiationFormAttributes | null>(null);
  const [representativeOptions, setRepresentativeOptions] = React.useState<DefaultOptionType[]>([]);
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const isFormValid = isValid(formName)(state);
  const is_installment_renegotiation = true;

  const setLoading = React.useCallback(async (value: boolean) => {
    await dispatch(UI_SET_LOADING_OPEN(value));
  }, []);

  const initRenegotiationForm = React.useCallback(
    async (args: { invoice_items: InvoiceItemAttributes[]; invoices: InvoiceAttributes[] }) => {
      const { new_initial_values, representative_options } = await handleRenegotiationFormInitialData({
        ...args,
        selected_invoice_ids
      })
      setRepresentativeOptions(representative_options);
      setInitialValues(new_initial_values);
    },
    [initialValues, invoice_items],
  );

  const fetchData = React.useCallback(async () => {
    try {
      const response = await dispatch(
        FETCH_INVOICES.request({
          params: {
            filters: {
              'q[id_in]': uniq(selected_invoice_ids),
              include: ['billings.invoice_items'].join(','),
              'page[size]': '100',
            },
          },
        }),
      );

      const {
        data: { data, included },
      } = response;

      const includedInvoices = map(data, (item) => ({
        id: item.id,
        ...item.attributes,
      }));

      const includedInvoiceItems = map(
        filter(included, (incl) => incl.type === 'invoice_items') as InvoiceItemJson[],
        (item) => ({
          id: item.id,
          ...item.attributes,
        }),
      );

      setInvoiceItems(includedInvoiceItems);
      await initRenegotiationForm({
        invoices: includedInvoices,
        invoice_items: includedInvoiceItems,
      });
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar dados da negociação',
        }),
      );
    }
  }, [selected_invoice_ids]);

  const createRenegotiation = React.useCallback(
    async (data: RenegotiationFormAttributes) => {
      try {
        setLoading(true);
        const formattedData = {
          invoices_attributes: data.invoices_attributes.map((item) => ({
            ...pick(item, ['id', 'status', 'registration_id', 'kind']),
            invoice_items_attributes: item.invoice_items_attributes.map((invoice_item) => ({
              ...pick(invoice_item, ['id', 'active']),
            })),
          })),
          cancel_when_expired: Boolean(data.cancel_when_expired),
          renegotiation_log: {
            invoice_reference: data.invoice_reference,
          },
          invoice_renegotiations_attributes: data.invoice_renegotiations_attributes
            .concat(data.remaining_invoice_renegotiations_attributes as InvoiceRenegotiationFormAttributes[])
            .map((item) => {
              return {
                invoice_attributes: {
                  ...pick(item.invoice_attributes, [
                    'anticipation_discount',
                    'contractual_addition',
                    'description',
                    'expiration_date',
                    'fees',
                    'installment_addition',
                    'installment_number',
                    'kind',
                    'parent_id',
                    'penalty',
                    'punctuality_expiration_date',
                    'registration_id',
                    'renegotiation_allowance',
                    'representative_id',
                  ]),
                  invoice_items_attributes: item.invoice_attributes.invoice_items_attributes.map((invoice_item) => ({
                    ...pick(invoice_item, ['active', 'parent_id', 'billing_id', 'base_values']),
                  })),
                },
              };
            }),
        };

        await dispatch(CREATE_RENEGOTIATION.request({ data: formattedData }));
        dispatch(
          success({
            title: 'Renegociação concluída!',
            message: 'Nova renegociação criada.',
          }),
        );
        await handleFetchInvoices();
        resetFilters()
        setSelectedInvoices([]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao criar nova renegociação',
          }),
        );
        setSelectedInvoices([]);
      }
    },
    [handleFetchInvoices, initialValues],
  );

  const initData = React.useCallback(async () => {
    await fetchData();
  }, []);

  React.useEffect(() => {
    initData();
  }, []);

  const onSubmit = async (data: RenegotiationFormAttributes) => {
    await createRenegotiation(data);
    handleTabChange(null, 'all_invoices');
  };

  if (isEmpty(initialValues)) {
    return <Loading />;
  }
  const title = 'Renegociação';
  return (
    <div>
      <span style={{ fontSize: '1.75rem', marginBottom: '2rem', display: 'block' }}>{title}</span>
      <RenegotiationForm
        handleTabChange={handleTabChange}
        initialValues={initialValues}
        isFormValid={isFormValid}
        onSubmit={onSubmit}
        representativeOptions={representativeOptions}
        is_installment_renegotiation={is_installment_renegotiation}
        setInitialValues={setInitialValues}
        setLoading={setLoading}
      />
    </div>
  );
};

export default RenegotiationFormContainer;
