import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
import http from '../../utils/http'

const registrationClosureEndpoint = '/registration_closures'

export const fetchRegistrationClosure = ({ params, id }: { params: RequestParams | undefined, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${registrationClosureEndpoint}/${id}?${queryParams}`)
}


export const createRegistrationClosure = (data: object) => {
  return http.post(`${registrationClosureEndpoint}`, data)
}

export const updateRegistrationClosure = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${registrationClosureEndpoint}/${id}`, data)
}

export const deleteRegistrationClosure = ({ id }: { id: string | number }) => {
  return http.delete(`${registrationClosureEndpoint}/${id}`)
}
