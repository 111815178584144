import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const accountsEndpoint = '/accounts'
const usersEndpoint = '/users'

const multipartInstance = http
multipartInstance.defaults.headers.common['Content-Type'] =
    'multipart/form-data; boundary=----------XnJLe9ZIbbGUYtzPQJ16u1'


export const fetchAccounts = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${accountsEndpoint}?${queryParams}`)
}

export const fetchUsers = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${usersEndpoint}?${queryParams}`)
}

export const fetchAccount = ({ id, params }: { id: string | number, params?: RequestParams }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${accountsEndpoint}/${id}?${queryParams}`)
}

export const updateAccount = ({ id, params }: { id: string | number, params: object }) => {
  const instance = typeof params === 'object' ? http : multipartInstance

  return instance.put(`${accountsEndpoint}/${id}`, params)
}

export const fetchAccountUser = ({ id }: { id: string | number }) => {
  return http.get(`${usersEndpoint}/${id}?include=companies.profile_dashboards,accounts.profiles,accounts.representatives.registration,teachers,coordinators`)
}

export const createAccounts = (params: object) => {
  const instance = typeof params === 'object' ? http : multipartInstance

  return instance.post(`${accountsEndpoint}`, params)
}
