import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/configureStore';
import { error } from 'react-notification-system-redux';
import {
  InvoiceAttributes,
  InvoiceChargeStatusEnum,
  InvoiceChargeStatusTypes,
  InvoiceStatusEnum,
  InvoiceStatusTypes,
  OrderOptions,
  PaginationType,
  RepresentativeAttributes,
  RepresentativeInvoicePageData,
  TransactionEventEnum,
  defaultPagination,
  invoiceChargeStatusOptions,
  invoiceStatusOptions,
} from '../utils/constants';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { FETCH_INVOICES, FetchInvoicesResponse } from '../store/invoices';
import { isEmpty, map, reduce, uniqBy } from 'lodash';
import RepresentativesInvoiceView from '../components/view/Representative/RepresentativeInvoicesView';
import InvoicesView from '../components/view/Secretary/InvoicesView';
import { get_company_search } from '../utils/functions';
import { CREATE_TRANSACTION } from '../store/transactions';

const default_pagination = { ...defaultPagination, pageSize: 25 };

const InvoicesContainer = (props: { is_representative_view?: boolean }) => {
  const { is_representative_view = true } = props;
  const initialStatusFilterValue = [InvoiceStatusEnum.ACTIVE,InvoiceStatusEnum.FINISHED]
  const defaultStatusFilterValue = !is_representative_view ? initialStatusFilterValue : []
  const defaultChargeStatusFilterValue = !is_representative_view ? [InvoiceChargeStatusEnum.RECEIVED, InvoiceChargeStatusEnum.CONFIRMED, InvoiceChargeStatusEnum.PENDING_PAYMENT, InvoiceChargeStatusEnum.EXPIRED] : []
  const dispatch = useDispatch();
  const [pageData, setPageData] = React.useState<RepresentativeInvoicePageData>();
  const [selectedInvoices, setSelectedInvoices] = React.useState<number[]>([]);
  const [initialPageData, setInitialPageData] = React.useState<RepresentativeInvoicePageData>();
  const [registrationFilterValue, setRegistrationFilterValue] = React.useState('');
  const [codeFilterValue, setCodeFilterValue] = React.useState('')
  const [representativeFilterValue, setRepresentativeFilterValue] = React.useState('');
  const [chargeStatusFilterValue, setChargeStatusFilterValue] = React.useState<InvoiceChargeStatusTypes[]>(defaultChargeStatusFilterValue);
  const [statusFilterValue, setStatusFilterValue] = React.useState<(InvoiceStatusTypes | string)[]>(defaultStatusFilterValue);
  const [fromExpirationDateFilterValue, setFromExpirationDateFilterValue] = React.useState<Date | null>(null);
  const [toExpirationDateFilterValue, setToExpirationDateFilterValue] = React.useState<Date | null>(null);
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');
  const [pagination, setPagination] = React.useState<PaginationType>(default_pagination);
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState<OrderOptions | string>('');

  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, user, company_descendants },
    account: { companies },
  } = state;

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);

  const createUserEvent = async (description: string) => {
    await dispatch(CREATE_TRANSACTION.request({
      data: {
        event: TransactionEventEnum.USER_EVENT,
        transactable_id: user.id,
        transactable_type: 'User',
        event_date: new Date().toISOString(),
        description
      }
    }))
  }

  const transformResponseData = (response: FetchInvoicesResponse) => {
    const {
      data: { data, included },
    } = response;
    const invoices = data.reduce((acc, invoice) => {
      if(is_representative_view && !initialStatusFilterValue.includes(invoice.attributes.status as InvoiceStatusEnum)){
        return acc
      }
      return acc.concat({
      id: invoice.id,
      ...invoice.attributes,
      })
    }, [] as InvoiceAttributes[]);
    const formatted_data = reduce(
      included,
      (acc, incl) => {
        let new_acc = acc;
        const acc_keys = Object.keys(acc);
        acc_keys.forEach((key) => {
          if (key === incl.type) {
            new_acc = {
              ...new_acc,
              [key]: [...new_acc[key as keyof RepresentativeInvoicePageData], { id: incl.id, ...incl.attributes }],
            };
          }
        });
        return new_acc;
      },
      {
        accounts: [],
        users: [],
        billings: [],
        invoice_items: [],
        invoices,
        representatives: [],
        registrations: [],
        renegotiations: [],
        invoice_renegotiations: [],
        contracts: [],
        products: [],
        wallet_credits: [],
        wallets: []
      } as RepresentativeInvoicePageData,
    );
    return formatted_data;
  };

  const fetchInvoicesMethod = async (
    params: {
      registration_filter?: string;
      status_filter_value?: (InvoiceStatusTypes | string)[];
      charge_status_filter?: InvoiceChargeStatusTypes[];
      from_expiration_date_filter?: Date | null;
      to_expiration_date_filter?: Date | null;
      representative_filter?: string;
      company_filter?: string;
      page_number: number;
      page_size: number;
      order_filter?: OrderOptions | string;
      order_by_filter?: string;
      code_filter?: string
    } = {
      registration_filter: registrationFilterValue,
      status_filter_value: statusFilterValue,
      from_expiration_date_filter: fromExpirationDateFilterValue,
      to_expiration_date_filter: toExpirationDateFilterValue,
      charge_status_filter: chargeStatusFilterValue,
      representative_filter: representativeFilterValue,
      company_filter: companyFilterValue,
      page_number: pagination.pageNumber,
      page_size: pagination.pageSize,
      order_filter: order,
      order_by_filter: orderBy,
      code_filter: codeFilterValue
    },
  ) => {
    const {
      page_number = pagination.pageNumber,
      page_size = pagination.pageSize,
      registration_filter = registrationFilterValue,
      representative_filter = representativeFilterValue,
      from_expiration_date_filter = fromExpirationDateFilterValue,
      to_expiration_date_filter = toExpirationDateFilterValue,
      charge_status_filter = chargeStatusFilterValue,
      company_filter = companyFilterValue,
      status_filter_value = statusFilterValue,
      order_filter = order,
      order_by_filter = orderBy,
      code_filter = codeFilterValue
    } = params;
    const companies_array = get_company_search({
      companies,
      company_descendants,
      company_filter,
      current_company_id: company,
    });
    const current_company = companies.find((item: any) => item.id === company);
    const all_companies = company_descendants.concat(current_company)
    const company_names = map(companies_array, id => {
      const company_for_this_id = all_companies.find((item: any) => ~~item.id === ~~id)
      return company_for_this_id?.name
    }).join(',')
    let representative_filter_object = {};
    if (is_representative_view) {
      const representative_ids_for_this_company = map(
        current_company.representatives as RepresentativeAttributes[],
        (item) => item.id,
      );
      representative_filter_object = {
        'q[representative_id_in]': representative_ids_for_this_company,
      };
    } else {
      representative_filter_object = {
        'q[representative_account_user_name_cont]': representative_filter,
      };
    }
    const expiration_date_filter_object = {
      ...(from_expiration_date_filter && {
        'q[expiration_date_gteq]': new Date(from_expiration_date_filter.setHours(0,0,0)).toISOString(),
      }),
      ...(to_expiration_date_filter && {
        'q[expiration_date_lteq]': new Date(to_expiration_date_filter.setHours(23,59,59)).toISOString(),
      }),
    };
    let charge_status_filter_object = {};
    if (charge_status_filter.length) {
      charge_status_filter_object = { 'q[charge_status_in]': charge_status_filter };
    }
    let registration_filter_object = {};
    if (is_representative_view) {
      registration_filter_object = { 'q[registration_id_eq]': registration_filter || '' };
    } else {
      registration_filter_object = { 'q[registration_account_user_name_cont]': registration_filter || '' };
    }
    let order_filter_object = {};
    if (order_filter && order_by_filter) {
      order_filter_object = {
        'q[s]': `${order_by_filter} ${order_filter}`,
      };
    }

    let code_filter_object = {}
    if(code_filter) {
      code_filter_object = {
        'q[code_cont]': code_filter
      }
    }
    const fixed_status_value = is_representative_view && status_filter_value.length
        ? status_filter_value.reduce((acc, filter_value) => {
            if (filter_value.includes(',')) {
              return acc.concat(filter_value.split(','));
            }
            return acc.concat(filter_value);
          }, [] as string[])
      : status_filter_value;
    const status_labels = fixed_status_value.map(status => {
      const status_option = invoiceStatusOptions.find(item => item.value === status)?.label
      return status_option
    })
    const charge_status_labels = charge_status_filter.map(status => {
      const status_option = invoiceChargeStatusOptions.find(item => item.value === status)?.label
      return status_option
    })
    const event_description = [
      ...(!isEmpty(representative_filter) ? [`Nome do responsável: ${representative_filter}`] : []),
      ...(!isEmpty(code_filter) ? [`Código de fatura: ${code_filter}`] : []),
      ...(!isEmpty(status_labels) ? [`Status usados: ${status_labels.join(',')}`] : []),
      ...(!isEmpty(charge_status_labels) ? [`Status de cobrança usados: ${charge_status_labels.join(',')}`] : [])
    ].join(';')
    await createUserEvent(`Busca na tela de cobranças foi realizada. Unidade(s): ${company_names}; Filtros: ${event_description}`)
    const response = await dispatch(
      FETCH_INVOICES.request({
        params: {
          filters: {
            'q[company_id_in]': companies_array,
            'q[status_in]': fixed_status_value || '',
            'page[number]': (page_number + 1).toString(),
            'page[size]': page_size.toString(),
            include:[
              'registration.account.user',
              'representative.account.user',
              ...(is_representative_view ? [
                'billings.invoice_items',
                'registration.contracts.product',
                'wallet_credits.wallet',
                'invoice_renegotiation.renegotiation.invoices',
                'invoice_renegotiation.renegotiation.invoice_renegotiations.invoice',
                'renegotiation.invoices',
                'renegotiation.invoice_renegotiations.invoice'  
              ] : [])
            ].join(','),
            ...expiration_date_filter_object,
            ...charge_status_filter_object,
            ...registration_filter_object,
            ...representative_filter_object,
            ...order_filter_object,
            ...code_filter_object,
          },
        },
      }),
    );

    const formatted_data = transformResponseData(response);
    return { data: formatted_data, meta: response.data.meta };
  };

  const getAllInvoicesData = React.useCallback(async () => {
    const per_page = 10;
    let current_page = 1;
    let total_pages = 0;
    let accumulated_data = {
      accounts: [],
      users: [],
      billings: [],
      invoice_items: [],
      invoices: [],
      representatives: [],
      registrations: [],
      renegotiations: [],
      invoice_renegotiations: [],
      contracts: [],
      products: [],
      wallet_credits: [],
      wallets: []
    } as RepresentativeInvoicePageData;
    while (total_pages === 0 || total_pages >= current_page) {
      const { data, meta } = await fetchInvoicesMethod({
        page_number: current_page,
        page_size: per_page,
      });
      accumulated_data = {
        accounts: uniqBy(accumulated_data.accounts.concat(data.accounts), 'id'),
        users: uniqBy(accumulated_data.users.concat(data.users), 'id'),
        billings: uniqBy(accumulated_data.billings.concat(data.billings), 'id'),
        invoice_items: uniqBy(accumulated_data.invoice_items.concat(data.invoice_items), 'id'),
        invoices: uniqBy(accumulated_data.invoices.concat(data.invoices), 'id'),
        representatives: uniqBy(accumulated_data.representatives.concat(data.representatives), 'id'),
        renegotiations: uniqBy(accumulated_data.renegotiations.concat(data.renegotiations), 'id'),
        invoice_renegotiations: uniqBy(accumulated_data.invoice_renegotiations.concat(data.invoice_renegotiations), 'id'),
        registrations: uniqBy(accumulated_data.registrations.concat(data.registrations), 'id'),
        contracts: uniqBy(accumulated_data.contracts.concat(data.contracts), 'id'),
        products: uniqBy(accumulated_data.products.concat(data.products), 'id'),
        wallet_credits: uniqBy(accumulated_data.wallet_credits.concat(data.wallet_credits), 'id'),
        wallets: uniqBy(accumulated_data.wallets.concat(data.wallets), 'id')
      };
      if ([0, 1].includes(meta.page_count)) {
        break;
      }
      total_pages = meta.page_count;
      current_page = current_page + 1;
    }
    return accumulated_data;
  }, []);

  const loadPayments = React.useCallback(
    async (
      this_props: {
        registration_filter?: string;
        status_filter_value?: (InvoiceStatusTypes | string)[];
        charge_status_filter_value?: InvoiceChargeStatusTypes[];
        from_expiration_date_filter_value?: Date | null;
        to_expiration_date_filter_value?: Date | null;
        representative_filter?: string;
        company_filter?: string;
        page_number?: number;
        page_size?: number;
        order_filter?: OrderOptions;
        order_by_filter?: string;
        code_filter?: string
      } = {
        registration_filter: registrationFilterValue,
        status_filter_value: statusFilterValue,
        from_expiration_date_filter_value: fromExpirationDateFilterValue,
        to_expiration_date_filter_value: toExpirationDateFilterValue,
        charge_status_filter_value: chargeStatusFilterValue,
        representative_filter: representativeFilterValue,
        company_filter: companyFilterValue,
        page_number: pagination.pageNumber,
        page_size: pagination.pageSize,
        code_filter: codeFilterValue
      },
    ) => {
      const {
        registration_filter = registrationFilterValue,
        status_filter_value = statusFilterValue,
        from_expiration_date_filter_value = fromExpirationDateFilterValue,
        to_expiration_date_filter_value = toExpirationDateFilterValue,
        charge_status_filter_value = chargeStatusFilterValue,
        representative_filter = representativeFilterValue,
        company_filter = companyFilterValue,
        page_number = pagination.pageNumber,
        page_size = pagination.pageSize,
        order_filter = order,
        order_by_filter = orderBy,
        code_filter = codeFilterValue
      } = this_props;
      try {
        setLoading(true);
        setSelectedInvoices([])
        if (is_representative_view) {
          const initial_data = await getAllInvoicesData();
          setInitialPageData(initial_data);
          setPageData(initial_data);
        } else if (!is_representative_view) {
          const { data, meta } = await fetchInvoicesMethod({
            registration_filter: registration_filter,
            representative_filter: representative_filter,
            company_filter: company_filter,
            status_filter_value: status_filter_value,
            charge_status_filter: charge_status_filter_value,
            from_expiration_date_filter: from_expiration_date_filter_value,
            to_expiration_date_filter: to_expiration_date_filter_value,
            page_number: page_number,
            page_size: page_size,
            order_filter: order_filter,
            order_by_filter: order_by_filter,
            code_filter: code_filter
          });
          setPageData(data);
          setPagination((current) => ({
            ...current,
            pageCount: meta.page_count,
            totalCount: meta.total_count,
            pageNumber: page_number,
          }));
        }
        setLoading(false);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar dados do cliente',
          }),
        );
        setLoading(false);
      }
    },
    [
      user,
      company,
      pageData,
      initialPageData,
      toExpirationDateFilterValue,
      fromExpirationDateFilterValue,
      chargeStatusFilterValue,
      statusFilterValue,
      registrationFilterValue,
      representativeFilterValue,
      pagination,
      codeFilterValue
    ],
  );
  const handlePageChange = (_: any, newPage: number) => {
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
    loadPayments({
      page_number: newPage,
    });
  };
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof InvoiceAttributes) => {
    const isAsc = orderBy === property && order === 'asc';
    const new_order = isAsc ? 'desc' : 'asc';
    setOrder(new_order);
    setOrderBy(property);
    loadPayments({
      page_number: defaultPagination.pageNumber,
      order_filter: new_order,
      order_by_filter: property,
    });
  };

  const handleChangePageSize = (e: any) => {
    setPagination({
      ...pagination,
      pageSize: e.target.value,
    });
    loadPayments({
      page_size: e.target.value,
    });
  };

  const initData = React.useCallback(async () => {
    await loadPayments();
  }, [user]);

  React.useEffect(() => {
    initData();
  }, []);

  if (is_representative_view ? !initialPageData || !pageData : !pageData) {
    return null;
  }

  if (!is_representative_view) {
    return (
      <InvoicesView
        handleChangePageSize={handleChangePageSize}
        pagination={pagination}
        order={order}
        orderBy={orderBy}
        handlePageChange={handlePageChange}
        handleRequestSort={handleRequestSort}
        chargeStatusFilterValue={chargeStatusFilterValue}
        companyFilterValue={companyFilterValue}
        fromExpirationDateFilterValue={fromExpirationDateFilterValue}
        loadPayments={loadPayments}
        pageData={pageData as RepresentativeInvoicePageData}
        representativeFilterValue={representativeFilterValue}
        setChargeStatusFilterValue={setChargeStatusFilterValue}
        setCompanyFilterValue={setCompanyFilterValue}
        setFromExpirationDateFilterValue={setFromExpirationDateFilterValue}
        setRepresentativeFilterValue={setRepresentativeFilterValue}
        setStatusFilterValue={setStatusFilterValue as React.Dispatch<React.SetStateAction<InvoiceStatusTypes[]>>}
        setToExpirationDateFilterValue={setToExpirationDateFilterValue}
        statusFilterValue={statusFilterValue as InvoiceStatusTypes[]}
        toExpirationDateFilterValue={toExpirationDateFilterValue}
        registrationFilterValue={registrationFilterValue}
        setRegistrationFilterValue={setRegistrationFilterValue}
        codeFilterValue={codeFilterValue}
        setCodeFilterValue={setCodeFilterValue}
        selectedInvoices={selectedInvoices}
        setSelectedInvoices={setSelectedInvoices}
      />
    );
  }
  return (
    <RepresentativesInvoiceView
      fromExpirationDateFilterValue={fromExpirationDateFilterValue}
      initialPageData={initialPageData}
      loadPayments={loadPayments}
      registrationFilterValue={registrationFilterValue}
      setFromExpirationDateFilterValue={setFromExpirationDateFilterValue}
      setRegistrationFilterValue={setRegistrationFilterValue}
      setStatusFilterValue={setStatusFilterValue}
      setToExpirationDateFilterValue={setToExpirationDateFilterValue}
      statusFilterValue={statusFilterValue}
      toExpirationDateFilterValue={toExpirationDateFilterValue}
    />
  );
};

export default InvoicesContainer;
