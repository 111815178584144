import React from 'react'
import { SvgIcon } from "@mui/material"

const SearchIcon = (props: any) => {
    return (
        <SvgIcon width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M9.25006 18.48C7.42214 18.482 5.63465 17.9418 4.11383 16.9277C2.593 15.9136 1.40712 14.4711 0.706236 12.7829C0.00534862 11.0947 -0.179069 9.23657 0.176329 7.44353C0.531726 5.65048 1.41098 4.00312 2.70282 2.70988C3.99466 1.41665 5.64112 0.535606 7.43377 0.178268C9.22643 -0.179071 11.0848 0.00337875 12.7737 0.702438C14.4627 1.4015 15.9064 2.58579 16.9222 4.10551C17.9379 5.62524 18.48 7.41211 18.48 9.24004C18.48 11.6889 17.508 14.0376 15.7773 15.7702C14.0466 17.5027 11.6989 18.4774 9.25006 18.48ZM9.25006 1.23003C7.66781 1.23003 6.12107 1.69924 4.80548 2.57829C3.48989 3.45734 2.46445 4.70669 1.85895 6.1685C1.25344 7.63031 1.09506 9.23885 1.40375 10.7907C1.71243 12.3425 2.47438 13.7681 3.5932 14.8869C4.71202 16.0057 6.13742 16.7677 7.68927 17.0763C9.24112 17.385 10.8497 17.2265 12.3115 16.621C13.7733 16.0155 15.0227 14.9902 15.9018 13.6746C16.7808 12.359 17.2501 10.8123 17.2501 9.23003C17.2501 7.10829 16.4072 5.07346 14.9069 3.57317C13.4066 2.07287 11.3718 1.23003 9.25006 1.23003Z'/>
            <path d='M20.3989 21.0091C20.2374 21.0084 20.0827 20.9436 19.9689 20.8291L15.2589 16.1091C15.1949 16.0545 15.1429 15.9872 15.1062 15.9115C15.0695 15.8358 15.0487 15.7533 15.0454 15.6692C15.0421 15.5851 15.0562 15.5012 15.0869 15.4228C15.1176 15.3445 15.1641 15.2734 15.2236 15.2139C15.2831 15.1543 15.3542 15.1078 15.4326 15.0771C15.511 15.0465 15.5949 15.0323 15.6789 15.0356C15.763 15.0389 15.8455 15.0597 15.9212 15.0964C15.997 15.1332 16.0642 15.1851 16.1189 15.2491L20.8389 19.9591C20.9079 20.0474 20.9518 20.1527 20.9657 20.2639C20.9796 20.3751 20.9631 20.4881 20.918 20.5907C20.8728 20.6933 20.8007 20.7817 20.7093 20.8465C20.6179 20.9114 20.5107 20.9503 20.3989 20.9591V21.0091Z'/>
        </SvgIcon>
    )
}

export default SearchIcon