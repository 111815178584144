/* eslint-disable camelcase */
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ContractSettingsForm from '../components/form/ContractSettingsForm';
import { RootState } from '../store/configureStore';
import { CREATE_CONTRACT_SETTING, FETCH_CONTRACT_SETTING, UPDATE_CONTRACT_SETTING } from '../store/contract_settings';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { ContractSettingsAttributes, LocationState, Paths, Role } from '../utils/constants';
import { evaluate_permissions, verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { formValueSelector } from 'redux-form';

const ContractSettingsFormContainer = () => {
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
  } = state
  const formValues = formValueSelector('contractSettingForm');
  const companyIdValue = formValues(state, 'company_id') as string;
  const [initialValues, setInitialValues] = React.useState<ContractSettingsAttributes | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const isUpdating = !isNil(initialValues?.id);
  const company_id_to_use = location?.state?.company_id
  ? location?.state?.company_id
  : is_above_school_director
  ? companyIdValue
  : company;

  const loadContractSetting = React.useCallback(async () => {
    if (location?.state?.contract_setting_id) {
      const contract_setting = await dispatch(
        FETCH_CONTRACT_SETTING.request({
          id: location.state.contract_setting_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = contract_setting;
      const formattedContractSetting = {
        id,
        ...attributes,
      };
      setInitialValues(formattedContractSetting);
    } else {
      setInitialValues({company_id: company_id_to_use} as ContractSettingsAttributes)
    }
  }, [location]);

  const initContractSettingsForm = React.useCallback(async () => {
    setLoading(true);
    await loadContractSetting();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: ContractSettingsAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'company_id',
          ]) as ContractSettingsAttributes;

          const signatures = isEmpty(data.signatures) ? null : data.signatures;
          const formattedData = { ...rest, signatures };
          await dispatch(
            UPDATE_CONTRACT_SETTING.request({
              id: initialValues?.id as string,
              data: formattedData,
            }),
          );
        } else {
          await dispatch(
            CREATE_CONTRACT_SETTING.request({
              data: {
                ...data,
                company_id: company_id_to_use,
              },
            }),
          );
        }
        history.push(Paths.CONTRACT_SETTINGS_LIST);
        dispatch(
          success({
            message: 'Configuração salva com sucesso.',
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar configuração de contrato.',
          }),
        );
      }
    },
    [initialValues, company_id_to_use, isUpdating],
  );

  React.useEffect(() => {
    initContractSettingsForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <ContractSettingsForm initialValues={initialValues} onSubmit={onSubmit} />;
};

export default ContractSettingsFormContainer;
