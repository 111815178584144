import React from 'react'
import { useDispatch } from 'react-redux'

import { FETCH_RACES } from '../../store/races'
import { DefaultOptionType } from '../../utils/constants'
import { error } from 'react-notification-system-redux'

export const racesHoc = (WrappedComponent: React.ElementType) => {
  const raceHocComponent = (props: any) => {
    const [racesOptions, setRaceOptions] = React.useState<DefaultOptionType[]>([])
    const dispatch = useDispatch()
    const fetchRacesMethod = async () => {
      try {
        const races = await dispatch(
          FETCH_RACES.request({})
        )
        const {
          data: { data },
        } = races
        const formattedData = data.map(({ attributes, id }) => {
          return {
            label: attributes.name,
            value: id
          }
        })
        setRaceOptions(formattedData)
      } catch (err) {
        dispatch(
          error(
            {
              message: 'Erro ao carregar opções de raças'
            }
          )
        )
      }
    }
    React.useEffect(() => {
      fetchRacesMethod()
    }, [])
    return <WrappedComponent {...props} racesOptions={racesOptions} />
  }
  return raceHocComponent
}

export default racesHoc
