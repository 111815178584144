import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'

const userDeficienciesEndpoint = '/user_deficiencies'
const deficienciesEndpoint = '/deficiencies'

export const deleteUserDeficiency = ({ id }: { id: string | number }) => {
  return http.delete(`${userDeficienciesEndpoint}/${id}`)
}

export const fetchDeficiencies = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${deficienciesEndpoint}?${queryParams}`)
}
