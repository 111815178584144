import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const compositionsEndpoint = '/compositions'

export const fetchCompositions = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${compositionsEndpoint}?${queryParams}`)
}

export const fetchComposition = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${compositionsEndpoint}/${id}?${queryParams}`)
}

export const createComposition = (data: object) => {
  return http.post(`${compositionsEndpoint}`, data)
}

export const updateComposition = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${compositionsEndpoint}/${id}`, data)
}

export const deleteComposition = ({ id }: { id: string | number } ) => {
  return http.delete(`${compositionsEndpoint}/${id}`)
}
