/* eslint-disable camelcase */
import { makeStyles } from '@mui/styles';
import { compose } from 'redux';
import {
  change,
  Field,
  FieldArray,
  FormSection,
  formValueSelector,
  reduxForm,
  WrappedFieldArrayProps,
} from 'redux-form';
import {
  colors,
  NestedOperationAttributes,
  OperationAttributes,
  operationKindOptions,
  operationOperableOptions,
  OperationOperableType,
  validation,
  CompositionPeriodKindEnum,
  DefaultOptionType,
  FormulableType,
  OperationKindEnum,
  ExamPlaceableType,
  ExamPlacementAttributes,
  FormulaAttributes,
  CompositionPeriodAttributes,
} from '../../utils/constants';
import InputComponent from '../input/form/input';
import SelectComponent from '../input/form/select';
import { css } from '@emotion/react';
import AddButton from '../shared/AddButton';
import React from 'react';
import DeleteButton from '../shared/DeleteButton';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import AutoCompleteComponent from '../input/form/autocomplete';
import { RootState } from '../../store/configureStore';
import { FETCH_EXAM_PLACEMENT, FETCH_EXAM_PLACEMENTS } from '../../store/exam_placements';
import { FETCH_FORMULA, FETCH_FORMULAS } from '../../store/formulas';
import { error } from 'react-notification-system-redux';
import { compact, isEmpty } from 'lodash';
import Loading from '../loading/Loading';
import { operation_simulation } from '../../utils/operationSimulation';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const AccordionCss = css`
  background: inherit;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grayBlue};
`;
const AccordionSummaryCss = css`
  display: flex;
  align-items: center;
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }
`;

const OperationSimulationCss = css`
  text-align: center;
  font-size: clamp(16px, 2vmax, 3vmin);
}`


const form_name = 'formulaForm';

const OperationsForm = (props: {
  children_operation_preffix?: string;
  current_operation: OperationAttributes;
  composition_period_id: string;
  composition_period_kind: CompositionPeriodKindEnum
  subject_period_id?: string
  subject_period_ids?: number[]
  composition_periods?: CompositionPeriodAttributes[]
  formulable_type: FormulableType;
  field_name: string
}) => {
  const [loading, setLoading] = React.useState(true);
  const [loadingOptions, setLoadingOptions] = React.useState(false)
  const dispatch = useDispatch();
  const { children_operation_preffix, field_name, current_operation, composition_period_id, composition_period_kind, formulable_type, composition_periods = [], subject_period_id, subject_period_ids = [] } = props;
  const [operableOptions, setOperableOptions] = React.useState<DefaultOptionType[]>([]);
  const composition_period_ids = composition_periods.map(item => item.id)
  const fetchExamPlacementsMethod = async (this_props: {
    name_value: string,
    placeable_ids: (number|string)[]
    placeable_type: ExamPlaceableType
  }) => {
    const {name_value, placeable_ids, placeable_type} = this_props
    try {
      const exam_placements = await dispatch(
        FETCH_EXAM_PLACEMENTS.request({
          params: {
            filters: {
              'q[name_start]': name_value,
              'q[exam_placeable_id_in]': placeable_ids,
              'q[exam_placeable_type_eq]': placeable_type,
              'page[size]': '100'
            },
          },
        }),
      );
      const {
        data: { data },
      } = exam_placements;
      const formatted_data = data.map((item) => ({ id: item.id, ...item.attributes }));
      return formatted_data
    } catch (error) {
      return []
    }
  }

  const fetchFormulasMethod = async (this_props: {
    name_value: string,
    formulable_ids: (number|string)[]
    formulable_type: FormulableType
  }) => {
    const {name_value, formulable_ids, formulable_type} = this_props
    try {
      const formulas = await dispatch(
        FETCH_FORMULAS.request({
          params: {
            filters: {
              'q[name_start]': name_value,
              'q[formulable_id_in]': formulable_ids,
              'q[formulable_type_eq]': formulable_type,
              'page[size]': '100'
            },
          },
        }),
      );
      const {
        data: { data },
      } = formulas;
      const formatted_data = data.map((item) => ({ id: item.id, ...item.attributes }));
      return formatted_data
    } catch (error) {
      return []
    }
  }
  

  const fetchOperableOptions = React.useCallback(
    async (value: string) => {
      try {
        if(isEmpty(value)){
          setOperableOptions([])
          return
        }
        setLoadingOptions(true);
        let options = [] as ExamPlacementAttributes[]
        const get_only_from_period = composition_period_kind === CompositionPeriodKindEnum.PARTIAL;
        if (current_operation.operable_type === OperationOperableType.EXAM_PLACEMENT) {
          const placeable_ids = get_only_from_period ? [composition_period_id] : composition_period_ids
          const composition_period_exam_placements = await fetchExamPlacementsMethod({
            name_value: value,
            placeable_ids: placeable_ids,
            placeable_type: ExamPlaceableType.COMPOSITION_PERIOD
          })
          options = options.concat(composition_period_exam_placements)
          if(subject_period_id){
            const placeable_ids = get_only_from_period ? [subject_period_id] : subject_period_ids
            const subject_period_exam_placements = await fetchExamPlacementsMethod({
              name_value: value,
              placeable_ids: placeable_ids,
              placeable_type: ExamPlaceableType.SUBJECT_PERIOD
            })
            options = options.concat(subject_period_exam_placements)
          }
          const formatted_options = options.map(item => ({value: item.id, label: item.name}))
          setOperableOptions(formatted_options);
        } else if (current_operation.operable_type === OperationOperableType.FORMULA) {
          let options = [] as FormulaAttributes[]
          const formulable_ids = get_only_from_period ? [composition_period_id] : composition_period_ids
          const composition_period_formulas = await fetchFormulasMethod({
            name_value: value,
            formulable_ids: formulable_ids,
            formulable_type: FormulableType.COMPOSITION_PERIOD
          })
          options = options.concat(composition_period_formulas)
          if(subject_period_id){
            const placeable_ids = get_only_from_period ? [subject_period_id] : subject_period_ids
            const subject_period_formulas = await fetchFormulasMethod({
              name_value: value,
              formulable_ids: placeable_ids,
              formulable_type: FormulableType.SUBJECT_PERIOD
            })
            options = options.concat(subject_period_formulas)
          }
          const formatted_options = options.map(item => ({value: item.id, label: item.name}))
          setOperableOptions(formatted_options);
        } else if (current_operation.operable_type === OperationOperableType.COMPOSITION_PERIOD) {
          setOperableOptions(composition_periods.map(item => ({
            value: item.id,
            label: item.name
          })))
        }
        setLoadingOptions(false);
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de operáveis',
          }),
        );
      }
    },
    [composition_period_id, composition_period_kind, current_operation, subject_period_id, subject_period_ids, composition_period_ids],
  );

  const getCurrentOperable = async () => {
    if(current_operation.operable_type === OperationOperableType.EXAM_PLACEMENT){
      const exam_placement = await dispatch(
        FETCH_EXAM_PLACEMENT.request({
          id: current_operation.operable_id,
        }),
      );

      const { data: { data } } = exam_placement
      const option = [
        {
          label: data.attributes.name,
          value: current_operation.operable_id.toString()
        }
      ]
      setOperableOptions(option)
    } else if(current_operation.operable_type === OperationOperableType.FORMULA) {
      const formula = await dispatch(
        FETCH_FORMULA.request({
          id: current_operation.operable_id,
        }),
      );

      const { data: { data } } = formula
      const option = [
        {
          label: data.attributes.name,
          value: current_operation.operable_id.toString()
        }
      ]
      setOperableOptions(option)
    }
  }
  
  const init = async () => {
    setLoading(true);
    if(current_operation?.operable_id){
      await getCurrentOperable()
    }
    setLoading(false);
  }

  const handleClearField = () => {
    dispatch(change(form_name, `${field_name}.operable_id`, null))
    setOperableOptions([])
  }

  React.useEffect(() => {
    init()
  }, [])

  if(loading){
    return <Loading />
  }
  return (
    <div>
      <div css={OperationSimulationCss}>
        <span>{operation_simulation(current_operation)}</span>
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: 24.5% 24.5% 24.5% 24.5%;
          justify-content: space-between;
        `}
      >
        <Field
          name='kind'
          component={SelectComponent}
          options={operationKindOptions}
          label={'Tipo de operação'}
          placeholder={'Selecione o tipo de operação'}
          validate={[validation.required]}
        />
        <Field label='Peso' component={InputComponent} name='weight' onlyNumbers placeholder={'7'} />
        <Field
          label='Ordem'
          component={InputComponent}
          name='order'
          onlyNumbers
          placeholder={'Ordem'}
          validate={[validation.required]}
        />
        <Field
          label='Valor numérico'
          component={InputComponent}
          name='number_input'
          onlyNumbers
          disabled={current_operation?.kind === OperationKindEnum.OPERABLE_INPUT}
          placeholder={'7'}
          validate={current_operation?.kind === OperationKindEnum.NUMBER_INPUT ? [validation.required] : []}
        />
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: 40% 40% 19%;
          justify-content: space-between;
        `}
      >
        <Field
          name='operable_type'
          component={SelectComponent}
          options={operationOperableOptions}
          label={'Tipo do operável'}
          disabled={current_operation?.kind !== OperationKindEnum.OPERABLE_INPUT}
          placeholder={'Selecione o tipo do operável'}
          validate={current_operation?.kind === OperationKindEnum.OPERABLE_INPUT ? [validation.required] : []}
        />
        <Field
          name='operable_id'
          label={'Operável'}
          placeholder={'Buscar Operável'}
          component={AutoCompleteComponent}
          disabled={current_operation?.kind !== OperationKindEnum.OPERABLE_INPUT}
          autoCompleteProps={{
            disabled: current_operation?.kind !== OperationKindEnum.OPERABLE_INPUT,
            filterOptions: (x: any) => x,
            loading: loadingOptions,
            clearable: true,
            getValueOnChange: true,
            fetchOptions: fetchOperableOptions,
            onClearField: handleClearField
          }}
          options={operableOptions}
          validate={current_operation?.kind === OperationKindEnum.OPERABLE_INPUT ? [validation.required] : []}
        />
        <Field
          name='label'
          component={InputComponent}
          label={'Nome da variável na simulação'}
          placeholder={'Insira o nome da variável'}
        />
      </div>
      <FieldArray
        name='children_attributes'
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        component={renderChildrenOperationForm}
        children_operation_preffix={children_operation_preffix}
        subject_period_id={subject_period_id}
        composition_period_id={composition_period_id}
        composition_period_kind={composition_period_kind}
        formulable_type={formulable_type}
        composition_periods={composition_periods}
      />
    </div>
  );
};

interface ChildrenOperationForm extends WrappedFieldArrayProps {
  children_operation_preffix: string;
  parent_operation: NestedOperationAttributes;
  composition_period_id: string;
  composition_period_kind: CompositionPeriodKindEnum
  formulable_type: FormulableType;
  subject_period_id?: string
  subject_period_ids?: number[]
  composition_periods?: CompositionPeriodAttributes[]
}

const renderChildrenOperationForm = (props: ChildrenOperationForm) => {
  const { fields, children_operation_preffix, parent_operation, composition_period_id, composition_period_kind, formulable_type, composition_periods, subject_period_id, subject_period_ids } = props;
  const dispatch = useDispatch();
  const addChildrenOperation = React.useCallback(() => {
    fields.push({
      ...(parent_operation?.id ? { parent_id: parent_operation.id } : {}),
    });
  }, [fields, parent_operation, children_operation_preffix]);
  const disable_children_operation = [OperationKindEnum.NUMBER_INPUT, OperationKindEnum.OPERABLE_INPUT].includes(parent_operation?.kind)
  const deleteChildrenOperation = React.useCallback(
    (current_children_operation: NestedOperationAttributes, children_operation: string, index: number) => {
      current_children_operation.id
        ? dispatch(change(form_name, `${children_operation_preffix}.${children_operation}._destroy`, true))
        : fields.remove(index);
    },
    [fields],
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar operação</span>
        <AddButton disabled={disable_children_operation} onClick={() => {
          addChildrenOperation()
          }} tooltip='Adicionar curso' />
      </div>
      {fields.map((children_operation, index) => {
        const currentChildrenOperation = fields.get(index) as NestedOperationAttributes;
        const kind_label = operationKindOptions.find(item => item.value === currentChildrenOperation.kind)?.label
        const operand_label = operationOperableOptions.find(item => item.value === currentChildrenOperation?.operable_type)?.label
        const label = compact([kind_label, operand_label]).join('-')
        if (currentChildrenOperation._destroy) {
          return null;
        }
        return (
          <Accordion disabled={disable_children_operation} TransitionProps={{ unmountOnExit: true }} key={children_operation} css={AccordionCss}>
            <AccordionSummary css={AccordionSummaryCss}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ExpandMore />
                <span>{label || 'Nova operação'}</span>
              </div>
              <DeleteButton
                onClick={(e) => {
                  e.stopPropagation();
                  deleteChildrenOperation(currentChildrenOperation, children_operation, index);
                }}
                tooltip='Remover período'
              />
            </AccordionSummary>
            <AccordionDetails>
              <div key={children_operation}>
                <FormSection name={children_operation}>
                  <OperationsForm
                    field_name={`${children_operation_preffix}.${children_operation}`}
                    formulable_type={formulable_type}
                    composition_period_id={composition_period_id}
                    current_operation={currentChildrenOperation}
                    composition_period_kind={composition_period_kind}
                    subject_period_id={subject_period_id}
                    subject_period_ids={subject_period_ids}
                    composition_periods={composition_periods}
                  />
                </FormSection>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

const FormulasForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  composition_period_id: string;
  composition_period_kind: CompositionPeriodKindEnum,
  close_form: () => void
  composition_periods?: CompositionPeriodAttributes[]
  subject_period_ids?: number[]
  subject_period_id?: string
}) => {
  const { handleSubmit, onSubmit, composition_period_id, composition_period_kind, close_form, composition_periods = [], subject_period_id, subject_period_ids } = props;
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const formValues = formValueSelector(form_name);
  const operation_attributes = formValues(state, 'operation_attributes') as OperationAttributes;
  const formulable_type = formValues(state, 'formulable_type') as FormulableType;

  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar fórmula</span>
      <form className={classes.form}>
        <div style={{ display: 'grid', gridTemplateColumns: '32% 32% 32%', justifyContent: 'space-between' }}>
          <Field
            name='name'
            component={InputComponent}
            label={'Nome'}
            placeholder={'Insira o nome da fórmula'}
            validate={[validation.required]}
          />
          <Field
            label='Média de aprovação'
            component={InputComponent}
            name='threshold'
            onlyNumbers
            placeholder={'7'}
            validate={[validation.required]}
          />
          <Field
            label='Ordem da fórmula/média'
            component={InputComponent}
            name='step'
            onlyNumbers
            placeholder={'1'}
            validate={[validation.required]}
          />
        </div>
        <FormSection name='operation_attributes'>
          <OperationsForm
            field_name='operation_attributes'
            formulable_type={formulable_type}
            current_operation={operation_attributes}
            composition_period_id={composition_period_id}
            composition_period_kind={composition_period_kind}
            subject_period_id={subject_period_id}
            subject_period_ids={subject_period_ids}
            composition_periods={composition_periods}
          />
        </FormSection>
        <div className={classes.buttons}>
          <button onClick={(e) => {
              e.preventDefault()
              close_form()
            }
          }
            className='red small'>
            <span> Cancelar </span>
          </button>
          <button onClick={handleSubmit(onSubmit)} className='blue small'>
            <span> Salvar fórmula </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(FormulasForm);
