/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CoursesForm from '../components/form/CoursesForm';
import { RootState } from '../store/configureStore';
import { CREATE_COURSE, FETCH_COURSE, UPDATE_COURSE } from '../store/courses';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { CoursesAttributes, LocationState, Paths } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';

const CoursesFormContainer = () => {
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    auth: { company },
  } = useSelector((state: RootState) => state);
  const [initialValues, setInitialValues] = React.useState<CoursesAttributes | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const isUpdating = !isNil(initialValues?.id);

  const loadCourse = React.useCallback(async () => {
    if (location?.state?.course_id) {
      const course = await dispatch(
        FETCH_COURSE.request({
          id: location.state.course_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = course;
      const formattedCourse = {
        id,
        ...attributes,
      };
      setInitialValues(formattedCourse);
    }
  }, [location]);

  const initCoursesForm = React.useCallback(async () => {
    setLoading(true);
    await loadCourse();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: CoursesAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, ['id', 'company_id']) as CoursesAttributes;
          await dispatch(
            UPDATE_COURSE.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          await dispatch(
            CREATE_COURSE.request({
              data: {
                ...data,
                company_id: company,
              },
            }),
          );
        }
        history.push(Paths.COURSES_LIST);
        dispatch(
          success({
            message: 'Curso salvo com sucesso.',
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar curso.',
          }),
        );
      }
    },
    [initialValues, company, isUpdating],
  );

  React.useEffect(() => {
    initCoursesForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <CoursesForm initialValues={initialValues} onSubmit={onSubmit} />;
};

export default CoursesFormContainer;
