import React from 'react';
import {
  auditActionOptions,
  AuditAttributes,
  ClassTimeSubjectAttributes,
  colors,
  defaultPagination,
  PaginationType,
} from '../../utils/constants';
import { FETCH_AUDITS } from '../../store/audits';
import { useDispatch } from 'react-redux';
import { find, head, keys, last, map } from 'lodash';
import { error } from 'react-notification-system-redux';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { css } from '@emotion/react';
import { parseISO, format } from 'date-fns';
import TooltipButton from '../shared/TooltipButton';
import { Visibility } from '@mui/icons-material';
import TablePaginationActions from '../shared/TablePaginationActions';
import { ClassTimeSubjectJson } from '../../store/class_time_subjects';

const TableCss = css`
  margin-bottom: 15%;
  & tbody > tr {
    background-color: ${colors.grayBlue} !important;
  }

  & tfoot {
    background: white;
    & td {
      font-weight: bold;
      font-size: 1rem;
    }
    & td:last-of-type {
      color: ${colors.blue};
    }
  }
  & .MuiTableContainer-root {
    background: inherit;
    box-shadow: none;
    & .MuiTableCell-root {
      border-bottom: 5px solid ${colors.lightBlue};
    }
    & th {
      color: ${colors.darkGrayBlue};
    }
    & td {
      color: ${colors.darkBlue};
    }
    & td.MuiTableCell-footer {
      border-bottom: none;
    }
  }
`;

interface AuditExamLog extends AuditAttributes {
  class_time_subject: ClassTimeSubjectAttributes;
}
const ClassPlanLogTable = (props: { user_id: string; company_id: string }) => {
  const dispatch = useDispatch();
  const [audits, setAudits] = React.useState<AuditExamLog[]>([]);
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [expandedChanges, setExpandedChanges] = React.useState<number[]>([]);

  const fetchAudits = React.useCallback(
    async ({ newPagination = pagination }: { newPagination?: PaginationType }) => {
      const { user_id, company_id } = props;
      try {
        const audits_response = await dispatch(
          FETCH_AUDITS.request({
            params: {
              filters: {
                'q[user_id_eq]': user_id,
                'q[auditable_type_eq]': 'ClassTimeSubject',
                'q[auditable_of_ClassTimeSubject_type_company_id_eq]': company_id,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
                include: 'auditable',
              },
            },
          }),
        );
        const {
          data: { data, included, meta },
        } = audits_response;
        const formatted_data = data.map((item) => {
          const included_class_time_subject = find(
            included,
            (incl) =>
              ~~incl.id === item.attributes.auditable_id && item.attributes.auditable_type === 'ClassTimeSubject',
          ) as ClassTimeSubjectJson;
          return {
            id: item.id,
            ...item.attributes,
            class_time_subject: {
              id: included_class_time_subject.id,
              ...included_class_time_subject.attributes,
            },
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setAudits(formatted_data);
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao carregar logs',
          }),
        );
      }
    },
    [pagination],
  );
  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchAudits({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchAudits({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const init = async () => {
    await fetchAudits({});
  };

  React.useEffect(() => {
    init();
  }, []);
  const columns = ['Tipo', 'Ano Letivo', 'Turma', 'Disciplina', 'Bimestre', 'Data/Horário'];
  return (
    <Table css={TableCss} size='small' aria-label='exam_audits'>
      <TableHead>
        <TableRow>
          {columns.map((item) => (
            <TableCell key={item}>{item}</TableCell>
          ))}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {audits.map((audit) => {
          const actionLabel = auditActionOptions.find((item) => item.value === audit.action)?.label || '';
          const { class_time_subject } = audit;
          return (
            <React.Fragment key={audit.id}>
              <TableRow>
                <TableCell align='center'>{actionLabel}</TableCell>
                <TableCell align='center'>{class_time_subject.year}</TableCell>
                <TableCell align='center'>{class_time_subject.room_name}</TableCell>
                <TableCell align='center'>{class_time_subject.subject_name}</TableCell>
                <TableCell align='center'>{class_time_subject.period_name}</TableCell>
                <TableCell align='center'>{format(parseISO(audit.created_at), 'dd-MM-yyyy H:mm')}</TableCell>
                <TableCell align='center'>
                  <TooltipButton
                    tooltipProps={{
                      title: expandedChanges.includes(~~audit.id) ? 'Ocultar mudanças' : 'Ver mudanças',
                    }}
                    Icon={Visibility}
                    iconButtonProps={{
                      onClick: () =>
                        setExpandedChanges((current) => {
                          if (current.includes(~~audit.id)) {
                            return current.filter((item) => item !== ~~audit.id);
                          } else {
                            return current.concat(~~audit.id);
                          }
                        }),
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
                  <Collapse in={expandedChanges.includes(~~audit.id)} timeout='auto' unmountOnExit>
                    {map(keys(audit.audited_changes), (key) => {
                      const changes = audit.audited_changes[key];
                      if (key === 'class_plan') {
                        return (
                          <div>
                            <span>Plano de aula anterior:</span>
                            <div key={key} dangerouslySetInnerHTML={{__html: head(changes) as unknown as string}} />
                            <br  />
                            <hr />
                            <span>Novo Plano de Aula:</span>
                            <div key={key} dangerouslySetInnerHTML={{__html: last(changes) as unknown as string}} />
                          </div>
                        );
                      }
                    })}
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            colSpan={columns?.length + 1}
            count={pagination.totalCount}
            rowsPerPage={pagination.pageSize}
            page={pagination.pageNumber}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangePageSize}
            labelRowsPerPage='Itens Por página'
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default ClassPlanLogTable;
