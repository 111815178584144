import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const roomsEndpoint = '/room_student_statuses'

export const fetchRoomStudentStatuses = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${roomsEndpoint}?${queryParams}`)
}

export const fetchRoomStudentStatus = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${roomsEndpoint}/${id}?${queryParams}`)
}

export const createRoomStudentStatus = (data: object) => {
  return http.post(`${roomsEndpoint}`, data)
}

export const updateRoomStudentStatus = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${roomsEndpoint}/${id}`, data)
}

export const deleteRoomStudentStatus = ({ id }: { id: string | number } ) => {
  return http.delete(`${roomsEndpoint}/${id}`)
}