import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const examsEndpoint = '/exams'

export const fetchExams = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${examsEndpoint}?${queryParams}`)
}

export const fetchExam = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${examsEndpoint}/${id}?${queryParams}`)
}

export const createExam = (data: object) => {
  return http.post(`${examsEndpoint}`, data)
}

export const updateExam = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${examsEndpoint}/${id}`, data)
}

export const deleteExam = ({ id }: { id: string | number } ) => {
  return http.delete(`${examsEndpoint}/${id}`)
}