/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { InvoiceFormAttributes, JsonFormat, JsonResponseFormat, RenegotiationStatusTypes } from '../../utils/constants'
import { AxiosResponse } from 'axios'

export interface RenegotiationsAttrs {
  description: string
  renegotiation_log: {
    invoice_reference: InvoiceFormAttributes
  }
  cancel_when_expired: boolean
  status: RenegotiationStatusTypes
}

export interface InvoiceRenegotiationAttrs {
  renegotiation_id: number
}

export type RenegotiationJson = JsonFormat<'renegotiations', RenegotiationsAttrs>
export type InvoiceRenegotiationJson = JsonFormat<'invoice_renegotiations', InvoiceRenegotiationAttrs>

export type FetchRenegotiationsResponse = JsonResponseFormat<RenegotiationJson[]>
export type FetchRenegotiationResponse = JsonResponseFormat<RenegotiationJson>


export interface CreateRenegotiationPayload {
  data: object
}

export interface CancelRenegotiationPayload {
  id: string
}

export const CREATE_RENEGOTIATION = createPromiseAction('renegotiations: CREATE_RENEGOTIATION')<CreateRenegotiationPayload, FetchRenegotiationResponse, any>()
export const CANCEL_RENEGOTIATION = createPromiseAction('renegotiations: CANCEL_RENEGOTIATION')<CancelRenegotiationPayload, AxiosResponse, any>()
