import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  CREATE_WALLET_CREDIT, CreateWalletCreditPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'


export function * createWalletCredit (action: DefaultAction<CreateWalletCreditPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'wallet_credits',
        attributes: { ...data },
      }
    }    
    const response = yield call(services.createWalletCredit, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_WALLET_CREDIT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_WALLET_CREDIT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchWalletCreditSagas () {
  yield takeLatest(CREATE_WALLET_CREDIT.request, createWalletCredit)
}

export default function * renegotiationSagas () {
  yield all([watchWalletCreditSagas()])
}
