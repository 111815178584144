import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import { SIGN_IN as signIn } from '../store/auth'
import { makeStyles } from '@mui/styles'

import SomaLogin from '../containers/SomaLogin'

const useStyles = makeStyles(() => ({
  container: {
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    boxSizing: 'border-box',
    backgroundRepeat: 'repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    height: '100%',
    background: '#E8E8E8'
  }
}))

export const SomaLoginPage = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const handleDispatch = (value: string) => {
    dispatch(signIn.request({ token: value, history }))
  }
  React.useEffect(() => {
    const queryString = 'token'
    const queryparams = new URLSearchParams(location.search)
    if (queryparams.has(queryString)) {
      const value = queryparams.get(queryString)
      if(value !== null){
        handleDispatch(value)
      }
    }
  }, [])
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <SomaLogin />
    </div>
  )
}

export default SomaLoginPage
