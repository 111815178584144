import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_COORDINATOR, CreateCoordinatorPayload, DELETE_COORDINATOR, DeleteCoordinatorPayload, FETCH_COORDINATOR, FETCH_COORDINATORS, FetchCoordinatorPayload, FetchCoordinatorsPayload, UPDATE_COORDINATOR, UpdateCoordinatorPayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchCoordinators (action: DefaultAction<FetchCoordinatorsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchCoordinators, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_COORDINATORS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_COORDINATORS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchCoordinator (action: DefaultAction<FetchCoordinatorPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchCoordinator, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_COORDINATOR.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_COORDINATOR.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createCoordinator (action: DefaultAction<CreateCoordinatorPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'coordinators',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createCoordinator, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_COORDINATOR.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_COORDINATOR.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateCoordinator (action: DefaultAction<UpdateCoordinatorPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'coordinators',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateCoordinator, {data: params, id})
    yield put(UPDATE_COORDINATOR.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_COORDINATOR.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteCoordinator (action: DefaultAction<DeleteCoordinatorPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteCoordinator, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_COORDINATOR.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_COORDINATOR.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchCoordinatorsSagas () {
  yield takeLatest(FETCH_COORDINATORS.request, fetchCoordinators)
  yield takeLatest(FETCH_COORDINATOR.request, fetchCoordinator)
  yield takeLatest(CREATE_COORDINATOR.request, createCoordinator)
  yield takeLatest(UPDATE_COORDINATOR.request, updateCoordinator)
  yield takeLatest(DELETE_COORDINATOR.request, deleteCoordinator)
}

export default function * coordinatorsSagas () {
  yield all([watchCoordinatorsSagas()])
}
