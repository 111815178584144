import { createPromiseAction } from "redux-saga-promise-actions"
import { JsonFormat, JsonResponseFormat, WithRequestParams } from "../../utils/constants"
import { CompositionPeriodJson } from "../composition_periods"
import { AxiosResponse } from "axios"
import { ExamPlacementJson } from "../exam_placements"

export interface CompositionAttrs {
  curriculum_id: number
  name: string
}

export type CompositionJson = JsonFormat<'compositions', CompositionAttrs>
export type FetchCompositionsResponse = JsonResponseFormat<CompositionJson[], (CompositionPeriodJson)[]>
export type FetchCompositionResponse = JsonResponseFormat<CompositionJson, (CompositionPeriodJson|ExamPlacementJson)[]>
export type FetchCompositionsPayload = WithRequestParams

export interface FetchCompositionPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateCompositionPayload {
  data: object
}

export interface UpdateCompositionPayload extends CreateCompositionPayload {
  id: string
}

export interface DeleteCompositionPayload {
  id: string
}


export const FETCH_COMPOSITIONS = createPromiseAction('compositions: FETCH_COMPOSITIONSS')<FetchCompositionsPayload, FetchCompositionsResponse, any>()
export const FETCH_COMPOSITION  = createPromiseAction('compositions: FETCH_COMPOSITION')<FetchCompositionPayload, FetchCompositionResponse, any>()
export const CREATE_COMPOSITION = createPromiseAction('compositions: CREATE_COMPOSITIONS')<CreateCompositionPayload, FetchCompositionResponse, any>()
export const UPDATE_COMPOSITION = createPromiseAction('compositions: UPDATE_COMPOSITIONS')<UpdateCompositionPayload, FetchCompositionResponse, any>()
export const DELETE_COMPOSITION = createPromiseAction('compositions: DELETE_COMPOSITIONS')<DeleteCompositionPayload, AxiosResponse, any>()