import React from 'react';
import {
  DefaultOptionType,
  InvoiceAttributes,
  InvoiceChargeStatusEnum,
  InvoiceChargeStatusTypes,
  InvoiceStatusEnum,
  InvoiceStatusTypes,
  RepresentativeAttributes,
  Role,
  colors,
  invoiceChargeStatusOptions,
  invoiceStatusOptions,
  onConfirm,
} from '../../utils/constants';
import { makeStyles } from '@mui/styles';
import { IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { CREATE_INVOICE_CHARGE, GENERATE_INVOICE_RECEIPTS, SEND_STRAIGHT_NOTIFICATION } from '../../store/invoices';
import { compact, find, uniq } from 'lodash';
import { error, success, warning } from 'react-notification-system-redux';
import SelectMultipleComponent from '../input/form/selectmultiple';
import SelectComponent from '../input/form/select';
import DatePickerComponent from '../input/form/datepicker';
import { evaluate_permissions } from '../../utils/functions';
import {
  CheckCircle,
  Close,
  ForwardToInbox,
  HourglassEmpty,
  LowPriority,
  RequestQuote,
  Summarize,
} from '@mui/icons-material';
import { IconModal } from '../modal/Modal';
import InvoiceValue from './InvoiceValues';
import InputComponent from '../input/form/input';
import { UI_SET_LOADING_OPEN } from '../../store/ui';
import { isBefore, isSameDay } from 'date-fns';

const useStyles = makeStyles(() => ({
  filters: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingRight: '4rem',
    paddingBottom: '2rem',
    width: '100%',
    '& > span': {
      marginBottom: '14px',
    },
    '& .inputArea': {
      width: '10rem !important',
      paddingRight: '3rem',
    },
  },
}));

interface GeneratingChargeType {
  id: string;
  status: string;
}

interface GeneratingMessages {
  awaiting: string;
  success: string;
  failure: string;
}

const SelectRepresentatives = ({
  representatives,
  setRepresentativeFilter,
  handleFiltering,
  representativeFilter,
}: {
  representatives: RepresentativeAttributes[];
  setRepresentativeFilter: React.Dispatch<React.SetStateAction<string>>;
  handleFiltering: (this_props: {
    status_filter_value?: InvoiceStatusEnum[];
    representative_filter_value?: string;
    charge_status_filter_value?: InvoiceChargeStatusEnum[];
    from_expiration_date_filter_value?: Date | null;
    to_expiration_date_filter_value?: Date | null;
  }) => void;
  representativeFilter: string;
}) => {
  const representative_options = representatives.reduce((acc, rep) => {
    return acc.concat({ label: rep.representative_name, value: rep.id });
  }, [] as DefaultOptionType[]);
  return (
    <SelectComponent
      placeholder='Responsável'
      label={'Responsável'}
      clearable
      options={representative_options}
      clearFieldFunction={() => {
        setRepresentativeFilter('');
      }}
      input={{
        value: representativeFilter,
        onChange: (e: any) => {
          const value = e?.target?.value || '';
          setRepresentativeFilter(value);
          handleFiltering({
            representative_filter_value: value,
          });
        },
      }}
    />
  );
};

const InvoiceValueRow = ({
  generatingCharges,
  invoices,
  messages,
}: {
  invoices: InvoiceAttributes[];
  generatingCharges: GeneratingChargeType[];
  messages: GeneratingMessages;
}) => {
  return (
    <InvoiceValue
      values={invoices.map((invoice) => {
        const generatingCharge = generatingCharges.find((item) => item.id === invoice.id);
        const generatingChargeStatus = generatingCharge?.status;
        const iconColor =
          generatingChargeStatus === 'successful'
            ? colors.green
            : generatingChargeStatus === 'failure'
            ? colors.lightRed
            : 'initial';
        return {
          label: invoice.code,
          value: invoice.representative_name,
          convert: false,
          ...(generatingCharge && {
            iconFunction: () => null,
            iconTooltipText:
              generatingChargeStatus === 'awaiting'
                ? messages.awaiting
                : generatingChargeStatus === 'successful'
                ? messages.success
                : messages.failure,
            icon:
              generatingChargeStatus === 'awaiting'
                ? HourglassEmpty
                : generatingChargeStatus === 'successful'
                ? CheckCircle
                : Close,
            iconProps: { style: { color: iconColor } },
          }),
        };
      })}
    />
  );
};

const InvoicesToolbar = ({
  changeInvoiceStepTab,
  chargeStatusFilter,
  codeFilter,
  fromExpirationDateFilterValue,
  handleFetchInvoices,
  handleFiltering,
  invoices,
  is_invoices_view = false,
  registrationFilter,
  representativeFilter,
  representatives,
  setChargeStatusFilter,
  setCodeFilter,
  setFromExpirationDateFilterValue,
  setRegistrationFilter,
  setRepresentativeFilter,
  setStatusFilter,
  setToExpirationDateFilterValue,
  statusFilter,
  toExpirationDateFilterValue,
  setSelectedInvoices,
  selectedInvoices,
}: {
  changeInvoiceStepTab?: (invoice?: InvoiceAttributes, destiny?: string) => void;
  chargeStatusFilter: InvoiceChargeStatusTypes[];
  codeFilter?: string;
  fromExpirationDateFilterValue: Date | null;
  handleFiltering: (this_props: {
    status_filter_value?: InvoiceStatusEnum[];
    representative_filter_value?: string;
    charge_status_filter_value?: InvoiceChargeStatusEnum[];
    from_expiration_date_filter_value?: Date | null;
    to_expiration_date_filter_value?: Date | null;
  }) => void;
  handleFetchInvoices?: () => Promise<void>;
  invoices: InvoiceAttributes[];
  is_invoices_view?: boolean;
  registrationFilter?: string;
  representativeFilter: string;
  representatives: RepresentativeAttributes[];
  setChargeStatusFilter: React.Dispatch<React.SetStateAction<InvoiceChargeStatusTypes[]>>;
  setCodeFilter?: React.Dispatch<React.SetStateAction<string>>;
  setFromExpirationDateFilterValue: React.Dispatch<React.SetStateAction<Date | null>>;
  setRegistrationFilter?: React.Dispatch<React.SetStateAction<string>>;
  setRepresentativeFilter: React.Dispatch<React.SetStateAction<string>>;
  setStatusFilter: React.Dispatch<React.SetStateAction<InvoiceStatusTypes[]>>;
  setToExpirationDateFilterValue: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedInvoices: number[];
  setSelectedInvoices: React.Dispatch<React.SetStateAction<number[]>>;
  statusFilter: InvoiceStatusTypes[];
  toExpirationDateFilterValue: Date | null;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    auth: { profile },
  } = useSelector((state: RootState) => state);

  const [generatingCharges, setGeneratingCharges] = React.useState<{ id: string; status: string }[]>([]);

  const createInvoiceCharge = React.useCallback(
    async ({ invoice_id }: { invoice_id: string }) => {
      try {
        await dispatch(
          CREATE_INVOICE_CHARGE.request({
            id: invoice_id,
          }),
        );
        setGeneratingCharges((current) =>
          current.map((item) => {
            if (item.id === invoice_id) {
              return { id: invoice_id, status: 'successful' };
            }
            return item;
          }),
        );
      } catch (err) {
        setGeneratingCharges((current) =>
          current.map((item) => {
            if (item.id === invoice_id) {
              return { id: invoice_id, status: 'failure' };
            }
            return item;
          }),
        );
      }
    },
    [setGeneratingCharges, generatingCharges],
  );

  const sendStraightNotification = React.useCallback(
    async ({ invoice_id }: { invoice_id: string }) => {
      try {
        await dispatch(
          SEND_STRAIGHT_NOTIFICATION.request({
            id: invoice_id,
          }),
        );
        setGeneratingCharges((current) =>
          current.map((item) => {
            if (item.id === invoice_id) {
              return { id: invoice_id, status: 'successful' };
            }
            return item;
          }),
        );
      } catch (err) {
        setGeneratingCharges((current) =>
          current.map((item) => {
            if (item.id === invoice_id) {
              return { id: invoice_id, status: 'failure' };
            }
            return item;
          }),
        );
      }
    },
    [setGeneratingCharges, generatingCharges],
  );

  const generateInvoiceReceipt = React.useCallback(async () => {
    try {
      dispatch(UI_SET_LOADING_OPEN(true));
      const response = await dispatch(
        GENERATE_INVOICE_RECEIPTS.request({
          params: {
            filters: {
              invoice_ids: selectedInvoices,
            },
          },
        }),
      );
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `recibos-${new Date().getTime()}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }
      dispatch(
        success({
          title: 'Geração de recibos',
          message: 'Recibos gerados com sucesso!',
          autoDismiss: 3,
        }),
      );
      dispatch(UI_SET_LOADING_OPEN(false));
    } catch (err) {
      dispatch(UI_SET_LOADING_OPEN(false));
      dispatch(
        error({
          message: 'Erro na geração dos recibos',
          autoDismiss: 3,
        }),
      );
    }
  }, [selectedInvoices]);

  const thereIsActiveInvoicesSelected = selectedInvoices.some((invoice_id) => {
    const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_id);
    return invoice?.status === InvoiceStatusEnum.ACTIVE;
  });

  const thereIsExpiredInvoicesSelected = selectedInvoices.some((invoice_id) => {
    const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_id);
    return invoice && compact([invoice.expiration_date, invoice.punctuality_expiration_date]).some(date => !(isSameDay(new Date(date), new Date()) || isBefore(new Date(), new Date(date))))
  })

  const thereIsNotActiveInvoicesSelected = selectedInvoices.some((invoice_id) => {
    const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_id);
    return invoice?.status !== InvoiceStatusEnum.ACTIVE;
  });

  const thereIsFinishedInvoicesSelected = selectedInvoices.some((invoice_id) => {
    const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_id);
    return invoice?.status === InvoiceStatusEnum.FINISHED;
  });

  const thereIsRefundedInvoicesSelected = selectedInvoices.some((invoice_id) => {
    const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_id);
    return invoice?.charge_status === InvoiceChargeStatusEnum.REFUNDED;
  });

  const thereIsNotFinishedInvoicesSelected = selectedInvoices.some((invoice_id) => {
    const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_id);
    return invoice?.status !== InvoiceStatusEnum.FINISHED;
  });
  const more_than_one_representative_selected =
    uniq(
      selectedInvoices.map((invoice_id) => {
        const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_id);
        return invoice?.representative_id;
      }),
    ).length > 1;

  const handleRenegotiationClick = () => {
    if (profile.role === Role.SCHOOL_SECRETARY && thereIsActiveInvoicesSelected) {
      dispatch(
        warning({
          message: 'Esse perfil não tem permissão para renegociar faturas ativas',
        }),
      );
    } else if (thereIsFinishedInvoicesSelected) {
      dispatch(
        warning({
          message: 'Não se pode renegociar faturas concluídas',
        }),
      );
    } else if (more_than_one_representative_selected) {
      dispatch(
        warning({
          message: 'Não se pode renegociar faturas de responsáveis diferentes',
        }),
      );
    } else {
      changeInvoiceStepTab?.(undefined, 'renegotiation');
    }
  };

  const handleGenerateReceiptsClick = async () => {
    if (thereIsNotFinishedInvoicesSelected) {
      dispatch(
        warning({
          message: 'Não se pode gerar recibos para faturas não concluídas',
        }),
      );
    } else if(thereIsRefundedInvoicesSelected) {
      dispatch(
        warning({
          message: 'Não se pode gerar recibos para faturas estornadas',
        }),
      );
    } else if (more_than_one_representative_selected) {
      dispatch(
        warning({
          message: 'Não é possível gerar recibos para responsáveis diferentes',
        }),
      );
    } else {
      await generateInvoiceReceipt();
    }
    setSelectedInvoices([]);
  };

  const onConfirmSendStraightNotification: onConfirm = React.useCallback(
    async ({ handleClose }) => {
      try {
        await setGeneratingCharges(selectedInvoices.map((item) => ({ id: item.toString(), status: 'awaiting' })));
        for (const invoice_id of selectedInvoices) {
          await sendStraightNotification({ invoice_id: invoice_id.toString() });
        }
        setSelectedInvoices([]);
        handleFetchInvoices?.();
        setGeneratingCharges([]);
        handleClose();
        dispatch(
          success({
            title: 'Notificações enviadas',
            message: 'Notificações enviadas com sucesso!',
            autoDismiss: 3,
          }),
        );
      } catch (error) {
        dispatch(
          success({
            title: 'Erro no envio de notificação das cobranças',
            message: 'Favor entrar em contato com o suporte',
            autoDismiss: 3,
          }),
        );
      }
    },
    [selectedInvoices, generatingCharges],
  );

  const onConfirmGenerateCharge: onConfirm = React.useCallback(
    async ({ handleClose }) => {
      try {
        await setGeneratingCharges(selectedInvoices.map((item) => ({ id: item.toString(), status: 'awaiting' })));
        for (const invoice_id of selectedInvoices) {
          await createInvoiceCharge({ invoice_id: invoice_id.toString() });
        }
        setSelectedInvoices([]);
        handleFetchInvoices?.();
        setGeneratingCharges([]);
        handleClose();
        dispatch(
          success({
            title: 'Cobranças geradas',
            message: 'Cobranças geradas com sucesso!',
            autoDismiss: 3,
          }),
        );
      } catch (error) {
        dispatch(
          success({
            title: 'Erro na geração de cobranças',
            message: 'Favor entrar em contato com o suporte',
            autoDismiss: 3,
          }),
        );
      }
    },
    [selectedInvoices, generatingCharges],
  );
  const selectedInvoicesInvoices = selectedInvoices.map((invoice_id) => {
    const invoice = find(invoices, (invoice) => ~~invoice.id === invoice_id) as InvoiceAttributes;
    return invoice;
  });
  const disableGenerateCharges = thereIsActiveInvoicesSelected || thereIsFinishedInvoicesSelected || thereIsExpiredInvoicesSelected;
  const disableSendStraightNotification = thereIsNotActiveInvoicesSelected;
  const disableGenerateInvoiceReceipts = thereIsNotFinishedInvoicesSelected || !(selectedInvoices.length > 0) || thereIsRefundedInvoicesSelected;
  const filteredChargeStatusOptions = invoiceChargeStatusOptions.filter((opt) =>
    [
      InvoiceChargeStatusEnum.NOT_CHARGEABLE,
      InvoiceChargeStatusEnum.RECEIVED,
      InvoiceChargeStatusEnum.PENDING_PAYMENT,
      InvoiceChargeStatusEnum.EXPIRED,
      InvoiceChargeStatusEnum.REFUNDED,
      InvoiceChargeStatusEnum.CONFIRMED
    ].includes(opt.value as InvoiceChargeStatusEnum),
  );
  return (
    <div className={classes.filters}>
      <SelectMultipleComponent
        placeholder={`Status da fatura`}
        label={'Status da fatura'}
        allow_select_all
        options={invoiceStatusOptions}
        input={{
          value: statusFilter,
          onChange: (event: any) => {
            setStatusFilter(event?.target?.value);
            handleFiltering({
              status_filter_value: event.target.value,
            });
          },
        }}
      />
      <SelectMultipleComponent
        placeholder='Status cobrança'
        allow_select_all
        label={'Status da cobrança'}
        clearable
        options={filteredChargeStatusOptions}
        input={{
          value: chargeStatusFilter,
          onChange: (e: any) => {
            setChargeStatusFilter(e.target.value);
            handleFiltering({
              charge_status_filter_value: e.target.value,
            });
          },
        }}
      />
      {is_invoices_view ? (
        <>
          <InputComponent
            placeholder={`Buscar por código`}
            label='Buscar por código'
            search
            onSearch={() => {
              handleFiltering({});
            }}
            input={{
              value: codeFilter,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setCodeFilter?.(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por aluno`}
            label='Buscar por aluno'
            search
            onSearch={() => {
              handleFiltering({});
            }}
            input={{
              value: registrationFilter,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setRegistrationFilter?.(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por responsável`}
            label='Buscar por responsável'
            search
            onSearch={() => {
              handleFiltering({});
            }}
            input={{
              value: representativeFilter,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setRepresentativeFilter(e.target.value);
              },
            }}
          />
        </>
      ) : (
        <SelectRepresentatives
          representatives={representatives}
          setRepresentativeFilter={setRepresentativeFilter}
          handleFiltering={handleFiltering}
          representativeFilter={representativeFilter}
        />
      )}

      <DatePickerComponent
        placeholder='Vencimento a partir de'
        label='Vencimento a partir de'
        datePickerProps={{
          allowSameDateSelection: true,
        }}
        clearFieldFunction={() => {
          setFromExpirationDateFilterValue(null);
          handleFiltering({
            from_expiration_date_filter_value: null,
          });
        }}
        input={{
          value: fromExpirationDateFilterValue,
          name: 'year',
          onChange: (e: Date) => {
            setFromExpirationDateFilterValue(e);
            handleFiltering({
              from_expiration_date_filter_value: e,
            });
          },
        }}
      />
      <DatePickerComponent
        placeholder='Vencimento até'
        label='Vencimento até'
        datePickerProps={{
          allowSameDateSelection: true,
        }}
        clearFieldFunction={() => {
          setToExpirationDateFilterValue(null);
          handleFiltering({
            to_expiration_date_filter_value: null,
          });
        }}
        input={{
          value: toExpirationDateFilterValue,
          name: 'year',
          onChange: (e: Date) => {
            setToExpirationDateFilterValue(e);
            handleFiltering({
              to_expiration_date_filter_value: e,
            });
          },
        }}
      />
      <div style={{ alignSelf: 'stretch', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {evaluate_permissions.is_above_school_director(profile.role) && !is_invoices_view && (
          <>
            <Tooltip title='Renegociar'>
              <span>
                <IconButton disabled={selectedInvoices.length === 0} onClick={handleRenegotiationClick}>
                  <LowPriority />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
        {!is_invoices_view && (
          <Tooltip title='Gerar Recibos para faturas selecionadas'>
            <span>
              <IconButton disabled={disableGenerateInvoiceReceipts} onClick={handleGenerateReceiptsClick}>
                <Summarize />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <IconModal
          icon={ForwardToInbox}
          disabled={disableSendStraightNotification}
          disableConfirm={generatingCharges.length > 0}
          onConfirm={onConfirmSendStraightNotification}
          message='Deseja enviar notificação de cobrança para todos os responsáveis das faturas selecionadas?'
          title='Enviar notificação de cobrança para responsáveis das faturas'
          tooltipText={'Enviar notificação para responsáveis das faturas selecionadas'}
          confirmButtonText={'Enviar'}
        >
          <InvoiceValueRow
            invoices={selectedInvoicesInvoices}
            generatingCharges={generatingCharges}
            messages={{ awaiting: 'Aguardando Envio', failure: 'Envio falhou', success: 'Envio concluído' }}
          />
        </IconModal>
        <IconModal
          icon={RequestQuote}
          disabled={disableGenerateCharges}
          disableConfirm={generatingCharges.length > 0}
          onConfirm={onConfirmGenerateCharge}
          message='Deseja gerar cobrança para todas as faturas selecionadas?'
          title='Gerar cobranças para faturas'
          tooltipText={'Gerar cobranças para faturas selecionadas'}
          confirmButtonText={'Gerar'}
        >
          <InvoiceValueRow
            invoices={selectedInvoicesInvoices}
            generatingCharges={generatingCharges}
            messages={{ awaiting: 'Aguardando Geração', failure: 'Geração falhou', success: 'Geração concluída' }}
          />
        </IconModal>
      </div>
    </div>
  );
};

export default InvoicesToolbar;
