/* eslint-disable camelcase */
import { find, get } from 'lodash';
import React from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import ProductsView from '../components/view/Secretary/ProductsView';
import { ClassroomJson } from '../store/classrooms';
import { RootState } from '../store/configureStore';
import { CourseJson } from '../store/courses';
import { KtwelveJson } from '../store/ktwelves';
import { FETCH_PRODUCTS } from '../store/products';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import {
  defaultPagination,
  PaginationType,
  periodOptions,
  PeriodTypes,
  ProductAttributes,
  ProductTypes,
} from '../utils/constants';
import { CompanyJson } from '../store/companies';
import { get_company_search } from '../utils/functions';

const ProductsContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants },
    account: { companies }
  } = state;

  const [products, setProducts] = React.useState<ProductAttributes[]>([]);
  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [productTypeFilter, setProductTypeFilter] = React.useState<ProductTypes | string>('');
  const [ktwelveFilterValue, setKtwelveFilterValue] = React.useState('');
  const [courseFilterValue, setCourseFilterValue] = React.useState('');
  const [classroomFilterValue, setClassroomFilterValue] = React.useState('');
  const [periodFilterValue, setPeriodFilterValue] = React.useState<PeriodTypes | string>('');
  const [yearFilterValue, setYearFilterValue] = React.useState<Date | null>(new Date('2025-06-01'));
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');
  const setLoading = (value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  };
  const fetchProductsMethod = async ({
    newPagination = pagination,
    year = yearFilterValue,
    period = periodFilterValue,
    type = productTypeFilter,
    company_filter = companyFilterValue
  }: {
    newPagination?: PaginationType;
    year?: Date | null;
    period?: PeriodTypes | string;
    type?: string;
    company_filter?: string
  }) => {
    try {
      setLoading(true);
      let yearFilter = {};
      if (year) {
        yearFilter = {
          'q[year_eq]': year.getFullYear(),
        };
      }
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company
      })
      const result = await dispatch(
        FETCH_PRODUCTS.request({
          params: {
            filters: {
              include: 'classroom.ktwelve_degree.course,company',
              'q[company_id_in]': companies_array,
              'page[number]': (newPagination.pageNumber + 1).toString(),
              'page[size]': newPagination.pageSize.toString(),
              'q[name_cont]': nameFilterValue,
              'q[classroom_period_eq]': period,
              'q[kind_eq]': type,
              'q[classroom_ktwelve_degree_course_name_cont]': courseFilterValue,
              'q[classroom_ktwelve_degree_name_cont]': ktwelveFilterValue,
              'q[classroom_name_cont]': classroomFilterValue,
              ...yearFilter,
            },
          },
        }),
      );
      const {
        data: { data, meta, included },
      } = result;
      const formattedData = data.map((item) => {
        const classroom = find(
          included,
          (incl) => incl.type === 'classrooms' && ~~incl.id === item.attributes.classroom_id,
        ) as ClassroomJson;
        const ktwelve = find(
          included,
          (incl) => incl.type === 'ktwelve_degrees' && ~~incl.id === get(classroom, 'attributes.ktwelve_degree_id'),
        ) as KtwelveJson;
        const course = find(
          included,
          (incl) => incl.type === 'courses' && ~~incl.id === get(ktwelve, 'attributes.course_id'),
        ) as CourseJson;
        const company = find(
          included,
          (incl) => incl.type === 'companies' && ~~incl.id === get(item, 'attributes.company_id'),
        ) as CompanyJson
        const period = get(classroom, 'attributes.period');
        const periodLabel = find(periodOptions, (item) => item.value === period)?.label || '';
        return {
          id: item.id,
          ...item.attributes,
          classroom: classroom ? `${get(classroom, 'attributes.name')} - ${periodLabel} - ${item.attributes.year}` as string :  '',
          ktwelve: get(ktwelve, 'attributes.name') as string,
          course: get(course, 'attributes.name') as string,
          company: get(company, 'attributes.name')
        };
      });
      setPagination((current) => ({
        ...current,
        pageCount: meta.page_count,
        totalCount: meta.total_count,
      }));
      setProducts(formattedData);
      setLoading(false);
    } catch (er) {
      dispatch(
        error({
          message: 'Erro ao carregar lista de produtos',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (_: any, newPage: number) => {
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
    fetchProductsMethod({
      newPagination: {
        ...pagination,
        pageNumber: newPage,
      },
    });
  };

  const handleChangePageSize = (e: any) => {
    setPagination({
      ...pagination,
      pageSize: e.target.value,
    });
    fetchProductsMethod({
      newPagination: {
        ...pagination,
        pageSize: e.target.value,
      },
    });
  };

  React.useEffect(() => {
    fetchProductsMethod({});
  }, []);

  return (
    <ProductsView
      products={products}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      fetchProductsMethod={fetchProductsMethod}
      productTypeFilter={productTypeFilter}
      setProductTypeFilter={setProductTypeFilter}
      ktwelveFilterValue={ktwelveFilterValue}
      setKtwelveFilterValue={setKtwelveFilterValue}
      setCourseFilterValue={setCourseFilterValue}
      courseFilterValue={courseFilterValue}
      classroomFilterValue={classroomFilterValue}
      setClassroomFilterValue={setClassroomFilterValue}
      periodFilterValue={periodFilterValue}
      setPeriodFilterValue={setPeriodFilterValue}
      yearFilterValue={yearFilterValue}
      setYearFilterValue={setYearFilterValue}
      nameFilterValue={nameFilterValue}
      setNameFilterValue={setNameFilterValue}
      companyFilterValue={companyFilterValue}
      setCompanyFilterValue={setCompanyFilterValue}
    />
  );
};

export default ProductsContainer;
