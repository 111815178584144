/* eslint-disable camelcase */
import { makeStyles } from '@mui/styles';
import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BillingAttributes, InvoiceAttributes, InvoiceItemAttributes, colors, WalletCreditAttributes } from '../../utils/constants';
import { Collapse, IconButton, Tooltip } from '@mui/material';
import InvoiceTable from './InvoiceTable';
import { FormatListBulleted, FormatListNumbered } from '@mui/icons-material';
import { formatToCurrency } from '../../utils/functions';
import InvoiceItemsTable from './InvoiceItemTable';


const useStyles = makeStyles(() => ({
  table: {
    '& .contract-billing-table-body-row': {
      background: colors.grayBlue,
    '& .MuiTableContainer-root': {
        background: 'inherit',
        },
        '& .MuiTableRow-root.last-row > td, th': {
          borderBottom: '0'
        },
        '& th': {
            color: colors.darkGrayBlue,
        },
        '& td': {
            color: colors.darkBlue,
        },
        '& td.MuiTableCell-footer ': {
            borderBottom: 'none',
        },
        '& th:last-of-type': {
            textAlign: 'center',
        },
        '& .actions': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
            '& a,div': {
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                textDecoration: 'none',
                '& > span': {
                    color: colors.grayBlue,
                },
                '&:hover': {
                    '& >span': {
                        color: colors.darkBlue,
                    },
                },
            },
            '& div:first-of-type': {
                '& svg': {
                    color: colors.blue,
                },
            },
            '& div:last-of-type': {
                '& svg': {
                    color: colors.green,
                },
            },
        },
      },
  },
}));

const BillingsActions = ({
  billing,
  setExpandedBillingInvoiceItems,
  setExpandedBillings,
}:{
  billing: BillingAttributes,
  setExpandedBillingInvoiceItems:  React.Dispatch<React.SetStateAction<number[]>>
  setExpandedBillings: React.Dispatch<React.SetStateAction<number[]>>
}) => {
	return(
		<>
      <Tooltip title='Ver itens de fatura'>
        <IconButton onClick={() => setExpandedBillingInvoiceItems(current => {
              if(current.includes(~~billing.id)){
                return current.filter(item => item !== ~~billing.id)
              } else {
                return current.concat(~~billing.id)
              }
            })}>
					<FormatListNumbered />
				</IconButton>
      </Tooltip>
			<Tooltip title='Ver faturas da parcela'>
				<IconButton onClick={() => setExpandedBillings(current => {
              if(current.includes(~~billing.id)){
                return current.filter(item => item !== ~~billing.id)
              } else {
                return current.concat(~~billing.id)
              }
            })}>
					<FormatListBulleted />
				</IconButton>
			</Tooltip>
		</>
	)
}

const BillingsTable = ({
  changeInvoiceStepTab,
  billings,
  handleFetchRegistration,
  invoice_items,
  invoices,
  wallet_credits
}:{
  changeInvoiceStepTab: (invoice?: InvoiceAttributes, destiny?: string) => void
  billings: BillingAttributes[]
  handleFetchRegistration: () => Promise<void>
  invoice_items: InvoiceItemAttributes[],
  invoices: InvoiceAttributes[],
  wallet_credits: WalletCreditAttributes[]
}) => {
  const classes = useStyles()
  const [expandedBillings, setExpandedBillings] = React.useState<number[]>([])
  const [expandedBillingInvoiceItems, setExpandedBillingInvoiceItems] = React.useState<number[]>([])
  const columns = ['Código da parcela', '% Valor', '% Pontualidade', 'Parcela', "Total(contrato)" , "Desconto Pontualidade(contrato)", 'Ações'];

  return (
    <div className={classes.table}>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
          {columns.map((item, index) => {
                const align = index === 0 ? 'left' : 'center'
                return (
                  <TableCell align={align} key={item}>{item}</TableCell>
                )
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {billings.map((billing, index) => {   
            const isLast = index === billings.length - 1
            const isLastClassName = isLast ? ' last-row ' : ''
            const portionName = billing.portion === 0 ? 'Entrada' : `Parcela ${billing.portion}`
            const billingInvoiceItems = invoice_items.filter(invoice_item => invoice_item.billing_id === ~~billing.id)
            const { full, conditional } = billingInvoiceItems.filter(invoice_item => invoice_item.active).reduce((acc, invoice_item) => {
              return {full: acc.full + invoice_item.base_values.contract_full_billing_percentage_base, conditional: acc.conditional + invoice_item.base_values.contract_full_billing_percentage_base}
            }, { full: 0, conditional: 0 })
            const billingInvoices = billingInvoiceItems.filter(invoice_item => invoice_item.invoice_id !== undefined).map(invoice_item => {
              return invoices.find(invoice => ~~invoice.id === invoice_item.invoice_id)
            }) as InvoiceAttributes[]
            const className = isLastClassName + 'contract-billing-table-body-row'
              return (
                <>
                  <TableRow className={className} key={billing.code}>
                    <TableCell>{billing.code}</TableCell>
                    <TableCell align='center'>
                      <Tooltip title={`${full*100}% do valor do contrato está ativo nas faturas`}>
                        <span> {full*100}% </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align='center'>
                      <Tooltip title={`${conditional*100}% do valor de pontualidade do contrato está ativo nas faturas`}>
                        <span> {conditional*100}% </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align='center'>{portionName}</TableCell>
                    <TableCell align='center'>{formatToCurrency(billing.contract_full)}</TableCell>
                    <TableCell align='center'>{formatToCurrency(billing.contract_conditional_discount)}</TableCell>
                    <TableCell align='center'>
                      <BillingsActions
                        billing={billing}
                        setExpandedBillingInvoiceItems={setExpandedBillingInvoiceItems}
                        setExpandedBillings={setExpandedBillings}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{paddingBottom: 0, paddingTop: 0, ...(isLast && {borderBottom: 0}) }}
                      colSpan={10}
                    >
                      <Collapse
                        in={expandedBillingInvoiceItems.includes(~~billing.id)}
                        timeout='auto'
                        unmountOnExit
                      >
                        <InvoiceItemsTable
                          invoices={billingInvoices}
                          invoice_items={billingInvoiceItems}
                          wallet_credits={wallet_credits}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{paddingBottom: 0, paddingTop: 0, ...(isLast && {borderBottom: 0}) }}
                      colSpan={10}
                    >
                      <Collapse
                        in={expandedBillings.includes(~~billing.id)}
                        timeout='auto'
                        unmountOnExit
                      >
                        <InvoiceTable
                          invoice_items={invoice_items}
                          invoicesDisposition={billingInvoices}
                          invoices={billingInvoices}
                          changeInvoiceStepTab={changeInvoiceStepTab}
                          handleUpdateResources={handleFetchRegistration}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
  )
}


export default BillingsTable