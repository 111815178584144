import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'

const subsidiesEndpoint = '/subsidies'

export const fetchSubsidies = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${subsidiesEndpoint}?${queryParams}`)
}

