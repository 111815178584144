import styled from '@emotion/styled'

export const Container = styled('div')`
    min-height: 100vh;
    box-sizing: border-box;
    text-align: center;
    max-height: 100vh;
    height: 100vh;
    position: relative;
    overflow: hidden;
`

export const ChildrenContainer = styled('div')`
    background: #f6f8f9;
    flex-grow: 1;
    overflow: auto;
    display: flex;
`
