/* eslint-disable camelcase */
import React from 'react'
import { error } from 'react-notification-system-redux'
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ContractAttributes, BillingAttributes, InvoiceAttributes, InvoiceItemAttributes, colors, WalletCreditAttributes } from '../../utils/constants';
import { Collapse, IconButton, Tooltip } from '@mui/material';
import { FormatListNumbered } from '@mui/icons-material';
import BillingsTable from './BillingsTable';
import { ContractJson, FETCH_CONTRACT } from '../../store/contracts';
import { useDispatch } from 'react-redux';
import { FETCH_REGISTRATION } from '../../store/registrations';
import { filter, map } from 'lodash';
import { WalletCreditJson } from '../../store/wallet_credits';
import { InvoiceJson } from '../../store/invoices';
import { BillingJson } from '../../store/billings';
import { InvoiceItemJson } from '../../store/invoice_items';
import Loading from '../loading/Loading';

const useStyles = makeStyles(() => ({
  table: {
    '& .table-body-row': {
      background: colors.lightBlue,
      borderBottom: '10px solid white',
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `5px solid ${colors.lightBlue}`,
        padding: '0',
      },
      '& .MuiTableRow-root.last-row > td, th': {
        borderBottom: '0'
      },
      '& th': {
        color: colors.darkGrayBlue,
      },
      '& td': {
        color: colors.darkBlue,
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none',
      },
      '& th:last-of-type': {
        textAlign: 'center',
      },
      '& tr.expanded': {
        borderBottom: '0'
      },
      '& .actions': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        '& a,div': {
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          textDecoration: 'none',
          '& > span': {
            color: colors.grayBlue,
          },
          '&:hover': {
            '& >span': {
              color: colors.darkBlue,
            },
          },
        },
        '& div:first-of-type': {
          '& svg': {
            color: colors.blue,
          },
        },
        '& div:last-of-type': {
          '& svg': {
            color: colors.green,
          },
        },
      },
    },
  },
}));

const BillingTableComponent = (args: {
  contract_id: string
  selectedInvoiceItems: number[]
  setSelectedInvoiceItems: React.Dispatch<React.SetStateAction<number[]>>
  fetchContracts: () => Promise<void>
  changeInvoiceStepTab: (invoice?: InvoiceAttributes, destiny?: string) => void
}) => {
  const { contract_id, fetchContracts, changeInvoiceStepTab } = args
  const dispatch = useDispatch()
  const [billings, setBillings] = React.useState<BillingAttributes[]>([])
  const [invoice_items, setInvoiceItems] = React.useState<InvoiceItemAttributes[]>([])
  const [invoices, setInvoices] = React.useState<InvoiceAttributes[]>([])
  const [wallet_credits, setWalletCredits] = React.useState<WalletCreditAttributes[]>([])
  const [loading, setLoading] = React.useState(true)
  const fetchContract = React.useCallback(async () => {
    setLoading(true)
    try {
      const response = await dispatch(
        FETCH_CONTRACT.request({
          id: contract_id,
          params: {
            filters: {
              'include': 'billings.invoice_items.invoice.wallet_credits'
            },
          },
        }),
      );
      const { data: { included }} = response
      const includedInvoices = map(filter(included, incl => incl.type === 'invoices') as InvoiceJson[], item => ({
        id: item.id,
        ...item.attributes
      }))

      const includedBillings = map(filter(included, incl => incl.type === 'billings' && incl.attributes.billable_type === 'Contract') as BillingJson[], item => ({
        id: item.id,
        ...item.attributes
      }))
      const includedInvoiteItems = map(filter(included, incl => incl.type === 'invoice_items') as InvoiceItemJson[], item => ({
        id: item.id,
        ...item.attributes
      }))

      const includedWalletCredits = map(filter(included, incl => incl.type === 'wallet_credits') as WalletCreditJson[], item => ({
        id: item.id,
        ...item.attributes
      }))

      setInvoices(includedInvoices)
      setInvoiceItems(includedInvoiteItems)
      setBillings(includedBillings)
      setWalletCredits(includedWalletCredits)
      setLoading(false)
    } catch (err) {
      dispatch(
        error(
          {
            message: 'Erro ao carregar faturas do contrato'
          }
        )
      )
    }
    setLoading(false)
  }, [contract_id])
  React.useEffect(() => {
    fetchContract()
  }, [])

  if(loading){
    return <Loading />
  }
  return (
    <BillingsTable
      wallet_credits={wallet_credits}
      billings={billings}
      invoice_items={invoice_items}
      invoices={invoices}
      handleFetchRegistration={fetchContracts}
      changeInvoiceStepTab={changeInvoiceStepTab}
    />
  )
}


const ContractActions = ({contract, setExpandedContracts}:{contract: ContractAttributes, setExpandedContracts: React.Dispatch<React.SetStateAction<number[]>>}) => {
	return(
		<>
			<Tooltip title='Ver parcelas do contrato'>
				<IconButton onClick={() => setExpandedContracts(current => {
              if(current.includes(~~contract.id)){
                return current.filter(item => item !== ~~contract.id)
              } else {
                return current.concat(~~contract.id)
              }
            })}>
					<FormatListNumbered />
				</IconButton>
			</Tooltip>
		</>
	)
}


const ContractInvoicesTable = ({
  changeInvoiceStepTab,
  selectedInvoiceItems,
  setSelectedInvoiceItems,
  registration_product_id
}:{
  changeInvoiceStepTab: (invoice?: InvoiceAttributes, destiny?: string) => void
  selectedInvoiceItems: number[]
  setSelectedInvoiceItems: React.Dispatch<React.SetStateAction<number[]>>
  registration_product_id: string
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [contracts, setContractsData] = React.useState<ContractAttributes[]>([])
  const [expandedContracts, setExpandedContract] = React.useState<number[]>([])
  const columns = ['Número do contrato', "Produto", 'Ações'];
  const fetchContracts = React.useCallback(async () => {
    try {
      const response = await dispatch(
        FETCH_REGISTRATION.request({
          id: registration_product_id,
          params: {
            filters: {
              'include': 'contracts'
            },
          },
        }),
      );
      const { data: { included }} = response
      const includedContracts = filter(included, (item) => item.type === 'contracts') as ContractJson[];
      const contracts = map(includedContracts, contract => {
        return({
          id: contract.id,
          ...contract.attributes
        })
      })
      setContractsData(contracts)  
    } catch (err) {
      dispatch(
        error(
          {
            message: 'Erro ao carregar contratos'
          }
        )
      )
      setContractsData([])
    }
  }, [registration_product_id])
  React.useEffect(() => {
    fetchContracts()
  }, [])
  return (
    <div className={classes.table}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((item, index) => {
                const align = index === 0 ? 'left' : 'center'
                return (
                  <TableCell align={align} key={item}>{item}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.map((contract, index) => {  
              const isLast = index === contracts.length - 1
              const isLastClassName = isLast ? ' last-row ' : ''
              const isExpanded = expandedContracts.includes(~~contract.id)
              const isExpandedClassName = isExpanded ? ' expanded ' : ''
              const className = isLastClassName + isExpandedClassName + 'table-body-row'
              return (
                <>
                  <TableRow className={className} key={contract.contract_number}>
                    <TableCell>{contract.contract_number}</TableCell>
                    <TableCell align='center'>{contract.product_name}</TableCell>
                    <TableCell align='center'>
                      <ContractActions
                        contract={contract}
                        setExpandedContracts={setExpandedContract}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{paddingBottom: 0, paddingTop: 0, ...(isLast && {borderBottom: 0}) }}
                    >
                      <Collapse
                        in={isExpanded}
                        timeout='auto'
                        unmountOnExit
                      >
                        <BillingTableComponent
                          changeInvoiceStepTab={changeInvoiceStepTab}
                          contract_id={contract.id}
                          fetchContracts={fetchContracts}
                          selectedInvoiceItems={selectedInvoiceItems}
                          setSelectedInvoiceItems={setSelectedInvoiceItems}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
            </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ContractInvoicesTable