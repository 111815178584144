import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_TRANSACTIONS,
  CREATE_TRANSACTION,
  FetchTransactionsPayload,
  CreateTransactionPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchTransactions (action: DefaultAction<FetchTransactionsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchTransactions, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_TRANSACTIONS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_TRANSACTIONS.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * createTransactions (action: DefaultAction<CreateTransactionPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'transactions',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createTransaction, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_TRANSACTION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_TRANSACTION.failure(e))
    rejectPromiseAction(action, e)
  }
}



export function * watchTransactionsSagas () {
  yield takeLatest(FETCH_TRANSACTIONS.request, fetchTransactions)
  yield takeLatest(CREATE_TRANSACTION.request, createTransactions)
}

export default function * transactionsSagas () {
  yield all([watchTransactionsSagas()])
}
