
import React from 'react'
import { Field } from 'redux-form'
import UploadFileComponent from '../shared/UploadFileComponent'
import { FormFileType, DefaultOptionType } from '../../utils/constants'
import AutoCompleteComponent from '../input/form/autocomplete'

const ContractSettingSignerForm = ({
  clearFileField,
  fileValue,
  userOptions,
  loading,
  fetchUserOptions
} : {
  clearFileField: (e: React.MouseEvent<HTMLButtonElement>) => void
  fileValue: FormFileType
  userOptions: DefaultOptionType[]
  loading: boolean
  fetchUserOptions: (id: string) => Promise<void>
}) => {
  return (
    <div style={{ display: 'grid' }}>
      <div>
        <Field
          name='user_id'
          label={'Nome ou CPF'}
          placeholder={'Nome ou CPF'}
          component={AutoCompleteComponent}
          autoCompleteProps={{
            filterOptions: (x: any) => x,
            loading,
            getValueOnChange: true,
            fetchOptions: fetchUserOptions
          
          }}
          options={userOptions}
        />
      </div>
      <UploadFileComponent fileValue={fileValue} clearFileField={clearFileField} name={'file'} fileLabel='Imagem de Assinatura' />
    </div>
  )
}

export default ContractSettingSignerForm