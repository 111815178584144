/* eslint-disable camelcase */
import React from 'react'
import { css } from "@emotion/react"
import { Field } from "redux-form"
import { paymentCollectionKindOptions, PaymentCollectionKindTypes, validation } from "../../utils/constants"
import DatePickerComponent from "../input/form/datepicker"
import InputComponent from "../input/form/input"
import SelectComponent from "../input/form/select"
import TextAreaComponent from "../input/form/textarea"


const PaymentCollectionForm = ({
  kind,
} : {
  kind: PaymentCollectionKindTypes
}) => {
  return (
    <div
      css={
        css`
          display: grid;
          row-gap: 1rem;
        `
      }
    >
      <Field
        name='description'
        component={TextAreaComponent}
        label={'Descrição'}
        placeholder={'Insira a descrição da configuração de pagamento'}
        validate={[validation.required]}               
      />

      <div
        css={css`
          display: grid;
          grid-template-columns: 49% 49%;
          justify-content: space-between;
        `}
      >
        <Field
          name='kind'
          component={SelectComponent}
          options={paymentCollectionKindOptions}
          label={'Tipo de parcela'}
          placeholder={'Selecione o tipo de parcela'}          
        />
        <Field
          name='number'
          component={InputComponent}
          disabled={kind === 'advance'}
          label={'Número da parcela'}
          onlyNumbers
          placeholder={'Número referente a parcela'}
          validate={[validation.required]}    
        />
        <Field
          id='expiration_date'
          name={`expiration_date`}
          label={'Vencimento *'}
          placeholder={'01/01/2021'}
          component={DatePickerComponent}
          datePickerProps={{
            showDropdownIcon: true
          }}
          validate={[validation.dateRequired]}
        />
        <Field
          name={`punctuality_expiration_date`}
          label={'Data limite do desconto condicional'}
          placeholder={'01/01/2021'}
          component={DatePickerComponent}
        />
        </div>
    </div>
  )
}

export default PaymentCollectionForm