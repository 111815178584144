import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const roomsEndpoint = '/rooms'

export const fetchRooms = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${roomsEndpoint}?${queryParams}`)
}

export const fetchRoom = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${roomsEndpoint}/${id}?${queryParams}`)
}

export const createRoom = (data: object) => {
  return http.post(`${roomsEndpoint}`, data)
}

export const updateRoom = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${roomsEndpoint}/${id}`, data)
}

export const deleteRoom = ({ id }: { id: string | number } ) => {
  return http.delete(`${roomsEndpoint}/${id}`)
}