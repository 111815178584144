import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_SUBJECT, FETCH_SUBJECTS } from '../store/subjects';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { SubjectAttributes, defaultPagination, PaginationType, SubjectFieldEnum } from '../utils/constants';
import { RootState } from '../store/configureStore';
import { get_company_search } from '../utils/functions';
import SubjectsView from '../components/view/Pedagogic/SubjectsView';

const SubjectsContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants },
    account: { companies },
  } = state;

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [subjects, setSubjects] = React.useState<SubjectAttributes[]>([]);
  const [fieldFilterValue, setFieldFilterValue] = React.useState<SubjectFieldEnum | string>('');
  const [nameFilterValue, setNameFilterValue] = React.useState('');
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const fetchSubjectsMethod = React.useCallback(
    async ({
      newPagination = pagination,
      company_filter = companyFilterValue,
      field_filter = fieldFilterValue
    }: {
      newPagination?: PaginationType;
      company_filter?: string;
      field_filter?: SubjectFieldEnum | string
    }) => {
      setLoading(true);
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company,
      });
      try {
        const subjects = await dispatch(
          FETCH_SUBJECTS.request({
            params: {
              filters: {
                'q[name_cont]': nameFilterValue,
                'q[field_eq]': field_filter,
                'q[company_id_in]': companies_array,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
              },
            },
          }),
        );
        const {
          data: { data, meta },
        } = subjects;
        const formattedSubjects = data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setSubjects(formattedSubjects);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar turmas',
          }),
        );
      }
    },
    [pagination, nameFilterValue, company, companyFilterValue, fieldFilterValue],
  );

  const deleteSubject = React.useCallback(
    async (
      id: string,
      {
        setLoading,
        handleClose,
      }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; handleClose: () => void },
    ) => {
      try {
        setLoading(true);
        await dispatch(
          DELETE_SUBJECT.request({
            id,
          }),
        );
        setLoading(false);
        dispatch(
          success({
            message: 'Disciplina removido com sucesso',
          }),
        );
        fetchSubjectsMethod({});
        handleClose();
      } catch (e) {
        handleClose();
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao remover disciplina',
          }),
        );
      }
    },
    [],
  );
  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchSubjectsMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchSubjectsMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  const loadAssets = React.useCallback(async () => {
    await fetchSubjectsMethod({});
  }, [company]);

  React.useEffect(() => {
    loadAssets();
  }, []);

  return (
    <SubjectsView
      subjects={subjects}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      nameFilterValue={nameFilterValue}
      setNameFilterValue={setNameFilterValue}
      fetchSubjectsMethod={fetchSubjectsMethod}
      setCompanyFilterValue={setCompanyFilterValue}
      setFieldFilterValue={setFieldFilterValue}
      companyFilterValue={companyFilterValue}
      deleteSubject={deleteSubject}
      fieldFilterValue={fieldFilterValue}
    />
  );
};

export default SubjectsContainer;
