/* eslint-disable camelcase */
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { Paths, validation } from '../../utils/constants'
import InputComponent from '../input/form/input'
import TextAreaComponent from '../input/form/textarea'

const useStyles = makeStyles((): {
  view: any,
  title: any,
  form: any,
  buttons: any
} => ({
  view: {
    width: 'inherit',
    height: 'fit-content',
    flexGrow: '1',
    display: 'flex',
    padding: '2rem 10%',
    flexDirection: 'column',
    position: 'relative',
    '& .MuiBackdrop-root': {
      position: 'inherit'
    },
    '& .MuiPaper-root': {
      background: 'none',
      boxShadow: 'none'
    }
  },
  title: {
    alignSelf: 'flex-start',
    marginBottom: '1rem',
    padding: '0 1rem'
  },
  form: {
    display: 'grid',
    rowGap: '1rem',
    background: '#FDFEFF',
    padding: '1rem'
  },

  buttons: {
    margin: '2rem 1rem',
    display: 'flex',
    justifyContent: 'end',
    gap: '1rem'
  },
}))


const CoursesForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
}) => {
  const { handleSubmit, onSubmit } = props
  const classes = useStyles()
  return(
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar curso</span>
      <form className={classes.form}>
        <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between'}}>
          <Field
            name='name'
            component={InputComponent}
            label={'Nome'}
            placeholder={'Insira o nome do curso'}
            validate={[validation.required]}
          />
          <Field
            name='code'
            component={InputComponent}
            label={'Código'}
            placeholder={'Insira o código do curso'}
            validate={[validation.required]}
          />
        </div>
        <Field
          name='description'
          component={TextAreaComponent}
          label={'Descrição'}
          placeholder={'Insira a descrição do curso'}
        />
        <div className={classes.buttons}>
          <Link to={Paths.COURSES_LIST} className='red small'>
            <span> Cancelar </span>
          </Link>
          <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar curso </span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default compose<any>(
  reduxForm({
      form: 'courseForm',
  }),
)(CoursesForm);
