/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'

export interface RoomStudentAttrs {
  active: boolean
  ends_at: string
  ktwelve_curriculum_registration_id: number
  product_id: number
  product_name: string
  registration_id: number
  report_url: string
  room_id: number
  room_name: string
  room_student_status_id: number
  room_student_status_name: string
  starts_at: string
  status_name: string
  student_name: string
  year: number
}

export interface RelocateRoomStudentFormData {
  current_room_student_attributes: {
    ends_at: string | Date
  }
  new_room_student_attributes: {
    starts_at: string | Date
    room_id: number
  }
}
export type RoomStudentJson = JsonFormat<'room_students', RoomStudentAttrs>

export type FetchRoomStudentsResponse = JsonResponseFormat<RoomStudentJson[]>
export type FetchRoomStudentResponse = JsonResponseFormat<RoomStudentJson>

export type FetchRoomStudentsPayload = WithRequestParams
export interface FetchRoomStudentPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateRoomStudentPayload {
  data: Partial<RoomStudentAttrs>
}

export interface UpdateRoomStudentPayload extends CreateRoomStudentPayload {
  id: string
}

export interface DeleteRoomStudentPayload {
  id: string
}

export interface RelocateRoomStudentPayload {
  data: RelocateRoomStudentFormData,
  id: string
}

export const FETCH_ROOM_STUDENTS = createPromiseAction('room_students: FETCH_ROOM_STUDENTS')<FetchRoomStudentsPayload, FetchRoomStudentsResponse, any>()
export const FETCH_ROOM_STUDENT = createPromiseAction('room_students: FETCH_ROOM_STUDENT')<FetchRoomStudentPayload, FetchRoomStudentResponse, any>()
export const CREATE_ROOM_STUDENT = createPromiseAction('room_students: CREATE_ROOM_STUDENT')<CreateRoomStudentPayload, FetchRoomStudentResponse, any>()
export const UPDATE_ROOM_STUDENT = createPromiseAction('room_students: UPDATE_ROOM_STUDENT')<UpdateRoomStudentPayload, FetchRoomStudentResponse, any>()
export const DELETE_ROOM_STUDENT = createPromiseAction('room_students: DELETE_ROOM_STUDENT')<DeleteRoomStudentPayload, AxiosResponse, any>()
export const RELOCATE_ROOM_STUDENT = createPromiseAction('room_students: RELOCATE_ROOM_STUDENT')<RelocateRoomStudentPayload, FetchRoomStudentResponse, any>()

