/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { ContractStatusTypes, JsonFormat, JsonResponseFormat, PaymentDataItems, PaymentOptionAttributes, WithRequestParams } from '../../utils/constants'
import { AccountJson } from '../accounts'
import { ClassroomJson } from '../classrooms'
import { KtwelveJson } from '../ktwelves'
import { ProductJson } from '../products'
import { RegistrationJson } from '../registrations'
import { RepresentativeJson, RepresentativeProductJson } from '../representatives'
import { UserJson } from '../users'
import { BillingJson } from '../billings'
import { InvoiceJson } from '../invoices'
import { InvoiceItemJson } from '../invoice_items'
import { PaymentCollectionJson, PaymentOptionJson } from '../payment_options'
import { RegistrationProductJson } from '../registration_products'
import { WalletCreditJson } from '../wallet_credits'

export interface ContractAttrs {
  company_name: string
  contract_number: string
  created_at: string
  description: string
  file_url: string;
  payment_option: PaymentOptionAttributes
  payment_simulation: PaymentDataItems
  product_id: number
  product_name: string
  registration_id: number
  representative_id: number
  representative_name: string
  representative_product_id: number
  sent_at: string
  signed_at: string
  signed_file_url: string;
  status: ContractStatusTypes
  student_name: string
}

export interface SendContractParams {
  registration_id: number
  representative_id: number
}

export type ContractJson = JsonFormat<'contracts', ContractAttrs>
export type FetchContractsResponse = JsonResponseFormat<ContractJson[], (
  RegistrationJson | RepresentativeJson | ClassroomJson | KtwelveJson | UserJson | AccountJson | RepresentativeProductJson |
   ProductJson | BillingJson | InvoiceJson | InvoiceItemJson | PaymentOptionJson | PaymentCollectionJson | RegistrationProductJson)[]>
export type FetchContractResponse = JsonResponseFormat<ContractJson, (
  RegistrationJson | RepresentativeJson | ClassroomJson | KtwelveJson | UserJson | AccountJson | RepresentativeProductJson | WalletCreditJson|
   ProductJson | BillingJson | InvoiceJson | InvoiceItemJson | PaymentOptionJson | PaymentCollectionJson | RegistrationProductJson)[]>
export type CreateContractResponse = JsonResponseFormat<ContractJson>
export type FetchContractsPayload = WithRequestParams
export interface SendContractPayload {
  data: SendContractParams;
  id: string | number;
}

export interface ResendContractPayload {
  id: string
}

export interface RefreshContractPayload {
  id: string
}
export interface CreateContractPayload {
  data: object;
}

export interface FetchContractPayload extends WithRequestParams {
  id: string | number;
}

export interface DeleteContractPayload {
  id: string
}

export interface SignContractsBatchPayload {
  data: {
    contract_ids: (string|number)[]
  }
}


export const CREATE_CONTRACT = createPromiseAction('contracts: CREATE_CONTRACT')<CreateContractPayload, CreateContractResponse, any>()
export const SEND_CONTRACT = createPromiseAction('contract: SEND_CONTRACT')<SendContractPayload, AxiosResponse, any>()
export const FETCH_CONTRACTS = createPromiseAction('contracts: FETCH_CONTRACTS')<FetchContractsPayload, FetchContractsResponse, any>()
export const FETCH_CONTRACT = createPromiseAction('contracts: FETCH_CONTRACT')<FetchContractPayload, FetchContractResponse, any>()
export const DELETE_CONTRACT = createPromiseAction('contracts: DELETE_CONTRACT')<DeleteContractPayload, AxiosResponse, any>()
export const SIGN_CONTRACTS_BATCH = createPromiseAction('contracts: SIGN_CONTRACTS')<SignContractsBatchPayload, AxiosResponse, any>()
export const RESEND_CONTRACT = createPromiseAction('contracts: RESEND_CONTRACT')<ResendContractPayload, AxiosResponse, any>()
export const GENERATE_BILLINGS = createPromiseAction('contracts: GENERATE_BILLINGS')<ResendContractPayload, AxiosResponse, any>()
export const REFRESH_CONTRACT = createPromiseAction('contracts: REFRESH_CONTRACT')<RefreshContractPayload, FetchContractResponse, any>()
