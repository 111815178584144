import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface RaceAttrs {
  name: string
  description: string
}

export interface UserRaceAttrs {
  name: string
  description: string
  user_id: number
  race_id: number
}

export type RaceJson = JsonFormat<'races', RaceAttrs>
export type UserRaceJson = JsonFormat<'user_races', UserRaceAttrs>

export type FetchRacesResponse = JsonResponseFormat<RaceJson[]>

export type FetchRaceResponse = JsonResponseFormat<RaceJson>
export type FetchRacesPayload = WithRequestParams

export interface FetchRacePayload extends WithRequestParams {
  id: string | number,
}

export const FETCH_RACES = createPromiseAction('races: FETCH_RACES')<FetchRacesPayload, FetchRacesResponse, any>()
export const FETCH_RACE = createPromiseAction('races: FETCH_RACE')<FetchRacePayload, FetchRaceResponse, any>()
