import { all, call, takeLatest, put } from 'redux-saga/effects'

import { FETCH_ABSENCE_JUSTIFICATIONS, FetchAbsenceJustificationsPayload, FetchAbsenceJustificationPayload, FETCH_ABSENCE_JUSTIFICATION } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchAbsenceJustifications (action: DefaultAction<FetchAbsenceJustificationsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchAbsenceJustifications, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ABSENCE_JUSTIFICATIONS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ABSENCE_JUSTIFICATIONS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchAbsenceJustification (action: DefaultAction<FetchAbsenceJustificationPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchAbsenceJustification, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ABSENCE_JUSTIFICATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ABSENCE_JUSTIFICATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchAbsenceJustificationsSagas () {
  yield takeLatest(FETCH_ABSENCE_JUSTIFICATIONS.request, fetchAbsenceJustifications)
  yield takeLatest(FETCH_ABSENCE_JUSTIFICATION.request, fetchAbsenceJustification)
}

export default function * absenceJustificationSagas () {
  yield all([watchAbsenceJustificationsSagas()])
}
