/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'

export interface TeacherClassTimeAttrs {
  class_time_subject_id: number
  teacher_subject_id: number
  teacher_name: string
  subject_name: string
  teacher_id: number
}
export type TeacherClassTimeJson = JsonFormat<'teacher_class_times', TeacherClassTimeAttrs>

export type FetchTeacherClassTimesResponse = JsonResponseFormat<TeacherClassTimeJson[]>
export type FetchTeacherClassTimeResponse = JsonResponseFormat<TeacherClassTimeJson>

export type FetchTeacherClassTimesPayload = WithRequestParams
export interface FetchTeacherClassTimePayload extends WithRequestParams {
  id: string | number,
}
export interface CreateTeacherClassTimePayload {
  data: Partial<TeacherClassTimeAttrs>
}

export interface UpdateTeacherClassTimePayload extends CreateTeacherClassTimePayload {
  id: string
}

export interface DeleteTeacherClassTimePayload {
  id: string
}

export const FETCH_TEACHER_CLASS_TIMES = createPromiseAction('teacher_class_times: FETCH_TEACHER_CLASS_TIMES')<FetchTeacherClassTimesPayload, FetchTeacherClassTimesResponse, any>()
export const FETCH_TEACHER_CLASS_TIME = createPromiseAction('teacher_class_times: FETCH_TEACHER_CLASS_TIME')<FetchTeacherClassTimePayload, FetchTeacherClassTimeResponse, any>()
export const CREATE_TEACHER_CLASS_TIME = createPromiseAction('teacher_class_times: CREATE_TEACHER_CLASS_TIME')<CreateTeacherClassTimePayload, FetchTeacherClassTimeResponse, any>()
export const UPDATE_TEACHER_CLASS_TIME = createPromiseAction('teacher_class_times: UPDATE_TEACHER_CLASS_TIME')<UpdateTeacherClassTimePayload, FetchTeacherClassTimeResponse, any>()
export const DELETE_TEACHER_CLASS_TIME = createPromiseAction('teacher_class_times: DELETE_TEACHER_CLASS_TIME')<DeleteTeacherClassTimePayload, AxiosResponse, any>()
