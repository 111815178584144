import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const roomSchedulesEndpoint = '/room_schedules'

export const fetchRoomSchedules = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${roomSchedulesEndpoint}?${queryParams}`)
}

export const fetchRoomSchedule = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${roomSchedulesEndpoint}/${id}?${queryParams}`)
}

export const createRoomSchedule = (data: object) => {
  return http.post(`${roomSchedulesEndpoint}`, data)
}

export const updateRoomSchedule = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${roomSchedulesEndpoint}/${id}`, data)
}

export const deleteRoomSchedule = ({ id }: { id: string | number } ) => {
  return http.delete(`${roomSchedulesEndpoint}/${id}`)
}