import React from 'react'
import ProfileDashboardsFormContainer from '../containers/ProfileDashboardsFormContainer'
import navigationHoc from '../components/hoc/navigationHoc'

const ProfileDashboardsFormPage = () => {
  return(
    <ProfileDashboardsFormContainer />
  )
}

export default navigationHoc(ProfileDashboardsFormPage)