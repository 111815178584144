import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const formulasEndpoint = '/formulas'

export const fetchFormulas = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${formulasEndpoint}?${queryParams}`)
}

export const fetchFormula = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${formulasEndpoint}/${id}?${queryParams}`)
}

export const createFormula = (data: object) => {
  return http.post(`${formulasEndpoint}`, data)
}

export const updateFormula = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${formulasEndpoint}/${id}`, data)
}

export const deleteFormula = ({ id }: { id: string | number } ) => {
  return http.delete(`${formulasEndpoint}/${id}`)
}
