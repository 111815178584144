import React from 'react'
import emotionNormalize from 'emotion-normalize'
import { Global, css } from '@emotion/react'
import HauoraRegular from './fonts/Hauora/Hauora-Regular.ttf'
import HauoraBold from './fonts/Hauora/Hauora-Bold.ttf'
import HauoraLight from './fonts/Hauora/Hauora-Light.ttf'
import { colors } from './utils/constants'

export const GlobalStyle = () => (
  <Global
    styles={css`
            ${emotionNormalize} @font-face {
                font-family: 'Patrick Hand SC';
                font-style: normal;
                font-weight: 400;
                src: local('Patrick Hand SC'), local('PatrickHandSC-Regular'),
                    url(https://fonts.gstatic.com/s/patrickhandsc/v4/OYFWCgfCR-7uHIovjUZXsZ71Uis0Qeb9Gqo8IZV7ckE.woff2)
                        format('woff2');
                unicode-range: U + 0100-024f, U + 1-1eff, U + 20a0-20ab, U + 20ad-20cf, U + 2c60-2c7f, U + A720-A7FF;
            }
            @font-face {
                font-family: 'Hauora';
                font-weight: 300;
                src: local('Hauora'), local('Hauora'), url(${HauoraLight}) format('truetype');
            }
            @font-face {
                font-family: 'Hauora';
                font-weight: 400;
                src: local('Hauora'), local('Hauora'), url(${HauoraRegular}) format('truetype');
            }

            @font-face {
                font-family: 'Hauora';
                font-weight: 700;
                src: local('Hauora'), local('Hauora'), url(${HauoraBold}) format('truetype');
            }

            p,
            span,
            label,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            input,
            textarea,
            button,
            a,
            li {
                font-family: Hauora, sans-serif;
                color: ${colors.darkBlue};
            }

            td,
            th {
                font-family: Hauora, sans-serif !important;
            }

            span.title {
                font-size: 2rem;
                font-weight: bold;
            }

            span.subtitle-one {
                font-size: 1.75rem;
            }
            span.subtitle-two {
                font-size: 1.5rem
            }
            span.subtitle-three {
                font-size: 1.25rem
            }
            button:disabled,
            button[disabled]{
            background-color: #cccccc !important;
            cursor: not-allowed !important;
            }
            button.blue,
            a.blue {
                background: ${colors.blue};
                border: none;
                padding: 1vw 2vw;
                border-radius: 4px;
                cursor: pointer;
                text-align: center;
                text-decoration: none;
                & span,
                svg {
                    color: white;
                    font-size: 1vw;
                }
                &:hover {
                    background: #4c98bd;
                }
            }

            button.red,
            a.red {
                background: ${colors.lightRed};
                border: none;
                padding: 1vw 2vw;
                border-radius: 4px;
                cursor: pointer;
                text-align: center;
                color: white;
                text-decoration: none;
                & > span {
                    color: white;
                    font-size: 1vw;
                }
                &:hover {
                    background: #cc8080;
                }
            }

            button.gray {
                background: #d4d4d4;
                border: none;
                padding: 1vw 2vw;
                border-radius: 4px;
                cursor: pointer;
                text-align: center;
                color: white;
                & > span {
                    color: white;
                    font-size: 1vw;
                }
                &:hover {
                    background: #a9a9a9;
                }
            }

            button.green {
                background: ${colors.green};
                border: none;
                padding: 1vw 2vw;
                border-radius: 4px;
                cursor: pointer;
                text-align: center;
                color: white;
                & > span {
                    color: white;
                    font-size: 1vw;
                }
                &:hover {
                    background: #4cb2a0;
                }
            }

            button.white {
                background: white;
                border: none;
                padding: 1vw 2vw;
                border-radius: 4px;
                cursor: pointer;
                text-align: center;
                &:hover {
                    background: #cccccc;
                }
            }

            button.darkBlue {
                background: ${colors.darkBlue};
                border: none;
                padding: 1vw 2vw;
                border-radius: 4px;
                cursor: pointer;
                text-align: center;
                & > span {
                    color: white;
                    font-size: 1vw;
                }
                &:hover {
                    background: #454560;
                }
            }

            button.small, a.small {
                padding: 0.5vw 4vw;
                font-size: 1vw;
            }

            button.medium, a.medium {
                padding: 1vw 4vw;
                font-size: 1.25vw;
            }
        `}
  />
)
