import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const citiesEndpoint = '/cities'

export const fetchCities = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${citiesEndpoint}?${queryParams}`)
}

export const fetchCity = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${citiesEndpoint}/${id}?${queryParams}`)
}