import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_REGISTRATION_CONNECTIONS,
  FetchRegistrationConnectionsPayload,
  DeleteRegistrationConnectionPayload,
  DELETE_REGISTRATION_CONNECTION
} from '.'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRegistrationConnections (action: DefaultAction<FetchRegistrationConnectionsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchRegistrationConnections, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_REGISTRATION_CONNECTIONS.request(response))
    resolvePromiseAction(action, response)

  } catch(e) {
    yield put(FETCH_REGISTRATION_CONNECTIONS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteRegistrationConnection (action: DefaultAction<DeleteRegistrationConnectionPayload, string>): any {
  const { payload: {id}} = action
  try {
    const response = yield call(services.deleteRegistrationConnection, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_REGISTRATION_CONNECTION.success(response))
    resolvePromiseAction(action, response)

  } catch(e) {
    yield put(DELETE_REGISTRATION_CONNECTION.failure(e))
    rejectPromiseAction(action, e)
    
  }
}


export function * watchRegistrationConnectionsSagas () {
  yield takeLatest(FETCH_REGISTRATION_CONNECTIONS.request, fetchRegistrationConnections)
  yield takeLatest(DELETE_REGISTRATION_CONNECTION.request, deleteRegistrationConnection)
}

export default function * registrationConnectionsSagas () {
  yield all([watchRegistrationConnectionsSagas()])
}
