import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  CREATE_REGISTRATION,
  UPDATE_REGISTRATION,
  FETCH_REGISTRATION,
  FETCH_REGISTRATIONS,
  DELETE_REGISTRATION,
  CreateRegistrationPayload,
  UpdateRegistrationPayload,
  FetchRegistrationPayload,
  FetchRegistrationsPayload,
  DeleteRegistrationPayload,
  FETCH_STUDENTS_MISSING_REGISTRATIONS
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'
import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'
import { FetchStudentsMissingrRegistrationProductsPayload } from '../registration_products'

export function * createRegistration (action: DefaultAction<CreateRegistrationPayload, string>): any {
  const { payload: {data}} = action
  const params = data instanceof FormData ? data : {
    data: {
      type: 'registrations',
      attributes: { ...data },
    }
  }
  try {
    const response = yield call(services.createRegistration, params)
    const {headers} = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateRegistration (action: DefaultAction<UpdateRegistrationPayload, string>): any {
  const {payload: {data, id}} = action
  try {
    const params = data instanceof FormData ? data : {
      data: {
        id,
        type: 'registrations',
        attributes: { ...data },
      }
    }
  
    const response = yield call(services.updateRegistration, {
      params,
      id
    })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(UPDATE_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchRegistrations (action: DefaultAction<FetchRegistrationsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchRegistrations, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_REGISTRATIONS.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(FETCH_REGISTRATIONS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchStudentMissingRegistrations (action: DefaultAction<FetchStudentsMissingrRegistrationProductsPayload, string>): any {
  const { payload: { product_id, params } } = action
  try {
    const response = yield call(services.fetchStudentMissingRegistrations, {product_id, params})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_STUDENTS_MISSING_REGISTRATIONS.success(response))
    resolvePromiseAction(action, response)
    
  } catch(err) {
    yield put(FETCH_STUDENTS_MISSING_REGISTRATIONS.failure(err))
    rejectPromiseAction(action, err)
  }
}


export function * fetchRegistration (action: DefaultAction<FetchRegistrationPayload, string>): any {
  const { payload: {id, params} } = action
  try {
    const response = yield call(services.fetchRegistration, { id, params })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteRegistration (action: DefaultAction<DeleteRegistrationPayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.deleteRegistration, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_REGISTRATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_REGISTRATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRegistrationSagas () {
  yield takeLatest(CREATE_REGISTRATION.request, createRegistration)
  yield takeLatest(UPDATE_REGISTRATION.request, updateRegistration)
  yield takeLatest(FETCH_REGISTRATIONS.request, fetchRegistrations)
  yield takeLatest(FETCH_REGISTRATION.request, fetchRegistration)
  yield takeLatest(DELETE_REGISTRATION.request, deleteRegistration)
  yield takeLatest(FETCH_STUDENTS_MISSING_REGISTRATIONS.request, fetchStudentMissingRegistrations)
}

export default function * registrationSagas () {
  yield all([watchRegistrationSagas()])
}
