/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'

export interface TeacherSubjectAttrs {
  ktwelve_subject_id: number
  teacher_id: string
  teacher_name: string
  subject_name: string
  ktwelve_degree_name: string
}
export type TeacherSubjectJson = JsonFormat<'teacher_subjects', TeacherSubjectAttrs>

export type FetchTeacherSubjectsResponse = JsonResponseFormat<TeacherSubjectJson[]>
export type FetchTeacherSubjectResponse = JsonResponseFormat<TeacherSubjectJson>

export type FetchTeacherSubjectsPayload = WithRequestParams
export interface FetchTeacherSubjectPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateTeacherSubjectPayload {
  data: Partial<TeacherSubjectAttrs>
}

export interface UpdateTeacherSubjectPayload extends CreateTeacherSubjectPayload {
  id: string
}

export interface DeleteTeacherSubjectPayload {
  id: string
}

export const FETCH_TEACHER_SUBJECTS = createPromiseAction('teacher_subjects: FETCH_TEACHER_SUBJECTS')<FetchTeacherSubjectsPayload, FetchTeacherSubjectsResponse, any>()
export const FETCH_TEACHER_SUBJECT = createPromiseAction('teacher_subjects: FETCH_TEACHER_SUBJECT')<FetchTeacherSubjectPayload, FetchTeacherSubjectResponse, any>()
export const CREATE_TEACHER_SUBJECT = createPromiseAction('teacher_subjects: CREATE_TEACHER_SUBJECT')<CreateTeacherSubjectPayload, FetchTeacherSubjectResponse, any>()
export const UPDATE_TEACHER_SUBJECT = createPromiseAction('teacher_subjects: UPDATE_TEACHER_SUBJECT')<UpdateTeacherSubjectPayload, FetchTeacherSubjectResponse, any>()
export const DELETE_TEACHER_SUBJECT = createPromiseAction('teacher_subjects: DELETE_TEACHER_SUBJECT')<DeleteTeacherSubjectPayload, AxiosResponse, any>()
