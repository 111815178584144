/* eslint-disable camelcase */
import { createAction, createReducer } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { withPayloadType } from '../../utils/functions'

export interface AccountAttrs {
  active: boolean,
  user_id: number,
  company_id: number
  student_code: string
  user_name: string
}

export type AccountJson = JsonFormat<'accounts', AccountAttrs>

export interface CreateAccountPayload {
  data: object;
}
export type FetchAccountResponse = JsonResponseFormat<AccountJson>
export type FetchAccountsResponse = JsonResponseFormat<AccountJson[]>


export type FetchAccountsPayload = WithRequestParams

export interface UpdateAccountPayload extends CreateAccountPayload {
  id: string | number;
}


export interface FetchAccountSessionPayload {
  id: string | number;
  changeProfile?: boolean
}

export interface AccountStateType {
  current: object | null;
  companies: [],
  isFetching: boolean;
  error: null;
}

export interface FetchAccountSessionSuccessPayload {
  current: object;
  companies: [];
}

export interface FetchAccountPayload extends WithRequestParams {
  id: string | number;
  afterEffect(res:FetchAccountResponse): void;
  handleErrorEffect(): void;
}

export interface UpdateStoredCompaniesPayload {
  companies: [];
}

export const FETCH_ACCOUNTS = createPromiseAction('accounts: FETCH_ACCOUNTS')<FetchAccountsPayload, FetchAccountsResponse, any>()
export const CREATE_ACCOUNT = createPromiseAction('accounts: CREATE_ACCOUNT')<CreateAccountPayload, FetchAccountResponse, any>()

export const FETCH_ACCOUNT_REQUEST = createAction('accounts: FETCH_ACCOUNT_REQUEST', withPayloadType<FetchAccountPayload>())

export const FETCH_ACCOUNT_SUCCESS = createAction('accounts: FETCH_ACCOUNT_SUCCESS')

export const FETCH_ACCOUNT_FAILURE = createAction('accounts: FETCH_ACCOUNT_FAILURE')

export const FETCH_ACCOUNT_SESSION_REQUEST = createAction('accounts: FETCH_ACCOUNT_SESSION_REQUEST', withPayloadType<FetchAccountSessionPayload>())

export const FETCH_ACCOUNT_SESSION_SUCCESS = createAction('accounts: FETCH_ACCOUNT_SESSION_SUCCESS', withPayloadType<FetchAccountSessionSuccessPayload>())

export const FETCH_ACCOUNT_SESSION_FAILURE = createAction('accounts: FETCH_ACCOUNT_SESSION_FAILURE')

export const UPDATE_ACCOUNT = createPromiseAction('accounts: UPDATE_ACCOUNT_REQUEST')<UpdateAccountPayload, FetchAccountResponse, any>()
export const UPDATE_STORED_COMPANIES = createAction('accounts: UPDATE_STORED_COMPANIES', withPayloadType<UpdateStoredCompaniesPayload>())

const initialState: AccountStateType = {
  current: null,
  companies: [],
  isFetching: false,
  error: null
}

export const accountReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      FETCH_ACCOUNT_SESSION_REQUEST,
      (state: AccountStateType): AccountStateType => ({
        ...state,
        isFetching: true
      })
    )
    .addCase(
      FETCH_ACCOUNT_SESSION_SUCCESS,
      (state: AccountStateType, action: { payload: any }): AccountStateType => ({
        ...state,
        isFetching: false,
        current: action.payload.current,
        companies: action.payload.companies
      })
    )
    .addCase(
      UPDATE_STORED_COMPANIES,
      (state: AccountStateType, action: { payload: any }): AccountStateType => ({
        ...state,
        companies: action.payload.companies
      })
    )
    .addCase(
      FETCH_ACCOUNT_FAILURE,
      (state: AccountStateType): AccountStateType => ({
        ...state,
        isFetching: false
      })
    )
})
