import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_TEACHER_SUBJECT, CreateTeacherSubjectPayload, DELETE_TEACHER_SUBJECT, DeleteTeacherSubjectPayload, FETCH_TEACHER_SUBJECT, FETCH_TEACHER_SUBJECTS, FetchTeacherSubjectPayload, FetchTeacherSubjectsPayload, UPDATE_TEACHER_SUBJECT, UpdateTeacherSubjectPayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchTeacherSubjects (action: DefaultAction<FetchTeacherSubjectsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchTeacherSubjects, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_TEACHER_SUBJECTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_TEACHER_SUBJECTS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchTeacherSubject (action: DefaultAction<FetchTeacherSubjectPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchTeacherSubject, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_TEACHER_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_TEACHER_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createTeacherSubject (action: DefaultAction<CreateTeacherSubjectPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'teacher_subjects',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createTeacherSubject, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_TEACHER_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_TEACHER_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateTeacherSubject (action: DefaultAction<UpdateTeacherSubjectPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'teacher_subjects',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateTeacherSubject, {data: params, id})
    yield put(UPDATE_TEACHER_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_TEACHER_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteTeacherSubject (action: DefaultAction<DeleteTeacherSubjectPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteTeacherSubject, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_TEACHER_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_TEACHER_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchTeacherSubjectsSagas () {
  yield takeLatest(FETCH_TEACHER_SUBJECTS.request, fetchTeacherSubjects)
  yield takeLatest(FETCH_TEACHER_SUBJECT.request, fetchTeacherSubject)
  yield takeLatest(CREATE_TEACHER_SUBJECT.request, createTeacherSubject)
  yield takeLatest(UPDATE_TEACHER_SUBJECT.request, updateTeacherSubject)
  yield takeLatest(DELETE_TEACHER_SUBJECT.request, deleteTeacherSubject)
}

export default function * teacher_subjectsSagas () {
  yield all([watchTeacherSubjectsSagas()])
}
