import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'

const coursesEndPoint = '/courses'

export const fetchCourses = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${coursesEndPoint}?${queryParams}`)
}
export const fetchCourse = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${coursesEndPoint}/${id}?${queryParams}`)
}

export const createCourse = (data: object) => {
  return http.post(`${coursesEndPoint}`, data)
}

export const updateCourse = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${coursesEndPoint}/${id}`, data)
}