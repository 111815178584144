/* eslint-disable camelcase */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { formValueSelector, change, untouch, isValid } from 'redux-form'
import { error, warning } from 'react-notification-system-redux'

import Loading from '../components/loading/Loading'

import { FETCH_CLASSROOMS } from '../store/classrooms'
import { FETCH_KTWELVES } from '../store/ktwelves'
import { FETCH_PRODUCTS } from '../store/products'
import { FETCH_COURSES } from '../store/courses'

import { resetFields, ensure,
  // logFormData
} from '../utils/functions'
import {
  CurrentRegistrationData,
  DefaultOptionType,
  ProductStepFormData,
  PaymentOptionAttributes,
  ProductOptions,
  periodOptions,
  defaultPagination,
  RegistrationProductFormAttributes,
  RepresentativeAttributes,
  ProductTypesEnum
} from '../utils/constants'

import InsertProductStepForm from '../components/form/InsertProductStepForm'

import { RootState } from '../store/configureStore'
import { FETCH_PAYMENT_OPTIONS } from '../store/payment_options'
import { filter, find, map } from 'lodash'
import { calculateProvisionMonths, getInitialProductPunctualityDiscountPortions, getPaymentSimulationPortions } from '../utils/paymentSimulation'
import { CREATE_REGISTRATION_PRODUCT } from '../store/registration_products'
import { FETCH_REGISTRATION } from '../store/registrations'
import { RepresentativeJson } from '../store/representatives'


const formatRegistrationData = (data: ProductStepFormData, current_registration: CurrentRegistrationData , provision_months: number[], representatives: RepresentativeAttributes[]) => {
  const paymentOption = data['payment_option']
  const portions = getPaymentSimulationPortions({payment_option_portions: paymentOption.portions, provision_months})
  const punctualityDiscountPortionsAttrs = getInitialProductPunctualityDiscountPortions({ provision_months, portions, advance_discount_value: paymentOption.advance_discount_value })
  const product_id = Number(data['product_id'])
  return {
    product_id,
    payment_option_id: Number(paymentOption['id']),
    starts_at: data['starts_at'],
    active: true,
    registration_id: ~~current_registration.id,
    punctuality_discount_portions: [...punctualityDiscountPortionsAttrs],
    provision_months: [...provision_months],
    representative_products_attributes: data.financial_responsible.map(representative => {
      const findRepresentative = representatives.find(
        representative_attr => representative_attr.id === representative.toString()
      ) as RepresentativeAttributes
      return {
        representative_id: ~~findRepresentative.id,
        product_id,
      }
    })
  }
}

const ProductStepFormContainer = (props: {
  setLoading: (value: boolean) => void
  currentRegistration: CurrentRegistrationData
  fetchRegistrationProducts: () => Promise<void>
  handleTabChange: (_: any, value: number) => void
}) => {
  const {
    setLoading,
    fetchRegistrationProducts,
    currentRegistration,
    handleTabChange,
  } = props
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state)
  const formName = 'registration-productstep'
  const {
    auth: {
      user: { name }, profile: { account_id: loggedInAccountId}
    }
  } = state
  const formValues = formValueSelector(formName)
  const [type, setType] = React.useState(0)
  const [coursesOptions, setCoursesOptions] = React.useState<DefaultOptionType[]>([])
  const [productOptions, setProductOptions] = React.useState<ProductOptions[]>([])
  const [classroomOptions, setClassroomOptions] = React.useState<DefaultOptionType[]>([])
  const [preLoadingOptions, setPreLoadingOptions] = React.useState<string[]>([])
  const [loadingDynamicOptions, setDynamicOptions] = React.useState<string[]>([])
  const [paymentOptionAttributes, setPaymentOptionAttributes] = React.useState<PaymentOptionAttributes[]>([])
  const [pagination, setPagination] = React.useState(defaultPagination)
  const [covenantOptions, setCovenantOptions] = React.useState([])
  const [covenantValue, setCovenantValue] = React.useState('')
  const [currentProduct, setCurrentProduct] = React.useState<ProductOptions | null>(null)
  const [customPeriodOptions, setCustomPeriodOptions] = React.useState<DefaultOptionType[]>(periodOptions)
  const [provisionMonths, setProvisionMonths] = React.useState<number[]>([])
  const [loadingPaymentOptionAttributes, setLoadingPaymentOptionAttributes] = React.useState<boolean>(false)
  const [representatives, setRepresentativesData] = React.useState<RepresentativeAttributes[]>([])


  const paymentOptionAPaymentOptionAttributesRef = React.useRef<HTMLSpanElement>(null)

  const currentPaymentOption = formValues(state, 'payment_option')
  const productValue = formValues(state, 'product_id')
  const courseValue = formValues(state, 'course')
  const fromYearValue = formValues(state, 'from_year')
  const classroomValue = formValues(state, 'classroom')
  const periodValue = formValues(state, 'period')
  const typeValue = formValues(state, 'type')
  const isFormValid = isValid(formName)(state)
  const uniqueIds : string[] = [] ;

  const uniqueRepresentatives = representatives.filter(element => {
    const isDuplicate = uniqueIds.includes(element.id);

    if (!isDuplicate) {
      uniqueIds.push(element.id);

      return true;
    }

    return false;
  });

  const fetchRegistrationRepresentatives = React.useCallback(async () => {
    setPreLoadingOptions((items) => items.concat('representatives'))
    const registration = await dispatch(
      FETCH_REGISTRATION.request({
        id: currentRegistration.id,
        params: {
          filters: {
            include: [
              'representatives',
            ].join(','),
          },
        },
      }),
    );
    const { data: { included } } = registration
    const includedRepresentatives = map(filter(included, (item) => item.type === 'representatives') as RepresentativeJson[], res => ({id: res.id, ...res.attributes}))
    setRepresentativesData(includedRepresentatives)
    setPreLoadingOptions((items) => items.filter((x) => x !== 'representatives'))
  }, [currentRegistration])
  const fetchClassroomsMethod = React.useCallback(async () => {
    if(classroomValue){
      try {
        setDynamicOptions((items) => items.concat('classrooms'))
        const classrooms = await dispatch(
          FETCH_CLASSROOMS.request({
            params: {
              filters: {
                'q[ktwelve_degree_id_eq]': classroomValue,
                'q[company_id_eq]': currentRegistration.company_id.toString(),
              }
            },
          })
        )
  
        const {
          data: { data }
        } = classrooms
        const periods = [...new Set(data.map(item => item.attributes.period))].map(item => {
          return periodOptions.find(x => x.value === item)
        })
        if(periods?.length){
          setCustomPeriodOptions(periods as DefaultOptionType[])
        }
      } catch (err) {
        dispatch(
          error(
            {
              message: 'Erro no carregamento das opções de turma'
            }
          )
        )
      } finally  {
        setDynamicOptions((items) => items.filter((x) => x !== 'classrooms'))
      }  
    } else {
      setCustomPeriodOptions(periodOptions)
    }
  }, [classroomValue, currentRegistration.company_id, setDynamicOptions])

  const fetchKtwelvesMethod = React.useCallback(async () => {
    if(courseValue){
      try {
        setDynamicOptions((items) => items.concat('classrooms'))
        const ktwelves = await dispatch(
          FETCH_KTWELVES.request({
            params: {
              filters: {
                'q[course_id_eq]': courseValue
              }
            },
          })
        )
        const {
          data: { data }
        } = ktwelves
        const getCourseName = ensure(coursesOptions.find((item) => item.value === courseValue)).label
        const formattedData = data.map((item) => {
          return {
            label: `${item.attributes.name} - ${getCourseName}`,
            value: item.id,
          }
        })
        resetFields(formName, ['classroom', 'product', 'period'], dispatch, change, untouch)
        setClassroomOptions(formattedData)
      } catch (err) {
        dispatch(
          error(
            {
              message: 'Erro no carregamento das opções de série'
            }
          )
        )
      } finally {
        setDynamicOptions((items) => items.filter((x) => x !== 'classrooms'))
      }  
    }
  }, [courseValue, setDynamicOptions])

  const fetchCoursesMethod = React.useCallback(async () => {
    try {
      const courses = await dispatch(
        FETCH_COURSES.request({
          params: {
            filters: {
              'q[classrooms_company_id_eq]': currentRegistration.company_id.toString()
            }
          },
        })
      )
      const {
        data: { data }
      } = courses
      const formattedData = data.map((item) => ({
        label: item.attributes.name,
        value: item.id
      }))
      setCoursesOptions(formattedData)
    } catch (err) {
      dispatch(
        error(
          {
            message: 'Erro no carregamento das opções de curso'
          }
        )
      )
    } finally {
      setPreLoadingOptions((items) => items.filter((x) => x !== 'courses'))
    }
  }, [currentRegistration.company_id, setPreLoadingOptions])

  const fetchPaymentOptionAttributesMethod = React.useCallback(async (product: ProductOptions = currentProduct as ProductOptions) => {
    try {
      if(product) {
        let paymentOptionAPaymentOptionAttributesAttrs = {}
        if (covenantValue) {
          paymentOptionAPaymentOptionAttributesAttrs = {
            starts_at: product.periods['starts_at'],
            covenant_id: covenantValue,
          }
        }
        setLoadingPaymentOptionAttributes(true)
        const payment_options = await dispatch(FETCH_PAYMENT_OPTIONS.request({
          params: {
            filters: {
              'q[product_id_eq]': product.value as string,
              ...paymentOptionAPaymentOptionAttributesAttrs
            }
          },
        }))
        const {
          data: { data }
        } = payment_options
        const formattedData = data.map((item) => ({
          ...item.attributes,
          id: item.id
        }))

        setPaymentOptionAttributes(formattedData) 
      }
    } catch (err) {
      dispatch(
        error(
          {
            message: 'Erro no carregamento das opções de pagamento'
          }
        )
      )
    } finally {
      setLoadingPaymentOptionAttributes(false)
    }
  }, [covenantValue, currentProduct])


  
  const fetchProductsMethod = React.useCallback(async ({ pagination } : { pagination: typeof defaultPagination }) => {
    try {
      let fromYearFilter = {}
      if (fromYearValue){
        fromYearFilter = {
          'q[year_eq]': fromYearValue.getFullYear()
        }
      }
      let courseFilter = {}
      if (courseValue) {
        courseFilter = {
          'q[classroom_ktwelve_degree_course_id_eq]': courseValue
        }
      }
      let classroomFilter = {}
      if (classroomValue) {
        classroomFilter = {
          'q[classroom_ktwelve_degree_id_eq]': classroomValue
        }
      }
      let periodFilter = {}
      if (periodValue) {
        periodFilter = {
          'q[classroom_period_eq]': periodValue
        }
      }
      let typeFilter = {}
      if(typeValue){
        typeFilter = {
          'q[kind_eq]': typeValue
        }  
      }
  
      setDynamicOptions((items) => items.concat('products'))
      const products = await dispatch(
        FETCH_PRODUCTS.request({
          params: {
            filters: {
              ...courseFilter,
              ...classroomFilter,
              ...periodFilter,
              ...typeFilter,
              ...fromYearFilter,
              'include': 'periods',
              'page[number]': (pagination.pageNumber + 1).toString(),
              'page[size]': (pagination.pageSize).toString(),
              'q[company_id_eq]': currentRegistration.company_id.toString(),
              'q[active_ends_at_gteq]': new Date().toISOString(),
            }
          },
        })
      )
      const {
        data: { data, included, meta }
      } = products
      const formattedData = data.map((item) => {
        const {
          attributes: { name, available, ...rest }
        } = item
        const productPeriods = included.find(includedItem => includedItem.type === 'product_periods' && includedItem.attributes.product_id === ~~item.id)

        return {
          label: item.attributes.name,
          value: item.id,
          disabled: item.attributes.available < 1,
          available,
          name,
          ...rest,
          periods: {
            ...productPeriods?.attributes
          }
        }
      })
      setPagination((current) => ({
        ...current,
        pageCount: meta.page_count,
        totalCount: meta.total_count
      }))
      setProductOptions(formattedData)
      setDynamicOptions((items) => items.filter((x) => x !== 'products'))

    } catch (err) {
      dispatch(
        error(
          {
            message: 'Erro no carregamento de produtos'
          }  
        )
      )
    } finally {
      setDynamicOptions((items) => items.filter((x) => x !== 'products'))
    }

  }, [fromYearValue, courseValue, classroomValue, periodValue, typeValue, pagination, currentRegistration.company_id, setDynamicOptions])

  const createRegistrationProductMethod = React.useCallback(async (data: RegistrationProductFormAttributes) => {
    try {
      await dispatch(
        CREATE_REGISTRATION_PRODUCT.request({
          data
        })
      )
    } catch (err) {
      dispatch(
        error(
          {
            message: 'Erro ao associar produto na matrícula'
          }  
        )
      )

    }
  }, [])

  const loadPaymentOptions = React.useCallback(async() => {
    if (productValue) {
      setDynamicOptions((items) => items.concat('payment_options'))

      const currentProduct = find(productOptions, (item) => item.value === productValue) as ProductOptions
      const newProvisionMonths = calculateProvisionMonths({
        starts_at: currentProduct.periods.starts_at,
        ends_at: currentProduct.periods.ends_at,
        registration_fee: currentProduct.registration_fee,
        current_date: new Date(),
        product_kind: currentProduct.kind as ProductTypesEnum
      })
      setCurrentProduct(currentProduct)
      await fetchPaymentOptionAttributesMethod(currentProduct)
      setProvisionMonths(newProvisionMonths)
      setDynamicOptions((items) => items.filter((x) => x !== 'payment_options'))
    }
  }, [productValue])

  const initData = async () => {
    await fetchRegistrationRepresentatives()
    await fetchCoursesMethod()
  }

  React.useEffect(() => {
    loadPaymentOptions()
  }, [productValue])

  
  React.useEffect(() => {
    initData()
  }, [])

  React.useEffect(() => {
    fetchKtwelvesMethod()    
  }, [courseValue])

  React.useEffect(() => {
    fetchClassroomsMethod()
  }, [classroomValue])


  React.useEffect(() => {
      resetFields(formName,['product', 'payment_option'], dispatch, change, untouch)
      setCurrentProduct(null)
      setPaymentOptionAttributes([])
      setPagination({ ...defaultPagination, pageSize: pagination.pageSize })
      fetchProductsMethod({ pagination: { ...defaultPagination, pageSize: pagination.pageSize } })  
  }, [
    courseValue,
    classroomValue,
    periodValue,
    fromYearValue,
    typeValue
  ])

  const onSubmitProductStep = React.useCallback(
    async ({ data } : { data: ProductStepFormData }) => {
      setLoading(true)
      try {
        const formData = formatRegistrationData(data, currentRegistration, provisionMonths, representatives)
        await createRegistrationProductMethod(formData)
        await fetchRegistrationProducts()
        handleTabChange(null, 0)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
        dispatch(
          warning({
            message: 'Erro na adição de produto'
          })
        )
      }
    },
    [currentRegistration, provisionMonths, representatives]
  )

  const handlePageChange = React.useCallback((_: any, newPage: number) => {
    setPagination({
      ...pagination,
      pageNumber: newPage
    })
    fetchProductsMethod({
      pagination: {
        ...pagination,
        pageNumber: newPage
      }
    })
  }, [pagination])

  const handleChangePageSize = React.useCallback((e: any) => {
    setPagination({
      ...pagination,
      pageSize: e.target.value
    })
    fetchProductsMethod({ pagination: {...pagination, pageSize: e.target.value } })
  }, [pagination])


  if (preLoadingOptions.length) {
    return <Loading />
  }
 
  return (
    <InsertProductStepForm
      loadingPaymentOptionAttributes={loadingPaymentOptionAttributes}
      paymentOptionAPaymentOptionAttributesRef={paymentOptionAPaymentOptionAttributesRef}
      name={name}
      loggedInAccountId={loggedInAccountId}
      setType={setType}
      type={type}
      classroomOptions={classroomOptions}
      loadingDynamicOptions={loadingDynamicOptions}
      coursesOptions={coursesOptions}
      productOptions={productOptions}
      currentProduct={currentProduct}
      paymentOptionAttributes={paymentOptionAttributes}
      formValues={formValues}
      representatives={uniqueRepresentatives}
      handleTabChange={handleTabChange}
      onSubmit={onSubmitProductStep}
      currentPaymentOption={currentPaymentOption}
      pagination={pagination}
      setPagination={setPagination}
      covenantOptions={covenantOptions}
      setCovenantOptions={setCovenantOptions}
      covenantValue={covenantValue}
      setCovenantValue={setCovenantValue}
      handleChangePageSize={handleChangePageSize}
      handlePageChange={handlePageChange}
      productValue={productValue}
      periodOptions={customPeriodOptions}
      currentRegistration={currentRegistration}
      provisionMonths={provisionMonths}
      isFormValid={isFormValid}
    />
  )
}

export default ProductStepFormContainer
