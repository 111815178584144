import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_ROOM_STUDENT, CreateRoomStudentPayload, DELETE_ROOM_STUDENT, DeleteRoomStudentPayload, FETCH_ROOM_STUDENT, FETCH_ROOM_STUDENTS, FetchRoomStudentPayload, FetchRoomStudentsPayload, RELOCATE_ROOM_STUDENT, RelocateRoomStudentPayload, UPDATE_ROOM_STUDENT, UpdateRoomStudentPayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRoomStudents (action: DefaultAction<FetchRoomStudentsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchRoomStudents, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ROOM_STUDENTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ROOM_STUDENTS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchRoomStudent (action: DefaultAction<FetchRoomStudentPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchRoomStudent, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ROOM_STUDENT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ROOM_STUDENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createRoomStudent (action: DefaultAction<CreateRoomStudentPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'room_students',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createRoomStudent, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_ROOM_STUDENT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_ROOM_STUDENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateRoomStudent (action: DefaultAction<UpdateRoomStudentPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'room_students',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateRoomStudent, {data: params, id})
    yield put(UPDATE_ROOM_STUDENT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_ROOM_STUDENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * relocateRoomStudent (action: DefaultAction<RelocateRoomStudentPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'room_students',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.relocateRoomStudent, {data: params, id})
    yield put(RELOCATE_ROOM_STUDENT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(RELOCATE_ROOM_STUDENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteRoomStudent (action: DefaultAction<DeleteRoomStudentPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteRoomStudent, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_ROOM_STUDENT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_ROOM_STUDENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRoomStudentsSagas () {
  yield takeLatest(FETCH_ROOM_STUDENTS.request, fetchRoomStudents)
  yield takeLatest(FETCH_ROOM_STUDENT.request, fetchRoomStudent)
  yield takeLatest(CREATE_ROOM_STUDENT.request, createRoomStudent)
  yield takeLatest(UPDATE_ROOM_STUDENT.request, updateRoomStudent)
  yield takeLatest(DELETE_ROOM_STUDENT.request, deleteRoomStudent)
  yield takeLatest(RELOCATE_ROOM_STUDENT.request, relocateRoomStudent)
}

export default function * roomStudentsSagas () {
  yield all([watchRoomStudentsSagas()])
}
