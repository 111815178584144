/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'
import { RoomStudentJson } from '../room_students'
import { RoomScheduleJson } from '../room_schedules'

export interface RoomAttrs {
  product_id: number
  name: string
  product_name: string
  ktwelve_degree_name: string
  company_name: string
  company_id: number
  product_year: number
  interval_length: number
  class_time_length: number
  ktwelve_curriculum_id: number
  last_day: string
}
export type RoomJson = JsonFormat<'rooms', RoomAttrs>

export type FetchRoomsResponse = JsonResponseFormat<RoomJson[]>
export type FetchRoomResponse = JsonResponseFormat<RoomJson, (RoomStudentJson|RoomScheduleJson)[]>

export type FetchRoomsPayload = WithRequestParams
export interface FetchRoomPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateRoomPayload {
  data: Partial<RoomAttrs>
}

export interface UpdateRoomPayload extends CreateRoomPayload {
  id: string
}

export interface DeleteRoomPayload {
  id: string
}

export const FETCH_ROOMS = createPromiseAction('rooms: FETCH_ROOMS')<FetchRoomsPayload, FetchRoomsResponse, any>()
export const FETCH_ROOM = createPromiseAction('rooms: FETCH_ROOM')<FetchRoomPayload, FetchRoomResponse, any>()
export const CREATE_ROOM = createPromiseAction('rooms: CREATE_ROOM')<CreateRoomPayload, FetchRoomResponse, any>()
export const UPDATE_ROOM = createPromiseAction('rooms: UPDATE_ROOM')<UpdateRoomPayload, FetchRoomResponse, any>()
export const DELETE_ROOM = createPromiseAction('rooms: DELETE_ROOM')<DeleteRoomPayload, AxiosResponse, any>()
