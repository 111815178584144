/* eslint-disable camelcase */
import React from 'react'

import { IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/system'
import { colors, CoursesAttributes, PaginationType, Paths } from '../../../utils/constants'
import TablePaginationActions from '../../shared/TablePaginationActions'
import { useHistory } from 'react-router-dom'
import PenIcon from '../../icon/PenIcon'
import InputComponent from '../../input/form/input'

const useStyles = makeStyles((theme: Theme): {
  view: any
  tableTitle: any
  button: any
  filters: any
  table: any
} => ({
  view: {
    width: 'inherit',
    minHeight: '100%',
    height: 'fit-content',
    display: 'flex',
    padding: '2rem',
    flexDirection: 'column',
    flexGrow: '1',
    position: 'relative',
    [theme.breakpoints?.down('lg')]: {
      padding: '2rem 1rem'
    },
    '& .MuiBackdrop-root': {
      position: 'inherit'
    },
    '& .MuiPaper-root': {
      background: 'none',
      boxShadow: 'none',
      overflow: 'hidden'
    }
  },
  tableTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
    background: colors.green,
    padding: '1rem 4rem',
    border: 'none'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '1rem',
    '& > span' : {
      marginBottom: '14px'
    },
    '& .inputArea': {
      width: '10rem !important',
      paddingRight: '3rem'
    }
  },
  table: {
    background: colors.lightBlue,
    padding: '2rem',
    borderRadius: '4px',
    display: 'grid',
    '& .subtitle': {
      textAlign: 'start',
      marginBottom: '1rem'
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `1px solid ${colors.grayBlue}`
      },

      '& th': {
        color: colors.darkGrayBlue
      },
      '& td': {
        fontSize: '12px',
        color: colors.darkBlue,
        [theme.breakpoints?.down('lg')]: {
          fontSize: '0.75rem'
        },
        '& svg': {
          cursor: 'pointer',
          margin: '0 0.25rem'
        }
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none'
      },
    }
  }
}))

const columns = ['Nome', 'Descrição', 'Código', '']

const CoursesView = ({
  courses,
  pagination,
  handleChangePageSize,
  handlePageChange,
  descriptionFilterValue,
  setDescriptionFilterValue,
  nameFilterValue,
  setNameFilterValue,
  fetchCoursesMethod
}:{
  courses: CoursesAttributes[]
  pagination: PaginationType
  handlePageChange: (_: any, newPage: number) => void
  handleChangePageSize: (e: any) => void
  descriptionFilterValue: string
  setDescriptionFilterValue: React.Dispatch<React.SetStateAction<string>>
  nameFilterValue: string
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>
  fetchCoursesMethod:  ({ newPagination }: {
    newPagination?: PaginationType | undefined;
  }) => Promise<void>
}) => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <div className={classes.view}>
      <div className={classes.tableTitle}>
        <span className='title'>Cursos</span>
        <button className='green small' onClick={() => history.push(Paths.COURSES_FORM)}>
          <span>Criar curso</span>
        </button>
      </div>
      <div className={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div
          className={classes.filters}
        >
          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchCoursesMethod({})
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value)
              }
            }}
          />
          <InputComponent
            placeholder={`Buscar por descrição`}
            search
            small
            onSearch={() => {
              fetchCoursesMethod({})
            }}
            input={{
              value: descriptionFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setDescriptionFilterValue(e.target.value)
              }
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                courses.map(course => {
                  return(
                    <TableRow key={course.id}>
                      <TableCell>
                        {course.name}
                      </TableCell>
                      <TableCell>
                        {course.description}
                      </TableCell>
                      <TableCell>
                        {course.code}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            history.push(Paths.COURSES_FORM, {
                              course_id: course.id,
                            })
                          }}
                        >
                          <PenIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default CoursesView