/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { ClassTimeSubjectAttributes, RoomAttributes, TeacherClassTimeAttributes } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { CREATE_TEACHER_CLASS_TIME, FETCH_TEACHER_CLASS_TIME, UPDATE_TEACHER_CLASS_TIME } from '../store/teacher_class_times';
import TeacherClassTimeForm from '../components/form/TeacherClassTimeForm';



const TeacherClassTimeFormContainer = (props: {
  onSave: () => void
  class_time_subject: ClassTimeSubjectAttributes
  teacher_class_time_id?: string
  close_form: () => void
  room: RoomAttributes
}) => {
  const { onSave, close_form, room, class_time_subject, teacher_class_time_id } = props
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = React.useState<Partial<TeacherClassTimeAttributes> | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const isUpdating = !isNil(initialValues?.id);

 
  const loadClassTimes = React.useCallback(async () => {
    if (teacher_class_time_id) {
      const subject = await dispatch(
        FETCH_TEACHER_CLASS_TIME.request({
          id: teacher_class_time_id,
        }),
      );
      const {
        data: {
          data: { id, attributes }
        },
      } = subject;


      const formattedSubject = {
        id,
        ...attributes,
      };
      setInitialValues(formattedSubject);
    } else {
      setInitialValues({
        class_time_subject_id: ~~class_time_subject.id,
      })
    }
  }, [class_time_subject]);

  const initTeacherClassTimeForm = React.useCallback(async () => {
    setLoading(true);
    await loadClassTimes();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: TeacherClassTimeAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'class_time_subject_id',
          ]) as TeacherClassTimeAttributes;

          await dispatch(
            UPDATE_TEACHER_CLASS_TIME.request({
              id: initialValues?.id as string,
              data: {...rest},
            }),
          );
        } else {
          await dispatch(
            CREATE_TEACHER_CLASS_TIME.request({
              data: {
                ...data,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Professor salvo na disciplina do horário com sucesso.',
          }),
        );
        onSave()
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar professor na disciplina do horário.',
          }),
        );
      }
    },
    [initialValues, class_time_subject, isUpdating],
  );

  React.useEffect(() => {
    initTeacherClassTimeForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <TeacherClassTimeForm room={room} class_time_subject={class_time_subject} close_form={close_form} initialValues={initialValues} onSubmit={onSubmit} />;
};

export default TeacherClassTimeFormContainer;
