/* eslint-disable camelcase */
import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { error, success, warning } from 'react-notification-system-redux'

import CreateCompanyView from '../components/view/CreateCompanyView'
import Loading from '../components/loading/Loading'

import { CREATE_COMPANY, FETCH_COMPANIES, FETCH_COMPANY, UPDATE_COMPANY, DELETE_COMPANY_LOGO, DELETE_COMPANY_EMAIL_HEADER_BANNER } from '../store/companies'
import { updateCredentialsRequest } from '../store/auth'
import { companyTypes, Paths, CompanyFormData, CompanyTypes } from '../utils/constants'
import { removeSpecialSymbols, ensure, verifyDifferentValues } from '../utils/functions'
import { UI_SET_LOADING_OPEN } from '../store/ui'
import { isEmpty } from 'lodash'
interface LocationState {
  parent_id: number
  company_id: string|number
}

interface DefaultOptionType {
  label: string | number,
  value: string | number
}


const preventUndefinedString = (values: any) => {
  const data = Object.keys(values).reduce((ac, value) => {
    if(values[value] === 'undefined') {
      return {
        ...ac,
        [value]: ''
      } 
    }
    return ac
  }, {...values})
  return data
}



const CompanyFormContainer = () => {
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const history = useHistory()

  const [companyOptions, setCompanyOptions] = React.useState<DefaultOptionType[]>([])
  const [companyKind, setCompanyKind] = React.useState<string>('')
  const [initialValues, setInitialValues] = React.useState<CompanyFormData|null>(null)
  const [renderReady, setRenderReady] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const setLoading = (value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value))
  }
  const fetchCompaniesMethod = async (kind: CompanyTypes) => {
    try {
      const companyKindInfo = ensure(companyTypes.find((type) => type.value === kind))
      const result = await dispatch(FETCH_COMPANIES.request({
        params: {
          filters: {
            'q[kind_in]': companyKindInfo.parentCompanies.join(',')
          }
        },
      }))
      const {
        data: { data },
        headers
      } = result
      dispatch(updateCredentialsRequest(headers))
      const formatedData = data?.map(({ id, attributes }) => ({
        label: attributes.name,
        value: id
      }))
      setCompanyOptions(formatedData)
      setRenderReady(true)
    } catch (err) {
      dispatch(
        error(
          {
            message: 'Erro ao carregar opções de companhias'
          }
        )
      )
    }

  }

  const fetchCompanyMethod = async({id: companyId}: { id: string }) => {
    const result = await dispatch(
      FETCH_COMPANY.request({
        id: companyId
      })
    )
    const { data: { data: { attributes, id } } } = result
    const formattedData = {
      city: attributes.additional_data.city,
      complement: attributes.additional_data.complement,
      neighbourhood: attributes.additional_data.neighbourhood,
      number: attributes.additional_data.number,
      state: attributes.additional_data.state,
      street: attributes.additional_data.street,
      zipcode: attributes.additional_data.zipcode,
      primary_color: attributes.additional_data.primary_color,
      secondary_color: attributes.additional_data.secondary_color,
      description: attributes.description,
      name: attributes.name,
      document: attributes.document,
      parent_id: attributes.parent_id,
      kind: attributes.kind,
      config_params: attributes.config_params,
      logo: {
        url: attributes.logo_url,
        id: attributes.logo_attachment_id
      },
      email_header_banner: {
        url: attributes.email_header_banner_url,
        id: attributes.email_header_banner_attachment_id
      },
      id,
    }
    setCompanyKind(attributes.kind)
    setInitialValues(preventUndefinedString(formattedData))
    await fetchCompaniesMethod(attributes.kind)
  }

  const initCompanyForm = async () => {
    try {
      const queryString = location.search
      const urlParams = new URLSearchParams(queryString)
      const companyId = location?.state?.company_id
      const newCompanyKind = urlParams.get('kind')
      const companyKindInfo = companyTypes.find((type) => type.value === newCompanyKind)
      if(companyId) {
        await fetchCompanyMethod({id: companyId as string})
      } else if (companyKindInfo !== undefined) {
        if (location.state.parent_id) {
          setInitialValues({
            parent_id: location.state.parent_id + ''
          })
        }
        setCompanyKind(urlParams.get('kind') || '')
        await fetchCompaniesMethod(newCompanyKind as CompanyTypes)

        setRenderReady(true)
      } else {
        history.push(Paths.HOME)
      }
      setLoaded(true)
    } catch (err) {
      dispatch(
        error(
          {
            message: 'Erro na inicialização do formulário'
          }
        )
      )
      history.push(Paths.HOME)
    }
  }

  React.useEffect(() => {
    initCompanyForm()
  }, [])

  const onSubmit = async (data: CompanyFormData) => {
    let sortData = data
    if(data.id){
      sortData = verifyDifferentValues(
        data, initialValues,
        ['id', 'logo', 'city', 'neighbourhood', 'number', 'state', 'street', 'zipcode', 'complement', 'primary_color', 'secondary_color']
      )
    }
    const {
      city,
      neighbourhood,
      number,
      state,
      street,
      zipcode,
      complement,
      primary_color,
      secondary_color,
      document,
      logo,
      email_header_banner,
      parent_id,
      name,
      description,
      id,
      config_params
    } = sortData

    const formData = new FormData()
    formData.append('data[type]', 'companies')
    parent_id && formData.append('data[attributes][parent_id]', parent_id.toString())
    document && formData.append('data[attributes][document]', removeSpecialSymbols(document))
    city && formData.append('data[attributes][additional_data][city]', city)
    neighbourhood && formData.append('data[attributes][additional_data][neighbourhood]', neighbourhood)
    number && formData.append('data[attributes][additional_data][number]', number)
    state && formData.append('data[attributes][additional_data][state]', state)
    street && formData.append('data[attributes][additional_data][street]', street)
    zipcode && formData.append('data[attributes][additional_data][zipcode]', zipcode)
    complement && formData.append('data[attributes][additional_data][complement]', complement)
    primary_color && formData.append('data[attributes][additional_data][primary_color]', primary_color)
    secondary_color && formData.append('data[attributes][additional_data][secondary_color]', secondary_color)
    if(!isEmpty(config_params) && config_params){
      config_params.asaas_api_key && formData.append('data[attributes][config_params][asaas_api_key]', config_params.asaas_api_key)
      config_params.days_before_expiration_charge && formData.append('data[attributes][config_params][days_before_expiration_charge]', config_params.days_before_expiration_charge.toString())
      config_params.days_before_punctuality_notification && formData.append('data[attributes][config_params][days_before_punctuality_notification]', config_params.days_before_punctuality_notification.toString())
      config_params.days_before_expiration_notification && formData.append('data[attributes][config_params][days_before_expiration_notification]', config_params.days_before_expiration_notification.toString())
      config_params.days_interval_after_expiration_notification && formData.append('data[attributes][config_params][days_interval_after_expiration_notification]', config_params.days_interval_after_expiration_notification.toString())
      config_params.charge_generation_config && formData.append('data[attributes][config_params][charge_generation_config]', config_params.charge_generation_config)
      config_params.days_to_block_invoice_adjustment_after_generation && formData.append('data[attributes][config_params][days_to_block_invoice_adjustment_after_generation]', config_params.days_to_block_invoice_adjustment_after_generation.toString())
      config_params.email_sender && formData.append('data[attributes][config_params][email_sender]', config_params.email_sender)
      config_params.payment_service && formData.append('data[attributes][config_params][payment_service]', config_params.payment_service)
      config_params.enable_whatsapp_invoice_notification && formData.append('data[attributes][config_params][enable_whatsapp_invoice_notification]', `${config_params.enable_whatsapp_invoice_notification}`)
    }
    formData.append('data[attributes][kind]', companyKind)
    name && formData.append('data[attributes][name]', name)
    description && formData.append('data[attributes][description]', description)
    let logoValue
    if(initialValues?.logo?.url && data['logo'] === null){
      logoValue = null
    } else if(logo) {
      logoValue = logo['file']
    }

    let email_header_banner_value
    if(initialValues?.email_header_banner?.url && data['email_header_banner'] === null){
      email_header_banner_value = null
    } else if(email_header_banner){
      email_header_banner_value = email_header_banner['file']
    }

    logoValue && formData.append('data[attributes][logo]', logoValue)
    email_header_banner_value && formData.append('data[attributes][email_header_banner]', email_header_banner_value)

    const afterEffect = () => {
      const companyLabel = ensure(companyTypes.find((item) => item.value === companyKind)).label
      let message = `${companyLabel} criado(a) com sucesso`
      if(id){
        message = `${companyLabel} atualizada com sucesso`
      }
      dispatch(
        success({
          message,
          autoDismiss: 3
        })
      )
      setLoading(false)
      history.push(Paths.HOME)
    }

    const handleErrorEffect = () => {
      let message = 'Erro na criação, favor entrar em contato com suporte.'
      if(id){
        message = 'Erro na atualização, favor entrar em contato com suporte.'
      }
      dispatch(
        error({
          title: 'Erro',
          message,
          autoDismiss: 3
        })
      )
      setLoading(false)
    }
    setLoading(true)
    try {
      if(id){
        formData.append('data[id]', id)
        if(logoValue === null) {
          try {
            await dispatch(DELETE_COMPANY_LOGO.request({
              id: id,
            }))
            dispatch(
              success({
                message: 'Logo removida com sucesso',
                autoDismiss: 3
              })
            ) 
          } catch (error) {
            dispatch(
              warning({
                message: 'Erro na remoção da logo',
                autoDismiss: 3
              })
            )
          }
        }

        if(email_header_banner_value === null){
          try {
            await dispatch(DELETE_COMPANY_EMAIL_HEADER_BANNER.request({
              id: id,
            }))
            dispatch(
              success({
                message: 'Cabeçalho de email removido com sucesso',
                autoDismiss: 3
              })
            ) 
          } catch (error) {
            dispatch(
              warning({
                message: 'Erro na remoção do cabeçalho',
                autoDismiss: 3
              })
            )
          }

        }
        await dispatch(UPDATE_COMPANY.request({ data: formData, id }))
      } else {
        await dispatch(CREATE_COMPANY.request({ data: formData}))
      }
      afterEffect()
    } catch (error) {
      handleErrorEffect()
    }
  }

  if (!renderReady || !loaded) {
    return <Loading />
  }
  return (
    <CreateCompanyView
      onSubmit={onSubmit}
      kind={companyKind}
      companyOptions={companyOptions}
      initialValues={initialValues as CompanyFormData}
    />
  )
}

export default CompanyFormContainer
