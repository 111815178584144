import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'

const paymentOptionsEndpoint = '/payment_options'


export const fetchPaymentOptions = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${paymentOptionsEndpoint}?${queryParams}`)
}
