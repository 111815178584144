import React from 'react'
import CoursesFormContainer from '../containers/CoursesFormContainer'
import navigationHoc from '../components/hoc/navigationHoc'

const CoursesFormPage = () => {
  return(
    <CoursesFormContainer />
  )
}

export default navigationHoc(CoursesFormPage)