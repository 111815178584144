/* eslint-disable camelcase */
import { css } from '@emotion/react'
import React from 'react'
import { DefaultOptionType, colors } from '../../utils/constants'
import { map, toNumber } from 'lodash'
import { formatToCurrency } from '../../utils/functions';
import { IconButton, Tooltip } from '@mui/material';
import { HighlightOffOutlined } from '@mui/icons-material';


const resultsCss = css`
  align-items: center;
  border-bottom: 1px dashed ${colors.darkGrayBlue};
  display: flex;
  gap: 5vmax;
  margin-bottom: 1vmin;
  justify-content: space-between;
  width: 100%;
`;

interface CustomInvoiceItemValueType extends DefaultOptionType {
  iconFunction?: () => void
  icon?: React.ElementType
  iconProps?: object
  convert?: boolean
  iconTooltipText?: string
  resultProps?:object
}


const InvoiceValue = (props: {values: CustomInvoiceItemValueType[]}) => {
  
  return (
    <div
      css={css`
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
    `}
    >
      {
        map(props.values, item => {
          const { convert = true, iconFunction, label, iconProps = {}, icon = HighlightOffOutlined, iconTooltipText = 'Remover valor', resultProps = {} } = item
          let value = item.value
          if(convert){
            value = toNumber(item.value)
            if(isNaN(value)){
              value = 0
            }
            value = formatToCurrency(value)
          }
          const Icon = icon
          return (
            <div css={css`display: flex; align-items: baseline; justify-content: center; width: 100%;`}>
              <div css={resultsCss} key={label} {...resultProps}>
                <span>{label}</span>
                <span>{value}</span>
              </div>
              <Tooltip style={{ visibility: iconFunction ? 'visible' : 'hidden' }} title={iconTooltipText}>
                <IconButton onClick={iconFunction}>
                  <Icon {...iconProps} />
                </IconButton>
              </Tooltip>
            </div>
            
          )
        })
        
      }
    </div>  
  )
}

export default InvoiceValue