/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'

export interface StudentAbsenceAttrs {
  room_student_id: number
  class_time_id: string
  justification: string
  absence_justification_id: string
}
export type StudentAbsenceJson = JsonFormat<'student_absences', StudentAbsenceAttrs>

export type FetchStudentAbsencesResponse = JsonResponseFormat<StudentAbsenceJson[]>
export type FetchStudentAbsenceResponse = JsonResponseFormat<StudentAbsenceJson>

export type FetchStudentAbsencesPayload = WithRequestParams
export interface FetchStudentAbsencePayload extends WithRequestParams {
  id: string | number,
}
export interface CreateStudentAbsencePayload {
  data: Partial<StudentAbsenceAttrs>
}

export interface UpdateStudentAbsencePayload extends CreateStudentAbsencePayload {
  id: string
}

export interface DeleteStudentAbsencePayload {
  id: string
}

export const FETCH_STUDENT_ABSENCES = createPromiseAction('student_absences: FETCH_STUDENT_ABSENCES')<FetchStudentAbsencesPayload, FetchStudentAbsencesResponse, any>()
export const FETCH_STUDENT_ABSENCE = createPromiseAction('student_absences: FETCH_STUDENT_ABSENCE')<FetchStudentAbsencePayload, FetchStudentAbsenceResponse, any>()
export const CREATE_STUDENT_ABSENCE = createPromiseAction('student_absences: CREATE_STUDENT_ABSENCE')<CreateStudentAbsencePayload, FetchStudentAbsenceResponse, any>()
export const UPDATE_STUDENT_ABSENCE = createPromiseAction('student_absences: UPDATE_STUDENT_ABSENCE')<UpdateStudentAbsencePayload, FetchStudentAbsenceResponse, any>()
export const DELETE_STUDENT_ABSENCE = createPromiseAction('student_absences: DELETE_STUDENT_ABSENCE')<DeleteStudentAbsencePayload, AxiosResponse, any>()
