import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
import http from '../../utils/http'

const representativeEndpoint = '/representatives'

export const deleteRepresentative = (id: string | number) => {
  return http.delete(`${representativeEndpoint}/${id}`)
}

export const fetchRepresentative = ({ id, params }: { id: string|number, params?: RequestParams }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${representativeEndpoint}/${id}?${queryParams}`)
}
