import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const ktwelvesEndpoint = '/ktwelve_degrees'

export const fetchKtwelves = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ktwelvesEndpoint}?${queryParams}`)
}

export const fetchKtwelve = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ktwelvesEndpoint}/${id}?${queryParams}`)
}

export const createKtwelve = (data: object) => {
  return http.post(`${ktwelvesEndpoint}`, data)
}

export const updateKtwelve = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${ktwelvesEndpoint}/${id}`, data)
}