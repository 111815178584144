import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const usersEndpoint = '/users'

const multipartInstance = http
multipartInstance.defaults.headers.common['Content-Type'] =
    'multipart/form-data; boundary=----------XnJLe9ZIbbGUYtzPQJ16u1'


export const fetchUsers = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${usersEndpoint}?${queryParams}`)
}

export const fetchUser = ({ id, params } : { id: string|number, params?: RequestParams}) => {
  const queryParams = setQueryParams(params)
  return http.get(`${usersEndpoint}/${id}?${queryParams}`)
}
export const updateUsers = ({ params, id }: { params: FormData | object, id: string|number }) => {
  const instance = params instanceof FormData ? multipartInstance : http
  return instance.put(`${usersEndpoint}/${id}`, params)
}

export const createUser = (params: FormData | object) => {
  const instance = typeof params === 'object' ? http : multipartInstance
  return instance.post(`${usersEndpoint}`, params)
}

export const deleteUser= ({ id } : { id: string|number }) => {
  return http.delete(`${usersEndpoint}/${id}`)
}
export const deleteUserAvatar= ({ id } : { id: string|number }) => {
  return http.delete(`${usersEndpoint}/${id}/avatar`)
}
export const recoverUserPassword = ({ data } : { data: object }) => {
  return http.post(`${usersEndpoint}/recover_password`, data)
}

export const registerUserAction= ({ data } : { data: object }) => {
  return http.post(`${usersEndpoint}/register_user_action`, data)
}

export const verifyUserHierarchy = ({ id } : { id: string|number }) => {
  return http.get(`${usersEndpoint}/${id}/verify_user_hierarchy`)
}
