/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface DocumentAttrs {
  name: string
  description: string
}

export type Documents = JsonFormat<'documents', DocumentAttrs>

export type FetchDocumentsResponse = JsonResponseFormat<Documents[]>
export type FetchDocumentsPayload = WithRequestParams

export const FETCH_DOCUMENTS = createPromiseAction('documents: FETCH_DOCUMENTS')<FetchDocumentsPayload, FetchDocumentsResponse, any>()
