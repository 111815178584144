/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SubjectsForm from '../components/form/SubjectsForm';
import { CREATE_SUBJECT, FETCH_SUBJECT, UPDATE_SUBJECT } from '../store/subjects';
import { RootState } from '../store/configureStore';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import {SubjectAttributes, LocationState, Paths, Role } from '../utils/constants';
import { evaluate_permissions, verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { formValueSelector } from 'redux-form';

const SubjectsFormContainer = () => {
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();
  const state  = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
  } = state
  const formValues = formValueSelector('subjectForm');
  const [initialValues, setInitialValues] = React.useState<SubjectAttributes | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const companyIdValue = formValues(state, 'company_id') as string;
  const isUpdating = !isNil(initialValues?.id);
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const company_id_to_use = location?.state?.company_id
    ? location?.state?.company_id
    : is_above_school_director
    ? companyIdValue
    : company;

 
  const loadSubjects = React.useCallback(async () => {
    if (location?.state?.subject_id) {
      const subject = await dispatch(
        FETCH_SUBJECT.request({
          id: location.state.subject_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = subject;
      const formattedSubject = {
        id,
        ...attributes,
      };
      setInitialValues(formattedSubject);
    } else {
      setInitialValues({ company_id: company_id_to_use } as SubjectAttributes)
    }
  }, [location]);

  const initSubjectsForm = React.useCallback(async () => {
    setLoading(true);
    await loadSubjects();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: SubjectAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'company_id',
          ]) as SubjectAttributes;
          await dispatch(
            UPDATE_SUBJECT.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          await dispatch(
            CREATE_SUBJECT.request({
              data: {
                ...data,
                company_id: company_id_to_use,
              },
            }),
          );
        }
        history.push(Paths.SUBJECTS_LIST);
        dispatch(
          success({
            message: 'Disciplina salva com sucesso.',
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar disciplina.',
          }),
        );
      }
    },
    [initialValues, company_id_to_use, isUpdating, history],
  );

  React.useEffect(() => {
    initSubjectsForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <SubjectsForm initialValues={initialValues} onSubmit={onSubmit} />;
};

export default SubjectsFormContainer;
