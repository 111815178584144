import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const productFamiliesEndpoint = '/product_families'


export const fetchProductFamilies = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${productFamiliesEndpoint}?${queryParams}`)
}

export const fetchProductFamily = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${productFamiliesEndpoint}/${id}?${queryParams}`)
}

export const createProductFamily = (data: object) => {
  return http.post(`${productFamiliesEndpoint}`, data)
}

export const updateProductFamily = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${productFamiliesEndpoint}/${id}`, data)
}