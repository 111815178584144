import React from 'react'
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { DefaultOptionType, monetaryValueMaskProps, validation } from '../../utils/constants';
import DatePickerComponent from '../input/form/datepicker';
import SelectComponent from '../input/form/select';
import TextAreaComponent from '../input/form/textarea';
import MaskedInputComponent from '../input/form/masked';
import { parseMonetaryValue } from '../../utils/functions';
import CheckboxComponent from '../input/form/checkbox';
import { useDispatch } from 'react-redux';
import { FETCH_ROOM_STUDENT_STATUSES } from '../../store/room_student_statuses';
import Loading from '../loading/Loading';
import { error } from 'react-notification-system-redux';

const RegistrationClosureForm = ({
  closureReasonOptions,
  closureStatusOptions,
  disable_display_refund_registration_fee,
  closureDatePickerProps,
  company_id
}:{
  closureReasonOptions: DefaultOptionType[]
  closureStatusOptions: DefaultOptionType[]
  disable_display_refund_registration_fee: boolean
  closureDatePickerProps: {
    max?: Date | undefined;
  }
  company_id: number
}) => {
  const dispatch = useDispatch()
  const [roomStudentStatusOptions, setRoomStudentStatusOptions] = React.useState<DefaultOptionType[]>([])
  const [loading, setLoading] = React.useState(true)
  const fetchRoomStudentStatus = React.useCallback(async () => {
    try {
      const response = await dispatch(
        FETCH_ROOM_STUDENT_STATUSES.request({
          params: {
            filters: {
              'q[cancellation_option_eq]': 'true',
              'q[company_id_eq]': company_id.toString(),
              'page[size]': '30'
            }
          }
        })
      )
      const { data: { data } } = response
      const options = data.map(item => ({ label: item.attributes.name, value: item.id }))
      setRoomStudentStatusOptions(options)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      dispatch(
        error(
          {
            message: 'Erro ao carregar opções de status do estudante na turma'
          }
        )
      )
    }
  }, [])

  const init = React.useCallback(async () => {
   await fetchRoomStudentStatus()
  }, [])

  React.useEffect(() => {
    init()
  }, [])

  if(loading){
    return <Loading />
  }
  return (
    <form
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column'
      }}
    >
      <span>Selecione o motivo</span>
      <Field
        name='registration_closure_status_id'
        small
        options={closureStatusOptions}
        clearable
        component={SelectComponent}
        required
        validate={[validation.required]}
      />
      <span>Selecione a razão</span>
      <Field
        name='registration_closure_reason_id'
        small
        options={closureReasonOptions}
        clearable
        component={SelectComponent}
        required
        validate={[validation.required]}
      />
      <span>Status do Estudante na Sala</span>
      <Field
        name='room_student_status_id'
        small
        options={roomStudentStatusOptions}
        clearable
        component={SelectComponent}
        required
        validate={[validation.required]}
      />
      <label htmlFor='closure_date'>Último Mês de Cobrança</label>
      <Field
        name='closure_date'
        id='closure_date'
        small
        component={DatePickerComponent}
        placeholder={'11/11/2021'}
        required
        validate={[validation.dateRequired]}
        datePickerProps={{...closureDatePickerProps, views: ['month', 'year']}}
      />
      <label htmlFor='last_date'>Ultimo Dia de Aula</label>
      <Field
        name='last_date'
        id='last_date'
        small
        component={DatePickerComponent}
        placeholder={'11/11/2021'}
        required
        validate={[validation.dateRequired]}
        datePickerProps={{
          showDropdownIcon: true,
        }}
      />
      <label htmlFor='last_date'>Data da solicitação</label>
      <Field
        name='request_date'
        id='request_date'
        small
        component={DatePickerComponent}
        placeholder={'11/11/2021'}
        required
        validate={[validation.dateRequired]}
        datePickerProps={{
          showDropdownIcon: true,
        }}
      />
      <label htmlFor='penalty'>Valor da multa</label>
      <Field
        name='penalty'
        component={MaskedInputComponent}
        label={'Valor'}
        parse={parseMonetaryValue}
        maskProps={monetaryValueMaskProps}
        placeholder={'Insira o valor da multa'}
        validate={[validation.closurePenaltyValidaiton]}

      />
      <label htmlFor='closure_description'>Detalhes</label>
      <Field
        name='details'
        component={TextAreaComponent}
        placeholder='Detalhes do cancelamento'
        id='details'
        validate={[validation.required]}
      />
      <Field
        name='refund_registration_fee'
        disabled={disable_display_refund_registration_fee}
        component={CheckboxComponent}
        placeholder={'Estornar Entrada'}
      />
    </form>
  )
}

export default compose<any>(
  reduxForm({
      form: 'registrationClosureForm',
  }),
)(RegistrationClosureForm);
