import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_WALLET,
  FETCH_WALLETS,
  FetchWalletPayload,
  FetchWalletsPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchWallets (action: DefaultAction<FetchWalletsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchWallets, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_WALLETS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_WALLETS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchWallet (action: DefaultAction<FetchWalletPayload, string>): any {
  const { payload: {id, params} } = action
  try {
    const response = yield call(services.fetchWallet, { id, params })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_WALLET.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_WALLET.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchWalletsSagas () {
  yield takeLatest(FETCH_WALLETS.request, fetchWallets)
  yield takeLatest(FETCH_WALLET.request, fetchWallet)
}

export default function * walletsSagas () {
  yield all([watchWalletsSagas()])
}
