import React from 'react'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tabPanel: {
      display: 'grid',
      background: 'transparent',
      padding: '1rem',
      borderRadius: '0 1rem 1rem 1rem',
  },
}));


export default function TabPanel(props: any) {
  const { children, value, index, style, ...other } = props;
  const classes = useStyles();
  return (
    <div
        role='tabpanel'
        style={{ display: value !== index && 'none', ...style }}
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        className={classes.tabPanel}
        {...other}
    >
        {value === index && <>{children}</>}
    </div>
  );
}