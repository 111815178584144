import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
import http from '../../utils/http'

const registrationProducts = '/registration_products'

export const createRegistrationProduct = ({ params }: { params: object }) => {
  return http.post(`${registrationProducts}`, params)
}

export const updateRegistrationProduct = ({ params, id }: { params: object, id: string|number }) => {
  return http.put(`${registrationProducts}/${id}`, params)
}
export const deleteRegistrationProduct = ({ id }: { id: string | number }) => {
  return http.delete(`${registrationProducts}/${id}`)
}

export const fetchRegistrationProduct = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${registrationProducts}/${id}?${queryParams}`)
}

export const generateBillings = ({ id }: { id: string | number } ) => {
  return http.post(`${registrationProducts}/${id}/generate_billings`)
}

export const fetchStudentMissingRegistrationProducts = ({ product_id, params }: { params?: RequestParams, product_id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${registrationProducts}/students_missing/${product_id}?${queryParams}`)
}