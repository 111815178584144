/* eslint-disable camelcase */

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TransactionAttributes, colors, TransactionEventOptions, PaginationType } from '../../utils/constants';
import { makeStyles } from '@mui/styles';
import { parseText } from '../../utils/functions';
import { format } from 'date-fns';
import { TableFooter, TablePagination, Tooltip } from '@mui/material';
import { orderBy } from 'lodash';
import TablePaginationActions from '../shared/TablePaginationActions';

const useStyles = makeStyles(() => ({
  table: {
    '& tbody > tr:nth-child(even)': {
      backgroundColor: `${colors.darkGrayBlue} !important`,
    },
    '& tbody > tr:nth-child(odd)': {
      backgroundColor: `${colors.grayBlue} !important`,
    },
    '& .include': {
      display: 'flex',
      marginLeft: 'auto',
      marginBottom: '1rem',
    },
    '& tfoot': {
      background: 'white',
      '& td': {
        fontWeight: 'bold',
        fontSize: '1rem',
      },
      '& td:last-of-type': {
        color: colors.blue,
      },
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `5px solid ${colors.lightBlue}`,
      },
      '& th': {
        color: colors.darkGrayBlue,
      },
      '& td': {
        color: colors.darkBlue,
        '& svg': {
          color: colors.darkGrayBlue,
          cursor: 'pointer',
        },
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none',
      },
    },
  },
}));

const TransactionsTableBody = ({transactions }: { transactions: TransactionAttributes[] }) => {
  return (
    <TableBody>
      {orderBy(transactions, (transaction) => new Date(transaction.event_date), 'asc').map((transaction) => {
        const eventLabel = TransactionEventOptions.find((opt) => opt.value === transaction.event)?.label;
        const eventDate = transaction.event_date && format(new Date(transaction.event_date), 'dd/MM/yyyy HH:mm');

        return (
          <React.Fragment key={transaction.id}>
            <TableRow>
              <TableCell align='center'>{eventLabel}</TableCell>
              <TableCell align='center'>{eventDate}</TableCell>
              <TableCell align='center'>
                <Tooltip title={transaction.description}>
                  <span>{parseText(transaction.description, 30)}</span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </TableBody>
  );
};

const TransactionsTable =  ({
  transactions,
  pagination,
  handleChangePageSize,
  handlePageChange
}: {
  transactions: TransactionAttributes[]
  pagination?: PaginationType;
  handlePageChange?: (_: any, newPage: number) => void;
  handleChangePageSize?: (e: any) => void;
}) => {
  const classes = useStyles();
  return (
    <Table className={classes.table} size='small' aria-label='payment-simulation'>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Evento</TableCell>
          <TableCell align='center'>Data do evento</TableCell>
          <TableCell align='center'>Descrição</TableCell>
        </TableRow>
      </TableHead>
      <TransactionsTableBody transactions={transactions} />
      {handlePageChange && pagination && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25, 50]}
              colSpan={3}
              count={pagination.totalCount}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNumber}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangePageSize}
              labelRowsPerPage='Itens Por página'
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default TransactionsTable;
