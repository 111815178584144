/* eslint-disable camelcase */
import { makeStyles } from '@mui/styles';
import { compose } from 'redux';
import { change, Field, FieldArray, FormSection, getFormValues, reduxForm, WrappedFieldArrayProps } from 'redux-form';
import { validation, classTimeKindOptions, colors, NestedClassTimeSubjectAttributes, ClassTimeFormAttributes, RoomAttributes, DefaultOptionType, ClassTimeKindEnum } from '../../utils/constants';
import SelectComponent from '../input/form/select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';
import DatePickerComponent from '../input/form/datepicker';
import AddButton from '../shared/AddButton';
import { css } from '@emotion/react';
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import DeleteButton from '../shared/DeleteButton';
import { ClassTimeSubjectFormBody } from './ClassTimeSubjectForm';
import { TimePicker } from '@mui/x-date-pickers';
import { find, isEmpty } from 'lodash';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      padding: '2rem 0',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const AccordionCss = css`
  background: inherit;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grayBlue}
`
const AccordionSummaryCss = css`
  display: flex;
  align-items: center;
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }
`


interface CustomClassTimeSubjectFormProps extends WrappedFieldArrayProps {
  class_time: string;
  current_class_time: ClassTimeFormAttributes;
  room: RoomAttributes
  form_name: string
}

const ClassTimeSubjectAccordion = (props: {
  class_time_subject: string
  currentClassTimeSubject: NestedClassTimeSubjectAttributes
  index: number
  deleteClassTimeSubject: (currentClassTimeSubject: NestedClassTimeSubjectAttributes, class_time_subject: string, index: number) => void
  room: RoomAttributes
  allClassTimeSubjects: NestedClassTimeSubjectAttributes[]
}) => {
  const {class_time_subject, currentClassTimeSubject, index, deleteClassTimeSubject, room, allClassTimeSubjects} = props
  const [ktwelveSubjectOptions, setKtwelveSubjectOptions] = React.useState<DefaultOptionType[]>([])
  const subjectLabel = currentClassTimeSubject?.ktwelve_subject_id ? (currentClassTimeSubject.subject_name || find(ktwelveSubjectOptions, kso => kso.value === currentClassTimeSubject.ktwelve_subject_id)?.label) : 'Nova Disciplina'
  return(
    <Accordion TransitionProps={{ unmountOnExit: true }} key={class_time_subject} css={AccordionCss}>
    <AccordionSummary css={AccordionSummaryCss}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <ExpandMore />
        <span>{subjectLabel}</span>
      </div>
      <DeleteButton
        onClick={() => deleteClassTimeSubject(currentClassTimeSubject, class_time_subject, index)}
        tooltip='Remover disciplina'
      />
    </AccordionSummary>
    <AccordionDetails>
      <div key={class_time_subject}>
        <FormSection name={class_time_subject}>
          <ClassTimeSubjectFormBody
            class_time_subject={class_time_subject}
            class_time_subject_form_values={currentClassTimeSubject}
            room={room}
            all_class_time_subjects={allClassTimeSubjects}
            ktwelveSubjectOptions={ktwelveSubjectOptions}
            setKtwelveSubjectOptions={setKtwelveSubjectOptions}
          />
        </FormSection>
      </div>
    </AccordionDetails>
  </Accordion>

  )
}


const renderClassTimeSubjects = (props: CustomClassTimeSubjectFormProps) => {
  const dispatch = useDispatch();
  const { fields, current_class_time, room, class_time, form_name } = props;
  const allClassTimeSubjects = fields.getAll() as NestedClassTimeSubjectAttributes[]
  const addClassTimeSubject = React.useCallback(() => {
    fields.push({
      class_time_id: current_class_time.id
  })}, [fields]);
  const deleteClassTimeSubject = React.useCallback(
    (currentClassTimeSubject: NestedClassTimeSubjectAttributes, class_time_subject: string, index: number) => {
      currentClassTimeSubject.id
        ? dispatch(change(form_name, [class_time, class_time_subject ,'_destroy'].join('.'), true))
        : fields.remove(index);
    },
    [fields],
  );

  return (
    <div>
      <div
        css={css`
          display: ${current_class_time.kind === ClassTimeKindEnum.INTERVAL ? 'none' : 'flex'};
          align-items: center;
          gap: 1rem;
          & div[id$='subject_id'] {
            width: 100%;
          }
        `}
      >
        <span>Adicionar Disciplina</span>
        <AddButton onClick={addClassTimeSubject} tooltip='Adicionar disciplina' />
      </div>
      {fields.map((class_time_subject, index) => {
        const currentClassTimeSubject = allClassTimeSubjects[index] as NestedClassTimeSubjectAttributes;
        if (currentClassTimeSubject._destroy) {
          return null;
        }
        return (
          <ClassTimeSubjectAccordion
            key={class_time_subject}
            currentClassTimeSubject={currentClassTimeSubject}
            class_time_subject={class_time_subject}
            room={room}
            allClassTimeSubjects={allClassTimeSubjects}
            index={index}
            deleteClassTimeSubject={deleteClassTimeSubject}
          />
        )
      })}
    </div>
  );
};

export const ClassTimeFormBody = (props: {
  class_time_form_values: ClassTimeFormAttributes;
  room: RoomAttributes;
  class_time?: string;
  form_name: string
}) => {
  const { class_time_form_values, room, class_time, form_name} = props
  const dispatch = useDispatch()
  const onAccept = (value: Date) => {
    const minutes_to_add = class_time_form_values.kind === ClassTimeKindEnum.INTERVAL ? room.interval_length : room.class_time_length
    const new_ends_at_date = new Date(new Date(value).getTime() + minutes_to_add * 60000);
    dispatch(
      change(
        form_name, `${class_time}.ends_at`, new_ends_at_date 
      )
    )
  }

  return(
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: '33% 33% 33%', justifyContent: 'space-between' }}>
        <Field
          name='kind'
          component={SelectComponent}
          options={classTimeKindOptions}
          label={'Tipo de horário'}
          placeholder={'Selecione o tipo de horário'}
          validate={[validation.required]}
        />
        <Field
          name={`starts_at`}
          label={'Início da aula'}
          placeholder={`01/01/${new Date().getFullYear()}`}
          component={DatePickerComponent}
          PickerComponent={TimePicker}
          room={room}
          disabled={isEmpty(class_time_form_values.kind)}
          class_time={class_time}
          datePickerProps={{
            ...(class_time_form_values.ends_at ? { maxDate: class_time_form_values.ends_at } : {}),
            disabled: isEmpty(class_time_form_values.kind),
            showDropdownIcon: true,
            inputFormat: "H:mm",
            onAccept: onAccept,
          }}
          validate={[validation.dateRequired]}
        />
        <Field
          name={`ends_at`}
          label={'Fim da aula'}
          placeholder={`01/01/${new Date().getFullYear()}`}
          component={DatePickerComponent}
          PickerComponent={TimePicker}
          room={room}
          class_time={class_time}
          disabled
          datePickerProps={{
            ...(class_time_form_values.starts_at ? { maxDate: new Date(class_time_form_values.starts_at) } : {}),
            disabled: true,
            showDropdownIcon: true,
            inputFormat: "H:mm"
          }}
          validate={[validation.dateRequired]}
        />
      </div>
      <div>
        <FieldArray
          name='class_time_subjects_attributes'
          component={renderClassTimeSubjects}
          current_class_time={class_time_form_values}
          room={room}
          class_time={class_time}
        />
      </div>
    </div>
  )
}

const form_name = 'roomClassTimeForm';

const ClassTimeForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form: () => void;
  room: RoomAttributes
}) => {
  const { handleSubmit, onSubmit, close_form, room } = props;
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const class_time_form_values = getFormValues(form_name)(state) as ClassTimeFormAttributes;

  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar Horário</span>
      <form className={classes.form}>
        <>
          <ClassTimeFormBody
            form_name={form_name}
            class_time_form_values={class_time_form_values}
            room={room}
          />
          <div className={classes.buttons}>
            <button
              onClick={(e) => {
                e.preventDefault();
                close_form();
              }}
              className='red small'
            >
              <span> Cancelar </span>
            </button>
            <button onClick={handleSubmit(onSubmit)} className='blue small'>
              <span> Salvar horário </span>
            </button>
          </div>
        </>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(ClassTimeForm);
