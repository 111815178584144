import React from 'react'
import RegistrationsContainer from '../containers/RegistrationsContainer'

import navigationHoc from '../components/hoc/navigationHoc';

const RegistrationsPage = () => {

  return <RegistrationsContainer/>
}

export default navigationHoc(RegistrationsPage)
