/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, RoomScheduleFormAttributes, RoomScheduleWeekDaysEnum, ScheduleStatusEnum, WithRequestParams } from '../../utils/constants'
import { AxiosResponse } from 'axios'
import { ClassTimeJson } from '../class_times'
import { ClassTimeSubjectJson } from '../class_time_subjects'
import { TeacherClassTimeJson } from '../teacher_class_times'
import { RoomClassTimeJson } from '../room_class_times'

export interface RoomScheduleAttrs {
  product_id: number
  room_id: number
  weekday: RoomScheduleWeekDaysEnum
  status: ScheduleStatusEnum
  schedule_date: string
  starts_at: string
  ends_at: string
}
export type RoomScheduleJson = JsonFormat<'room_schedules', RoomScheduleAttrs>

export type FetchRoomSchedulesResponse = JsonResponseFormat<RoomScheduleJson[]>
export type FetchRoomScheduleResponse = JsonResponseFormat<RoomScheduleJson, (ClassTimeJson|ClassTimeSubjectJson|TeacherClassTimeJson|RoomClassTimeJson)[]>

export type FetchRoomSchedulesPayload = WithRequestParams
export interface FetchRoomSchedulePayload extends WithRequestParams {
  id: string | number,
}
export interface CreateRoomSchedulePayload {
  data: Partial<RoomScheduleFormAttributes>
}

export interface UpdateRoomSchedulePayload extends CreateRoomSchedulePayload {
  id: string
}

export interface DeleteRoomSchedulePayload {
  id: string
}

export const FETCH_ROOM_SCHEDULES = createPromiseAction('room_schedules: FETCH_ROOM_SCHEDULES')<FetchRoomSchedulesPayload, FetchRoomSchedulesResponse, any>()
export const FETCH_ROOM_SCHEDULE = createPromiseAction('room_schedules: FETCH_ROOM_SCHEDULE')<FetchRoomSchedulePayload, FetchRoomScheduleResponse, any>()
export const CREATE_ROOM_SCHEDULE = createPromiseAction('room_schedules: CREATE_ROOM_SCHEDULE')<CreateRoomSchedulePayload, FetchRoomScheduleResponse, any>()
export const UPDATE_ROOM_SCHEDULE = createPromiseAction('room_schedules: UPDATE_ROOM_SCHEDULE')<UpdateRoomSchedulePayload, FetchRoomScheduleResponse, any>()
export const DELETE_ROOM_SCHEDULE = createPromiseAction('room_schedules: DELETE_ROOM_SCHEDULE')<DeleteRoomSchedulePayload, AxiosResponse, any>()
