/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { error, success } from 'react-notification-system-redux';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Collapse, IconButton, Tooltip } from '@mui/material';
import { Theme } from '@mui/system';

import { IconModal } from '../../../modal/Modal';
import { convertDocument, formatToCurrency } from '../../../../utils/functions';
import { DELETE_REPRESENTATIVE, FETCH_REPRESENTATIVE, RepresentativeJson } from '../../../../store/representatives';

import {
  colors,
  representativeTypes,
  relationshipOptions,
  CurrentRegistrationData,
  WalletAttributes,
  WalletCreditAttributes,
  onConfirm,
  RepresentativeAttributes,
} from '../../../../utils/constants';
import ResponsibleForm from '../../../../containers/ResponsibleStepFormContainer';
import PenIcon from '../../../icon/PenIcon';
import { filter, isEmpty, map, orderBy } from 'lodash';
import { AccountBalanceWallet, CurrencyExchange } from '@mui/icons-material';
import WalletCredits from '../../../table/WalletCreditsTable';
import WalletCreditForm from '../../../form/WalletCreditForm';
import { CREATE_WALLET_CREDIT, WalletCreditJson } from '../../../../store/wallet_credits';
import { RootState } from '../../../../store/configureStore';
import { getFormValues } from 'redux-form';
import { FETCH_REGISTRATION } from '../../../../store/registrations';
import { FETCH_WALLET } from '../../../../store/wallets';
import Loading from '../../../loading/Loading';
import TrashIcon from '../../../icon/TrashIcon';
import { RegistrationProductJson } from '../../../../store/registration_products';


const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    flex: 1,
  },
  view: {
    padding: '2rem',
    position: 'relative',
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0',
    },
  },
  table: {
    '& .include': {
      display: 'flex',
      marginLeft: 'auto',
      marginBottom: '1rem',
    },
    '& .actionsIcon': {
      color: colors.darkGrayBlue,
      cursor: 'pointer',
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `5px solid ${colors.lightBlue}`,
      },
      '& th': {
        color: colors.darkGrayBlue,
      },
      '& td': {
        color: colors.darkBlue,
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none',
      },
      '& tbody > tr': {
        background: 'white',
      },
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    gap: '1rem',
    marginTop: '4.5rem',
  },
  tabStyle: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      gap: '0.25rem',
    },
  },
  insertButton: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '3.5rem',
  },
}));

const columns = ['CPF/CNPJ', 'Nome', 'Tipo de Relacionamento', 'Tipo de Responsável'];

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      className={classes.view}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const WalletBallanceComponent = ({
  wallet_id,
  fetchRegistrationRepresentatives,
}:{
  wallet_id: string,
  fetchRegistrationRepresentatives: () => Promise<void>
}) => {
  const [walletData, setWalletData] = React.useState<WalletAttributes|null>(null)
  const [walletCreditsData, setWalletCreditsData] = React.useState<WalletCreditAttributes[]>([])
  const [loading, setLoading] = React.useState(true)
  const state = useSelector((state: RootState) => state);
  const wallet_credit_form_values = getFormValues('walletCreditForm')(
    state,
  ) as WalletCreditAttributes
  const dispatch = useDispatch()

  const createWalletCredit = React.useCallback(
    async ({ setLoading, handleClose }: any) => {
      try {
        setLoading(true);
        await dispatch(
          CREATE_WALLET_CREDIT.request({data: {...wallet_credit_form_values, description: `Devolução de créditos: ${wallet_credit_form_values.description}`}}),
        );

        dispatch(
          success({
            title: 'Devolução de crédito',
            message: 'Devolução concluída com sucesso!',
            autoDismiss: 3,
          }),
        );
        await fetchRegistrationRepresentatives()
        setLoading(false);
        handleClose();
      } catch (err) {
        setLoading(false);
        handleClose();
        dispatch(
          error({
            message: 'Erro na devolução de crédito',
          }),
        );
      }
    },
    [wallet_credit_form_values],
  );
  
  const fetchWalletData = React.useCallback(async () => {
    setLoading(true)
    const wallet = await dispatch(
      FETCH_WALLET.request({
        id: wallet_id,
        params: {
          filters: {
            include: 'wallet_credits'
          }
        }
      })
    )
    const { data: { included = [], data } } = wallet
    const wallet_credits = orderBy(map(included.filter(incl => incl.type === 'wallet_credits') as WalletCreditJson[], item => ({
        id: item.id,
        ...item.attributes
      })), (wallet_credit) => new Date(wallet_credit.created_at), 'desc')
    setWalletCreditsData(wallet_credits)
    setWalletData({id: data.id, ...data.attributes})
    setLoading(false)
  }, [wallet_id])
  React.useEffect(() => {
    fetchWalletData()
  }, [])
  if(loading){
    return <Loading />
  }
  if(!walletData){
    return null
  }
  const current_balance = walletData.balance
  return (
    <div>
      <span>Balanço:<b>{formatToCurrency(current_balance)}</b></span>
      <IconModal
        disabled={isEmpty(walletData) || current_balance <= 0}
        onConfirm={createWalletCredit}
        icon={CurrencyExchange}
        iconProps={{
          style: {
            color: colors.green
          }
        }}
        message='Devolver crédito para o responsável'
        title='Devolver crédito para o responsável'
        disableConfirm={(wallet_credit_form_values?.credit_out > current_balance || isEmpty(wallet_credit_form_values?.description))}
      >
        <WalletCreditForm
          form_name={'walletCreditForm'}
          current_balance={current_balance}
          initialValues={{ wallet_id: walletData?.id }}
        />
      </IconModal>
      <WalletCredits
        credits={walletCreditsData}
      />
    </div>
  )
}
const ResponsibleStepView = (props: {
  currentRegistration: CurrentRegistrationData;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setLoading: (value: boolean) => void;
}) => {
  const {
    setStep,
    currentRegistration,
    setLoading,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);
  const [currentRepresentativeId, setCurrentRepresentativeId] = React.useState<string>('');
  const [representativesData, setRepresentativesData] = React.useState<RepresentativeAttributes[]>([]);
  const [expandedAccountWalletRow, setExpandedAccountWalletRows] = React.useState<number[]>([])

  const fetchRegistrationRepresentatives = React.useCallback(async () => {
    const registration = await dispatch(
      FETCH_REGISTRATION.request({
        id: currentRegistration.id,
        params: {
          filters: {
            include: [
              'representatives',
            ].join(','),
          },
        },
      }),
    );
    const { data: { included } } = registration
    const includedRepresentatives = map(filter(included, (item) => item.type === 'representatives') as RepresentativeJson[], res => ({id: res.id, ...res.attributes}))
    setRepresentativesData(includedRepresentatives)
  }, [currentRegistration])
  const deleteRepresentativeMethod = async (id: string) => {
    try {
      await dispatch(
        DELETE_REPRESENTATIVE.request({
          id,
        }),
      );
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao remover responsável',
          autoDismiss: 3,
        }),
      );
    }
  };

  const verifyRegistrationProducts = React.useCallback(async (args: { handleClose: () => void, representative_id: string}) => {     
    const { handleClose, representative_id } = args 
    try {
      const response = await dispatch(
        FETCH_REPRESENTATIVE.request({
          id: representative_id,
          params: {
            filters: {
              'include': 'representative_products.registration_product'
            }
          }
        })
      )
      const { data: { included } } = response
      const includedRegistrationProducts = map(filter(included, (item) => item.type === 'registration_products') as RegistrationProductJson[], res => ({id: res.id, ...res.attributes}))
      if(includedRegistrationProducts.length > 0){
        dispatch(
          error({
            message: 'Não é possível remover o responsável pois este tem produtos na matrícula',
            autoDismiss: 3,
          }),
        );
        handleClose()
      }
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao verificar produtos do responsável',
          autoDismiss: 3,
        }),
      );
       handleClose()
    }
  }, [])

  React.useEffect(() => {
    if(currentRegistration?.id){
      fetchRegistrationRepresentatives()
    }
  }, [currentRegistration]);
  const handleTabChange = (_: any, value: number) => {
    setTabValue(value);
  };

  return (
    <div className={classes.wrapper}>
      <TabPanel value={tabValue} index={0}>
        <div className={classes.insertButton}>
          <button onClick={() => handleTabChange(null, 1)} className='blue small'>
            <span> Inserir </span>
          </button>
        </div>
        {representativesData.length ? (
          <div className={classes.table}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((item) => (
                      <TableCell key={item}>{item}</TableCell>
                    ))}
                    <TableCell> Ações </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {representativesData.map((representative, index, self) => {
                   
                    const editResponsible = () => {
                      setCurrentRepresentativeId(representative.id);
                      handleTabChange(null, 1)
                    };

                    const deleteResponsible: onConfirm = async ({ setLoading, handleClose }) => {
                      const repData = self[index];
                      try {
                        await deleteRepresentativeMethod(repData.id);
                        await fetchRegistrationRepresentatives()
                      } catch (er) {
                        dispatch(
                          error({
                            message: 'Erro na exclusão de responsáveis',
                          }),
                        );
                      } finally {
                        setLoading(false);
                        handleClose();
                      }
                    };
                    return (
                      <React.Fragment key={representative.id}>
                        <TableRow>
                          <TableCell>{convertDocument(representative.representative_document)}</TableCell>
                          <TableCell> {representative.representative_name} </TableCell>
                          <TableCell>
                            {' '}
                            {relationshipOptions.find((item) => representative.connection === item.value)?.label}{' '}
                          </TableCell>
                          <TableCell>
                          {representativeTypes.find((type) => type.value === representative.kind)?.label}
                          </TableCell>
                          <TableCell>
                            <Tooltip title='Ver carteira do responsável'>
                              <IconButton
                                disabled={!representative.wallet_id}
                                onClick={() => setExpandedAccountWalletRows((current) => {
                                  if (current.includes(~~representative.id)) {
                                    return current.filter((item) => item !== ~~representative.id);
                                  } else {
                                    return current.concat(~~representative.id);
                                  }
                                })}
                              >
                                <AccountBalanceWallet/>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Editar dados do responsável'>
                              <IconButton onClick={editResponsible}>
                                <PenIcon style={{ color: colors.blue }} />
                              </IconButton>
                            </Tooltip>
                            <IconModal
                              tooltipText='Remover responsável'
                              title='Remover responsável'
                              message={`Deseja remover o responsável ${representative.representative_name} desta matricula?`}
                              onConfirm={deleteResponsible}
                              icon={TrashIcon}  
                              iconProps={{ style: {color: colors.lightRed} }}
                              onOpen={(args) => verifyRegistrationProducts({...args, representative_id: representative.id})}                        
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 2}>
                            <Collapse in={expandedAccountWalletRow.includes(~~representative.id)} timeout='auto' unmountOnExit>
                              <WalletBallanceComponent wallet_id={representative.wallet_id} fetchRegistrationRepresentatives={fetchRegistrationRepresentatives} />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div>
            <span>Não há responsáveis inseridos até o momento.</span>
          </div>
        )}
        <div className={classes.buttons}>
          <button
            className='red small'
            onClick={() => {
              setStep((step) => step - 1);
            }}
          >
            Voltar
          </button>
          <button
            onClick={() => {
              setStep((step) => step + 1);
            }}
            className='green small'
          >
            {' '}
            Avançar{' '}
          </button>
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ResponsibleForm
          currentRegistration={currentRegistration}
          setTabValue={setTabValue}
          setLoading={setLoading}
          currentRepresentativeId={currentRepresentativeId}
          setCurrentRepresentativeId={setCurrentRepresentativeId}
          fetchRegistrationRepresentatives={fetchRegistrationRepresentatives}
        />
      </TabPanel>
    </div>
  );
};

export default ResponsibleStepView;
