import http from '../../utils/http'

const renegotiationEndpoint = '/renegotiations'

export const createRenegotiation = (params: object) => {
  return http.post(`${renegotiationEndpoint}`, params)
}

export const cancelRenegotiation = ({ id }: { id: string | number }) => {
  return http.get(`${renegotiationEndpoint}/${id}/cancel`)
}

