/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ClassroomsForm from '../components/form/ClassroomsForm';
import { CREATE_CLASSROOM, FETCH_CLASSROOM, UPDATE_CLASSROOM } from '../store/classrooms';
import { RootState } from '../store/configureStore';
import { FETCH_KTWELVES } from '../store/ktwelves';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { DefaultOptionType, ClassroomAttributes, LocationState, Paths, Role } from '../utils/constants';
import { evaluate_permissions, verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { formValueSelector } from 'redux-form';

const ClassroomsFormContainer = () => {
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();
  const state  = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
  } = state
  const formValues = formValueSelector('classroomsForm');
  const [initialValues, setInitialValues] = React.useState<ClassroomAttributes | null>(null);
  const [ktwelveOptions, setKtwelveOptions] = React.useState<DefaultOptionType[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const companyIdValue = formValues(state, 'company_id') as string;
  const isUpdating = !isNil(initialValues?.id);
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const company_id_to_use = location?.state?.company_id
    ? location?.state?.company_id
    : is_above_school_director
    ? companyIdValue
    : company;

  const loadKtwelveOptions = React.useCallback(async () => {
    try {
      const ktwelves = await dispatch(
        FETCH_KTWELVES.request({
          params: {
            filters: {
              'page[size]': '100',
            },
          },
        }),
      );
      const {
        data: { data },
      } = ktwelves;
      const formattedKtwelves = data.map((item) => {
        return {
          value: ~~item.id,
          label: item.attributes.name,
        };
      });
      setKtwelveOptions(formattedKtwelves);
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar as opções de série',
        }),
      );
    }
  }, []);
  const loadClassrooms = React.useCallback(async () => {
    if (location?.state?.classroom_id) {
      const classroom = await dispatch(
        FETCH_CLASSROOM.request({
          id: location.state.classroom_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = classroom;
      const formattedClassroom = {
        id,
        ...attributes,
      };
      setInitialValues(formattedClassroom);
    } else {
      setInitialValues({ company_id: company_id_to_use } as ClassroomAttributes)
    }
  }, [location]);

  const initClassroomsForm = React.useCallback(async () => {
    setLoading(true);
    await loadKtwelveOptions();
    await loadClassrooms();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: ClassroomAttributes) => {
      try {
        if (isUpdating) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'company_id',
          ]) as ClassroomAttributes;
          await dispatch(
            UPDATE_CLASSROOM.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          await dispatch(
            CREATE_CLASSROOM.request({
              data: {
                ...data,
                company_id: company_id_to_use,
              },
            }),
          );
        }
        history.push(Paths.CLASSROOM_LIST);
        dispatch(
          success({
            message: 'Turma salva com sucesso.',
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar turma.',
          }),
        );
      }
    },
    [initialValues, company_id_to_use, isUpdating, history],
  );

  React.useEffect(() => {
    initClassroomsForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <ClassroomsForm initialValues={initialValues} onSubmit={onSubmit} ktwelveOptions={ktwelveOptions} />;
};

export default ClassroomsFormContainer;
