import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'

const profileDashboardsEndpoint = '/profile_dashboards'

export const createProfileDashboard = (params: object) => {
  return http.post(`${profileDashboardsEndpoint}`, params)
}
export const updateProfileDashboard = ({ params, id }: { params: object, id: string|number }) => {
  return http.put(`${profileDashboardsEndpoint}/${id}`, params)
}


export const fetchProfileDashboards = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${profileDashboardsEndpoint}?${queryParams}`)
}

export const fetchProfileDashboard = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${profileDashboardsEndpoint}/${id}?${queryParams}`)
}

export const deleteDashboardLogo = ({ id }: { id: string|number }) => {
  return http.delete(`${profileDashboardsEndpoint}/${id}/dashboard_logo`)
}
