/* eslint-disable camelcase */
import { find, includes, isNil, keys } from 'lodash'
import React from 'react'
import { error, success } from 'react-notification-system-redux'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ProfileDashboard from '../components/form/ProfileDashboardsForm'
import { UPDATE_COMPANY } from '../store/companies'
import { RootState } from '../store/configureStore'
import { DELETE_DASHBOARD_LOGO, FETCH_PROFILE_DASHBOARDS } from '../store/profile_dashboards'
import { UI_SET_LOADING_OPEN } from '../store/ui'
import { companyTypes, DefaultOptionType, LocationState, NestedProfileDashboardAttributes, Paths, ProfileDashboardFormAttributes, profiles } from '../utils/constants'
import { generateFormDataNestedAttributePrefixString, logFormData, verifyDifferentValues } from '../utils/functions'

const profileDashboardsUpdater = (currentProfileDashboards: NestedProfileDashboardAttributes[], initialProfileDashboards: NestedProfileDashboardAttributes[]) => {
  const sorted:NestedProfileDashboardAttributes[] = []
  currentProfileDashboards.forEach(profileDashboard => {
    const related = find(initialProfileDashboards, initial => initial.id === profileDashboard.id)
    if (isNil(related)) {
      sorted.push(profileDashboard)
      return
    }
    const omit = ['id', 'company_id', '_destroy', 'dashboard_logo_url', 'dashboard_logo_attachment_id', 'dashboard_logo']
    const result = verifyDifferentValues(profileDashboard, related, omit) as NestedProfileDashboardAttributes
    if(keys(result).filter(key => key !== 'id').length > 0) {
      sorted.push(result)
    }
  })
  return sorted as NestedProfileDashboardAttributes[]
}


const formatSubmitData = (data: ProfileDashboardFormAttributes) => {
  const formData = new FormData()
  let purge_logos: string[] = []
  formData.append('data[type]', 'companies')
  const profileDashboards = data.profile_dashboards_attributes
  profileDashboards.forEach((profile_dashboard, index) => {
    keys(profile_dashboard).forEach(key => {
      if(key === 'id'){
        formData.append(generateFormDataNestedAttributePrefixString('profile_dashboards_attributes', 'id', index), profile_dashboard.id)
        return true
      } else if(key === '_destroy') {
        formData.append(generateFormDataNestedAttributePrefixString('profile_dashboards_attributes', '_destroy', index), 'true')
        return true
      } else if(key === 'dashboard_logo') {
        const dashboard_logo = profile_dashboard[key]
        if(dashboard_logo?.file){
          const value = dashboard_logo?.file as File || null
          formData.append(generateFormDataNestedAttributePrefixString('profile_dashboards_attributes','dashboard_logo', index), value)
        } else if(dashboard_logo === null && profile_dashboard.dashboard_logo_attachment_id !== null) {
          purge_logos = purge_logos.concat(profile_dashboard.id)
        }
        return true
      } else if (!['dashboard_logo_url', 'dashboard_logo_attachment_id'].includes(key)) {
        formData.append(generateFormDataNestedAttributePrefixString(`profile_dashboards_attributes`, key, index), profile_dashboard[key as keyof NestedProfileDashboardAttributes] as string)
      }
    })
  })
  logFormData(formData)
  return {formData, purge_logos}
}



const ProfileDashboardContainer = () => {
  const location  = useLocation<LocationState>()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    auth: {
      company, profile
     }   } = useSelector((state: RootState) => state)
  const [initialValues, setInitialValues] = React.useState<ProfileDashboardFormAttributes|null>(null)
  const [loaded, setLoaded] = React.useState(false)
  const [currentCompanyId, setCurrentCompanyId] = React.useState('')
  const [profileOptions, setProfileOptions] = React.useState<DefaultOptionType[]>([])
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value))
  }, [])


  const loadProfileDashboard = React.useCallback(async (profileOptions: DefaultOptionType[]) => {
    if(location?.state?.company_id && !loaded){
      setCurrentCompanyId(location.state.company_id.toString())
      const profileDashboards = await dispatch(
        FETCH_PROFILE_DASHBOARDS.request({
          params:{
            filters: {
              'q[company_id_eq]': location.state.company_id.toString(),
            }
          }
        })
      )
      const { data: { data } } = profileDashboards
      const profileDashboardsAttributes = data.filter(item => {
        return profileOptions.find(option => option.value === item.attributes.role)
      }).map(item => {
        return ({
          id: item.id,
          dashboard_logo: {
            url: item.attributes.dashboard_logo_url,
            id: item.attributes.dashboard_logo_attachment_id,
          },
          ...item.attributes,
        })
      })
      setInitialValues({
        profile_dashboards_attributes: [...profileDashboardsAttributes]
      })
    }
  },[location])

  const loadProfileOptions = React.useCallback(async () => {
    const currentCompanyIdKind = location?.state?.company_kind
    const allowedProfileDashboardsRolesInThisCompany = companyTypes.find(item => item.value === currentCompanyIdKind)?.canHaveProfileDashboards
    const newProfileOptions = profiles.find(item => item.name === profile.role)?.canCreateProfileDashboardsFor.filter(role => includes(allowedProfileDashboardsRolesInThisCompany, role)).map(role => {
      const label = profiles.find(profile => profile.name === role)?.label as string
      return ({
        value: role as string,
        label
      })
    }) as DefaultOptionType[]
    setProfileOptions(newProfileOptions)
    return newProfileOptions
  }, [profile, location])

  const initProfileDashboardForm = React.useCallback(async () => {
    setLoading(true)
    const newProfileOptions = await loadProfileOptions()
    await loadProfileDashboard(newProfileOptions)
    setLoading(false)
    setLoaded(true)
  },[])

  const onSubmit = React.useCallback(async (data: ProfileDashboardFormAttributes) => {
    try {
      const initial_profile_dashboards_attributes = initialValues?.profile_dashboards_attributes || []
      let { profile_dashboards_attributes } = data
      profile_dashboards_attributes = profileDashboardsUpdater(profile_dashboards_attributes, initial_profile_dashboards_attributes)
      const payload = {profile_dashboards_attributes}

      const {formData, purge_logos} = formatSubmitData(payload)
      formData.append('data[id]', currentCompanyId)
      await purge_logos.forEach(async (profile_dashboard_id) => {
        await dispatch(DELETE_DASHBOARD_LOGO.request({
          id: profile_dashboard_id
        }))
      })
      await dispatch(
        UPDATE_COMPANY.request({
          id: currentCompanyId,
          data: formData
        })
      )
      history.push(Paths.HOME)
      dispatch(
        success(
          {
            message: 'Dashboards por perfil salvos com sucesso.'
          }
        )
      )
    } catch (er) {
      dispatch(
        error(
          {
            message: 'Erro ao salvar dashboards por perfil.'
          }
        )
      )
    }
  },[initialValues, company, currentCompanyId])

  React.useEffect(() => {
    initProfileDashboardForm()
  }, [location])

  return (
    <ProfileDashboard
      initialValues={initialValues}
      onSubmit={onSubmit}
      profileOptions={profileOptions}
    />
  )
}

export default ProfileDashboardContainer
