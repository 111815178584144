import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_PENDING_SIGNATURE,
  FETCH_PENDING_SIGNATURES,
  FetchPendingSignaturePayload,
  FetchPendingSignaturesPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchPendingSignatures (action: DefaultAction<FetchPendingSignaturesPayload, string>): any {
  const { payload: { params } } = action
  try {
    const response = yield call(services.fetchPendingSignatures, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_PENDING_SIGNATURES.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(FETCH_PENDING_SIGNATURES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchPendingSignature (action :DefaultAction<FetchPendingSignaturePayload, string>): any {
  const { payload: { params, id } } = action
  try {
    const response = yield call(services.fetchPendingSignature, { params, id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_PENDING_SIGNATURE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_PENDING_SIGNATURE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchPendingSignaturesagas () {
  yield takeLatest(FETCH_PENDING_SIGNATURES.request, fetchPendingSignatures)
  yield takeLatest(FETCH_PENDING_SIGNATURE.request, fetchPendingSignature)
}

export default function * pendingSignaturesagas () {
  yield all([watchPendingSignaturesagas()])
}
