import React from 'react';
import { SvgIcon } from '@mui/material';

const PedagogicIcon = (props: any) => (
  <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M30.5506 4.49752C30.8204 4.60541 31.0002 4.87515 31.0002 5.16287V28.252C31.0002 28.7555 30.4877 29.1062 30.0202 28.9263C25.3987 27.1371 20.7683 27.1371 16.1109 28.9263C15.9401 28.9893 15.7603 28.9893 15.5984 28.9263C10.977 27.1551 6.3466 27.1551 1.68022 28.9263C1.2037 29.1062 0.700195 28.7555 0.700195 28.252V5.16287C0.700195 4.86616 0.880017 4.59643 1.15874 4.48853C6.10384 2.56444 11.013 2.52847 15.8502 4.38963C20.7773 2.5015 25.6865 2.53746 30.5506 4.49752Z' stroke='black' strokeMiterlimit='10'/>
    <path d='M15.8496 4.38965V28.8904' stroke='black' strokeMiterlimit='10'/>
    <path d='M10.7879 9.49658H5.01562' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
    <path d='M10.7879 13.8213H5.01562' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
    <path d='M10.7879 18.1548H5.01562' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
    <path d='M10.7879 22.4795H5.01562' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
    <path d='M25.9469 9.49658H20.1836' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
    <path d='M25.9469 13.8213H20.1836' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
    <path d='M25.9469 18.1548H20.1836' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
    <path d='M25.9469 22.4795H20.1836' stroke='black' strokeMiterlimit='10' strokeLinecap='round'/>
  </SvgIcon>
);

export default PedagogicIcon;
