import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
    CREATE_CONTRACT,
    CreateContractPayload,
    FetchContractsPayload,
    SendContractPayload,
    FETCH_CONTRACTS,
    SEND_CONTRACT,
    FETCH_CONTRACT,
    FetchContractPayload,
    DeleteContractPayload,
    DELETE_CONTRACT,
    SignContractsBatchPayload,
    SIGN_CONTRACTS_BATCH,
    ResendContractPayload,
    RESEND_CONTRACT,
    GENERATE_BILLINGS,
    RefreshContractPayload,
    REFRESH_CONTRACT
} from '.'

import { updateCredentialsRequest } from '../auth'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchContracts (action: DefaultAction<FetchContractsPayload, string>): any {
  const { payload: {params}} = action
  try {
    const response = yield call(services.fetchContracts, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CONTRACTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CONTRACTS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * signContractsBatch (action: DefaultAction<SignContractsBatchPayload, string>): any {
  const { payload: { data } } = action
  try {
    const params = {
      data: {
        type: 'contracts',
        attributes: { ...data }
      }
    }
    const response = yield call(services.signContractsBatch, {data: params})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(SIGN_CONTRACTS_BATCH.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(SIGN_CONTRACTS_BATCH.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createContract (action: DefaultAction<CreateContractPayload, string>) :any {
  const { payload: {data}} = action
  try {
    const params = {
      data: {
        type: 'contracts',
        attributes: { ...data }
      }
    }
    const response = yield call(services.createContract, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_CONTRACT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_CONTRACT.failure(e))
    rejectPromiseAction(action, e)
  }
}

  export function * sendContract (action: DefaultAction<SendContractPayload, string>) :any {
    const { payload: {data,id}} = action
    try {
      const params = {
        data: {
          type: 'contracts',
          attributes: { ...data }
        }
      }
      const response = yield call(services.sendContract, {params,id})
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(SEND_CONTRACT.success(response))
      resolvePromiseAction(action, response)
    } catch (e) {
      yield put(SEND_CONTRACT.failure(e))
      rejectPromiseAction(action, e)
    }
  }

  export function * resendContract (action: DefaultAction<ResendContractPayload, string>) :any {
    const { payload: {id}} = action
    try {
      const response = yield call(services.resendContract, {id})
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(RESEND_CONTRACT.success(response))
      resolvePromiseAction(action, response)
    } catch (e) {
      yield put(RESEND_CONTRACT.failure(e))
      rejectPromiseAction(action, e)
    }
  }

  export function * refreshContract (action: DefaultAction<RefreshContractPayload, string>) :any {
    const { payload: {id}} = action
    try {
      const response = yield call(services.refreshContract, {id})
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(REFRESH_CONTRACT.success(response))
      resolvePromiseAction(action, response)
    } catch (e) {
      yield put(REFRESH_CONTRACT.failure(e))
      rejectPromiseAction(action, e)
    }
  }


  export function * generateBillings (action: DefaultAction<ResendContractPayload, string>) :any {
    const { payload: {id}} = action
    try {
      const response = yield call(services.generateBillings, {id})
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(GENERATE_BILLINGS.success(response))
      resolvePromiseAction(action, response)
    } catch (e) {
      yield put(GENERATE_BILLINGS.failure(e))
      rejectPromiseAction(action, e)
    }
  }

  export function * fetchContract (action: DefaultAction<FetchContractPayload, string>): any {
    const {payload:{id, params}} = action
    try {
      const response = yield call(services.fetchContract, {id, params})
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(FETCH_CONTRACT.success(response))
      resolvePromiseAction(action, response)
    } catch(e) {
      yield put(FETCH_CONTRACT.failure(e))
      rejectPromiseAction(action, e)
    }
  }

  export function * deleteContract (action: DefaultAction<DeleteContractPayload, string>): any {
    const {payload:{id}} = action
    try {
      const response = yield call(services.deleteContract, {id})
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(DELETE_CONTRACT.success(response))
      resolvePromiseAction(action, response)
    } catch(e) {
      yield put(DELETE_CONTRACT.failure(e))
      rejectPromiseAction(action, e)
    }
  }
  export function * watchContractSagas () {
    yield takeLatest(CREATE_CONTRACT.request, createContract)
    yield takeLatest(FETCH_CONTRACTS.request, fetchContracts)
    yield takeLatest(SEND_CONTRACT.request, sendContract)
    yield takeLatest(RESEND_CONTRACT.request, resendContract)
    yield takeLatest(REFRESH_CONTRACT.request, refreshContract)
    yield takeLatest(GENERATE_BILLINGS.request, generateBillings)
    yield takeLatest(FETCH_CONTRACT.request, fetchContract)
    yield takeLatest(DELETE_CONTRACT.request, deleteContract)
    yield takeLatest(SIGN_CONTRACTS_BATCH.request, signContractsBatch)
  }
  
  export default function * contractSagas () {
    yield all([watchContractSagas()])
  }
  
  