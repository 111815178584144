import React from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import ContractSettingsView from '../components/view/Secretary/ContractSettingsView';
import { RootState } from '../store/configureStore';
import { FETCH_CONTRACT_SETTINGS } from '../store/contract_settings';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import {
  ContractSettingsViewAttributes,
  defaultPagination,
  PaginationType,
} from '../utils/constants';
import { CompanyJson } from '../store/companies';
import { get_company_search } from '../utils/functions';

const ContractSettingsContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, company_descendants },
    account: { companies },
  } = state;

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination);
  const [contractSettings, setContractSettings] = React.useState<ContractSettingsViewAttributes[]>([]);
  const [descriptionFilterValue, setDescriptionFilterValue] = React.useState('');
  const [companyFilterValue, setCompanyFilterValue] = React.useState<string>('');

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);

  const fetchContractSettingsMethod = React.useCallback(
    async ({
      newPagination = pagination,
      company_filter = companyFilterValue,
    }: {
      newPagination?: PaginationType;
      company_filter?: string;
    }) => {
      setLoading(true);
      const companies_array = get_company_search({
        companies,
        company_descendants,
        company_filter,
        current_company_id: company
      })

      try {
        const contractSettings = await dispatch(
          FETCH_CONTRACT_SETTINGS.request({
            params: {
              filters: {
                include: 'company',
                'q[company_id_in]': companies_array,
                'q[description_cont]': descriptionFilterValue,
                'page[number]': (newPagination.pageNumber + 1).toString(),
                'page[size]': newPagination.pageSize.toString(),
              },
            },
          }),
        );
        const {
          data: { data, meta, included },
        } = contractSettings;
        const formattedContractSettings = data.map((item) => {
          const company = included.find((co) => ~~co.id === item.attributes.company_id) as CompanyJson;
          return {
            id: item.id,
            ...item.attributes,
            company: company?.attributes.name,
          };
        });
        setPagination((current) => ({
          ...current,
          pageCount: meta.page_count,
          totalCount: meta.total_count,
        }));
        setContractSettings(formattedContractSettings);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          error({
            message: 'Erro ao carregar configurações de contrato',
          }),
        );
      }
    },
    [descriptionFilterValue, company, pagination],
  );

  const handlePageChange = React.useCallback(
    (_: any, newPage: number) => {
      setPagination({
        ...pagination,
        pageNumber: newPage,
      });
      fetchContractSettingsMethod({
        newPagination: {
          ...pagination,
          pageNumber: newPage,
        },
      });
    },
    [pagination],
  );

  const handleChangePageSize = React.useCallback(
    (e: any) => {
      setPagination({
        ...pagination,
        pageSize: e.target.value,
      });
      fetchContractSettingsMethod({
        newPagination: {
          ...pagination,
          pageSize: e.target.value,
        },
      });
    },
    [pagination],
  );

  React.useEffect(() => {
    fetchContractSettingsMethod({});
  }, []);

  return (
    <ContractSettingsView
      contractSettings={contractSettings}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      descriptionFilterValue={descriptionFilterValue}
      setDescriptionFilterValue={setDescriptionFilterValue}
      fetchContractSettingsMethod={fetchContractSettingsMethod}
      setCompanyFilterValue={setCompanyFilterValue}
      companyFilterValue={companyFilterValue}
    />
  );
};

export default ContractSettingsContainer;
