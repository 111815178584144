import React from 'react'
import { css } from '@emotion/react'
import { filter, includes, isEmpty, isNil } from 'lodash'
import { Field } from 'redux-form'
import { DefaultOptionType, discountKindOptions, DiscountTypes, monthOptions, SubsidyAttributes, validation } from '../../utils/constants'
import { getPropsAndParser } from '../../utils/functions'
import CheckboxComponent from '../input/form/checkbox'
import InputComponent from '../input/form/input'
import MaskedInputComponent from '../input/form/masked'
import SelectComponent from '../input/form/select'
import SelectMultipleComponent from '../input/form/selectmultiple'
import TextAreaComponent from '../input/form/textarea'
import DatePickerComponent from '../input/form/datepicker'

const subsidyFormWrapperCss = css`
  display: grid;
  row-gap: 1rem;
`

const SubsidyForm = ({
  discountTypesOptions,
  subsidyKind,
  monthsRange,
  current_subsidy
} : {
  discountTypesOptions: DefaultOptionType[]
  subsidyKind: DiscountTypes
  monthsRange: number[]
  current_subsidy: SubsidyAttributes
}) => {
  const formattedMonthOptions = monthsRange.length ? filter(monthOptions, item => includes(monthsRange, item.value)) : monthOptions
  return (
    <div css={subsidyFormWrapperCss}>
      <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
        <Field
          name='discount_type_id'
          component={SelectComponent}
          options={discountTypesOptions}
          label={'Tipo de desconto'}
          placeholder={'Selecione o tipo de desconto'}
          validate={validation.required}
        />
        <Field
          name='name'
          component={InputComponent}
          label={'Nome'}
          placeholder={'Insira o nome do desconto'}
          validate={validation.required}     
        />
        <Field
          name={`active_starts_at`}
          label={'Selecionavel a partir de *'}
          placeholder={'01/01/2021'}
          component={DatePickerComponent}
          datePickerProps={{
              ...(current_subsidy.active_ends_at ? {maxDate:current_subsidy.active_ends_at } : {} ),
              showDropdownIcon: true,
          }}
        />
        <Field
          name={`active_ends_at`}
          label={'Selecionavel até *'}
          placeholder={'01/01/2021'}
          component={DatePickerComponent}
          datePickerProps={{
            ...(current_subsidy.active_starts_at ? {minDate:current_subsidy.active_starts_at } : {} ),
            showDropdownIcon: true,
          }}
        />
      </div>
      <Field
        name='description'
        component={TextAreaComponent}
        label={'Descrição'}
        placeholder={'Insira a descrição do produto'}
        validate={validation.required}
      />
      <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', justifyContent: 'space-between' }}>
        <Field
          name='kind'
          component={SelectComponent}
          options={discountKindOptions}
          label={'Tipo do desconto'}
          placeholder={'Selecione o tipo do desconto'}
          validate={validation.required}
        />
        <Field
          name='discount'
          component={MaskedInputComponent}
          disabled={isNil(subsidyKind) || subsidyKind === 'none'}
          parse={getPropsAndParser(subsidyKind).parser}
          maskProps={getPropsAndParser(subsidyKind).props}  
          label={'Valor do desconto'}
          onlyNumbers
          placeholder={'Selecione o valor do desconto'}
          validate={validation.required}
        />
        <Field
          name='changeable'
          component={CheckboxComponent}
          placeholder={'Alterável?'}
        />
        <Field
          name='include_registration'
          component={CheckboxComponent}
          placeholder={'Permitir entrada?'}
        />
      </div>
      <Field
        label='Meses'
        placeholder='Selecione os meses do desconto'
        name='portions'
        disabled={isEmpty(formattedMonthOptions)}
        component={SelectMultipleComponent}
        options={formattedMonthOptions}
        allow_select_all
      />
    </div>
  )
}

export default SubsidyForm