/* eslint-disable camelcase */
import React from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/system';
import {
  colors,
  discountKindOptions,
  PaginationType,
  Paths,
  Role,
  defaultPagination,
  DiscountTypesViewAttributes,
} from '../../../utils/constants';
import TablePaginationActions from '../../shared/TablePaginationActions';
import { useHistory } from 'react-router-dom';
import PenIcon from '../../icon/PenIcon';
import InputComponent from '../../input/form/input';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/configureStore';
import { evaluate_permissions, getCompanyFilterOptions } from '../../../utils/functions';
import SelectComponent from '../../input/form/select';

const useStyles = makeStyles(
  (
    theme: Theme,
  ): {
    view: any;
    tableTitle: any;
    button: any;
    filters: any;
    table: any;
  } => ({
    view: {
      width: 'inherit',
      minHeight: '100%',
      height: 'fit-content',
      display: 'flex',
      padding: '2rem',
      flexDirection: 'column',
      flexGrow: '1',
      position: 'relative',
      [theme.breakpoints?.down('lg')]: {
        padding: '2rem 1rem',
      },
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
        overflow: 'hidden',
      },
    },
    tableTitle: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1rem',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      cursor: 'pointer',
      background: colors.green,
      padding: '1rem 4rem',
      border: 'none',
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      gap: '1rem',
      '& > span': {
        marginBottom: '14px',
      },
      '& .inputArea': {
        width: '10rem !important',
        paddingRight: '3rem',
      },
    },
    table: {
      background: colors.lightBlue,
      padding: '2rem',
      borderRadius: '4px',
      display: 'grid',
      '& .subtitle': {
        textAlign: 'start',
        marginBottom: '1rem',
      },
      '& .MuiTableContainer-root': {
        background: 'inherit',
        boxShadow: 'none',
        '& .MuiTableCell-root': {
          borderBottom: `1px solid ${colors.grayBlue}`,
        },

        '& th': {
          color: colors.darkGrayBlue,
        },
        '& td': {
          fontSize: '12px',
          color: colors.darkBlue,
          [theme.breakpoints?.down('lg')]: {
            fontSize: '0.75rem',
          },
          '& svg': {
            cursor: 'pointer',
            margin: '0 0.25rem',
          },
        },
        '& td.MuiTableCell-footer ': {
          borderBottom: 'none',
        },
      },
    },
  }),
);



const DiscountTypesView = ({
  discountTypes,
  pagination,
  handleChangePageSize,
  handlePageChange,
  descriptionFilterValue,
  setDescriptionFilterValue,
  nameFilterValue,
  setNameFilterValue,
  fetchDiscountTypesMethod,
  setCompanyFilterValue,
  companyFilterValue,
}: {
  discountTypes: DiscountTypesViewAttributes[];
  pagination: PaginationType;
  handlePageChange: (_: any, newPage: number) => void;
  handleChangePageSize: (e: any) => void;
  descriptionFilterValue: string;
  setDescriptionFilterValue: React.Dispatch<React.SetStateAction<string>>;
  nameFilterValue: string;
  setNameFilterValue: React.Dispatch<React.SetStateAction<string>>;
  fetchDiscountTypesMethod: ({
    newPagination,
    company_filter,
  }: {
    newPagination?: PaginationType | undefined;
    company_filter?: string;
  }) => Promise<void>;
  setCompanyFilterValue: React.Dispatch<React.SetStateAction<string>>;
  companyFilterValue: string;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const state = useSelector((state: RootState) => state);
  const {
    auth: { company, profile, company_descendants },
    account: { companies },
  } = state;

  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const companies_options = getCompanyFilterOptions({
    is_above_school_director,
    current_company_id: company,
    company_descendants,
    companies,
  });

  const columns = [
    ...(is_above_school_director ? ['Unidade'] : []),
    'Nome',
    'Descrição',
    'Tipo Sugerido',
    'Valor Sugerido',
    '',
  ];
  return (
    <div className={classes.view}>
      <div className={classes.tableTitle}>
        <span className='title'>Tipos de descontos</span>
        <button className='green small' onClick={() => history.push(Paths.DISCOUNT_TYPES_FORM)}>
          <span>Criar tipo de desconto</span>
        </button>
      </div>
      <div className={classes.table}>
        <span className='subtitle'>Filtros</span>
        <div className={classes.filters}>
          {is_above_school_director && (
            <SelectComponent
              placeholder='Selecionar Unidade'
              small
              options={companies_options}
              input={{
                value: companyFilterValue,
                onChange: (e: any) => {
                  setCompanyFilterValue(e.target.value);
                  fetchDiscountTypesMethod({
                    newPagination: { ...defaultPagination },
                    company_filter: e.target.value,
                  });
                },
              }}
            />
          )}

          <InputComponent
            placeholder={`Buscar por nome`}
            search
            small
            onSearch={() => {
              fetchDiscountTypesMethod({});
            }}
            input={{
              value: nameFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setNameFilterValue(e.target.value);
              },
            }}
          />
          <InputComponent
            placeholder={`Buscar por descrição`}
            search
            small
            onSearch={() => {
              fetchDiscountTypesMethod({});
            }}
            input={{
              value: descriptionFilterValue,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setDescriptionFilterValue(e.target.value);
              },
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {discountTypes.map((discount_type) => {
                const kind = discountKindOptions.find((item) => item.value === discount_type.suggested_kind)?.label;
                return (
                  <TableRow key={discount_type.id}>
                    {is_above_school_director && (
                      <TableCell>{discount_type.company}</TableCell>
                    )}
                    <TableCell>{discount_type.name}</TableCell>
                    <TableCell>{discount_type.description}</TableCell>
                    <TableCell>{kind}</TableCell>
                    <TableCell>{discount_type.suggested_discount}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          history.push(Paths.DISCOUNT_TYPES_FORM, {
                            discount_type_id: discount_type.id,
                            company_id: discount_type.company_id
                          });
                        }}
                      >
                        <PenIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  colSpan={columns?.length + 1}
                  count={pagination.totalCount}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageNumber}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangePageSize}
                  labelRowsPerPage='Itens Por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `'mais de' ${to}`}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default DiscountTypesView;
