import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
import http from '../../utils/http'

const registrationClosureReasonEndpoint = '/registration_closure_reasons'

export const fetchRegistrationClosureReasons = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${registrationClosureReasonEndpoint}?${queryParams}`)
}
