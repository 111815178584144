import React from 'react'
import ExportCompanyDataOptions from './ExportCompanyData';
import { InvoiceFilters, Role } from '../../utils/constants';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

const SchoolExportListsComponent = (props: { is_registrations_view: boolean, is_invoices_view: boolean, defaultFilters?: InvoiceFilters }) => {
  const state = useSelector((state: RootState) => state);
  const {
    auth: { profile, company },
    account: { companies },
  } = state;
  const currentCompany = find(companies, (cia) => cia.id === company);
  const isSchoolAdmin = [Role.SCHOOL_ADMIN, Role.SCHOOL_MANAGER].includes(profile.role);
  if (!isSchoolAdmin) {
    return null;
  }

  return (
    <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
      <ExportCompanyDataOptions
        message='Clique em confirmar para exportar a planilha'
        type='button'
        isSchool
        allowedRole={isSchoolAdmin}
        companyId={company}
        companyName={currentCompany?.name}
        companyKind={currentCompany?.kind}
        options={[]}
        onClose={() => null}
        {...props}
      />
    </div>
  );
};

export default SchoolExportListsComponent