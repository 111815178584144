import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_PRODUCT_FAMILIES,
  FetchProductFamiliesPayload,
  FetchProductFamilyPayload,
  CreateProductFamilyPayload,
  UpdateProductFamilyPayload,
  FETCH_PRODUCT_FAMILY,
  CREATE_PRODUCT_FAMILY,
  UPDATE_PRODUCT_FAMILY,
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchProductFamilies (action: DefaultAction<FetchProductFamiliesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchProductFamilies, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_PRODUCT_FAMILIES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_PRODUCT_FAMILIES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchProductFamily (action: DefaultAction<FetchProductFamilyPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchProductFamily, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_PRODUCT_FAMILY.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_PRODUCT_FAMILY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createProductFamily (action: DefaultAction<CreateProductFamilyPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'product_families',
        attributes: { ...data },
      }
    }
    const response = yield call(services.createProductFamily, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_PRODUCT_FAMILY.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_PRODUCT_FAMILY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateProductFamily (action: DefaultAction<UpdateProductFamilyPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'product_families',
        attributes: { ...data },
        id
      }
    }
    const response = yield call(services.updateProductFamily, {data: params, id})
    yield put(UPDATE_PRODUCT_FAMILY.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_PRODUCT_FAMILY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchProductFamiliesSagas () {
  yield takeLatest(FETCH_PRODUCT_FAMILIES.request, fetchProductFamilies)
  yield takeLatest(FETCH_PRODUCT_FAMILY.request, fetchProductFamily)
  yield takeLatest(CREATE_PRODUCT_FAMILY.request, createProductFamily)
  yield takeLatest(UPDATE_PRODUCT_FAMILY.request, updateProductFamily)

}

export default function * productFamilySagas () {
  yield all([watchProductFamiliesSagas()])
}
