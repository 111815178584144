/* eslint-disable camelcase */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { success, error } from 'react-notification-system-redux';
import {
  LocationState,
  Paths,
  Role,
  ElementView,
  CurriculumAttributes} from '../utils/constants';
import {
  FetchCurriculumResponse,
  FETCH_CURRICULUM,
} from '../store/curriculums';

import { RootState } from '../store/configureStore';


import { evaluate_permissions } from '../utils/functions';
import { formValueSelector } from 'redux-form';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import SetupView from '../components/shared/SetupView';
import Loading from '../components/loading/Loading';
import CurriculumStep from '../components/view/Pedagogic/CurriculumStep';
import CompositionStep from '../components/view/Pedagogic/CompositionStep';
import KtwelveStep from '../components/view/Pedagogic/KtwelveStep';

const CurriculumSetupContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state: RootState) => state);
  const {
      auth: { company: companyId, profile},
  } = state
  const formValues = formValueSelector('curriculum-studentstep');
  const companyIdValue = formValues(state, 'company_id') as string;
  const location = useLocation<LocationState>();
  const [curriculum, setCurrentCurriculum] = React.useState<CurriculumAttributes>();
  const [renderReady, setRenderReady] = React.useState(false);
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const company_id_to_use = curriculum?.id
    ? curriculum.company_id
    : is_above_school_director
    ? companyIdValue
    : companyId;
  const setLoading = (value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value))
  }
  
  const updateCurrentCurriculum = async (res: FetchCurriculumResponse) => {
      const {
          data: {
              data: { attributes, id },
          },
      } = res;
      const formattedData = {
          ...attributes,
          id,
      } as CurriculumAttributes;
      setCurrentCurriculum(formattedData);
      dispatch(
        success({
            message: 'Matriz curricular carregada com sucesso!',
            autoDismiss: 1,
        }),
      );
  };

  const fetchAndUpdateCurriculumMethod = async (id: string = curriculum?.id as string) => {
    setLoading(true)
    try {
      const curriculum = await dispatch(
        FETCH_CURRICULUM.request({
            id,
        }),
      );
      await updateCurrentCurriculum(curriculum)
      setLoading(false)
    } catch (er) {
      console.error('er',er)
      dispatch(
        error({
            message: 'Erro ao carregar a matriz curricular',
            autoDismiss: 3,
        }),
      )
    }
  }


  const init = async () => {
    setRenderReady(false)
    if (location?.state?.curriculum_id) {
      await fetchAndUpdateCurriculumMethod(location.state.curriculum_id.toString())
    }
    setRenderReady(true)
  }


  React.useEffect(() => {
      init()
  }, []);


  React.useEffect(() => {
      if (curriculum !== undefined && curriculum.company_id !== company_id_to_use) {
        history.push(Paths.SECRETARY);
      }
  }, [company_id_to_use]);

  const elements_array: ElementView[] = [
    {
      step: 0,
      path: Paths.CURRICULUMS_FORM,
      label: 'Matriz Curricular',
      element: CurriculumStep,
      props: {fetchAndUpdateCurriculumMethod},
    },
    {
      step: 1,
      path: Paths.CURRICULUMS_COMPOSITIONS_LIST,
      label: 'Composições',
      element: CompositionStep,
      props: {
        curriculum
      },
    },
    {
      step: 2,
      label: 'Séries',
      path: Paths.CURRICULUM_CURRICULUM_KTWELVES,
      element: KtwelveStep,
      props: {
        curriculum
      }
    }
  ]

  if(!renderReady){
    return <Loading />
  }

  return (
    <SetupView
      custom_tabs_props={{grid_template_columns: 'repeat(3, 0.333fr)'}}
      current_resource={curriculum as CurriculumAttributes}
      element_array={elements_array}
      title={curriculum ? curriculum.name : 'Nova Matriz'}
      tooltip_title='Atualizar Matriz'
    />
  )
}

export default CurriculumSetupContainer
