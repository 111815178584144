import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_STUDENT_ABSENCE, CreateStudentAbsencePayload, DELETE_STUDENT_ABSENCE, DeleteStudentAbsencePayload, FETCH_STUDENT_ABSENCE, FETCH_STUDENT_ABSENCES, FetchStudentAbsencePayload, FetchStudentAbsencesPayload, UPDATE_STUDENT_ABSENCE, UpdateStudentAbsencePayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchStudentAbsences (action: DefaultAction<FetchStudentAbsencesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchStudentAbsences, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_STUDENT_ABSENCES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_STUDENT_ABSENCES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchStudentAbsence (action: DefaultAction<FetchStudentAbsencePayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchStudentAbsence, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_STUDENT_ABSENCE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_STUDENT_ABSENCE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createStudentAbsence (action: DefaultAction<CreateStudentAbsencePayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'student_absences',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createStudentAbsence, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_STUDENT_ABSENCE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_STUDENT_ABSENCE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateStudentAbsence (action: DefaultAction<UpdateStudentAbsencePayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'student_absences',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateStudentAbsence, {data: params, id})
    yield put(UPDATE_STUDENT_ABSENCE.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_STUDENT_ABSENCE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteStudentAbsence (action: DefaultAction<DeleteStudentAbsencePayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteStudentAbsence, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_STUDENT_ABSENCE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_STUDENT_ABSENCE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchStudentAbsencesSagas () {
  yield takeLatest(FETCH_STUDENT_ABSENCES.request, fetchStudentAbsences)
  yield takeLatest(FETCH_STUDENT_ABSENCE.request, fetchStudentAbsence)
  yield takeLatest(CREATE_STUDENT_ABSENCE.request, createStudentAbsence)
  yield takeLatest(UPDATE_STUDENT_ABSENCE.request, updateStudentAbsence)
  yield takeLatest(DELETE_STUDENT_ABSENCE.request, deleteStudentAbsence)
}

export default function * student_absencesSagas () {
  yield all([watchStudentAbsencesSagas()])
}
