/* eslint-disable camelcase */
import React from 'react'
import { makeStyles } from '@mui/styles';
import { compose } from 'redux';
import {
  Field,
  reduxForm,
} from 'redux-form';
import {
  validation,
  DefaultOptionType,
  ktwelveSubjectKindOptions,
  ktwelveSubjectEvaluationKindOptions,
  KtwelveCurriculumAttributes,
  KtwelveSubjectAttributes,
} from '../../utils/constants';
import SelectComponent from '../input/form/select';
import { useDispatch } from 'react-redux';
import { FETCH_CURRICULUM } from '../../store/curriculums';
import { reduce } from 'lodash';
import { error } from 'react-notification-system-redux';
import Loading from '../loading/Loading';

const useStyles = makeStyles(
  (): {
    view: any;
    title: any;
    form: any;
    buttons: any;
  } => ({
    view: {
      width: 'inherit',
      height: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      '& .MuiBackdrop-root': {
        position: 'inherit',
      },
      '& .MuiPaper-root': {
        background: 'none',
        boxShadow: 'none',
      },
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
    form: {
      display: 'grid',
      rowGap: '1rem',
      background: '#FDFEFF',
      padding: '1rem',
    },

    buttons: {
      margin: '2rem 1rem',
      display: 'flex',
      justifyContent: 'end',
      gap: '1rem',
    },
  }),
);

const form_name = 'ktwelveSubjectForm'

const KtwelveSubjectsForm = (props: {
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form: () => void;
  ktwelve_subjects_options: DefaultOptionType[]
  ktwelve_curriculum: KtwelveCurriculumAttributes
  initialValues: KtwelveSubjectAttributes
}) => {
  const { handleSubmit, onSubmit, close_form, ktwelve_subjects_options, ktwelve_curriculum, initialValues } = props;
  const classes = useStyles();
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(true)
  const [curriculumSubjectOptions, setCurriculumSubjectOptions] = React.useState<DefaultOptionType[]>([])

  const fetchCurriculumSubjects = React.useCallback(async () => {
    try {
      const response = await dispatch(
        FETCH_CURRICULUM.request({
          id: ktwelve_curriculum.curriculum_id,
          params: {
            filters: {
              include: 'curriculum_subjects',
            },
          },
        }),
      );
      const {
        data: { included },
      } = response;
      const subject_options_labels = ktwelve_subjects_options.map(item => item.label)
      const curriculum_subjects = reduce(
        included,
        (acc, incl) => {
          if (incl.type === 'curriculum_subjects' && !subject_options_labels.includes(incl.attributes.subject_name)) {
            return acc.concat({ value: incl.id, label: incl.attributes.subject_name });
          }
          return acc;
        },
        [] as DefaultOptionType[],
      );
      setCurriculumSubjectOptions([...curriculum_subjects, ...(initialValues.id ? [{label: initialValues.subject_name, value: ~~initialValues.curriculum_subject_id}] : [])]);
    } catch (er) {
      dispatch(
        error({
          message: 'Erro ao disciplina da série',
          autoDismiss: 3,
        }),
      );
    }
  }, [ktwelve_curriculum, ktwelve_subjects_options]);
  const init = async () => {
    setLoading(true);
    await fetchCurriculumSubjects();
    setLoading(false);
  };

  React.useEffect(() => {
    init()
  }, [])

  if(loading){
    return <Loading />
  }
  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>Salvar Disciplina</span>
      <form className={classes.form}>
      <div style={{ width: '100%' }}>
          <Field
            label={'Selecionar disciplina'}
            placeholder={'Selecionar disciplina'}
            name='curriculum_subject_id'
            component={SelectComponent}
            options={curriculumSubjectOptions}
            validate={[validation.required]}
          />
          <Field
            label={'Tipo'}
            placeholder={'Selecionar tipo'}
            name='kind'
            component={SelectComponent}
            options={ktwelveSubjectKindOptions}
            validate={[validation.required]}
          />
          <Field
            label={'Tipo de avaliação'}
            placeholder={'Selecionar tipo de avaliação'}
            name='evaluation_kind'
            component={SelectComponent}
            options={ktwelveSubjectEvaluationKindOptions}
            validate={[validation.required]}
          />
          <Field
            label={'Disciplina Mãe'}
            placeholder={'Selecionar disciplina mãe'}
            name='parent_id'
            component={SelectComponent}
            options={ktwelve_subjects_options}
          />
        </div>
        <div className={classes.buttons}>
          <button onClick={(e) => {
              e.preventDefault()
              close_form()
            }
          }
            className='red small'>
            <span> Cancelar </span>
          </button>
          <button onClick={handleSubmit(onSubmit)} className='blue small'>
            <span> Salvar Disciplina </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: form_name,
  }),
)(KtwelveSubjectsForm);
