import React from 'react'
import ContractSettingsFormContainer from '../containers/ContractSettingsFormContainer'
import navigationHoc from '../components/hoc/navigationHoc'

const ContractSettingsFormPage = () => {
  return(
    <ContractSettingsFormContainer />
  )
}

export default navigationHoc(ContractSettingsFormPage)