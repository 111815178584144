import React from 'react'
import { SvgIcon } from "@mui/material"

const DownloadIcon = (props:any) => (
    <SvgIcon width='13' height='16' viewBox='0 0 13 16' fill='none' {...props}>
        <path d='M6.45856 11.7506C6.40234 11.7517 6.34648 11.7413 6.29446 11.7199C6.24245 11.6986 6.19538 11.6667 6.15617 11.6264L0.302731 5.77299C0.26302 5.73328 0.231544 5.68615 0.210053 5.63427C0.188562 5.58238 0.17749 5.52676 0.17749 5.4706C0.17749 5.41444 0.188562 5.35885 0.210053 5.30696C0.231544 5.25508 0.26302 5.20792 0.302731 5.16821C0.342441 5.1285 0.3896 5.09699 0.441484 5.0755C0.493368 5.05401 0.548961 5.04297 0.60512 5.04297C0.66128 5.04297 0.716906 5.05401 0.76879 5.0755C0.820674 5.09699 0.8678 5.1285 0.90751 5.16821L6.45856 10.7193L12.0096 5.16821C12.0493 5.1285 12.0964 5.09699 12.1483 5.0755C12.2002 5.05401 12.2558 5.04297 12.312 5.04297C12.3682 5.04297 12.4238 5.05401 12.4756 5.0755C12.5275 5.09699 12.5747 5.1285 12.6144 5.16821C12.6541 5.20792 12.6856 5.25508 12.7071 5.30696C12.7286 5.35885 12.7396 5.41444 12.7396 5.4706C12.7396 5.52676 12.7286 5.58238 12.7071 5.63427C12.6856 5.68615 12.6541 5.73328 12.6144 5.77299L6.76095 11.6264C6.72175 11.6667 6.67468 11.6986 6.62266 11.7199C6.57064 11.7413 6.51479 11.7517 6.45856 11.7506Z' fill='#60DFC8'/>
        <path d='M6.45856 11.7501C6.34542 11.7501 6.23693 11.7051 6.15693 11.6251C6.07693 11.5451 6.03198 11.4366 6.03198 11.3235V0.426609C6.03198 0.31347 6.07693 0.204944 6.15693 0.124944C6.23693 0.0449432 6.34542 0 6.45856 0C6.5717 0 6.68019 0.0449432 6.76019 0.124944C6.84019 0.204944 6.88513 0.31347 6.88513 0.426609V11.3505C6.87826 11.4589 6.8303 11.5606 6.75103 11.6348C6.67176 11.7091 6.56717 11.7503 6.45856 11.7501Z' fill='#60DFC8'/>
        <path d='M12.5226 15.2609H0.383763C0.278207 15.249 0.180708 15.1987 0.109915 15.1195C0.039122 15.0403 0 14.9378 0 14.8316C0 14.7254 0.039122 14.6229 0.109915 14.5437C0.180708 14.4645 0.278207 14.4142 0.383763 14.4023H12.5226C12.6281 14.4142 12.7256 14.4645 12.7964 14.5437C12.8672 14.6229 12.9064 14.7254 12.9064 14.8316C12.9064 14.9378 12.8672 15.0403 12.7964 15.1195C12.7256 15.1987 12.6281 15.249 12.5226 15.2609Z' fill='#60DFC8'/>
    </SvgIcon>
)

export default DownloadIcon