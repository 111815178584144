/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, SubjectFieldEnum, SubjectKindEnum, WithRequestParams } from '../../utils/constants'
import { CompanyJson } from '../companies'
import { AxiosResponse } from 'axios'

export interface SubjectAttrs {
  name: string
  subject_company_name: string
  field: SubjectFieldEnum
  kind: SubjectKindEnum
  company_id: number
}
export type SubjectJson = JsonFormat<'subjects', SubjectAttrs>

export type FetchSubjectsResponse = JsonResponseFormat<SubjectJson[], (CompanyJson)[]>
export type FetchSubjectResponse = JsonResponseFormat<SubjectJson, CompanyJson[]>
export type FetchSubjectsPayload = WithRequestParams
export interface FetchSubjectPayload extends WithRequestParams {
  id: string | number,
}

export type DeleteSubjectPayload = FetchSubjectPayload
export interface CreateSubjectPayload {
  data: object
}

export interface UpdateSubjectPayload extends CreateSubjectPayload {
  id: string
}

export const FETCH_SUBJECTS = createPromiseAction('subjects: FETCH_SUBJECTS')<FetchSubjectsPayload, FetchSubjectsResponse, any>()
export const FETCH_SUBJECT  = createPromiseAction('subjects: FETCH_SUBJECT')<FetchSubjectPayload, FetchSubjectResponse, any>()
export const CREATE_SUBJECT = createPromiseAction('subjects: CREATE_SUBJECT')<CreateSubjectPayload, FetchSubjectResponse, any>()
export const UPDATE_SUBJECT = createPromiseAction('subjects: UPDATE_SUBJECT')<UpdateSubjectPayload, FetchSubjectResponse, any>()
export const DELETE_SUBJECT = createPromiseAction('subjects: DELETE_SUBJECT')<DeleteSubjectPayload, AxiosResponse, any>()
