import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_ETHNICITIES,
  FETCH_ETHNICITY,
  FetchEthnicityPayload,
  FetchEthnicitiesPayload,
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchEthnicities (action: DefaultAction<FetchEthnicitiesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchEthnicities, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ETHNICITIES.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(FETCH_ETHNICITIES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchEhnicity (action: DefaultAction<FetchEthnicityPayload, string>): any {
  const { payload: {params, id}} = action
  try {
    const response = yield call(services.fetchEthnicity, { params, id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ETHNICITY.request(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ETHNICITY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchEthnicitiesSagas () {
  yield takeLatest(FETCH_ETHNICITIES.request, fetchEthnicities)
  yield takeLatest(FETCH_ETHNICITY.request, fetchEhnicity)
}

export default function * ethnicitySagas () {
  yield all([watchEthnicitiesSagas()])
}
