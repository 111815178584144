/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface RegistrationConnectionAttrs {
  registration_id: number
  connection_id: number
  kind: string
  connection_name: string
}

export type RegistrationConnectionJson = JsonFormat<'registration_connections', RegistrationConnectionAttrs>

export type FetchRegistrationConnectionsResponse = JsonResponseFormat<RegistrationConnectionJson[]>
export type FetchRegistrationConnectionsPayload = WithRequestParams

export interface DeleteRegistrationConnectionPayload {
  id: string | number,
}

export const FETCH_REGISTRATION_CONNECTIONS = createPromiseAction('registration_connections: FETCH_REGISTRATION_CONNECTIONS')<FetchRegistrationConnectionsPayload, FetchRegistrationConnectionsResponse, any>()
export const DELETE_REGISTRATION_CONNECTION = createPromiseAction('registration_connection: DELETE_REGISTRATION_CONNECTION')<DeleteRegistrationConnectionPayload, AxiosResponse, any>()
