import { createPromiseAction } from "redux-saga-promise-actions"
import { JsonFormat, JsonResponseFormat, WithRequestParams } from "../../utils/constants"
import { WalletCreditJson } from "../wallet_credits"


export interface WalletAttrs {
  account_id: number
  balance: number
  company: string
  document_number: string
  name: string
  created_at: string
}

export type WalletJson = JsonFormat<'wallets', WalletAttrs>
export type FetchWalletsResponse = JsonResponseFormat<WalletJson[], WalletCreditJson[]>
export type FetchWalletResponse = JsonResponseFormat<WalletJson, WalletCreditJson[]>
export type FetchWalletsPayload = WithRequestParams

export interface FetchWalletPayload extends WithRequestParams {
  id: string | number;
}

export const FETCH_WALLETS = createPromiseAction('wallets: FETCH_WALLETS')<FetchWalletsPayload, FetchWalletsResponse, any>()
export const FETCH_WALLET = createPromiseAction('wallets: FETCH_WALLET')<FetchWalletPayload, FetchWalletResponse, any>()

