import React from 'react'
import { error } from 'react-notification-system-redux'
import { useDispatch, useSelector } from 'react-redux'
import ProfileDashboardView from '../components/view/ProfileDashboardsView'
import { RootState } from '../store/configureStore'
import { FETCH_PROFILE_DASHBOARDS } from '../store/profile_dashboards'
import { UI_SET_LOADING_OPEN } from '../store/ui'
import { ProfileDashboardAttributes, defaultPagination, PaginationType } from '../utils/constants'

const ProfileDashboardContainer = () => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state)
  const {
    auth: { profile }  } = state

  const [pagination, setPagination] = React.useState<PaginationType>(defaultPagination)
  const [profileDashboards, setProfileDashboards] = React.useState<ProfileDashboardAttributes[]>([])
  const [nameFilterValue, setNameFilterValue] = React.useState('')
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value))
  }, [])

  const fetchProfileDashboardMethod = React.useCallback(async (
  {
    newPagination = pagination,
  } : {
    newPagination?: PaginationType,
  }
  ) => {
    setLoading(true)
    try {
      const profileDashboards = await dispatch(
        FETCH_PROFILE_DASHBOARDS.request({
          params: {
            filters: {
              'q[role_eq]': profile.role,
              'page[number]': (newPagination.pageNumber + 1).toString(),
              'page[size]': (newPagination.pageSize).toString(),
            }
          }
        })
      )
      const { data: { data, meta } } = profileDashboards
      const formattedProfileDashboard = data.map(item => {
        return ({
          id: item.id,
          ...item.attributes
        })
      })
      setPagination((current) => ({
        ...current,
        pageCount: meta.page_count,
        totalCount: meta.total_count
      }))
      setProfileDashboards(formattedProfileDashboard)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      dispatch(
        error(
          {
            message: 'Erro ao carregar cursos'
          }
        )
      )
    }
  }, [pagination])

  const handlePageChange = React.useCallback((_: any, newPage: number) => {
    setPagination({
      ...pagination,
      pageNumber: newPage
    })
    fetchProfileDashboardMethod({
      newPagination: {
        ...pagination,
        pageNumber: newPage
      }
    })
  },[pagination])

  const handleChangePageSize = React.useCallback((e: any) => {
    setPagination({
      ...pagination,
      pageSize: e.target.value
    })
    fetchProfileDashboardMethod({
      newPagination: {
        ...pagination,
        pageSize: e.target.value
      }
    })
  },[pagination])
  
  React.useEffect(() => {
    fetchProfileDashboardMethod({})
  }, [])


  return (
    <ProfileDashboardView
      profileDashboards={profileDashboards}
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleChangePageSize={handleChangePageSize}
      nameFilterValue={nameFilterValue}
      setNameFilterValue={setNameFilterValue}
      fetchProfileDashboardsMethod={fetchProfileDashboardMethod}
    />
  )
}

export default ProfileDashboardContainer