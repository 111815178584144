import React from 'react'

import { makeStyles } from '@mui/styles'
import AccountForm from '../form/AccountForm'
import { LoadingBackdrop } from '../modal/Modal'

const useStyles = makeStyles((): {
  view: any,
  title: any
} => ({
  view: {
    width: 'inherit',
    height: 'fit-content',
    flexGrow: '1',
    display: 'flex',
    padding: '2rem 10%',
    flexDirection: 'column',
    position: 'relative',
    '& .MuiBackdrop-root': {
      position: 'inherit'
    },
    '& .MuiPaper-root': {
      background: 'none',
      boxShadow: 'none'
    }
  },
  title: {
    alignSelf: 'flex-start',
    marginBottom: '1rem',
    padding: '0 1rem'
  }
}))

const CreateAccountView = (props: any) => {
  const { submitting, ...rest } = props
  const classes = useStyles()
  return (
    <div className={classes.view}>
      <LoadingBackdrop open={submitting} />
      <span className={`${classes.title} title`}>Salvar usuário</span>
      <AccountForm {...rest} />
    </div>
  )
}

export default CreateAccountView
