import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_CONTRACT_SETTINGS,
  FetchContractSettingsPayload,
  FetchContractSettingPayload,
  FETCH_CONTRACT_SETTING,
  CreateContractSettingPayload,
  CREATE_CONTRACT_SETTING,
  UpdateContractSettingPayload,
  UPDATE_CONTRACT_SETTING,
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchContractSettings (action: DefaultAction<FetchContractSettingsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchContractSettings, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CONTRACT_SETTINGS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CONTRACT_SETTINGS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchContractSetting (action: DefaultAction<FetchContractSettingPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchContractSetting, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CONTRACT_SETTING.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CONTRACT_SETTING.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createContractSetting (action: DefaultAction<CreateContractSettingPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = data instanceof FormData ? data : {
      data: {
        type: 'contract_settings',
        attributes: { ...data },
      }
    }
    const response = yield call(services.createContractSetting, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_CONTRACT_SETTING.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_CONTRACT_SETTING.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateContractSetting (action: DefaultAction<UpdateContractSettingPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = data instanceof FormData ? data : {
      data: {
        type: 'contract_settings',
        attributes: { ...data },
        id
      }
    }
    const response = yield call(services.updateContractSetting, {data: params, id})
    yield put(UPDATE_CONTRACT_SETTING.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_CONTRACT_SETTING.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchContractSettings () {
  yield takeLatest(FETCH_CONTRACT_SETTINGS.request, fetchContractSettings)
  yield takeLatest(FETCH_CONTRACT_SETTING.request, fetchContractSetting)
  yield takeLatest(CREATE_CONTRACT_SETTING.request, createContractSetting)
  yield takeLatest(UPDATE_CONTRACT_SETTING.request, updateContractSetting)
}

export default function * contractSettingSagas () {
  yield all([watchContractSettings()])
}
