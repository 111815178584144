import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_FORMULAS,
  FetchFormulasPayload,
  FetchFormulaPayload,
  FETCH_FORMULA,
  CREATE_FORMULA,
  CreateFormulaPayload,
  UpdateFormulaPayload,
  UPDATE_FORMULA,
  DeleteFormulaPayload,
  DELETE_FORMULA
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchFormulas (action: DefaultAction<FetchFormulasPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchFormulas, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_FORMULAS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_FORMULAS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchFormula (action: DefaultAction<FetchFormulaPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchFormula, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_FORMULA.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_FORMULA.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createFormula (action: DefaultAction<CreateFormulaPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'formulas',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createFormula, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_FORMULA.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_FORMULA.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateFormula (action: DefaultAction<UpdateFormulaPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'formulas',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateFormula, {data: params, id})
    yield put(UPDATE_FORMULA.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_FORMULA.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteFormula (action: DefaultAction<DeleteFormulaPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteFormula, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_FORMULA.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_FORMULA.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchFormulasSagas () {
  yield takeLatest(FETCH_FORMULAS.request, fetchFormulas)
  yield takeLatest(FETCH_FORMULA.request, fetchFormula)
  yield takeLatest(CREATE_FORMULA.request, createFormula)
  yield takeLatest(UPDATE_FORMULA.request, updateFormula)
  yield takeLatest(DELETE_FORMULA.request, deleteFormula)
}

export default function * formulasSagas () {
  yield all([watchFormulasSagas()])
}
