import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_RACES,
  FETCH_RACE,
  FetchRacePayload,
  FetchRacesPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRaces (action: DefaultAction<FetchRacesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchRaces, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_RACES.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    console.error(e)
    yield put(FETCH_RACES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchRace (action: DefaultAction<FetchRacePayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchRace, { params, id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_RACE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_RACE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRacesSagas () {
  yield takeLatest(FETCH_RACES.request, fetchRaces)
  yield takeLatest(FETCH_RACE.request, fetchRace)
}

export default function * racesSagas () {
  yield all([watchRacesSagas()])
}
