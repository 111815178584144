/* eslint-disable camelcase */
import { CityJson } from '../cities'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'
import { createPromiseAction } from 'redux-saga-promise-actions'


export interface StateAttrs {
  name: string,
  abbreviation: string,
  country_id: number,
  region_id: number
}

export type StateJson = JsonFormat<'states', StateAttrs>

export type FetchStateResponse = JsonResponseFormat<StateJson, CityJson[]>

export type FetchStatesResponse = JsonResponseFormat<StateJson[]>

export type FetchStatesPayload = WithRequestParams

export interface FetchStatePayload extends WithRequestParams {
    id: string | number,
  }

export const FETCH_STATES = createPromiseAction('states: FETCH_STATES')<FetchStatesPayload, FetchStatesResponse, any>()
export const FETCH_STATE = createPromiseAction('states: FETCH_STATE')<FetchStatePayload, FetchStateResponse, any>()
