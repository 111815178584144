import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const statesEndpoint = '/states'

export const fetchStates = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${statesEndpoint}?${queryParams}`)
}

export const fetchState = ({ params, id }: { params?: RequestParams, id: string|number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${statesEndpoint}/${id}?${queryParams}`)
}
