import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const studentAbsencesEndpoint = '/student_absences'

export const fetchStudentAbsences = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${studentAbsencesEndpoint}?${queryParams}`)
}

export const fetchStudentAbsence = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${studentAbsencesEndpoint}/${id}?${queryParams}`)
}

export const createStudentAbsence = (data: object) => {
  return http.post(`${studentAbsencesEndpoint}`, data)
}

export const updateStudentAbsence = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${studentAbsencesEndpoint}/${id}`, data)
}

export const deleteStudentAbsence = ({ id }: { id: string | number } ) => {
  return http.delete(`${studentAbsencesEndpoint}/${id}`)
}