/* eslint-disable camelcase */
import React from 'react';
import { compose } from 'redux';
import { Field, FieldArray, FieldArrayFieldsProps, FormSection, WrappedFieldArrayProps, change, reduxForm } from 'redux-form';
import {
  DefaultOptionType,
  NestedInvoiceItemAttributes,
  colors,
  invoicePaymentOptions,
  monetaryValueMaskProps,
  percentageMaskProps,
  validation,
  InvoiceFormAttributes,
  NestedWalletCreditAttributes,
  WalletAttributes,
  WalletCreditAttributes,
  RegistrationProductAttributes,
  InvoiceStatusEnum,
} from '../../utils/constants';
import DatePickerComponent from '../input/form/datepicker';
import SelectComponent from '../input/form/select';
import { css } from '@emotion/react';
import {
  convertToDate,
  evaluate_permissions,
  formatToCurrency,
  invoiceItemValues,
  parseMonetaryValue,
  parsePercentageDiscount,
  toFixedNumber,
  totalInvoiceValues,
} from '../../utils/functions';
import MaskedInputComponent from '../input/form/masked';
import { Accordion, AccordionDetails, AccordionSummary, Tab, Tabs, Tooltip } from '@mui/material';
import TabPanel from '../shared/TabPanel';
import TextAreaComponent from '../input/form/textarea';
import { AttachMoney, Discount, ExpandMore, History } from '@mui/icons-material';
import InputComponent from '../input/form/input';
import { find, get, isEmpty, toNumber } from 'lodash';
import Loading from '../loading/Loading';
import { InvoiceItemBaseValues } from '../../store/invoice_items';
import InvoiceSummary from '../shared/InvoiceSummary';
import InvoiceItemSummary from '../shared/InvoiceItemSummary';
import AddButton from '../shared/AddButton';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../shared/DeleteButton';
import { WalletCreditFormBody } from './WalletCreditForm';
import { RootState } from '../../store/configureStore';
import { CompanyConfigParams } from '../../store/companies';

interface CustomInvoiceItemFieldProps extends WrappedFieldArrayProps {
  all_invoices?: InvoiceFormAttributes[];
  all_invoice_items?: NestedInvoiceItemAttributes[];
  allow_remove_values: boolean;
  invoice_form_values: InvoiceFormAttributes;
  wallet_credits: NestedWalletCreditAttributes[]
  handleClearBaseValues: (key: keyof InvoiceItemBaseValues, invoice_item_ids: string[]) => void;
  is_renegotiation: boolean;
  [x: string]: any;
}

interface CustomWalletCreditFieldProps extends WrappedFieldArrayProps {
  invoice_form_values: InvoiceFormAttributes;
  wallets: WalletAttributes[]
  form: string
  is_renegotiation: boolean
  initialValues: InvoiceFormAttributes
  invoice_liquid_value: number
}



const tabStyle = css`& .Mui-selected': {
  background: '#FDFEFF',
  borderRadius: '1rem 1rem 0 0',
}`;

const accordionCss = css`
  background: inherit;
  border-bottom: 1px solid ${colors.grayBlue};
  box-shadow: none;
`;
const accordionSummaryCss = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const WalletCredit = (props: {
  fields: FieldArrayFieldsProps<any>;
  index: number
  wallet_credit: string
  form_name: string
  current_balance: number
  invoice_liquid_value: number;
  deleteWalletCreditMethod: (current_wallet_credit: NestedWalletCreditAttributes, wallet_credit_attribute: string, index: number) => void
}) => {
  const { fields, index, wallet_credit, form_name, deleteWalletCreditMethod, current_balance, invoice_liquid_value } = props
  const currentWalletCredit = fields.get(index) as NestedWalletCreditAttributes;
  const isDestroyed = currentWalletCredit._destroy;
  if (isDestroyed) {
    return null;
  }

  const deleteWalletCredit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    deleteWalletCreditMethod(currentWalletCredit, wallet_credit, index)
  }

  return (
    <div key={wallet_credit}>
    <FormSection name={wallet_credit}>
      <div style={{ display: 'grid', gridTemplateColumns: '80% 5%', justifyContent: 'space-between'}}>
        <WalletCreditFormBody 
          invoice_liquid_value={invoice_liquid_value}
          disabled={Boolean(currentWalletCredit.id)}
          form_name={form_name}
          current_balance={current_balance}
        />
        <DeleteButton tooltip='Apagar crédito' onClick={deleteWalletCredit}/>
      </div>
    </FormSection>
  </div>

  )
}

const appliedCreditsInThisInvoice = (wallet_credits: NestedWalletCreditAttributes[], initial_wallet_credits: WalletCreditAttributes[]) => {
  return wallet_credits.reduce((acc, wallet_credit) => {
    if(wallet_credit._destroy){
      return acc - (wallet_credit.credit_out||0)
    }
    const initial = initial_wallet_credits.find(initial => initial.id === wallet_credit.id)
    if(initial && initial.credit_out !== wallet_credit.credit_out){
      return acc - (initial.credit_out - (wallet_credit.credit_out||0))
    } else if(initial && initial.credit_out === wallet_credit.credit_out){
      return acc
    }
    return (wallet_credit.credit_out||0) + acc
  }, 0)
}

const renderWalletCredits = (props: CustomWalletCreditFieldProps) => {
  const {fields, invoice_form_values, wallets, is_renegotiation, invoice_liquid_value, ...rest} = props
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch()
  const {
    auth: { profile },
  } = state;
  const expiration_date = invoice_form_values.expiration_date && (typeof invoice_form_values.expiration_date === 'string' ? invoice_form_values.expiration_date : (invoice_form_values.expiration_date as Date).toUTCString())
  const canAddCredits = expiration_date && evaluate_permissions.can_add_credits_to_invoice(profile.role, expiration_date)
  if(is_renegotiation){
    return null
  }
  if(!invoice_form_values){
    return <Loading />
  }
  if (!invoice_form_values.representative_id) {
    return null
  }
  const representative_wallet = wallets.find(wallet => ~~wallet.id === invoice_form_values.representative_wallet_id)
  const addProfile = () => {
    fields.push({
      wallet_id: representative_wallet?.id
    });
  };
  const all_wallet_credits = fields.getAll()
  const current_balance = toFixedNumber(representative_wallet ? representative_wallet?.balance - appliedCreditsInThisInvoice(all_wallet_credits, rest.initialValues.wallet_credits_attributes as WalletCreditAttributes[]) : 0)
  const deleteWalletCreditMethod = React.useCallback((current_wallet_credit: NestedWalletCreditAttributes, wallet_credit_attribute: string, index: number ) => {
    current_wallet_credit?.id ? dispatch(
      change(
        rest.form,`${wallet_credit_attribute}._destroy`, true
      )
    ) : fields.remove(index)

  }, [fields])
  const disableAddCreditButton = !representative_wallet || representative_wallet.balance <= 0 || !canAddCredits
  return (
    <div>
      <span style={{ fontSize: '1.5rem', margin: '2vmax auto', display: 'block' }}>Créditos da fatura</span>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <span><b>Balanço: </b>{formatToCurrency(current_balance)}</span>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
          `}
        >
          <span>Adicionar Crédito</span>
          <AddButton
            disabled={disableAddCreditButton}
            tooltip={'Adicionar crédito'}
            onClick={addProfile}
          />
        </div>
        {fields.map((wallet_credit, index) => {
          return (
            <WalletCredit
              invoice_liquid_value={invoice_liquid_value}
              form_name={rest.form}
              key={wallet_credit}
              index={index}
              current_balance={current_balance}
              wallet_credit={wallet_credit}
              fields={fields}
              deleteWalletCreditMethod={deleteWalletCreditMethod}
            />
          )
        })}
      </div>
    </div>
  );
  
}

const renderInvoiceItems = (props: CustomInvoiceItemFieldProps) => {
  const {
    all_invoice_items,
    all_invoices,
    allow_remove_values,
    fields,
    handleClearBaseValues,
    invoice_form_values,
  } = props;

  // const punctuality_expiration_date = formValues(state, 'punctuality_expiration_date')
  if (isEmpty(invoice_form_values)) {
    return <Loading />;
  }
  return (
    <div>
      <span style={{ fontSize: '1.5rem', margin: '2vmax auto', display: 'block' }}>Itens da fatura</span>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        {fields.map((invoice_item, index) => {
          const currentInvoiceItem = fields.get(index) as NestedInvoiceItemAttributes;
          const previousInvoiceItem = find(
            all_invoice_items,
            (invoice_item) => invoice_item.id === currentInvoiceItem.parent_id?.toString(),
          ) as NestedInvoiceItemAttributes;
          const previousInvoice = find(
            all_invoices,
            (invoice) => invoice.id === get(previousInvoiceItem, 'invoice_id')?.toString(),
          ) as NestedInvoiceItemAttributes;
          const result = invoiceItemValues({
            invoice: invoice_form_values,
            invoice_item: currentInvoiceItem,
            invoice_items: invoice_form_values.invoice_items_attributes || [],
            wallet_credits: invoice_form_values.wallet_credits_attributes || []
          });
          const {
            contract_conditional_discount_billing_percentage,
            contract_full_billing_percentage,
            current_charge,
            current_conditional_charge,
            invoice_item_conditional_discount_percentage_over_invoice,
            invoice_item_percentage_over_invoice,
          } = result;
          const handleClearValue = (key: keyof InvoiceItemBaseValues) => {
            handleClearBaseValues(key, [currentInvoiceItem.id as string]);
          };
          const portionName = currentInvoiceItem.portion === 0 ? 'Entrada' : `Parcela ${currentInvoiceItem.portion}`;
          const isDestroyed = currentInvoiceItem._destroy;
          if (isDestroyed) {
            return null;
          }
          return (
            <Accordion css={accordionCss} key={invoice_item}>
              <AccordionSummary>
                <div css={accordionSummaryCss}>
                  <div
                    css={css`
                      align-items: center;
                      display: flex;
                      flex-wrap: wrap;
                      gap: 1vmin;
                    `}
                  >
                    <ExpandMore />
                  </div>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      gap: 1vmin;
                    `}
                  >
                    {!isEmpty(previousInvoice) && (
                      <Tooltip
                        title={`Esse item de fatura veio da fatura código ${previousInvoice.code}; Item de fatura: ${previousInvoiceItem.code}`}
                      >
                        <History />
                      </Tooltip>
                    )}
                    <Tooltip
                      title={`Esse item de fatura detém ${toFixedNumber(invoice_item_percentage_over_invoice)}% do valor da fatura`}
                    >
                      <AttachMoney style={{ color: colors.green }} />
                    </Tooltip>
                    <Tooltip
                      title={`Esse item de fatura detém ${toFixedNumber(invoice_item_conditional_discount_percentage_over_invoice)}% do valor de desconto condicional da fatura`}
                    >
                      <Discount style={{ color: colors.green }} />
                    </Tooltip>
                    <Tooltip
                      title={`Esse item de fatura detém ${toFixedNumber(contract_full_billing_percentage)}% do valor da parcela do contrato`}
                    >
                      <AttachMoney style={{ color: colors.blue }} />
                    </Tooltip>
                    <Tooltip
                      title={`Esse item de fatura detém ${toFixedNumber(contract_conditional_discount_billing_percentage)}% do valor de desconto condicional da fatura`}
                    >
                      <Discount style={{ color: colors.blue }} />
                    </Tooltip>
                    <span>{currentInvoiceItem?.code}</span>
                    <span>{portionName}</span>
                    <span>{currentInvoiceItem?.product_name}</span>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      gap: 1vmin;
                    `}
                  >
                    <span>Cobrança atual:{formatToCurrency(current_charge)}</span>
                    <span>Desconto condicional atual: {formatToCurrency(current_conditional_charge)}</span>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <InvoiceItemSummary
                  invoice={invoice_form_values}
                  invoice_item={currentInvoiceItem}
                  invoice_items={invoice_form_values.invoice_items_attributes}
                  allow_remove_values={allow_remove_values}
                  handleClearValue={handleClearValue}
                  wallet_credits={invoice_form_values.wallet_credits_attributes || []}
                />
                <FormSection name={invoice_item}>
                  <Field type='hidden' name='billing_id' component={'input'} />
                </FormSection>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export const InvoiceForm = (props: {
  all_invoice_items?: NestedInvoiceItemAttributes[];
  all_invoices?: InvoiceFormAttributes[];
  allow_remove_values?: boolean;
  disable_fields?: boolean;
  handleClearBaseValues?: (key: keyof InvoiceItemBaseValues, invoice_item_ids: string[]) => void;
  invoice_form_values: InvoiceFormAttributes;
  is_renegotiation: boolean;
  representativeOptions: DefaultOptionType[];
  wallets: WalletAttributes[]
  invoice_company_config?: CompanyConfigParams
}) => {
  const {
    all_invoice_items,
    all_invoices,
    invoice_form_values,
    is_renegotiation,
    allow_remove_values = false,
    handleClearBaseValues,
    representativeOptions,
    disable_fields = true,
    invoice_company_config,
    ...rest
  } = props;
  const canAjustDate = invoice_company_config && !is_renegotiation && evaluate_permissions.can_adjust_invoice_dates(
    toNumber(invoice_company_config?.days_to_block_invoice_adjustment_after_generation) || 0,
    invoice_form_values.created_at as string,
    invoice_form_values.status as InvoiceStatusEnum
  )



  const result = totalInvoiceValues(
    {
      invoice: invoice_form_values,
      invoice_items: invoice_form_values.invoice_items_attributes,
      wallet_credits:  invoice_form_values.wallet_credits_attributes || []
    }
  )
  const hidePunctualityDate = result.current_conditional_charge === 0
  const parsedExpirationDate = convertToDate(invoice_form_values.expiration_date);
  const parsedPunctualityExpirationDate = invoice_form_values.punctuality_expiration_date && convertToDate(invoice_form_values.punctuality_expiration_date);


  return (
    <div>
      <div>
        <div
          css={css`
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(4, 1fr);
            justify-content: space-between;
            width: 100%;
          `}
        >
          <Field
            clearable
            component={SelectComponent}
            label='Método de Pagamento'
            name='payment_method'
            options={invoicePaymentOptions}
            required
            disabled
          />
          <Field
            clearable
            component={SelectComponent}
            label='Responsável'
            name='representative_id'
            options={representativeOptions}
            disabled={disable_fields}
            required
          />
          <Field
            component={DatePickerComponent}
            id='expiration_date'
            label='Vencimento'
            name='expiration_date'
            placeholder={'31/11/2021'}
            required
            validate={[validation.checkExpirationDate, validation.dateRequired]}
            disabled={!canAjustDate}
            datePickerProps={{
              disabled: !canAjustDate,
              minDate: parsedPunctualityExpirationDate || new Date(),
            }}
        />
          <div style={{ visibility: hidePunctualityDate ? 'hidden' : 'initial' }}>
            <Field
              component={DatePickerComponent}
              id='punctuality_expiration_date'
              label='Vencimento desc. condicional'
              name='punctuality_expiration_date'
              placeholder={'11/11/2021'}
              validate={[validation.checkPunctualityExpirationDate]}
              disabled={!canAjustDate}
              datePickerProps={{
                disabled: !canAjustDate,
                minDate: new Date(),
                maxDate: parsedExpirationDate,
              }}
            />
          </div>
          <Field
            component={MaskedInputComponent}
            label={'Abono de Renegociação'}
            maskProps={monetaryValueMaskProps}
            name='renegotiation_allowance'
            parse={parseMonetaryValue}
            placeholder={'Abono de Renegociação'}
            disabled={disable_fields}
          />
          <Field
            component={MaskedInputComponent}
            label={'Ajuste Contratual '}
            maskProps={{
              ...monetaryValueMaskProps,
              allowNegative: true,
              isAllowed: (value: {
                formattedValue: string;
                value: string;
                floatValue: number;
            }) => {
                const { floatValue } = value;
                if (floatValue) {
                    return true;
                } else {
                    return false;
                }
            }
            }}
            name='contractual_addition'
            parse={parseMonetaryValue}
            placeholder={'Ajuste Contratual'}
            disabled={disable_fields}
          />
          <Field
            component={MaskedInputComponent}
            label={'Acréscimo de Postergação'}
            maskProps={monetaryValueMaskProps}
            name='installment_addition'
            parse={parseMonetaryValue}
            disabled={disable_fields}
            placeholder={'Acréscimo de Postergação'}
          />
          <Field
            component={MaskedInputComponent}
            label={'Desconto de antecipação'}
            maskProps={monetaryValueMaskProps}
            name='anticipation_discount'
            parse={parseMonetaryValue}
            disabled={disable_fields}
            placeholder={'Desconto de antecipação'}
          />
          <Field
            component={MaskedInputComponent}
            label='Juros'
            maskProps={percentageMaskProps}
            name='fees'
            parse={parsePercentageDiscount}
            disabled={disable_fields}
            placeholder='Juros'
          />
          <Field
            component={MaskedInputComponent}
            label='Multa'
            maskProps={percentageMaskProps}
            name='penalty'
            disabled={disable_fields}
            parse={parsePercentageDiscount}
            placeholder='Multa'
          />
          <Field
            component={InputComponent}
            label={'Número da parcela'}
            name='installment_number'
            disabled
            placeholder={'Número referente a parcela'}
          />
        </div>
        <Field name='description' label='Comentário' component={TextAreaComponent} required />
      </div>
      <Accordion css={accordionCss}>
        <AccordionSummary css={accordionSummaryCss}>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <ExpandMore />
            <span>Resumo da fatura</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <InvoiceSummary
            invoice={invoice_form_values}
            allow_remove_values={allow_remove_values}
            handleClearBaseValues={handleClearBaseValues}
            invoice_items={invoice_form_values.invoice_items_attributes}
            wallet_credits={invoice_form_values.wallet_credits_attributes || []}
            {...rest}
          />
        </AccordionDetails>
      </Accordion>
      <FieldArray
        component={renderWalletCredits}
        name={'wallet_credits_attributes'}
        invoice_form_values={invoice_form_values}
        is_renegotiation={is_renegotiation}
        invoice_liquid_value={result.invoice_liquid_value}
        {...rest}
      />
      <FieldArray
        all_invoice_items={all_invoice_items}
        all_invoices={all_invoices}
        component={renderInvoiceItems}
        handleClearBaseValues={handleClearBaseValues}
        allow_remove_values={allow_remove_values}
        invoice_form_values={invoice_form_values}
        is_renegotiation={is_renegotiation}
        name={'invoice_items_attributes'}
        {...rest}
      />
    </div>
  );
};

const InvoiceFormBody = ({
  handleSubmit,
  handleTabChange: handleExternalTabChange,
  isFormValid,
  onSubmit,
  representativeOptions,
  is_renegotiation = false,
  invoice_items_attributes,
  invoice_form_values,
  registration_products,
  ...rest
}: {
  handleSubmit: (arg: () => void) => any;
  handleTabChange: (_: any, newValue: string) => void;
  isFormValid: boolean;
  onSubmit: () => void;
  registration_products: RegistrationProductAttributes[]
  representativeOptions: DefaultOptionType[];
  is_renegotiation?: boolean;
  invoice_items_attributes: NestedInvoiceItemAttributes[];
  invoice_form_values: InvoiceFormAttributes;
  wallets: WalletAttributes[]
  invoice_company_config: CompanyConfigParams
}) => {
  const cancelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleExternalTabChange(null, 'all_invoices');
  };

  const [tab, setTab] = React.useState('invoice');
  const handleTabChange = (_: any, newValue: string) => {
    setTab(newValue);
  };
  const submitAction = is_renegotiation ? null : handleSubmit(onSubmit);

  return (
    <form>
      <Tabs css={tabStyle} value={tab} onChange={handleTabChange}>
        <Tab
          css={css`
            display: ${is_renegotiation ? `none` : `initial`};
          `}
          label='Fatura'
          value={'invoice'}
        />
      </Tabs>
      <TabPanel style={{ backgroundColor: 'white' }} value={tab} index={'invoice'}>
        <InvoiceForm
          invoice_form_values={invoice_form_values}
          is_renegotiation={is_renegotiation}
          representativeOptions={representativeOptions}
          {...rest}
        />
      </TabPanel>
      <div
        css={css`
          display: ${is_renegotiation ? `none` : `flex`};
          justify-content: end;
          gap: 1vmax;
        `}
      >
        <button onClick={cancelClick} className='small red'>
          <span>Cancelar</span>
        </button>
        <button type='submit' disabled={!isFormValid} onClick={submitAction} className='small blue'>
          <span>Salvar</span>
        </button>
      </div>
    </form>
  );
};

export default compose<any>(
  reduxForm({
    form: 'invoiceForm',
  }),
)(InvoiceFormBody);
