import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { RootState } from "../../../store/configureStore";
import AutoCompleteComponent from "./autocomplete";
import { convertToCPF, removeSpecialSymbols } from "../../../utils/functions";
import { UserOptionsData, validation } from "../../../utils/constants";
import { AddressJson, FETCH_USERS } from "../../../store/users";
import { filter, find, map, toString } from "lodash";
import { AccountJson } from "../../../store/accounts";
import { UserAlergyJson } from "../../../store/alergies";
import { UserDeficiencyJson } from "../../../store/deficiencies";
import { error } from "react-notification-system-redux";
import { UserRaceJson } from "../../../store/races";

type DocumentNumberProps = {
  formName: string
  disabled: boolean
  handleSelectUser?: (user: UserOptionsData) => Promise<void>
  handleClearField?: () => void
  company_id: string
  clearable: boolean
  handleDocumentChange?: (value: string) => Promise<void>
  label?: string
  placeholder?: string
  inputFormatFunction?: (value: string) => string
}

const DocumentNumberField = (props: DocumentNumberProps) => {
  const { formName, disabled, handleSelectUser, handleClearField, company_id, clearable, handleDocumentChange, label = 'CPF *', placeholder = 'CPF', inputFormatFunction = convertToCPF } = props
  const state = useSelector((state: RootState) => state);
  const [loading, setLoading] = React.useState(false)
  const [userOptions, setUserOptions] = React.useState<UserOptionsData[]>([]);
  const dispatch = useDispatch()
  const formValues = formValueSelector(formName);
  const document_number_value = formValues(state, 'document_number')
  const company_id_to_use = company_id

  const getUserFromOptions = (document_number: string, options: UserOptionsData[]) => {
    return document_number && options.find((item) => item.document_number === removeSpecialSymbols(document_number || '')); 
  }

  const fetchUsersOptionsMethod = React.useCallback(
    async (document: string) => {
      if(!document){
        return
      }
      setLoading(true);
      try {
        const users = await dispatch(
          FETCH_USERS.request({
            params: {
              filters: {
                'q[document_number_cont]': removeSpecialSymbols(document),
                include: 'ethnicities,user_races,address,user_deficiencies,user_alergies,accounts',
              },
            },
          }),
        );
        const {
          data: { data, included },
        } = users;

        const formattedData = map(data, ({ attributes, id }) => {
          const getAddress = find(
            included,
            (item) => item.type === 'addresses' && item.attributes.user_id === ~~id,
          ) as AddressJson;
          let address_attributes;
          if (getAddress) {
            const {
              attributes: { city_id, state_id, country_id, ...rest },
            } = getAddress;
            address_attributes = {
              id: getAddress.id,
              city_id: toString(city_id),
              state_id: toString(state_id),
              country_id: toString(country_id),
              ...rest,
            };
          }
          const accountsFromThisCompany = find(
            included,
            (item) =>
              item.type === 'accounts' &&
              item.attributes.user_id === ~~id &&
              item.attributes.company_id === ~~company_id_to_use,
          ) as AccountJson;
          const accountAttrs = {
            account: {
              ...accountsFromThisCompany?.attributes,
              id: accountsFromThisCompany?.id,
            },
          };
          const filteredAlergies = filter(
            included,
            (item) => item.type === 'user_alergies' && item.attributes.user_id === ~~id,
          ) as UserAlergyJson[];
          const user_alergies_attributes = map(filteredAlergies, (item) => {
            return {
              id: item.id,
              ...item.attributes,
            };
          });
          const filteredDeficiencies = filter(
            included,
            (item) => item.type === 'user_deficiencies' && item.attributes.user_id === ~~id,
          ) as UserDeficiencyJson[];
          const includedDeficiencies = map(filteredDeficiencies, (item) => {
            return {
              id: item.id,
              ...item.attributes,
            };
          });
          const user_races_attributes = map(filter(
            included,
            (item) => item.type === 'user_races' && item.attributes.user_id === ~~id,
          ) as UserRaceJson[], item => ({id: item.id, ...item.attributes}))

          const deficienciesAttrs =
            includedDeficiencies && includedDeficiencies?.length
              ? {
                  user_deficiencies_attributes: [...includedDeficiencies],
                }
              : { user_deficiencies_attributes: [] };
          return {
            ...attributes,
            id,
            user_alergies_attributes,
            user_races_attributes,
            address_attributes,
            ...deficienciesAttrs,
            ...accountAttrs,
          };
        });

        setUserOptions(formattedData);
        const correspondent_user = getUserFromOptions(document, formattedData)
          
        if (correspondent_user) {
          await handleSelectUser?.(correspondent_user);
        }
        setLoading(false)
      } catch (err) {
        setLoading(false)
        dispatch(
          error({
            message: 'Erro ao carregar opções de usuario',
          }),
        );
      }
    },
    [setUserOptions, company_id_to_use],
  );


  const handleOnBlur = async () => {
    if(document_number_value){
      const user_option = getUserFromOptions(document_number_value, userOptions)
      if(user_option){
        await handleSelectUser?.(user_option)
      }
    }
  }

  const handleOnChange = (_: React.ChangeEvent<any> | undefined, value: string) => {
    handleDocumentChange?.(value)
  }

  const onClearField = () => {
    handleClearField?.()
    setUserOptions([])
  }
  return (
    <Field
      name='document_number'
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      component={AutoCompleteComponent}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      autoCompleteProps={{
        loading: loading,
        freeSolo: true,
        clearable,
        onClearField: onClearField,
        disabled,
        onlyNumbers: true,
        inputFormatFunction,
        fetchOptions: fetchUsersOptionsMethod,
        getValueOnChange: true,
        showOptionValue: true,
        handleSelect: async (value: string) => {
          const user = getUserFromOptions(value, userOptions)
          if(user){
            await handleSelectUser?.(user);
          }
        },
      }}
      options={userOptions.map((option) => {
        return {
          value: option.document_number,
          label: `${option.name} - ${option.document_number}`,
        };
      })}
      validate={[validation.required, validation.validCpf]}
    />
  )
}

export default DocumentNumberField