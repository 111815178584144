import { parse } from "date-fns"
import { ptBR } from "date-fns/locale"
import { DiscountTypeEnum, NestedRegistrationProductSubsidyAttributes, PaymentOptionAttributes, PaymentOptionPortionsEnum, ProductAttributes, RegistrationProductSubsidyAttributes, SubsidiesAttributes, semiAnualPortionsConfig } from "./constants"
import { calculateDiscount, toFixedNumber } from "./functions"


export const dateRangeWithRegistrationFee = (dateRange: string[], registration_fee: number) => {
  const dateRangeOptions = dateRange.map(item => {
    const splitted = item.split('/')[0]
    return ({
      value: parse(splitted, 'LLL', new Date(), { locale: ptBR }).getMonth() + 1,
      label: item
    })
  })

  if(registration_fee && registration_fee > 0){
    dateRangeOptions.unshift({ value: 0, label: 'Entrada' })
  }
  return dateRangeOptions
}

export const getValuesForMonth = (props: {
  product: ProductAttributes;
  subsidies: SubsidiesAttributes[];
  registration_product_subsidies: (RegistrationProductSubsidyAttributes | NestedRegistrationProductSubsidyAttributes)[];
  month: number;
  current_payment_option: Partial<PaymentOptionAttributes>
  punctuality_discount_portions: number[]
}) => {
  const { registration_product_subsidies, month, subsidies, product, current_payment_option, punctuality_discount_portions } = props;

  const value = month === 0 ? product.registration_fee : product.price;
  const subsidy = registration_product_subsidies.filter(rps => rps.subsidy_id).reduce((acc, rps) => {
    if (!rps.portions.includes(month)) {
      return acc;
    }
    const subsidy = subsidies.find((subsidy) => ~~subsidy.id === ~~rps.subsidy_id) as SubsidiesAttributes;
    const calculated_discount = calculateDiscount(subsidy.kind, rps.discount, value);
    return acc + toFixedNumber(calculated_discount);
  }, 0);
  const final = value - subsidy
  const apply_financial_discount = (month === 0 || current_payment_option.portions === PaymentOptionPortionsEnum.MONTHLY) ?
    punctuality_discount_portions.includes(month) :
      current_payment_option.portions === PaymentOptionPortionsEnum.SEMI_ANUALY ?
      punctuality_discount_portions.includes(semiAnualPortionsConfig.find(item => item.months.includes(month))?.portion as number) :
      punctuality_discount_portions.includes(1)
  const discount_to_be_applied = {
    kind: month === 0 ? current_payment_option.advance_discount_kind : [PaymentOptionPortionsEnum.SEMI_ANUALY, PaymentOptionPortionsEnum.YEARLY].includes(current_payment_option.portions as PaymentOptionPortionsEnum) ? current_payment_option.portion_discount_kind : current_payment_option.punctuality_discount_kind,
    value: month === 0 ? current_payment_option.advance_discount_value : [PaymentOptionPortionsEnum.SEMI_ANUALY, PaymentOptionPortionsEnum.YEARLY].includes(current_payment_option.portions as PaymentOptionPortionsEnum) ? current_payment_option.portion_discount_value : current_payment_option.punctuality_discount_value
  }
  const financial = !apply_financial_discount ? 0 : toFixedNumber(calculateDiscount(discount_to_be_applied.kind as DiscountTypeEnum, discount_to_be_applied.value as number, final))
  const final_financial = final - financial
  return { initial: value, subsidy, final, financial, final_financial }
};