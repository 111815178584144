import { createAction, createReducer } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'
import { SET_COMPANY_FAILURE, SET_COMPANY_REQUEST, SET_COMPANY_SUCCESS } from '../auth'

export interface UIStateType {
  loading: {
    open: boolean
  }
  changing_company: boolean
}

const initialState : UIStateType = {
  loading: {
    open: false
  },
  changing_company: false
}
export const UI_SET_LOADING_OPEN = createAction('UI: SET_LOADING_OPEN', withPayloadType<boolean>())

export const UIReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      UI_SET_LOADING_OPEN,
      (state: UIStateType, action: { payload: boolean }): UIStateType => ({
        ...state,
        loading: {
          open: action.payload
        }
      })
    ).addCase(
      SET_COMPANY_REQUEST,
      (state: UIStateType): UIStateType => ({
        ...state,
        changing_company: true
      })
    ).addCase(
      SET_COMPANY_SUCCESS,
      (state: UIStateType): UIStateType => ({
        ...state,
        changing_company: false
      })
    ).addCase(
      SET_COMPANY_FAILURE,
      (state: UIStateType): UIStateType => ({
        ...state,
        changing_company: false
      })
    )
})
