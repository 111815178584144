/* eslint-disable camelcase */
import { filter, find, isNil, keys, map } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import RoomSchedulesForm from '../components/form/RoomScheduleForm';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { RoomScheduleAttributes, RoomAttributes, NestedClassTimeSubjectAttributes, NestedTeacherClassTimeAttributes, NestedClassTimeAttributes, RoomScheduleFormAttributes, NestedRoomClassTimeAttributes } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { CREATE_ROOM_SCHEDULE, FETCH_ROOM_SCHEDULE, UPDATE_ROOM_SCHEDULE } from '../store/room_schedules';
import { ClassTimeJson } from '../store/class_times';
import { ClassTimeSubjectJson } from '../store/class_time_subjects';
import { TeacherClassTimeJson } from '../store/teacher_class_times';

const teacherClassTimeUpdater = (
  currentTeacherClassTimes: NestedTeacherClassTimeAttributes[],
  initialTeacherClassTimes: NestedTeacherClassTimeAttributes[],
) => {
  const sorted: NestedTeacherClassTimeAttributes[] = [];
  currentTeacherClassTimes.forEach((teacher_class_time) => {
    const related = find(initialTeacherClassTimes, (initial) => initial.id === teacher_class_time.id);
    if (isNil(related)) {
      sorted.push(teacher_class_time);
      return;
    }
    const omit = ['id', '_destroy', 'class_time_subject_id'];
    const result = verifyDifferentValues(teacher_class_time, related, omit) as NestedTeacherClassTimeAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedTeacherClassTimeAttributes[];
};

const roomClassTimeUpdater = (
  currentTeacherClassTimes: NestedRoomClassTimeAttributes[],
  initialTeacherClassTimes: NestedRoomClassTimeAttributes[],
) => {
  const sorted: NestedRoomClassTimeAttributes[] = [];
  currentTeacherClassTimes.forEach((teacher_class_time) => {
    const related = find(initialTeacherClassTimes, (initial) => initial.id === teacher_class_time.id);
    if (isNil(related)) {
      sorted.push(teacher_class_time);
      return;
    }
    const omit = ['id', '_destroy', 'class_time_id', 'room_id'];
    const result = verifyDifferentValues(teacher_class_time, related, omit) as NestedRoomClassTimeAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedRoomClassTimeAttributes[];
};


const classTimeSubjectUpdater = (
  currentClassTimeSubject: NestedClassTimeSubjectAttributes[],
  initialClassTimeSubject: NestedClassTimeSubjectAttributes[],
) => {
  const sorted: NestedClassTimeSubjectAttributes[] = [];
  currentClassTimeSubject.forEach((class_time_subject) => {
    const related = find(initialClassTimeSubject, (initial) => initial.id === class_time_subject.id);
    if (isNil(related)) {
      sorted.push(class_time_subject);
      return;
    }
    const result = verifyDifferentValues(class_time_subject, related, [
      'id',
      '_destroy',
      'teacher_class_times_attributes',
      'class_time_id'
    ]) as NestedClassTimeSubjectAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentTeacherClassTime = class_time_subject.teacher_class_times_attributes;
      const initialTeacherClassTime = related?.teacher_class_times_attributes;
      const teacher_class_times_attributes = teacherClassTimeUpdater(
        currentTeacherClassTime as NestedTeacherClassTimeAttributes[],
        initialTeacherClassTime as NestedTeacherClassTimeAttributes[],
      );
      const formattedPaymentOption = {
        ...result,
        teacher_class_times_attributes,
      };
      sorted.push(formattedPaymentOption);
    }
  });
  return sorted as NestedClassTimeSubjectAttributes[];
};

const classTimesUpdater = (
  currentClassTimeSubject: NestedClassTimeAttributes[],
  initialClassTimeSubject: NestedClassTimeAttributes[],
) => {
  const sorted: NestedClassTimeAttributes[] = [];
  currentClassTimeSubject.forEach((class_time) => {
    const related = find(initialClassTimeSubject, (initial) => initial.id === class_time.id);
    if (isNil(related)) {
      sorted.push(class_time);
      return;
    }
    const result = verifyDifferentValues(class_time, related, [
      'id',
      '_destroy',
      'class_time_subjects_attributes',
      'company_id',
      'room_schedule_id'
    ]) as NestedClassTimeAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentClassTimeSubject = class_time.class_time_subjects_attributes;
      const initialClassTimeSubject = related?.class_time_subjects_attributes;
      const class_time_subjects_attributes = classTimeSubjectUpdater(
        currentClassTimeSubject as NestedClassTimeSubjectAttributes[],
        initialClassTimeSubject as NestedClassTimeSubjectAttributes[],
      );
      const currentRoomClassTime = class_time.room_class_times_attributes
      const initialRoomClassTime = related?.room_class_times_attributes
      const room_class_times_attributes = roomClassTimeUpdater(
        currentRoomClassTime,
        initialRoomClassTime
      )
      const formattedPaymentOption = {
        ...result,
        class_time_subjects_attributes,
        room_class_times_attributes
      };
      sorted.push(formattedPaymentOption);
    }
  });
  return sorted as NestedClassTimeAttributes[];
};


const RoomSchedulesFormContainer = (props: {
  room_schedule_id?: number
  room: RoomAttributes,
  onSave: () => void
  close_form: () => void
}) => {
  const { onSave, room_schedule_id, close_form, room } = props
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = React.useState<Partial<RoomScheduleFormAttributes> | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const isUpdating = !isNil(initialValues?.id);

 
  const loadRoomSchedules = React.useCallback(async () => {
    if (room_schedule_id) {
      const subject = await dispatch(
        FETCH_ROOM_SCHEDULE.request({
          id: room_schedule_id,
          params: {
            filters: {
              'include': ['class_times','class_time_subjects', 'teacher_class_times'].join(',')
            }
          }
        }),
      );
      const {
        data: {
          data: { id, attributes }, included
        },
      } = subject;
      const class_times_attributes = map(filter(included, item => item.type === 'class_times') as ClassTimeJson[], item => {
        const class_time_subjects_attributes = map(filter(included, cts => cts.type === 'class_time_subjects' && cts.attributes.class_time_id === ~~item.id) as ClassTimeSubjectJson[], cts => {
          const teacher_class_times_attributes = map(filter(included, incl => incl.type === 'teacher_class_times' && incl.attributes.class_time_subject_id === ~~cts.id) as TeacherClassTimeJson[], tct => ({
            id: tct.id,
            ...tct.attributes
          }))
          return ({
            id: cts.id,
            ...cts.attributes,
            teacher_class_times_attributes
          })
        })
  
        return ({
          id: item.id,
          ...item.attributes,
          class_time_subjects_attributes,
          room_class_times_attributes: []
        })
      })

      const formattedSubject = {
        id,
        ...attributes,
        class_times_attributes
      };
      setInitialValues(formattedSubject);
    } else {
      setInitialValues({ room_id: ~~room.id, class_times_attributes: [{ company_id: room.company_id, room_class_times_attributes: [], class_time_subjects_attributes: [], active: false }] })
    }
  }, [room_schedule_id]);

  const initRoomSchedulesForm = React.useCallback(async () => {
    setLoading(true);
    await loadRoomSchedules();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: RoomScheduleFormAttributes) => {
      const { class_times_attributes, ...dataRest } = data
      try {
        if (isUpdating) {
          const { class_times_attributes: initial_class_times_attributes, ...initialRest } = initialValues as RoomScheduleFormAttributes
          const { id, ...rest } = verifyDifferentValues(dataRest, initialRest, [
            'id',
            'room_id',
          ]) as RoomScheduleAttributes;
          const updated_class_times_attributes =  classTimesUpdater(class_times_attributes, initial_class_times_attributes)

          await dispatch(
            UPDATE_ROOM_SCHEDULE.request({
              id: initialValues?.id as string,
              data: {
                ...rest,
                class_times_attributes: updated_class_times_attributes
              },
            }),
          );
        } else {
          await dispatch(
            CREATE_ROOM_SCHEDULE.request({
              data: {
                ...data,
                room_id: ~~room.id,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Agendamento de sala salvo com sucesso.',
          }),
        );
        onSave()
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar agendamento de sala.',
          }),
        );
      }
    },
    [initialValues, room, isUpdating],
  );

  React.useEffect(() => {
    initRoomSchedulesForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <RoomSchedulesForm close_form={close_form} initialValues={initialValues} room={room} onSubmit={onSubmit} />;
};

export default RoomSchedulesFormContainer;
