import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const classroomsEndpoint = '/classrooms'

export const fetchClassrooms = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${classroomsEndpoint}?${queryParams}`)
}

export const fetchClassroom = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${classroomsEndpoint}/${id}?${queryParams}`)
}

export const createClassroom = (data: object) => {
  return http.post(`${classroomsEndpoint}`, data)
}

export const updateClassroom = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${classroomsEndpoint}/${id}`, data)
}
