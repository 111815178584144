/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface AlergyAttrs {
  name: string
  description: string
}

export type AlergyJson = JsonFormat<'alergies', AlergyAttrs>
export interface UserAlergyAttrs {
  description: string
  user_id: number
  alergy_id: number
  alergy_name: string
}

export type UserAlergyJson = JsonFormat<'user_alergies', UserAlergyAttrs>
export type FetchAlergiesResponse = JsonResponseFormat<AlergyJson[]>
export type FetchAlergiesPayload = WithRequestParams

export interface DeleteUserAlergyPayload {
  id: string | number,
}

export const FETCH_ALERGIES = createPromiseAction('alergies: FETCH_ALERGIES')<FetchAlergiesPayload, FetchAlergiesResponse, any>()
export const DELETE_USER_ALERGY = createPromiseAction('alergies: DELETE_USER_ALERGY')<DeleteUserAlergyPayload, AxiosResponse, any>()
