/* eslint-disable camelcase */
import React from 'react'
import { useDispatch } from 'react-redux'
import { error, success } from 'react-notification-system-redux'
import {filter, map} from 'lodash'

import { makeStyles } from '@mui/styles'
import { CurrentRegistrationData, RegistrationClosureAttributes, RegistrationFormData, RegistrationProductAttributes } from '../../../../utils/constants'
import InsertProductDiscountForm from '../../../../containers/InsertProductDiscountFormContainer'
import InsertProductStepForm from '../../../../containers/InsertProductStepFormContainer'
import TabPanel from '../../../shared/TabPanel'
import ProductStepTable from '../../../table/ProductStepTable'
import { DELETE_REGISTRATION_PRODUCT, RegistrationProductJson } from '../../../../store/registration_products'
import { Tab, Tabs } from '@mui/material'
import { DELETE_REGISTRATION_CLOSURE, RegistrationClosureJson } from '../../../../store/registration_closures'
import { FETCH_REGISTRATION, FetchRegistrationResponse } from '../../../../store/registrations'

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    '& .white-bg': {
      background: 'white'
    },
    '& .default-padding': {
      padding: '1rem'
    }
  },
  buttons: {
    marginTop: '4.5rem',
    display: 'flex',
    justifyContent: 'end',
    gap: '1rem'
  },
  aboveContent: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'end',
    marginBottom: '3.5rem'
  },
  tabStyle: {
    '& .Mui-selected': {
        background: '#FDFEFF',
        borderRadius: '1rem 1rem 0 0',
    },
  },
}))

const Buttons = ({
  setStep
} : {
  setStep: React.Dispatch<React.SetStateAction<number>>
}) => {
  const classes = useStyles()
  return (
    <div className={classes.buttons}>
    <button
      className='red small'
      onClick={() => {
        setStep((step) => step - 1)
      }}
    >
      <span>Voltar</span>
    </button>
    <button
      onClick={() => {
        setStep((step) => step + 1)
      }}
      className='green small'
    >
      <span>Avançar</span>
    </button>
  </div>
  )
}

const TabsWrapper = ({
  tabValue, handleTabChange
} : {
  tabValue: number
  handleTabChange: (_: any, value: number) => void
}) => {
  if(![0,3].includes(tabValue)){
    return null
  }
  const classes = useStyles()
  return(
    <Tabs className={classes.tabStyle} value={tabValue} onChange={handleTabChange}>
      <Tab label='Produtos ativos' value={0} />
      <Tab label='Produtos Inativos' value={3} />
    </Tabs>
  )
}



const ProductStep = ({ currentRegistration, setStep, setLoading, fetchAndUpdateRegistrationMethod, updateCurrentRegistration } : {
  currentRegistration: CurrentRegistrationData
  setStep: React.Dispatch<React.SetStateAction<number>>
  setLoading: (value: boolean) => void
  fetchAndUpdateRegistrationMethod: (id?: string, data?: RegistrationFormData | FormData) => Promise<void>
  updateCurrentRegistration: (res: FetchRegistrationResponse) => Promise<void>
}) => {
  
  const classes = useStyles()
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = React.useState(0)
  const [registrationProductsData, setRegistrationProductData] = React.useState<RegistrationProductAttributes[]>([])
  const [closuresData, setClosuresData] = React.useState<RegistrationClosureAttributes[]>([])
  const [initialRegistrationProduct, setinitialRegistrationProduct] = React.useState<number | null>(null)
  const [renderReady, setRenderReady] = React.useState(false)
  

  const handleTabChange = React.useCallback((_: any, value: number) => {
    if(value === 0){
      setinitialRegistrationProduct(null)
    }
    setTabValue(value)
    
  }, [])

  const [expandedRows, setExpandedRows] = React.useState<number[]>([])
  const [expandedClosureRow, setExpandedClosureRows] = React.useState<number[]>([])
  const [expandedProductContractsRow, setExpandedProductContractsRow] = React.useState<number[]>([])

  const fetchRegistrationProducts = React.useCallback(async () => {
    const registration = await dispatch(
      FETCH_REGISTRATION.request({
        id: currentRegistration.id,
        params: {
          filters: {
            include: [
              'registration_products.registration_closure',
            ].join(','),
          },
        },
      })
    );
    const { data: { included } } = registration
    const includedRegistrationProducts = map(filter(included, (item) => item.type === 'registration_products') as RegistrationProductJson[], res => ({id: res.id, ...res.attributes}))
    const includedClosures = map(filter(included, (item) => item.type === 'registration_closures') as RegistrationClosureJson[], res => ({id: res.id, ...res.attributes}))
    await updateCurrentRegistration(registration)
    setRegistrationProductData(includedRegistrationProducts)
    setClosuresData(includedClosures)
  }, [currentRegistration])

    const deleteRegistrationClosureMethod = React.useCallback(async (id: string) => {
      try {
        await dispatch(
          DELETE_REGISTRATION_CLOSURE.request({id})
        )
        dispatch(
          success(
            {
              message: 'Cancelamento do produto excluído com sucesso'
            }
          )
        )
        await fetchRegistrationProducts()
      } catch (err) {
        dispatch(error({
          message: 'Erro ao excluir cancelamento do produto'
        }))
      }
    },[])


   

  const initStepData = React.useCallback(async() => {
    setRenderReady(false)
    await fetchRegistrationProducts()
    setRenderReady(true)
  }, [currentRegistration])


  const deleteRegistrationProduct = React.useCallback(async ({ setLoading, handleClose }, registration_product_id: string) => {
    try {
      setLoading(true)

      await dispatch(
        DELETE_REGISTRATION_PRODUCT.request({
          id: registration_product_id,
        })
      )
      setLoading(false)
      handleClose()
      await fetchRegistrationProducts()
    } catch (err) {
      setLoading(false)
      handleClose()
      dispatch(error({
        message: 'Erro ao deletar produto da matrícula'
      }))
    }
  }, [currentRegistration])

  const updateRegistrationMethod = React.useCallback(async () => {
    try {
      setRenderReady(false)
      await fetchAndUpdateRegistrationMethod(currentRegistration.id, {active: currentRegistration.active})
      await initStepData()  
      setRenderReady(true)
    } catch (err) {
      dispatch(error({
        message: 'Erro ao recalcular simulação'
      }))
    }
  }, [currentRegistration])

  const selectProduct = React.useCallback((id: number) => {
    setinitialRegistrationProduct(id)
  }, [])


  React.useEffect(() => {
    if(initialRegistrationProduct) {
      handleTabChange(null, 2)
    }
  }, [initialRegistrationProduct])

  React.useEffect(() => {
    initStepData()
  }, [])
  
  return (
    <div className={classes.wrapper}>
      <TabsWrapper tabValue={tabValue} handleTabChange={handleTabChange}/>
      <TabPanel value={tabValue} index={0} className='white-bg default-padding'>
        <div className={classes.aboveContent}>
          <button onClick={updateRegistrationMethod} className='green small'>
            <span>Recalcular</span>
          </button>
          <button onClick={() => handleTabChange(null, 1)} className='blue small'>
            <span> Inserir </span>
          </button>
        </div>
        <ProductStepTable
          registration={currentRegistration}
          registrationProductsData={registrationProductsData.filter(item => item.active)}
          closuresData={closuresData}
          deleteRegistrationClosureMethod={deleteRegistrationClosureMethod}
          deleteRegistrationProduct={deleteRegistrationProduct}
          expandedClosureRow={expandedClosureRow}
          expandedProductContractsRow={expandedProductContractsRow}
          expandedRows={expandedRows}
          fetchRegistrationProducts={fetchRegistrationProducts}
          renderReady={renderReady}
          selectProduct={selectProduct}
          setExpandedClosureRows={setExpandedClosureRows}
          setExpandedProductContractsRow={setExpandedProductContractsRow}
          setExpandedRows={setExpandedRows}
          tabValue={tabValue}
        />
        <Buttons setStep={setStep} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <InsertProductStepForm
          currentRegistration={currentRegistration}
          fetchRegistrationProducts={fetchRegistrationProducts}
          handleTabChange={handleTabChange}
          setLoading={setLoading}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <InsertProductDiscountForm
          currentRegistration={currentRegistration}
          fetchRegistrationProducts={fetchRegistrationProducts}
          handleTabChange={handleTabChange}
          initialRegistrationProduct={initialRegistrationProduct}
          setLoading={setLoading}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3} className='white-bg default-padding'>
        <ProductStepTable
          registration={currentRegistration}
          registrationProductsData={registrationProductsData.filter(item => !item.active)}
          closuresData={closuresData}
          deleteRegistrationClosureMethod={deleteRegistrationClosureMethod}
          deleteRegistrationProduct={deleteRegistrationProduct}
          expandedClosureRow={expandedClosureRow}
          expandedProductContractsRow={expandedProductContractsRow}
          expandedRows={expandedRows}
          fetchRegistrationProducts={fetchRegistrationProducts}
          renderReady={renderReady}
          selectProduct={selectProduct}
          setExpandedClosureRows={setExpandedClosureRows}
          setExpandedProductContractsRow={setExpandedProductContractsRow}
          setExpandedRows={setExpandedRows}
          tabValue={tabValue}
        />
        <Buttons setStep={setStep} />
      </TabPanel>
    </div>
  )
}

export default ProductStep
