import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

import { StateJson } from '../states'

export interface CountryAttrs {
  name: string
}
export type CountryJson = JsonFormat<'countries', CountryAttrs>

export type FetchCountriesResponse = JsonResponseFormat<CountryJson[]>
export type FetchCountryResponse = JsonResponseFormat<CountryJson, StateJson[]>

export type FetchCountriesPayload = WithRequestParams

export interface FetchCountryPayload extends WithRequestParams {
    id: string,
  }

export const FETCH_COUNTRIES = createPromiseAction('countries: FETCH_COUNTRIES')<FetchCountriesPayload, FetchCountriesResponse, any>()
export const FETCH_COUNTRY = createPromiseAction('countries: FETCH_COUNTRY')<FetchCountryPayload, FetchCountryResponse, any>()
