import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_KTWELVE_SUBJECTS,
  FetchKtwelveSubjectsPayload,
  FetchKtwelveSubjectPayload,
  FETCH_KTWELVE_SUBJECT,
  CREATE_KTWELVE_SUBJECT,
  CreateKtwelveSubjectPayload,
  UpdateKtwelveSubjectPayload,
  UPDATE_KTWELVE_SUBJECT,
  DeleteKtwelveSubjectPayload,
  DELETE_KTWELVE_SUBJECT
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchKtwelveSubjects (action: DefaultAction<FetchKtwelveSubjectsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchKtwelveSubjects, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_KTWELVE_SUBJECTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_KTWELVE_SUBJECTS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchKtwelveSubject (action: DefaultAction<FetchKtwelveSubjectPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchKtwelveSubject, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_KTWELVE_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_KTWELVE_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createKtwelveSubject (action: DefaultAction<CreateKtwelveSubjectPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'ktwelve_subjects',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createKtwelveSubject, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_KTWELVE_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_KTWELVE_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateKtwelveSubject (action: DefaultAction<UpdateKtwelveSubjectPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'ktwelve_subjects',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateKtwelveSubject, {data: params, id})
    yield put(UPDATE_KTWELVE_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_KTWELVE_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteKtwelveSubject (action: DefaultAction<DeleteKtwelveSubjectPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteKtwelveSubject, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_KTWELVE_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_KTWELVE_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchKtwelveSubjectsSagas () {
  yield takeLatest(FETCH_KTWELVE_SUBJECTS.request, fetchKtwelveSubjects)
  yield takeLatest(FETCH_KTWELVE_SUBJECT.request, fetchKtwelveSubject)
  yield takeLatest(CREATE_KTWELVE_SUBJECT.request, createKtwelveSubject)
  yield takeLatest(UPDATE_KTWELVE_SUBJECT.request, updateKtwelveSubject)
  yield takeLatest(DELETE_KTWELVE_SUBJECT.request, deleteKtwelveSubject)
}

export default function * ktwelveSubjectsSagas () {
  yield all([watchKtwelveSubjectsSagas()])
}
