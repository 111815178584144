/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { DiscountTypes, JsonFormat, JsonResponseFormat, PaymentCollectionKindTypes, PaymentOptionDateKindTypes, PaymentOptionPortionsEnum, WithRequestParams } from '../../utils/constants'

export interface PaymentOptionAttrs {
  active_ends_at: string
  active_starts_at: string
  advance_discount_kind: DiscountTypes
  advance_discount_value: number
  consolidate_payments_same_expiration_date: boolean
  date_kind: PaymentOptionDateKindTypes
  days_after_billing: number
  description: string
  expiration_day: number
  fees: number
  name: string
  penalty: number
  portion_discount_kind: DiscountTypes
  portion_discount_value: number
  portions: PaymentOptionPortionsEnum
  product_id: number
  punctuality_discount_kind: DiscountTypes
  punctuality_discount_value: number
  punctuality_expiration_day: number
}


export interface PaymentCollectionAttrs {
  description: string
  kind: PaymentCollectionKindTypes
  number: number
  expiration_date: string
  punctuality_expiration_date: string
  payment_option_id: number
}
export type PaymentOptionJson = JsonFormat<'payment_options', PaymentOptionAttrs>
export type PaymentCollectionJson = JsonFormat<'payment_collections', PaymentCollectionAttrs>
export type FetchPaymentOptionsResponse = JsonResponseFormat<PaymentOptionJson[]>
export type FetchPaymentOptionsPayload = WithRequestParams

export const FETCH_PAYMENT_OPTIONS = createPromiseAction('payment_options: FETCH_PAYMENT_OPTIONS')<FetchPaymentOptionsPayload, FetchPaymentOptionsResponse, any>()
