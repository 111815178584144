import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './styles/DashboardTemplate.style'

const NotLoggedTemplate = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container>
      <div>{children}</div>
    </Container>
  )
}

NotLoggedTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.array]).isRequired
}

export default NotLoggedTemplate