import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_TEACHER, CreateTeacherPayload, DELETE_TEACHER, DeleteTeacherPayload, FETCH_TEACHER, FETCH_TEACHERS, FetchTeacherPayload, FetchTeachersPayload, UPDATE_TEACHER, UpdateTeacherPayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchTeachers (action: DefaultAction<FetchTeachersPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchTeachers, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_TEACHERS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_TEACHERS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchTeacher (action: DefaultAction<FetchTeacherPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchTeacher, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_TEACHER.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_TEACHER.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createTeacher (action: DefaultAction<CreateTeacherPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'teachers',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createTeacher, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_TEACHER.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_TEACHER.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateTeacher (action: DefaultAction<UpdateTeacherPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'teachers',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateTeacher, {data: params, id})
    yield put(UPDATE_TEACHER.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_TEACHER.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteTeacher (action: DefaultAction<DeleteTeacherPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteTeacher, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_TEACHER.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_TEACHER.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchTeachersSagas () {
  yield takeLatest(FETCH_TEACHERS.request, fetchTeachers)
  yield takeLatest(FETCH_TEACHER.request, fetchTeacher)
  yield takeLatest(CREATE_TEACHER.request, createTeacher)
  yield takeLatest(UPDATE_TEACHER.request, updateTeacher)
  yield takeLatest(DELETE_TEACHER.request, deleteTeacher)
}

export default function * teachersSagas () {
  yield all([watchTeachersSagas()])
}
