import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const walletEndpoint = '/wallets'

export const fetchWallets = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${walletEndpoint}?${queryParams}`)
}

export const fetchWallet = ({ id, params }: { id: string|number, params?: RequestParams }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${walletEndpoint}/${id}?${queryParams}`)
}
