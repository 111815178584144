import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_CURRICULUMS,
  FetchCurriculumsPayload,
  FetchCurriculumPayload,
  FETCH_CURRICULUM,
  CREATE_CURRICULUM,
  CreateCurriculumPayload,
  UpdateCurriculumPayload,
  UPDATE_CURRICULUM,
  DeleteCurriculumPayload,
  DELETE_CURRICULUM
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchCurriculums (action: DefaultAction<FetchCurriculumsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchCurriculums, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CURRICULUMS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CURRICULUMS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchCurriculum (action: DefaultAction<FetchCurriculumPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchCurriculum, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CURRICULUM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CURRICULUM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createCurriculum (action: DefaultAction<CreateCurriculumPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'curriculums',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createCurriculum, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_CURRICULUM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_CURRICULUM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateCurriculum (action: DefaultAction<UpdateCurriculumPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'curriculums',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateCurriculum, {data: params, id})
    yield put(UPDATE_CURRICULUM.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_CURRICULUM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteCurriculum (action: DefaultAction<DeleteCurriculumPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteCurriculum, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_CURRICULUM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_CURRICULUM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchCurriculumsSagas () {
  yield takeLatest(FETCH_CURRICULUMS.request, fetchCurriculums)
  yield takeLatest(FETCH_CURRICULUM.request, fetchCurriculum)
  yield takeLatest(CREATE_CURRICULUM.request, createCurriculum)
  yield takeLatest(UPDATE_CURRICULUM.request, updateCurriculum)
  yield takeLatest(DELETE_CURRICULUM.request, deleteCurriculum)
}

export default function * curriculumsSagas () {
  yield all([watchCurriculumsSagas()])
}
