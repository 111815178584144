import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { WrappedFieldProps } from 'redux-form'

interface CustomFieldProps {
  placeholder: string
  disabled: boolean
}

const CheckboxComponent = (props: WrappedFieldProps & CustomFieldProps) => {
  const { input, placeholder, disabled } = props
  const { value, name, ...rest} = input
    return (
      <div className='checkbox' id={`input-${input.name}`}>
        <span>{placeholder}</span>
        <Checkbox
          value={value}
          name={name}
          disabled={disabled}
          checked={value}
          {...rest}
        />
      </div>
    )
}

  export default CheckboxComponent