import React from 'react';
import { css } from '@emotion/react';
import { Field, reduxForm } from 'redux-form';
import { DefaultOptionType, RoomStudentAttributes, validation } from '../../utils/constants';
import DatePickerComponent from '../input/form/datepicker';
import { compose } from 'redux';
import AutoCompleteComponent from '../input/form/autocomplete';
import { FETCH_ROOMS } from '../../store/rooms';
import { useDispatch } from 'react-redux';
import { error } from 'react-notification-system-redux';
import { RelocateRoomStudentFormData } from '../../store/room_students';
import TextAreaComponent from '../input/form/textarea';

const FormWrapperCss = css`
  display: grid;
  row-gap: 1rem;
`;
const ButtonCss = css`
  margin: 2rem 1rem;
  display: flex;
  justify-content: end;
  gap: 1rem;
`;
const RelocateStudentForm = (props: {
  room_student: RoomStudentAttributes;
  handleSubmit: (arg: () => void) => any;
  onSubmit: () => void;
  close_form: () => void;
}) => {
  const { room_student, handleSubmit, onSubmit, close_form } = props;
  const [loading, setLoading] = React.useState(false);
  const [roomOptions, setRoomOptions] = React.useState<DefaultOptionType[]>([]);
  const dispatch = useDispatch();
  const fetchProductOptionsMethod = React.useCallback(
    async (value = '') => {
      try {
        setLoading(true);
        const rooms = await dispatch(
          FETCH_ROOMS.request({
            params: {
              filters: {
                'q[name_start]': value,
                'q[product_id_eq]': room_student.product_id.toString(),
                'q[id_not_eq]': room_student.room_id.toString(),
              },
            },
          }),
        );
        if (rooms) {
          const {
            data: { data },
          } = rooms;
          const formattedData = data.reduce((acc, { attributes, id }) => {
            return acc.concat({
              label: attributes.name,
              value: id,
            });
          }, [] as DefaultOptionType[]);
          setRoomOptions(formattedData);
          setLoading(false);
        }
      } catch (e) {
        dispatch(
          error({
            message: 'Erro ao carregar opções de salas',
          }),
        );
      }
    },
    [room_student],
  );

  const init = React.useCallback(async () => {
    await fetchProductOptionsMethod();
  }, [room_student]);

  React.useEffect(() => {
    init();
  }, []);
  return (
    <div css={FormWrapperCss}>
      <div style={{ display: 'grid', gridTemplateColumns: '33% 33% 33%', justifyContent: 'space-between' }}>
        <Field
          name={`current_room_student_attributes.ends_at`}
          label={'Último dia de aula*'}
          placeholder={'01/01/2025'}
          component={DatePickerComponent}
          datePickerProps={{
            showDropdownIcon: true,
          }}
          validate={[validation.dateRequired]}
        />
        <Field
          name={`new_room_student_attributes.starts_at`}
          label={'Primeiro dia de aula*'}
          placeholder={'01/01/2025'}
          component={DatePickerComponent}
          datePickerProps={{
            showDropdownIcon: true,
          }}
          validate={[validation.dateRequired]}
        />
        <Field
          name='new_room_student_attributes.room_id'
          label={'Nova Sala'}
          placeholder={'Nova Sala'}
          component={AutoCompleteComponent}
          autoCompleteProps={{
            filterOptions: (x: any) => x,
            loading,
            getValueOnChange: true,
            fetchOptions: fetchProductOptionsMethod,
          }}
          options={roomOptions}
          validate={[validation.required]}
        />
      </div>
      <Field
        label={'Comentários *'}
        placeholder={'Escreva um comentário sobre o remanejamento'}
        name='current_room_student_attributes.audit_comment'
        component={TextAreaComponent}
        validate={[validation.required]}
      />
      <div css={ButtonCss}>
        <button
          onClick={(e) => {
            e.preventDefault();
            close_form();
          }}
          className='red small'
        >
          <span> Cancelar </span>
        </button>
        <button onClick={handleSubmit(onSubmit)} className='blue small'>
          <span> Remanejar </span>
        </button>
      </div>
    </div>
  );
};

export default compose<
  React.FunctionComponent<{
    room_student: RoomStudentAttributes;
    onSubmit: (data: RelocateRoomStudentFormData) => void;
    close_form: () => void;
  }>
>(
  reduxForm({
    form: 'relocateStudentForm',
  }),
)(RelocateStudentForm);
