import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'

const invoiceItemEndpoint = '/invoice_items'

export const createInvoice = (params: object) => {
  return http.post(`${invoiceItemEndpoint}`, params)
}
export const updateInvoice = ({ params, id }: { params: object, id: string|number }) => {
  return http.put(`${invoiceItemEndpoint}/${id}`, params)
}

export const fetchInvoices = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${invoiceItemEndpoint}?${queryParams}`)
}

export const fetchInvoice = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${invoiceItemEndpoint}/${id}?${queryParams}`)
}

