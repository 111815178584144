import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_CLASSROOMS,
  FetchClassroomsPayload,
  FetchClassroomPayload,
  FETCH_CLASSROOM,
  CREATE_CLASSROOM,
  CreateClassroomPayload,
  UpdateClassroomPayload,
  UPDATE_CLASSROOM
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchClassrooms (action: DefaultAction<FetchClassroomsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchClassrooms, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CLASSROOMS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CLASSROOMS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchClassroom (action: DefaultAction<FetchClassroomPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchClassroom, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CLASSROOM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CLASSROOM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createClassroom (action: DefaultAction<CreateClassroomPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'classrooms',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createClassroom, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_CLASSROOM.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_CLASSROOM.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateClassroom (action: DefaultAction<UpdateClassroomPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'classrooms',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateClassroom, {data: params, id})
    yield put(UPDATE_CLASSROOM.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_CLASSROOM.failure(e))
    rejectPromiseAction(action, e)
  }
}



export function * watchClassroomsSagas () {
  yield takeLatest(FETCH_CLASSROOMS.request, fetchClassrooms)
  yield takeLatest(FETCH_CLASSROOM.request, fetchClassroom)
  yield takeLatest(CREATE_CLASSROOM.request, createClassroom)
  yield takeLatest(UPDATE_CLASSROOM.request, updateClassroom)
}

export default function * classrooomsSagas () {
  yield all([watchClassroomsSagas()])
}
