import { createPromiseAction } from "redux-saga-promise-actions"
import { CompositionPeriodKindEnum, JsonFormat, JsonResponseFormat, WithRequestParams} from "../../utils/constants"
import { ExamPlacementJson } from "../exam_placements"
import { FormulaJson } from "../formulas"
import { AxiosResponse } from "axios"

export interface SubjectPeriodAttrs {
  composition_period_id: number
  ktwelve_subject_id: number
  subject_name: string
  ktwelve_name: string
  composition_period_name: string
  starts_at: string
  ends_at: string
  insert_starts_at: string
  insert_ends_at: string
  order: number
  kind: CompositionPeriodKindEnum
}

export type SubjectPeriodJson = JsonFormat<'subject_periods', SubjectPeriodAttrs>
export type FetchSubjectPeriodsResponse = JsonResponseFormat<SubjectPeriodJson[], (ExamPlacementJson|FormulaJson)[]>
export type FetchSubjectPeriodResponse = JsonResponseFormat<SubjectPeriodJson, (ExamPlacementJson|FormulaJson)[]>
export type FetchSubjectPeriodsPayload = WithRequestParams

export interface FetchSubjectPeriodPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateSubjectPeriodPayload {
  data: object
}

export interface UpdateSubjectPeriodPayload extends CreateSubjectPeriodPayload {
  id: string
}

export interface DeleteSubjectPeriodPayload {
  id: string
}


export const FETCH_SUBJECT_PERIODS = createPromiseAction('subject_periods: FETCH_SUBJECT_PERIODS')<FetchSubjectPeriodsPayload, FetchSubjectPeriodsResponse, any>()
export const FETCH_SUBJECT_PERIOD  = createPromiseAction('subject_periods: FETCH_SUBJECT_PERIOD')<FetchSubjectPeriodPayload, FetchSubjectPeriodResponse, any>()
export const CREATE_SUBJECT_PERIOD = createPromiseAction('subject_periods: CREATE_SUBJECT_PERIOD')<CreateSubjectPeriodPayload, FetchSubjectPeriodResponse, any>()
export const UPDATE_SUBJECT_PERIOD = createPromiseAction('subject_periods: UPDATE_SUBJECT_PERIOD')<UpdateSubjectPeriodPayload, FetchSubjectPeriodResponse, any>()
export const DELETE_SUBJECT_PERIOD = createPromiseAction('subject_periods: DELETE_SUBJECT_PERIOD')<DeleteSubjectPeriodPayload, AxiosResponse, any>()