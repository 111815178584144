import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_INVOICES,
  FETCH_INVOICE,
  FetchInvoicesPayload,
  FetchInvoicePayload,
  CreateInvoicePayload,
  CREATE_INVOICE,
  UpdateInvoicePayload,
  UPDATE_INVOICE,
  CreateInvoiceChargePayload,
  CREATE_INVOICE_CHARGE,
  DeleteInvoiceChargePayload,
  DELETE_INVOICE_CHARGE,
  SendStraightNotificationPayload,
  SEND_STRAIGHT_NOTIFICATION,
  REFRESH_INVOICE_CHARGE,
  RefreshInvoiceChargePayload,
  FETCH_INVOICE_CHARGE_QR_CODE,
  UNLINK_INVOICE_CHARGE,
  UnlinkInvoiceChargePayload,
  GenerateInvoiceReceipts,
  GENERATE_INVOICE_RECEIPTS
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'


export function * createInvoice (action: DefaultAction<CreateInvoicePayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'invoices',
        attributes: { ...data },
      }
    }    
    const response = yield call(services.createInvoice, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_INVOICE.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_INVOICE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateInvoice (action: DefaultAction<UpdateInvoicePayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'invoices',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateInvoice, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(UPDATE_INVOICE.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_INVOICE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchInvoices (action: DefaultAction<FetchInvoicesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchInvoices, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_INVOICES.success(response))
    resolvePromiseAction(action, response)
    
  } catch(err) {
    yield put(FETCH_INVOICES.failure(err))
    rejectPromiseAction(action, err)
  }
}

export function * fetchInvoice(action: DefaultAction<FetchInvoicePayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchInvoice, { params, id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_INVOICE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_INVOICE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createInvoiceCharge(action: DefaultAction<CreateInvoiceChargePayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.createInvoiceCharge, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_INVOICE_CHARGE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_INVOICE_CHARGE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteInvoiceCharge(action: DefaultAction<DeleteInvoiceChargePayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.deleteInvoiceCharge, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_INVOICE_CHARGE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_INVOICE_CHARGE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * refreshInvoiceCharge(action: DefaultAction<RefreshInvoiceChargePayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.refreshInvoiceCharge, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(REFRESH_INVOICE_CHARGE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(REFRESH_INVOICE_CHARGE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * unlinkInvoiceCharge(action: DefaultAction<UnlinkInvoiceChargePayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.unlinkInvoiceCharge, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(UNLINK_INVOICE_CHARGE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(UNLINK_INVOICE_CHARGE.failure(e))
    rejectPromiseAction(action, e)
  }
}



export function * fetchInvoiceChargeQrCode(action: DefaultAction<RefreshInvoiceChargePayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.fetchInvoiceChargeQrCode, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_INVOICE_CHARGE_QR_CODE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_INVOICE_CHARGE_QR_CODE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * sendStraightNotification(action: DefaultAction<SendStraightNotificationPayload, string>): any {
  const { payload: {id} } = action
  try {
    const response = yield call(services.sendStraightNotification, { id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(SEND_STRAIGHT_NOTIFICATION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(SEND_STRAIGHT_NOTIFICATION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * generateInvoiceReceipts (action: DefaultAction<GenerateInvoiceReceipts, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.generateInvoiceReceipts, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(GENERATE_INVOICE_RECEIPTS.success(response))
    resolvePromiseAction(action, response)
    
  } catch(err) {
    yield put(GENERATE_INVOICE_RECEIPTS.failure(err))
    rejectPromiseAction(action, err)
  }
}



export function * watchInvoicesSagas () {
  yield takeLatest(CREATE_INVOICE_CHARGE.request, createInvoiceCharge)
  yield takeLatest(CREATE_INVOICE.request, createInvoice)
  yield takeLatest(DELETE_INVOICE_CHARGE.request, deleteInvoiceCharge)
  yield takeLatest(FETCH_INVOICE_CHARGE_QR_CODE.request, fetchInvoiceChargeQrCode)
  yield takeLatest(FETCH_INVOICE.request, fetchInvoice)
  yield takeLatest(FETCH_INVOICES.request, fetchInvoices)
  yield takeLatest(REFRESH_INVOICE_CHARGE.request, refreshInvoiceCharge)
  yield takeLatest(SEND_STRAIGHT_NOTIFICATION.request, sendStraightNotification)
  yield takeLatest(UPDATE_INVOICE.request, updateInvoice)
  yield takeLatest(UNLINK_INVOICE_CHARGE.request, unlinkInvoiceCharge)
  yield takeLatest(GENERATE_INVOICE_RECEIPTS.request, generateInvoiceReceipts)
}

export default function * invoiceSagas () {
  yield all([watchInvoicesSagas()])
}
