/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { TransactionEventTypes, JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'


export interface TransactionAttrs {
  description: string
  transactable_id: number
  transactable_type: 'Invoice'|'Wallet'|'User'
  renegotiation_id: number
  event: TransactionEventTypes
  event_date: string
  transaction_log: object
}

export type TransactionJson = JsonFormat<'transactions', TransactionAttrs>
export type FetchTransactionResponse = JsonResponseFormat<TransactionJson[]>
export type FetchTransactionsPayload = WithRequestParams

export interface CreateTransactionPayload {
  data: Partial<TransactionAttrs>
}


export const CREATE_TRANSACTION = createPromiseAction('transactions: CREATE_TRANSACTION')<CreateTransactionPayload, FetchTransactionResponse, any>()
export const FETCH_TRANSACTIONS = createPromiseAction('transactions: FETCH_TRANSACTIONS')<FetchTransactionsPayload, FetchTransactionResponse, any>()