import React from 'react'
import { Field } from 'redux-form'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import { Theme } from '@mui/system'

import { colors, PaginationType, ProductOptions } from '../../utils/constants'
import { LoadingBackdrop } from '../modal/Modal'
import RadioComponent from '../input/form/radio'
import { checkResourceActivity, parseText } from '../../utils/functions'
import { makeStyles } from '@mui/styles'

const columns = [
  'Nome', 'Descrição', 'Vagas'
]

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    background: '#FDFEFF',
    padding: '1rem',
    borderRadius: '1rem',
    '& .delete': {
      color: colors.lightRed,
      cursor: 'pointer',
      '&:hover' :{
        color: '#cc8080'
      },
    },
    '& th': {
      color: colors.darkGrayBlue,
      borderBottom: 'none'
    },
    '& .MuiPaper-root': {
      flexGrow: '1'
    },
    '& span.empty': {
      color: colors.lightRed
    },
    '& span.empty:empty:before': {
      content: '"\\200b"'
    },
    '& tbody': {
      position: 'relative'
    },
    '& td': {
      padding: '2px',
      color: colors.darkBlue,
      fontSize: '1rem',
      borderBottom: `1px solid ${colors.grayBlue}`,
      [theme.breakpoints.down('lg')]: {
        fontSize: '0.75rem'
      },
    },
    '& .contract': {
      background: '#FDFEFF',
      '& td:last-of-type, th:last-of-type': {
        borderLeft: `1px solid ${colors.grayBlue}`,
        borderRight: `1px solid ${colors.grayBlue}`    
      },
      '& th:last-of-type': {
        borderTop: `1px solid ${colors.grayBlue}`
      },  
    },
    '& .applied': {
      border: '1px solid #C5D4E3',
      background: 'white'
    },
    '& .applied, .contract':{
      '& tfoot':{
        '& td:last-of-type':{
          color: colors.green,
          paddingRight: '0.5rem'
        }
      }
    },
    '& .applied, .contract, .preview': {
      '& tfoot': {
        '& td:first-of-type':{
          paddingLeft: '0.5rem'
        }
      }
    },
    '& tfoot' : {
      "& > tr": {
        border: `1px solid ${colors.grayBlue}`,
        'border-radius': '0px 0px 8px 8px',  
        background: 'white',
        '& > td': {
          padding: '0.5rem 0',
          fontWeight: 'bold'
        },
      }
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& td': {
        color: colors.darkBlue,
        '& svg': {
          cursor: 'pointer',
          margin: '0 0.25rem'
        }
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none'
      }
    }
  }
}))

const InsertProductStepTable = ({
  loadingDynamicOptions,
  productOptions,
  pagination,
  productValue,
  handleChangePageSize,
  handlePageChange
}:{
  loadingDynamicOptions: string[]
  productOptions: ProductOptions[]
  pagination: PaginationType
  productValue: string
  handlePageChange: (_: any, newPage: number) => void
  handleChangePageSize: (e: any) => void
}) => {
  const classes = useStyles()

  return (
    <div className={classes.table}>
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='left'>
                Nome
              </TableCell>
              <TableCell align='center'>
                Descrição
              </TableCell>
              <TableCell align='center'>
                Quantidade total
              </TableCell>
              <TableCell align='right'>
                Vagas restantes
              </TableCell>
          </TableRow>
          </TableHead>
          <TableBody style={{ height: loadingDynamicOptions.includes('products') ? '30rem' : 'unset' }}>
            <LoadingBackdrop open={loadingDynamicOptions.includes('products')} />
            {
              productOptions.length === 0 && !loadingDynamicOptions.includes('products') ?
              (
                <TableRow>
                  <TableCell colSpan={4}>
                    <h3 style={{ textAlign: 'center' }}>Produtos não encontrados</h3>
                  </TableCell>
                </TableRow>
              )
              : productOptions.map(row => {
                const { sale_ends_at, sale_starts_at } = row
                const result = checkResourceActivity(sale_starts_at, sale_ends_at, row.periods['ends_at'])
                return (
                <TableRow key={row.value}>
                  <TableCell>
                    <Field
                      name='product_id'
                      component={RadioComponent}
                      props={{ value: row.value }}
                      checked={productValue === row.value}
                      disabled={row.available <= 0 || !result}
                    />
                  </TableCell>
                  <TableCell align='left'>
                    {row.label}
                  </TableCell>
                  <TableCell align='center'>
                    <Tooltip title={row.description}>
                      <span>{parseText(row.description, 40)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center'>
                    {row.quantity}
                  </TableCell>
                  <TableCell align='right'>
                    {row.available}
                  </TableCell>
                </TableRow>
                )
              })
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                colSpan={columns?.length + 2}
                count={pagination.totalCount}
                rowsPerPage={pagination.pageSize}
                page={pagination.pageNumber}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangePageSize}
                labelRowsPerPage='Itens Por página'
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : ` 'mais de' ${to}`}`
                }
              />
            </TableRow>
          </TableFooter>
          </Table>
      </TableContainer>
    </div>
  )
}

export default InsertProductStepTable
