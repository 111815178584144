import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const ktwelveCurriculumEndpoints = '/ktwelve_curriculums'

export const fetchKtwelveCurriculums = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ktwelveCurriculumEndpoints}?${queryParams}`)
}

export const fetchKtwelveCurriculum = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ktwelveCurriculumEndpoints}/${id}?${queryParams}`)
}

export const createKtwelveCurriculum = (data: object) => {
  return http.post(`${ktwelveCurriculumEndpoints}`, data)
}

export const updateKtwelveCurriculum = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${ktwelveCurriculumEndpoints}/${id}`, data)
}

export const deleteKtwelveCurriculum = ({ id }: { id: string | number } ) => {
  return http.delete(`${ktwelveCurriculumEndpoints}/${id}`)
}
