import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  FetchProductsPayload,
  FetchProductPayload,
  CreateProductPayload,
  CREATE_PRODUCT,
  UpdateProductPayload,
  UPDATE_PRODUCT
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'


export function * createProduct (action: DefaultAction<CreateProductPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'products',
        attributes: { ...data },
      }
    }    
    const response = yield call(services.createProduct, params)
    yield put(CREATE_PRODUCT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_PRODUCT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateProduct (action: DefaultAction<UpdateProductPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'products',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateProduct, {params, id})
    yield put(UPDATE_PRODUCT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_PRODUCT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchProducts (action: DefaultAction<FetchProductsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchProducts, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_PRODUCTS.success(response))
    resolvePromiseAction(action, response)
    
  } catch(err) {
    yield put(FETCH_PRODUCTS.failure(err))
    rejectPromiseAction(action, err)
  }
}

export function * fetchProduct(action: DefaultAction<FetchProductPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchProduct, { params, id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_PRODUCT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_PRODUCT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchProductsSagas () {
  yield takeLatest(FETCH_PRODUCTS.request, fetchProducts)
  yield takeLatest(FETCH_PRODUCT.request, fetchProduct)
  yield takeLatest(CREATE_PRODUCT.request, createProduct)
  yield takeLatest(UPDATE_PRODUCT.request, updateProduct)
}

export default function * productsSagas () {
  yield all([watchProductsSagas()])
}
