

import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { ParsedPaymentSimulationDataResult, PaymentSimulationPaymentInfo, colors } from '../../utils/constants'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  table: {
    '& tbody > tr:nth-child(even)': {
      backgroundColor: `${colors.darkGrayBlue} !important`
    },
    '& tbody > tr:nth-child(odd)': {
      backgroundColor: `${colors.grayBlue} !important`
    },
    '& .include': {
      display: 'flex',
      marginLeft: 'auto',
      marginBottom: '1rem'
    },
    '& tfoot': {
      background: 'white',
      '& td':{
        fontWeight: 'bold',
        fontSize: '1rem'
      },
      '& td:last-of-type': {
        color: colors.blue
      }
    },
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `5px solid ${colors.lightBlue}`
      },
      '& th': {
        color: colors.darkGrayBlue
      },
      '& td': {
        color: colors.darkBlue,
        '& svg': {
          color: colors.darkGrayBlue,
          cursor: 'pointer'
        }
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none'
      }
    }
  },
}))


const BillingsTableBody = ({
  paymentInfo,
  result
} : {
  paymentInfo: PaymentSimulationPaymentInfo[]
  result: ParsedPaymentSimulationDataResult
}) => {
  return (
    <TableBody>
    {paymentInfo?.length &&
      paymentInfo.map((billing) => {
        return (
          <TableRow key={billing.code}>
            <TableCell align='left'>
              {' '}
              {billing.code}{' '}
            </TableCell>
            <TableCell>
              {billing.month?.split('+').map(month => <>{month}<br/></>)}
            </TableCell>
            <TableCell align='center'>
              {billing.tableValue}
            </TableCell>
            <TableCell align='center'>
              {billing.initialValue}
            </TableCell>
            <TableCell align='center'>
              {billing.discountValue}
            </TableCell>
            <TableCell align='right'>
              {billing.total}
            </TableCell>
          </TableRow>
        )
      })}
      <TableRow>
        <TableCell align='left'>
          {'Total'}
        </TableCell>
        <TableCell colSpan={1}/>
        <TableCell align='center'>
          {result.tableValue}
        </TableCell>
        <TableCell align='center'>
          {result.initialValue}
        </TableCell>
        <TableCell align='center'>
          {result.discountValue}
        </TableCell>
        <TableCell align='right'>
          {result.total}
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

const BillingTable = ({
  paymentInfo,
  result
} : {
  paymentInfo: PaymentSimulationPaymentInfo[]
  result: ParsedPaymentSimulationDataResult
}) => {
  const classes = useStyles()
  return (
    <Table className={classes.table} size='small' aria-label='payment-simulation'>
    <TableHead>
      <TableRow>
        <TableCell align='left'>
          Mês/Parcela
        </TableCell>
        <TableCell>
          Mês
        </TableCell>
        <TableCell align='center'>
          Valor Tabela
        </TableCell>
        <TableCell align='center'>
          Valor Contrato
        </TableCell>
        <TableCell align='center' >
          Desconto Condicional
        </TableCell>
        <TableCell align='right'>
          Valor Líquido
        </TableCell>
      </TableRow>
    </TableHead>
    <BillingsTableBody
      paymentInfo={paymentInfo}
      result={result}
    />
    </Table>
  )
}

export default BillingTable
