import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_CALENDAR_EVENTS,
  FetchCalendarEventsPayload,
  FetchCalendarEventPayload,
  FETCH_CALENDAR_EVENT,
  CREATE_CALENDAR_EVENT,
  CreateCalendarEventPayload,
  UpdateCalendarEventPayload,
  UPDATE_CALENDAR_EVENT,
  DeleteCalendarEventPayload,
  DELETE_CALENDAR_EVENT
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchCalendarEvents (action: DefaultAction<FetchCalendarEventsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchCalendarEvents, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CALENDAR_EVENTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CALENDAR_EVENTS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchCalendarEvent (action: DefaultAction<FetchCalendarEventPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchCalendarEvent, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CALENDAR_EVENT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CALENDAR_EVENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createCalendarEvent (action: DefaultAction<CreateCalendarEventPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'calendar_events',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createCalendarEvent, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_CALENDAR_EVENT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_CALENDAR_EVENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateCalendarEvent (action: DefaultAction<UpdateCalendarEventPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'calendar_events',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateCalendarEvent, {data: params, id})
    yield put(UPDATE_CALENDAR_EVENT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_CALENDAR_EVENT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteCalendarEvent (action: DefaultAction<DeleteCalendarEventPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchCalendarEvent, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_CALENDAR_EVENT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_CALENDAR_EVENT.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * watchCalendarEventsSagas () {
  yield takeLatest(FETCH_CALENDAR_EVENTS.request, fetchCalendarEvents)
  yield takeLatest(FETCH_CALENDAR_EVENT.request, fetchCalendarEvent)
  yield takeLatest(CREATE_CALENDAR_EVENT.request, createCalendarEvent)
  yield takeLatest(UPDATE_CALENDAR_EVENT.request, updateCalendarEvent)
  yield takeLatest(DELETE_CALENDAR_EVENT.request, updateCalendarEvent)
}

export default function * calendarEventSagas () {
  yield all([watchCalendarEventsSagas()])
}
