/* eslint-disable camelcase */
import React from 'react'
import { useDispatch } from 'react-redux'
import { error, warning } from 'react-notification-system-redux'

import Loading from '../components/loading/Loading'

import { ProductJson, ProductPeriodJson } from '../store/products'

import { dateRange, checkResourceActivity, verifyDifferentValues } from '../utils/functions'
import {
  CurrentRegistrationData,
  PaymentOptionsAttributes,
  DefaultOptionType,
  SubsidyAttributes,
  UpdateRegistrationProductFormAttributes,
  ProductAttributes,
  NestedRegistrationProductSubsidyAttributes,
} from '../utils/constants'

import InsertProductDiscountForm from '../components/form/InsertProductDiscountForm'

import { SubsidyJson } from '../store/subsidies'
import {  find, isEmpty, isNil, keys, omit } from 'lodash'
import { FETCH_REGISTRATION_PRODUCT, UPDATE_REGISTRATION_PRODUCT } from '../store/registration_products'
import { RegistrationProductSubsidiesJson } from '../store/registrations'
import { PaymentOptionJson } from '../store/payment_options'
import { dateRangeWithRegistrationFee } from '../utils/contractSimulation';

const registrationProductSubsidyUpdater = (currentRegistrationProductSubsidy: NestedRegistrationProductSubsidyAttributes[], initialRegistrationProductSubsidy: NestedRegistrationProductSubsidyAttributes[]) => {
  const sorted:NestedRegistrationProductSubsidyAttributes[]  = []
  currentRegistrationProductSubsidy.forEach(payment_collection => {
    const related = find(initialRegistrationProductSubsidy, initial => initial.id === payment_collection.id)
    if (isNil(related)) {
      sorted.push(payment_collection)
      return
    }
    const result = verifyDifferentValues(payment_collection, related, ['id', '_destroy']) as NestedRegistrationProductSubsidyAttributes
    if(keys(result).filter(key => key !== 'id').length > 0) {
      sorted.push(result)
    }
  })
  return sorted as NestedRegistrationProductSubsidyAttributes[]
}

const InsertProductDiscountContainer = (props: {
  setLoading: (value: boolean) => void
  currentRegistration: CurrentRegistrationData
  initialRegistrationProduct?: number | null
  handleTabChange: (_: any, value: number) => void
  fetchRegistrationProducts: () => Promise<void>
}) => {
  const {
    setLoading,
    currentRegistration,
    initialRegistrationProduct,
    handleTabChange,
    fetchRegistrationProducts,
  } = props
  const dispatch = useDispatch()

  const [initialValues, setInitialValues] = React.useState<UpdateRegistrationProductFormAttributes>()
  const [subsidyOptions, setSubsidyOptions] = React.useState<DefaultOptionType[]>([])
  const [allSubsidies, setAllSubsidies] = React.useState<SubsidyAttributes[]>([])
  const [preLoadingOptions, setPreLoadingOptions] = React.useState<string[]>([])
  const [currentProduct, setCurrentProduct] = React.useState<ProductAttributes | null>(null)
  const [currentPaymentOption, setCurrentPaymentOption] = React.useState<PaymentOptionsAttributes|null>(null)
  const [serviceRange, setServiceRange] = React.useState<DefaultOptionType[]>([])

  const loadinitialRegistrationProductInfo = React.useCallback(async () => {
    try {
      setLoading(true)
      setPreLoadingOptions((items) => items.concat('product'))
      const response = await dispatch(
        FETCH_REGISTRATION_PRODUCT.request({
          id: initialRegistrationProduct?.toString() as string,
          params:{
            filters: {
              'include': 'product.periods,registration_product_subsidies.subsidy,payment_option,product.subsidies'
            }
          },
        })
      )
      const { data: { included, data: { id, attributes } } } = response
      const registration_product = {
        id,
        ...attributes,
      }
      const product = find(included, incl => incl.type === 'products') as ProductJson
      const periods = included.find(item => item.type === 'product_periods') as ProductPeriodJson
      const registration_product_subsidies = included.filter(incl => incl.type === 'registration_product_subsidies') as RegistrationProductSubsidiesJson[]
      const payment_option = included.find(incl => incl.type === 'payment_options' && ~~incl.id === registration_product.payment_option_id) as PaymentOptionJson
      const formatted_payment_option = {
        id: payment_option.id,
        ...payment_option.attributes
      }
      const formatted_product = {
        id: product.id,
        ...product.attributes
      }
      const new_date_range = dateRange(periods.attributes.starts_at, periods.attributes.ends_at)
      const new_service_range = dateRangeWithRegistrationFee(new_date_range, product.attributes.registration_fee)
      const subsidies = included.filter(incl => incl.type === 'subsidies' && incl.attributes.product_id === ~~product.id) as SubsidyJson[]

      const new_initial_values = {
        id,
        ...attributes,
        registration_product_subsidies_attributes: registration_product_subsidies.map(rps => ({
          id: rps.id,
          ...rps.attributes
        }))
      }
      setInitialValues(new_initial_values)
      setCurrentPaymentOption(formatted_payment_option)
      setAllSubsidies(subsidies.map(item => ({id: item.id, ...item.attributes})))
      setSubsidyOptions(subsidies.map(item => {
        const { attributes: {active_ends_at, active_starts_at} } = item
        const result = checkResourceActivity(active_starts_at, active_ends_at, periods.attributes.ends_at)
        return ({ value: item.id, label: item.attributes.name, disabled: !result })
      }))
      setCurrentProduct(formatted_product)
      setServiceRange(new_service_range)
      setLoading(false)
      setPreLoadingOptions((items) => items.filter((x) => x !== 'product'))
    } catch (err) {
      dispatch(error({ message: 'Erro no carregamento do produto' }))
      setPreLoadingOptions((items) => items.filter((x) => x !== 'product'))
    }
  }, [initialRegistrationProduct, setPreLoadingOptions, currentRegistration])


  React.useEffect(() => {
    loadinitialRegistrationProductInfo()
  }, [initialRegistrationProduct])


  const onSubmitProductStep = async (data: UpdateRegistrationProductFormAttributes) => {
      setLoading(true)
      handleTabChange(null, 0)
      try {
        const { id, registration_product_subsidies_attributes, ...rest} = data
        const registration_product_attributes = {
          ...rest
        }
        const { registration_product_subsidies_attributes: initial_registration_product_subsidies_attributes, ...initial_rest } = initialValues as UpdateRegistrationProductFormAttributes
        const initial_registration_product_attributes = {...initial_rest}
        const new_registration_product_attributes = verifyDifferentValues(registration_product_attributes, initial_registration_product_attributes, ['id', 'code', 'registration_id', 'product_id'])
        const new_registration_product_subsidies_attributes = registrationProductSubsidyUpdater(registration_product_subsidies_attributes, initial_registration_product_subsidies_attributes)
        await dispatch(
          UPDATE_REGISTRATION_PRODUCT.request({
            id,
            data: {
              ...omit(new_registration_product_attributes, ['code']),
              registration_product_subsidies_attributes: new_registration_product_subsidies_attributes
            }
          })
        )
        await fetchRegistrationProducts()
        setLoading(false)
      } catch (e) {
        console.error(e)
        dispatch(
          warning({
            message: 'Erro na adição de produto'
          })
        )
        setLoading(false)
      }
    }

  if (preLoadingOptions.length || isEmpty(initialValues)) {
    return <Loading />
  }
  return (
    <InsertProductDiscountForm
      currentProduct={currentProduct}
      handleTabChange={handleTabChange}
      onSubmit={onSubmitProductStep}
      currentPaymentOption={currentPaymentOption}
      initialValues={initialValues}
      subsidyOptions={subsidyOptions}
      allSubsidies={allSubsidies}
      serviceRange={serviceRange}
    />
  )
}

export default InsertProductDiscountContainer
