import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_COMPOSITIONS,
  FetchCompositionsPayload,
  FetchCompositionPayload,
  FETCH_COMPOSITION,
  CREATE_COMPOSITION,
  CreateCompositionPayload,
  UpdateCompositionPayload,
  UPDATE_COMPOSITION,
  DeleteCompositionPayload,
  DELETE_COMPOSITION
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchCompositions (action: DefaultAction<FetchCompositionsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchCompositions, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_COMPOSITIONS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_COMPOSITIONS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchComposition (action: DefaultAction<FetchCompositionPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchComposition, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_COMPOSITION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_COMPOSITION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createComposition (action: DefaultAction<CreateCompositionPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'compositions',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createComposition, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_COMPOSITION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_COMPOSITION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateComposition (action: DefaultAction<UpdateCompositionPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'compositions',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateComposition, {data: params, id})
    yield put(UPDATE_COMPOSITION.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_COMPOSITION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteComposition (action: DefaultAction<DeleteCompositionPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteComposition, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_COMPOSITION.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_COMPOSITION.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchCompositionsSagas () {
  yield takeLatest(FETCH_COMPOSITIONS.request, fetchCompositions)
  yield takeLatest(FETCH_COMPOSITION.request, fetchComposition)
  yield takeLatest(CREATE_COMPOSITION.request, createComposition)
  yield takeLatest(UPDATE_COMPOSITION.request, updateComposition)
  yield takeLatest(DELETE_COMPOSITION.request, deleteComposition)
}

export default function * compositionsSagas () {
  yield all([watchCompositionsSagas()])
}
