import React from 'react'
import { css } from '@emotion/react'
import IconButton from '@mui/material/IconButton'
import { Box } from '@mui/material'

import { LeftArrow, RightArrow } from '../icon/ArrowIcon'
import { colors } from '../../utils/constants'


const Wrapper = css`
  display: flex;
  margin-left: 1rem;
  gap: 0.5rem;
  & button: {
    height: 2rem;
    width: 2rem;
    padding: unset;
    & svg: {
      height: 1rem;
    }
  }
  & button:not(.Mui-disabled): {
    backgroundColor: ${colors.darkBlue};
    & svg: {
      color: white;
    }
  }
`

const TablePaginationActions = (props: {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (e: any, page: number) => void
}) => {
  const { count, page, rowsPerPage, onPageChange } = props

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, 0)
  }

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1)
  }

  return(
    <Box css={Wrapper}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='Previous page'
      >
        <LeftArrow />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count/rowsPerPage) -1}
        aria-label='Next page'
      >
        <RightArrow />
      </IconButton>
    </Box>
  )
}

export default TablePaginationActions




