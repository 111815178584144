import React from 'react'
import { css } from '@emotion/react'
import { Field } from 'redux-form'
import { DefaultOptionType, validation } from '../../utils/constants'
import CheckboxComponent from '../input/form/checkbox'
import SelectComponent from '../input/form/select'

const documentRequirementFormWrapperCss = css`
  display: grid;
  grid-template-columns: 70% 25%;
  justify-content: space-between;
  align-items: center;
  min-width: 70%;
`

const DocumentRequirementForm = ({
  documentOptions
} : {
  documentOptions: DefaultOptionType[]
}) => {
  return (
    <div css={documentRequirementFormWrapperCss}>
      <Field
        name='document_id'
        component={SelectComponent}
        options={documentOptions}
        label={'Documento'}
        placeholder={'Selecione o tipo do documento'}
        validate={[validation.required]}
      />
      <Field
        name='mandatory'
        component={CheckboxComponent}
        placeholder={'Obrigatório?'}
      />
    </div>
  )
}

export default DocumentRequirementForm
