import React from 'react'
import { SvgIcon } from "@mui/material"

const DateIcon = (props: any) => (
    <SvgIcon viewBox='0 0 40 44' {...props}>
      <g clipPath='url(#clip0_992:1605)'>
        <path
          d='M30.9306 43.9996H9.07262C6.66774 43.9971 4.362 43.0245 2.6612 41.2951C0.960397 39.5658 0.0033598 37.2209 0 34.7748V12.5425C0.00335801 10.0961 0.960293 7.7509 2.66101 6.02106C4.36172 4.29122 6.66745 3.31787 9.07262 3.31445H30.9306C33.3341 3.32043 35.6374 4.2949 37.336 6.02447C39.0347 7.75404 39.9903 10.0978 39.9937 12.5425V34.7748C39.9903 37.2192 39.0346 39.5626 37.3358 41.2917C35.6371 43.0207 33.3338 43.9945 30.9306 43.9996ZM9.07262 5.36405C7.20191 5.36662 5.4086 6.12384 4.08611 7.46958C2.76362 8.81533 2.01992 10.6397 2.01824 12.5425V34.7748C2.01992 36.6773 2.76372 38.5013 4.0863 39.8466C5.40888 41.1918 7.20221 41.9483 9.07262 41.95H30.9306C32.801 41.9483 34.5943 41.1918 35.9169 39.8466C37.2395 38.5013 37.9833 36.6773 37.9849 34.7748V12.5425C37.9833 10.6397 37.2396 8.81533 35.9171 7.46958C34.5946 6.12384 32.8013 5.36662 30.9306 5.36405H9.07262Z'
          fill='#31314F'
        />
        <path
          d='M38.5626 17.2491H1.43452C1.16688 17.2491 0.910226 17.141 0.720979 16.9485C0.531732 16.756 0.425415 16.4949 0.425415 16.2227C0.425415 15.9505 0.531732 15.6894 0.720979 15.4969C0.910226 15.3044 1.16688 15.1963 1.43452 15.1963H38.5626C38.8303 15.1963 39.087 15.3044 39.2762 15.4969C39.4655 15.6894 39.5718 15.9505 39.5718 16.2227C39.5718 16.4949 39.4655 16.756 39.2762 16.9485C39.087 17.141 38.8303 17.2491 38.5626 17.2491Z'
          fill='#31314F'
        />
        <path
          d='M10.5323 9.90297C10.4002 9.90297 10.2694 9.8765 10.1474 9.82508C10.0253 9.77366 9.91441 9.6983 9.821 9.60329C9.72759 9.50828 9.6535 9.39548 9.60295 9.27134C9.5524 9.1472 9.52637 9.01416 9.52637 8.8798V1.02357C9.54082 0.761721 9.65328 0.515433 9.84064 0.335259C10.028 0.155086 10.2761 0.0546875 10.5339 0.0546875C10.7918 0.0546875 11.0398 0.155086 11.2272 0.335259C11.4145 0.515433 11.527 0.761721 11.5414 1.02357V8.8798C11.5414 9.01444 11.5153 9.14774 11.4646 9.27209C11.4138 9.39644 11.3394 9.50938 11.2457 9.60443C11.1519 9.69949 11.0407 9.77477 10.9183 9.826C10.7959 9.87723 10.6647 9.9034 10.5323 9.90297Z'
          fill='#31314F'
        />
        <path
          d='M29.4677 9.90297C29.2009 9.90297 28.9451 9.79518 28.7564 9.60329C28.5678 9.41141 28.4618 9.15116 28.4618 8.8798V1.02357C28.4762 0.761721 28.5887 0.515433 28.7761 0.335259C28.9634 0.155086 29.2115 0.0546875 29.4693 0.0546875C29.7271 0.0546875 29.9752 0.155086 30.1626 0.335259C30.3499 0.515433 30.4624 0.761721 30.4769 1.02357V8.8798C30.4769 9.01444 30.4507 9.14774 30.4 9.27209C30.3492 9.39644 30.2748 9.50938 30.1811 9.60443C30.0874 9.69949 29.9761 9.77477 29.8537 9.826C29.7312 9.87723 29.6001 9.9034 29.4677 9.90297Z'
          fill='#31314F'
        />
      </g>
      <defs>
        <clipPath id='clip0_992:1605'>
          <rect width='40' height='44' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  )

export default DateIcon