/* eslint-disable camelcase */
import { isNil } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RoomsForm from '../components/form/RoomForm';
import { RootState } from '../store/configureStore';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { Paths, Role, RoomAttributes, RoomFormAttributes } from '../utils/constants';
import { evaluate_permissions, verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { formValueSelector } from 'redux-form';
import { CREATE_ROOM, FETCH_ROOM, UPDATE_ROOM } from '../store/rooms';

const RoomsFormContainer = (props: {
  onSave: () => void
  room_id?: number
  close_form: () => void
}) => {
  const { onSave, room_id, close_form } = props
  const dispatch = useDispatch();
  const history = useHistory();
  const state  = useSelector((state: RootState) => state);
  const {
    auth: { company, profile },
  } = state
  const formValues = formValueSelector('roomForm');
  const [initialValues, setInitialValues] = React.useState<RoomAttributes | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const companyIdValue = formValues(state, 'company_id') as string;
  const isUpdating = !isNil(initialValues?.id);
  const is_above_school_director = evaluate_permissions.is_above_school_director(profile.role as Role);
  const company_id_to_use = is_above_school_director
    ? companyIdValue
    : company;

 
  const loadRooms = React.useCallback(async () => {
    if (room_id) {
      const subject = await dispatch(
        FETCH_ROOM.request({
          id: room_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = subject;
      const formattedSubject = {
        id,
        ...attributes,
      };
      setInitialValues(formattedSubject);
    } else {
      setInitialValues({ company_id: company_id_to_use } as RoomAttributes)
    }
  }, [room_id]);

  const initRoomsForm = React.useCallback(async () => {
    setLoading(true);
    await loadRooms();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: RoomFormAttributes) => {
      try {
        if (isUpdating) {
          const { id, year, company_id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'company_id',
            'year'
          ]) as RoomFormAttributes;
          await dispatch(
            UPDATE_ROOM.request({
              id: initialValues?.id as string,
              data: rest,
            }),
          );
        } else {
          const {company_id, year, ...rest} = data
          await dispatch(
            CREATE_ROOM.request({
              data: {
                ...rest,
              },
            }),
          );
        }
        history.push(Paths.ROOMS_LIST);
        dispatch(
          success({
            message: 'Sala salva com sucesso.',
          }),
        );
        onSave()
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar sala.',
          }),
        );
      }
    },
    [initialValues, company_id_to_use, isUpdating, history],
  );

  React.useEffect(() => {
    initRoomsForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <RoomsForm close_form={close_form} initialValues={initialValues} onSubmit={onSubmit} />;
};

export default RoomsFormContainer;
