import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const subjectsEndpoint = '/subjects'

export const fetchSubjects = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${subjectsEndpoint}?${queryParams}`)
}

export const fetchSubject = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${subjectsEndpoint}/${id}?${queryParams}`)
}

export const createSubject = (data: object) => {
  return http.post(`${subjectsEndpoint}`, data)
}

export const updateSubject = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${subjectsEndpoint}/${id}`, data)
}

export const deleteSubject = ({ id }: { id: string | number } ) => {
  return http.delete(`${subjectsEndpoint}/${id}`)
}