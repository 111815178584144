/* eslint-disable camelcase */
import React from 'react'
import { WrappedFieldProps } from 'redux-form'
import { makeStyles, DefaultTheme } from '@mui/styles'
import NumberFormat from 'react-number-format'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { colors, CountryOptions } from '../../../utils/constants'
import { removeSpecialSymbols } from '../../../utils/functions'

type Props = {
  disabled: boolean
}


const useStyles = makeStyles<DefaultTheme, Props>(() => ({
  inputArea: {
    display: 'grid',
    position: 'relative',
    '& span': {
      justifySelf: 'flex-start',
      color: colors.darkGrayBlue,
      fontSize: '0.8rem'
    },
    '& span.error': {
      color: colors.lightRed
    },
    '& span.error:empty:before': {
      content: '"\\200b"'
    },
    '& > div': {
      height: '2.5rem',
      display: 'grid',
      background: (props) => (props.disabled ? colors.lightBlue : 'white'),
      justifyItems: 'flex-start',
      cursor: 'text',
      borderRadius: '4px',
      border: `1px solid ${colors.grayBlue}`,
      padding: '0.5rem 0.75rem',
      '& label': {
        color: colors.darkGrayBlue,
        fontSize: '10px'
      },
      '& .phoneArea': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '2rem'
      },
      '& .phoneBody': {
        border: 'none',
        outline: 'none',
        width: '100%',
        '& fieldset': {
          border: 'none'
        },
        '& .MuiOutlinedInput-root, input': {
          padding: 'unset'
        }
      },
      '& .countryField': {
        '& input': {
          width: '10rem !important',
          color: colors.darkBlue,
          'font-family': 'Hauora, sans-serif'
        }
      },
      '& ::placeholder': {
        color: colors.grayBlue,
        fontSize: '16px'
      },
      '& span': {
        justifySelf: 'flex-start'
      }
    }
  }
}))

const MaskedInputComponent = (props: any) => {
  const { label, input, maskProps, meta, ...rest } = props
  const inputRef = React.useRef<HTMLDivElement>()
  const classes = useStyles({
    disabled: input.disabled || rest.disabled
  })
  return (
    <div className={classes.inputArea}  id={`input-${input.name}`}>
      <div onClick={() => inputRef?.current?.focus()}>
        <label> {label} </label>
        <NumberFormat data-testid={input.name} className='phoneBody' getInputRef={inputRef} {...input} {...maskProps} {...rest} />
      </div>
      <span className='error'>{meta && meta.touched && meta.error && meta.error}</span>
    </div>
  )
}

interface PhoneInputProps extends WrappedFieldProps {
  maskProps?: object
  initialCountryOptions?: CountryOptions[]
  disabled?: boolean,
  label: string
}

interface CountryCodeInputOption {
  value: string
  label: string
  countryValue: string|number
}

function formatMethod(value: string, pattern:string) {
  let i = 0;
  const v = value.toString();
  return pattern.replace(/#/g, () => v[i++]);
}

const defaultCountryCodeInputValue = { value: '+55', label: '🇧🇷 +55', countryValue: 'BR' }

export const PhoneInputComponent = (props: PhoneInputProps) => {
  const { label, input, maskProps, meta, initialCountryOptions, ...rest } = props
  const [countryOptions, setCountryOptions] = React.useState<CountryCodeInputOption[]>([])
  const [countryCodeInput, setCountryCodeInput] = React.useState<CountryCodeInputOption>(defaultCountryCodeInputValue)
  const [phoneInput, setPhoneInput] = React.useState<string>('')
  const inputRef = React.useRef<HTMLDivElement>()
  const countryInputRef = React.useRef<HTMLInputElement>()
  const format = removeSpecialSymbols(input.value).length < 11 ? '(##) ####-#####' : '(##) #####-####'
  const handleChange = React.useCallback(({type, value}:{type: 'code'|'body', value: CountryCodeInputOption|string}) => {
    let newInputValue = ''
    if(type === 'code'){
      const parsedValue = value as CountryCodeInputOption
      setCountryCodeInput(parsedValue)
      newInputValue = `${parsedValue.value} ${phoneInput}`
    } else if(type === 'body') {
      const parsedValue = value as string
      setPhoneInput(parsedValue)
      newInputValue = `${countryCodeInput?.value || defaultCountryCodeInputValue.value} ${parsedValue}`
    }
    input.onChange(newInputValue)
  },[input, countryCodeInput, phoneInput])

  React.useEffect(() => {
    if(initialCountryOptions){
      const newCountryOptions = initialCountryOptions.map((item) => ({
        value: item.phonecode[0] === '+' ? item.phonecode : `+${item.phonecode}`,
        label: `${item.flag} - ${item.value} ${
            item.phonecode[0] === '+' ? item.phonecode : `+${item.phonecode}`
        }`,
        countryValue: item.value,
      }))
      const phone = input.value
      const countryCode = phone ? phone.substr(0, phone.indexOf(' ')) : ''
      const country_code =
      countryCode && !!initialCountryOptions && !!initialCountryOptions?.length
        ? initialCountryOptions
          .map((item) => {
            const { flag, phonecode } = item
            const value = phonecode[0] === '+' ? phonecode : `+${phonecode}`;
            const label = `${flag} - ${item.value} ${
              phonecode[0] === '+' ? phonecode : '+' + phonecode
            }`;
            return {
              value,
              label,
              countryValue: item.value
            };
          })
          .find((item) => item.value === countryCode)
        : defaultCountryCodeInputValue;
      const phoneBody = phone ? phone.substr(phone.indexOf(' ') + 1, phone?.length) : ''
      const formatPhoneBody = formatMethod(removeSpecialSymbols(phoneBody), format)
      setCountryOptions(newCountryOptions)
      setCountryCodeInput(country_code as CountryCodeInputOption)
      
      setPhoneInput(formatPhoneBody)
    }
  }, [initialCountryOptions, input])
  React.useEffect(() => {
    if(input.value){
      const splitted_value = input.value.split(" ")
      const body = removeSpecialSymbols(`${splitted_value[1]}${splitted_value[2]}`)
      const formatPhoneBody = formatMethod(removeSpecialSymbols(body), format)
      const new_value = `${splitted_value[0]} ${formatPhoneBody}`
      input.onChange(new_value)
    }
  }, [])
  const classes = useStyles({
    disabled: rest.disabled as boolean
  })
  return (
    <div className={classes.inputArea} id={`input-${input.name}`}>
      <div
        onClick={() => {
          if (countryInputRef && countryInputRef?.current?.value === '') {
            countryInputRef.current.focus()
          } else {
            inputRef?.current?.focus()
          }
        }}
      >
        <label> {label} </label>
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
          className='phoneArea'
        >
          <Autocomplete
            data-testid={'country_code'}
            options={countryOptions}
            onChange={(_, value) => {
              handleChange({type: 'code', value})
            }}
            getOptionLabel={(option) => {
              return option?.label as string
            }}
            value={countryCodeInput}
            disableClearable
            renderInput={(params) => {
              return (
                <TextField
                  onClick={() => {
                    countryInputRef?.current?.focus()
                  }}
                  inputRef={countryInputRef}
                  className='phoneBody countryField'
                  placeholder='+00'
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                />
              )
            }}
          />
          <NumberFormat
            data-testid={input.name}
            className='phoneBody'
            getInputRef={inputRef}
            onChange={(event: any) => {
              handleChange({type: 'body', value: event.target.value})
            }}
            value={phoneInput}
            onBlur={() => input.onBlur(undefined)}
            // {...input}
            {...maskProps}
            format={format}
            {...rest}
          />
        </div>
      </div>
      <span className='error'>{meta && meta.touched && meta.error && meta.error}</span>
    </div>
  )
}
export default MaskedInputComponent
