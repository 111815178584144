import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
import http from '../../utils/http'


export const fetchAsaasPayments = async (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`asaas/find_client_payments?${queryParams}`)
}

export const fetchAsaasWebhookSettings = async () => {
  return http.get(`asaas/get_webhook`)
}

export const updateAsaasWebhookSettings = async (params: object) => {
  return http.post(`asaas/update_webhook`, params)
}

