/* eslint-disable camelcase */
import { filter, find, isNil, keys, map } from 'lodash';
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { ClassTimeAttributes, ClassTimeSubjectAttributes, ClassTimeSubjectFormAttributes, NestedTeacherClassTimeAttributes, RoomAttributes } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { CREATE_CLASS_TIME_SUBJECT, FETCH_CLASS_TIME_SUBJECT, UPDATE_CLASS_TIME_SUBJECT } from '../store/class_time_subjects';
import { TeacherClassTimeJson } from '../store/teacher_class_times';
import ClassTimeSubjectForm from '../components/form/ClassTimeSubjectForm';

const teacherClassTimeUpdater = (
  currentTeacherClassTimes: NestedTeacherClassTimeAttributes[],
  initialTeacherClassTimes: NestedTeacherClassTimeAttributes[],
) => {
  const sorted: NestedTeacherClassTimeAttributes[] = [];
  currentTeacherClassTimes.forEach((teacher_class_time) => {
    const related = find(initialTeacherClassTimes, (initial) => initial.id === teacher_class_time.id);
    if (isNil(related)) {
      sorted.push(teacher_class_time);
      return;
    }
    const omit = ['id', '_destroy', 'class_time_subject_id'];
    const result = verifyDifferentValues(teacher_class_time, related, omit) as NestedTeacherClassTimeAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedTeacherClassTimeAttributes[];
};



const ClassTimeSubjectsFormContainer = (props: {
  onSave: () => void
  class_time: ClassTimeAttributes
  class_time_subject_id?: string
  close_form: () => void
  room: RoomAttributes
}) => {
  const { onSave, close_form, class_time, room, class_time_subject_id } = props
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = React.useState<Partial<ClassTimeSubjectAttributes> | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const isUpdating = !isNil(initialValues?.id);

 
  const loadClassTimes = React.useCallback(async () => {
    if (class_time_subject_id) {
      const subject = await dispatch(
        FETCH_CLASS_TIME_SUBJECT.request({
          id: class_time_subject_id,
          params: {
            filters: {
              'include': ['teacher_class_times'].join(',')
            }
          }
        }),
      );
      const {
        data: {
          data: { id, attributes }, included
        },
      } = subject;
      const teacher_class_times_attributes = map(filter(included, incl => incl.type === 'teacher_class_times') as TeacherClassTimeJson[], tct => ({
        id: tct.id,
        ...tct.attributes
      }))

      const formattedSubject = {
        id,
        ...attributes,
        teacher_class_times_attributes
      };
      setInitialValues(formattedSubject);
    } else {
      setInitialValues({
        class_time_id: ~~class_time.id,
      })
    }
  }, [class_time]);

  const initClassTimesForm = React.useCallback(async () => {
    setLoading(true);
    await loadClassTimes();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: ClassTimeSubjectFormAttributes) => {
      const { teacher_class_times_attributes, ...dataRest } = data
      try {
        if (isUpdating) {
          const { teacher_class_times_attributes: initial_teacher_class_times_attributes, ...initialRest } = initialValues as ClassTimeSubjectFormAttributes
          const { id, ...rest } = verifyDifferentValues(dataRest, initialRest, [
            'id',
            'class_time_id',
          ]) as ClassTimeSubjectFormAttributes;
          const updated_teacher_class_times_attributes =  teacherClassTimeUpdater(teacher_class_times_attributes, initial_teacher_class_times_attributes)

          await dispatch(
            UPDATE_CLASS_TIME_SUBJECT.request({
              id: initialValues?.id as string,
              data: {...rest, teacher_class_times_attributes : updated_teacher_class_times_attributes},
            }),
          );
        } else {
          await dispatch(
            CREATE_CLASS_TIME_SUBJECT.request({
              data: {
                ...data,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Disciplina no horário salva com sucesso.',
          }),
        );
        onSave()
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar disciplina no horário.',
          }),
        );
      }
    },
    [initialValues, class_time, isUpdating],
  );

  React.useEffect(() => {
    initClassTimesForm();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <ClassTimeSubjectForm room={room} close_form={close_form} initialValues={initialValues} onSubmit={onSubmit} />;
};

export default ClassTimeSubjectsFormContainer;
