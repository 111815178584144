import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const ktwelveCurriculumRegistrationEndpoints = '/ktwelve_curriculum_registrations'

export const fetchKtwelveCurriculumRegistrations = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ktwelveCurriculumRegistrationEndpoints}?${queryParams}`)
}

export const fetchKtwelveCurriculumRegistration = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ktwelveCurriculumRegistrationEndpoints}/${id}?${queryParams}`)
}

export const generateKtwelveCurriculumRegistrationReport = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${ktwelveCurriculumRegistrationEndpoints}/${id}/generate_report?${queryParams}`)
}

export const createKtwelveCurriculumRegistration = (data: object) => {
  return http.post(`${ktwelveCurriculumRegistrationEndpoints}`, data)
}

export const updateKtwelveCurriculumRegistration = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${ktwelveCurriculumRegistrationEndpoints}/${id}`, data)
}

export const deleteKtwelveCurriculumRegistration = ({ id }: { id: string | number } ) => {
  return http.delete(`${ktwelveCurriculumRegistrationEndpoints}/${id}`)
}
