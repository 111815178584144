import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_SUBJECT_PERIODS,
  FetchSubjectPeriodsPayload,
  FetchSubjectPeriodPayload,
  FETCH_SUBJECT_PERIOD,
  CREATE_SUBJECT_PERIOD,
  CreateSubjectPeriodPayload,
  UpdateSubjectPeriodPayload,
  UPDATE_SUBJECT_PERIOD,
  DeleteSubjectPeriodPayload,
  DELETE_SUBJECT_PERIOD
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchSubjectPeriods (action: DefaultAction<FetchSubjectPeriodsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchSubjectPeriods, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_SUBJECT_PERIODS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_SUBJECT_PERIODS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchSubjectPeriod (action: DefaultAction<FetchSubjectPeriodPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchSubjectPeriod, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_SUBJECT_PERIOD.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_SUBJECT_PERIOD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createSubjectPeriod (action: DefaultAction<CreateSubjectPeriodPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'subject_periods',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createSubjectPeriod, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_SUBJECT_PERIOD.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_SUBJECT_PERIOD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateSubjectPeriod (action: DefaultAction<UpdateSubjectPeriodPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'subject_periods',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateSubjectPeriod, {data: params, id})
    yield put(UPDATE_SUBJECT_PERIOD.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_SUBJECT_PERIOD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteSubjectPeriod (action: DefaultAction<DeleteSubjectPeriodPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteSubjectPeriod, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_SUBJECT_PERIOD.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_SUBJECT_PERIOD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchSubjectPeriodsSagas () {
  yield takeLatest(FETCH_SUBJECT_PERIODS.request, fetchSubjectPeriods)
  yield takeLatest(FETCH_SUBJECT_PERIOD.request, fetchSubjectPeriod)
  yield takeLatest(CREATE_SUBJECT_PERIOD.request, createSubjectPeriod)
  yield takeLatest(UPDATE_SUBJECT_PERIOD.request, updateSubjectPeriod)
  yield takeLatest(DELETE_SUBJECT_PERIOD.request, deleteSubjectPeriod)
}

export default function * subjectPeriodsSagas () {
  yield all([watchSubjectPeriodsSagas()])
}
