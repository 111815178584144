import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_STATE,
  FETCH_STATES,
  FetchStatePayload,
  FetchStatesPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchStates (action: DefaultAction<FetchStatesPayload, string>): any {
  const { payload: { params } } = action
  try {
    const response = yield call(services.fetchStates, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_STATES.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(FETCH_STATES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchState (action :DefaultAction<FetchStatePayload, string>): any {
  const { payload: { params, id } } = action
  try {
    const response = yield call(services.fetchState, { params, id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_STATE.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_STATE.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchStateSagas () {
  yield takeLatest(FETCH_STATES.request, fetchStates)
  yield takeLatest(FETCH_STATE.request, fetchState)
}

export default function * stateSagas () {
  yield all([watchStateSagas()])
}
