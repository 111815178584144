/* eslint-disable camelcase */
import { createPromiseAction } from 'redux-saga-promise-actions'
import { JsonFormat, JsonResponseFormat, WithRequestParams } from '../../utils/constants'

export interface RegistrationClosureReasonAttrs {
  name: string
}

export type RegistrationClosureReasonJson = JsonFormat<'registration_closure_reasons', RegistrationClosureReasonAttrs>

export type FetchRegistrationClosureReasonsResponse = JsonResponseFormat<RegistrationClosureReasonJson[]>

export type FetchRegistrationClosureReasonsPayload = WithRequestParams

export const FETCH_REGISTRATION_CLOSURE_REASONS = createPromiseAction('registration_closure_reasons: FETCH_REGISTRATION_CLOSURE_REASONS')<FetchRegistrationClosureReasonsPayload, FetchRegistrationClosureReasonsResponse, any>()
