import React from 'react'
import { SvgIcon } from "@mui/material"


export const RightArrow = (props: any) => {
    return (
        <SvgIcon width='8' height='14' viewBox='0 0 8 14' fill='none' {...props}>
            <path d='M1.25559 0.482626C1.31854 0.482472 1.38093 0.494734 1.43914 0.518705C1.49736 0.542674 1.55026 0.577897 1.59486 0.622335L7.85713 6.88461C7.90238 6.92917 7.93832 6.98229 7.96285 7.04088C7.98738 7.09947 8 7.16234 8 7.22585C8 7.28936 7.98738 7.35224 7.96285 7.41082C7.93832 7.46941 7.90238 7.52253 7.85713 7.56709L1.59485 13.8294C1.5516 13.8804 1.49824 13.9219 1.43812 13.9512C1.378 13.9805 1.31245 13.997 1.24562 13.9996C1.17879 14.0023 1.11213 13.991 1.04988 13.9665C0.987635 13.9421 0.931139 13.905 0.883986 13.8575C0.836834 13.8101 0.800063 13.7534 0.775971 13.691C0.751881 13.6286 0.740994 13.5619 0.744035 13.4951C0.747075 13.4283 0.763975 13.3628 0.793633 13.3029C0.823291 13.2429 0.865072 13.1898 0.916338 13.1469L6.83537 7.22785L0.916339 1.30482C0.849688 1.23731 0.804403 1.15166 0.786131 1.05856C0.76786 0.965464 0.777423 0.869037 0.81361 0.781337C0.849797 0.693636 0.91101 0.618553 0.989616 0.565428C1.06822 0.512305 1.16072 0.483498 1.25559 0.482626Z'/>
        </SvgIcon>
    )
}

export const LeftArrow = (props: any) => {
    return (
        <SvgIcon width='8' height='14' viewBox='0 0 8 14' fill='none' {...props}>
            <path d='M6.74442 13.5174C6.68146 13.5175 6.61907 13.5053 6.56086 13.4813C6.50265 13.4573 6.44974 13.4221 6.40515 13.3777L0.142875 7.11539C0.0976216 7.07083 0.0616801 7.01771 0.03715 6.95912C0.0126199 6.90053 0 6.83766 0 6.77415C0 6.71064 0.0126199 6.64776 0.03715 6.58918C0.0616801 6.53059 0.0976216 6.47747 0.142875 6.43291L6.40515 0.170635C6.4484 0.119622 6.50176 0.0781381 6.56188 0.0488319C6.622 0.0195256 6.68755 0.00302714 6.75438 0.000378541C6.82121 -0.00227006 6.88787 0.0090038 6.95012 0.0334603C7.01237 0.0579168 7.06886 0.0950199 7.11601 0.14245C7.16317 0.18988 7.19994 0.246589 7.22403 0.308979C7.24812 0.37137 7.25901 0.438101 7.25597 0.504912C7.25292 0.571723 7.23603 0.637173 7.20637 0.697118C7.17671 0.757062 7.13493 0.810172 7.08366 0.853123L1.16463 6.77215L7.08366 12.6952C7.15031 12.7627 7.1956 12.8483 7.21387 12.9414C7.23214 13.0345 7.22258 13.131 7.18639 13.2187C7.1502 13.3064 7.08899 13.3814 7.01039 13.4346C6.93178 13.4877 6.83929 13.5165 6.74442 13.5174Z'/>
        </SvgIcon>
    )
}