import React from 'react'
import { Field, GenericField, change, reduxForm } from 'redux-form';
import MaskedInputComponent from '../input/form/masked';
import { parseMonetaryValue, toFixedNumber } from '../../utils/functions';
import { monetaryValueMaskProps, validation } from '../../utils/constants';
import InputComponent from '../input/form/input';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';

interface WalletCreditCustomProps{
  label: string;
  placeholder: string;
  allowNegatives: boolean
  maskProps: object
  disabled: boolean
}
const FieldCustom = Field as new () => GenericField<WalletCreditCustomProps>;


export const WalletCreditFormBody = (props: {
  disabled: boolean
  current_balance: number
  invoice_liquid_value?: number
  form_name: string
}) => {
  const { current_balance, form_name, invoice_liquid_value, ...rest } = props
  const dispatch = useDispatch()
  const handleBlur = (e:React.ChangeEvent<any>|undefined, value: any, previousValue: any, name = '') => {
    const formatted_invoice_liquid_value = toFixedNumber(invoice_liquid_value||0)
    if((value > current_balance + previousValue || (formatted_invoice_liquid_value && value > formatted_invoice_liquid_value))){
      e?.preventDefault()
      dispatch(change(form_name, name, previousValue))
    }
  }

  const handleChange = (e:React.ChangeEvent<any>|undefined, value: any) => {
    const formatted_invoice_liquid_value = toFixedNumber(invoice_liquid_value||0)
    if((value > current_balance) || (formatted_invoice_liquid_value && value > formatted_invoice_liquid_value)){
      e?.preventDefault()
    }
  }
  return (
    <form style={{ display: 'grid', gridTemplateColumns: '45% 45%', justifyContent: 'space-between' }}>
      <FieldCustom
          name='credit_out'
          label='Valor do Crédito'
          component={MaskedInputComponent}
          parse={parseMonetaryValue}
          allowNegatives={false}
          maskProps={monetaryValueMaskProps}
          placeholder={'Valor do Crédito'}
          validate={[validation.required]}
          onChange={handleChange}
          onBlur={handleBlur}
          {...rest}
        />
        <Field
          name='description'
          label={'Descrição'}
          placeholder={'Descrição'}
          component={InputComponent}
          validate={[validation.required]}
        />
    </form>
  )
}

export default compose<any>(
  reduxForm({
    form: 'walletCreditForm',
  }),
)(WalletCreditFormBody);
