/* eslint-disable camelcase */
import React from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { UI_SET_LOADING_OPEN } from '../store/ui';
import { DefaultOptionType, KtwelveCurriculumAttributes, KtwelveSubjectAttributes, LocationState } from '../utils/constants';
import { verifyDifferentValues } from '../utils/functions';
import Loading from '../components/loading/Loading';
import { CREATE_KTWELVE_SUBJECT, FETCH_KTWELVE_SUBJECT, UPDATE_KTWELVE_SUBJECT } from '../store/ktwelve_subjects';
import KtwelveSubjectsForm from '../components/form/KtwelveSubjectsForm';

type KtwelveSubjectFormData = Partial<KtwelveSubjectAttributes>

const KtwelveSubjectsFormContainer = (props: {
  ktwelve_curriculum: KtwelveCurriculumAttributes;
  ktwelve_subject_id?: string
  onSave: () => void;
  close_form?: () => void;
  ktwelve_subjects_options: DefaultOptionType[]
}) => {
  const { ktwelve_curriculum, ktwelve_subject_id, close_form, onSave, ktwelve_subjects_options } = props;
  const ktwelve_curriculum_id = ~~ktwelve_curriculum.id
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const history = useHistory();

  const [initialValues, setInitialValues] = React.useState<KtwelveSubjectFormData | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value));
  }, []);
  const loadKtwelveSubject = React.useCallback(async () => {
    if (ktwelve_subject_id) {
      const ktwelve_subject = await dispatch(
        FETCH_KTWELVE_SUBJECT.request({
          id: ktwelve_subject_id,
        }),
      );
      const {
        data: {
          data: { id, attributes },
        },
      } = ktwelve_subject;

      const formattedKtwelveSubject = {
        id,
        ...attributes,
      };
      setInitialValues(formattedKtwelveSubject);
    } else {
      setInitialValues({ ktwelve_curriculum_id } as KtwelveSubjectFormData);
    }
  }, [location]);

  const initKtwelveSubject = React.useCallback(async () => {
    setLoading(true);
    await loadKtwelveSubject();
    setLoading(false);
    setLoaded(true);
  }, []);

  const onSubmit = React.useCallback(
    async (data: KtwelveCurriculumAttributes) => {
      try {
        if (initialValues?.id) {
          const { id, ...rest } = verifyDifferentValues(data, initialValues, [
            'id',
            'ktwelve_curriculum_id',
          ]) as KtwelveCurriculumAttributes;
          await dispatch(
            UPDATE_KTWELVE_SUBJECT.request({
              id: initialValues?.id as string,
              data: {
                ...rest,
              },
            }),
          );
        } else {
          await dispatch(
            CREATE_KTWELVE_SUBJECT.request({
              data: {
                ...data,
              },
            }),
          );
        }
        dispatch(
          success({
            message: 'Disciplina inserida na série com sucesso.',
          }),
        );
        onSave();
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao inserir disciplina na série.',
          }),
        );
      }
    },
    [initialValues, history],
  );

  React.useEffect(() => {
    initKtwelveSubject();
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <KtwelveSubjectsForm
      close_form={close_form}
      initialValues={initialValues}
      onSubmit={onSubmit}
      ktwelve_curriculum={ktwelve_curriculum}
      ktwelve_subjects_options={ktwelve_subjects_options}
    />
  );
};

export default KtwelveSubjectsFormContainer;
