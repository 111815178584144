import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_SUBJECTS,
  FetchSubjectsPayload,
  FetchSubjectPayload,
  FETCH_SUBJECT,
  CREATE_SUBJECT,
  CreateSubjectPayload,
  UpdateSubjectPayload,
  UPDATE_SUBJECT,
  DeleteSubjectPayload,
  DELETE_SUBJECT
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchSubjects (action: DefaultAction<FetchSubjectsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchSubjects, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_SUBJECTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_SUBJECTS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchSubject (action: DefaultAction<FetchSubjectPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchSubject, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createSubject (action: DefaultAction<CreateSubjectPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'subjects',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createSubject, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateSubject (action: DefaultAction<UpdateSubjectPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'subjects',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateSubject, {data: params, id})
    yield put(UPDATE_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteSubject (action: DefaultAction<DeleteSubjectPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteSubject, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchSubjectsSagas () {
  yield takeLatest(FETCH_SUBJECTS.request, fetchSubjects)
  yield takeLatest(FETCH_SUBJECT.request, fetchSubject)
  yield takeLatest(CREATE_SUBJECT.request, createSubject)
  yield takeLatest(UPDATE_SUBJECT.request, updateSubject)
  yield takeLatest(DELETE_SUBJECT.request, deleteSubject)
}

export default function * subjectsSagas () {
  yield all([watchSubjectsSagas()])
}
