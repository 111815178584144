import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_ROOM_STUDENT_STATUS, CreateRoomStudentStatusPayload, DELETE_ROOM_STUDENT_STATUS, DeleteRoomStudentStatusPayload, FETCH_ROOM_STUDENT_STATUS, FETCH_ROOM_STUDENT_STATUSES, FetchRoomStudentStatusesPayload, FetchRoomStudentStatusPayload, UPDATE_ROOM_STUDENT_STATUS, UpdateRoomStudentStatusPayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchRoomStudentStatuses (action: DefaultAction<FetchRoomStudentStatusesPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchRoomStudentStatuses, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ROOM_STUDENT_STATUSES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ROOM_STUDENT_STATUSES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchRoomStudentStatus (action: DefaultAction<FetchRoomStudentStatusPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchRoomStudentStatus, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_ROOM_STUDENT_STATUS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_ROOM_STUDENT_STATUS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createRoomStudentStatus (action: DefaultAction<CreateRoomStudentStatusPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'room_student_status',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createRoomStudentStatus, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_ROOM_STUDENT_STATUS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_ROOM_STUDENT_STATUS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateRoomStudentStatus (action: DefaultAction<UpdateRoomStudentStatusPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'room_student_status',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateRoomStudentStatus, {data: params, id})
    yield put(UPDATE_ROOM_STUDENT_STATUS.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_ROOM_STUDENT_STATUS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteRoomStudentStatus (action: DefaultAction<DeleteRoomStudentStatusPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteRoomStudentStatus, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_ROOM_STUDENT_STATUS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_ROOM_STUDENT_STATUS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchRoomStudentStatusesSagas () {
  yield takeLatest(FETCH_ROOM_STUDENT_STATUSES.request, fetchRoomStudentStatuses)
  yield takeLatest(FETCH_ROOM_STUDENT_STATUS.request, fetchRoomStudentStatus)
  yield takeLatest(CREATE_ROOM_STUDENT_STATUS.request, createRoomStudentStatus)
  yield takeLatest(UPDATE_ROOM_STUDENT_STATUS.request, updateRoomStudentStatus)
  yield takeLatest(DELETE_ROOM_STUDENT_STATUS.request, deleteRoomStudentStatus)
}

export default function * roomStudentStatusSagas () {
  yield all([watchRoomStudentStatusesSagas()])
}
