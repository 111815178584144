import React from 'react'
import ProfileDashboardsContainer from '../containers/ProfileDashboardsContainer'
import navigationHoc from '../components/hoc/navigationHoc'

const ProfileDashboardsPage = () => {
  return(
    <ProfileDashboardsContainer />
  )
}

export default navigationHoc(ProfileDashboardsPage)