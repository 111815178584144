import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_PROFILE_DASHBOARDS,
  FETCH_PROFILE_DASHBOARD,
  FetchProfileDashboardsPayload,
  FetchProfileDashboardPayload,
  CreateProfileDashboardPayload,
  CREATE_PROFILE_DASHBOARD,
  UpdateProfileDashboardPayload,
  UPDATE_PROFILE_DASHBOARD,
  DELETE_DASHBOARD_LOGO,
  DeleteDashboardLogoPayload
} from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'


export function * createProfileDashboard (action: DefaultAction<CreateProfileDashboardPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'profile_dashboards',
        attributes: { ...data },
      }
    }    
    const response = yield call(services.createProfileDashboard, params)
    yield put(CREATE_PROFILE_DASHBOARD.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(CREATE_PROFILE_DASHBOARD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateProfileDashboard (action: DefaultAction<UpdateProfileDashboardPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'profile_dashboards',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateProfileDashboard, {params, id})
    yield put(UPDATE_PROFILE_DASHBOARD.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_PROFILE_DASHBOARD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchProfileDashboards (action: DefaultAction<FetchProfileDashboardsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchProfileDashboards, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_PROFILE_DASHBOARDS.success(response))
    resolvePromiseAction(action, response)
    
  } catch(err) {
    yield put(FETCH_PROFILE_DASHBOARDS.failure(err))
    rejectPromiseAction(action, err)
  }
}

export function * fetchProfileDashboard(action: DefaultAction<FetchProfileDashboardPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchProfileDashboard, { params, id })
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_PROFILE_DASHBOARD.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_PROFILE_DASHBOARD.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteDashboardLogo (action:DefaultAction<DeleteDashboardLogoPayload,string>): any {
  const { payload: {id}} = action
  try {
    const response = yield call(services.deleteDashboardLogo, { id })
    const {headers} = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_DASHBOARD_LOGO.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_DASHBOARD_LOGO.failure(e))
    rejectPromiseAction(action, e)
  }
}


export function * watchProfileDashboardsSagas () {
  yield takeLatest(FETCH_PROFILE_DASHBOARDS.request, fetchProfileDashboards)
  yield takeLatest(FETCH_PROFILE_DASHBOARD.request, fetchProfileDashboard)
  yield takeLatest(CREATE_PROFILE_DASHBOARD.request, createProfileDashboard)
  yield takeLatest(UPDATE_PROFILE_DASHBOARD.request, updateProfileDashboard)
  yield takeLatest(DELETE_DASHBOARD_LOGO.request, deleteDashboardLogo)
}

export default function * profileDashboardsSagas () {
  yield all([watchProfileDashboardsSagas()])
}
