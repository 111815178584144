import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_CITIES,
  FETCH_CITY,
  FetchCitiesPayload,
  FetchCityPayload,
} from './index'
import { DefaultAction } from '../../utils/constants'
import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchCities (action: DefaultAction<FetchCitiesPayload, string>): any {
  const { payload: {params}} = action
  try {
    const response = yield call(services.fetchCities, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CITIES.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CITIES.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchCity (action: DefaultAction<FetchCityPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchCity, { params, id })
      const { headers } = response
      yield put(updateCredentialsRequest(headers))
      yield put(FETCH_CITY.success(response))
      resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CITY.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchCitiesSagas () {
  yield takeLatest(FETCH_CITIES.request, fetchCities)
  yield takeLatest(FETCH_CITY.request, fetchCity)
}

export default function * citySagas () {
  yield all([watchCitiesSagas()])
}
