/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  reduxForm,
  Field,
  change,
  getFormValues,
  FieldArray,
  WrappedFieldArrayProps,
  FormSection,
  isValid,
  GenericField,
} from 'redux-form';
import { css } from '@emotion/react';

import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TextAreaComponent from '../input/form/textarea';
import MaskedInputComponent from '../input/form/masked';
import SelectComponent from '../input/form/select';

import {
  colors,
  PaymentOptionAttributes,
  monetaryValueMaskProps,
  percentageMaskProps,
  DefaultOptionType,
  Subsidies,
  ProductAttributes,
  UpdateRegistrationProductFormAttributes,
  NestedRegistrationProductSubsidyAttributes,
  SubsidiesAttributes,
  validation,
} from '../../utils/constants';
import {
  parseMonetaryValue,
  parsePercentageDiscount,
  formatToCurrency,
} from '../../utils/functions';
import { DefaultModalInterface } from '../modal/Modal';
import ContractDataTable from '../table/ContractDataTable';
import PaymentDataTable from '../table/PaymentDataTable';
import TabPanel from '../shared/TabPanel';
import { RootState } from '../../store/configureStore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import DeleteButton from '../shared/DeleteButton';
import RegistrationProductSubsidyFormTable from '../table/RegistrationProductSubsidyFormTable';
import AddButton from '../shared/AddButton';
import { getValuesForMonth } from '../../utils/contractSimulation';
interface SubsidyIdCustomProps {
  label: string;
  options: DefaultOptionType[];
  placeholder: string;
}
const FieldCustom = Field as new () => GenericField<SubsidyIdCustomProps>;
const useStyles = makeStyles(() => ({
  modalButtons: {
    marginTop: '4.5rem',
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
  },
  tabPanel: {
    display: 'grid',
    width: '100%',
    background: '#FDFEFF',
    padding: '1rem',
    borderRadius: '0 1rem 1rem 1rem',
  },
  discountForm: {
    display: 'grid',
    rowGap: '1rem',
    '& span.error': {
      display: 'none',
    },
    '& .subtitle-one': {
      justifySelf: 'start',
    },
    '& > div.first-grid': {
      display: 'grid',
      gridTemplateColumns: '24.25% 24.25% 24.25% 24.25%',
      justifyContent: 'space-between',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        width: '100%',
      },
    },
    '& > div.second-grid': {
      display: 'grid',
      gridTemplateColumns: '78% 20%',
      justifyContent: 'space-between',
      '& span': {
        fontSize: '1.5rem',
      },
    },
  },
  wrapper: {
    background: colors.lightBlue,
    padding: '2rem 1rem',
    display: 'grid',
    rowGap: '0.5rem',
    '& .subtitle-one': {
      justifySelf: 'start',
    },
    '& .types': {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'center',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& .product-form': {
      display: 'grid',
      rowGap: '1rem',
      '& > div > div': {
        width: 'auto',
      },
    },
    '& .title': {
      fontWeight: 'bold',
      fontSize: '1.75rem',
      justifySelf: 'center',
      marginBottom: '1rem',
    },
  },
  tabStyle: {
    '& .Mui-selected': {
      background: '#FDFEFF',
      borderRadius: '1rem 1rem 0 0',
    },
  },
  accordion: {
    background: 'inherit',
    'box-shadow': 'none',
    'border-bottom': `1px solid ${colors.darkGrayBlue}`,
  },
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    background: `${colors.grayBlue}`,
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'inherit',
    },
  },
}));

interface CustomRegistrationProductSubsidiesAttributesForm extends WrappedFieldArrayProps {
  current_registration_product: UpdateRegistrationProductFormAttributes;
  service_range: DefaultOptionType[];
  all_subsidies: SubsidiesAttributes[];
  subsidies_options: DefaultOptionType[];
  getValuesForMonths: (months: number[], exclude_subsidies?: number[]) => {
    final: number;
    financial: number;
    final_financial: number;
    initial: number;
    subsidy: number;
  }  
}

const renderRegistrationProductSubsidiesAttributesForm = (props: CustomRegistrationProductSubsidiesAttributesForm) => {
  const { fields, service_range, all_subsidies, subsidies_options, current_registration_product, getValuesForMonths } =
    props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const deleteRegistrationProductSubsidyMethod = React.useCallback(
    (
      current_registration_product_subsidy: NestedRegistrationProductSubsidyAttributes,
      registration_product_subsidy_attribute: string,
      index: number,
    ) => {
      current_registration_product_subsidy?.id
        ? dispatch(change('registration-productstep', `${registration_product_subsidy_attribute}._destroy`, true))
        : fields.remove(index);
    },
    [fields],
  );

  const addRegistrationProductSubsidy = () => {
    fields.push({
      portions: [],
    });
  };
  return (
    <div>
      <div
        data-testid='accounts_attributes'
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        <span>Adicionar Desconto Comercial</span>
        <AddButton onClick={addRegistrationProductSubsidy} />
      </div>
      {fields.map((registration_product_subsidy, index) => {
        const current_registration_product_subsidy = fields.get(index) as NestedRegistrationProductSubsidyAttributes;
        if (current_registration_product_subsidy._destroy) {
          return null;
        }
        
        const subsidy = all_subsidies.find(
          (item) => ~~item.id === ~~current_registration_product_subsidy.subsidy_id,
        ) as SubsidiesAttributes;
        const deleteRegistrationProductSubsidy = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          deleteRegistrationProductSubsidyMethod(
            current_registration_product_subsidy,
            registration_product_subsidy,
            index,
          );
        };
        const parseFunction =
          subsidy && subsidy.kind === 'full_value'
            ? parseMonetaryValue
            : subsidy && subsidy.kind === 'percentage'
            ? parsePercentageDiscount
            : undefined;
        const maskProps =
          subsidy && subsidy.kind === 'full_value'
            ? monetaryValueMaskProps
            : subsidy && subsidy.kind === 'percentage'
            ? percentageMaskProps
            : undefined;
        const message = subsidy
          ? `${subsidy.name ? subsidy.name : ''} ${
              current_registration_product_subsidy.discount
                ? `${
                    subsidy.kind === 'full_value'
                      ? `${formatToCurrency(current_registration_product_subsidy.discount)}`
                      : `${current_registration_product_subsidy.discount}%`
                  }`
                : ''
            }`
          : 'Novo Desconto Comercial';


        const handleSubsidyChange = (_:React.ChangeEvent<any>|undefined, value: any) => {
          if (value) {
            const new_subsidy = all_subsidies.find(
              (item) => ~~item.id === ~~value,
            ) as SubsidiesAttributes;
            const new_portions = new_subsidy.portions.filter((portion) =>
              current_registration_product.provision_months.includes(portion),
            );
            dispatch(
              change(
                'registration-productstep',
                `${registration_product_subsidy}.discount`,
                new_subsidy.discount,
              ),
            );
            dispatch(
              change(
                'registration-productstep',
                `${registration_product_subsidy}.portions`,
                new_portions,
              ),
            );
          }
        }
        return (
          <Accordion key={registration_product_subsidy} className={classes.accordion}>
            <AccordionSummary className={classes.accordionSummary}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ExpandMore />
                <span>{message}</span>
              </div>
              <DeleteButton tooltip='Deletar Desconto Comercial' onClick={deleteRegistrationProductSubsidy} />
            </AccordionSummary>
            <AccordionDetails>
              <FormSection name={registration_product_subsidy}>
                <form style={{ display: 'grid' }}>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      & > div {
                        flex-basis: 45%;
                      }
                    `}
                  >
                    <FieldCustom
                      onChange={handleSubsidyChange}
                      name='subsidy_id'
                      component={SelectComponent}
                      label={'Desconto Comercial'}
                      options={subsidies_options}
                      placeholder={'Desconto Comercial'}
                      validate={[validation.required]}
                    />
                    <Field
                      name='discount'
                      label='Valor do Desconto'
                      component={MaskedInputComponent}
                      disabled={!subsidy || !subsidy.changeable}
                      parse={parseFunction}
                      maskProps={maskProps}
                      placeholder={'Valor do Desconto'}
                      validate={[validation.required]}
                    />
                  </div>
                  <Field name='comments' label='Observações' component={TextAreaComponent} />
                  <RegistrationProductSubsidyFormTable
                    current_registration_product_subsidy={current_registration_product_subsidy}
                    subsidy={subsidy}
                    current_registration_product={current_registration_product}
                    service_range={service_range}
                    getValuesForMonths={getValuesForMonths}
                    registration_product_subsidy_attribute={registration_product_subsidy}
                  />
                </form>
              </FormSection>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

const RegistrationProductDiscountStepForm = (props: {
  handleTabChange: (_: any, value: number) => void;
  onSubmit: (data: UpdateRegistrationProductFormAttributes) => void;
  handleSubmit: (arg: (args: UpdateRegistrationProductFormAttributes) => void) => any;
  currentProduct: ProductAttributes;
  name: string;
  currentPaymentOption: PaymentOptionAttributes;
  subsidyOptions: DefaultOptionType[];
  allSubsidies: Subsidies[];
  serviceRange: DefaultOptionType[];
}) => {
  const {
    handleTabChange,
    onSubmit,
    handleSubmit,
    currentProduct,
    currentPaymentOption,
    serviceRange,
    allSubsidies,
    subsidyOptions,
  } = props;
  const classes = useStyles();
  const [contractTabValue, setContractTabValue] = React.useState(0);
  const [modal, setModal] = React.useState(false);
  const state = useSelector((state: RootState) => state);
  const current_registration_product = getFormValues('registration-productstep')(
    state,
  ) as UpdateRegistrationProductFormAttributes;
  const isFormValid = isValid('registration-productstep')(state);
  const { registration_product_subsidies_attributes, punctuality_discount_portions } = current_registration_product;
  const handleContractTabChange = React.useCallback((_: any, newValue: number) => {
    setContractTabValue(newValue);
  }, []);
  const handleSubmitMethod = React.useCallback(() => {
    handleSubmit((values) => {
      onSubmit(values);
    })();
  }, [handleSubmit, onSubmit]);

  const cancelMethod = React.useCallback(() => {
    handleTabChange(null, 0);
  }, []);
  const onConfirm = React.useCallback(({ handleClose }) => {
    handleClose();
    handleSubmitMethod();
  }, []);
  const getValuesForMonthMethod = (month: number, exclude_subsidies: number[] = []) => {
    return getValuesForMonth({
      product: currentProduct,
      current_payment_option: currentPaymentOption,
      registration_product_subsidies: registration_product_subsidies_attributes.filter(item => !item._destroy && !exclude_subsidies.includes(~~item.subsidy_id)),
      month,
      subsidies: allSubsidies,
      punctuality_discount_portions,
    });
  };

  const getValuesForMonths = (months: number[], exclude_subsidies: number[] = []) => {
    return months.reduce(
      (acc, month) => {
        const { final, financial, final_financial, initial, subsidy } = getValuesForMonthMethod(month, exclude_subsidies);
        return {
          final: acc.final + final,
          financial: acc.financial + financial,
          final_financial: acc.final_financial + final_financial,
          initial: acc.initial + initial,
          subsidy: acc.subsidy + subsidy,
        };
      },
      { final: 0, financial: 0, final_financial: 0, initial: 0, subsidy: 0 },
    );
  };

  return (
    <form className={classes.wrapper}>
      <div>
        <span className='subtitle-one'>{currentProduct.name}</span>
        <Tabs className={classes.tabStyle} value={contractTabValue} onChange={handleContractTabChange}>
          <Tab label='Gerenciar parcelas' value={0} />
          <Tab label='Gerenciar descontos' value={1} />
        </Tabs>
        <TabPanel value={contractTabValue} index={0} className={classes.tabPanel}>
          <ContractDataTable
            getValuesForMonths={getValuesForMonths}
            serviceRange={serviceRange}
            current_registration_product={current_registration_product}
          />
        </TabPanel>
        <TabPanel value={contractTabValue} index={1} className={classes.tabPanel}>
        <div style={{ marginBottom: '1rem', display: 'grid' }}>
            <span className='subtitle-one'>Descontos Comerciais</span>
            <FieldArray
              name='registration_product_subsidies_attributes'
              component={renderRegistrationProductSubsidiesAttributesForm}
              service_range={serviceRange}
              subsidies_options={subsidyOptions}
              all_subsidies={allSubsidies}
              getValuesForMonths={getValuesForMonths}
              current_registration_product={current_registration_product}
            />
          </div>
          <PaymentDataTable
            getValuesForMonths={getValuesForMonths}
            service_range={serviceRange}
            current_payment_option={currentPaymentOption}
            current_registration_product={current_registration_product}
          />
        </TabPanel>
      </div>
      <div className={classes.modalButtons}>
        <button onClick={cancelMethod} className='red small'>
          {' '}
          Voltar{' '}
        </button>
        <button className='green small' disabled={!isFormValid} onClick={handleSubmitMethod}>
          Salvar
        </button>
        <DefaultModalInterface
          title={'Confirmação'}
          message={'Existem descontos selecionados que não foram alterados ou aplicados. Deseja continuar mesmo assim?'}
          open={modal}
          handleClose={() => setModal(false)}
          onConfirm={onConfirm}
        />
      </div>
    </form>
  );
};

export default compose<any>(reduxForm({ form: 'registration-productstep' }))(RegistrationProductDiscountStepForm);
