import React from 'react'
import { makeStyles } from '@mui/styles'

import CompanyFormComponent from '../form/CompanyForm'
import { CompanyFormData, companyTypes } from '../../utils/constants'

interface DefaultOptionType {
  label: string | number,
  value: string | number
}


const useStyles = makeStyles(() : {
  view: any,
  title: any
} => ({
  view: {
    width: 'inherit',
    height: 'fit-content',
    display: 'flex',
    flexGrow: '1',
    padding: '2rem 15%',
    flexDirection: 'column',
    position: 'relative',
    '& .MuiBackdrop-root': {
      position: 'inherit'
    },
    '& .MuiPaper-root': {
      background: 'none',
      boxShadow: 'none'
    }
  },
  title: {
    alignSelf: 'flex-start',
    marginBottom: '1rem'
  }
}))

const getTitle = (initialValues: CompanyFormData, kind: string) => {
  if(initialValues.id){
    return `Editar companhia ${initialValues.name}`
  } else {
    const companyKindInfo = companyTypes.find((type) => type.value === kind)
    return `Criar uma companhia ${companyKindInfo && `tipo ${companyKindInfo?.label?.toLowerCase()}`}`
  }
}

const CreateCompanyView = (props : {
    kind: string,
    onSubmit: (arg: any) => void,
    companyOptions: DefaultOptionType[],
    initialValues: CompanyFormData
  }) => {
  const { kind, initialValues } = props
  const classes = useStyles()
  return (
    <div className={classes.view}>
      <span className={`${classes.title} title`}>
       {getTitle(initialValues, kind)}
      </span>
      <CompanyFormComponent {...props} />
    </div>
  )
}

export default CreateCompanyView
