import { all, call, takeLatest, put } from 'redux-saga/effects'

import { CREATE_CLASS_TIME_SUBJECT, CreateClassTimeSubjectPayload, DELETE_CLASS_TIME_SUBJECT, DeleteClassTimeSubjectPayload, FETCH_CLASS_TIME_SUBJECT, FETCH_CLASS_TIME_SUBJECTS, FetchClassTimeSubjectPayload, FetchClassTimeSubjectsPayload, UPDATE_CLASS_TIME_SUBJECT, UpdateClassTimeSubjectPayload } from './index'
import { DefaultAction } from '../../utils/constants'

import * as services from './services'

import { updateCredentialsRequest } from '../auth'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'

export function * fetchClassTimeSubjects (action: DefaultAction<FetchClassTimeSubjectsPayload, string>): any {
  const { payload: {params} } = action
  try {
    const response = yield call(services.fetchClassTimeSubjects, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CLASS_TIME_SUBJECTS.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CLASS_TIME_SUBJECTS.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * fetchClassTimeSubject (action: DefaultAction<FetchClassTimeSubjectPayload, string>): any {
  const { payload: {params, id} } = action
  try {
    const response = yield call(services.fetchClassTimeSubject, {params, id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(FETCH_CLASS_TIME_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(FETCH_CLASS_TIME_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * createClassTimeSubject (action: DefaultAction<CreateClassTimeSubjectPayload, string>): any {
  const { payload: {data} } = action
  try {
    const params = {
      data: {
        type: 'class_time_subjects',
        attributes: { ...data },
      }
    } 
    const response = yield call(services.createClassTimeSubject, params)
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(CREATE_CLASS_TIME_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(CREATE_CLASS_TIME_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * updateClassTimeSubject (action: DefaultAction<UpdateClassTimeSubjectPayload, string>): any {
  const { payload: {id, data} } = action
  try {
    const params = {
      data: {
        type: 'class_time_subjects',
        attributes: { ...data },
        id
      }
    }    
    const response = yield call(services.updateClassTimeSubject, {data: params, id})
    yield put(UPDATE_CLASS_TIME_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch (e) {
    yield put(UPDATE_CLASS_TIME_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * deleteClassTimeSubject (action: DefaultAction<DeleteClassTimeSubjectPayload, string>): any {
  const {payload:{id}} = action
  try {
    const response = yield call(services.deleteClassTimeSubject, {id})
    const { headers } = response
    yield put(updateCredentialsRequest(headers))
    yield put(DELETE_CLASS_TIME_SUBJECT.success(response))
    resolvePromiseAction(action, response)
  } catch(e) {
    yield put(DELETE_CLASS_TIME_SUBJECT.failure(e))
    rejectPromiseAction(action, e)
  }
}

export function * watchClassTimeSubjectsSagas () {
  yield takeLatest(FETCH_CLASS_TIME_SUBJECTS.request, fetchClassTimeSubjects)
  yield takeLatest(FETCH_CLASS_TIME_SUBJECT.request, fetchClassTimeSubject)
  yield takeLatest(CREATE_CLASS_TIME_SUBJECT.request, createClassTimeSubject)
  yield takeLatest(UPDATE_CLASS_TIME_SUBJECT.request, updateClassTimeSubject)
  yield takeLatest(DELETE_CLASS_TIME_SUBJECT.request, deleteClassTimeSubject)
}

export default function * classTimeSubjectsSagas () {
  yield all([watchClassTimeSubjectsSagas()])
}
