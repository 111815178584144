import http from '../../utils/http'
import { RequestParams } from '../../utils/constants'
import { setQueryParams } from '../../utils/functions'
const curriculumsEndpoint = '/curriculums'

export const fetchCurriculums = (params?: RequestParams) => {
  const queryParams = setQueryParams(params)
  return http.get(`${curriculumsEndpoint}?${queryParams}`)
}

export const fetchCurriculum = ({ params, id }: { params?: RequestParams, id: string | number }) => {
  const queryParams = setQueryParams(params)
  return http.get(`${curriculumsEndpoint}/${id}?${queryParams}`)
}

export const createCurriculum = (data: object) => {
  return http.post(`${curriculumsEndpoint}`, data)
}

export const updateCurriculum = ({ data, id }: { data: object, id: string|number }) => {
  return http.put(`${curriculumsEndpoint}/${id}`, data)
}

export const deleteCurriculum = ({ id }: { id: string | number } ) => {
  return http.delete(`${curriculumsEndpoint}/${id}`)
}