/* eslint-disable camelcase */
import React from 'react'
import { error, success } from 'react-notification-system-redux'
import { useDispatch } from 'react-redux'
import { UI_SET_LOADING_OPEN } from '../store/ui'
import { AsaasWebhookSettingsAttrs, FETCH_ASAAS_WEBHOOK_SETTING, UPDATE_ASAAS_WEBHOOK_SETTING } from '../store/asaas'
import AsaasWebhookSettingsForm from '../components/form/AsaasWebhookSettingsForm'
import { useHistory } from 'react-router-dom'
import { Paths } from '../utils/constants'




const AsaasWebhookSettingsFormContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [initialValues, setInitialValues] = React.useState<AsaasWebhookSettingsAttrs|null>(null)

  const setLoading = React.useCallback((value: boolean) => {
    dispatch(UI_SET_LOADING_OPEN(value))
  }, [])

  const loadSettings = React.useCallback(async () => {
    try {
      setLoading(true)
      const response = await dispatch(FETCH_ASAAS_WEBHOOK_SETTING.request({}))
      const { data } = response
      setInitialValues(data)
      setLoading(false)
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar dados do Asaas'
        })
      )
      setLoading(false)
    }
  }, [])

  const updateSettings = React.useCallback(async (data: AsaasWebhookSettingsAttrs) => {
    try {
      setLoading(true)
      await dispatch(UPDATE_ASAAS_WEBHOOK_SETTING.request({data}))
      setInitialValues(data)
      setLoading(false)
      dispatch(
        success({
          message: 'Dados do webhook atualizados com sucesso'
        })
      )
      history.push(Paths.HOME)
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao atualizar dados do Asaas'
        })
      )
      setLoading(false)
    }
  }, [])

  const initData = React.useCallback(async () => {
    await loadSettings()
  }, [])

  React.useEffect(() => {
    initData()
  }, [])

  const onSubmit = React.useCallback((data: AsaasWebhookSettingsAttrs) => {
    updateSettings(data)
  }, [initialValues])
  return (
    <AsaasWebhookSettingsForm
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  )
}

export default AsaasWebhookSettingsFormContainer