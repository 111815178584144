import { createPromiseAction } from "redux-saga-promise-actions"
import { CompositionPeriodKindEnum, JsonFormat, JsonResponseFormat, WithRequestParams } from "../../utils/constants"
import { CompositionJson } from "../compositions"
import { ExamPlacementJson } from "../exam_placements"
import { AxiosResponse } from "axios"
import { FormulaJson } from "../formulas"

export interface CompositionPeriodAttrs {
  composition_id: number
  name: string
  starts_at: string
  ends_at: string
  insert_starts_at: string
  insert_ends_at: string
  end_starts_at: string
  order: number
  kind: CompositionPeriodKindEnum
}

export type CompositionPeriodJson = JsonFormat<'composition_periods', CompositionPeriodAttrs>
export type FetchCompositionPeriodsResponse = JsonResponseFormat<CompositionPeriodJson[], (CompositionJson|ExamPlacementJson|FormulaJson)[]>
export type FetchCompositionPeriodResponse = JsonResponseFormat<CompositionPeriodJson, (CompositionJson|ExamPlacementJson|FormulaJson)[]>
export type FetchCompositionPeriodsPayload = WithRequestParams

export interface FetchCompositionPeriodPayload extends WithRequestParams {
  id: string | number,
}
export interface CreateCompositionPeriodPayload {
  data: object
}

export interface UpdateCompositionPeriodPayload extends CreateCompositionPeriodPayload {
  id: string
}

export interface DeleteCompositionPeriodPayload {
  id: string
}


export const FETCH_COMPOSITION_PERIODS = createPromiseAction('composition_periods: FETCH_COMPOSITION_PERIODS')<FetchCompositionPeriodsPayload, FetchCompositionPeriodsResponse, any>()
export const FETCH_COMPOSITION_PERIOD  = createPromiseAction('composition_periods: FETCH_COMPOSITION_PERIOD')<FetchCompositionPeriodPayload, FetchCompositionPeriodResponse, any>()
export const CREATE_COMPOSITION_PERIOD = createPromiseAction('composition_periods: CREATE_COMPOSITION_PERIOD')<CreateCompositionPeriodPayload, FetchCompositionPeriodResponse, any>()
export const UPDATE_COMPOSITION_PERIOD = createPromiseAction('composition_periods: UPDATE_COMPOSITION_PERIOD')<UpdateCompositionPeriodPayload, FetchCompositionPeriodResponse, any>()
export const DELETE_COMPOSITION_PERIOD = createPromiseAction('composition_periods: DELETE_COMPOSITION_PERIOD')<DeleteCompositionPeriodPayload, AxiosResponse, any>()