/* eslint-disable camelcase */
import { makeStyles } from '@mui/styles'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { validation } from '../../utils/constants'
import DatePickerComponent from '../input/form/datepicker'
import InputComponent from '../input/form/input'

const useStyles = makeStyles((): {
  form: any,
} => ({
  form: {
    display: 'grid',
    rowGap: '1rem',
    background: '#FDFEFF',
    padding: '1rem'
  },
}))

const RegistrationProductForm = () => {
  const classes = useStyles()
  return(
      <form className={classes.form}>
        <Field
          label='Data de Início'
          name='starts_at'
          id='starts_at'
          small
          component={DatePickerComponent}
          placeholder={'11/11/2021'}
          required
          validate={[validation.dateRequired]}
        />
        <Field
          label='Email extra para notificação de faturas'
          name='notification_email'
          small
          component={InputComponent}
          placeholder='Email extra para notificação de faturas'
          validate={[validation.validEmail]}
        />
      </form>
  )
}

export default compose<any>(
  reduxForm({
      form: 'registrationProductForm',
  }),
)(RegistrationProductForm);
