/* eslint-disable camelcase */
import React from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'

import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'

import DownloadIcon from '../../../icon/DownloadIcon'
import UploadIcon from '../../../icon/UploadIcon'

import { colors, CurrentRegistrationData, RegistrationFormData } from '../../../../utils/constants'
import { DeleteItemModal } from '../../../modal/Modal'
import { error, success } from 'react-notification-system-redux'
import { Theme } from '@mui/system'
import { DocumentRequirementJson, FETCH_REGISTRATION } from '../../../../store/registrations'
import { filter, find, map } from 'lodash'
import { RegistrationDocumentJson } from '../../../../store/registration_documents'

interface DocumentRequirementViewData  {
  id: string;
  document_id: number;
  document_name: string;
  mandatory: boolean;
  product_id: number;
  registration_documents: {
      document_requirement_id: number;
      file_url: {
          id: number;
          filename: string;
          url: string;
      };
      registration_id: number;
      id: string;
      sent_at: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    flex: 1
  },
  view: {
    padding: '1rem',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0'
    }
  },
  table: {
    padding: '2rem 4rem',
    '& .MuiTableContainer-root': {
      background: 'inherit',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        borderBottom: `5px solid ${colors.lightBlue}`,
        padding: '0.25rem',
      },
      '& th': {
        color: colors.darkGrayBlue
      },
      '& td': {
        color: colors.darkBlue
      },
      '& td.MuiTableCell-footer ': {
        borderBottom: 'none'
      },
      '& tbody > tr': {
        background: 'white'
      },
      '& .date, th:last-of-type': {
        textAlign: 'end'
      }
    },
    '& .actions': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      '& label': {
        position: 'relative'
      },
      '& input': {
        opacity: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '-1'
      },
      '& a,label,div': {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
        gap: '0.25rem',
        '& > span': {
          color: colors.grayBlue
        },
        '&:hover': {
          '& >span':{
            color: colors.darkBlue
          }
        }
      },
      '& div:first-of-type,label': {
        '& svg': {
          color: colors.blue
        }
      },
      '& div:last-of-type,label': {
        '& svg': {
          color: colors.lightRed
        }
      },
      '& .circular': {
        '& > span': {
          maxWidth: '1rem',
          maxHeight: '1rem'
        }
      }
    },  
  },
  doc: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    '& span': {
      background: colors.blue,
      padding: '1rem 3rem',
      color: 'white'
    }
  },
  button: {
    position: 'relative',
    padding: 'unset !important',
    '& > input': {
      display: 'none'
    },
    '& > label': {
      background: 'inherit',
      cursor: 'inherit',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'fit-content',
      borderRadius: '16px',
      padding: '1rem',
      gap: '1rem',
      '& span': {
        color: 'white'
      },
    }
  },
  buttons: {
    padding: '0 4rem',
    marginTop: '4.5rem',
    display: 'flex',
    justifyContent: 'end',
    gap: '1rem'
  }
}))

const columns = ['Documento', 'Ações', 'Data de Entrega']

const DocumentStep = ({ currentRegistration, setStep, fetchAndUpdateRegistrationMethod, setLoading } : {
  currentRegistration: CurrentRegistrationData,
  setStep: React.Dispatch<React.SetStateAction<number>>
  fetchAndUpdateRegistrationMethod: (id: string, data?: RegistrationFormData | FormData) => Promise<void>
  setLoading: (value: boolean) => void
}) => {
  const [document_requirements, setDocumentRequirements] = React.useState<DocumentRequirementViewData[]>([])
  const [loadingDocs, setLoadingDocs] = React.useState<(string|number)[]>([])
  const documentRequirements: typeof document_requirements = []
  !!document_requirements &&
        document_requirements?.forEach((doc) => {
          if (!documentRequirements?.some((item) => item.document_id === doc.document_id)) {
            documentRequirements?.push(doc)
          }
        })
  const classes = useStyles()
  const dispatch = useDispatch()

    const updateRegistrationMethod = async ({
    data,
    action,
  } : {
    data: RegistrationFormData | FormData,
    action?: string
  }) => {
    try {
      await fetchAndUpdateRegistrationMethod(currentRegistration.id, data)
      const actionMessage = action === 'delete' ? 'deletado' : 'adicionado'
      dispatch(
        success({
          message: `Documento ${actionMessage} com sucesso`,
          autoDismiss: 3
        })
      )
      } catch (er) {
      dispatch(error({
        message: 'Erro na adição dos documentos',
        autoDismiss: 3
      }))
    } finally {
      setLoadingDocs([])
    }
  }
  const initData = React.useCallback(async () => {
    setLoading(true)
    const response = await dispatch(
      FETCH_REGISTRATION.request({
        id: currentRegistration.id,
        params: {
          filters: {
            include: [
              'products.document_requirements,registration_documents',
            ].join(','),
          },
        },
      }),
    );
    const { data: { included } } = response
      const includedDocumentRequirements = filter(included, (item) => item.type === 'document_requirements') as DocumentRequirementJson[];
      const includedRegistrationDocuments = filter(included, (item) => item.type === 'registration_documents') as RegistrationDocumentJson[];

      const documentRequirementsAttrs: {
        document_requirements: DocumentRequirementViewData[];
      } = { document_requirements: [] };

      if (includedDocumentRequirements?.length) {
        documentRequirementsAttrs.document_requirements = map(includedDocumentRequirements, (item) => {
            const registrationDocuments = find(includedRegistrationDocuments, 
                (doc) => doc.attributes.document_requirement_id === ~~item.id,
            ) as RegistrationDocumentJson;
            return {
                ...item.attributes,
                id: item.id,
                registration_documents: {
                    ...registrationDocuments?.attributes,
                    id: registrationDocuments?.id,
                },
            };
        });
      }
      setDocumentRequirements(documentRequirementsAttrs.document_requirements)
      setLoading(false)
  }, [])

  React.useEffect(() => {
    initData()
  }, [])

  return (
    <div className={classes.wrapper}>
      <div className={classes.view}> 
      {documentRequirements.length ?
      <div className={classes.table}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell
                    key={item}
                    align={index !== 0 ? 'center' : 'left' }
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {documentRequirements.map((row) => {
                const hasDocuments = !!row.registration_documents.id
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.document_name}</TableCell>
                    <TableCell>
                    <div className='actions'>
                      {hasDocuments ? (
                        <>
                          <div>
                            <a
                              target='_blank'
                              href={row.registration_documents.file_url.url}
                              rel='noreferrer'
                            >
                              <IconButton>
                                <DownloadIcon />
                              </IconButton>
                              
                              <span>Baixar</span>
                              
                            </a>
                          </div>
                          <div>
                            <DeleteItemModal
                            text={'Apagar'}
                            message={
                              'Tem certeza que deseja excluir este documento?'
                            }
                            onConfirm={async ({ setLoading, handleClose }) => {
                              const registrationAttrs = {
                                registration_documents_attributes: [{
                                  id: row.registration_documents.id,
                                  _destroy: true
                                }]
                              }
                              await updateRegistrationMethod({ data: registrationAttrs, action: 'delete' })
                              setLoading(false)
                              handleClose()
                              }
                            }
                            />
                          </div>
                        </>
                      ) : (
                          <label htmlFor={row.id.toString()}>
                              {loadingDocs.includes(row.id) ? (
                              <div className='circular'>
                                {' '}
                                <CircularProgress />{' '}
                              </div>
                            ) : (
                              <IconButton>
                                <UploadIcon />
                              </IconButton>
                            )}
                            <span>
                              {loadingDocs.includes(row.id) ? 'Enviando' : 'Enviar'}
                            </span>
                            <input
                            id={row.id.toString()}
                            type='file'
                            disabled={
                              Boolean(loadingDocs.length && !loadingDocs.includes(row.id))
                            }
                            onChange={async (e) => {
                              const file = e?.target?.files
                              if (!!file && file[0]) {
                                setLoadingDocs([row.id])
                                const formData = new FormData()
                                formData.append('data[type]', 'registrations')
                                formData.append('data[id]', currentRegistration.id)
                                formData.append(
                                  `data[attributes][registration_documents_attributes][0][document_requirement_id]`,
                                  row.id.toString()
                                )
                                formData.append(
                                  'data[attributes][registration_documents_attributes][0][file]',
                                  file[0]
                                )
                                await updateRegistrationMethod({data: formData})
                              }
                            }}
                          />
                          </label>
                      )}
                    </div>
                    </TableCell>
                    <TableCell className='date'>
                      {row?.registration_documents?.sent_at && (
                        <span>
                          {format(
                            new Date(row?.registration_documents?.sent_at),
                            'dd/MM/yyyy'
                          )}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div> :
      <div>
        <span> Não há documentos pendentes. </span>
      </div>
      }
      <div className={classes.buttons}>
        <button
          className='red small'
          onClick={() => {
            setStep((step) => step - 1)
          }}
        >
          Voltar
        </button>
        <button
          onClick={() => {
            setStep((step) => step + 1)
          }}
          className='green small'
        >
          {' '}
                    Avançar{' '}
        </button>
      </div>
      </div>
    </div>
  )
}

export default DocumentStep
